import React from 'react'

import { CurrencyAttributeDisplay } from 'features/views/attributes/CurrencyAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 1
const MAX_LENGTH = 60

type CurrencyCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const CurrencyCell: TableViewCellComponent<number, CurrencyCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field, isPrimary } = column

    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <CurrencyAttributeDisplay
                weight={isPrimary ? 'bold' : undefined}
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxLines={MAX_LINES}
                maxLength={MAX_LENGTH}
                isLoading={isLoading}
            />
        </BaseCell>
    )
}
