import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { formatDate } from 'utils/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseDateAttributeDisplayStateProps = {
    field: FieldDto
    value?: string
    isLoading?: boolean
    showIcon?: boolean
}

export function useDateAttributeDisplayState({
    value,
    field,
    isLoading,
    showIcon,
}: UseDateAttributeDisplayStateProps) {
    const content = formatDateValue(field, isLoading ? PLACEHOLDER_VALUE : value)

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return { content, icon }
}

function formatDateValue(field: FieldDto, value?: string): string {
    if (!value) return ''

    const showTime = field.type === 'datetime'

    const { dateValue } = formatDate(value, field.options?.timezone, showTime)
    if (!dateValue) return ''

    const formattedValue = dateValue.format(showTime ? 'MMM D, YYYY LT' : 'MMM D, YYYY')

    return formattedValue
}

const PLACEHOLDER_VALUE = '2020-01-01T00:00:00Z'
