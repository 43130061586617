import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { getPageSizeFromRows } from 'features/views/ListView/CardView/Pagination/getCardsPageSize'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { PaginationContextProvider } from 'features/views/ListView/Pagination/PaginationContext'

type CardViewPaginationContextValue = {
    columns: number | undefined
    setContainerRef: (ref: HTMLDivElement | undefined) => void
}

const defaultContext: CardViewPaginationContextValue = {
    columns: undefined,
    setContainerRef: () => {},
}

const CardViewPaginationContext = createContext<CardViewPaginationContextValue>(defaultContext)

type CardViewPaginationContextProviderProps = {
    children: ReactNode
}

export const CardViewPaginationContextProvider: React.FC<
    CardViewPaginationContextProviderProps
> = ({ children }) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | undefined>(undefined)
    const [columns, setColumns] = useState<number | undefined>(undefined)
    const { view } = useListViewContext()

    useEffect(() => {
        if (!containerRef) return

        const computedStyles = window.getComputedStyle(containerRef)
        const gridTemplateColumns = computedStyles.getPropertyValue('grid-template-columns')
        const numberOfColumns = gridTemplateColumns.split(' ').length
        setColumns(numberOfColumns)
    }, [containerRef])

    const context = useMemo(() => {
        return { columns, setContainerRef }
    }, [columns, setContainerRef])

    const pageSize = useMemo(
        () => getPageSizeFromRows(view.options, columns),
        [view.options, columns]
    )

    return (
        <CardViewPaginationContext.Provider value={context}>
            <PaginationContextProvider pageSize={pageSize}>{children}</PaginationContextProvider>
        </CardViewPaginationContext.Provider>
    )
}

export const useCardViewPaginationContext = (): CardViewPaginationContextValue => {
    return useContext(CardViewPaginationContext)
}
