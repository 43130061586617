// @ts-strict-ignore
import React, { useContext, useMemo } from 'react'

import { BoxProps } from '@chakra-ui/react'

import { useAppContext } from 'app/AppContext'
import { AppUserContext } from 'app/AppUserContext'
import { TaskRelatedTo } from 'data/hooks/tasks/types'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import { TasksQuickCreatePopoverButton } from 'features/tasks/TasksQuickCreatePopoverButton'

import { Tooltip } from 'v2/ui'

type Config = {
    conditions: any
}

type DeleteButtonProps = BoxProps & {
    config: Config
    record: RecordDto
    tooltip: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ config, record, tooltip }) => {
    const { selectedStack } = useAppContext()
    const { role } = useContext(AppUserContext)
    const processFilter = useProcessFilter()
    // Note: need to re-filter upon role change
    const visible = useMemo(
        () => record && processFilter([record], config.conditions).length > 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [record, config.conditions, role, processFilter]
    )

    if (!visible) return null

    return (
        <Tooltip disabled={!tooltip} label={tooltip}>
            <TasksQuickCreatePopoverButton
                buttonProps={{
                    children: 'Add task',
                    startIcon: { name: 'CalendarCheck' },
                    variant: 'secondary',
                    size: 'm',
                    ml: 'm',
                }}
                related_to={record._sid}
                related_to_type={TaskRelatedTo.Record}
                related_to_stack={selectedStack?._sid}
            />
        </Tooltip>
    )
}
export default DeleteButton
