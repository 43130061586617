import React, { useCallback, useContext, useReducer } from 'react'

import shortid from 'shortid'

import { Toast } from './Toast'
import * as Parts from './Toast.parts'

const MAX_DISPLAYED_TOASTS = 10

type ToastProviderProps = React.PropsWithChildren<{
    viewportProps?: Partial<React.ComponentPropsWithoutRef<typeof Parts.Viewport>>
}>

export const ToastProvider: React.FC<ToastProviderProps> = ({ children, viewportProps }) => {
    const [state, dispatch] = useReducer(toastContextStateReducer, defaultContextState)

    const showToast = useCallback((props: ToastProps) => {
        dispatch({ type: 'showToast', payload: props })
    }, [])

    const handleOpenChange = useCallback(
        (toast: StateToast) => (open: boolean) => {
            if (open) return

            dispatch({ type: 'removeToast', id: toast.id })
        },
        []
    )

    return (
        <ToastContext.Provider value={{ ...state, showToast }}>
            <Parts.Provider swipeDirection="right">
                {children}
                {state.toasts.map((toast) => (
                    <Toast key={toast.id} {...toast} onOpenChange={handleOpenChange(toast)} />
                ))}
                <Parts.Viewport {...viewportProps} />
            </Parts.Provider>
        </ToastContext.Provider>
    )
}

export type ToastProps = Pick<
    React.ComponentPropsWithoutRef<typeof Toast>,
    'type' | 'title' | 'helperText' | 'startIcon' | 'showDismissButton' | 'onClick'
>

export const useToast = () => {
    const context = useContext(ToastContext)

    return context.showToast
}

type ToastContextShowToastAction = {
    type: 'showToast'
    payload: ToastProps
}

type ToastContextRemoveToastAction = {
    type: 'removeToast'
    id: string
}

type ToastContextAction = ToastContextShowToastAction | ToastContextRemoveToastAction

type StateToast = ToastProps & { id: string }

type ToastContextState = {
    toasts: StateToast[]
}

const defaultContextState: ToastContextState = {
    toasts: [],
}

function toastContextStateReducer(state: ToastContextState, action: ToastContextAction) {
    switch (action.type) {
        case 'showToast':
            if (state.toasts.length >= MAX_DISPLAYED_TOASTS) {
                return state
            }

            const newId = shortid.generate()

            return {
                ...state,
                toasts: [...state.toasts, { ...action.payload, id: newId }],
            }
        case 'removeToast':
            return {
                ...state,
                toasts: state.toasts.filter((toast) => toast.id !== action.id),
            }
        default:
            return state
    }
}

type ToastContext = ToastContextState & {
    showToast: (props: ToastProps) => void
}

const defaultContext: ToastContext = {
    ...defaultContextState,
    showToast: () => {},
}

const ToastContext = React.createContext(defaultContext)
