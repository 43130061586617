import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useDisclosure } from '@chakra-ui/react'

import { useLocalStorageState } from 'utils/useLocalStorageState'

type DisclosureState = {
    isOpen: boolean
    onClose: () => any
    onToggle: () => any
}

type SidebarState = 'open' | 'workspaceBarCollapsed' | 'fullyCollapsed'
export type NavContextType = {
    mobileDrawerState: DisclosureState
    sidebarState: SidebarState
    setSidebarState: (value: SidebarState) => void
    isPoppedOut: boolean
    setIsPoppedOut: React.Dispatch<React.SetStateAction<boolean>>
    hasMenuOpen: boolean
    setHasMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const NavContext = React.createContext<NavContextType>({} as NavContextType)

export const useNavContext = () => React.useContext(NavContext)

export const NavContextProvider: React.FC = ({ children }) => {
    const mobileDrawerState = useDisclosure()
    const [sidebarState, setSidebarState] = useLocalStorageState<SidebarState>('sidebar_state', {
        defaultValue: 'workspaceBarCollapsed',
    })
    const [isPoppedOut, setIsPoppedOut] = useState(false)
    const [hasMenuOpen, setHasMenuOpen] = useState(false)
    const location = useLocation()

    useEffect(() => {
        // When side bar state changes, reset popped state
        setIsPoppedOut(false)
    }, [sidebarState, location])

    const state = {
        mobileDrawerState,
        sidebarState,
        setSidebarState,
        isPoppedOut,
        setIsPoppedOut,
        hasMenuOpen,
        setHasMenuOpen,
    }

    return <NavContext.Provider value={state}>{children}</NavContext.Provider>
}
