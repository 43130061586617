import React, { useContext, useEffect, useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'
import { useGetLinkedFields } from 'v2/blocks/useGetLinkedFields'
import { AdvancedOptionsItem } from 'v2/views/List/AdvancedOptionsItem'
import DownloadOption from 'v2/views/List/DownloadOption'
import HideSearchBar from 'v2/views/List/HideSearchBar'
import { isRecordListDownloadAllowed } from 'v2/views/List/isRecordListDownloadAllowed'
import { isSearchBarHidden } from 'v2/views/List/isSearchBarHidden'

import AppContext from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import { useField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { useViews } from 'data/hooks/views'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import useTrack from 'utils/useTrack'

import { Box, Button, Collapse, Divider, Dropdown, Input, Radio, Text } from 'v2/ui'

import { shouldAllowAddNewButton } from './recordListEditorUtils'

const listTypes = [
    { value: 'all', label: 'include all records' },
    { value: 'related', label: 'include only related records' },
]

const fieldToOption = (field, objectName, isExternalField) => ({
    label: `${objectName} → ${field.label}`,
    value: field._sid,
    objectId: field.object_id,
    fieldId: field._sid,
    isExternalField,
})

const RecordListEditor = ({ context, setAttr, params: attrs }) => {
    const theme = useTheme()
    const { selectedStack: stack } = useContext(AppContext)
    const { data: views = [] } = useViews()

    const relationshipField = useField(attrs.fieldId || attrs?.field?.fieldId)

    // this selectedField object is what is selected in our dropdown
    // and holds all the info about the relationship
    const selectedField = useMemo(() => {
        // If we have a field object on our attrs, then we use that
        if (attrs.field) {
            return attrs.field
        }

        // If we don't have an attrs.field, then we are dealing with an old style
        // AT out-bound relationship config so we will build up the field option
        // object here.
        return relationshipField
            ? fieldToOption(relationshipField, context.object.name, false)
            : null
    }, [relationshipField, context.object.name, attrs])

    // Target object is either specified in the listObjectId attr,
    // or for older configs, we can get it by looking at the specified relationship
    // field--for local fields, it will be the link_target_object_id, for foreign fields, it will be
    // the field's object_id
    const targetObjectId = useMemo(
        () =>
            attrs?.listObjectId ||
            (relationshipField?.object_id === context.object._sid
                ? relationshipField?.link_target_object_id
                : relationshipField?.object_id),
        [attrs, relationshipField, context.object._sid]
    )

    const { object: targetObject } = useObject(targetObjectId)

    // If the selected relationship field is an AT relationship field, it may have
    // a corresponding symmetric field on the target object. We need to check this field
    // later on for validity.
    const symmetricField = useMemo(() => {
        if (
            !relationshipField ||
            !targetObject ||
            !relationshipField?.connection_options?.simpleconn_symmetric_column_id
        )
            return null

        return targetObject.fields.find((f) => {
            const simpleconn_match =
                f.connection_options.simpleconn_field &&
                f.connection_options.simpleconn_field ===
                    relationshipField.connection_options.simpleconn_symmetric_column_id

            return simpleconn_match
        })
    }, [relationshipField, targetObject])

    const allowAddNewButton = shouldAllowAddNewButton(
        attrs.listType,
        targetObject,
        selectedField,
        relationshipField,
        symmetricField
    )

    useEffect(() => {
        if (allowAddNewButton) return
        if (attrs.mayCreateRecords) setAttr('mayCreateRecords', false)
        if (attrs.displayCharts) setAttr('displayCharts', false)
    }, [allowAddNewButton, attrs.mayCreateRecords, attrs.displayCharts, setAttr])

    const [localLinkedFields, foreignLinkedFields] = useGetLinkedFields(
        context.object,
        targetObject
    )

    const availableFieldOptions = useMemo(
        () => [...localLinkedFields, ...foreignLinkedFields],
        [localLinkedFields, foreignLinkedFields]
    )

    const availableViewOptions = useMemo(
        () =>
            views
                .filter((view) => view.object_id === targetObjectId && view.type === 'list')
                .map((view) => ({
                    label: view.name,
                    value: view._sid,
                })),
        [views, targetObjectId]
    )

    useEffect(() => {
        // If there is only one relationship field available, select it by default to save the user a step
        if (!attrs.field && !attrs.fieldId && availableFieldOptions?.length === 1) {
            setAttr('field', availableFieldOptions[0])

            // Otherwise if there are no field options, and the user hasn't yet
            // tried to choose a list type, set it to "all"
        } else if (availableFieldOptions?.length === 0 && !attrs.listType) {
            setAttr('listType', 'all')
        }
    }, [availableFieldOptions, setAttr, attrs])

    const view = useMemo(() => {
        if (!attrs.viewId) return null
        if (!(targetObject && (attrs.listType === 'all' || relationshipField))) return null

        const view = views?.find((view) => view._sid === attrs.viewId && view.type === 'list')

        return view
    }, [attrs.viewId, views, attrs.listType, targetObject, relationshipField])

    const viewUrl = useMemo(() => (view ? getUrl(view.url, stack) : ''), [view, stack])

    // If there is only one view available, select it by default to save the user a step
    useEffect(() => {
        if (!attrs.viewId && availableViewOptions?.length === 1) {
            setAttr('viewId', availableViewOptions[0].value)
        }
    }, [availableViewOptions, setAttr, attrs])

    // when setting the target object, clear out the other settings
    const handleSetTargetObject = (id) => {
        setAttr('listObjectId', id)
        setAttr('field', null)
        setAttr('fieldId', null)
        setAttr('viewId', null)
        setAttr('listType', null)
    }
    const handleSetRelationshipField = (field) => {
        setAttr('field', field)
    }
    const handleSetListType = (type) => {
        setAttr('listType', type)
        setAttr('listObjectId', targetObjectId)
    }

    const allowDownload = isRecordListDownloadAllowed(attrs, view)

    const { track } = useTrack()

    const handleChangeAllowDownload = (isChecked) => {
        track(`Frontend - List View - Allow export CSV - ${isChecked ? 'On' : 'Off'}`, {
            table_name: targetObject.name,
        })

        setAttr('allowDownload', isChecked)
    }

    const hideSearch = isSearchBarHidden(attrs, view)

    return (
        <>
            <Text variant="adminFieldLabel" pt={4}>
                Show records from
            </Text>
            <ObjectPicker
                value={targetObjectId}
                onChange={handleSetTargetObject}
                placeholder="select table"
                isClearable={false}
            />

            <Collapse isOpen={!!targetObject}>
                <Box mt={4}>
                    <Radio
                        options={listTypes}
                        value={attrs.listType || 'related'}
                        size="md"
                        onChange={handleSetListType}
                    />
                </Box>
            </Collapse>
            <Collapse isOpen={targetObject && attrs.listType !== 'all'}>
                <Box mt={2}>
                    <Dropdown
                        value={selectedField}
                        options={availableFieldOptions}
                        menuProps={{
                            width: '300px',
                        }}
                        placeholder="select link field"
                        onChange={handleSetRelationshipField}
                        allowHorizontalScroll
                        returnObject
                        style={{
                            fontSize: theme.fontSizes.sm,
                        }}
                        isClearable={false}
                        adminTheme
                    />
                </Box>
            </Collapse>
            <Collapse isOpen={targetObject && (attrs.listType === 'all' || relationshipField)}>
                <Text variant="adminFieldLabel" pt={4}>
                    Layout
                </Text>
                <Dropdown
                    value={attrs.viewId}
                    options={availableViewOptions}
                    placeholder="select layout"
                    onChange={(value) => setAttr('viewId', value)}
                    menuProps={{
                        width: '300px',
                    }}
                    allowHorizontalScroll
                    style={{
                        fontSize: theme.fontSizes.sm,
                    }}
                    isClearable={false}
                    adminTheme
                />
                <Text variant="adminFieldLabel" pt={4}>
                    Title
                </Text>
                <Input
                    variant="admin"
                    size="sm"
                    placeholder="Title"
                    value={attrs.title}
                    onChange={(e) => setAttr('title', e.target.value)}
                />
            </Collapse>
            <Collapse isOpen={!!(targetObject && allowAddNewButton)}>
                <Divider mt={5} mb={6} />
                <Text variant="adminFieldLabel" pb={1}>
                    Additional options
                </Text>
                <AdvancedOptionsItem
                    label="Allow creating new records"
                    id="may-create-records"
                    onChange={(isChecked) => setAttr('mayCreateRecords', isChecked)}
                    value={attrs.mayCreateRecords}
                />

                {
                    <AdvancedOptionsItem
                        label="Display charts"
                        id="display-charts"
                        onChange={(isChecked) => setAttr('displayCharts', isChecked)}
                        value={attrs.displayCharts}
                        mt={2.5}
                    />
                }
                <HideSearchBar
                    onChange={(value) => setAttr('hide_search_bar', value)}
                    hideSearchBar={hideSearch}
                    mt={2.5}
                />
                <DownloadOption
                    mt={2.5}
                    value={allowDownload}
                    onChange={handleChangeAllowDownload}
                />
            </Collapse>

            {viewUrl && (
                <>
                    <Divider my={5} />
                    <Button
                        variant="linkNoUnderline"
                        href={viewUrl}
                        color="userInterface.accent.1200"
                        size="sm"
                        fontWeight="normal"
                    >
                        Edit the record list layout
                    </Button>
                </>
            )}
        </>
    )
}

export default RecordListEditor
