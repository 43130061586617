import { useCallback, useEffect, useMemo, useRef } from 'react'

import { ListViewUrlParams } from 'features/views/ListView/hooks/useListViewUrlParams'
import { useSyncListViewUrlParams } from 'features/views/ListView/hooks/useSyncListViewUrlParams'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { usePaginationContext } from 'features/views/ListView/Pagination/PaginationContext'

type UseTableViewPaginationOptions = {
    recordCount: number
    urlParams?: ListViewUrlParams
    isLoading?: boolean
}

export function useTableViewPagination(options: UseTableViewPaginationOptions) {
    const { recordCount, isLoading } = options
    const { urlParams } = useListViewContext()

    const urlParamsRef = useRef(urlParams)
    urlParamsRef.current = urlParams

    const { currentPageIndex, setCurrentPageIndex, pageSize } = usePaginationContext()!

    const onParamLoad = useCallback(
        (urlParams: ListViewUrlParams) => {
            const { pageIndex } = urlParams
            if (typeof pageIndex === 'undefined') return

            setCurrentPageIndex(pageIndex)
        },
        [setCurrentPageIndex]
    )
    const onParamSync = useCallback(
        (urlParams: ListViewUrlParams) => {
            urlParams.setParams({
                pageIndex: currentPageIndex,
            })
        },
        [currentPageIndex]
    )
    useSyncListViewUrlParams(onParamLoad, onParamSync)

    const pageCount = Math.ceil(recordCount / pageSize)

    useEffect(() => {
        // If the current page is out of bounds, reset it to the first page.
        if (!isLoading && currentPageIndex >= pageCount) {
            setCurrentPageIndex(0)
        }
    }, [currentPageIndex, setCurrentPageIndex, isLoading, pageCount])

    return useMemo(
        () => ({
            pageIndex: currentPageIndex,
            pageSize,
            setPageIndex: setCurrentPageIndex,
        }),
        [currentPageIndex, setCurrentPageIndex, pageSize]
    )
}
