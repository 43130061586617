import React, { useCallback, useMemo } from 'react'

import { useInlineCreateViewModal } from 'v2/views/Create/useInlineCreateViewModal'
import { useCreateQueryParams } from 'v2/views/utils/useCreateQueryParams'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/ListHeaderContext'

type ListHeaderAddNewButtonProps = {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export function useListHeaderAddNewButtonState({ onClick }: ListHeaderAddNewButtonProps) {
    const { object, isEmbedded, embeddedByField, embeddedByFieldValue, view } =
        useListHeaderContext()

    const redirectToList = view.options?.redirectToList
    const relatedListType = isEmbedded && !embeddedByField ? 'all' : undefined

    const queryParams = useCreateQueryParams(object, {
        isRecordList: isEmbedded,
        relatedFieldMayCreateNewRecords: true,
        redirectToList,
        relatedListType,
        relatedListSymmetricColumnName: embeddedByField?.api_name,
        autoFilledRelatedListRecord: embeddedByFieldValue,
    })

    const { toggle: toggleModal } = useInlineCreateViewModal({
        objectId: object._sid,
        queryParams,
    })

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            toggleModal()
            onClick?.(e)
        },
        [onClick, toggleModal]
    )

    return useMemo(
        () => ({
            onClick: handleClick,
        }),
        [handleClick]
    )
}
