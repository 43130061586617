import { useMemo } from 'react'

import { getCreateViewUrl } from 'v2/views/utils/getCreateViewUrl'

import { useAppContext } from 'app/AppContext'
import { getUrl } from 'app/UrlService'
import { useObjects } from 'data/hooks/objects'
import { usePages } from 'data/hooks/pages'
import { useStackViews } from 'data/hooks/views'

export const useInternalLink = (viewId: string | undefined) => {
    const { selectedStack, workspaceAccount } = useAppContext()

    const views = useStackViews() as ViewDto[]
    const { data: pages } = usePages() as { data: PageDto[] }
    const { data: objects } = useObjects()

    const view = useMemo(() => views.find((view) => view._sid === viewId), [viewId, views])
    return useMemo(() => {
        if (!view) {
            return null
        }
        if (view.type === 'create') {
            const object = objects?.find((o) => o._sid === view.object_id)
            return getCreateViewUrl(view, pages, object, undefined, selectedStack, workspaceAccount)
        } else {
            return getUrl(view.url, selectedStack, workspaceAccount)
        }
    }, [view, selectedStack, workspaceAccount, objects, pages])
}
