import React, { useMemo } from 'react'

import { DocumentViewEditorAdditionalActions } from 'v2/views/Document/DocumentViewEditorAdditionalActions'
import DocumentViewEditorABPanes from 'v2/views/Document/DocumentViewEditorPanes'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'
import useViewConfig from 'v2/views/utils/useViewConfig'

import { useDocument } from 'data/hooks/documents'
import { useDuplicateViewModal } from 'features/views/DuplicateViewModal'
import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'
import ViewEditPane from 'features/views/ViewEditPane'

import { LoadingSplash } from 'v2/ui'

import { DocumentEditor } from './DocumentEditor'
import { DocumentPageFrame } from './DocumentPageFrame'

type DocumentViewProps = {
    view: ViewDto
    page?: PageDto
    onError?: (error: unknown) => void
}

const DocumentView: React.FC<DocumentViewProps> = ({ view, page, onError }) => {
    useHistoryBreadcrumb({ title: view?.name, type: 'Document', objectId: view?.object_id })

    // Configure the view itself
    const {
        setViewData,
        saveConfig,
        isConfigDirty,
        viewState,
        setPageRoles,
        rolesDirty,
        pageRoles,
    } = useViewConfig(view, page)
    const isDirty = isConfigDirty || rolesDirty
    const showDuplicateViewModal = useDuplicateViewModal()
    const showConfirmDeleteModal = useConfirmDeleteViewModal() //remove cast when useViewConfig will be typed

    const { data, isLoading } = useDocument(view.options.document_id, undefined, {
        onError,
    })

    const patchedView: ViewDto = viewState.view as ViewDto
    const viewPaneActions = useMemo(
        () => [
            {
                action: () => showConfirmDeleteModal({ view: patchedView }),
                label: 'Delete Page',
            },
        ],
        [patchedView, showConfirmDeleteModal]
    )

    return (
        <DocumentPageFrame>
            {isLoading && <LoadingSplash />}
            {!isLoading && data && <DocumentEditor {...data} />}
            <ViewEditPane
                saveView={saveConfig}
                setViewData={setViewData}
                isConfigDirty={isDirty}
                viewName={patchedView.name}
                view={patchedView}
                additionalActions={
                    <DocumentViewEditorAdditionalActions
                        onDuplicatePage={() => showDuplicateViewModal({ view: patchedView })}
                    />
                }
                actions={viewPaneActions}
            >
                <DocumentViewEditorABPanes
                    view={patchedView}
                    onChange={setViewData}
                    pageRoles={pageRoles}
                    setPageRoles={setPageRoles}
                />
            </ViewEditPane>
        </DocumentPageFrame>
    )
}

export default DocumentView
