import React, { SVGProps } from 'react'

// import AutomaticRecordLinkConfiguration from '../AutomaticRecordLinkConfiguration'
import { ConfigurationComponentProps } from 'features/admin/fields/common'
import { CurrencyFieldConfiguration } from 'features/admin/fields/CurrencyFieldConfiguration'
import DropdownFieldConfiguration from 'features/admin/fields/DropdownFieldConfiguration'
import { FormulaFieldConfiguration } from 'features/admin/fields/FormulaFieldConfiguration'
import { LookupFieldConfiguration } from 'features/admin/fields/LookupFieldConfiguration'
import RecordLinkConfiguration from 'features/admin/fields/RecordLinkConfiguration'
import { RenderIcon } from 'features/admin/fields/RenderIcon'
import { RollupFieldConfiguration } from 'features/admin/fields/RollupFieldConfiguration'

import {
    Attachment,
    // AutomaticLink,
    Checkbox,
    Currency,
    Date,
    DateTime,
    Formula,
    Link,
    LongText,
    Lookup,
    MultipleSelect,
    Number,
    Percentage,
    RecordId,
    RichText,
    Select,
    ShortText,
    Url,
    UserIcon,
    View,
} from 'v2/ui/svgs/field-icons'

import {
    EditableFieldTypeDefinition,
    editableFieldTypeDefinitionMap,
} from './editableFieldTypeDefinitions'
import { CreatableFieldTypeKey } from './fieldTypeDefinitions'

type FieldTypeComponentDefinition = {
    icon: React.ReactNode
    iconComponent?: React.ComponentType<SVGProps<SVGSVGElement>>
    configurationComponent?: React.ComponentType<ConfigurationComponentProps>
}

export type FieldTypeComponentData = EditableFieldTypeDefinition & FieldTypeComponentDefinition

const fieldTypeComponentDefinitionMap: Record<CreatableFieldTypeKey, FieldTypeComponentDefinition> =
    {
        string: {
            icon: <RenderIcon Icon={ShortText} />,
            iconComponent: ShortText,
        },
        long_text: {
            icon: <RenderIcon Icon={LongText} />,
            iconComponent: LongText,
        },
        string_with_rich_text: {
            icon: <RenderIcon Icon={RichText} />,
            iconComponent: RichText,
        },
        document: {
            icon: <RenderIcon Icon={RichText} />,
            iconComponent: RichText,
        },
        number: {
            icon: <RenderIcon Icon={Number} />,
            iconComponent: Number,
        },
        lookup: {
            icon: <RenderIcon Icon={Link} />,
            iconComponent: Link,
            configurationComponent: RecordLinkConfiguration,
        },
        multi_lookup: {
            icon: <RenderIcon Icon={Link} />,
            iconComponent: Link,
            configurationComponent: RecordLinkConfiguration,
        },
        // relationship: {
        //     icon: <RenderIcon Icon={AutomaticLink} />,
        //     iconComponent: AutomaticLink,
        //     configurationComponent: AutomaticRecordLinkConfiguration,
        // },
        url: {
            icon: <RenderIcon Icon={Url} />,
            iconComponent: Url,
        },
        image: {
            icon: <RenderIcon Icon={Attachment} />,
            iconComponent: Attachment,
        },
        file: {
            icon: <RenderIcon Icon={Attachment} />,
            iconComponent: Attachment,
        },
        multi_file: {
            icon: <RenderIcon Icon={Attachment} />,
            iconComponent: Attachment,
        },
        date: {
            icon: <RenderIcon Icon={Date} />,
            iconComponent: Date,
        },
        datetime: {
            icon: <RenderIcon Icon={DateTime} />,
            iconComponent: DateTime,
        },
        checkbox: {
            icon: <RenderIcon Icon={Checkbox} />,
            iconComponent: Checkbox,
        },
        dropdown: {
            icon: <RenderIcon Icon={Select} />,
            iconComponent: Select,
            configurationComponent: DropdownFieldConfiguration,
        },
        multi_select: {
            icon: <RenderIcon Icon={MultipleSelect} />,
            iconComponent: MultipleSelect,
            configurationComponent: DropdownFieldConfiguration,
        },
        currency: {
            icon: <RenderIcon Icon={Currency} />,
            iconComponent: Currency,
            configurationComponent: CurrencyFieldConfiguration,
        },
        currency_varying: {
            icon: <RenderIcon Icon={Currency} />,
            iconComponent: Currency,
        },
        percentage: {
            icon: <RenderIcon Icon={Percentage} />,
            iconComponent: Percentage,
        },
        lookup_related_value: {
            icon: <RenderIcon Icon={Lookup} />,
            iconComponent: Lookup,
            configurationComponent: LookupFieldConfiguration,
        },
        rollup_related_records: {
            icon: <RenderIcon Icon={View} />,
            iconComponent: View,
            configurationComponent: RollupFieldConfiguration,
        },
        formula: {
            icon: <RenderIcon Icon={Formula} />,
            iconComponent: Formula,
            configurationComponent: FormulaFieldConfiguration,
        },
        created_at: {
            icon: <RenderIcon Icon={Date} />,
            iconComponent: Date,
        },
        updated_at: {
            icon: <RenderIcon Icon={Date} />,
            iconComponent: Date,
        },
        created_by: {
            icon: <RenderIcon Icon={UserIcon} />,
            iconComponent: UserIcon,
        },
        updated_by: {
            icon: <RenderIcon Icon={UserIcon} />,
            iconComponent: UserIcon,
        },
        record_id: {
            icon: <RenderIcon Icon={RecordId} />,
            iconComponent: RecordId,
        },
        autonumber: {
            icon: <RenderIcon Icon={Number} />,
            iconComponent: Number,
        },
    }

export const fieldTypeComponentList: FieldTypeComponentData[] = (
    Object.keys(fieldTypeComponentDefinitionMap) as CreatableFieldTypeKey[]
).map((key) => ({
    ...editableFieldTypeDefinitionMap[key],
    ...fieldTypeComponentDefinitionMap[key],
}))

export const toFieldTypeComponentData = (
    def: EditableFieldTypeDefinition
): FieldTypeComponentData => ({
    ...def,
    ...fieldTypeComponentDefinitionMap[def.value],
})
