import React, { useCallback, useContext, useMemo } from 'react'

import { primaryInput } from 'detect-it'

import {
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import {
    RecordEditManagerContextProvider,
    useRecordEditManagerContext,
} from 'features/records/RecordEditManagerContext'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { ListViewSort } from 'features/views/ListView/Sort/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useTableViewFilters } from './Filters/hooks/useTableViewFilters'
import { useColumnsFromView } from './hooks/useColumnsFromView'
import { TableViewCalculations, useTableViewCalculations } from './hooks/useTableViewCalculations'
import { useTableViewPagination } from './Pagination/hooks/useTableViewPagination'
import { useTableViewSearch } from './Search/hooks/useTableViewSearch'
import { useTableViewSort } from './Sort/hooks/useTableViewSort'
import { TableViewColumn } from './types'

type TableViewInternalContextValue = {
    columns: TableViewColumn[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]

    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    clearFilters: () => void
    requestFilters: Filter[]
    requestIncludedFields: string[]
    hasFilters: boolean
    isEmbedded: boolean
    isRecordActionsColumnPinned: boolean
    onRecordClick: Required<ListViewOptions['onRecordClick']>
    thumbnailImageField?: FieldDto
    thumbnailImageAspectRatio?: '1:1' | '4:3'
    calculations?: TableViewCalculations
    isCalculationsFetchingSlow: boolean
    isCalculationsError: boolean
    header: ListViewHeader
    showColumnIcons: boolean
}

export type TableViewContextValue = TableViewInternalContextValue & {
    pendingRecords: RecordDto[]
}

export const TableViewContext = React.createContext<TableViewInternalContextValue | null>(null)

export function useTableViewContext(): TableViewContextValue {
    const context = useContext(TableViewContext)

    if (!context) {
        throw new Error('useTableViewContext must be used within a TableViewContextProvider')
    }

    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = context.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!context.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    return useDeepEqualsMemoValue({
        ...context,
        isLoading,
        pendingRecords,
    })
}

export type TableViewContextProviderProps = {}

export const TableViewContextProvider: React.FC<TableViewContextProviderProps> = ({ children }) => {
    const { object, stack, isEmbedded, allFields, view, searchFields, header } =
        useListViewContext()

    const columns = useColumnsFromView()

    const thumbnailFieldApiName = view.options.coverImage?.id
    const additionalFieldApiNames = thumbnailFieldApiName ? [thumbnailFieldApiName] : undefined
    const onRecordClick = view.options.onRecordClick || 'preview'
    const disableRealtimeUpdates = view.options.disableRealtimeUpdates

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isLoadingSlow: isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
        disableRealtimeUpdates,
    })

    const { pageIndex, pageSize, setPageIndex } = useTableViewPagination({
        recordCount,
        isLoading,
    })

    const { sortBy, setSortBy, defaultSortBy } = useTableViewSort()
    const { query, setQuery } = useTableViewSearch()
    const { hasFilters: hasInlineFilters, clearFilters: clearInlineFilters } = useTableViewFilters()

    const hasFilters = !!query || hasInlineFilters
    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const clearFilters = useCallback(() => {
        setQuery('')
        clearInlineFilters()
    }, [setQuery, clearInlineFilters])

    // empty addRecord callback as table views don't need this
    const addRecord = useCallback(async (record: RecordDto) => record, [])

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const isDeviceTouchOnly = primaryInput === 'touch'
    const isRecordActionsColumnPinned = !isDeviceTouchOnly

    const thumbnailImageField = allFields.find((f) => f.api_name === thumbnailFieldApiName)
    const thumbnailImageAspectRatio = view.options.coverImage?.aspectRatio ?? '1:1'

    const viewMemo = useDeepEqualsMemoValue(view)

    const {
        calculations,
        isLoadingSlow: isCalculationsFetchingSlow,
        isError: isCalculationsError,
    } = useTableViewCalculations({
        stack,
        object,
        requestFilters,
        requestIncludedFields,
        requestSearchFields: searchFields,
        columns,
        sortBy,
    })

    const showColumnIcons = view.options.tableShowFieldIcons ?? false

    const value = useMemo(
        () => ({
            columns,
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            currentPageIndex: pageIndex,
            pageSize,
            setCurrentPageIndex: setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            isRecordActionsColumnPinned,
            onRecordClick,
            thumbnailImageField,
            thumbnailImageAspectRatio,
            view: viewMemo,
            calculations,
            isCalculationsFetchingSlow,
            isCalculationsError,
            header,
            showColumnIcons,
        }),
        [
            columns,
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            pageIndex,
            pageSize,
            setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            isRecordActionsColumnPinned,
            onRecordClick,
            thumbnailImageField,
            thumbnailImageAspectRatio,
            viewMemo,
            calculations,
            isCalculationsFetchingSlow,
            isCalculationsError,
            header,
            showColumnIcons,
        ]
    )

    return (
        <TableViewContext.Provider value={value}>
            <RecordEditManagerContextProvider
                records={records ?? []}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecords={deleteRecords}
            >
                {children}
            </RecordEditManagerContextProvider>
        </TableViewContext.Provider>
    )
}
