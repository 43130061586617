import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { ResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

type UseRecordLinksAttributeStateOptions = {
    attribute: CardViewAttribute
    value?: string | string[]
    isLoading?: boolean
    displayStyle?: ListViewOptions['boardFieldStyle']
}

const titleSizeMapping = {
    small: { mobile: 'm', tablet: 's' },
    medium: 'm',
    large: 'm',
}

export function useRecordLinksAttributeState(options: UseRecordLinksAttributeStateOptions) {
    const { value, isLoading, displayStyle, attribute } = options
    const { field, type } = attribute

    const isFooter = type === 'footer'

    const { dereferencedRecords, titleSize } = useCardViewContext()

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const isSingle = !Array.isArray(value) || value.length === 1

    const usersObject = useStackerUsersObject()
    const objectSid = field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    let displayAsTag = displayStyle === 'tags'
    if (isFooter && !isUsersObject) {
        displayAsTag = true
    }

    const size = useMemo(() => {
        switch (type) {
            case 'title':
                return titleSizeMapping[titleSize || 'medium'] as ResponsiveValue<'s' | 'm'>
            case 'eyebrow':
                return 's'
            default:
                return 'm'
        }
    }, [type, titleSize])

    return useMemo(
        () => ({
            dereferencedRecords,
            isEmpty,
            isSingle,
            displayAsTag,
            size,
        }),
        [dereferencedRecords, displayAsTag, isEmpty, isSingle, size]
    )
}
