import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'

import { useRecordEditManagerContext } from 'features/records/RecordEditManagerContext'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

import { CreateTaskModal } from './CreateTaskModal'
import { DeleteRecordConfirmModal } from './DeleteRecordConfirmModal'

export type ActionMenuContextValue = {
    deleteRecord: (recordSid: string) => void
    createTaskForRecord: (recordSid: string) => void
}

export const ActionMenuContext = React.createContext<ActionMenuContextValue | null>(null)

export function useActionMenuContext(): ActionMenuContextValue {
    const context = useContext(ActionMenuContext)

    if (!context) {
        throw new Error('useActionMenuContext must be used within a ActionMenuContextProvider')
    }

    return context
}

type ActionMenuContextProviderProps = {}

export const ActionMenuContextProvider: React.FC<ActionMenuContextProviderProps> = ({
    children,
}) => {
    const [deleteModalRecordSid, setDeleteModalRecordSid] = useState('')
    const isDeleteModalOpen = !!deleteModalRecordSid
    const onDeleteModalOpenChange = useCallback((open: boolean) => {
        if (!open) setDeleteModalRecordSid('')
    }, [])

    const [createTaskRecord, setCreateTaskRecord] = useState<RecordDto | undefined>()
    const isCreateTaskModalOpen = !!createTaskRecord
    const onCreateTaskModalOpenChange = useCallback((open: boolean) => {
        if (!open) setCreateTaskRecord(undefined)
    }, [])

    const { stack } = useListViewContext()
    const { records } = useRecordEditManagerContext()!
    const recordsRef = useRef(records)
    recordsRef.current = records

    const createTaskForRecord = useCallback((recordSid: string) => {
        const records = recordsRef.current
        const record = records.find((r) => r._sid === recordSid)
        if (record) setCreateTaskRecord(record)
    }, [])

    const value = useMemo(
        () => ({
            deleteRecord: setDeleteModalRecordSid,
            createTaskForRecord: createTaskForRecord,
        }),
        [createTaskForRecord]
    )

    return (
        <ActionMenuContext.Provider value={value}>
            {children}
            <DeleteRecordConfirmModal
                recordSid={deleteModalRecordSid}
                isOpen={isDeleteModalOpen}
                onOpenChange={onDeleteModalOpenChange}
            />
            <CreateTaskModal
                record={createTaskRecord}
                onOpenChange={onCreateTaskModalOpenChange}
                stackSid={stack._sid}
                isOpen={isCreateTaskModalOpen}
            />
        </ActionMenuContext.Provider>
    )
}
