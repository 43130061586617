import React, { useCallback, useRef, useState } from 'react'

export type DropdownContentContextValue = {
    searchQuery: string
    setSearchQuery: (value: string | ((prevValue: string) => string)) => void
    isSearchQueryControlled: boolean
    isSearchManaged: boolean
    searchInputElement: HTMLInputElement | null
    setIsSearchManaged: (value: boolean, searchInput?: HTMLInputElement) => void
}

export const DropdownContentContext = React.createContext<DropdownContentContextValue>({
    searchQuery: '',
    setSearchQuery: () => {},
    isSearchQueryControlled: false,
    isSearchManaged: false,
    searchInputElement: null,
    setIsSearchManaged: () => {},
})

export function useDropdownContentContext() {
    return React.useContext(DropdownContentContext)
}

export type DropdownContentContextProviderProps = {
    children: React.ReactNode
    searchQuery?: string
    setSearchQuery?: (value: string) => void
}

export const DropdownContentContextProvider: React.FC<DropdownContentContextProviderProps> = ({
    children,
    searchQuery: controlledSearchQuery,
    setSearchQuery: setControlledSearchQuery,
}) => {
    const [internalSearchQuery, setInternalSearchQuery] = useState('')

    const isControlled = typeof controlledSearchQuery !== 'undefined'
    const searchQuery = isControlled ? controlledSearchQuery! : internalSearchQuery
    const setSearchQuery = useCallback(
        (newValue: string | ((prev: string) => string)) => {
            if (isControlled) {
                const effectiveValue =
                    typeof newValue === 'function' ? newValue(controlledSearchQuery) : newValue
                setControlledSearchQuery?.(effectiveValue)
            } else {
                setInternalSearchQuery(newValue)
            }
        },
        [isControlled, controlledSearchQuery, setControlledSearchQuery]
    )

    const [searchInputElement, setSearchInputElement] = useState<HTMLInputElement | null>(null)
    const isSearchManaged = useRef(false)
    const setIsSearchManaged = useCallback((value: boolean, searchInput?: HTMLInputElement) => {
        isSearchManaged.current = value
        setSearchInputElement(searchInput ?? null)
    }, [])

    return (
        <DropdownContentContext.Provider
            value={{
                searchQuery,
                setSearchQuery,
                isSearchQueryControlled: isControlled,
                isSearchManaged: isSearchManaged.current,
                searchInputElement: searchInputElement,
                setIsSearchManaged,
            }}
        >
            {children}
        </DropdownContentContext.Provider>
    )
}
