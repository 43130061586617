import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Input = styled('input')`
    font-family: ${(props) => props.theme.fontFamily};
    color: ${(props) => props.theme.fontColor};
    font-size: ${(props) => props.theme.fontSize};
    background-color: ${(props) => props.theme.inputBackgroundColor};
    border-radius: ${(props) => props.theme.elementBorderRadius};
    padding: ${(props) => props.theme[props.padding || 'small']};
    border: ${(props) => props.theme.dividerThickness}px solid
        ${(props) => props.theme.outlineColor};

    &:focus {
        outline: none;
    }
    &:disabled {
        opacity: 0.9;
        cursor: not-allowed;
    }
`

Input.propTypes = {
    padding: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}
Input.displayName = 'Input'
export default Input
