import React, { useState } from 'react'

import styled from '@emotion/styled'

import IconPickerForm from 'features/workspace/forms/IconPicker'

import { Icon } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import Form from 'ui/deprecated/forms/Form'

const { colors } = stackerTheme()

const StyledIcon = styled(Icon)`
    margin-top: 2px;
    opacity: 1;
    transition: opacity 0.15s ease-out;
`

const EditBadge = styled.div`
    position: absolute;
    top: -10%;
    right: -10%;
    background: ${colors.userInterface.accent[800]};
    padding: 0.2rem;
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.15s ease-out;
`

const Wrapper = styled.div<{ color?: string }>`
    ${({ color }) =>
        color &&
        `
    background-color: ${color};
    border: 2px solid ${color};
    `}
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 1rem;
    cursor: pointer;
    position: relative;

    &:hover .edit-badge {
        opacity: 1;
    }

    &:hover .styled-icon {
        opacity: 0.6;
    }

    &:focus-visible {
        outline: none;
        border-color: ${colors.userInterface.accent[800]};
    }
`

type QuickLinksBlockEditorIconProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> & {
    value?: string
    onChange: (value: string) => void
    brandColor?: string
}

const QuickLinksBlockEditorIcon: React.FC<QuickLinksBlockEditorIconProps> = ({
    value,
    onChange,
    brandColor,
    ...props
}) => {
    const [isEditing, setIsEditing] = useState(false)

    return (
        <Wrapper
            aria-label="Edit link icon"
            color={brandColor}
            tabIndex={0}
            role="button"
            onClick={(e) => {
                e.stopPropagation()
                setIsEditing(!isEditing)
            }}
            onKeyDown={(e) => {
                if (isEditing) return

                if (e.key === ' ' || e.key === 'Enter') {
                    setIsEditing(true)
                }
            }}
            {...props}
        >
            {isEditing ? (
                <Form
                    options={{
                        mode: 'onChange',
                    }}
                    onSubmit={() => {}}
                >
                    <IconPickerForm
                        hideArrow={true}
                        width="inherit"
                        fontSize="inherit"
                        color="white"
                        border="none"
                        place="left"
                        usePortal={true}
                        name="icon"
                        margin="0"
                        bodyPadding="0"
                        item={{ options: { icon: value } }}
                        defaultIsOpen={true}
                        onClose={() => setIsEditing(false)}
                        onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
                        customOnChange={(e: React.FormEvent<HTMLFormElement>, newIcon: string) => {
                            e.stopPropagation()
                            onChange(newIcon)
                            setIsEditing(false)
                        }}
                    />
                </Form>
            ) : (
                <>
                    <EditBadge className="edit-badge" aria-hidden="true">
                        <Icon icon="fa-pencil" color="userInterface.accent.100" size="0.5rem" />
                    </EditBadge>
                    <StyledIcon className="styled-icon" icon={value} size="m" color="white" />
                </>
            )}
        </Wrapper>
    )
}

export default QuickLinksBlockEditorIcon
