import React, { useEffect, useState } from 'react'

import { PickerResponse } from 'filestack-js'

import { useAppUserContext } from 'app/AppUserContext'
import { useCreateTask } from 'data/hooks/tasks/tasks'
import { TaskDescription, TaskRelatedTo } from 'data/hooks/tasks/types'
import { AttachmentGallery } from 'features/attachments/AttachmentGallery'
import { useAttachments } from 'features/attachments/useAttachments'

import { Tooltip, useCtrlKey } from 'v2/ui'
import { useToast } from 'v2/ui/components/Toast'
import useShiftKey from 'v2/ui/components/useShiftKey'
import ReactFilestack from 'v2/ui/ReactFilestack'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'
import { Kbd } from 'ui/components/Kbd'

import { getBlobFromClipboardEvent } from './attachmentsUtils'
import { validateNewTask } from './quickCreateUtils'
import { useTaskListContext } from './TaskListContext'
import { TasksAssigneesEditor } from './TasksAssigneesEditor'
import { TasksDescriptionEditor } from './TasksDescriptionEditor'
import { TasksDueDateEditor } from './TasksDueDateEditor'
import { TaskAssignee } from './types'

export type TasksQuickCreateProps = React.ComponentPropsWithoutRef<typeof Container> & {
    onCreate?: (shouldDraftNew: boolean) => void
    related_to?: string
    related_to_type?: TaskRelatedTo
    related_to_stack?: string
    showRelatedToText?: string
    onClose?: () => void
    _object_id?: string
}

export const TasksQuickCreate: React.FC<TasksQuickCreateProps> = ({
    onCreate,
    showRelatedToText,
    onClose,
    related_to,
    related_to_stack,
    related_to_type,
    _object_id,
    ...props
}) => {
    const { user: currentUser } = useAppUserContext()
    const { createTask: createTaskOnList, emptyContext } = useTaskListContext()
    const currentUserId = currentUser?._sid

    const defaultAssignees = currentUserId ? [currentUserId] : []

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState<TaskDescription>({
        format: 'tiptap',
        content: undefined,
        plainTextContent: '',
    })
    const [dueDate, setDueDate] = useState('')
    const [assignees, setAssignees] = useState<TaskAssignee[]>(defaultAssignees)
    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)
    useEffect(() => {
        if (inputElement) {
            setTimeout(() => {
                inputElement.focus()
            }, 0)
        }
    }, [inputElement])

    const [validationMessage, setValidationMessage] = useState('')

    const toast = useToast()
    const { mutateAsync: createTask, isError, reset } = useCreateTask()

    const {
        attachments,
        uploadingAttachments,
        uploadAttachment,
        removeAttachment,
        clearAttachments,
        onAttachmentUploaded,
    } = useAttachments()

    const handleFilestackUpload = (data: PickerResponse) => {
        for (const file of data.filesUploaded) {
            onAttachmentUploaded(file.url, {
                filename: file.filename,
                mimetype: file.mimetype,
                size: file.size,
                url: file.url,
            })
        }
    }

    const resetForm = () => {
        setTitle('')
        setDescription({
            format: 'tiptap',
            content: undefined,
            plainTextContent: '',
        })
        clearAttachments()
    }

    const handleSubmit = async (shouldDraftNew: boolean) => {
        reset()

        if (!currentUserId) return Promise.resolve()

        const validationMessage = validateNewTask({
            title,
            description,
            dueDate,
            assignees,
            related_to: related_to ?? null,
        })
        setValidationMessage(validationMessage)

        if (validationMessage.length > 0) {
            return Promise.resolve()
        }

        const createFn = !emptyContext ? createTaskOnList : createTask
        createFn({
            userId: currentUserId,
            title,
            description: description as string,
            attachments,
            due_at: dueDate,
            assignees,
            related_to,
            related_to_type,
            related_to_stack,
            _object_id,
        })
            .then(() => {
                toast({ title: 'Task added successfully.', status: 'success' })
            })
            .catch(() => {
                toast({ title: 'Task failed to create.', status: 'error' })
            })

        resetForm()

        inputElement?.focus()

        onCreate?.(shouldDraftNew)
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            const shouldDraftNew = e.shiftKey
            handleSubmit(shouldDraftNew)
        } else if (e.key === 'Escape') {
            onClose?.()
            e.preventDefault()
        }
    }

    const handlePaste = (e: React.ClipboardEvent) => {
        const blob = getBlobFromClipboardEvent(e)
        if (blob) uploadAttachment(blob)
    }

    const isSubmitButtonDisabled = uploadingAttachments.length > 0

    return (
        <Container p="l" {...props}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(false)
                }}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
            >
                {showRelatedToText && (
                    <Box flex center mb="m">
                        <Box as="span" color="textWeak" mr="xs" noShrink>
                            New task for
                        </Box>
                        <Box as="span" bold shrink trim>
                            {showRelatedToText}
                        </Box>
                    </Box>
                )}
                <Input
                    ref={setInputElement}
                    width="full"
                    variant="borderless"
                    placeholder="Task name"
                    fontSize="bodyL"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoFocus
                    mb="m"
                />
                <TasksDescriptionEditor value={description} onChange={setDescription} grow />
                <AttachmentGallery
                    attachments={attachments}
                    onDeleteAttachment={removeAttachment}
                    uploadingAttachments={uploadingAttachments}
                    onUploaded={onAttachmentUploaded}
                    size="md"
                    mt="l"
                />
                <Box flex justifyContent="space-between" mt="xs" wrap gap="m">
                    <Box flex center gap="m" grow noShrink>
                        <TasksAssigneesEditor
                            compact
                            value={assignees}
                            onChange={setAssignees}
                            currentUserId={currentUserId}
                            related_to_stack={related_to_stack}
                        />
                        <TasksDueDateEditor value={dueDate} onChange={setDueDate} />
                        <Box>
                            <ReactFilestack
                                options={{
                                    maxFiles: 5,
                                }}
                                onSuccess={handleFilestackUpload}
                                customRender={({ onPick }) => {
                                    return (
                                        <Button
                                            variant="secondary"
                                            size="s"
                                            startIcon={{ name: 'Paperclip' }}
                                            onClick={onPick}
                                            noShrink
                                            type="button"
                                        />
                                    )
                                }}
                            />
                        </Box>
                    </Box>
                    <Box flex gap="m" grow noShrink justifyContent="flex-end">
                        <Button variant="ghost" onClick={onClose} type="button" size="s">
                            Cancel
                        </Button>
                        <Tooltip
                            disabled={isSubmitButtonDisabled}
                            placement="bottom"
                            label={<TasksQuickCreateSubmitTooltip />}
                            maxWidth="500px"
                        >
                            <Button
                                type="submit"
                                disabled={isSubmitButtonDisabled}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleSubmit(false)
                                }}
                                size="s"
                            >
                                Add task
                            </Button>
                        </Tooltip>
                    </Box>
                </Box>
            </form>

            {isError && (
                <Banner
                    icon={{
                        name: 'AlertTriangle',
                    }}
                    mt="xl"
                    title="There was a problem creating your task"
                    type="error"
                />
            )}

            {validationMessage.length > 0 && (
                <Banner
                    icon={{
                        name: 'AlertTriangle',
                    }}
                    mt="xl"
                    title={validationMessage}
                    type="warning"
                />
            )}
        </Container>
    )
}

type TasksQuickCreateSubmitTooltipProps = React.ComponentPropsWithoutRef<typeof Box> & {}

const TasksQuickCreateSubmitTooltip: React.FC<TasksQuickCreateSubmitTooltipProps> = (props) => {
    const modifierKey = useCtrlKey()
    const shiftKey = useShiftKey()

    return (
        <Box fontSize="bodyS" {...props}>
            <Box>
                <Kbd>{modifierKey}</Kbd> + <Kbd>Enter</Kbd> to save task
            </Box>
            <Box mt="m">
                <Kbd>{modifierKey}</Kbd> + <Kbd>{shiftKey}</Kbd> + <Kbd>Enter</Kbd> to save and
                draft new
            </Box>
        </Box>
    )
}
