import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Progress } from 'ui/components/Progress'
import { Tag } from 'ui/components/Tag'
import { Body, Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

import { RequirementSchema } from './bundles/zodSchema'
import { getChatCompletion } from './chatUtils/chatApi'
import { zodToOpenAiResponseFormat } from './chatUtils/zodUtils'

type Step1DescribeProps = {
    description: string
    clearbitContext: string
    setDescription: (description: string) => void
    onMoveNext: (result: string[]) => void
    failed: boolean
    setFailed: (failed: boolean) => void
}

export function Step1Describe({
    description,
    clearbitContext,
    setDescription,
    onMoveNext,
    failed,
    setFailed,
}: Step1DescribeProps) {
    async function generateRequirements() {
        if (failed) {
            onMoveNext([])
            return
        }
        console.log('description', description)
        try {
            const response = await getChatCompletion(
                [
                    {
                        role: 'system',
                        content:
                            'You are an expert database architect. Generate the key entities that require dedicated tables that will meet the requirements of the user. Only generate the most important entities, probably less than 5. Use only 6-10 words to describe each entity. Assume general platform functionality (logging in, users etc) is already taken care of. ' +
                            clearbitContext,
                    },
                    { role: 'user', content: description },
                ],
                undefined,
                zodToOpenAiResponseFormat(RequirementSchema, 'RequirementsSchema')
            )

            const result = JSON.parse(response.content ?? '[]')
            onMoveNext(result.requirements)
        } catch (ex) {
            console.error(ex)
            setFailed(true)
        }
    }

    return (
        <>
            <Box flex width="full">
                <Progress value={25} style={{ width: '100%' }}>
                    Stacker AI Builder
                </Progress>
            </Box>

            <Box flex column pt="5xl" style={{ flexGrow: 1 }}>
                <Headline marginBottom="s" size="l">
                    What are you building?
                </Headline>
                <Body>
                    Tell us about your use-case so that our AI builder can create your app for you.
                </Body>
                <Headline mt="3xl" size="2xs">
                    About this project
                </Headline>
                <Box my="l" gap="s" flex wrap>
                    <Tag
                        size="s"
                        onClick={() => setDescription('A custom CRM to help me track my sales')}
                    >
                        Sales CRM
                    </Tag>
                    <Tag
                        size="s"
                        onClick={() =>
                            setDescription('A way to track maintenance requests from my tenants')
                        }
                    >
                        Work tracker
                    </Tag>
                    <Tag
                        size="s"
                        onClick={() =>
                            setDescription(
                                'A self-serve portal for my customers to see their order history'
                            )
                        }
                    >
                        Customer portal
                    </Tag>
                    <Tag
                        size="s"
                        onClick={() =>
                            setDescription(
                                'A way to keep track the tasks in my construction project'
                            )
                        }
                    >
                        Project management
                    </Tag>
                    <Tag
                        size="s"
                        onClick={() =>
                            setDescription('An order management system for our custom bike orders')
                        }
                    >
                        Order tracker
                    </Tag>
                    <Tag
                        size="s"
                        onClick={() =>
                            setDescription(
                                'A secure dashboard for our partners to submit work requests'
                            )
                        }
                    >
                        Partner dashboard
                    </Tag>
                </Box>
                <Textarea
                    placeholder="An app to manage my dog grooming business"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size="l"
                />
            </Box>
            <Button onClick={generateRequirements} alignSelf="flex-end" disabled={failed}>
                Next
            </Button>
        </>
    )
}
