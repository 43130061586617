import React from 'react'
import { Link } from 'react-router-dom'

import { Menu, MenuButton, MenuDivider, MenuGroup, MenuList, Portal } from '@chakra-ui/react'

import { useAccountUserContext } from 'app/AccountUserContext'
import settings from 'app/settings'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { withAccounts } from 'data/wrappers/WithAccounts'
import { withUser } from 'data/wrappers/WithUser'
import { isPortalRole } from 'features/auth/utils/roleUtils'
import { openProfileSettingsModal } from 'features/profile/ProfileSettingsModal'

import { Button, Flex, Icon, MenuItem, ScrollBox, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { useCreateWorkspaceModal } from './CreateWorkspaceModal'

const _UserMenu = ({
    accounts,
    workspaceAccount,
    children,
    maxBoxHeight = '60vh',
    as,
    ...props
}) => {
    const { isOnPortalDomain } = useWorkspaceContext()
    const { role: accountRole } = useAccountUserContext()
    const { show: showCreateWorkspaceModal } = useCreateWorkspaceModal()
    const showWorkspaces = !isOnPortalDomain && (accounts?.length !== 1 || accountRole)

    const canCreateWorkspaces = accountRole && !isPortalRole(accountRole)

    return (
        <Menu>
            <MenuButton as={as || Button} {...props}>
                {children}
            </MenuButton>

            <Portal>
                <MenuList zIndex={9999} position="relative">
                    <MenuGroup
                        title={
                            showWorkspaces && (
                                <Flex
                                    className={STYLE_CLASSES.USER_MENU_WORKSPACES_TITLE}
                                    textAlign="left"
                                >
                                    <Text flexGrow={1}>Workspaces</Text>
                                    {canCreateWorkspaces && (
                                        <Icon
                                            button
                                            icon="add"
                                            size="sm"
                                            color="gray.400"
                                            onClick={showCreateWorkspaceModal}
                                            outerClassName={
                                                STYLE_CLASSES.USER_MENU_WORKSPACES_ADD_NEW
                                            }
                                            data-testid="user-menu.add-workspace"
                                        />
                                    )}
                                </Flex>
                            )
                        }
                    >
                        {showWorkspaces && (
                            <>
                                <ScrollBox
                                    className={STYLE_CLASSES.USER_MENU_WORKSPACES}
                                    style={{ maxHeight: maxBoxHeight }}
                                >
                                    {accounts.map((x) => {
                                        let url = `${settings.STUDIO_URL}/${x.slug}`

                                        // If SSO is required, we serve this workspace at either the custom URL
                                        // or the base URL (subdomain on the studio domain)
                                        if (x.sso_required) {
                                            if (x.custom_base_url) {
                                                url = `https://${x.custom_base_url}`
                                            } else {
                                                url = `https://${x.base_url}`
                                            }
                                        }

                                        return (
                                            <MenuItem key={x._sid} as="a" href={url}>
                                                <Icon
                                                    icon={x === workspaceAccount && 'check'}
                                                    mr={2}
                                                    width="1em"
                                                />
                                                {x.name || x._sid}
                                            </MenuItem>
                                        )
                                    })}
                                </ScrollBox>
                                <MenuDivider color="gray.300" />
                            </>
                        )}
                        {!isOnPortalDomain && (
                            <MenuItem
                                className={STYLE_CLASSES.USER_MENU_SETTINGS}
                                icon="avatar"
                                onClick={openProfileSettingsModal}
                            >
                                My Settings
                            </MenuItem>
                        )}
                        <MenuItem
                            className={STYLE_CLASSES.USER_MENU_LOGOUT}
                            icon="signOut"
                            as={Link}
                            to="/logout"
                        >
                            Log Out
                        </MenuItem>
                    </MenuGroup>
                </MenuList>
            </Portal>
        </Menu>
    )
}
export const UserMenu = withUser(withAccounts(_UserMenu))
