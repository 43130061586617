import { getIsRelationshipField, getIsSymmetricRelationshipField } from 'utils/fieldUtils'

/**
 * Links are currently implemented as a view on-top of our existing fields.
 * To create a list of links, we collect all the relationship fields
 * and match them up with their symmetric field. We join these together into
 * a single "link" view
 * @param {any[]} fields
 * @returns {Link[]}
 */

export function getLinks(fields) {
    if (!fields) return []
    const linkFields = fields.filter(isLink)
    const sourceLinkFields = linkFields.filter(isSourceLink)
    const symmetricLinksByLinkId = fields.filter(getIsSymmetricRelationshipField).reduce(
        (acc, field) => ({
            ...acc,
            [field.connection_options.relationship_synthetic_lookup_field]: field,
        }),
        {}
    )
    const links = sourceLinkFields
        .reduce((acc, field) => {
            return [...acc, buildLink(symmetricLinksByLinkId, field)]
        }, [])
        .filter(Boolean)
    return links
}

export function getLinksFromObject(fields, userObjectId) {
    const links = getLinks(fields)
    const userLinks = links.filter((link) => link.source.objectId === userObjectId)

    return userLinks
}

/**
 * The links above only contains IDs referenced to related data.
 * This link view joins and materializes those references into a denormalised
 * data structure containing related names, icons etc. more suitable for displaying
 * in the UI
 * @param {any[]} fields
 * @param {object} data
 * @returns {LinkView[]}
 */
export function getLinkViews(fields, data) {
    const links = getLinks(fields)
    const factory = buildLinkView.bind(null, data)
    return links.map(factory).filter(Boolean)
}

/**
 *
 * @param {*} field
 * @returns {Link}
 */
function buildLink(symmetricLookupById, field) {
    const symmetric = symmetricLookupById[field._sid] ?? null
    const getMode = (field) => (field.type === 'lookup' ? 'one-to-many' : 'many-to-many')
    try {
        return {
            apiName: field.api_name,
            linkId: field._sid,
            name: field.label,
            mode: getMode(field),
            delimiter: field.connection_options.relationship_local_lookup_field_delimiter,
            filters: field.connection_options.relationship_target_lookup_filters ?? [],
            source: {
                objectId: field.object_id,
                fieldId: field.connection_options.relationship_local_lookup_field,
            },
            target: {
                objectId: field.link_target_object_id,
                fieldId: field.connection_options.relationship_target_lookup_field,
            },
            symmetric: symmetric
                ? {
                      objectId: symmetric.object_id,
                      fieldId: symmetric._sid,
                  }
                : null,
        }
    } catch (error) {
        console.warn(`Failed to construct link`, error)
        return null
    }
}

function buildLinkView({ connectionByObjectId, objectsById, fieldsById }, link) {
    const sourceConnection = connectionByObjectId[link.source.objectId]
    const targetConnection = connectionByObjectId[link.target.objectId]
    const targetObject = objectsById[link.target.objectId]
    try {
        return {
            ...link,
            source: {
                ...link.source,
                connectionId: sourceConnection._sid,
                connectionName: sourceConnection.label,
                connectionType: sourceConnection.type,
                ...(sourceConnection.service_name
                    ? { connectionServiceName: sourceConnection.service_name }
                    : {}),
                objectName: objectsById[link.source.objectId]?.name,
                fieldName: fieldsById[link.source.fieldId]?.label,
            },
            target: {
                ...link.target,
                connectionId: targetConnection._sid,
                connectionName: targetConnection.label,
                connectionType: targetConnection.type,
                ...(targetConnection.service_name
                    ? { connectionServiceName: targetConnection.service_name }
                    : {}),
                objectName: targetObject.name,
                fieldName: fieldsById[link.target.fieldId]?.label,
            },
            symmetric: link.symmetric
                ? {
                      ...link.symmetric,
                      connectionId: targetConnection._sid,
                      connectionName: targetConnection.label,
                      connectionType: targetConnection.type,
                      objectName: targetObject.name,
                      fieldName: fieldsById[link.symmetric.fieldId]?.label,
                  }
                : null,
        }
    } catch (error) {
        console.warn(
            `Failed to construct link view`,
            connectionByObjectId,
            objectsById,
            fieldsById,
            error
        )
        return null
    }
}

function isLink(field) {
    return field.type === 'lookup' || field.type === 'multi_lookup'
}

function isSourceLink(field) {
    return isLink(field) && getIsRelationshipField(field)
}
