import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/ListHeaderContext'
import { isCmdOrCtrlPressed } from 'utils/isCmdOrCtrlPressed'

import useDebounce from 'v2/ui/utils/useDebounce'

const SEARCH_DEBOUNCE_RATE = 1000 // 1 second

type UseListHeaderSearchControlStateOptions = {
    setShowRecordCount?: (showRecordCount: boolean) => void
}

export function useListHeaderSearchControlState(options: UseListHeaderSearchControlStateOptions) {
    const { setShowRecordCount } = options

    const { searchQuery, setSearchQuery, isEmbedded, allowSearch } = useListHeaderContext()

    const [showInput, setShowInput] = useState(false)
    const showInputRef = useRef(showInput)
    showInputRef.current = showInput

    const onSearchButtonClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        setShowInput(true)
    }, [])

    const [inputSearchQuery, setInputSearchQuery] = useState(searchQuery)
    const inputSearchQueryRef = useRef(inputSearchQuery)
    inputSearchQueryRef.current = inputSearchQuery

    const isInputFocused = useRef(false)

    const onInputFocus = useCallback(() => {
        isInputFocused.current = true
    }, [])

    const onInputBlur = useCallback(() => {
        isInputFocused.current = false

        queueMicrotask(() => {
            const inputSearchQuery = inputSearchQueryRef.current
            if (!inputSearchQuery.trim()) {
                setShowInput(false)
            }
        })
    }, [])

    useEffect(() => {
        // Sync global search state with the input search query, if the input is not focused.
        const isFocused = isInputFocused.current
        if (!isFocused) {
            setInputSearchQuery(searchQuery)
            // Also hide input if the search query is empty.
            if (!searchQuery) {
                setShowInput(false)
            }
        }
    }, [searchQuery])

    useEffect(() => {
        // Show input if it's hidden and there is a search query set.
        if (!searchQuery) return

        const showInput = showInputRef.current
        if (showInput) return

        setShowInput(true)
    }, [searchQuery])

    // Apply search to the list header context.
    const debounceApplySearch = useDebounce(setSearchQuery, SEARCH_DEBOUNCE_RATE)

    const handleSetSearchQuery = useCallback(
        (value: string) => {
            setInputSearchQuery(value)
            debounceApplySearch(value)
        },
        [debounceApplySearch]
    )

    const onInputClear = useCallback(() => {
        handleSetSearchQuery('')
        setShowInput(false)
    }, [handleSetSearchQuery])

    const inputRef = useRef<HTMLInputElement>(null)

    useLayoutEffect(() => {
        if (isEmbedded || !allowSearch) return

        // Switch to the input when the user presses 'CMD+F' or 'CTRL+F'.
        const handleKeyboardEvent = (e: KeyboardEvent) => {
            if (e.key === 'f' && isCmdOrCtrlPressed(e)) {
                e.preventDefault()
                setShowInput(true)

                queueMicrotask(() => {
                    inputRef.current?.focus()
                })
            }
        }

        window.addEventListener('keydown', handleKeyboardEvent)
        return () => window.removeEventListener('keydown', handleKeyboardEvent)
    }, [isEmbedded, allowSearch])

    useLayoutEffect(() => {
        // Hide the record count when the input is shown.
        setShowRecordCount?.(!showInput)
    }, [showInput, setShowRecordCount])

    return useMemo(
        () => ({
            showInput,
            onSearchButtonClick,
            inputSearchQuery,
            setInputSearchQuery: handleSetSearchQuery,
            onInputBlur,
            onInputFocus,
            onInputClear,
            allowSearch,
            inputRef,
        }),
        [
            onSearchButtonClick,
            inputSearchQuery,
            showInput,
            onInputBlur,
            onInputFocus,
            handleSetSearchQuery,
            onInputClear,
            allowSearch,
        ]
    )
}
