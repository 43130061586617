// @ts-strict-ignore
import React, { FC, useState } from 'react'

import styled from '@emotion/styled'
import { isEmpty } from 'lodash'

import { useDefaultFields } from 'data/hooks/fields'
import { createObject } from 'data/hooks/objects/objectOperations'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import useTrack from 'utils/useTrack'

import { Button, Checkbox, Collapse, Flex, Icon, Input, Modal, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'
import useModalToggle, { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

const MODAL_KEY = 'NewNativeTable'
const { colors } = stackerTheme()

/**
 *
 * @returns
 */
export const useNewNativeTableModal = () => {
    return useModalDeclaration(MODAL_KEY, NewNativeTableModal)
}

const NewNativeTableModal: FC = () => {
    const { isOpen, toggle, data, setData } = useModalToggle(MODAL_KEY)
    const [tableName, setTableName] = useState<string | undefined>()
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>()
    const [tableNameError, setTableNameError] = useState(false)
    const { data: defaultFields } = useDefaultFields()
    const [fields, setFields] = useState<Array<Partial<FieldDto> | undefined>>(defaultFields)
    const [generateSampleContent, setGenerateSampleContent] = useState<boolean>(true)

    const { track } = useTrack()

    const handleSave = async (): Promise<void> => {
        track('WIP - Frontend - New Table Modal - Save Button - Clicked')

        if (!tableName || tableName.length === 0) {
            setTableNameError(true)
            return
        }

        setIsSaving(true)

        try {
            let object
            let objectSids: string[] = []
            object = await createObject({
                name: tableName,
                fields: fields,
                no_default_records: !generateSampleContent,
            })

            cleanup()

            await refetchObjects()
            // @ts-expect-error
            if (data?.onObjectCreated) {
                // @ts-expect-error
                await data.onObjectCreated(tableName, object?._sid || objectSids?.[0])
            }
            // don't close the model until we've refetched the objects
            toggle()

            // @ts-expect-error
            if (data?.onConfirm) {
                // @ts-expect-error
                await data.onConfirm(tableName, object)
            }
            // @ts-expect-error
            setData({ success: true, tableName, ...data })
        } catch (error) {
            console.error(error)
            setError('Unable to create table')
        }

        setIsSaving(false)
    }

    const cleanup = () => {
        setTableName(undefined)
        setFields(defaultFields)
        setError(undefined)
        setTableNameError(false)
    }

    const onCancel = (): void => {
        track('WIP - Frontend - New Table Modal - Cancel Button - Clicked')
        cleanup()
        toggle()
    }

    const onKeyPress = (event: KeyboardEvent): void => {
        if (event.key !== 'Enter') {
            return
        }

        if (isEmpty(tableName)) {
            setTableNameError(true)
            return
        }

        handleSave()
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onCancel}
                errorMessage={error}
                isSecondLayer
                data-testid="new-table-modal"
                noDividers
            >
                <Flex
                    style={{ width: '100%' }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    marginTop="-0.75rem"
                >
                    <Flex style={{ width: '100%' }} alignItems="top">
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                width: '100%',
                            }}
                        >
                            <Icon
                                icon="tableLight"
                                color={colors.userInterface.neutral[600]}
                                size="32px"
                                mr={2}
                            />
                            <Text
                                my={2}
                                textAlign="center"
                                variant="heading"
                                style={{
                                    color: colors.userInterface.neutral[1000],
                                }}
                            >
                                Name your table
                            </Text>
                            <Input
                                data-testid="new-table-form.table-name"
                                style={{
                                    width: '100%',
                                    height: '36px',
                                    border: `solid 1px ${colors.userInterface.neutral[400]}`,
                                }}
                                flexGrow={1}
                                placeholder="e.g. Customers, Products, Events, Projects…"
                                value={tableName}
                                onChange={(e) => setTableName(e.target.value)}
                                disabled={isSaving}
                                autoFocus
                                mb={3}
                                className={`${ONBOARDING_CLASSES.CREATE_TABLE_MODAL.TABLE_NAME} ${
                                    tableNameError ? 'invalid' : ''
                                }`}
                                onKeyPress={onKeyPress}
                            />
                            <Collapse isOpen={tableNameError}>
                                <ErrorText>Please enter a table name</ErrorText>
                            </Collapse>
                            <Checkbox
                                isChecked={generateSampleContent}
                                mb={2}
                                ml={1}
                                onChange={(e) => setGenerateSampleContent(e.target.checked)}
                                isDisabled={isSaving}
                            >
                                <Text variant="checkbox">Generate sample content</Text>
                            </Checkbox>
                        </div>
                    </Flex>
                </Flex>
                <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flexGrow={1}
                    mt={1}
                >
                    <Button
                        onClick={onCancel}
                        variant="Tertiary"
                        color="userInterface.neutral.850"
                        buttonSize="medium"
                        flex={1}
                        mr={1}
                    >
                        Cancel
                    </Button>
                    <Button
                        ml={1}
                        onClick={handleSave}
                        isLoading={isSaving}
                        data-testid="new-table-modal.save-button"
                        className={ONBOARDING_CLASSES.CREATE_TABLE_MODAL.SAVE_BUTTON}
                        variant="Primary"
                        buttonSize="medium"
                        flex={1}
                    >
                        Create table
                    </Button>
                </Flex>
            </Modal>
        </>
    )
}

const ErrorText = styled(Text)`
    font-size: 12px;
    color: ${colors.userInterface.error[600]};

    margin-bottom: 10px;
`
