import React, { useCallback, useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { GlobalStaticState } from 'app/GlobalStaticState'
import { fetchImpersonatedUser } from 'data/api/userApiNew'

import { PreviewServiceContext } from './PreviewServiceContext'
import { PreviewServiceStorage } from './PreviewServiceStorage'

export const PreviewServiceContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { selectedStack } = useAppContext()
    const [previewingAsUser, setPreviewingAsUserState] = useState<UserDto | undefined>()
    const [previewingAsRoleApiName, setPreviewingAsRoleState] = useState<string | undefined>()
    const isPreviewingAsUserOrRole = !!previewingAsUser || !!previewingAsRoleApiName

    const setPreviewingAsUser = useCallback((user: UserDto | undefined) => {
        GlobalStaticState.setPreviewingAsUser(user)
        PreviewServiceStorage.setPreviewingUser(user)
        PreviewServiceStorage.setLastPreviewingUser(user)
        setPreviewingAsUserState(user)
    }, [])

    const setPreviewingAsRole = useCallback((roleApiName: string | undefined) => {
        GlobalStaticState.setPreviewingAsRole(roleApiName)
        PreviewServiceStorage.setPreviewingRoleApiName(roleApiName)
        PreviewServiceStorage.setLastPreviewingRoleApiName(roleApiName)
        setPreviewingAsRoleState(roleApiName)
    }, [])

    const stackId = selectedStack?._sid
    useEffect(() => {
        const user = PreviewServiceStorage.getPreviewingUser()
        const roleId = PreviewServiceStorage.getPreviewingRoleApiName()

        setPreviewingAsUser(user)
        setPreviewingAsRole(roleId)
    }, [setPreviewingAsRole, setPreviewingAsUser, stackId])

    const stopPreviewing = useCallback(async () => {
        setPreviewingAsUser(undefined)
        setPreviewingAsRole(undefined)
    }, [setPreviewingAsRole, setPreviewingAsUser])

    const previewAsUser = useCallback(
        async (userId: string): Promise<UserDto> => {
            const newUser = await fetchImpersonatedUser(userId)

            setPreviewingAsUser(newUser)
            setPreviewingAsRole(undefined)

            return newUser
        },
        [setPreviewingAsRole, setPreviewingAsUser]
    )

    const previewAsRole = useCallback(
        async (roleId: string) => {
            setPreviewingAsRole(roleId)
            setPreviewingAsUser(undefined)
        },
        [setPreviewingAsRole, setPreviewingAsUser]
    )

    return (
        <PreviewServiceContext.Provider
            value={{
                stopPreviewing,
                previewAsUser,
                previewAsRole,
                isPreviewingAsUserOrRole,
                previewingAsUser,
                previewingAsRoleApiName,
            }}
        >
            {children}
        </PreviewServiceContext.Provider>
    )
}
