import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'

export function useTableViewHeadingState() {
    const { columns, isRecordActionsColumnPinned, showColumnIcons } = useTableViewContext()

    return useMemo(
        () => ({
            columns,
            isRecordActionsColumnPinned,
            showColumnIcons,
        }),
        [columns, isRecordActionsColumnPinned, showColumnIcons]
    )
}
