import React, { useContext } from 'react'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useLayoutEditorContext } from './LayoutEditorContext'
import { useLayoutEditorWidgetContext } from './LayoutEditorWidgetContext'
import { LayoutEditorSchema, Widget } from './types'
import { getWidgetAreaAtPath } from './utils'

export type LayoutEditorWidgetAreaContextValue = {
    // The parent widget area IDs.
    path: string[]
    children: Widget[]
    editorSchema: LayoutEditorSchema
    isEditing: boolean
}

export const LayoutEditorWidgetAreaContext =
    React.createContext<LayoutEditorWidgetAreaContextValue | null>(null)

export function useLayoutEditorWidgetAreaContext(): LayoutEditorWidgetAreaContextValue | null {
    return useContext(LayoutEditorWidgetAreaContext)
}

type LayoutEditorWidgetAreaContextProviderProps = {
    id: string
}

export const LayoutEditorWidgetAreaContextProvider: React.FC<
    LayoutEditorWidgetAreaContextProviderProps
> = ({ id, children }) => {
    const { schema, view, isEditing } = useLayoutEditorContext()
    const { widget } = useLayoutEditorWidgetContext() ?? {}
    const { path = [] } = useLayoutEditorWidgetAreaContext() || {}

    // Widget area contexts can be nested, to calculate the parent ID path.
    const newPath = widget ? [...path, widget.id, id] : [...path, id]

    const childrenWidgets = getWidgetAreaAtPath(newPath, view?.layout) ?? []

    const value = useDeepEqualsMemoValue({
        path: newPath,
        children: childrenWidgets,
        editorSchema: schema,
        isEditing,
    })

    return (
        <LayoutEditorWidgetAreaContext.Provider value={value}>
            {children}
        </LayoutEditorWidgetAreaContext.Provider>
    )
}
