import { APP_SIDE_NAV_WIDTH, WORKSPACE_BAR_WIDTH } from './frameConstants'

export const getLeftOffset = (
    showAppBar: boolean = false,
    showSideNav: boolean = false,
    showSidebar: boolean = false
) => {
    let margin = 0

    if (showSidebar || showSideNav) {
        margin = APP_SIDE_NAV_WIDTH + (showAppBar ? WORKSPACE_BAR_WIDTH : 0)
    } else if (showAppBar) {
        margin = WORKSPACE_BAR_WIDTH
    }

    return margin
}
