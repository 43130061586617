import React, { useCallback } from 'react'

import { isEqual } from 'lodash'

import { useUserAttributeDisplayState } from './hooks/useUserAttributeDisplayState'
import { AttributeDisplayComponent } from './types'
import { Users } from './Users'

type UserAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Users> & {}

export const UserAttributeDisplay: AttributeDisplayComponent<
    UserRefDto,
    UserAttributeDisplayProps
> = React.memo(function UserAttributeDisplay({
    field,
    value,
    size = 'm',
    onClick,
    isLoading,
    ...props
}) {
    const { value: effectiveSize, isByStackerSystem } = useUserAttributeDisplayState({
        field,
        value,
        isLoading,
    })

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            if (isByStackerSystem) return

            e.stopPropagation()

            onClick?.(e)
        },
        [isByStackerSystem, onClick]
    )

    return (
        <Users
            value={effectiveSize}
            size={size}
            onClick={handleClick}
            isLoading={isLoading}
            {...props}
        />
    )
},
isEqual)
