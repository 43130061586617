import React, { ReactNode, useRef } from 'react'

import {
    closestCenter,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'

import { useAppContext } from 'app/AppContext'
import { getUrl, getWorkspaceUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useFavorites } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useGroupedWorkspaceStacks, useStacks } from 'data/hooks/stacks'
import { StackIconBadge } from 'features/core/StackIconBadge'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { useNavContext } from 'features/utils/NavContext'
import { useLocalStorageState } from 'utils/useLocalStorageState'

import { Icon as V2Icon } from 'v2/ui'
import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { ButtonProps } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Divider } from 'ui/components/Divider'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { LinkButton } from 'ui/components/LinkButton'
import { stopAllPointerPropagation, stopPropagation } from 'ui/helpers/utilities'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'
import { theme } from 'ui/styling/Theme.css'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { CollapseControls } from './CollapseControls'
import { SidebarUserMenuButton } from './SidebarUserMenuButton'
import { WorkspaceSidebarTop } from './WorkspaceSidebarTop'

import {
    ItemGripStyle,
    SidebarContainerStyle,
    SidebarHoverClass,
} from './WorkspaceSidebarStyles.css'

type WorkspaceSidebarProps = { navTheme: any }
export function WorkspaceSidebar({ navTheme }: WorkspaceSidebarProps) {
    const { sidebarState, setSidebarState, isPoppedOut, setIsPoppedOut } = useNavContext()
    const { selectedStack } = useAppContext()
    const { workspaceZone } = useWorkspaceContext()

    const isCollapsed =
        sidebarState === 'workspaceBarCollapsed' ||
        (!selectedStack?._sid && sidebarState === 'fullyCollapsed')
    const toggleCollapsed = () => {
        if (sidebarState === 'workspaceBarCollapsed') {
            setSidebarState('open')
        } else if (sidebarState === 'fullyCollapsed' && !selectedStack?._sid) {
            setSidebarState('open')
        } else {
            setSidebarState('workspaceBarCollapsed')
        }
    }

    const isCollapsedRef = useRef(false)
    isCollapsedRef.current = isCollapsed
    const [_isHovered, hoverHandlers] = useHover({
        onHoverStart: () => (isCollapsedRef.current ? setIsPoppedOut(true) : undefined),
        onHoverEnd: () => (isCollapsedRef.current ? setIsPoppedOut(false) : undefined),
        delay: 50,
    })

    const [workspaceOpen, setWorkspaceOpen] = useLocalStorageState('workspace_expanded', {
        defaultValue: true,
    })
    const [favoritesOpen, setFavoritesOpen] = useLocalStorageState('favorites_expanded', {
        defaultValue: true,
    })

    const favoritesEnabled = useFavoritesEnabled()

    const title = workspaceZone?.type === 'Portal' ? workspaceZone.name : 'WORKSPACE'
    return (
        <Box
            as={ThemeProvider}
            theme="admin"
            position="relative"
            flex
            column
            stretch
            noShrink
            className={classNames(SidebarContainerStyle, SidebarHoverClass)}
            {...hoverHandlers}
        >
            <WorkspaceSidebarTop />
            <Divider my="m" />
            <Box flex column grow overflowY="auto">
                {favoritesEnabled && (
                    <Collapsible open={favoritesOpen} onOpenChange={setFavoritesOpen}>
                        <CollapsibleTrigger asChild>
                            <SidebarLabel role="button" ml="l" my="m">
                                FAVORITES{!favoritesOpen && '...'}
                            </SidebarLabel>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                            <FavoritesSection mb="m" />
                        </CollapsibleContent>
                    </Collapsible>
                )}
                <Collapsible open={workspaceOpen} onOpenChange={setWorkspaceOpen}>
                    <CollapsibleTrigger asChild>
                        <Box flex center justifyContent="space-between" ml="l">
                            <SidebarLabel role="button">
                                {title.toUpperCase()}
                                {!workspaceOpen && '...'}
                            </SidebarLabel>
                            <LinkButton
                                variant="ghost"
                                size="xs"
                                mr="xs"
                                to={getWorkspaceUrl(Urls.Home)}
                                style={{ zIndex: 10 }}
                                startIcon={{ name: 'Layers' }}
                                iconOnly={true}
                            />
                        </Box>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                        <AppList mt="m" />
                    </CollapsibleContent>
                </Collapsible>
            </Box>
            <Divider />
            <Box noShrink>
                <SidebarUserMenuButton navTheme={navTheme} />
            </Box>
            <CollapseControls
                isCollapsed={isCollapsed}
                toggleCollapsed={toggleCollapsed}
                isPoppedOut={isPoppedOut}
            />
        </Box>
    )
}

function AppList(props: Omit<BoxProps, 'children'>) {
    const { data: groups } = useGroupedWorkspaceStacks()

    return (
        <Box
            flex
            column
            fontSize="bodyM"
            maxWidth="full"
            overflowX="hidden"
            overflowY="auto"
            {...props}
        >
            {groups?.map((group) => (
                <AppGroup
                    key={group.name}
                    name={groups.length > 1 ? group.name || 'General' : ''}
                    stacks={group.stacks}
                />
            ))}
        </Box>
    )
}

function AppGroup({ name, stacks }: BoxProps & { name: string; stacks: StackDto[] }) {
    const [isOpen, setIsOpen] = useLocalStorageState<Boolean>(`${name}_Expanded`, {
        defaultValue: true || !name,
    })
    const pinnedStacks = stacks?.filter((s) => s.options.pin_to_app_bar && !s.options.is_archived)
    if (!pinnedStacks?.length) return null
    return (
        <Collapsible flex column mb="m" open={Boolean(isOpen)} onOpenChange={setIsOpen}>
            {name && (
                <CollapsibleTrigger
                    color="textWeaker"
                    flex
                    center
                    px="m"
                    pb="xs"
                    justifyContent="flex-start"
                >
                    <Icon name={isOpen ? 'ChevronDown' : 'ChevronRight'} mr="2xs" />
                    <Box shrink trim fontWeight="bodyBold" style={{ fontSize: 15 }}>
                        {name}
                    </Box>
                </CollapsibleTrigger>
            )}
            <CollapsibleContent flex column stretch>
                {pinnedStacks.map((stack) => (
                    <StackButton key={stack._sid} stack={stack} pl={name ? 'xl' : 'l'} pr="m" />
                ))}
            </CollapsibleContent>
        </Collapsible>
    )
}

function FavoritesSection(props: BoxProps) {
    const { favorites, updateDisplayOrder } = useFavorites()
    const sensors = useSensors(useSensor(PointerSensor))

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event

        if (active.id !== over?.id) {
            const overItem = favorites.find((x) => x.auto_id === over?.id)

            if (!overItem) return

            updateDisplayOrder({ id: active.id as number, displayOrder: overItem.display_order })
        }
    }
    return (
        <Box flex column stretch {...props}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={favorites.map((x) => ({ id: x.auto_id }))}
                    strategy={verticalListSortingStrategy}
                >
                    {favorites.map((f) => (
                        <FavoritesLink key={f.auto_id} item={f} pl="l" />
                    ))}
                </SortableContext>
            </DndContext>
        </Box>
    )
}

function FavoritesLink({ item, ...props }: ButtonProps & { item: FavoriteDto }) {
    const { setHasMenuOpen } = useNavContext()

    const { deleteFavorite, data } = useFavorites()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.auto_id,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }
    const { data: stacks } = useStacks()
    const stack = stacks?.find((s) => s._sid === item.stack_id)

    const removeFavorite = () => {
        deleteFavorite(item.auto_id)
    }
    let content: ReactNode

    if (!stack) {
        return null
    }
    if (item.type === FavoriteType.App) {
        content = (
            <StackButton
                stack={stack}
                {...(props as Partial<React.ComponentPropsWithoutRef<typeof StackButton>>)}
                style={{
                    paddingLeft: theme.space.l,
                    paddingRight: theme.space['2xl'],
                }}
            />
        )
    } else if (item.type === FavoriteType.NavigationItem) {
        const navItem = data?.navigation_items?.find(
            (n) => n._sid === item.navigation_id && n.stack_id === item.stack_id
        )
        if (navItem) {
            content = (
                <NavigationItemButton
                    url={navItem.url}
                    label={navItem.label}
                    icon={navItem.options.icon}
                    stack={stack}
                    {...(props as Partial<
                        React.ComponentPropsWithoutRef<typeof NavigationItemButton>
                    >)}
                    style={{
                        paddingLeft: theme.space.l,
                        paddingRight: theme.space['2xl'],
                    }}
                />
            )
        }
    } else if (item.type === FavoriteType.CreateForm) {
        const obj = data?.objects?.find(
            (n) => n._sid === item.object_id && n.stack_id === item.stack_id
        )
        const view = data?.views?.find(
            (n) => n._sid === item.view_id && n.stack_id === item.stack_id
        )
        if (obj) {
            content = (
                <NavigationItemButton
                    url={item.url!}
                    label={view?.options?.title || `${obj.name}: add new`}
                    icon="faPlusSquare"
                    stack={stack}
                    {...(props as Partial<
                        React.ComponentPropsWithoutRef<typeof NavigationItemButton>
                    >)}
                    style={{
                        paddingLeft: theme.space.l,
                        paddingRight: theme.space['2xl'],
                    }}
                />
            )
        }
    } else if (item.type === FavoriteType.Record) {
        const record = data?.records?.find((n) => n._sid === item.record_id)
        const obj = data?.objects?.find(
            (n) => n._sid === item.object_id && n.stack_id === item.stack_id
        )
        if (record && obj) {
            content = (
                <RecordLinkButton
                    record={record}
                    object={obj}
                    objectUrls={data.object_urls ?? {}}
                    stack={stack}
                    {...(props as Partial<React.ComponentPropsWithoutRef<typeof RecordLinkButton>>)}
                    style={{
                        paddingLeft: theme.space.l,
                        paddingRight: theme.space['2xl'],
                    }}
                />
            )
        }
    } else if (item.type === FavoriteType.Document) {
        const document = data?.documents?.find((n) => n.auto_id === item.document_id)
        if (document) {
            content = (
                <NavigationItemButton
                    url={`/docs/${document?.auto_id}`}
                    label={document.title || 'Untitled'}
                    icon="faNotebook"
                    stack={stack}
                    {...(props as Partial<
                        React.ComponentPropsWithoutRef<typeof NavigationItemButton>
                    >)}
                    style={{
                        paddingLeft: theme.space.l,
                        paddingRight: theme.space['2xl'],
                    }}
                />
            )
        }
    }

    const handleOpenChange = (isOpen: boolean) => {
        setHasMenuOpen(isOpen)
    }
    if (!content) return null
    return (
        <Box
            flex
            column
            position="relative"
            overflow="hidden"
            maxWidth="full"
            className={HoverContainerStyle}
            ref={setNodeRef}
            style={style}
            {...attributes}
        >
            <Box className={ItemGripStyle} {...stopAllPointerPropagation()}>
                <Icon name="GripVertical" {...listeners} />
            </Box>
            {content}
            <Dropdown onOpenChange={handleOpenChange}>
                <DropdownButton
                    position="absolute"
                    size="2xs"
                    right="s"
                    style={{
                        top: 0,
                        bottom: 0,
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        zIndex: 10,
                    }}
                    className={OpacityOnContainerHover}
                    variant="ghost"
                    startIcon={{ name: 'MoreVertical' }}
                />

                <DropdownContent onClick={stopPropagation}>
                    <DropdownItem
                        startIcon={{ name: 'MinusCircle' }}
                        onClick={removeFavorite}
                        label="Remove from favorites"
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

function NavigationItemButton({
    url,
    label,
    icon,
    stack,
    style,
    ...props
}: Omit<React.ComponentPropsWithoutRef<typeof LinkButton>, 'icon'> & {
    url: string
    label: string
    icon: string
    stack: StackDto
}) {
    return (
        <LinkButton
            variant="ghost"
            size="s"
            to={getUrl(url, stack)}
            style={{ justifyContent: 'flex-start', ...style }}
            {...props}
        >
            <Box flex center shrink>
                <StackIconBadge stack={stack} size={20} />
                <Icon
                    style={{ color: '#1C1D25BB' }}
                    size="xs"
                    noShrink
                    name="ChevronRight"
                    ml="xs"
                />
                {icon && (
                    <Box
                        flex
                        column
                        center
                        justifyContent="center"
                        width="2xl"
                        height="2xl"
                        rounded="m"
                        mr="xs"
                        noShrink
                    >
                        <V2Icon
                            iconPack="far"
                            icon={icon}
                            size="sm"
                            opacity={0.7}
                            style={{ width: '15px', color: '#1C1D25BB' }}
                        />
                    </Box>
                )}
                <Box shrink trim style={{ color: '#1C1D25BB', fontSize: '0.9375rem' }}>
                    {label}
                </Box>
            </Box>
        </LinkButton>
    )
}

function RecordLinkButton({
    record,
    object,
    objectUrls,
    stack,
    children,
    containerProps,
    style,
    ...props
}: React.ComponentPropsWithoutRef<typeof LinkButton> & {
    record: Partial<RecordDto>
    object: Partial<ObjectDto>
    objectUrls: { [key: string]: string }
    stack: StackDto
    containerProps?: BoxProps
}) {
    if (!record) return null

    return (
        <LinkButton
            variant="ghost"
            size="s"
            to={getUrl(`${objectUrls[object._sid ?? '']}/view/${record?._sid}`, stack)}
            style={{ justifyContent: 'flex-start', ...style }}
            {...props}
        >
            <Box flex center shrink {...containerProps}>
                <StackIconBadge stack={stack} size={20} />
                <Icon
                    style={{ color: '#1C1D25BB' }}
                    size="xs"
                    noShrink
                    name="ChevronRight"
                    mx="2xs"
                />
                <Box
                    flex
                    column
                    center
                    justifyContent="center"
                    width="2xl"
                    height="2xl"
                    rounded="m"
                    mr="xs"
                    noShrink
                >
                    <Icon name="FileText" style={{ color: '#1C1D25BB' }} />
                </Box>
                <Box shrink trim style={{ color: '#1C1D25BB', fontSize: '0.9375rem' }}>
                    {record._primary}
                </Box>
            </Box>
            {children}
        </LinkButton>
    )
}

function StackButton({
    stack,
    style,
    ...props
}: React.ComponentPropsWithoutRef<typeof LinkButton> & { stack: StackDto }) {
    return (
        <LinkButton
            variant="ghost"
            size="s"
            to={getUrl(Urls.Home, stack)}
            style={{
                justifyContent: 'flex-start',
                paddingLeft: theme.space.xl,
                ...style,
            }}
            {...props}
        >
            <Box flex center shrink>
                <StackIconBadge stack={stack} size={20} mr="m" />
                <Box shrink trim style={{ color: '#1C1D25BB', fontSize: 15 }}>
                    {stack.name}
                </Box>
            </Box>
        </LinkButton>
    )
}
function SidebarLabel(props: BoxProps) {
    return (
        <Box
            fontSize="bodyS"
            fontWeight="bodyBold"
            style={{ letterSpacing: 1, fontSize: 11 }}
            color="textWeaker"
            {...props}
        />
    )
}
