import { StepTypes } from 'data/hooks/actionTypes'
import { useExecuteActionModal } from 'features/actions/ExecuteActionModal'
import { getFieldsWithPerms } from 'features/actions/getFieldsWithPerms'
import { useObjectFromId } from 'features/actions/helpers'

import { useExecuteInvisibleAction } from './useExecuteInvisibleAction'

const isVisibleStep = (step: ActionStep, record: RecordDto, object: ObjectDto): boolean => {
    if (step.type === StepTypes.AutomationTrigger) {
        return false
    }

    if (step.always_confirm_step) {
        return true
    }

    return getFieldsWithPerms(record, object, step.fields).some((field) => field.promptUser)
}

export const useActionButton = ({
    action,
    includeFields,
    record,
    recordId,
}: {
    action: ActionDto
    includeFields: string[]
    record: RecordDto
    recordId: string
}): { isVisible: boolean; executeAction: () => void } => {
    const actionId = action?._sid
    const hasActionButtonStep = action?.options?.steps.some(
        (step: ActionStep) => step.type === StepTypes.AutomationTrigger
    )
    const { toggle } = useExecuteActionModal(action, recordId, includeFields)

    const run = useExecuteInvisibleAction({
        recordId,
        actionId,
        objectId: action.object_id,
        includeFields,
        name: action.name,
        hasActionButtonStep,
    })

    const steps = action?.options?.steps || []

    const { object } = useObjectFromId(record?._object_id || '')

    const isVisibleAction = object
        ? steps.some((step: ActionStep) => isVisibleStep(step, record, object))
        : true

    return {
        isVisible: isVisibleAction,
        executeAction: isVisibleAction ? toggle : run,
    }
}
