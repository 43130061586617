// @ts-strict-ignore
import React, { FC } from 'react'

import ProfileImage from 'v2/views/Detail/ProfileImage'
import getImageUrl from 'v2/views/utils/getImageUrl'

import { IMAGE_FIELD_TYPES } from 'data/utils/fieldDefinitions'

import { Box, Button, Container, PopoverButton } from 'v2/ui'
import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'

type Props = {
    data: any
    object: ObjectDto | undefined
    context: any
    isFieldDisabled: (fieldId: string, useApiName: boolean) => boolean
    isProfileLayout: boolean
}

const DetailViewProfileImage: FC<Props> = ({
    data,
    object,
    context,
    isFieldDisabled,
    isProfileLayout,
}) => {
    const imageFieldApiName = data?.config?.profileImage?.id
    if (!imageFieldApiName) {
        return null
    }
    if (!data?.record) {
        return null
    }

    const field = object?.fields?.find(
        ({ api_name, type }) => api_name === imageFieldApiName && IMAGE_FIELD_TYPES.includes(type)
    )

    if (!field || isFieldDisabled(imageFieldApiName, true)) {
        return null
    }

    const recordImage = data.record[imageFieldApiName]
    const imageUrl = getImageUrl(recordImage)

    const canEditProfileField =
        !field.is_read_only &&
        data?.record?._permissions.may_update &&
        data?.record?._permissions.may_update_fields.includes(field.api_name)

    const styleProps = isProfileLayout
        ? {
              size: ['66%', '66%', '25%', '66%'],
              mb: 4,
              pb: ['66%', '66%', '25%', '66%'],
              alignSelf: ['center', 'center', 'start', 'center'],
          }
        : {}

    return (
        <Box mr={2}>
            <ProfileImage
                position="relative"
                name={data.record._primary}
                image={imageUrl}
                alignSelf="flex-start"
                mx="auto"
                {...styleProps}
            >
                {context.view.editing && canEditProfileField && (
                    <PopoverButton
                        borderRadius="100%"
                        width="24px"
                        height="24px"
                        // @ts-ignore
                        icon="edit"
                        defaultIsOpen={false}
                        position="absolute"
                        bottom={0}
                        right={0}
                        bg="rgba(0,0,0,.8)"
                        color="white"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="12px"
                        _hover={{ bg: 'rgba(100,100,100,.8)' }}
                        p={0}
                        closeOnOuterAction={false}
                    >
                        {({ onToggle }) => (
                            <Container p={4} maxWidth="368px">
                                <AttributeDisplay
                                    objectId={context.object._sid}
                                    fieldId={field._sid}
                                    record={context.record}
                                    editing={context.view.editing}
                                    setValue={context.view.actions.setValue}
                                    setValid={context.view.actions.setValid}
                                    valid={context.view.valid}
                                    showErrors={context.view.showErrors}
                                    isLoading={context.view?.isLoading}
                                    variant="heading"
                                    useFirstImageSelector
                                />
                                <Button
                                    variant="sm"
                                    icon="check"
                                    onClick={(...args) => {
                                        onToggle(...args)
                                    }}
                                >
                                    Done
                                </Button>
                            </Container>
                        )}
                    </PopoverButton>
                )}
            </ProfileImage>
        </Box>
    )
}

export default DetailViewProfileImage
