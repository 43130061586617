import React from 'react'

import { TaskActions } from 'data/hooks/tasks/tasks'
import { AssigneesDisplayMode, GetTasksPayload, SourceDisplayMode } from 'data/hooks/tasks/types'

export type TaskListContext = GetTasksPayload &
    TaskActions & {
        related_to?: string
        emptyContext?: boolean
        _object_id?: string
        showAssignees?: AssigneesDisplayMode
        showSource?: SourceDisplayMode
    }

export const DefaultTaskListContext: TaskListContext = {
    tasks: [],
    createTask: () => Promise.reject(),
    updateTask: () => Promise.reject(),
    deleteTask: () => Promise.reject(),
    emptyContext: true,
}

export const TaskListContext = React.createContext<TaskListContext>(DefaultTaskListContext)

export function useTaskListContext() {
    return React.useContext(TaskListContext)
}

export function TaskListContextProvider({
    value,
    children,
}: {
    value: TaskListContext
    children: React.ReactNode
}) {
    return <TaskListContext.Provider value={value}>{children}</TaskListContext.Provider>
}
