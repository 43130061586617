// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react'

import { bulkUpdateFields, createField, deleteField, updateField } from 'data/hooks/fields'
import { useObjects } from 'data/hooks/objects'
import { createObject, deleteObject, updateObject } from 'data/hooks/objects/objectOperations'

type Props = {
    objectId: string | null | undefined
    children: any
    loading?: JSX.Element | null | string
    shouldRenderEvenWithEmptyObject?: boolean
}

export const WithObject = ({
    objectId,
    loading,
    children,
    shouldRenderEvenWithEmptyObject,
}: Props) => {
    const { data: objects } = useObjects()

    const object = useMemo(() => {
        return objects?.find((o) => o._sid === objectId)
    }, [objects, objectId])

    const createObjectField = useCallback(
        (data) => {
            if (object?._sid) createField({ ...data, object_id: object?._sid })
        },
        [object]
    )

    const changeObject = useCallback(
        (data, options) => {
            if (object?._sid) updateObject(object?._sid, data, options)
        },
        [object]
    )

    const showLoadingState = !objects

    if (showLoadingState) return loading ? loading : null
    if (!object && !shouldRenderEvenWithEmptyObject) return loading ? loading : null

    return children({
        object: object || { fields: [] },
        isObjectEmpty: !object,
        primaryField: getPrimaryField(object),
        onChange: updateObject,
        createField: createObjectField,
        createObject,
        changeObject,
        deleteField,
        changeField: updateField,
        bulkChangeFields: bulkUpdateFields,
        removeObject: deleteObject,
    })
}

export default WithObject

const getPrimaryField = (object) => {
    const primaries = object?.fields.filter((field) => field.is_primary)
    if (!primaries || !primaries.length) return { api_name: '_sid' }
    return primaries[0]
}

export const withObject =
    (Child: React.ComponentType<any>) =>
    ({ objectId, ...props }: { objectId: string; [prop: string]: any }) =>
        (
            <WithObject objectId={objectId}>
                {(recProps) => <Child {...recProps} {...props} />}
            </WithObject>
        )
