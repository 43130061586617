import { setElementVars } from '@vanilla-extract/dynamic'

import { colorBaseConfig } from 'v2/ui/theme/styles/default/colors'
import { AppColors } from 'v2/ui/theme/styles/types/colorBase'

import { ColorScheme, themeColors } from 'ui/styling/baseVariables/colors/colorPalette'
import { paletteTheme } from 'ui/styling/baseVariables/colors/PaletteTheme.css'
import { colorSchemeVar } from 'ui/styling/GlobalStyles.css'

import { getColorScale } from './themeHelpers'

const colorShades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]

export const updateThemeValues = ({
    appColor,
    colorScheme = 'Indigo',
}: {
    appColor?: string
    colorScheme?: ColorScheme
}) => {
    const appScale = appColor ? getColorScale(appColor) : getColorSchemeScale(colorScheme)

    const themeUpdates = {} as typeof paletteTheme
    for (const shade of colorShades) {
        themeUpdates[`theme${shade}`] = appScale[shade]
    }
    setElementVars(document.querySelector(':root')!, paletteTheme, themeUpdates)
    setElementVars(document.querySelector(':root')!, {
        [colorSchemeVar]: colorScheme,
    })
    return appScale
}

export function getColorSchemeScale(colorScheme: ColorScheme): Record<number, string> {
    const colorSchemeLowerCase = colorScheme.toLowerCase()

    const newScale = {} as Record<number, string>
    for (const shade of colorShades) {
        newScale[shade] = paletteTheme[`${colorSchemeLowerCase}${shade}`] ?? ''
    }

    return newScale
}

const numberRegexp = /\d+/

export function determineColorScheme(baseColor: string): ColorScheme | undefined {
    const newColor = Object.entries(themeColors).find(
        ([colorName, color]) => colorName.endsWith('600') && color === baseColor
    )
    if (!newColor) {
        // Maybe this is a v2 app color.
        return mapV2AppColorToColorScheme(baseColor)
    }

    const colorName = newColor[0].replace(numberRegexp, '')
    const capitalizedColorName = colorName.charAt(0).toUpperCase() + colorName.slice(1)

    return capitalizedColorName as ColorScheme
}

function mapV2AppColorToColorScheme(appColor: string): ColorScheme | undefined {
    const v2AppColor = Object.entries(colorBaseConfig.appColors).find(
        ([_, colorTheme]) => colorTheme[500] === appColor
    )
    if (!v2AppColor) return undefined

    const v2ColorName: keyof AppColors = v2AppColor[0] as keyof AppColors
    return mapV2ColorNameToColorScheme(v2ColorName)
}

export function mapV2ColorNameToColorScheme(colorName: string): ColorScheme | undefined {
    switch (colorName) {
        case 'blue':
            return 'Blue'
        case 'teal':
            return 'Teal'
        case 'purple':
        case 'mauve':
            return 'Purple'
        case 'indigo':
            return 'Indigo'
        case 'orange':
        case 'poppy':
        case 'gold':
            return 'Orange'
        case 'ruby':
            return 'Rose'
        case 'raspberry':
            return 'Pink'
        case 'green':
            return 'Emerald'
        case 'moss':
        case 'pine':
            return 'Lime'
        case 'mulberry':
            return 'Fuchsia'
        default:
            return undefined
    }
}
