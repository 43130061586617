import { Bundle } from 'features/AiPlayground/bundles/bundleBuilder'
import { AppSchema } from 'features/AiPlayground/bundles/zodSchema'
import { getChatCompletion } from 'features/AiPlayground/chatUtils/chatApi'
import { zodToOpenAiResponseFormat } from 'features/AiPlayground/chatUtils/zodUtils'
import { swapImages } from 'features/AiPlayground/swapImages'

export async function generateBasicSchema(bundle: Bundle, requestId: string): Promise<Bundle> {
    console.log('# Generating schema', { requestId, bundle })
    const result = await getChatCompletion(
        [
            {
                role: 'system',
                content: `You are a chat bot in an app builder. Fill out this schema to have fields for each object.

1. Think about the relationships between objects. Make them one-to-one or one-to-many as appropriate.
2. Don't generate ID columns.
3. Think about the real-world use case and what fields would be useful on each object.
4. Field ids MUST be unique across the app and include the object id

For things like people, companies, product listings etc. you should try to include an image field.

- Companies should have a logo
- People (leads, contacts, etc. ) should have a profile photo
- Products, listings etc. should have a relevant object image
- Image fields should not be the first field in the object.
- If you do add an image, make it be the second field.
`,
            },
            { role: 'user', content: JSON.stringify(bundle) },
        ],
        undefined,
        zodToOpenAiResponseFormat(AppSchema, 'appSchema')
    )

    const app = JSON.parse(result.content || '')

    await swapImages(app)
    console.log('# Generated basic schema', app)

    const objectDescriptions = bundle.apps[0].objects.reduce((acc, object) => {
        acc[object.id] = object.description || ''
        return acc
    }, {} as Record<string, string>)

    const updatedApp = {
        ...app,
        objects: app.objects.map((object) => ({
            ...object,
            description: objectDescriptions[object.id],
        })),
    }
    return { apps: [updatedApp] }
}
