import React from 'react'

import settings from 'app/settings'
import { useUpdateAccount } from 'data/hooks/accounts'
import { validateSubdomain } from 'data/utils/validateSubdomain'
import { ConfirmationModal } from 'features/workspace/modals/ConfirmationModal'
import { FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import useRequestConfirmModal from 'v2/ui/utils/useRequestConfirmModal'

import { Divider } from 'ui/components/Divider'
import { Body } from 'ui/components/Text'
import Form from 'ui/deprecated/forms/Form'

import { DeleteWorkspace } from './DeleteWorkspace'
import { SettingsInput } from './SettingsInput'
import { updateAccountUrl } from './updateAccountUrl'

type WorkspaceGeneralSettingsProps = {
    formRef: any
    workspaceAccount: Account
    children?: React.ReactNode
    readOnly?: boolean
}

type FormData = { name: string; urlSlug: string; custom_base_url: string }

export const WorkspaceGeneralSettings = ({
    formRef,
    workspaceAccount,
    children,
    readOnly,
}: WorkspaceGeneralSettingsProps) => {
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const {
        onShowConfirmation: onRequestUrlChange,
        showModal: showUrlChangeRequest,
        onAllow: onAllowUrlChange,
        onDeny: onDenyUrlChange,
    } = useRequestConfirmModal()

    const { name, base_url, custom_base_url } = workspaceAccount
    const urlSlug = base_url ? base_url.substring(0, base_url.indexOf('.')) : ''
    const defaultValues = { name, urlSlug, custom_base_url }

    const onSubmit = async (values: FormData) => {
        const afterChanged = (resp: Account) => {
            if (resp.slug !== workspaceAccount.slug) {
                window.location.assign(`/${resp.slug}`)
            }
        }
        // require explicit user confirmation if url is changing
        if (values.urlSlug && values.urlSlug !== workspaceAccount.slug) {
            return onRequestUrlChange().then(() =>
                updateAccountUrl(updateAccount, workspaceAccount, values).then(afterChanged)
            )
        } else {
            return updateAccountUrl(updateAccount, workspaceAccount, values).then(afterChanged)
        }
    }

    return (
        <Form onSubmit={onSubmit} options={{ defaultValues }} style={{ flex: 1 }} resetOnSuccess>
            <FormFrame title="General" formRef={formRef}>
                <Divider variant="weak" mb="l" />
                <SettingsInput
                    formRef={formRef}
                    name="name"
                    label="Name"
                    readOnly={readOnly}
                    required
                />
                <div style={{ height: '15px' }} />
                <SettingsInput
                    formRef={formRef}
                    name="urlSlug"
                    label="Customize URL"
                    required
                    readOnly={readOnly}
                    startAction={() => (
                        <Body color="gray500">{`https://${settings.WORKSPACE_DOMAIN}/`}</Body>
                    )}
                    registerOptions={{
                        validate: {
                            asyncValidate: (value) =>
                                base_url ? validateSubdomain(base_url, value) : false,
                        },
                    }}
                    mb="s"
                />
                {workspaceAccount.custom_base_url && (
                    <SettingsInput
                        formRef={formRef}
                        name="custom_base_url"
                        label="Custom Domain"
                        readOnly={readOnly}
                        startAction={() => <Body color="gray500">https://</Body>}
                    />
                )}
                <DeleteWorkspace workspaceAccount={workspaceAccount} />
            </FormFrame>
            <ConfirmationModal
                isOpen={showUrlChangeRequest}
                title="Confirm URL change"
                subtitle="Are you sure that you wish to update the workspace URL? Any links to your previous url will no longer work."
                onConfirm={onAllowUrlChange}
                onCancel={onDenyUrlChange}
            />
            {children}
        </Form>
    )
}
