import { useCallback } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'
import useConfirmDeleteRecords from 'features/datagrid/hooks/useConfirmDeleteRecords'

export function useDeleteSelectedRecords(deleteRecords?: (recordIds: string[]) => Promise<void>) {
    const { gridApi, canDeleteRecords } = useDataGridContext()

    const showConfirmDelete = useConfirmDeleteRecords()

    return useCallback(() => {
        if (!gridApi || !canDeleteRecords) return

        const selectedRecords = gridApi.getSelectedRows()
        if (selectedRecords.length < 1) return

        const recordIds = selectedRecords.reduce(
            (ids, record) => ids.add(record._sid),
            new Set<string>()
        )

        showConfirmDelete(recordIds.size, async () => {
            return deleteRecords?.(Array.from(recordIds))
        })
    }, [canDeleteRecords, deleteRecords, gridApi, showConfirmDelete])
}
