import { useCallback } from 'react'

import { useAppContext } from 'app/AppContext'
import { useStackRoles } from 'data/hooks/roles'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { PreviewServiceStorage } from 'features/PreviewService/PreviewServiceStorage'

export const usePreviewAs = (): { open: () => Promise<void> } => {
    const [_isBannerShowing, setIsBannerShowing] = useIsBannerShowing()
    const { data: roles } = useStackRoles()
    const { selectedStack } = useAppContext()
    const { previewAsRole, previewAsUser } = usePreviewServiceContext()
    const internalOptions = { includeUsersWithNoAccess: false }
    const { data, refetch, isFetched } = useAppUsersForAdmin(false, {}, internalOptions)

    const previewAsCallback = useCallback(
        (users) => {
            const defaultRoleSid = selectedStack?.options.roles__default_user_role
            const defaultRole = roles?.find((r) => r._sid === defaultRoleSid)

            const previousPreviewedRoleId = PreviewServiceStorage.getLastPreviewingRoleApiName()
            const previousPreviewedUser = PreviewServiceStorage.getLastPreviewingUser()

            if (previousPreviewedUser) {
                return previewAsUser(previousPreviewedUser._sid)
            }

            const firstRealUser = users.find((u: UserDto) => u.role !== 'internal_admin')
            if (firstRealUser) {
                return previewAsUser(firstRealUser._sid)
            }

            const roleApiName = previousPreviewedRoleId || defaultRole?.api_name
            return roleApiName ? previewAsRole(roleApiName) : Promise.resolve()
        },
        [selectedStack?.options.roles__default_user_role, roles, previewAsRole, previewAsUser]
    )

    const open = useCallback(async () => {
        const users = isFetched ? data : (await refetch()).data
        await previewAsCallback(users)
        setIsBannerShowing(true)
    }, [refetch, data, isFetched, previewAsCallback, setIsBannerShowing])

    return { open }
}
