// @ts-strict-ignore
import { getUserLocale } from 'get-user-locale'
import moment, { Moment } from 'moment-timezone'

import 'moment/min/locales'

const SUPPORTED_LOCALES = ['en-GB', 'en-US']

export function getLocale(): string {
    let locale = getUserLocale()
    if (!SUPPORTED_LOCALES.includes(locale)) {
        locale = 'en-GB'
    }

    return locale
}

// Format date as string based on the field display format options
export function formatDateAsString(value: any, field: any, showTime: boolean) {
    const { dateValue } = formatDate(value, field?.options?.timezone, showTime)
    const { displayFormat } = getDateTimeFormats(field, showTime)
    return dateValue?.format?.(displayFormat)
}

export function formatDate(
    children: any,
    timezone: string,
    showTime: boolean
): { dateValue: Moment | null; locale: string; isUTC: boolean } {
    let dateval: Moment | null = null

    const locale = getLocale()
    const isUTC = timezone === 'UTC'

    moment.tz.setDefault(isUTC ? 'UTC' : undefined)

    if (!children) {
        return { dateValue: null, locale, isUTC }
    }

    if (showTime) {
        dateval = moment(children)
    } else {
        try {
            dateval = moment(children, 'YYYY-MM-DD')
        } catch (e) {
            dateval = moment(children, 'DD/MM/YYYY')
        }
    }

    if (!dateval.isValid()) {
        dateval = null
    }

    if (dateval) {
        dateval.locale(locale)
    }

    return { dateValue: dateval, locale, isUTC }
}

export const getDateTimeFormats = (field, showTime) => {
    const { dateFormat, timeFormat } = field?.options || {}
    // Formats for both the datapicker and the display (using Moment.js)
    // They have slightly different formats, so we use 2 variables for the time and date
    // Default to local time formats / no time format if only showing a date
    let displayTimeFormat = showTime ? ' LT' : ''
    let datepickerTimeFormat = showTime ? 'p' : ''
    if (showTime) {
        switch (timeFormat) {
            case '12hour':
                displayTimeFormat = ' hh:mm a'
                datepickerTimeFormat = displayTimeFormat
                break
            case '24hour':
                displayTimeFormat = ' HH:mm'
                datepickerTimeFormat = displayTimeFormat
                break
        }
    }

    // Default to local date formats
    let displayFormat = 'L'
    let datepickerFormat = 'P'
    switch (dateFormat) {
        case 'Friendly':
            displayFormat = 'DD MMMM YYYY'
            datepickerFormat = 'dd MMMM yyyy'
            break
        case 'US':
            displayFormat = 'M/D/YYYY'
            datepickerFormat = 'M/d/yyyy'
            break
        case 'European':
            displayFormat = 'D/M/YYYY'
            datepickerFormat = 'd/M/yyyy'
            break
        case 'ISO':
            displayFormat = 'YYYY-MM-DD'
            datepickerFormat = 'yyyy-MM-dd'
            break
    }

    // Add the date and time formats together
    displayFormat = `${displayFormat}${displayTimeFormat}`
    datepickerFormat = `${datepickerFormat}${datepickerTimeFormat}`

    return { displayTimeFormat, datepickerTimeFormat, displayFormat, datepickerFormat }
}
