import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isEmpty, uniqBy, without } from 'lodash'
import { v4 as uuid } from 'uuid'

import { assertIsDefined } from 'data/utils/ts_utils'
import useTrack from 'utils/useTrack'

export type RecordEditManagerArgs = {
    records: RecordDto[]
    updateRecord?: (recordId: string, patch: { [key: string]: any }) => Promise<void>
    addRecord?: (record: RecordDto) => Promise<RecordDto>
    deleteRecords?: (recordIds: string[]) => Promise<void>
}

export enum FailedCommitReasons {
    isDelete = 'isDelete',
    isUpdate = 'isUpdate',
    isCreate = 'isCreate',
}

export type FailedCommit = Partial<{ [key in FailedCommitReasons]: boolean }> & {
    data?: { [key: string]: any }
}
export type RecordEditManagerResult = {
    records: RecordDto[]
    editRecord: (record: RecordDto, patch: Partial<RecordDto>) => Promise<void>
    deleteRecords: (recordIds: string[]) => void
    addRecord: (record?: Partial<RecordDto>) => Promise<RecordDto>
    pendingNewRecords: PendingAdd[]
    pendingDeletes: PendingDelete[]
    failedRecords: { [keyof: string]: FailedCommit }
    pendingEdits: {
        [keyof: string]: PendingEdit[]
    }
    retryFailures: () => Promise<void>
}

type PendingEdit = {
    key: string
    patch: { [key: string]: any }
    // used to trigger the save of edits made to new records while they were being created
    startCommit?: boolean
    committed?: boolean
    failedCommit?: boolean
}

export type PendingDelete = {
    recordId: string
    startCommit?: boolean
    failedCommit?: boolean
}

type PendingAdd = {
    record: RecordDto
    failedCommit?: boolean
}

function mergePendingEdits(edits: PendingEdit[]) {
    return edits?.reduce(
        (result, edit) => ({
            ...result,
            ...edit.patch,
        }),
        {}
    )
}

/*
 * This hook takes in a list of records and handles staging edits, deletes, and added records
 * while those changes are being committed to the server. These are the scenarios covered:
 *
 * - keeping track of edited fields pending save so those edits are merged into the records
 * coming from the server
 *
 * - holding temporary new records in memory until the first field is edited, then we call
 * out to add the new records
 *
 * - if further edits are made to a new record that is currently being created on the server,
 * we hold those edits until the new record is created, then we update that new record with
 * the pending edits
 *
 * - keeping track of deleted records so we don't include those in the final list of records
 * while were waiting for the server to process those deletions
 *
 *
 * TODO handle failures during saves.
 */
function useRecordEditManager({
    records,
    addRecord,
    updateRecord,
    deleteRecords,
}: RecordEditManagerArgs): RecordEditManagerResult {
    const savedNewRecordIds = useRef<{ [keyof: string]: string }>({})
    const [
        {
            allRecords,
            pendingNewRecords,
            pendingEdits,
            pendingDeletes,
            newRecordsSaving,
            failedRecords,
        },
        setState,
    ] = useState<{
        allRecords: RecordDto[]
        pendingNewRecords: PendingAdd[]
        pendingEdits: { [keyof: string]: PendingEdit[] }
        pendingDeletes: PendingDelete[]
        newRecordsSaving: string[]
        failedRecords: { [keyof: string]: FailedCommit }
    }>({
        allRecords: records,
        pendingNewRecords: [],
        pendingEdits: {},
        pendingDeletes: [],
        newRecordsSaving: [],
        failedRecords: {},
    })

    const { track } = useTrack()

    const createRecord = useCallback(
        async (record: RecordDto, updatedRecord: RecordDto) => {
            assertIsDefined(addRecord)

            setState((prevState) => {
                delete prevState.failedRecords[record._sid]

                return {
                    ...prevState,
                    pendingNewRecords: prevState.pendingNewRecords.map((pendingNewRecord) =>
                        pendingNewRecord.record._sid === record._sid
                            ? { ...pendingNewRecord, failedCommit: false }
                            : pendingNewRecord
                    ),
                    failedRecords: prevState.failedRecords,
                    newRecordsSaving: [...prevState.newRecordsSaving, record._sid],
                }
            })

            try {
                const savedRecord = await addRecord(updatedRecord)

                // Keep a dictionary of saved new record Ids so if any edits come in for
                // that previous temporary Id, we can match it to the new Id and update
                savedNewRecordIds.current = {
                    ...savedNewRecordIds.current,
                    [record._sid]: savedRecord._sid,
                }
                setState((prevState) => {
                    // If we have unsaved changes record against this record, update them
                    // to be recorded against the newly created record's SID and mark them
                    // as ready to save. We have a useEffect which will then process them.
                    // Why do it in a use effect? Because we are still in a callback from the successful
                    // addition of this record. It's better to do the update of the record in the next
                    // react cycle when all states (here and in consuming components) have had a chance
                    // to update.
                    const unsavedChanges = prevState.pendingEdits[record._sid]?.[0]
                    let newPendingEdits = { ...prevState.pendingEdits }
                    if (unsavedChanges) {
                        delete newPendingEdits[record._sid]
                        unsavedChanges.startCommit = true
                        newPendingEdits = {
                            ...newPendingEdits,
                            [savedRecord._sid]: [unsavedChanges],
                        }
                    }

                    return {
                        ...prevState,
                        // Update the list of records
                        allRecords: prevState.allRecords.map((r) => {
                            if (r._sid !== record._sid) {
                                return r
                            }

                            return {
                                ...savedRecord,
                                ...mergePendingEdits(prevState.pendingEdits[record._sid]),
                            }
                        }),
                        // Remove from pending new records
                        pendingNewRecords: prevState.pendingNewRecords.filter(
                            ({ record: { _sid } }) => _sid !== record._sid
                        ),
                        pendingEdits: newPendingEdits,
                        // If a delete came in for this record while it was in the process of creating
                        // update those deletes to use the new record sid now and set them to be
                        // processed now.
                        pendingDeletes: prevState.pendingDeletes.map((pendingDelete) =>
                            pendingDelete.recordId === record._sid
                                ? { recordId: savedRecord._sid, startCommit: true }
                                : pendingDelete
                        ),
                        // Remove from list of new records currently saving
                        newRecordsSaving: prevState.newRecordsSaving.filter(
                            (id) => id !== record._sid
                        ),
                    }
                })

                return savedRecord
            } catch (error) {
                if (!failedRecords[record._sid]) {
                    track('WIP - Frontend - DataGrid - Create record - Failed', {
                        table: record._object_id,
                        records: failedRecords,
                    })
                }

                setState((prevState) => {
                    // If we have commits that have been registered while the create operation was running
                    // they will have all been added into our pendingNewRecord entry. So we can safely drop them
                    // now and when/if the creation gets retried, it will include their data.
                    const unsavedChanges = prevState.pendingEdits[record._sid]?.[0]
                    let newPendingEdits = { ...prevState.pendingEdits }
                    if (unsavedChanges) {
                        delete newPendingEdits[record._sid]
                    }

                    return {
                        ...prevState,
                        pendingEdits: newPendingEdits,
                        pendingNewRecords: prevState.pendingNewRecords.map((pendingNewRecord) =>
                            pendingNewRecord.record._sid === record._sid
                                ? { ...pendingNewRecord, failedCommit: true }
                                : pendingNewRecord
                        ),
                        failedRecords: {
                            ...prevState.failedRecords,
                            [record._sid]: { isCreate: true },
                        },
                        // Remove from list of new records currently saving
                        newRecordsSaving: prevState.newRecordsSaving.filter(
                            (id) => id !== record._sid
                        ),
                    }
                })
            }
        },
        [addRecord, failedRecords, track]
    )

    const _addRecord = useCallback(
        async (record?: Partial<RecordDto>): Promise<RecordDto> => {
            const newRecord: RecordDto = {
                _sid: uuid(),
                ...record,
            } as RecordDto

            setState((prevState) => ({
                ...prevState,
                allRecords: [...prevState.allRecords, newRecord],
                pendingNewRecords: prevState.pendingNewRecords.concat({ record: newRecord }),
            }))

            const { _object_id, ...rest } = record || {}
            if (!isEmpty(rest)) {
                const response = await createRecord(newRecord, newRecord)
                if (response) {
                    return response
                }
            }
            return newRecord
        },
        [createRecord]
    )

    const editRecord = useCallback(
        async (specifiedRecord: RecordDto, patch: Partial<RecordDto>) => {
            if (!specifiedRecord) {
                return
            }

            const record = { ...specifiedRecord }
            // If this supplied ID is actually for a pending new record that has already been saved
            // then we need to switch to use the new Id.
            if (savedNewRecordIds.current[record._sid]) {
                record._sid = savedNewRecordIds.current[record._sid]
            }

            const edit: PendingEdit = {
                key: uuid(),
                // send the _object_id through as the record caching needs that
                // to properly update the object record caches.
                patch: { ...patch, _object_id: specifiedRecord._object_id },
            }
            const pendingNewRecord = pendingNewRecords.find(
                ({ record: { _sid } }) => _sid === record._sid
            )
            const pendingDelete = !!pendingDeletes.find(({ recordId }) => recordId === record._sid)

            if (pendingNewRecord) {
                const updatedRecord = { ...pendingNewRecord.record, ...patch }
                setState((prevState) => ({
                    ...prevState,
                    allRecords: [
                        ...prevState.allRecords.filter(
                            ({ _sid }) => _sid !== pendingNewRecord.record._sid
                        ),
                        updatedRecord,
                    ],
                    pendingNewRecords: prevState.pendingNewRecords.map((pendingNewRecord) =>
                        pendingNewRecord.record._sid === record._sid
                            ? { ...pendingNewRecord, record: updatedRecord }
                            : pendingNewRecord
                    ),
                }))

                // If we haven't already started saving this new record, do so now.
                if (!newRecordsSaving.includes(record._sid) && !pendingDelete) {
                    await createRecord(record, updatedRecord)
                    return
                }

                // Otherwise, we stash the pending updates to this record until the existing add operation finishes
                // We combine all edits made to a new record that is pending save into a single edit
                // entry in the pending changes dictionary
                setState((prevState) => {
                    const previousRecordEdits = prevState.pendingEdits[record._sid]?.[0] || {}
                    return {
                        ...prevState,
                        pendingEdits: {
                            ...prevState.pendingEdits,
                            [record._sid]: [
                                {
                                    ...previousRecordEdits,
                                    patch: { ...previousRecordEdits.patch, ...edit.patch },
                                },
                            ],
                        },
                    }
                })

                return
            }

            setState((prevState) => {
                // any edits for this record which have previously failed,
                // we want to mark as ready to commit again.
                const pendingEdits = (prevState.pendingEdits[record._sid] || []).map(
                    (pendingEdit) =>
                        pendingEdit.failedCommit
                            ? { ...pendingEdit, startCommit: true }
                            : pendingEdit
                )

                // And mark this edit as ready to commit (unless this record is pending delete, then
                // just add this edit to the queue, but don't push to the server.)
                edit.startCommit = !pendingDelete

                return {
                    ...prevState,
                    allRecords: prevState.allRecords.map((r) => {
                        if (r._sid !== record._sid) {
                            return r
                        }

                        return { ...r, ...mergePendingEdits([...pendingEdits, edit]) }
                    }),
                    pendingEdits: {
                        ...prevState.pendingEdits,
                        [record._sid]: [...pendingEdits, edit],
                    },
                }
            })
        },
        [createRecord, newRecordsSaving, pendingDeletes, pendingNewRecords]
    )

    const _deleteRecords = useCallback(
        async (recordIds: string[]) => {
            assertIsDefined(deleteRecords)

            // Get a list of any new, empty records which are in the list of records to remove
            const unsavedNewRecordIds = pendingNewRecords
                .filter(
                    ({ record: { _sid } }) =>
                        recordIds.includes(_sid) && !newRecordsSaving.includes(_sid)
                )
                .map(({ record: { _sid } }) => _sid)

            // Get a list of the ids which are for non-new records. Those are the ids
            // we can send to the server to delete.
            const idsToDelete = recordIds.filter(
                (recordId) => !pendingNewRecords.find((record) => record.record._sid === recordId)
            )

            setState((prevState) => {
                // Add all the record ids to the pendingDeletes list
                const newPendingDeletes = [
                    ...prevState.pendingDeletes,
                    ...without(recordIds, ...unsavedNewRecordIds).map((recordId) => ({
                        recordId,
                        startCommit: idsToDelete.includes(recordId),
                    })),
                ]

                // Want to remove any failed record entries for these records being deleted
                const newFailedRecords = { ...prevState.failedRecords }
                for (const recordId of recordIds) {
                    delete newFailedRecords[recordId]
                }

                return {
                    ...prevState,
                    allRecords: prevState.allRecords.filter(
                        ({ _sid }) =>
                            !unsavedNewRecordIds.includes(_sid) &&
                            !newPendingDeletes.find(
                                (pendingDelete) => pendingDelete.recordId === _sid
                            )
                    ),
                    // Clear out the new empty records which are being deleted
                    pendingNewRecords: prevState.pendingNewRecords.filter(
                        ({ record: { _sid } }) => !unsavedNewRecordIds.includes(_sid)
                    ),
                    pendingDeletes: newPendingDeletes,
                    failedRecords: newFailedRecords,
                }
            })
        },
        [deleteRecords, newRecordsSaving, pendingNewRecords]
    )

    const _processPendingRecordEdits = useCallback(
        async (recordId: string, editsToCommit: PendingEdit[]) => {
            assertIsDefined(updateRecord)

            // If the record to edit is not yet in the list of records returned by the server, nothing to do
            if (!records.find(({ _sid }) => _sid === recordId)) {
                return
            }

            // Merge the edits into a single patch
            const patch = mergePendingEdits(editsToCommit)
            const editsToCommitKeys = editsToCommit.map((x) => x.key)

            setState((prevState) => {
                // Reset failed state for this record
                const newFailedRecords = { ...prevState.failedRecords }
                delete newFailedRecords[recordId]

                // Reset the startCommit and failedCommit flags on these edits
                const newRecordPendingEdits = prevState.pendingEdits[recordId].map((pendingEdit) =>
                    editsToCommitKeys.includes(pendingEdit.key)
                        ? { ...pendingEdit, startCommit: false, failedCommit: false }
                        : pendingEdit
                )

                return {
                    ...prevState,
                    failedRecords: newFailedRecords,
                    pendingEdits: { ...prevState.pendingEdits, [recordId]: newRecordPendingEdits },
                }
            })

            try {
                // Save the changes to the record
                await updateRecord(recordId, patch)

                // Now update the update the pending changes dictionary for this record
                setState((prevState) => {
                    let newPendingEdits = { ...prevState.pendingEdits }

                    // See if we have any other edits which have not finished committing
                    let uncommittedChanges =
                        prevState.pendingEdits[recordId]?.filter(
                            (pendingEdit) =>
                                !editsToCommitKeys.includes(pendingEdit.key) &&
                                !pendingEdit.committed
                        ) || []

                    // If there are no uncommitted edits, then we can remove this record from
                    // the pendingEdits dictionary. Everything has been saved
                    if (uncommittedChanges.length === 0) {
                        delete newPendingEdits[recordId]
                        // Otherwise, update the dictionary with the new record state
                    } else {
                        // Set these committed edits as committed, but leave them on the stack of
                        // of other uncommitted changes so that their  changes continue to get
                        // applied into the mergedRecords collection, otherwise previous pending
                        // edits which have not saved yet and changed these same fields will suddenly
                        // show up again in the mergedRecords result
                        newPendingEdits[recordId] =
                            prevState.pendingEdits[recordId]?.map((pendingEdit) =>
                                !editsToCommitKeys.includes(pendingEdit.key)
                                    ? pendingEdit
                                    : { ...pendingEdit, committed: true }
                            ) || []
                    }

                    return {
                        ...prevState,
                        allRecords: prevState.allRecords.map((r) => {
                            if (r._sid !== recordId) {
                                return r
                            }

                            return {
                                ...r,
                                ...patch,
                                ...mergePendingEdits(newPendingEdits[recordId]),
                            }
                        }),
                        pendingEdits: newPendingEdits,
                    }
                })
            } catch (error) {
                if (!pendingEdits[recordId].some((r) => r.failedCommit)) {
                    track('WIP - Frontend - DataGrid - Update record - Failed', {
                        records: pendingEdits,
                    })
                }

                setState((prevState) => {
                    // Mark these edits has having failed and set startCommit back to false
                    const newRecordPendingEdits = uniqBy(
                        prevState.pendingEdits[recordId],
                        (pendingEdits) => pendingEdits.key
                    ).map((pendingEdit) =>
                        editsToCommitKeys.includes(pendingEdit.key)
                            ? { ...pendingEdit, failedCommit: true, startCommit: false }
                            : pendingEdit
                    )

                    return {
                        ...prevState,
                        pendingEdits: {
                            ...prevState.pendingEdits,
                            [recordId]: newRecordPendingEdits,
                        },
                        failedRecords: {
                            ...prevState.failedRecords,
                            [recordId]: {
                                isUpdate: true,
                                data: { ...prevState.failedRecords[recordId]?.data, ...patch },
                            },
                        },
                    }
                })
            }
        },
        [pendingEdits, records, track, updateRecord]
    )

    const _processPendingRecordDeletes = useCallback(async () => {
        if (!deleteRecords) {
            return
        }

        const deletesToCommit = pendingDeletes.filter(({ startCommit }) => startCommit)
        const idsToDelete = deletesToCommit.map(({ recordId }) => recordId)
        if (deletesToCommit.length === 0) {
            return
        }

        // Reset the failedCommit/startCommit flags on these records because we're sending them now to the server
        setState((prevState) => ({
            ...prevState,
            pendingDeletes: prevState.pendingDeletes.map((pendingDelete) =>
                idsToDelete.includes(pendingDelete.recordId)
                    ? { ...pendingDelete, failedCommit: false, startCommit: false }
                    : pendingDelete
            ),
        }))

        try {
            await deleteRecords(idsToDelete)
            setState((prevState) => {
                // Remove these records from pendingRecordChanges
                const newPendingEdits = { ...prevState.pendingEdits }
                for (const id of idsToDelete) {
                    delete newPendingEdits[id]
                }

                return {
                    ...prevState,
                    allRecords: prevState.allRecords.filter(
                        (record) => !idsToDelete.includes(record._sid)
                    ),
                    pendingEdits: newPendingEdits,
                    // Remove these records from pendingDeletes
                    pendingDeletes: prevState.pendingDeletes.filter(
                        ({ recordId }) => !idsToDelete.includes(recordId)
                    ),
                }
            })
        } catch (error) {
            track('WIP - Frontend - DataGrid - Delete record - Failed', {
                records: deletesToCommit,
            })

            // Update the failedRecords collection to show these records have failed to delete
            const newFailedRecords = deletesToCommit.reduce<{ [keyof: string]: FailedCommit }>(
                (failedRecords, failedRecord) => ({
                    ...failedRecords,
                    [failedRecord.recordId]: {
                        isDelete: true,
                    },
                }),
                {}
            )

            setState((prevState) => ({
                ...prevState,
                allRecords: [
                    ...prevState.allRecords,
                    ...records.filter(({ _sid }) => idsToDelete.includes(_sid)),
                ],
                pendingDeletes: prevState.pendingDeletes.map((pendingDelete) =>
                    idsToDelete.includes(pendingDelete.recordId)
                        ? { ...pendingDelete, failedCommit: true }
                        : pendingDelete
                ),
                failedRecords: { ...prevState.failedRecords, ...newFailedRecords },
            }))
        }
    }, [deleteRecords, pendingDeletes, records, track])

    const retryFailures = useCallback(async () => {
        track('WIP - Frontend - DataGrid - Retry')

        setState((prevState) => ({
            ...prevState,
            pendingDeletes: prevState.pendingDeletes.map((pendingDelete) =>
                pendingDelete.failedCommit ? { ...pendingDelete, startCommit: true } : pendingDelete
            ),
        }))

        for (const recordId of Object.keys(pendingEdits)) {
            // Don't submit any edits for records which are being deleted
            if (pendingDeletes.find((pendingDelete) => pendingDelete.recordId === recordId)) {
                continue
            }

            const edits = pendingEdits[recordId] || []
            const editsToCommit = edits.filter(({ failedCommit }) => failedCommit)
            if (editsToCommit.length > 0) {
                _processPendingRecordEdits(recordId, editsToCommit)
            }
        }

        for (const pendingNewRecord of pendingNewRecords) {
            // Don't submit any creates for records which are being deleted
            if (
                pendingDeletes.find(
                    (pendingDelete) => pendingDelete.recordId === pendingNewRecord.record._sid
                )
            ) {
                continue
            }

            if (pendingNewRecord.failedCommit) {
                createRecord(pendingNewRecord.record, pendingNewRecord.record)
            }
        }

        setState((prevState) => ({ ...prevState, failedRecords: {} }))
    }, [
        _processPendingRecordEdits,
        createRecord,
        pendingDeletes,
        pendingEdits,
        pendingNewRecords,
        track,
    ])

    useEffect(() => {
        // Do nothing while there are pending operations
        if (
            pendingNewRecords.length > 0 ||
            Object.keys(pendingEdits).length > 0 ||
            pendingDeletes.length > 0
        ) {
            return
        }

        setState((prevState) => ({
            ...prevState,
            allRecords: records,
        }))
    }, [pendingDeletes.length, pendingEdits, pendingNewRecords, records])

    // Check to see if we have any edits which have been flagged ready to commit.
    useEffect(() => {
        for (const recordId of Object.keys(pendingEdits)) {
            const edits = pendingEdits[recordId] || []
            const editsToCommit = edits.filter((edit) => edit.startCommit)

            if (editsToCommit.length) {
                _processPendingRecordEdits(recordId, editsToCommit)
            }
        }
    }, [pendingEdits, _processPendingRecordEdits])

    // Process any pendingDeletes which were held back and are now ready to process
    // due to being against records which were pending creation
    useEffect(() => {
        _processPendingRecordDeletes()
    }, [_processPendingRecordDeletes])

    return useMemo(
        () => ({
            records: allRecords,
            pendingNewRecords,
            pendingEdits,
            pendingDeletes,
            failedRecords,
            addRecord: _addRecord,
            editRecord,
            deleteRecords: _deleteRecords,
            retryFailures,
        }),
        [
            _addRecord,
            _deleteRecords,
            allRecords,
            editRecord,
            failedRecords,
            pendingDeletes,
            pendingEdits,
            pendingNewRecords,
            retryFailures,
        ]
    )
}

export default useRecordEditManager
