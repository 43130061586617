import React, { useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'
import { OpenRecordFunction, RowLinkFunction, ViewHeader, ViewRow } from 'v2/views/List/types'
import { ListViewOrderBy } from 'v2/views/utils/orderBy/types'

import ActionButtonsList from 'features/actions/ActionButtonsList'

import Table from 'v2/ui/components/Table'

type TableRendererListProps = {
    object?: ObjectDto
    data?: ViewRow[]
    headers?: ViewHeader[]
    rowLinkFunction?: RowLinkFunction
    additionalContainerContent?: React.ReactNode
    viewOptions?: ListViewOptions
    setViewOptions?: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    isEditing?: boolean
    orderBy?: ListViewOrderBy
    setOrderBy?: (orderBy: ListViewOrderBy) => void
    openRecord?: OpenRecordFunction
}

export const TableRendererList: React.FC<TableRendererListProps> = ({
    object,
    data,
    headers,
    rowLinkFunction,
    additionalContainerContent,
    viewOptions,
    setViewOptions,
    isEditing = false,
    orderBy,
    setOrderBy,
    openRecord,
}) => {
    // Style each row's wrapper.
    const modifiedData = useMemo(() => {
        return data?.map((d) => {
            const newData = { ...d }

            // Override the action buttons' isEditing state.
            if (
                React.isValidElement<React.ComponentPropsWithoutRef<typeof ActionButtonsList>>(
                    newData.actionButtons
                )
            ) {
                newData.actionButtons = React.cloneElement(newData.actionButtons, {
                    showControls: isEditing,
                })
            }

            return newData
        })
    }, [data, isEditing])

    const { colors } = useTheme()

    return (
        /* @ts-expect-error */
        <Table
            header={headers}
            data={modifiedData}
            object={object}
            rowLink={rowLinkFunction}
            additionalListContainerContent={additionalContainerContent}
            viewOptions={viewOptions}
            setConfig={setViewOptions}
            onOrderByChange={setOrderBy}
            orderBy={orderBy}
            isEditMode={false}
            boxShadow="unset"
            backgroundColor="transparent"
            spacing={0}
            mb={0}
            headerPadding="0.5rem"
            tablePadding="1.2rem"
            tablePaddingRight="1.2rem"
            rowLgPadding="0.5rem"
            borderColor={colors.userInterface.neutral[200]}
            headerTextColor={colors.userInterface.neutral[850]}
            rowBorderStyle="solid"
            useClassNames={false}
            openRecord={openRecord}
        />
    )
}
