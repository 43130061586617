import { useMemo } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'
import { RowData } from 'features/datagrid/types'
import { getNewRecordButton, getNewRecordPlaceholder } from 'features/datagrid/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useGridData(records: RecordDto[], hideAddRecords: boolean) {
    const { showEmptyRecordRow, canAddRecords } = useDataGridContext()

    const rows = useMemo<RowData[]>(() => {
        if (!canAddRecords || hideAddRecords) return records

        if (showEmptyRecordRow) {
            return [...records, getNewRecordPlaceholder()]
        }

        return [...records, getNewRecordButton()]
    }, [records, showEmptyRecordRow, canAddRecords, hideAddRecords])

    return useDeepEqualsMemoValue(rows)
}
