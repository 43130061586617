type UseLongTextAttributeDisplayStateProps = {
    value?: string
    maxLength?: number
    isLoading?: boolean
}

export function useLongTextAttributeDisplayState({
    value,
    maxLength,
    isLoading,
}: UseLongTextAttributeDisplayStateProps) {
    const content = isLoading ? PLACEHOLDER_VALUE : value ?? ''

    const isOverflowing = value && maxLength && value.length > maxLength

    return { content, isOverflowing }
}

const PLACEHOLDER_VALUE =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus sit amet nunc tincidunt aliquam.'
