import React, { useRef } from 'react'

import { Rights, useAppUserContext } from 'app/AppUserContext'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Flex, LoadingScreen } from 'v2/ui'

import { Button } from 'ui/components/Button'

import { TableGrantForm } from './TableGrants/TableGrantForm'
import ManualAppUsers from './ManualAppUsers'
import { useUserModal } from './UserModalContext'

export const AppUsersModalContent: React.FC = () => {
    const { hasRight } = useAppUserContext()
    const { data: users = [], isLoading: usersLoading } = useAppUsersForAdmin(true)

    const formRef = useRef({})
    const { modalState: displayChangesModal } = useUnsavedChangesModalCallback(formRef)

    const userCount = users.length
    const isLoading = usersLoading

    const unsavedChangesModal = displayChangesModal ? (
        // @ts-expect-error
        <UnsavedChangesModal {...displayChangesModal} />
    ) : null

    const { setMode, mode, editData } = useUserModal()

    const openGrantEditor = () => {
        setMode('tableGrant', null)
    }
    const closeGrantEditor = () => {
        setMode('users', null)
    }

    if (!hasRight(Rights.Admin.Any)) return null

    return (
        <LoadingScreen isLoading={isLoading} flexShrink={1} minHeight="0" keepChildrenMounted>
            {mode === 'tableGrant' ? (
                <TableGrantForm onClose={closeGrantEditor} tableGrant={editData} />
            ) : (
                <Flex column align="stretch" height="100%" maxHeight="100%" wrap="nowrap">
                    <FormHeader
                        title={`App users (${userCount})`}
                        subtitle={null}
                        rightSideContent={
                            <Button
                                size="s"
                                variant="secondary"
                                startIcon={{ name: 'Table' }}
                                onClick={openGrantEditor}
                            >
                                Create table group
                            </Button>
                        }
                    />

                    <Box flexGrow={1} flexShrink={1} minHeight={0} mt={4}>
                        <ManualAppUsers formRef={formRef}>{unsavedChangesModal}</ManualAppUsers>
                    </Box>
                </Flex>
            )}
        </LoadingScreen>
    )
}
