import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { useAppUserContext } from 'app/AppUserContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Banner, Box, Button } from 'v2/ui'

const Wrapper = styled(Box)<{ isOpen: boolean; rightOffset: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    right: ${(props) => props.rightOffset}px;
    bottom: 12px;
    transform: ${(props) => (props.isOpen ? 'translateY(0px)' : 'translateY(150%)')};
    transition: transform 0.5s ease-in-out;
    z-index: 1000;
`

type UnsavedChangesBannerProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isDirty: boolean
    saveChanges: () => void
    discardChanges: () => void

    rightOffset?: number
}

export const UnsavedChangesBanner: React.VFC<UnsavedChangesBannerProps> = ({
    isDirty,
    saveChanges,
    discardChanges,
    ...props
}) => {
    const { state } = useSlidingPane()
    const { isPreviewingAsUserOrRole } = useAppUserContext()

    const rightOffset = useMemo(() => {
        if (isPreviewingAsUserOrRole) {
            return 90
        }

        return state.key ? 20 + (state.contentWidth as number) : 90
    }, [isPreviewingAsUserOrRole, state.contentWidth, state.key])

    return (
        <Wrapper position="fixed" isOpen={isDirty} rightOffset={rightOffset} {...props}>
            <Banner justifyContent="center" icon="info" whiteSpace="nowrap">
                You have unsaved changes.
                <Button ml={3} variant="Secondary" buttonSize="small" onClick={discardChanges}>
                    Discard changes
                </Button>
                <Button ml={2} variant="Primary" buttonSize="small" onClick={saveChanges}>
                    Save changes
                </Button>
            </Banner>
        </Wrapper>
    )
}
