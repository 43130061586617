import React, { forwardRef, useRef } from 'react'

import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import DateAttribute from 'v2/ui/components/Attribute/DateAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCell, DataGridCellEditorComponent, DataGridCellRendererComponent } from './types'

import { DateCellWrapperStyle } from './DateCell.css'

type DateCellRendererProps = {}

const DateCellRenderer: DataGridCellRendererComponent<DateCellRendererProps> = ({
    value,
    field,
}) => {
    if (!value) return null

    const showTime = field.type === 'datetime'

    return (
        <Box flex center gap="xs" height="full" width="full" overflowX="auto">
            {/* @ts-expect-error */}
            <DateAttribute
                mode={modes.readonly}
                onChange={() => {}}
                showTime={showTime}
                field={field}
            >
                {value}
            </DateAttribute>
        </Box>
    )
}

type DateCellEditorProps = {}

const DateCellEditor: DataGridCellEditorComponent<DateCellEditorProps> = forwardRef(
    ({ value, field }, ref) => {
        const [newValue, setNewValue] = useEditorValue<string>(ref, value)

        const inputElementRef = useRef<HTMLInputElement | null>(null)

        useTypeInCellToEdit({
            getInputElement: () => inputElementRef.current,
        })

        const showTime = field.type === 'datetime'

        return (
            <Box
                ref={(el: HTMLDivElement | null) => {
                    inputElementRef.current = el?.querySelector('input[type="text"]') ?? null
                }}
                height="full"
                width="full"
                tabIndex={0}
                className={DateCellWrapperStyle}
            >
                {/* @ts-expect-error */}
                <DateAttribute
                    mode={modes.editing}
                    onChange={setNewValue}
                    showTime={showTime}
                    field={field}
                    formStyle={{ width: '100%' }}
                    renderInline
                    hideBorder
                >
                    {newValue}
                </DateAttribute>
            </Box>
        )
    }
)

export const DateCell: DataGridCell = {
    provideRenderer: DateCellRenderer,
    provideEditor: DateCellEditor,
    popupEditor: true,
}
