import moment from 'moment'

import { TaskPayload } from 'data/hooks/tasks/types'
import {
    isDueDateFuture,
    isDueDateOverdue,
    isDueDateSoon,
    isDueDateToday,
    parseTaskDueDate,
} from 'features/tasks/categoryUtils'
import { CategorizedTasksGeneric } from 'features/tasks/types'

type TaskCategory = 'overdue' | 'today' | 'unscheduled' | 'soon' | 'future' | 'completed'
export type TasksByDueDateAndCompleted = CategorizedTasksGeneric<TaskCategory>

export function groupByDueDateAndCompleted(tasks: TaskPayload[] = []): TasksByDueDateAndCompleted {
    const categorizedTasks = tasks.reduce<TasksByDueDateAndCompleted>(
        (agg, task) => {
            if (task.is_completed) {
                agg.completed.tasks.push(task)
                return agg
            }

            const dueDate = parseTaskDueDate(task)
            if (!dueDate) {
                agg.unscheduled.tasks.push(task)
                return agg
            }

            switch (true) {
                case isDueDateOverdue(dueDate):
                    agg.overdue.tasks.push(task)
                    break
                case isDueDateToday(dueDate):
                    agg.today.tasks.push(task)
                    break
                case isDueDateSoon(dueDate):
                    agg.soon.tasks.push(task)
                    break
                case isDueDateFuture(dueDate):
                    agg.future.tasks.push(task)
                    break
            }

            return agg
        },
        {
            overdue: { label: 'Overdue', tasks: [], defaultExpanded: true },
            today: { label: 'Today', tasks: [], defaultExpanded: true },
            unscheduled: { label: 'Unscheduled', tasks: [], defaultExpanded: true },
            soon: { label: 'Due soon', tasks: [], defaultExpanded: true },
            future: { label: 'Future', tasks: [], defaultExpanded: true },
            completed: { label: 'Completed', tasks: [], defaultExpanded: false },
        }
    )

    for (const category of Object.keys(categorizedTasks)) {
        categorizedTasks[category as TaskCategory].tasks = sortTasks(
            category as TaskCategory,
            categorizedTasks[category as TaskCategory].tasks
        )
    }

    return categorizedTasks
}

function sortTasks(category: TaskCategory, tasks: TaskPayload[]): TaskPayload[] {
    return tasks.sort((a, b) => {
        if (category === 'completed') {
            const aCompletionDate = moment.utc(a.completed_at)
            const bCompletionDate = moment.utc(b.completed_at)

            return aCompletionDate.isAfter(bCompletionDate) ? -1 : 1
        }

        const aDueDate = parseTaskDueDate(a)
        const bDueDate = parseTaskDueDate(b)

        if (!aDueDate || !bDueDate) {
            const aCreationDate = moment.utc(a.created_at)
            const bCreationDate = moment.utc(b.created_at)

            return aCreationDate.isBefore(bCreationDate) ? -1 : 1
        }

        return aDueDate.isBefore(bDueDate) ? -1 : 1
    })
}
