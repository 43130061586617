import React from 'react'

import { Wrap, WrapItem } from '@chakra-ui/react'

import { LINK_TYPES } from 'data/utils/fieldDefinitions'
import { AttachmentInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/AttachmentInlineFilter'
import { BooleanInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/BooleanInlineFilter'
import { CurrencyInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/CurrencyInlineFilter'
import { DateInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/DateInlineFilter'
import { DropdownInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/DropdownInlineFilter'
import { LookupInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/LookupInlineFilter'
import { NumberInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/NumberInlineFilter'
import { PercentageInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/PercentageInlineFilter'
import { TextInlineFilter } from 'features/records/components/RecordFilters/inlineFilters/components/TextInlineFilter'
import { InlineFilterComponent } from 'features/records/components/RecordFilters/inlineFilters/components/types'
import { RecordFiltersProvider } from 'features/records/components/RecordFilters/provider/RecordFiltersProvider'

import type { ViewColumn } from './types'

const FILTER_COMPONENTS_BY_FIELD_TYPE: Partial<
    Record<FieldDto['type'], InlineFilterComponent<unknown>>
> = {
    checkbox: BooleanInlineFilter,
    dropdown: DropdownInlineFilter,
    multi_select: DropdownInlineFilter,
    string: TextInlineFilter,
    long_text: TextInlineFilter,
    url: TextInlineFilter,
    image: TextInlineFilter,
    formula: TextInlineFilter,
    number: NumberInlineFilter,
    file: AttachmentInlineFilter,
    multi_file: AttachmentInlineFilter,
    percentage: PercentageInlineFilter,
    currency: CurrencyInlineFilter,
    currency_varying: CurrencyInlineFilter,
    lookup: LookupInlineFilter,
    multi_lookup: LookupInlineFilter,
    date: DateInlineFilter,
    datetime: DateInlineFilter,
}

type InlineFiltersProps = {
    object: ObjectDto
    columns?: ViewColumn[]
    onChange: (filters: Filter[]) => void
    userFilterFieldIds: string[]
    filters: Filter[]
}

function getFilterComponent(field: FieldDto) {
    if (LINK_TYPES.includes(field.type) && field.is_foreign) {
        return TextInlineFilter
    }

    return FILTER_COMPONENTS_BY_FIELD_TYPE[field.type]
}

const InlineFilters: React.FC<InlineFiltersProps> = ({
    object,
    columns,
    onChange,
    userFilterFieldIds,
    filters,
}) => {
    return (
        <RecordFiltersProvider
            includeIncompleteFilters={false}
            onChange={onChange}
            object={object}
            initialFilters={filters}
        >
            <Wrap spacing={2}>
                {userFilterFieldIds.map((fieldId) => {
                    const field = object.fields.find((f) => f._sid === fieldId) as FieldDto
                    if (field) {
                        const FilterComponent = getFilterComponent(field)
                        return (
                            !!FilterComponent && (
                                <WrapItem>
                                    <FilterComponent field={field} columns={columns} />
                                </WrapItem>
                            )
                        )
                    }
                })}
            </Wrap>
        </RecordFiltersProvider>
    )
}

export default InlineFilters
