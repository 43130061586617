import React from 'react'

import { mergeAttributes } from '@tiptap/core'
import { TaskItem } from '@tiptap/extension-task-item'
import {
    NodeViewContent,
    NodeViewRendererProps,
    NodeViewWrapper,
    ReactNodeViewRenderer,
    wrappingInputRule,
} from '@tiptap/react'
import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Checkbox } from 'ui/components/Checkbox'

import { CheckItemCheckboxLabelStyle, CheckItemStyle } from './CheckItemExtension.css'

export function createCheckItemExtension() {
    return TaskItem.extend({
        addNodeView() {
            return ReactNodeViewRenderer(CheckItemComponent, {
                as: 'li',
            })
        },
        parseHTML() {
            return [{ tag: 'check-item' }]
        },
        renderHTML({ HTMLAttributes }) {
            return ['check-item', mergeAttributes(HTMLAttributes)]
        },
        addInputRules() {
            return [
                wrappingInputRule({
                    find: inputRegexp,
                    type: this.type,
                    getAttributes: (match) => ({
                        checked: match[match.length - 1] === 'x',
                    }),
                }),
            ]
        },
    }).configure({
        nested: true,
    })
}

const inputRegexp = /^\s*(-?\[([( |x])?\])\s$/

type CheckItemComponentProps = NodeViewRendererProps & {}

const CheckItemComponent: React.FC<CheckItemComponentProps> = ({ node, editor, getPos }) => {
    const isChecked = node.attrs.checked

    const handleCheckedChange = () => {
        if (!editor.isEditable) return

        editor
            .chain()
            .focus(undefined, { scrollIntoView: false })
            .command(({ tr }) => {
                if (typeof getPos !== 'function') return false

                const pos = getPos()
                const currNode = tr.doc.nodeAt(pos)

                tr.setNodeMarkup(pos, undefined, {
                    ...currNode?.attrs,
                    checked: !isChecked,
                })

                return true
            })
            .run()
    }

    return (
        <NodeViewWrapper
            as={Box}
            flex
            alignItems="flex-start"
            flexWrap="nowrap"
            className={classNames('check-item', CheckItemStyle)}
        >
            <Checkbox checked={isChecked} onCheckedChange={handleCheckedChange} />
            <NodeViewContent as={Box} ml="m" className={CheckItemCheckboxLabelStyle} />
        </NodeViewWrapper>
    )
}
