import React, { useMemo } from 'react'

import { BoxProps } from 'ui/components/Box/Box'

import { WorkflowChain } from './WorkflowChain'
import { useWorkflowEditorContext } from './WorkflowEditorContext'

import { CanvasStyle } from './WorkflowCanvasStyles.css'

type WorkflowCanvasProps = BoxProps & { workflow: WorkflowDto }
export function WorkflowCanvas({ workflow, ...props }: WorkflowCanvasProps) {
    const { setSelectedItem, setSelectedLine } = useWorkflowEditorContext()

    const items: WorkflowItem[] = useMemo(
        () => [workflow.trigger, ...workflow.chain],
        [workflow.chain, workflow.trigger]
    )

    const hasTriggerType = Boolean(workflow.trigger.trigger_type)

    return (
        <WorkflowChain
            {...props}
            flex
            column
            center
            p="4xl"
            onClick={() => {
                setSelectedItem(undefined)
                setSelectedLine(undefined)
            }}
            className={CanvasStyle}
            items={items}
            onlyShowTrigger={!hasTriggerType}
        />
    )
}
