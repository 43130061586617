import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

type UseBoardViewSubtitleStateOptions = {
    record: RecordDto
}

export function useBoardViewSubtitleState(options: UseBoardViewSubtitleStateOptions) {
    const { record } = options

    const { subtitleField, view } = useBoardViewContext()

    const fieldDisplayOptions = useMemo(
        () => (subtitleField ? view.options.boardCardSubtitle?.fieldDisplayOptions : {}),
        [subtitleField, view.options.boardCardSubtitle?.fieldDisplayOptions]
    )

    const attribute: BoardViewAttribute | undefined = useMemo(() => {
        if (!subtitleField) return undefined

        return {
            id: subtitleField._sid,
            title: subtitleField.label,
            field: subtitleField,
            rawOptions: fieldDisplayOptions ?? {},
            type: 'subtitle',
        }
    }, [subtitleField, fieldDisplayOptions])

    const value = subtitleField ? record[subtitleField?.api_name] ?? undefined : undefined

    return useMemo(
        () => ({
            attribute,
            value,
        }),
        [attribute, value]
    )
}
