// @ts-strict-ignore
import React from 'react'

import { useFields } from 'data/hooks/fields'
import { useObjects } from 'data/hooks/objects'
import { createObject, deleteObject, updateObject } from 'data/hooks/objects/objectOperations'

const WithObjects = ({ children }) => {
    const { data: objects = [], isFetched: objectsFetched } = useObjects()
    // Fetch the fields only when the objects have been fetched
    const { isFetched: fieldsFetched } = useFields({ options: { enabled: objectsFetched } })

    return children({
        objects,
        createObject,
        onChange: updateObject,
        removeObject: deleteObject,
        fetchedObjects: objectsFetched && fieldsFetched,
        fetched: objectsFetched,
    })
}

export default WithObjects

export const withObjects = (Child) => (props) =>
    <WithObjects>{(objProps) => <Child {...objProps} {...props} />}</WithObjects>
