import React, { useCallback, useMemo } from 'react'

import { forwardRef } from '@chakra-ui/react'
import { Moment } from 'moment'

import { useDateFilter } from 'features/records/components/RecordFilters/inlineFilters/components/DateFilterContext'
import { InlineFilterMultiValueInput } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterMultiValueInput'
import { InlineFilterOption } from 'features/records/components/RecordFilters/inlineFilters/InlineFilterOption'
import { formatDateAsString } from 'utils/dateUtils'

import { Box } from 'v2/ui'
import DateAttribute from 'v2/ui/components/Attribute/DateAttribute'
import { CalendarOutline } from 'v2/ui/svgs'
import { modes } from 'v2/ui/utils/attributeSettings'

import type { InlineFilterInputComponent } from './types'

type DateInputProps = {}

export const DateInput: InlineFilterInputComponent<DateInputProps> = ({
    field,
    value = '',
    onChange,
    options,
}) => {
    return (
        <InlineFilterMultiValueInput
            key="date"
            options={options}
            onChange={onChange}
            value={value}
            singleValue={true}
            customOptionComponent={CustomOption}
            // @ts-expect-error
            optionProps={{ field }}
        />
    )
}

type CustomOptionComponent = Required<
    React.ComponentPropsWithoutRef<typeof InlineFilterMultiValueInput>
>['customOptionComponent']

type CustomOptionComponentProps = React.ComponentPropsWithRef<CustomOptionComponent> & {
    field?: FieldDto
}

const CustomOption = forwardRef<CustomOptionComponentProps, 'button'>(
    ({ field, value, label, isChecked, ...props }, ref) => {
        const { specificDate, setSpecificDate } = useDateFilter()

        const isCustomDate = value === '_customDate' || value === '_inBetweenDates'
        const showTime = field?.type === 'datetime'

        const newLabel = useMemo(() => {
            if (isCustomDate && specificDate) {
                if (Array.isArray(specificDate)) {
                    const formattedDateRange = specificDate.map((date) =>
                        formatDateAsString(date, field, showTime)
                    )
                    return formattedDateRange.join(' - ')
                }
                const formattedDate = formatDateAsString(specificDate, field, showTime)
                if (formattedDate) return formattedDate
            }

            return label
        }, [field, isCustomDate, label, showTime, specificDate])

        const getStringFromDate = useCallback(
            (value: string | Moment, showTime: boolean): string => {
                if (typeof value == 'string') {
                    return value
                }
                return showTime ? value.toISOString() : value.format('YYYY-MM-DD')
            },
            []
        )

        const handleChange = useCallback(
            (value: string | string[] | Moment | Moment[]) => {
                if (Array.isArray(value)) {
                    const dateStrings: string[] = value.map((date: string | Moment) =>
                        getStringFromDate(date, showTime)
                    )
                    setSpecificDate(dateStrings)
                    return
                }

                setSpecificDate(getStringFromDate(value, showTime) as string)
            },
            [setSpecificDate, showTime, getStringFromDate]
        )

        if (!isCustomDate) {
            return (
                <InlineFilterOption
                    ref={ref}
                    role="button"
                    label={newLabel}
                    isChecked={isChecked}
                    {...props}
                />
            )
        }

        return (
            <DateAttribute
                // @ts-expect-error
                mode={modes.editing}
                showTime={showTime}
                field={field}
                onChange={handleChange}
                formStyle={{
                    width: '100%',
                    borderWidth: 0,
                    fontSize: '0.8rem',
                    color: 'userInterface.neutral.1000',
                }}
                hideBorder
                selectsRange={value === '_inBetweenDates'}
                customInput={
                    <InlineFilterOption
                        ref={ref}
                        role="button"
                        label={newLabel}
                        isChecked={isChecked}
                        rightAdorner={
                            <Box border={0} padding={0} background="none">
                                <CalendarOutline
                                    color={isChecked ? 'brand.400' : 'userInterface.neutral.1000'}
                                    size={14}
                                    aria-hidden={true}
                                />
                            </Box>
                        }
                    />
                }
            />
        )
    }
)
