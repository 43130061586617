import React from 'react'

import styled from '@emotion/styled'

import { getWorkspaceAccount } from 'app/GlobalStaticState'
import publicAsset from 'utils/publicAsset'

import { Box } from 'v2/ui'

export const limitStackNameLogToChars = (name = '', chars) =>
    // ... is only about 2 chars wide,
    // so if over limit just sub in last 2 chars up to limit with ...
    name.length > chars ? name.substring(0, chars - 2) + '...' : name

const DefaultStackNameLogoIcon = ({ brandColor, navColor }) => (
    <svg viewBox="0 0 64 64">
        <path
            style={{
                stroke: 'none',
                fillRule: 'nonzero',
                fillOpacity: 1,
                fill:
                    navColor === 'brand'
                        ? // for brand color theme use same color as text in logo, adjusted to be light or dark depending on brand color
                          'currentColor'
                        : // for dark theme make it white
                        navColor === 'dark'
                        ? '#fff'
                        : // for white theme make it brand color
                          brandColor,
            }}
            d="M 0.988281 32 C 0.988281 14.875 14.875 0.988281 32 0.988281 C 49.125 0.988281 63.011719 14.875 63.011719 32 C 63.011719 49.125 49.125 63.011719 32 63.011719 C 14.875 63.011719 0.988281 49.125 0.988281 32 Z M 0.988281 32 "
        />
        <path
            style={{
                stroke: 'none',
                fillRule: 'nonzero',
                fillOpacity: 1,
                fill:
                    navColor === 'dark' || navColor === 'brand'
                        ? // for dark theme or brand color theme, make star brand color
                          brandColor
                        : // for white theme, make it white (the circle background is brand color)
                          '#fff',
            }}
            d="M 32 12.238281 L 38.101562 24.585938 L 51.726562 26.574219 L 41.863281 36.1875 L 44.1875 49.761719 L 32 43.351562 L 19.800781 49.761719 L 22.136719 36.1875 L 12.273438 26.574219 L 25.898438 24.585938 Z M 32 12.238281 "
        />
    </svg>
)

const DefaultStackNameLogoContainer = styled(Box)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        flex-shrink: 0;
        margin-right: 10px;
        width: 28px;
        height: 28px;

        & > path {
            transition: fill 0.2s linear;
        }
    }

    .text {
        font-family: Proxima Nova;
        font-weight: bold;
        padding-top: 2px;
        letter-spacing: -0.02em;
        transition: color 0.2s linear;
        overflow: hidden;
        color: inherit;

        ${(p) => (p.wrapText ? '' : 'white-space: nowrap;')}
    }

    @media (max-width: 768px) {
        height: 26px;

        svg {
            width: 24px;
            height: 24px;
        }

        .text {
            padding-top: 2px;
            font-size: ${(p) => p.fontSize - 2}px;
        }
    }
`

export const DefaultStackNameLogo = ({ name, brandColor, navColor, wrapText }) => {
    // TODO when we've upgraded to chakra 1.0,
    // can just pull button.text out of the theme (via useTheme()) to avoid this override hack
    const colorForNavBarTheme =
        navColor === 'dark'
            ? '#fff'
            : navColor === 'brand'
            ? // if nav bar is brand color, the color is set using button.text,
              // takes into account contrast with brand color
              undefined
            : // if white theme (which is default) use dark text
              '#111010'

    // we can't show names that are too long for styling reasons
    let fontSize = 22
    let nameMaxLength = wrapText ? 35 : 22

    // try to display in a smaller font if too long

    if (name.length > nameMaxLength) {
        nameMaxLength = wrapText ? 40 : 28
        fontSize = 16
    }

    // truncate name if still too long
    const displayName = limitStackNameLogToChars(name, nameMaxLength)

    return (
        <DefaultStackNameLogoContainer
            color="button.text"
            style={{ color: colorForNavBarTheme }}
            wrapText={!!wrapText}
            fontSize={fontSize}
        >
            <DefaultStackNameLogoIcon brandColor={brandColor} navColor={navColor} />
            <Box className="text">{displayName}</Box>
        </DefaultStackNameLogoContainer>
    )
}

export const getLogoUrl = (stack, useIconIfNoLogo = false, defaultLogo = null) => {
    if (defaultLogo === null) {
        defaultLogo = publicAsset('/static/media/stacker-logo-s-only.png')
    }
    const workspaceAccount = getWorkspaceAccount()
    const { options } = stack ?? {}

    let logo = options?.theme?.logo
    if (!logo && workspaceAccount) {
        if (workspaceAccount.options?.theme?.branding === 'logo') {
            logo = workspaceAccount.options?.theme?.logo
        } else if (workspaceAccount.options?.theme?.branding === 'logo_icon') {
            logo = workspaceAccount.options?.theme?.logo_icon
        }
    }

    if (!logo && useIconIfNoLogo) {
        if (!options.theme?.icon?.startsWith('fa') || options.theme?.icon?.includes('.')) {
            logo = options?.theme?.icon
        }
    }

    return logo || defaultLogo
}
