import { useMemo } from 'react'

import { useActions } from 'data/hooks/actions'
import { getFilterFields } from 'features/records/components/logic/recordLogic'
import { getOrderFields } from 'features/records/components/RecordOrder'
import { getIncludedFieldsFromActionButtons } from 'features/views/ListView/Actions/utils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { useSortContext } from 'features/views/ListView/Sort/SortContext'

export function useIncludedFields(additionalFieldApiNames?: string[]) {
    const { view, visibleFields, allFields } = useListViewContext()
    const { sortBy } = useSortContext()
    const { data: actions } = useActions()

    return useMemo(() => {
        const filterFields = new Set<string>()

        const { filters = [] } = view.options
        for (const apiName of getFilterFields(filters)) {
            filterFields.add(apiName)
        }
        if (sortBy) {
            filterFields.add(getOrderFields([sortBy]))
        }

        const actionButtonFields = getIncludedFieldsFromActionButtons(
            view.options.actionButtons,
            actions
        )
        for (const apiName of actionButtonFields) {
            filterFields.add(apiName)
        }

        for (const field of visibleFields) {
            filterFields.add(field.api_name)
        }

        if (additionalFieldApiNames) {
            for (const apiName of additionalFieldApiNames) {
                filterFields.add(apiName)
            }
        }

        const fields: FieldDto[] = allFields
        const fieldsByApiName = fields.reduce(
            (acc, field) => acc.set(field.api_name, field),
            new Map<string, FieldDto>()
        )

        const includedFields = Array.from(filterFields).reduce((acc, fieldApiName) => {
            const field = fieldsByApiName.get(fieldApiName)
            if (field) {
                acc.push(field)
            }

            return acc
        }, [] as FieldDto[])

        return includedFields
    }, [actions, additionalFieldApiNames, allFields, sortBy, view.options, visibleFields])
}
