import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'app/AppContext'
import { getStackTheme } from 'app/Branding'
import { getUrl, Urls } from 'app/UrlService'
import { AppBar } from 'features/AppBar/AppBar'
import { UserMenu } from 'features/AppBar/UserMenu'
import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { useIsBannerShowing } from 'features/core/useIsBannerShowing'
import { DefaultStackNameLogo, getLogoUrl } from 'features/utils/getLogo'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import WorkspaceLogo from 'features/workspace/WorkspaceLogoDeprecated'
import { wsNavThemeToV3 } from 'features/workspace/WorkspaceSettingsModalUi'
import analytics from 'utils/analytics'

import { Avatar, Box, Divider, Flex, Icon, ScrollBox, Text, Tooltip } from 'v2/ui'
import useNavigationColor from 'v2/ui/hooks/useNavigationColor'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import AppNavTree from './AppNavTree'

export default function SidebarWorkspace(props) {
    const { selectedStack: stack } = useAppContext()

    const theme = stack?.options?.theme ?? {}
    const navTheme = useNavigationColor(theme)
    // Use default for appBar
    const appBarTheme = useNavigationColor({})

    return (
        <Flex wrap="nowrap" align="stretch" height="100%" maxHeight="100%" maxWidth="100%">
            <AppBar navTheme={appBarTheme} />
            {stack?._sid && (
                <Sidebar
                    showSideNav={props.showSideNav}
                    navTheme={navTheme}
                    stack={stack}
                    onClickNavItem={props.onClickNavItem}
                />
            )}
        </Flex>
    )
}

const Sidebar = memo(function Sidebar({ navTheme, stack, showSideNav, onClickNavItem }) {
    const [isBannerShowing] = useIsBannerShowing()
    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    const isMobile = window.innerWidth < 768

    return (
        <Flex
            column
            wrap="nowrap"
            flexGrow={1}
            minWidth={0}
            align="stretch"
            background={navTheme.bgScdNav}
            onClick={handleClickOutsideSidePane}
            maxWidth="100%"
            marginTop={isBannerShowing && !isMobile ? `${BANNER_HEIGHT}px` : null}
            style={
                navTheme.theme === 'light'
                    ? { borderLeft: '1px solid #F5F7F8', borderRight: '1px solid #EEF0F1' }
                    : {}
            }
            className={ONBOARDING_CLASSES.APP_NAV_MENU}
            data-testid="app-nav-menu"
        >
            {!showSideNav ? (
                <Flex flexGrow={1} flexShrink={0} />
            ) : (
                <>
                    <SidebarHeader stack={stack} navTheme={navTheme} />
                    <Divider variant="custom" bg="#AAAAAA33" />
                    {stack.name.length >= 20 ? (
                        <Tooltip label={stack.name} placement="right">
                            <Flex px={4} pt={4} pb={1} style={{ paddingRight: 5 }}>
                                <div
                                    style={{
                                        ...V4DesignSystem.h2,
                                        color: navTheme.textBright,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {stack.name}
                                </div>
                            </Flex>
                        </Tooltip>
                    ) : (
                        <Flex px={4} pt={4} pb={1} style={{ paddingRight: 5 }}>
                            <div
                                style={{
                                    ...V4DesignSystem.h2,
                                    color: navTheme.textBright,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {stack.name}
                            </div>
                        </Flex>
                    )}
                    <ScrollBox
                        marginTop="10px"
                        overflowY="auto"
                        flexGrow={1}
                        flexShrink={1}
                        scrollbarColor={navTheme.highlightColor}
                    >
                        <AppNavTree
                            stack={stack}
                            navTheme={wsNavThemeToV3(navTheme)}
                            onClickNavItem={onClickNavItem}
                        />
                    </ScrollBox>
                </>
            )}
        </Flex>
    )
})

const AppLogo = ({ stack, logoStyle }) => {
    const theme = getStackTheme(stack)
    const stackLogo = getLogoUrl(stack, false, null)
    const showDefaultStackLogo = theme && !stackLogo && theme.enableDefaultStackLogo

    if (showDefaultStackLogo) {
        return (
            <Link to={getUrl(Urls.Home)}>
                <DefaultStackNameLogo
                    name={stack?.name}
                    navColor={theme.navColor}
                    brandColor={theme.brandColor}
                    wrapText
                />
            </Link>
        )
    }
    return <WorkspaceLogo logoStyle={logoStyle} />
}

export const SidebarHeader = ({ stack, navTheme, style, logoStyle, ...props }) => {
    return (
        <Box
            style={{ padding: '10px 5px', minHeight: 66, ...style }}
            textAlign="center"
            color={navTheme.textBright}
            className={STYLE_CLASSES.LOGO}
            {...props}
        >
            <AppLogo stack={stack} logoStyle={logoStyle} />
        </Box>
    )
}

export const UserMenuButton = ({
    user,
    navTheme,
    mobile = false,
    iconOnly = false,
    ...otherProps
}) => {
    const [userProfileMenuOpen, setUserProfileMenuOpen] = useState(false)

    const userMenuStyle = {
        backgroundColor: navTheme?.bgScdNav || navTheme?.button?.background || '#F4F5F8',
        color:
            navTheme?.textColorBright ||
            navTheme?.button?.color ||
            navTheme?.textBright ||
            '#ffffff',
    }

    const icon = user?.avatar ? <Avatar src={user.avatar} size="xs" /> : <Icon icon="avatar" />

    const menuProps = mobile
        ? {
              height: '24px',
              width: '24px',
              fontSize: 'sm',
          }
        : { width: !iconOnly ? '100%' : null, height: 10 }

    return (
        <UserMenu
            onClick={() => {
                analytics.track('user menu clicked', {
                    from: 'side nav',
                })
                setUserProfileMenuOpen(!userProfileMenuOpen)
            }}
            {...menuProps}
            {...otherProps}
            style={userMenuStyle}
        >
            {mobile || iconOnly ? (
                icon
            ) : (
                <Flex row width="100%" justify="space-between" align="center" wrap="nowrap">
                    <Flex row align="center" wrap="nowrap" flexShrink={1} minWidth={0}>
                        {icon}
                        <Text
                            color={navTheme?.textBright}
                            variant="profile"
                            ml="10px"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {user?.name || user?.email}
                        </Text>
                    </Flex>
                    <Icon
                        icon={userProfileMenuOpen ? 'arrowUp' : 'arrowDown'}
                        ml={2}
                        opacity={0.6}
                    />
                </Flex>
            )}
        </UserMenu>
    )
}
