import { z } from 'zod'

export type AppSchemaNoFieldsNoRecordsType = z.infer<typeof AppSchemaNoFieldsNoRecords>
export type AppSchemaType = z.infer<typeof AppSchema>
export type RequirementSchemaType = z.infer<typeof RequirementSchema>
export type AppRecordsSchemaType = z.infer<typeof AppRecordsSchema>

export const FieldSchema = z
    .object({
        id: z.string({
            description: 'Unique pair of object id and single-token id based on name',
        }),
        name: z.string({
            description:
                'Do not repeat object name in field name. Ie. do not use Company Name, use Name instead',
        }),
        type: z.enum([
            'checkbox',
            'currency',
            'date',
            'datetime',
            'dropdown',
            'document',
            'multi_select',
            'number',
            'percentage',
            'string',
            'relationship',
            'multi_relationship',
            'image',
        ]),
        target: z.union([z.string(), z.null()]).describe('target object id if relationship field'),
    })
    .strict()

export const BasicFieldSchema = z
    .object({
        id: z.string(),
        name: z.string(),
    })
    .strict()

export const RecordValueSchema = z
    .object({
        fieldId: z.string(),
        value: z.union([z.string(), z.number(), z.boolean(), z.null()]),
    })
    .strict()

export const RecordSchema = z
    .object({
        id: z.string({ description: 'object id + record index' }),
        fields: z.array(
            z.union([z.string(), z.number(), z.boolean(), z.null()], {
                description: 'one entry per field, can be null where appropriate',
            })
        ),
    })
    .strict()

export const ListViewSchema = z
    .object({
        id: z.string(),
        title: z.string(),
        layout: z.enum(['table', 'gallery', 'kanban']),
    })
    .strict()

export const ObjectSchema = z
    .object({
        id: z.string({ description: 'Single-token unique id based on object name' }),
        name: z.string(),
        // type: z.enum(['person', 'company', 'other']),
        // other_object_type: z.string().nullable(),
        fields: z.array(FieldSchema),
        layout: z.enum(['table', 'gallery']),
    })
    .strict()

export const ObjectRecordsSchema = z
    .object({
        id: z.string({ description: 'object id' }),
        records: z.array(RecordSchema),
    })
    .strict()

export const AppRecordsSchema = z
    .object({
        objects: z.array(ObjectRecordsSchema),
    })
    .strict()

export const ObjectSchemaNoFieldsNoRecords = z
    .object({
        id: z.string({ description: 'Single-token unique id based on object name' }),
        name: z.string(),
        description: z.string({ description: 'less than 5 word summary of object' }),
    })
    .strict()

export const AppSchema = z
    .object({
        id: z.string(),
        name: z.string(),
        objects: z.array(ObjectSchema),
    })
    .strict()

export const AppSchemaNoFieldsNoRecords = z
    .object({
        id: z.string(),
        name: z.string(),
        objects: z.array(ObjectSchemaNoFieldsNoRecords),
    })
    .strict()

export const SimplifiedAppSchema = z
    .object({
        apps: z.array(AppSchema),
    })
    .strict()

export const RequirementSchema = z.object({
    requirements: z.array(z.string()),
})
