import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { useAppContext } from 'app/AppContext'
import { TaskRelatedTo } from 'data/hooks/tasks/types'

import { Tooltip } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Icon } from 'ui/components/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'
import { DisplayOnContainerHover } from 'ui/styles/hoverUtils.css'

import { RelatedTasksPopover } from './RelatedTasksPopover'

type RecordTaskCountProps = StandardComponentProps & {
    record: RecordDto
    alwaysShow: boolean
    setIsActive: (isActive: boolean) => void
}
export function RecordTaskCount({
    record,
    alwaysShow,
    setIsActive,
    ...props
}: RecordTaskCountProps) {
    const { selectedStack } = useAppContext()
    const [isOpen, setIsOpen] = useState<boolean>()

    const onClose = () => {
        setIsOpen(false)
    }

    const targetRef = useRef<HTMLDivElement>(null)

    const handleClick = (e: React.MouseEvent): void => {
        setIsOpen(!isOpen)
        e.preventDefault()
        e.stopPropagation()
    }
    const count = record?._task_count ?? 0

    useEffect(() => {
        setIsActive(isOpen || count > 0)
    }, [isOpen, count, setIsActive])

    return (
        <>
            <TaskCountDisplay
                ml="xs"
                count={record?._task_count ?? 0}
                completed={record?._task_completed_count ?? 0}
                onClick={handleClick}
                ref={targetRef}
                alwaysShow={isOpen || alwaysShow}
                {...props}
            />
            <RelatedTasksPopover
                open={isOpen}
                targetElement={targetRef.current ?? undefined}
                onClose={onClose}
                placement="bottom-start"
                related_to={record?._sid}
                related_to_type={TaskRelatedTo.Record}
                related_to_stack={selectedStack?._sid}
                _object_id={record?._object_id}
                defaultAddNew={!record?._task_count}
            />
        </>
    )
}

export type TaskCountDisplayProps = BoxProps & {
    count: number
    completed: number
    alwaysShow?: boolean
}

export const TaskCountDisplay = React.forwardRef<HTMLDivElement, TaskCountDisplayProps>(
    function TaskCountDisplay(
        { count, completed, onClick, alwaysShow, ...props }: TaskCountDisplayProps,
        ref
    ) {
        const label =
            count > 0 ? `${completed ?? 0} of ${count ?? 0} tasks completed` : 'Add a task'
        return (
            <Tooltip label={label} placement="bottom">
                <Box
                    flex
                    center
                    position="relative"
                    className={
                        !count && !alwaysShow ? classNames(DisplayOnContainerHover) : undefined
                    }
                    aria-role={onClick ? 'button' : undefined}
                    onClick={onClick}
                    ref={ref}
                    {...props}
                >
                    {count ? (
                        <Box
                            fontSize="bodyXs"
                            color="textWeaker"
                            borderWidth="base"
                            borderStyle="base"
                            borderColor="border"
                            background={completed !== count ? undefined : 'surfaceStrongest'}
                            px={count > 0 ? 's' : undefined}
                            py={count > 0 ? '2xs' : undefined}
                            rounded="xs"
                        >{`${completed ?? 0}/${count ?? 0}`}</Box>
                    ) : null}
                    {count === 0 && (
                        <Icon
                            color="border"
                            name={count > 0 ? 'CheckCircle2' : 'CalendarCheck'}
                            opacity={count > 0 ? 1 : 0.8}
                            size="l"
                        />
                    )}
                </Box>
            </Tooltip>
        )
    }
)
