import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'

import { Box } from 'ui/components/Box'

type UseCardViewBaseAttributeStateOptions = {
    attribute: CardViewAttribute
}

export function useCardViewBaseAttributeState(options: UseCardViewBaseAttributeStateOptions) {
    const { attribute } = options

    const { labelStyle, titleSize } = useCardViewContext()

    const showLabel = labelStyle === 'text' && !!attribute.title && attribute.type === 'content'
    const showIcon = labelStyle === 'icon' && !!attribute.icon && attribute.type === 'content'

    let titleTextSize: React.ComponentPropsWithoutRef<typeof Box>['fontSize']
    switch (titleSize) {
        case 'small':
            titleTextSize = 'headline3Xs'
            break
        case 'medium':
            titleTextSize = 'headline2Xs'
            break
        case 'large':
            titleTextSize = 'headlineXs'
            break
    }

    return useMemo(
        () => ({ showLabel, titleTextSize, showIcon }),
        [showIcon, showLabel, titleTextSize]
    )
}
