import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import settings from 'app/settings'
import { getPortalDomain, getWorkspaceUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent } from 'ui/components/Dropdown'
import { Body } from 'ui/components/Text'

import { useCreateZoneModal } from './CreateZoneModal'
import { ZoneItem } from './ZoneItem'

export const ZoneDropdown = ({
    internalZone,
    portals,
    canCreateZones,
    canViewSettings,
}: {
    internalZone: AccountZone | undefined
    portals: AccountZone[]
    canCreateZones: boolean
    canViewSettings: boolean
}) => {
    const { workspaceZone: currentZone, isOnPortalDomain, workspaceAccount } = useWorkspaceContext()
    const [isPortalMenuOpen, setIsPortalMenuOpen] = useState(false)
    const history = useHistory()
    const { show: showCreateZoneModal } = useCreateZoneModal()

    const handleZoneSwitch = useCallback(
        (zone: AccountZone) => {
            if (isOnPortalDomain) {
                let url: string
                if (workspaceAccount) {
                    url = getPortalDomain(zone, workspaceAccount, Urls.Root)
                } else {
                    // if workspaceAccount is not available for some reason, just redirect to root domain
                    url = `https://${settings.STUDIO_DOMAIN}`
                }
                window.location.assign(url)
            } else {
                const url = getWorkspaceUrl(Urls.Root, undefined, zone)
                history.push(url)
            }
        },
        [isOnPortalDomain, workspaceAccount, history]
    )

    const getIsCurrentZone = useCallback(
        (zone: AccountZone): boolean => zone._sid === currentZone?._sid,
        [currentZone]
    )
    const onCreateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsPortalMenuOpen(false)
        showCreateZoneModal(e)
    }

    return (
        <Dropdown open={isPortalMenuOpen} onOpenChange={setIsPortalMenuOpen}>
            <DropdownButton
                size="xs"
                variant={isPortalMenuOpen ? 'activated' : 'ghost'}
                startIcon={{
                    name: isPortalMenuOpen ? 'ChevronUp' : 'ChevronDown',
                }}
                style={{ marginRight: '5px' }}
            />
            <DropdownContent align="end" style={{ width: '300px', marginLeft: '5px' }}>
                {internalZone && (
                    <ZoneItem
                        zone={internalZone}
                        isCurrent={getIsCurrentZone(internalZone)}
                        onClick={() => handleZoneSwitch(internalZone)}
                        canViewSettings={canViewSettings}
                        isInternal
                    />
                )}
                {portals.length > 0 && (
                    <>
                        <Body
                            size="xs"
                            weight="bold"
                            color="gray600"
                            style={{ paddingLeft: '1rem', textTransform: 'uppercase' }}
                        >
                            Portals
                        </Body>
                        {portals.map((portal, idx) => (
                            <ZoneItem
                                key={idx}
                                zone={portal}
                                isCurrent={getIsCurrentZone(portal)}
                                onClick={() => handleZoneSwitch(portal)}
                                canViewSettings={canViewSettings}
                            />
                        ))}
                    </>
                )}
                {canCreateZones && (
                    <Box
                        flex
                        flexDirection="column"
                        width="300px"
                        style={{
                            paddingTop: '6px',
                        }}
                    >
                        <Button
                            size="m"
                            startIcon={{
                                name: 'Plus',
                            }}
                            variant="secondary"
                            style={{ width: '90%', margin: 'auto' }}
                            onClick={onCreateClick}
                        >
                            Create New Portal
                        </Button>
                    </Box>
                )}
            </DropdownContent>
        </Dropdown>
    )
}
