import { useAppUserContext } from 'app/AppUserContext'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export function useShouldDisplayAddNewTableButton(): boolean {
    const isMobile = useIsMobile()
    const { isPreviewingAsUserOrRole, isAdmin } = useAppUserContext()

    return shouldDisplayAddButton({ isAdmin, isMobile, isPreviewingAsUserOrRole })
}

export type ShouldDisplayAddButtonParams = {
    isAdmin: boolean
    isMobile: boolean
    isPreviewingAsUserOrRole: boolean
}
export const shouldDisplayAddButton = ({
    isAdmin,
    isMobile,
    isPreviewingAsUserOrRole,
}: ShouldDisplayAddButtonParams) => {
    return isAdmin && !isMobile && !isPreviewingAsUserOrRole
}
