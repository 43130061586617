import { useAuthContext } from 'app/AuthContext/AuthContext'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { makeToast } from 'utils/toaster'

const regenerateIntegrationApiRoot = `change-integration-key/`

export function useRefreshUserIntegrationKey() {
    const { setUser } = useAuthContext()
    return async () => {
        try {
            const response = await fetchWithAuth(regenerateIntegrationApiRoot, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
            })

            const user = (await response.json()) as AuthedUserDto

            setUser(user)
        } catch (error) {
            makeToast(
                'could_not_regenerate_integration_key',
                'An error occurred trying to regenerate your integration key. If this problem continues, please contact support.',
                'error'
            )
        }
    }
}
