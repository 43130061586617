import React, { forwardRef } from 'react'

import { useBrowserInfo } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { EndIndicatorShortcutStyle } from 'ui/components/Menu/Menu.css'
import { Body } from 'ui/components/Text'

import { SlotContentProps } from './types'

type DropdownEndIndicatorShortcutRef = HTMLDivElement

type DropdownEndIndicatorShortcutProps = React.ComponentPropsWithoutRef<typeof Box> &
    SlotContentProps & {
        label?: string | { mac: string; default: string }
    }

export const DropdownEndIndicatorShortcut = forwardRef<
    DropdownEndIndicatorShortcutRef,
    DropdownEndIndicatorShortcutProps
>(({ label, ...props }, ref) => {
    const browser = useBrowserInfo()
    const isMac = browser.os.name === 'macOS'

    let labelValue: string
    if (typeof label === 'object') {
        labelValue = isMac ? label.mac : label.default
    } else {
        labelValue = label ?? ''
    }

    return (
        <Box {...props} ref={ref}>
            <Body size="m" weight="regular" className={EndIndicatorShortcutStyle}>
                {labelValue}
            </Body>
        </Box>
    )
})
