import React from 'react'

import DetailView from 'v2/views/Detail/DetailView'

import { SaveGuardContextProvider } from 'features/records/SaveGuardContext'

const _DetailViewWrapper = ({ ...props }) => {
    return (
        <SaveGuardContextProvider>
            <DetailView {...props} />
        </SaveGuardContextProvider>
    )
}

export const DetailViewWrapper = React.memo(_DetailViewWrapper)
