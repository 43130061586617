// @ts-strict-ignore
import React, { useState } from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'

import { Button, Flex, Input } from 'v2/ui'

import { ButtonProps } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import { getDeleteTableModalData } from './utils'

type DeleteNativeTableButtonProps = {
    object: ObjectDto
    onDeleteObject: (sid: string) => Promise<unknown>
    // if this table is a copy of a shared table, then deletion is allowed but the wording is different
    isSharedTableCopy: boolean
} & ButtonProps

export const DeleteNativeTableButton: React.FC<DeleteNativeTableButtonProps> = ({
    object,
    onDeleteObject,
    isSharedTableCopy,
    ...buttonProps
}) => {
    const deleteTableModalData = getDeleteTableModalData(object)
    const { show: showConfirm, setData } = useConfirmModal({
        ...deleteTableModalData,
        onConfirm: (modal) => {
            setData({ ...deleteTableModalData, isSaving: true })
            onDeleteObject(object?._sid)
                .then(() => {
                    refetchObjects()
                })
                .then(() => {
                    setData({ ...deleteTableModalData, isSaving: false })
                    modal.toggle()
                })
        },
    })

    if (!object?.connection_options?.stacker_native_object) {
        return null
    }

    const icon = isSharedTableCopy ? <Icon name="XCircle" size="m" mr="xs" /> : 'trash'

    return (
        <div>
            <Button
                icon={icon}
                onClick={showConfirm}
                variant="Primary"
                buttonSize="sm"
                {...buttonProps}
            >
                {isSharedTableCopy ? 'Remove table' : 'Delete this table'}
            </Button>
        </div>
    )
}

export const RenameNativeTableForm = ({ object, onChangeObject }) => {
    const [objectName, setObjectName] = useState(object?.name)

    return (
        <Flex>
            <Input w="310px" onChange={(e) => setObjectName(e.target.value)} value={objectName} />
            <Button
                variant="Primary"
                buttonSize="sm"
                style={{ marginLeft: '10px' }}
                isDisabled={!objectName || objectName === object?.name}
                onClick={() => {
                    onChangeObject({ name: objectName })
                }}
            >
                Save
            </Button>
        </Flex>
    )
}
