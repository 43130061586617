import React, { useCallback } from 'react'

import { Button, Flex, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

export const TrashButton = ({ trashMode, setTrashMode }) => {
    const buttonVariant = trashMode ? 'Primary' : 'Tertiary'
    const iconColor = trashMode ? '#ffffff' : 'userInterface.accent.1000'

    const toggleTrashMode = useCallback(() => {
        setTrashMode((prevMode: boolean) => !prevMode)
    }, [setTrashMode])

    return (
        <>
            {trashMode && (
                <Flex wrap="nowrap" px={3} py={1} mx={1} align="center" borderRadius="5px">
                    <Text color={colors.userInterface.neutral[800]} fontSize="14px">
                        Viewing deleted records from the last 7 days
                    </Text>
                </Flex>
            )}
            <Button
                icon={trashMode ? 'fa-rotate-left' : 'trash'}
                variant={buttonVariant}
                buttonSize="medium"
                iconColor={iconColor}
                color={colors.userInterface.neutral[800]}
                onClick={toggleTrashMode}
                data-testid="datagrid.trashMode.button"
            >
                {trashMode ? 'Exit Trash' : 'Trash'}
            </Button>
        </>
    )
}
