import settings from 'app/settings'
import { replaceCachedAccount } from 'data/hooks/accounts'

export const updateAccountUrl = (
    onUpdateAccount: any,
    account: Pick<Account, '_sid' | 'base_url'> | Account | null,
    values: {
        name: string
        urlSlug: string
        custom_base_url: string
    }
): Promise<Account> => {
    const nextBaseUrl = `${values.urlSlug}.${settings.WORKSPACE_DOMAIN}`

    return onUpdateAccount({
        id: account?._sid,
        patch: {
            name: values.name,
            base_url: nextBaseUrl,
            custom_base_url: values.custom_base_url === '' ? null : values.custom_base_url,
            slug: values.urlSlug,
        },
    }).then((resp: Account) => {
        replaceCachedAccount(resp)
        return resp
    })
}
