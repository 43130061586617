// @ts-strict-ignore
import React, { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { truncate } from 'lodash'

import { useObject, useObjects } from 'data/hooks/objects'
import FieldPicker from 'features/studio/ui/FieldPicker'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import { getIsSymmetricRelationshipField, getSymmetricUnderlyingLinkField } from 'utils/fieldUtils'

import { Dropdown } from 'v2/ui'

import { ConfigurationComponentProps, DATE_TYPES, NUMERIC_TYPES, STRING_TYPES } from './common'
import FiltersConfiguration from './FiltersConfiguration'
import { ConfigurationField } from './ui'

const OperatorOptions = [
    {
        label: 'Number of related records',
        value: 'count',
    },
    { label: 'Count values...', value: 'counta' },
    { label: 'Count unique values...', value: 'countunique' },
    { label: 'Sum...', value: 'sum' },
    { label: 'Average...', value: 'avg' },
    { label: 'Min...', value: 'min' },
    { label: 'Max...', value: 'max' },
]
const OperatorSupportedTypes = {
    sum: NUMERIC_TYPES,
    avg: NUMERIC_TYPES,
    min: NUMERIC_TYPES.concat(DATE_TYPES),
    max: NUMERIC_TYPES.concat(DATE_TYPES),
    countunique: NUMERIC_TYPES.concat(DATE_TYPES).concat(STRING_TYPES).concat(['lookup']),
}

export const RollupFieldConfiguration: React.VFC<ConfigurationComponentProps> = ({ object }) => {
    const { setValue } = useFormContext()
    const { data: objects } = useObjects()

    function filterLinks(field: FieldDto) {
        return (
            // links pointing to our main object
            field?.link_target_object_id === object?._sid &&
            // and links which are not symmetric (and thus will have a local multi symmetric),
            // or they are symmetric of a local multi link
            (!getIsSymmetricRelationshipField(field) ||
                getSymmetricUnderlyingLinkField(field, objects)?.type == 'multi_lookup') &&
            // and they aren't lookups
            field.synthetic_field_type !== 'lookup' &&
            !field.is_foreign
        )
    }
    const filterObjects = (obj) => obj.fields.find(filterLinks)

    const connection_options: FieldConnectionOptions =
        useWatch({ name: 'connection_options' }) ?? {}

    const rollupTargetObjectId = useWatch({ name: 'rollup_target_object_id' })

    const { object: targetObject } = useObject(rollupTargetObjectId)

    const showSourceFields = connection_options.rollup_operator !== 'count'
    const targetFields = targetObject?.fields?.filter(filterLinks) || []
    const operator = connection_options.rollup_operator
    const filterSourceField = (field) =>
        operator &&
        (!OperatorSupportedTypes[operator] || OperatorSupportedTypes[operator].includes(field.type))
    useEffect(() => {
        if (targetFields?.length === 1) {
            setValue('connection_options.rollup_relationship_field', targetFields[0]._sid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetObject])

    return (
        <>
            <ConfigurationField
                label="Summarize related records from"
                as={ObjectPicker}
                objectId={object?._sid}
                name="rollup_target_object_id"
                placeholder="Select a table"
                filter={filterObjects}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                show={targetFields.length > 1}
                label={
                    <>
                        where the <strong>{object?.name}</strong> record is
                    </>
                }
                as={FieldPicker}
                objectId={targetObject?._sid}
                name="connection_options.rollup_relationship_field"
                placeholder="Select a field"
                provideOptionLabel={(field) =>
                    `${truncate(targetObject?.name, { length: 12 })} → ${field.label}`
                }
                filter={filterLinks}
                controlled
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />

            <ConfigurationField
                show={!!connection_options.rollup_relationship_field}
                label="Display the"
                as={Dropdown}
                name="connection_options.rollup_operator"
                placeholder="Select"
                options={OperatorOptions}
                controlled
                errorMessages={{ required: 'Select a roll-up operator' }}
                usePortal={false}
            />
            <ConfigurationField
                show={!!connection_options?.rollup_operator && showSourceFields}
                label={
                    <>
                        of the <strong>{targetObject?.name}</strong>
                    </>
                }
                as={FieldPicker}
                objectId={targetObject?._sid}
                name="connection_options.rollup_field"
                placeholder="Select a field"
                filter={filterSourceField}
                controlled
                required={!!connection_options?.rollup_operator && showSourceFields}
                errorMessages={{ required: 'Select a field' }}
                usePortal={false}
            />
            <ConfigurationField
                show={!!targetFields.length}
                as={FiltersConfiguration}
                name="connection_options.filters"
                checkboxLabel="Only include records that meet certain conditions"
                object={targetObject}
                controlled
                required={false}
            />
        </>
    )
}
