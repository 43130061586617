import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import type { DropdownMenuCheckboxItemProps } from '@radix-ui/react-dropdown-menu'

import { ItemVariants } from 'ui/components/Menu/Menu.css'
import { StandardComponentProps } from 'ui/helpers/styles'

import {
    CheckboxItem,
    ItemIndicator,
    ItemIndicatorIcon,
    ItemIndicatorPlaceholder,
    LeftSlot,
    RightSlot,
} from './Dropdown.parts'

export type SlotContentProps = {
    disabled?: boolean
    isChecked?: boolean | 'indeterminate'
    onCheckedChange?: (checked: boolean | 'indeterminate') => void
}

type DropdownCheckboxBaseRef = HTMLDivElement

type DropdownCheckboxBaseProps = Omit<DropdownMenuCheckboxItemProps, 'asChild'> &
    StandardComponentProps &
    ItemVariants & {
        leftSlotContent?: React.ComponentType<SlotContentProps>
        rightSlotContent?: React.ComponentType<SlotContentProps>
        hideCheckbox?: boolean
        closeOnSelect?: boolean
    }

export const DropdownCheckboxBase = forwardRef<DropdownCheckboxBaseRef, DropdownCheckboxBaseProps>(
    (
        {
            leftSlotContent: LeftSlotContent,
            rightSlotContent: RightSlotContent,
            onSelect,
            children,
            defaultChecked,
            checked,
            onCheckedChange,
            hideCheckbox,
            closeOnSelect = false,
            variant,
            ...props
        },
        ref
    ) => {
        const handleSelect = (event: Event) => {
            if (!closeOnSelect) {
                // Prevent closing the dropdown when clicking the checkbox.
                event.preventDefault()
            }

            onSelect?.(event)
        }

        const isControlled = typeof checked !== 'undefined'
        const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false)
        useEffect(() => {
            setIsChecked(checked ?? defaultChecked ?? false)
        }, [checked, defaultChecked])

        const handleCheckedChange = (checked: boolean) => {
            if (!isControlled) {
                setIsChecked(checked)
            }
            onCheckedChange?.(checked)
        }

        const slotProps = {
            disabled: props.disabled,
            checked: isChecked,
            onCheckedChange: handleCheckedChange,
        }

        const iconColor = useMemo(() => {
            if (props.disabled) return undefined

            switch (variant) {
                default:
                    return 'iconTheme'
            }
        }, [props.disabled, variant])

        return (
            <CheckboxItem
                onSelect={handleSelect}
                onCheckedChange={handleCheckedChange}
                checked={isChecked}
                variant={variant}
                {...props}
                ref={ref}
            >
                {LeftSlotContent && (
                    <LeftSlot>
                        <LeftSlotContent {...slotProps} />
                    </LeftSlot>
                )}
                {children}
                <RightSlot>
                    {RightSlotContent && <RightSlotContent {...slotProps} />}
                    {!hideCheckbox && (
                        <ItemIndicator forceMount={true}>
                            {isChecked ? (
                                <ItemIndicatorIcon name="Check" size="m" color={iconColor} />
                            ) : (
                                <ItemIndicatorPlaceholder />
                            )}
                        </ItemIndicator>
                    )}
                </RightSlot>
            </CheckboxItem>
        )
    }
)
