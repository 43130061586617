import React, { useCallback } from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { useDeleteWorkflow } from 'data/hooks/workflows/workflows'

import { Icon, Text } from 'v2/ui'
import { useToast } from 'v2/ui/components/Toast'
import { DeleteData } from 'v2/ui/svgs'
import { Modal } from 'v2/ui/utils/useModalToggle'

export const useConfirmDeleteWorkflowModal = ({ onDelete }: { onDelete: () => void }) => {
    const { show } = useConfirmModal()
    const { mutate: deleteWorkflow } = useDeleteWorkflow({
        onSuccess: () => {
            toast({ title: 'Workflow deleted successfully.', status: 'success' })
        },
        onError: () => {
            toast({
                title: 'There was a problem deleting the workflow. Please try again later.',
                status: 'error',
            })
        },
    })

    const toast = useToast()

    return useCallback(
        (sid: string) => {
            show({
                title: 'Confirm Delete',
                confirmVariant: 'PrimaryError',
                icon: <Icon svg={<DeleteData />} size="56px" ml="15px" mb={4} />,
                message: (
                    <Text>
                        Are you sure you want to delete this workflow? This action cannot be undone.
                    </Text>
                ),
                onConfirm: async (modal: Modal<unknown>) => {
                    onDelete()
                    deleteWorkflow(sid)
                    modal.setIsOpen(false)
                },
                onCancel: (modal: Modal<unknown>) => {
                    modal.setIsOpen(false)
                },
            })
        },
        [show, onDelete, deleteWorkflow]
    )
}
