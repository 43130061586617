import { useCallback, useMemo } from 'react'

import { useInlineCreateViewModal } from 'v2/views/Create/useInlineCreateViewModal'
import { useCreateQueryParams } from 'v2/views/utils/useCreateQueryParams'

import { useListViewContext } from 'features/views/ListView/ListViewContext'

export function useNoRecordsEmptyStateState() {
    const { object, view } = useListViewContext()

    const redirectToList = view.options?.redirectToList

    const queryParams = useCreateQueryParams(object, {
        redirectToList,
    })
    const { toggle: toggleModal } = useInlineCreateViewModal({
        objectId: object._sid,
        queryParams,
    })

    const handleClickAddNew = useCallback(() => {
        toggleModal()
    }, [toggleModal])

    return useMemo(
        () => ({
            onClickAddNew: handleClickAddNew,
        }),
        [handleClickAddNew]
    )
}
