import React, { useEffect, useState } from 'react'

import { updateField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { deleteObject, updateObject } from 'data/hooks/objects/objectOperations'
import useLDFlags from 'data/hooks/useLDFlags'
import {
    DeleteNativeTableButton,
    RenameNativeTableForm,
} from 'features/admin/settings/object/NativeTableSettingsControls'
import { Divider, ExpandSection, Section } from 'legacy/v1/ui'
import { canBeExternalIdField, canBePrimaryField } from 'utils/fieldUtils'

import { Banner, Button, Dropdown } from 'v2/ui'
import { useToast } from 'v2/ui/components/Toast'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Body } from 'ui/components/Text'

import { DisplaySettings } from './DisplaySettings'
import { ShareTablesSettings } from './ShareTablesSettings'
import { UnShareTablesSettings } from './UnShareTablesSettings'

export const AppSettingsModalDataSettings = ({
    noPadding,
    objectId,
}: {
    noPadding: boolean
    objectId: string
}): React.ReactElement | null => {
    const { flags } = useLDFlags()
    const { object } = useObject(objectId)
    const [externalIdButtonDisabled, setExternalIdButtonDisabled] = useState(true)
    const [externalIdFieldApiName, setExternalIdFieldApiName] = useState<string | null>(null)
    const [primaryFieldApiName, setPrimaryFieldApiNameApiName] = useState<string | null>(null)
    const toast = useToast()

    useEffect(() => {
        if (object && object.external_id_field_id) {
            const field = object.fields.find((f) => f._sid === object.external_id_field_id)
            if (field) {
                setExternalIdFieldApiName(field.api_name)
            }
        }
    }, [object])

    function getPrimaryFieldApi(object: ObjectDto) {
        if (primaryFieldApiName) {
            return primaryFieldApiName
        }

        if (object && object.fields) {
            const field = object.fields.find((f) => f.is_primary)
            if (field) return field.api_name
        }
    }

    function openErrorToast(operation: 'update' | 'delete' | 'unshare' = 'update') {
        toast({
            status: 'error',
            title: `Failed to ${operation} this object. Please try again or contact Support.`,
        })
    }

    const isSharedTable =
        object?.connection_options?.stacker_native_object &&
        object?.connection_options?.shared_object_id

    const isSharedTableCopy = !!(
        object?.connection_options?.shared_object_id && !object.is_shared_original
    )

    if (!object) {
        return null
    }

    return (
        <Section
            style={{
                width: '100%',
                marginBottom: 0,
                padding: noPadding ? 0 : undefined,
            }}
        >
            <ExpandSection
                heading="Display Name"
                text="Configure the primary field for this table."
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DISPLAY_NAME,
                }}
            >
                <Section noPadding noMargin>
                    <Dropdown
                        value={getPrimaryFieldApi(object)}
                        options={object.fields.filter(canBePrimaryField).map((field: FieldDto) => {
                            return {
                                label: field.label,
                                value: field.api_name,
                            }
                        })}
                        onChange={(fieldApiName: string) => {
                            setPrimaryFieldApiNameApiName(fieldApiName)
                        }}
                        isClearable={false}
                    />

                    <Button
                        variant="Primary"
                        buttonSize="sm"
                        color={primaryFieldApiName ? 'success' : ''}
                        disabled={!primaryFieldApiName}
                        width="70px"
                        mt={1}
                        onClick={async () => {
                            // Set the new primary field
                            // We don't need to manually set the old primary field to False
                            const primaryField = object.fields.find(
                                (f: FieldDto) => f.api_name === primaryFieldApiName && !f.is_primary
                            )
                            if (!primaryField) {
                                return
                            }

                            try {
                                await updateField(primaryField._sid, {
                                    is_primary: true,
                                })
                                setPrimaryFieldApiNameApiName(null)
                            } catch (e) {
                                openErrorToast()
                            }
                        }}
                    >
                        Save
                    </Button>
                </Section>
            </ExpandSection>

            {/* Show the delete button unless this is the original copy of a shared table*/}
            {!object?.is_shared_original && !!!object?.system_object_type && (
                <>
                    <Divider />
                    <ExpandSection
                        heading={isSharedTableCopy ? 'Remove shared table' : 'Delete table'}
                        text={
                            isSharedTableCopy
                                ? 'Remove shared table from this app.'
                                : 'Remove this table and all associated data.'
                        }
                        helpUrl=""
                        helpText=""
                        classes={{
                            heading:
                                ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM
                                    .DELETE_OBJECT,
                        }}
                    >
                        <Section noPadding noMargin>
                            {object?.connection_options?.is_protected_by_grant ? (
                                <Body size="s" color="textWeaker">
                                    This table is required in user table group. Please first remove
                                    associated table groups if you wish to delete.
                                </Body>
                            ) : (
                                <DeleteNativeTableButton
                                    object={object}
                                    onDeleteObject={async (sid: string) => {
                                        try {
                                            await deleteObject(sid)
                                        } catch (e) {
                                            openErrorToast('delete')
                                        }
                                    }}
                                    isSharedTableCopy={isSharedTableCopy}
                                />
                            )}
                        </Section>
                    </ExpandSection>
                </>
            )}
            {/* If this is a shared table and the original copy, show unshare button */}
            {object?.is_shared_original && (
                <UnShareTablesSettings object={object} openErrorToast={openErrorToast} />
            )}

            {flags.externalIdField && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="External ID field"
                        text="Set the field to use as the External ID for this table (Short Text fields only)"
                    >
                        <Section noPadding noMargin>
                            <Dropdown
                                value={externalIdFieldApiName}
                                options={object.fields.filter(canBeExternalIdField).map((field) => {
                                    return {
                                        label: field.label,
                                        value: field.api_name,
                                    }
                                })}
                                onChange={(fieldApiName: string | null) => {
                                    setExternalIdFieldApiName(fieldApiName)
                                    setExternalIdButtonDisabled(false)
                                }}
                                isClearable={true}
                            />
                            <div>
                                <Button
                                    variant="Primary"
                                    buttonSize="sm"
                                    disabled={externalIdButtonDisabled}
                                    width="70px"
                                    mt={1}
                                    onClick={async () => {
                                        const externalIdField = object.fields.find(
                                            (f) => f.api_name === externalIdFieldApiName
                                        )

                                        try {
                                            await updateObject(object._sid, {
                                                external_id_field_id:
                                                    externalIdField && externalIdField._sid
                                                        ? externalIdField._sid
                                                        : null,
                                            })
                                            setExternalIdButtonDisabled(true)
                                        } catch (e) {
                                            openErrorToast()
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </Section>
                    </ExpandSection>
                </>
            )}

            {!isSharedTable && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="Rename table"
                        text="Rename the table within App Settings. To rename the table in the menus, use the Navigation Settings."
                        helpUrl=""
                        helpText=""
                        classes={{
                            heading:
                                ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM
                                    .RENAME_OBJECT,
                        }}
                    >
                        <RenameNativeTableForm
                            object={object}
                            onChangeObject={async (data: Partial<ObjectDto>) => {
                                try {
                                    await updateObject(object._sid, data)
                                } catch (e) {
                                    openErrorToast()
                                }
                            }}
                        />
                    </ExpandSection>

                    {flags.sharedTables && <ShareTablesSettings object={object} />}
                </>
            )}
            {isSharedTable && (
                <Banner icon="info" variant="Information" title="Shared table">
                    {object.is_shared_original
                        ? 'This table is shared and available to be added to other apps.'
                        : 'This is a shared table that is owned by another app.'}
                </Banner>
            )}
            {!noPadding && <Divider />}

            {localStorage.getItem('support_login') && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="Display settings"
                        text="Change how this table is displayed in the Manage Data pane"
                        helpUrl=""
                        helpText=""
                    >
                        <DisplaySettings object={object} />
                    </ExpandSection>
                </>
            )}
        </Section>
    )
}
