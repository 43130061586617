import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'

export type SearchContextValue = {
    query: string
    setQuery: (query: string) => void
    allowSearch: boolean
}

export const SearchContext = React.createContext<SearchContextValue | null>(null)

export function useSearchContext(): SearchContextValue {
    const context = useContext(SearchContext)

    if (!context) {
        throw new Error('useSearchContext must be used within a SearchContextProvider')
    }

    return context
}

export type SearchContextProviderProps = {}

export const SearchContextProvider: React.FC<SearchContextProviderProps> = ({ children }) => {
    const [query, setQuery] = useState('')

    const handleSetQuery = useCallback((query: string) => {
        const newValue = query.trim().toLowerCase()

        setQuery(newValue)
    }, [])

    const { allowSearch } = useListViewContext()

    const value = useMemo(
        () => ({
            query,
            setQuery: handleSetQuery,
            allowSearch,
        }),
        [query, handleSetQuery, allowSearch]
    )

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}
