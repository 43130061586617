import { BoardViewColumn } from 'features/views/ListView/BoardView/types'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { convertV2FieldColorToTheme } from 'utils/fieldUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useBoardColumns(statusField?: FieldDto): BoardViewColumn[] {
    const { view } = useListViewContext()

    const columns = makeColumns(statusField, view)

    return useDeepEqualsMemoValue(columns)
}

function makeColumns(statusField?: FieldDto, view?: ViewDto): BoardViewColumn[] {
    if (!statusField || !view || !['dropdown', 'multi_select'].includes(statusField.type)) return []

    const statusColumns = view.options.statusColumns?.slice()
    if (!statusColumns?.length) return []
    const statusColumnsSet = new Set(statusColumns)

    const options = statusField.options?.options ?? []
    const allowsColors = statusField.options?.allow_dropdown_colors ?? false

    const columns = options.reduce((acc, option) => {
        if (!option.value) return acc

        // If the user has selected this status to use as a column, add it to the list.
        const statusColumn = statusColumnsSet.has(option.value)
        if (statusColumn) {
            acc.push({
                id: option.value,
                title: option.label ?? option.value,
                value: option.value,
                colorScheme: allowsColors ? convertV2FieldColorToTheme(option.color!) : undefined,
            })
        }

        return acc
    }, [] as BoardViewColumn[])

    // Add a "No category" column if the user has selected it.
    if (statusColumnsSet.has(null)) {
        columns.unshift({
            id: 'none',
            title: 'No category',
            value: null,
        })
    }

    return columns
}
