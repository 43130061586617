import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'

export function useRecordLinksCellState() {
    const { dereferencedRecords } = useTableViewContext()

    return useMemo(
        () => ({
            dereferencedRecords,
        }),
        [dereferencedRecords]
    )
}
