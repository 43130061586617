import React from 'react'
import { useHistory } from 'react-router-dom'

import { getWorkspaceUrl, Urls } from 'app/UrlService'
import { invalidateFields } from 'data/hooks/fields'
import useLDFlags from 'data/hooks/useLDFlags'
import { useUpdateZone } from 'data/hooks/zones'
import { validateSlug } from 'data/utils/validateZoneSlug'
import { ConfirmationModal } from 'features/workspace/modals/ConfirmationModal'
import { FormFrame } from 'features/workspace/WorkspaceSettingsModalUi'

import useRequestConfirmModal from 'v2/ui/utils/useRequestConfirmModal'

import { Checkbox } from 'ui/components/Checkbox'
import { Divider } from 'ui/components/Divider'
import { Body } from 'ui/components/Text'
import Form from 'ui/deprecated/forms/Form'
import { FormField } from 'ui/deprecated/forms/FormField'

import { DeletePortal } from './DeletePortal'
import { SettingsInput } from './SettingsInput'
import { updateZoneUrl } from './updateZoneUrl'

type PortalGeneralSettingsProps = {
    formRef: any
    portal: AccountZone & { type: 'Portal' }
    children?: React.ReactNode
    readOnly?: boolean
}

type FormData = Pick<
    AccountZone,
    'name' | 'slug' | 'custom_domain' | 'enable_permanent_login_links'
>

const CUSTOM_DOMAIN_CHARACTER_LIMIT = 60

export const PortalGeneralSettings = ({
    formRef,
    portal,
    children,
    readOnly,
}: PortalGeneralSettingsProps) => {
    const { flags } = useLDFlags()
    const { mutateAsync: updateZone } = useUpdateZone()
    const {
        onShowConfirmation: onRequestUrlChange,
        showModal: showUrlChangeRequest,
        onAllow: onAllowUrlChange,
        onDeny: onDenyUrlChange,
    } = useRequestConfirmModal()

    const defaultValues = {
        name: portal.name,
        slug: portal.slug,
        custom_domain: portal.custom_domain,
        enable_permanent_login_links: portal.enable_permanent_login_links,
    }

    const history = useHistory()
    const onSubmit = async (values: FormData) => {
        const afterChanged = (resp: AccountZone) => {
            if (resp.enable_permanent_login_links !== portal.enable_permanent_login_links) {
                invalidateFields({ ignoreStack: true })
            }
            if (resp.slug !== portal.slug) {
                history.push(getWorkspaceUrl(Urls.Root, undefined, resp))
            }
        }
        // require explicit user confirmation if slug or custom domain is changing
        if (
            (values.slug && values.slug !== portal.slug) ||
            (values.custom_domain && values.custom_domain !== portal.custom_domain)
        ) {
            return onRequestUrlChange().then(() =>
                updateZoneUrl(updateZone, portal, values).then(afterChanged)
            )
        } else {
            return updateZoneUrl(updateZone, portal, values).then(afterChanged)
        }
    }

    const validateSlugValue = async (newSlug: string) => {
        if (newSlug && newSlug !== portal.slug) {
            const result = await validateSlug(newSlug)
            return result.isValid || result.error
        } else {
            return !!newSlug || 'Slug is required'
        }
    }

    return (
        <Form onSubmit={onSubmit} options={{ defaultValues }} style={{ flex: 1 }} resetOnSuccess>
            <FormFrame title="General" formRef={formRef}>
                <Divider variant="weak" mb="l" />
                <SettingsInput
                    formRef={formRef}
                    name="name"
                    label="Name"
                    readOnly={readOnly}
                    required
                />
                <div style={{ height: '15px' }} />
                <SettingsInput
                    formRef={formRef}
                    name="slug"
                    label="Customize URL"
                    required
                    readOnly={readOnly}
                    startAction={() => <Body color="gray500">https://</Body>}
                    endAction={() => <Body color="gray500">.stackerportal.com</Body>}
                    registerOptions={{
                        pattern: {
                            value: /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$/,
                            message: 'The url slug you entered does not match the required format.',
                        },
                        validate: validateSlugValue,
                    }}
                />
                {portal.custom_domain && (
                    <SettingsInput
                        formRef={formRef}
                        name="custom_domain"
                        label="Custom Domain"
                        readOnly={readOnly}
                        registerOptions={{
                            maxLength: {
                                value: CUSTOM_DOMAIN_CHARACTER_LIMIT,
                                message: `Exceeded maximum character limit of ${CUSTOM_DOMAIN_CHARACTER_LIMIT}.`,
                            },
                        }}
                        startAction={() => <Body color="gray500">https://</Body>}
                    />
                )}
                <PermanentLoginLinksSetting />
                <DeletePortal portal={portal} />
            </FormFrame>
            <ConfirmationModal
                isOpen={showUrlChangeRequest}
                title="Confirm URL change"
                subtitle={
                    <>
                        Are you sure that you wish to update the portal URL? Any links to your
                        previous url will no longer work.
                        {flags?.permanentLoginLinks && (
                            <>
                                <br />
                                <br />
                                Existing permanent login links will become invalid and new ones will
                                be generated.
                            </>
                        )}
                    </>
                }
                onConfirm={onAllowUrlChange}
                onCancel={onDenyUrlChange}
            />
            {children}
        </Form>
    )
}

function PermanentLoginLinksSetting() {
    const { flags } = useLDFlags()
    if (!flags?.permanentLoginLinks) return null

    return (
        <>
            <FormField
                as={Checkbox}
                name="enable_permanent_login_links"
                controlled
                controlledDefaultValue={false}
                controlledRender={({ field: { onChange, name, value } }) => (
                    <Checkbox onCheckedChange={onChange} name={name} checked={value} mt="xl">
                        Generate permanent login Links
                    </Checkbox>
                )}
            />
        </>
    )
}
