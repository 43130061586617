import React, { useCallback } from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItemLink } from 'ui/components/Dropdown'
import { LinkButton } from 'ui/components/LinkButton'
import { Skeleton } from 'ui/components/Skeleton'
import { makeLineTruncationStyle, stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useRecordLinksAttributeDisplayState } from './hooks/useRecordLinksAttributeDisplayState'
import { AttributeDisplayComponent } from './types'
import { Users } from './Users'

const sizeMapping = {
    button: {
        s: '2xs',
        m: 'xs',
    },
} as const

type RecordLinksAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    maxLinks?: number
    maxLines?: number
    maxItemLength?: number
    dereferencedRecords?: RecordDto[]
    size?: ResponsiveValue<'s' | 'm'>
    isLoading?: boolean
    showSingleUserName?: boolean
}

export const RecordLinksAttributeDisplay: AttributeDisplayComponent<
    string | string[],
    RecordLinksAttributeDisplayProps
> = React.memo(function RecordLinksAttributeDisplay({
    value,
    maxLinks,
    dereferencedRecords,
    style,
    maxLines,
    maxItemLength,
    size = 'm',
    isLoading,
    field,
    showSingleUserName = true,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const buttonSize = sizeMapping.button[effectiveSize]

    const {
        records,
        overflowingRecordsCountLabel,
        overflowingRecords,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
        isUsersObject,
        users,
    } = useRecordLinksAttributeDisplayState({
        field,
        value,
        maxLinks,
        dereferencedRecords,
        maxItemLength,
        isLoading,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    if (records.length === 0) {
        return null
    }

    if (isUsersObject) {
        return (
            <Users
                value={users}
                isLoading={isLoading}
                size={effectiveSize}
                style={style}
                maxLines={maxLines}
                maxLength={maxItemLength}
                onClick={handleClick}
                showSingleUserName={showSingleUserName}
                {...props}
            />
        )
    }

    return (
        <Box
            flex
            center
            gap="xs"
            style={{ ...makeLineTruncationStyle(maxLines), ...style }}
            {...props}
        >
            {records.map((record) => (
                <Skeleton isLoading={isLoading} key={record.url}>
                    <LinkButton
                        variant="secondary"
                        size={buttonSize}
                        to={record.url}
                        onClick={handleClick}
                        noShrink
                        mb={maxLines !== 1 ? 'xs' : undefined}
                        mr={maxLines !== 1 ? 'xs' : undefined}
                    >
                        {record.name}
                    </LinkButton>
                </Skeleton>
            ))}
            {overflowingRecordsCountLabel && (
                <Skeleton isLoading={isLoading}>
                    <Dropdown
                        open={isOverflowPopupOpen}
                        onOpenChange={onOverflowPopupOpenChange}
                        modal={false}
                    >
                        <DropdownButton
                            variant="secondary"
                            size={buttonSize}
                            noShrink
                            onClick={stopPropagation}
                            onMouseEnter={onOverflowLabelMouseEnter}
                            onMouseLeave={onOverflowLabelMouseLeave}
                        >
                            {overflowingRecordsCountLabel}
                        </DropdownButton>
                        <DropdownContent
                            sideOffset={0}
                            side="bottom"
                            align="end"
                            onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                            onMouseEnter={onOverflowLabelMouseEnter}
                            onMouseLeave={onOverflowLabelMouseLeave}
                        >
                            {overflowingRecords.map((record) => (
                                <DropdownItemLink
                                    key={record.url}
                                    to={record.url}
                                    label={record.name}
                                    onClick={handleClick}
                                />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </Skeleton>
            )}
        </Box>
    )
},
isEqual)

RecordLinksAttributeDisplay.displayName = 'RecordLinksAttributeDisplay'
