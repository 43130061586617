import { useCallback, useMemo } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'

export function useDetailViewControlsState() {
    const { view, commands } = useLayoutEditorContext()

    const title = view ? `${view.name} detail` : 'Edit layout'

    const viewName = view?.name ?? ''

    const onChangeViewName = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            commands.updateViewName(e.target.value)
        },
        [commands]
    )

    const onClickWidget = useCallback(
        (widgetType: string) => {
            commands.insertWidgetAtPath(widgetType, ['content'])
        },
        [commands]
    )

    return useMemo(
        () => ({
            title,
            viewName,
            onChangeViewName,
            onClickWidget,
        }),
        [title, viewName, onChangeViewName, onClickWidget]
    )
}
