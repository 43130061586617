import React from 'react'

import classNames from 'classnames'

import { useTheme } from 'ui/styling/themes/ThemeProvider'

import * as Parts from './Popover.parts'

type PopoverContentRef = HTMLDivElement

type PopoverContentProps = React.ComponentPropsWithoutRef<typeof Parts.Content> & {}

export const PopoverContent = React.forwardRef<PopoverContentRef, PopoverContentProps>(
    ({ className, ...props }, ref) => {
        const { themeClassName } = useTheme()

        return (
            <Parts.Portal>
                <Parts.Content
                    ref={ref}
                    className={classNames(themeClassName, className)}
                    {...props}
                />
            </Parts.Portal>
        )
    }
)
