import { useCallback, useState } from 'react'

import { useRecordEditManagerContext } from 'features/records/RecordEditManagerContext'

type UseDeleteRecordConfirmModalStateOptions = {
    recordSid: string
    onOpenChange?: (open: boolean) => void
}

export function useDeleteRecordConfirmModalState(options: UseDeleteRecordConfirmModalStateOptions) {
    const { recordSid, onOpenChange } = options

    const [isError, setIsError] = useState(false)

    const handleOpenChange = useCallback(
        (isOpen: boolean) => {
            setIsError(false)
            onOpenChange?.(isOpen)
        },
        [onOpenChange]
    )

    const { deleteRecords } = useRecordEditManagerContext()
    const onDelete = useCallback(async () => {
        try {
            deleteRecords([recordSid])

            handleOpenChange(false)
        } catch {
            setIsError(true)
        }
    }, [deleteRecords, handleOpenChange, recordSid])

    return {
        onDelete,
        isError,
        handleOpenChange,
    }
}
