import React, { useMemo } from 'react'

import { assertIsDefined } from 'data/utils/ts_utils'
import { ConditionGroup } from 'features/conditions/types'
import { ContextSchema } from 'features/expressions/types'
import { ConnectionDropdown } from 'features/workflows/Integrations/ConnectionDropdown'
import { WorkflowNodeEditorComponent } from 'features/workflows/types'
import { WorkflowConditionInput } from 'features/workflows/WorkflowConditionInput'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'

import { ActionButtonTriggerConfig } from './ActionButtonTrigger'
import { RecordChangeTriggerConfig } from './RecordChangeTrigger'
import { RecordUpdatedTriggerConditions } from './RecordUpdatedConditions'
import { SlackOnReactionAddedTriggerConfig } from './SlackOnReactionAddedTrigger'
import { SlackTriggerConfig } from './SlackTrigger'
import { TriggerTypeDropdown } from './TriggerTypeDropdown'
import { WorkflowTriggerEditorComponent } from './types'
import { usePreparedTriggerSchema } from './usePreparedTriggerSchema'
import { triggerTypeSupportsConditions } from './utils'
import { WebhookTriggerConfig } from './WebhookTrigger'

type TriggerEditorProps = {
    workflow: WorkflowDto
    trigger: WorkflowTriggerConfig
    onChange: (trigger: Omit<Partial<WorkflowTriggerConfig>, 'id'>) => void
}
export const TriggerEditor: WorkflowNodeEditorComponent<TriggerEditorProps> = ({
    workflow,
    trigger,
    onChange,
}) => {
    const triggerType = usePreparedTriggerSchema(trigger)

    const ConfiguratorComponent = TriggerConfigurators[trigger.trigger_type]
    if (Boolean(ConfiguratorComponent)) assertIsDefined(triggerType)
    const ConditionConfiguratorComponent = TriggerConditionConfigurators[trigger.trigger_type]

    const handleChangeType = (triggerType: WorkflowSchemaTriggerType) => {
        onChange({ name: triggerType.name, trigger_type: triggerType.id, settings: {} })
    }

    const contextSchema: ContextSchema = useMemo(() => {
        if (!triggerType) return []

        return [{ id: 'trigger', name: 'Trigger', type: 'group', items: triggerType.outputs }]
    }, [triggerType])

    const handleChangeCondition = (condition: ConditionGroup) => {
        onChange({ condition })
    }

    const supportsConditions = triggerType && triggerTypeSupportsConditions(triggerType)

    return (
        <>
            <Box flex column stretch p="xl">
                <ConfigurationLabel mt="xs">Trigger</ConfigurationLabel>
                <TriggerTypeDropdown type={trigger.trigger_type} onChange={handleChangeType} />
                {ConfiguratorComponent && (
                    <ConfiguratorComponent
                        trigger={trigger}
                        triggerType={triggerType!}
                        onChange={onChange}
                        workflow={workflow}
                    />
                )}
                {triggerType && (
                    <>
                        <ConfigurationLabel>Trigger label</ConfigurationLabel>
                        <Input
                            value={trigger.name}
                            onChange={(e) => onChange({ name: e.target.value })}
                        />
                    </>
                )}
                {triggerType?.auth && (
                    <>
                        <ConfigurationLabel>Connection</ConfigurationLabel>
                        <ConnectionDropdown
                            value={trigger.settings?.connection_id as string | undefined}
                            onChange={(connection) =>
                                onChange({
                                    settings: {
                                        ...trigger.settings,
                                        connection_id: connection.id,
                                    },
                                })
                            }
                            providerId={triggerType.auth.provider_id}
                        />
                    </>
                )}
            </Box>
            {supportsConditions && (
                <>
                    <Divider my="m" />

                    <Box flex column stretch px="xl" pb="2xl">
                        <ConfigurationLabel>Conditions</ConfigurationLabel>
                        <WorkflowConditionInput
                            value={trigger.condition}
                            contextSchema={contextSchema}
                            onChange={handleChangeCondition}
                            disabled={!triggerType}
                        />
                        {ConditionConfiguratorComponent && (
                            <ConditionConfiguratorComponent
                                workflow={workflow}
                                trigger={trigger}
                                triggerType={triggerType!}
                                onChange={onChange}
                            />
                        )}
                    </Box>
                </>
            )}
        </>
    )
}

const TriggerConfigurators: Record<string, WorkflowTriggerEditorComponent> = {
    record_created: RecordChangeTriggerConfig,
    record_updated: RecordChangeTriggerConfig,
    record_created_or_updated: RecordChangeTriggerConfig,
    webhook: WebhookTriggerConfig,
    action_button_clicked: ActionButtonTriggerConfig,
    slack_on_reaction_added: SlackOnReactionAddedTriggerConfig,
    slack_on_message: SlackTriggerConfig,
}

const TriggerConditionConfigurators: Record<string, WorkflowTriggerEditorComponent> = {
    record_updated: RecordUpdatedTriggerConditions,
    record_created_or_updated: RecordUpdatedTriggerConditions,
}
