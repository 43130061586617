import React, { useMemo } from 'react'

import { useCombobox, UseComboboxProps } from 'downshift'

import { ComboboxContext, Item as ItemType, useComboboxContext } from './ComboboxContext'

/*
 *  Unstyled combox box primitves which utilize downshift's useCombobox hook
 */
type RootProps = React.PropsWithChildren<UseComboboxProps<ItemType>>
function Root({ children, ...rest }: RootProps) {
    const combobox = useCombobox({ selectedItemChanged: () => true, ...rest })

    const contextValue = useMemo(() => ({ ...combobox }), [combobox])
    return <ComboboxContext.Provider value={contextValue}>{children}</ComboboxContext.Provider>
}

type MenuProps = React.HTMLProps<HTMLDivElement>
const Menu = React.forwardRef(function Menu(props: MenuProps, ref) {
    const context = useComboboxContext()
    return <div ref={ref} {...context.getMenuProps()} {...props} />
})

type InputProps = React.HTMLProps<HTMLInputElement>
const Input = React.forwardRef(function Input(props: InputProps, ref) {
    const context = useComboboxContext()
    return <Input ref={ref} {...context.getInputProps()} {...props} />
})

type ItemProps = React.HTMLProps<HTMLDivElement> & { item: ItemType; index: number }
const Item = React.forwardRef(function Item({ item, index, ...props }: ItemProps, ref) {
    const context = useComboboxContext()
    return (
        <div
            ref={ref}
            {...context.getItemProps({ item, index })}
            {...props}
            data-highlighted={index === context.highlightedIndex ? true : undefined}
        />
    )
})

export const Combobox = {
    Root,
    Menu,
    Input,
    Item,
}
