import { useMemo } from 'react'

import { RichTextValue } from 'features/views/attributes/types'
import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'

type UseCardViewProfileImageStateOptions = {
    record: RecordDto
}

export function useCardViewProfileImageState(options: UseCardViewProfileImageStateOptions) {
    const { record } = options

    const { profileImageField } = useCardViewContext()

    const hasField = !!profileImageField

    const fieldValue = hasField ? record?.[profileImageField?.api_name] : undefined
    const imageSrc = hasField ? extractSrcFromFieldValue(profileImageField, fieldValue) : undefined

    const hasThumbnail = hasField && !!imageSrc

    return useMemo(
        () => ({
            hasThumbnail,
            imageSrc,
        }),
        [hasThumbnail, imageSrc]
    )
}

function extractSrcFromFieldValue(field: FieldDto, value: unknown) {
    if (!value) return undefined
    if (['date', 'datetime', 'lookup', 'checkbox'].includes(field.type)) {
        return undefined
    }

    if (typeof value === 'string') {
        return value
    }

    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    }

    if (field.type === 'document') {
        return (value as RichTextValue).plainTextContent
    }

    return undefined
}
