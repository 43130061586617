import { useEffect } from 'react'

import aa, { InsightsEventType } from 'search-insights'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { getWorkspaceAccount } from 'app/GlobalStaticState'
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, ALGOLIA_INDEX_ID } from 'app/settings'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function AlgoliaInsights() {
    const { workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()

    useEffect(() => {
        if (workspaceAccount && user) {
            aa('init', {
                appId: ALGOLIA_APP_ID,
                apiKey: ALGOLIA_API_KEY,
                userToken: getAlgoliaUserToken(user._sid),
            })
        }
    }, [workspaceAccount, user])

    return null
}

export type InsightEventName =
    | 'ViewedRecord'
    | 'ViewedObject'
    | 'ViewedApp'
    | 'CreatedRecord'
    | 'UpdatedRecord'

const eventNameToType: Record<InsightEventName, InsightsEventType> = {
    ViewedRecord: 'click',
    ViewedObject: 'click',
    ViewedApp: 'click',
    CreatedRecord: 'conversion',
    UpdatedRecord: 'conversion',
}

export const InsightEvent: Record<InsightEventName, InsightEventName> = {
    ViewedRecord: 'ViewedRecord',
    ViewedObject: 'ViewedRecord',
    ViewedApp: 'ViewedRecord',
    CreatedRecord: 'CreatedRecord',
    UpdatedRecord: 'UpdatedRecord',
}

export type InsightsEvent = {
    eventName: InsightEventName
    objectIds: string[]
}

type useTrackEventsProps = {
    onLoad?: InsightsEvent[]
}
export function useTrackEvents(props: useTrackEventsProps | undefined) {
    const { onLoad } = props || {}
    const eventsOnLoad = useDeepEqualsMemoValue(onLoad)

    useEffect(() => {
        if (eventsOnLoad) {
            trackInsightsEvents(eventsOnLoad)
        }
    }, [eventsOnLoad])

    return trackInsightsEvents
}

function getAlgoliaUserToken(userId: string): string {
    return userId.replace(/[^a-zA-Z0-9_=]/g, '_')
}

export const trackInsightsEvents = (events: InsightsEvent[]) => {
    const workspaceAccount = getWorkspaceAccount()
    if (!workspaceAccount) return

    const index = ALGOLIA_INDEX_ID
    if (!index) return

    aa(
        'sendEvents',
        events.map(({ eventName, objectIds }) => ({
            eventType: eventNameToType[eventName],
            index: ALGOLIA_INDEX_ID,
            eventName,
            objectIDs: objectIds,
            filters: [`_account_id:${workspaceAccount._sid}`],
        }))
    )
}
