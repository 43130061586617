import { useCallback, useMemo } from 'react'

import { SendToClipboardParams } from 'ag-grid-enterprise'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

type UseHandleSendToClipboardProps = {
    fields: FieldDto[]
}

export function useHandleSendToClipboard({ fields }: UseHandleSendToClipboardProps) {
    const { getDocumentFieldHTMLContent } = useDataGridContext()

    const fieldsByApiName = useMemo(() => {
        return fields.reduce<Map<string, FieldDto>>(
            (agg, field) => agg.set(field.api_name, field),
            new Map<string, FieldDto>()
        )
    }, [fields])

    return useCallback(
        (params: SendToClipboardParams) => {
            let plainText = params.data
            let htmlText = ''

            const cellRanges = params.api.getCellRanges()

            const range = cellRanges?.[0]
            const startIndex = range?.startRow?.rowIndex ?? 0
            const endIndex = range?.endRow?.rowIndex ?? 0

            // Make sure a single cell is selected.
            if (range?.columns.length === 1 && startIndex - endIndex === 0) {
                const column = range.columns[0]
                const field = fieldsByApiName.get(column.getColId())

                // Make sure document fields keep their formatting.
                if (field?.type === 'document') {
                    const currentRow = params.api.getDisplayedRowAtIndex(startIndex)!
                    const value = params.api.getValue(column, currentRow) as TipTapValue

                    plainText = value.plainTextContent
                    htmlText = getDocumentFieldHTMLContent(value)
                }
            }

            const textBlob = new Blob([plainText], { type: 'text/plain' })
            const htmlBlob = new Blob([htmlText], { type: 'text/html' })

            const clipboardContent: Record<string, Blob> = {
                [textBlob.type]: textBlob,
            }
            if (htmlBlob.size > 0) {
                clipboardContent[htmlBlob.type] = htmlBlob
            }

            navigator.clipboard.write([new ClipboardItem(clipboardContent)])
        },
        [fieldsByApiName, getDocumentFieldHTMLContent]
    )
}
