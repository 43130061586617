import { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useQuery,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'

type InternalOptions = {
    includeUsersWithNoAccess?: boolean
    paginate?: boolean
    pageSize?: number
    pageIndex?: number
    grantType?: AccessGrant['type']
    grantSid?: string
    searchQuery?: string
}

type Response = {
    users: UserDto[]
    count: number
}

/**
 * Gets all the app users for the current studio user
 * ignoring preview as
 * NOTE: as this is pretty heavy, you need to enable it explicitly by using true as first parameter
 * @param isEnabled explicitly state is your react-query is enabled
 * @param {import('react-query').UseQueryOptions } options
 */
export function useAppUsersForAdmin(
    isEnabled = false,
    options = {},
    internalOptions: InternalOptions = {}
) {
    const QUERY_NAME: REACT_QUERY_DEPS_NAME = 'useAppUsersForAdmin'
    const stackQueriesEnabled = useCanRunStackScopedQueries()

    const urlParams = new URLSearchParams()
    if (internalOptions.paginate) {
        urlParams.set('paginate', 'true')
        if (typeof internalOptions.pageSize !== 'undefined') {
            urlParams.set('limit', internalOptions.pageSize.toString())
        }
        if (typeof internalOptions.pageIndex !== 'undefined') {
            urlParams.set('offset', internalOptions.pageIndex.toString())
        }
    }

    if (internalOptions.searchQuery) {
        urlParams.set('search', internalOptions.searchQuery)
    }

    if (internalOptions.grantType) {
        urlParams.set('grant_type', internalOptions.grantType)
    }
    if (internalOptions.grantSid) {
        urlParams.set('grant_sid', internalOptions.grantSid)
    }

    const url = !!urlParams.size ? `list-app-users/?${urlParams.toString()}` : 'list-app-users/'

    const { selectedStack } = useAppContext()
    const queryKey = useQueryKeyBuilder([QUERY_NAME, internalOptions], {
        includeStackId: true,
    })
    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })

    const query = useQuery<Response>(
        queryKey,
        url,
        { enabled: isEnabled && stackQueriesEnabled, ...options },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            bypassQueryDeps: true,
        }
    )
    const includeUsersWithNoAccess = internalOptions?.includeUsersWithNoAccess
    return useMemo(() => {
        const users = query.data?.users
        const totalUserCount = query.data?.count

        const data = users && !includeUsersWithNoAccess ? users.filter((user) => user.role) : users

        const refetch = async (options?) => {
            const result = await query.refetch(options)

            return { ...result, data: result.data?.users, totalUserCount: result.data?.count }
        }

        return {
            isLoading: query.isLoading,
            data,
            totalUserCount,
            refetch,
            isFetched: query.isFetched,
            isFetching: query.isFetching,
            isError: query.isError,
        }
    }, [query, includeUsersWithNoAccess])
}
