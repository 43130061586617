import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Divider = styled('hr')`
    border: 1px solid ${(props) => props.theme.dividerColor};
    border-bottom: none;
    margin: ${(props) => props.theme[props.margin || 'medium']};
`

Divider.propTypes = {
    margin: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'none']),
}

Divider.displayName = 'Divider'
export default Divider
