import React, { Component, useCallback } from 'react'

import BlankPageView from 'v2/views/BlankPage/BlankPageView'
import CreateViewWrapper from 'v2/views/Create/CreateViewWrapper'
import { DetailViewWrapper } from 'v2/views/Detail/DetailViewWrapper'
import DocumentView from 'v2/views/Document/DocumentView'
import { PreviewRecordModal } from 'v2/views/List/PreviewRecord/PreviewRecordModal'
import { useGoBackUsingBreadcrumbs } from 'v2/views/utils/useGoBackUsingBreadcrumbs'

import { withUser } from 'data/wrappers/WithUser'
import WithView from 'data/wrappers/WithView'
import { text } from 'features/pages/blocks/settings/attributes/items/primitives'
import { ListView } from 'features/views/ListView/ListView'

const ViewBlockInner = ({ view, onChange, onRecordLoadError, ...props }) => {
    const recordId = props.context.url && props.context.url.id
    const { context, isEditing } = props
    const handleViewChange = useCallback((options) => onChange({ options }), [onChange])
    const goBackUsingBreadcrumbs = useGoBackUsingBreadcrumbs()

    // If the record throws a permissions error, then the current user
    // has no permission to view this page, so we're just going to redirect to home
    const handleRecordLoadError = useCallback(
        (error) => {
            if (error?.status === 403 || error?.status === 404) {
                onRecordLoadError ? onRecordLoadError(error) : goBackUsingBreadcrumbs()
            }
        },
        [goBackUsingBreadcrumbs, onRecordLoadError]
    )
    if (view.type === 'list') {
        return (
            <ListView
                view={view}
                context={context}
                showControls={isEditing}
                onChange={handleViewChange}
            />
        )
    }
    if (view.type === 'create') {
        return (
            <CreateViewWrapper
                view={view}
                showRelated
                context={context}
                objectId={view.object_id}
                config={view.options}
                showControls={isEditing}
                onChange={handleViewChange}
                isCreate
            />
        )
    }
    if (view.type === 'detail') {
        return (
            <DetailViewWrapper
                view={view}
                showRelated
                context={context}
                objectId={view.object_id}
                config={view.options}
                showControls={isEditing}
                onChange={handleViewChange}
                recordSid={recordId}
                fromListView={props.fromListView}
                isRecordList={props.isRecordList}
                storeTabState={props.storeTabState}
                onError={handleRecordLoadError}
            />
        )
    }
    if (view.type === 'document') {
        return <DocumentView view={view} page={context?.page} onError={handleRecordLoadError} />
    }
    if (view.type === 'blankpage') {
        return <BlankPageView view={view} page={context?.page} showControls={isEditing} />
    }
    return 'Unknown view'
}

export class ViewBlock extends Component {
    render() {
        const { attrs } = this.props

        return (
            <WithView viewId={attrs.viewId}>
                {({ view, onChange }) => (
                    <ViewBlockInner {...this.props} view={view} onChange={onChange} />
                )}
            </WithView>
        )
    }
}

const _ViewBlockWrapper = (props) => {
    return (
        <React.Fragment key={props.attrs.viewId}>
            <ViewBlock {...props} />
            <PreviewRecordModal />
        </React.Fragment>
    )
}

export const ViewBlockWrapper = withUser(_ViewBlockWrapper)

ViewBlock.attributes = [text({ field: 'viewId', label: 'View' })]
