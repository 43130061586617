import React, { useState } from 'react'

import { refetchAccounts } from 'data/hooks/accounts'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Progress } from 'ui/components/Progress'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'

import { Bundle, bundleBuilder } from './bundles/bundleBuilder'
import { SimpleBundlePreview } from './components/SimpleBundlePreview/SimpleBundlePreview'
import { AiGenerationFailed } from './AiGenerationFailed'
import { RequirementsStep } from './RequirementsStep'
import { Step1Describe } from './Step1Describe'

type AiAppBuilderProps = {
    onClose: () => void
}

const SAMPLE_APP_BUNDLE_URL = 'https://resources.go.stackerhq.com/templates/default_sample_app.json'

export function AiAppBuilder({ onClose }: AiAppBuilderProps) {
    const [clearbitContext] = useState<string>('')
    const [bundle, setBundle] = useState<Bundle | null>(null)
    const [generationFailed, setGenerationFailed] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [requirements, setRequirements] = useState<string[]>([])
    const [progress, setProgress] = useState<{ progress: number; status: string }>({
        progress: 0,
        status: '',
    })
    const [isGenerating, setIsGenerating] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<'describe' | 'requirements'>('describe')

    async function initializeAccount({
        bundle,
        bundleUrl,
    }: {
        bundle?: Bundle
        bundleUrl?: string
    }) {
        // If running this inside an existing workspace we'll hit the bundle import endpoint
        // and also have to refetchRoles(), refetchStacks() instead of accounts at the end
        const response = await fetchWithAuth('accounts/initialize-from-onboarding/', {
            method: 'POST',

            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                company_name: localStorage.getItem('onboardingCompanyName'),
                bundle: bundle ? JSON.stringify(bundle) : undefined,
                bundle_url: bundleUrl,
            }),
        })

        if (response.status !== 200) return

        const data = await response.json()
        console.log(data)
        await Promise.all([refetchAccounts()])
    }

    async function handleFinish() {
        assertIsDefined(bundle)
        const result = bundleBuilder(bundle)
        console.log('# Importing bundle', { simple: bundle, full: result })
        await initializeAccount({ bundle: result })
        onClose()
    }

    async function handleInstallDefaultBundle() {
        console.log('# Importing default app bundle')
        await initializeAccount({ bundleUrl: SAMPLE_APP_BUNDLE_URL })
        onClose()
    }
    return (
        <>
            <Box
                display={{ mobile: 'flex', tablet: 'none' }}
                column
                center
                justifyContent="center"
                gap="3xl"
                grow
            >
                <Icon name="ScreenShare" size="4xl" opacity={0.5} />
                <Body size="l" textAlign="center">
                    Since you&apos;re on a small screen we will install a sample app for you to
                    explore.
                </Body>
                <Body size="l" textAlign="center">
                    To build custom apps, please log in on a tablet or computer.
                </Body>
                <Button onClick={handleInstallDefaultBundle} width="full">
                    Continue
                </Button>
            </Box>
            <Box grow display={{ mobile: 'none', tablet: 'flex' }}>
                <Box
                    flex
                    column
                    gap="l"
                    justifyContent="center"
                    style={{ flexBasis: '40%' }}
                    p="3xl"
                    noShrink
                >
                    {currentStep === 'describe' && (
                        <Step1Describe
                            description={description}
                            clearbitContext={clearbitContext}
                            setDescription={setDescription}
                            onMoveNext={(result: string[]) => {
                                setRequirements(result)
                                setCurrentStep('requirements')
                            }}
                            failed={generationFailed}
                            setFailed={setGenerationFailed}
                        />
                    )}
                    {currentStep === 'requirements' && (
                        <RequirementsStep
                            requirements={requirements}
                            clearbitContext={clearbitContext}
                            setRequirements={setRequirements}
                            onMoveNext={handleFinish}
                            moveBack={() => setCurrentStep('describe')}
                            setBundle={setBundle}
                            isGenerating={isGenerating}
                            setIsGenerating={setIsGenerating}
                            updateProgress={setProgress}
                            failed={generationFailed}
                            setFailed={setGenerationFailed}
                        />
                    )}
                </Box>
                <Box
                    flex
                    column
                    style={{ flexBasis: '60%' }}
                    p="3xl"
                    background={'gray100'}
                    shrink
                    rounded="m"
                >
                    {generationFailed && (
                        <AiGenerationFailed onContinue={handleInstallDefaultBundle} />
                    )}
                    {!generationFailed && (
                        <>
                            <Box
                                flex
                                column
                                width="full"
                                alignSelf="stretch"
                                mb="xl"
                                style={{ visibility: isGenerating ? 'visible' : 'hidden' }}
                            >
                                <Progress
                                    value={progress.progress}
                                    width="full"
                                    alignSelf="stretch"
                                    style={{ width: '100%' }}
                                >
                                    <Box flex center gap="m">
                                        <Spinner size="s" style={{ opacity: 0.5 }} />
                                        <Body color="textWeakest">Generating preview:</Body>
                                        <Body>{progress.status}</Body>
                                    </Box>
                                </Progress>
                            </Box>
                            <Box flex column gap="l" justifyContent="center" shrink grow>
                                <Box height="full" maxHeight="600px">
                                    {bundle && <SimpleBundlePreview bundle={bundle} />}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
