import React, { FC } from 'react'

import styled from '@emotion/styled'
import Color from 'color'

import { DROPDOWN_COLORS } from 'data/utils/fieldDefinitions'
import { convertV2FieldColorToTheme, getDefaultDropdownFieldOptionColor } from 'utils/fieldUtils'

import { DropdownButton, Icon } from 'v2/ui'
import { DropdownArrow, SolidCheck } from 'v2/ui/svgs'

const ColorsWrapper = styled.div`
    width: calc(216px + 1.5rem);
    padding: 0.75rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
`

const ColorCheck = styled.div<{ color: string }>`
    cursor: pointer;

    width: 20px;
    height: 20px;

    background-color: ${(props) => props.color};
    transition: 0.2s ease background-color;

    border-radius: 3px;
`

const SolidCheckWrapper = styled.div`
    margin-top: 3px;
    margin-left: 3px;
`

type Props = {
    value: string
    disabled: boolean
    onChange: (color: string) => void
}

const ColorPicker: FC<Props> = ({ value, disabled, onChange }) => {
    const colorScheme = convertV2FieldColorToTheme(value)

    const currentColor = colorScheme
        ? DROPDOWN_COLORS[colorScheme] ?? value
        : getDefaultDropdownFieldOptionColor()

    const darkenedColor = Color(currentColor).darken(0.5).hex()

    const onSelectColor = (colorName: string, closeDropdown: () => void) => {
        onChange(colorName)
        closeDropdown()
    }

    return (
        <DropdownButton
            style={{
                width: 16,
                height: 16,
                padding: 0,
                borderRadius: 3,
                background: currentColor,
                transition: '0.2s ease background',
            }}
            dropdownBoxStyle={{
                padding: 0,
                marginTop: '0.25rem',
                borderRadius: 8,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            }}
            place="bottom-start"
            color={currentColor}
            // @ts-expect-error
            icon={<Icon svg={<DropdownArrow />} fill={darkenedColor} />}
            hideIcon
            disabled={disabled}
        >
            {({ close }: { close: () => void }) => (
                <ColorsWrapper>
                    {Object.entries(DROPDOWN_COLORS).map(([name, value]) => (
                        <ColorCheck
                            key={name}
                            color={value}
                            onClick={() => onSelectColor(name, close)}
                        >
                            {value === currentColor && (
                                <SolidCheckWrapper>
                                    <SolidCheck color={darkenedColor} />
                                </SolidCheckWrapper>
                            )}
                        </ColorCheck>
                    ))}
                </ColorsWrapper>
            )}
        </DropdownButton>
    )
}

export default ColorPicker
