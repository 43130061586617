import React, { useEffect, useMemo, useRef, useState } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { TaskPayload, UpdateTaskPayload } from 'data/hooks/tasks/types'
import { CommentCountDisplay } from 'features/Activity/CommentCount'
import { useActivityFeedConfig } from 'features/Activity/useActivityFeedConfig'

import { useToast } from 'v2/ui/components/Toast'
import usePrevious from 'v2/ui/hooks/usePrevious'
import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'

import { isDescriptionEmpty } from './descriptionUtils'
import { RecordLink } from './RecordLink'
import { TaskAdditionalOptions } from './TaskAdditionalOptions'
import { AssigneesDisplay } from './TaskAssigneesDisplay'
import { TaskAttachmentCounter } from './TaskAttachmentCounter'
import { useTaskListContext } from './TaskListContext'
import { TasksCheckboxEditor } from './TasksCheckboxEditor'
import { TasksDescription } from './TasksDescription'
import { TasksDueDateEditor } from './TasksDueDateEditor'
import { TasksEditModePopover } from './TasksEditModePopover'
import { TaskSourceDisplay } from './TaskSourceDisplay'
import { TasksTitle } from './TasksTitle'
import { TaskAssignee } from './types'

import { OpacityOnTaskHover, TasksListItemStyle } from './TasksListItem.css'

type TasksListItemProps = React.ComponentPropsWithoutRef<typeof Box> & {
    task: TaskPayload
    onEdit?: (task: TaskPayload) => void
    showGutter?: boolean
    editOnClick?: boolean
}

export const TasksListItem: React.FC<TasksListItemProps> = ({
    task,
    onEdit,
    showGutter,
    editOnClick,
    ...props
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null)

    const {
        related_to: list_related_to,
        updateTask,
        _object_id,
        showAssignees,
        showSource,
    } = useTaskListContext()
    const { user } = useAppUserContext()
    const [isDueDateOpen, setIsDueDateOpen] = useState(false)
    const [isAdditionalOptionsOpen, setIsAdditionalOptionsOpen] = useState(false)
    const [isHovering, hoverListeners] = useHover()
    const isExtendedMode = isHovering || isDueDateOpen || isAdditionalOptionsOpen
    const lastDueDate = usePrevious(task.due_at)

    const [isEditMode, setIsEditMode] = useState(false)
    const enableEditMode = () => {
        setIsEditMode(true)
        onEdit?.(task)
    }

    const handleDoubleClick = (e: React.MouseEvent) => {
        const datePicker = document.querySelector('.react-datepicker')
        if (datePicker) {
            const target = e.target as HTMLElement
            if (datePicker.contains(target)) return
        }

        enableEditMode()
    }

    const hasDescription = useMemo(() => !isDescriptionEmpty(task.description), [task.description])

    const toast = useToast()

    const [dueDate, setDueDate] = useState(task.due_at ?? '')
    useEffect(() => {
        if (lastDueDate === task.due_at) return

        const dueAt = task.due_at ?? ''
        setDueDate(dueAt)
    }, [task.due_at, dueDate, lastDueDate])

    const doUpdateTask = (task: UpdateTaskPayload) => {
        updateTask({ ...task, _object_id })
            .then(() => {
                toast({ title: 'Task updated successfully.', status: 'success' })
            })
            .catch(() => {
                toast({
                    title: 'There was a problem updating the task. Please try again later.',
                    status: 'error',
                })
            })
    }

    const saveDueDate = () => {
        const newDueDate = dueDate || null
        if (task.due_at === newDueDate) return

        doUpdateTask({
            taskId: task.auto_id,
            due_at: newDueDate,
        })
    }

    const onChangeAssignees = (assignees: TaskAssignee[]) => {
        doUpdateTask({
            taskId: task.auto_id,
            assignees,
        })
    }

    const handleDueDateClear = () => {
        doUpdateTask({
            taskId: task.auto_id,
            due_at: null,
        })
    }

    const updateTaskStatus = (isCompleted: boolean) => {
        doUpdateTask({ taskId: task.auto_id, is_completed: isCompleted })
    }

    const showRelatedItem =
        !!task.related_to && task.related_to !== list_related_to && !!task.related_to_stack

    const shouldShowAssignees =
        showAssignees === 'always' ||
        (showAssignees === 'whenNotMe' &&
            !task.assignees?.find((assignee) => assignee === user?._sid))

    const shouldShowSource =
        showSource === 'always' || (showSource === 'whenNotMe' && task.source !== user?._sid)

    const { commentCount = 0 } = useActivityFeedConfig({ task })

    return (
        <>
            <Box
                ref={wrapperRef}
                px={showGutter ? '3xl' : 'l'}
                py="xs"
                className={TasksListItemStyle}
                {...hoverListeners}
                flex
                column
                width="full"
                onDoubleClick={handleDoubleClick}
                cursor={editOnClick ? 'pointer' : 'default'}
                onClick={editOnClick && !isEditMode ? enableEditMode : undefined}
                {...props}
            >
                <Box flex>
                    <TasksCheckboxEditor
                        value={task.is_completed}
                        onChange={updateTaskStatus}
                        my="xs"
                        mr="l"
                        noShrink
                    />
                    <Box grow shrink flex center columnGap="m" flexWrap="wrap">
                        <TasksTitle onClick={enableEditMode}>
                            <Box trim title={task.title} style={{ lineHeight: '22px' }}>
                                {task.title}
                            </Box>
                        </TasksTitle>
                        {commentCount > 0 && (
                            <CommentCountDisplay
                                count={commentCount}
                                alwaysShow={true}
                                onClick={enableEditMode}
                                role="button"
                                tabIndex={0}
                            />
                        )}
                        <TaskAttachmentCounter attachments={task.attachments} allowPreview={true} />
                        {showRelatedItem && (
                            <RecordLink
                                recordId={task.related_to!}
                                stackId={task.related_to_stack!}
                                flexShrink={1}
                                maxWidth="300px"
                            />
                        )}
                    </Box>

                    <Box flex gap="m" noShrink center>
                        {shouldShowSource && (
                            <TaskSourceDisplay
                                source={task.source}
                                source_type={task.source_type}
                                created_at={task.created_at}
                                mr="xs"
                            />
                        )}
                        <TasksDueDateEditor
                            value={dueDate}
                            onChange={setDueDate}
                            variant="ghost"
                            isClearable
                            onBlur={() => {
                                saveDueDate()
                                setIsDueDateOpen(false)
                            }}
                            onClear={handleDueDateClear}
                            onOpen={() => setIsDueDateOpen(true)}
                            opacity={isExtendedMode || dueDate ? 1 : 0}
                            pointerEvents={isExtendedMode || dueDate ? 'auto' : 'none'}
                            clearButtonProps={{
                                className: OpacityOnTaskHover,
                            }}
                        />
                        {shouldShowAssignees && (
                            <AssigneesDisplay
                                displayPlaceholder={isExtendedMode}
                                assignees={task.assignees}
                                onChangeAssignees={onChangeAssignees}
                            />
                        )}
                        <TaskAdditionalOptions
                            buttonProps={{
                                className: OpacityOnTaskHover,
                                ml: 'm',
                                size: '2xs',
                            }}
                            onOpenChange={setIsAdditionalOptionsOpen}
                            onEdit={enableEditMode}
                            taskId={task.auto_id}
                            _object_id={_object_id}
                            related_to={list_related_to}
                            source={task.source}
                            source_type={task.source_type}
                            is_completed={task.is_completed}
                        />
                    </Box>
                </Box>
                {hasDescription && (
                    <Box flex>
                        <Box style={{ width: '17px' }} mr="l" noShrink />
                        <Box grow width="full" shrink>
                            <TasksDescription value={task.description} trim width="full" mt="xs" />
                        </Box>
                    </Box>
                )}
            </Box>
            <TasksEditModePopover
                task={task}
                targetElement={wrapperRef.current as HTMLElement}
                open={isEditMode}
                onClose={() => setIsEditMode(false)}
                px={showGutter ? '3xl' : 'l'}
            />
        </>
    )
}
