import { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { defaultColor } from 'features/workspace/forms/ColorPicker'
import { ThemeConfig } from 'features/workspace/types'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

type Theme = {
    navColor: 'light' | 'dark' | 'brand'
    brandColor?: string
    icon?: string
    logo_icon?: string
}

const defaultDarkColors = {
    bgNav: '#333C43',
    bgScdNav: '#343843',
    text: 'rgba(255,255,255,0.6)',
    textBright: 'white',
    highlightColor: 'white',
    bgAppBar: '#1C2227',
}
const defaultLightColors = {
    bgNav: 'white',
    bgScdNav: 'white',
    text: '#1C1D25BB',
    textBright: '#1C1D25',
    highlightColor: 'white',
    bgAppBar: 'white',
}
const defaultBrandColors = {
    text: 'rgba(255,255,255,0.6)',
    textBright: 'white',
    highlightBg: 'white',
    highlightColor: V4DesignSystem.colors.text,
    bgAppBar: '#1C2227',
}

export const getNavigationColor = ({ navColor, brandColor = defaultColor }: Theme): ThemeConfig => {
    if (navColor === 'dark') {
        return {
            ...defaultDarkColors,
            highlightBg: brandColor,
            brandColor,
            theme: navColor,
        } as ThemeConfig
    }
    if (navColor === 'brand') {
        return {
            ...defaultBrandColors,
            bgScdNav: brandColor,
            brandColor,
            theme: navColor,
            bgNav: brandColor,
        } as ThemeConfig
    }

    return {
        ...defaultLightColors,
        highlightBg: brandColor,
        brandColor,
        theme: navColor,
    } as ThemeConfig
}

const useNavigationColor = ({ navColor, brandColor }: Theme): ThemeConfig => {
    const { workspaceAccount } = useAppContext()

    if (!brandColor) {
        brandColor = workspaceAccount?.options?.theme?.brandColor
    }
    const _navColor = navColor || workspaceAccount?.options?.theme?.navColor
    return useMemo(
        () =>
            getNavigationColor({
                navColor: _navColor || 'dark',
                brandColor,
            }),
        [_navColor, brandColor]
    )
}

export default useNavigationColor
