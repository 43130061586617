import * as React from 'react'
import { useMemo, useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'

import stackerTheme from 'v2/ui/theme/styles/default'

import GeneralSettings from './AppSettingsGeneralSettings/AppSettingsModalGeneralSettings'
import Permissions from './Permissions/AppSettingsModalPermissions'
import Roles from './Roles/AppSettingsModalRoles'
import Appearance from './AppSettingsModalAppearance'
import Navigation from './AppSettingsModalNavigation'
import { Sidebar } from './AppSettingsModalSidebar'

export const colors = stackerTheme().colors

const GeneralSettingsMemo = React.memo(GeneralSettings)

/**
 *
 * @param {AppSettingsPage} page
 * @returns
 */
function getPageComponent(page) {
    switch (page?.name) {
        case 'general':
            return GeneralSettingsMemo

        case 'appearance':
            return Appearance

        case 'navigation':
            return Navigation

        case 'roles':
            return Roles

        case 'permissions':
            return Permissions

        default:
            return null
    }
}

export const AppSettingsContent = ({
    onRequestClose = () => {},
    modalState = { page: { name: 'general' } },
    setPage,
}) => {
    const formRef = useRef({})
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const { selectedStack } = useAppContext()

    const { page } = modalState

    // Because in some cases getPageComponent returns an inline created function to render
    // each call will yield a new function and thus a new component. This useMemo will ensure
    // That doesn't happen on rerender of this component unless something actually has changed.
    const Component = useMemo(() => getPageComponent(page, selectedStack), [page, selectedStack])

    /**
     * check for unsaved changes, then change the page
     * @param  {AppSettingsPage} page
     * @return {Promise<any>}
     **/
    const onChangePage = (page) => {
        return saveChanges(() => setPage(page))
    }

    // check for unsaved changes, then close the modal
    function onClose() {
        saveChanges(onRequestClose)
    }

    return (
        <div
            style={{
                display: 'flex',
                flexGrow: 1,
                overflow: 'hidden',
            }}
        >
            <Sidebar onChangePage={onChangePage} pageName={page.name} pageOptions={page.meta} />
            <div
                style={{
                    overflowY: 'auto',
                    width: '100%',
                    display: 'flex',
                }}
            >
                {Component ? (
                    <Component formRef={formRef} onCloseSettingsModal={onClose} {...page.props}>
                        {displayChangesModal && <UnsavedChangesModal {...displayChangesModal} />}
                    </Component>
                ) : (
                    "can't render " + page.name
                )}
            </div>
        </div>
    )
}
