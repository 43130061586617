import React, { useRef } from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { useNavigationPosition } from 'features/core/nav/useNavigationPosition'
import { limitStackNameLogToChars } from 'features/utils/getLogo'

import { ConditionalWrapper, Tooltip } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const Logo = styled.img`
    margin: auto;
`

export function WorkspaceLogoInner({ workspaceAccount, logoStyle = null }) {
    const logoRef = useRef()
    const { selectedStack } = useAppContext()
    const navPosition = useNavigationPosition()

    const wsTheme = workspaceAccount.options?.theme ?? {}
    const stackTheme = selectedStack?.options?.theme ?? {}
    const defaultStyle = { height: 37, maxWidth: 230 }
    const topNav = navPosition === 'top'
    const style = logoStyle || defaultStyle

    const logoSrc =
        stackTheme.logo ||
        (wsTheme.branding === 'logo' && wsTheme.logo) ||
        (wsTheme.branding === 'logo_icon' && wsTheme.logo_icon)

    if (logoSrc) {
        return (
            <Logo
                ref={logoRef}
                onLoad={() => logoRef.current.setAttribute('data-loaded', 'true')}
                src={logoSrc}
                alt="Workspace Logo"
                style={style}
            />
        )
    }

    const maxCharLength = 19
    let logoText = workspaceAccount.name ?? 'Workspace'
    const longWsName = logoText.length > maxCharLength
    if (topNav && longWsName) {
        logoText = limitStackNameLogToChars(logoText, maxCharLength)
    }

    return (
        <ConditionalWrapper
            condition={topNav && longWsName}
            wrapper={(children) => (
                <Tooltip label={workspaceAccount.name} position="bottom">
                    {children}
                </Tooltip>
            )}
        >
            <div style={{ ...V4DesignSystem.h1, marginTop: 10, marginBottom: 10 }}>{logoText}</div>
        </ConditionalWrapper>
    )
}

/** @deprecated
 *  For new designs use `ZoneLogo` from `features/workspace/zones/ZoneLogo`
 */
export default function WorkspaceLogo(props = {}) {
    const { workspaceAccount } = useAppContext()

    return <WorkspaceLogoInner workspaceAccount={workspaceAccount} {...props} />
}
