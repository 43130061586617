import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 1
const MAX_OVERFLOW_LENGTH = 300

type TextCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const TextCell: TableViewCellComponent<string, TextCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { isPrimary, field, rawOptions } = column

    const isEmpty = !isLoading && !value

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <TextAttributeDisplay
                weight={isPrimary ? 'bold' : undefined}
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                value={value}
                field={field}
                maxLines={MAX_LINES}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
                treatAsUrl={rawOptions.treatAsUrl}
                buttonTitle={rawOptions.buttonTitle}
                displayAsImage={rawOptions.displayAsImage}
                imageAltText={rawOptions.imageAltText}
                openInNewTab={rawOptions.openInNewTab}
            />
        </BaseCell>
    )
}
