import React from 'react'

import { isEqual } from 'lodash'

import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { TableViewHeaderSort } from './Sort/TableViewHeaderSort'
import * as Parts from './TableView.parts'
import { TableViewCellContent } from './TableViewCellContent'
import { TableViewColumn } from './types'

type TableViewHeaderProps = React.ComponentPropsWithoutRef<typeof Parts.Header> & {
    column: TableViewColumn
    showIcon?: boolean
}

export const TableViewHeader: React.FC<TableViewHeaderProps> = React.memo(function TableViewHeader({
    column,
    showIcon,
    ...props
}) {
    const { title, isPinned, isPrimary, supportsSort, icon } = column

    return (
        <Parts.Header color={isPrimary ? 'text' : 'textWeak'} isPinned={isPinned} {...props}>
            <TableViewCellContent column={column} gap="xs">
                {showIcon && icon && (
                    <FieldIcon
                        size={{ mobile: 'xs', tablet: 's' }}
                        color={isPrimary ? 'text' : 'icon'}
                        noShrink
                        value={icon}
                        mr={{ mobile: 0, tablet: '3xs' }}
                    />
                )}
                <Box trim>
                    <Body
                        size={{ mobile: 's', tablet: 'm' }}
                        weight={isPrimary ? 'bold' : 'medium'}
                        style={{ color: 'inherit' }}
                    >
                        {title}
                    </Body>
                </Box>
                {supportsSort && <TableViewHeaderSort column={column} />}
            </TableViewCellContent>
        </Parts.Header>
    )
},
isEqual)
