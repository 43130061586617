import React from 'react'

import { useAppContext } from 'app/AppContext'
import { NavMenuButton } from 'features/NewAppBar/NavMenuButton'
import { SidebarHeader } from 'features/workspace/Sidebar'

import useNavigationColor from 'v2/ui/hooks/useNavigationColor'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'

export const HEADER_HEIGHT = 46

type MobileNavigationProps = {}

export const MobileNavigation: React.FC<MobileNavigationProps> = () => {
    const { selectedStack: stack } = useAppContext()

    const stackTheme = stack?.options?.theme ?? ({} as StackDto['options']['theme'])
    const navTheme = useNavigationColor(stackTheme)

    return (
        <Box
            background="surface"
            width="full"
            position="fixed"
            top={0}
            left={0}
            style={{ zIndex: 199, height: `${HEADER_HEIGHT}px` }}
            flex
            center
            grow
            alignSelf="stretch"
            gap={{ mobile: 'xs' }}
            borderWidth={0}
            borderBottomWidth={{ mobile: 'base' }}
            borderStyle="base"
            borderColor="borderWeak"
            p={{ mobile: 'm' }}
            className={STYLE_CLASSES.HEADER}
            trim
        >
            <NavMenuButton variant="ghost" size="s" startIcon={{ name: 'PanelLeftOpen' }} />
            <Box grow>
                <SidebarHeader
                    stack={stack}
                    navTheme={navTheme}
                    style={{ minHeight: 'auto', padding: 0, textAlign: 'left' }}
                    logoStyle={{
                        margin: 0,
                        height: '24px',
                    }}
                />
            </Box>
        </Box>
    )
}
