import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import isUrl from 'is-url'
import queryString from 'qs'

import { getUrl, Urls } from 'app/UrlService'
import { buildUrl } from 'data/utils/utils'
import analytics from 'utils/analytics'

import { Collapse, Flex } from 'v2/ui'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import { Button, Input, Link, Text } from './AuthUIElements'
import EmailVerificationForm from './EmailVerificationForm'

const MODES = { EmailVerification: null, Password: 'password' }
const LoginForm = ({
    onSuccess,
    onRejected,
    email,
    onLogIn,
    // eslint-disable-next-line unused-imports/no-unused-vars
    showRegisterLink = false,
    invitation,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useUrlParam('mode', '')
    const [state, setState] = useState({ email: email || invitation?.user.email })
    const [error, setError] = useState(false)

    useEffect(
        () => setState((state) => ({ ...state, email: email || invitation?.user.email })),
        [email, invitation]
    )

    const getRedirect = () => {
        let redirect = null
        const searchString = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        if (searchString && searchString.redirect) {
            redirect = decodeURI(searchString.redirect)
        }

        return isUrl(redirect) && redirect
    }

    const getRedirectQuery = () => {
        let query = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
        })
        if (query) {
            // drop these params as they aren't needed past the start
            // off onboarding
            delete query.email
            delete query.status
            delete query.mode

            query = queryString.stringify(query)
        }

        return query
    }

    const handleLogin = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setError(false)
        // If we have a redirect in the url, request it

        analytics.track('user login submitted', {
            event_category: 'user',
            event_description: 'User login was started',
            authentication_method: 'email',
        })

        const redirect = getRedirect()

        onLogIn(state.email, state.password, redirect)
            .then((response) => {
                analytics.track('user login completed', {
                    event_category: 'user',
                    event_description: 'User login was completed',
                    authentication_method: 'email',
                })

                onSuccess(response)
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setError(true)
                onRejected(ex, state.email)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleChange = (e) => {
        const field = e.target.id
        const value = e.target.value

        setState((state) => ({ ...state, [field]: value }))
    }

    const toggleModeClick = (e) => {
        e.preventDefault()
        setMode(mode === MODES.Password ? MODES.EmailVerification : MODES.Password)
    }
    return (
        <>
            {mode !== MODES.Password && !invitation && (
                <Flex column align="stretch">
                    <EmailVerificationForm
                        onSuccess={onSuccess}
                        email={state.email}
                        onEmailChange={(email) => setState((state) => ({ ...state, email: email }))}
                    />
                    <Link mt={3} onClick={toggleModeClick}>
                        Login with password
                    </Link>
                </Flex>
            )}

            <Collapse isOpen={mode === MODES.Password || !!invitation}>
                <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Input
                        variant="authentication"
                        id="email"
                        type="email"
                        placeholder="enter email"
                        width="100%"
                        mb={1}
                        value={state.email}
                        onChange={handleChange}
                        isDisabled={invitation}
                        aria-label="Email"
                    />
                    <Input
                        variant="authentication"
                        id="password"
                        type="password"
                        placeholder="enter password"
                        width="100%"
                        aria-label="Password"
                        mb={1}
                        value={state.password}
                        onChange={handleChange}
                    />
                    <Collapse isOpen={error}>
                        <Text variant="error" mb={1}>
                            Sorry, that information is not correct.
                        </Text>
                    </Collapse>
                    <Button
                        width="100%"
                        buttonSize="sm"
                        mt={1}
                        mb={4}
                        isLoading={isLoading}
                        type="submit"
                    >
                        Sign in
                    </Button>

                    <Link
                        my={2}
                        to={`${buildUrl(getUrl(Urls.ResetPassword), {
                            email: state.email || '',
                        })}&${getRedirectQuery()}`}
                        style={{ alignSelf: 'center' }}
                    >
                        Forgot your password?
                    </Link>
                    {!invitation && (
                        <Link mt={2} onClick={toggleModeClick}>
                            Login without password
                        </Link>
                    )}
                </form>
            </Collapse>

            {/* Hidden for now as we don't want to expose register page */}
            {/*{!invitation && showRegisterLink && (*/}
            {/*    <Link mt={1} to={`${getUrl(Urls.Register)}?${getRedirectQuery()}`}>*/}
            {/*        Create an account*/}
            {/*    </Link>*/}
            {/*)}*/}
        </>
    )
}
export default withRouter(LoginForm)
