import React, { useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { AppContext } from 'app/AppContext'
import AppUserContext from 'app/AppUserContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidatePages } from 'data/hooks/pages'
import { createNewStackData, useCreateStack } from 'data/hooks/stacks'
import { invalidateViews } from 'data/hooks/views'
import ColorPickerForm, { defaultColor } from 'features/workspace/forms/ColorPicker'
import IconPickerForm from 'features/workspace/forms/IconPicker'
import analytics from 'utils/analytics'

import { Flex, Modal } from 'v2/ui'

import Form from 'ui/deprecated/forms/Form'
import InputForm from 'ui/deprecated/forms/InputForm'
import SubmitButton from 'ui/deprecated/forms/SubmitButton'

// BE truncates name above this length (stack name is used in pg identifiers so needs to be short)
const MAX_STACK_NAME_LENGTH = 25

const CreateAppModal = ({
    isOpen,
    onClose,
    onBeforeCreateStack = null,
    preselectedDataSourceType = null,
}) => {
    const { mutateAsync: createStack, isError } = useCreateStack()
    const history = useHistory()
    const { workspaceAccount, workspaceZone } = useContext(AppContext)
    const { user } = useAuthContext()
    const { isPreviewingAsUserOrRole } = useContext(AppUserContext)
    const createStackFormRef = useRef()

    useEffect(() => {
        if (isError) {
            createStackFormRef.current.setError('name', {
                type: 'manual',
                message: 'An unknown error occurred.',
            })
        }
    }, [isError])
    const handleSave = ({ appName, brandColor, logo, icon }) => {
        if (!brandColor) {
            brandColor = workspaceAccount.options?.theme?.brandColor || defaultColor
        }
        const data = createNewStackData(
            workspaceAccount._sid,
            workspaceZone._sid,
            user._sid,
            appName,
            icon,
            logo,
            brandColor
        )

        onBeforeCreateStack?.(data)

        analytics.track('app created submitted', {
            workspace_id: workspaceAccount._sid,
            is_impersonating: isPreviewingAsUserOrRole,
            user_id: user?._sid,
            event_description: 'New app created submitted',
            event_category: 'app',
        })

        return createStack(data).then((stack) => {
            history.push(getAfterCreateRedirect(stack, preselectedDataSourceType))

            // Invalidate nav related items when creating a new stack, as the new stack will come with some of these
            // for the Stacker Users table.
            invalidateViews()
            invalidatePages()
            invalidateNavigation()
            onClose()
        })
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Create a new app"
            size={[400, null, null, null]}
            headerVariant="largeCentered"
        >
            <Form
                ref={createStackFormRef}
                options={{
                    mode: 'onSubmit',
                    defaultValues: {
                        icon: 'faBook',
                        brandColor: workspaceAccount.options?.theme?.brandColor || defaultColor,
                    },
                }}
                onSubmit={handleSave}
            >
                <Flex
                    column
                    align="stretch"
                    wrap="nowrap"
                    overflowY="auto"
                    maxHeight={['90%', null, null, 600]}
                >
                    <InputForm
                        autoFocus
                        name="appName"
                        label="App name"
                        maxLength={MAX_STACK_NAME_LENGTH}
                        errorMessages={{ required: 'Please enter a name for your app' }}
                        required
                        variant="admin_white"
                        data-testid="create-new-app.app-name"
                    />

                    <Flex>
                        <IconPickerForm name="icon" label="App Icon" />
                        <div style={{ width: 15 }} />
                        <ColorPickerForm
                            name="brandColor"
                            label="Color"
                            defaultColor={workspaceAccount.options?.theme?.brandColor}
                        />
                    </Flex>
                    <div style={{ height: 15 }} />
                    <SubmitButton
                        disabled={false}
                        aria-label="Submit and create the app"
                        data-testid="create-new-app.create-app"
                    >
                        Create
                    </SubmitButton>
                </Flex>
            </Form>
        </Modal>
    )
}

export function getAfterCreateRedirect(stack, preselectedDataSourceType) {
    let url = getUrl(Urls.Home, stack)

    // if there is a preselected data source type set,
    // no need to offer the choice to the user on the splash screen,
    // just go ahead and show the correct add data source modal by adding the 'data' query param
    if (preselectedDataSourceType) {
        url += `${url.indexOf('?') > -1 ? '&' : '?'}data=${preselectedDataSourceType}`
    }

    return url
}

export default CreateAppModal
