import React from 'react'
import { createPortal } from 'react-dom'

import styled from '@emotion/styled'
import classNames from 'classnames'
import { ThemeProvider } from 'emotion-theming'

import AddBlockDraggable from 'features/pages/editor/dnd/AddBlockDraggable'
import BlockDraggable from 'features/pages/editor/ui/components/BlockDraggable'
import smallDarkTheme from 'features/pages/editor/ui/components/smallDarkTheme'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { CollapsableSection } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, ConditionalWrapper, Flex, Icon, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { BlocksList } from './BlocksList'

export class AddBlockPaneLight extends React.PureComponent {
    state = { search: null }

    filter = (search) => {
        if (!search) this.setState({ search: '' })
        this.setState({ search })
    }

    getOnboardingClassName = (blockId) => {
        if (blockId === 'template_field_highlights')
            return ONBOARDING_CLASSES.EDIT_LAYOUT_DRAG_AND_DROP_HIGHLIGHTS
        if (blockId === 'template_field_container')
            return ONBOARDING_CLASSES.EDIT_LAYOUT_DRAG_AND_DROP_FIELDS

        return ''
    }

    renderBlocksFromList = (blockList) => {
        let sections = blockList.filter((section) => section.hide !== true)

        return sections.map(({ name, icon, blocks, viewType }, index) => (
            <>
                <ConditionalWrapper
                    condition={viewType === 'blankpage'}
                    wrapper={(children) => (
                        <Box
                            key={name}
                            borderStyle="solid"
                            borderX="0"
                            borderColor="userInterface.neutral.400"
                            borderTopWidth="1px"
                            borderBottomWidth={index === sections.length - 1 ? '1px' : 0}
                            mt={index === 0 ? 3 : 0}
                        >
                            <CollapsableSection
                                defaultIsOpen
                                collapseProps={{
                                    mb: 4,
                                }}
                                header={
                                    <Flex columns py={2}>
                                        <Icon icon={icon} />
                                        <Text variant="adminFieldLabel" ml={2}>
                                            {name}
                                        </Text>
                                    </Flex>
                                }
                                Toggle={({ isOpen }) => {
                                    return (
                                        <Icon
                                            icon={isOpen ? 'arrowUp' : 'arrowDown'}
                                            size="sm"
                                            color="grey.300"
                                            pr={1}
                                        />
                                    )
                                }}
                            >
                                {children}
                            </CollapsableSection>
                        </Box>
                    )}
                >
                    <Box>
                        {blocks
                            .filter((block) => !block.deprecated)
                            .filter(
                                ({ label }) =>
                                    !this.state.search ||
                                    label.toLowerCase().indexOf(this.state.search.toLowerCase()) !==
                                        -1
                            )
                            .map(
                                ({
                                    label,
                                    icon,
                                    iconStyle,
                                    blockElement,
                                    blockTemplate,
                                    id,
                                    defaultConfig,
                                    description,
                                    isNew,
                                }) => (
                                    <AddBlockDraggable
                                        key={label}
                                        payload={{ id, blockElement, blockTemplate, defaultConfig }}
                                        onDragStart={this.props.hide}
                                        onDragEnd={this.props.close}
                                        onDrop={this.props.onDrop}
                                    >
                                        {() => (
                                            <div>
                                                <BlockDraggable
                                                    iconStyle={iconStyle}
                                                    label={label}
                                                    icon={icon}
                                                    isNew={isNew}
                                                    description={description}
                                                    className={classNames(
                                                        this.getOnboardingClassName(id),
                                                        ONBOARDING_CLASSES.EDIT_LAYOUT_DRAG_AND_DROP_ANY
                                                    )}
                                                    onClick={() =>
                                                        this.props.onClick({
                                                            payload: {
                                                                id,
                                                                blockElement,
                                                                blockTemplate,
                                                                defaultConfig,
                                                                blocks,
                                                            },
                                                            targetId: this.props.targetId,
                                                            targetPosition:
                                                                this.props.targetPosition,
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                    </AddBlockDraggable>
                                )
                            )}
                    </Box>
                </ConditionalWrapper>
            </>
        ))
    }

    render() {
        const children = (
            <div
                style={{
                    maxHeight: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {!this.props.hideTitle && (
                    <Text variant="paletteSectionLabel" pt={0}>
                        Add to layout
                    </Text>
                )}

                <ScrollingList>
                    <BlocksList>
                        {({ blocks }) => (
                            <ThemeProvider theme={smallDarkTheme}>
                                <>{this.renderBlocksFromList(this.props.blocks || blocks)}</>
                            </ThemeProvider>
                        )}
                    </BlocksList>
                </ScrollingList>
            </div>
        )

        // We get the portal dom element context now. The id selector is left in for backwards compatibility
        let element = document.getElementById('blockSelector')
        element = this.context ? this.context.blockSelector || element : element
        if (!element) {
            // We do this because race conditions can mean that the blockSelector isn't yet out on the DOM
            // There may be a more idiomatic way to do it, but if there is, I don't know what it is
            window.setTimeout(() => {
                if (this.updater) this.forceUpdate()
            }, 100)
            return null
        }
        return createPortal(children, element)
    }
}

AddBlockPaneLight.contextType = LayoutEditorContext

const ScrollingList = styled('div')`
    overflow: auto;
`
