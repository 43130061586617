import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'

export function useTableViewDataState() {
    const {
        isLoading,
        isFetchingSlow,
        records,
        hasFilters,
        hasError,
        clearFilters,
        retryFetchRecords,
        isEmbedded,
        header,
    } = useTableViewContext()

    const hasRecords = !!records?.length

    const isError = !hasRecords && hasError

    const isEmpty = !isLoading && !isFetchingSlow && !hasRecords && !hasFilters && !isError
    const isEmptyWithFilters =
        !isLoading && !isFetchingSlow && !hasRecords && hasFilters && !isError

    const showTable = (!isLoading && hasRecords) || isFetchingSlow

    const showTopPadding = header.type === 'portal' && header.style === 'full'

    return useMemo(
        () => ({
            isEmpty,
            isEmptyWithFilters,
            showTable,
            clearFilters,
            isError,
            retryFetchRecords,
            isFetchingSlow,
            isEmbedded,
            showTopPadding,
        }),
        [
            clearFilters,
            isEmpty,
            isEmptyWithFilters,
            showTable,
            isError,
            retryFetchRecords,
            isFetchingSlow,
            isEmbedded,
            showTopPadding,
        ]
    )
}
