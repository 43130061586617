import { useMemo } from 'react'
import { notifyManager, useQuery as _useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { SERVER_PAGE_SIZE } from 'app/settings'
import { recordApi } from 'data/api/recordApi'
import { RecordSearchQueryOptions } from 'data/api/recordApiTypes'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'
import { getCurrentUserRecordId } from 'data/wrappers/withUserUtils'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { getCachedRecord } from './records/getCachedRecord'
import { getRecordQueryFunction } from './records/getRecordQueryFunction'
import { RECORD_LIST_QUERY_CONFIG, RECORD_QUERY_CONFIG } from './records/recordConstants'
import { useUserRecord } from './users/main'
import { queryCache, queryClient } from './_helpers'
import { includeFieldsToRelatedFieldSuffix } from './recordQueryKeySuffix'

export type UseRecordsProps = {
    objectSid: string
    filters?: Filter[]
    fetchOptions?: RecordSearchQueryOptions
    options?: UseQueryOptions
    internalOptions?: {
        lookupObjects?: string[]
    }
    schemaTimestamp?: number
    disableRealtimeUpdates?: boolean
}

const getUseRecordsQueryKey = ({
    objectSid,
    filters,
    fetchOptions,
    schemaTimestamp,
}: {
    objectSid: string
    filters?: Filter[]
    fetchOptions?: RecordSearchQueryOptions
    schemaTimestamp?: number
}) => {
    const serializableFilters = filters?.map((filter) => ({ ...filter, _id: undefined }))
    return ['records', objectSid, serializableFilters, fetchOptions, schemaTimestamp]
}

/**
 * Fetches records from the server for a given object + filters.
 */
export function useRecords({
    objectSid,
    filters,
    fetchOptions,
    // until we are live resetting cache on notification from BE, we want to refetch on mount
    // otherwise the cache will go stale and never refresh
    options = { refetchOnMount: 'always' },
    internalOptions = {},
    schemaTimestamp,
    disableRealtimeUpdates,
}: UseRecordsProps): UseQueryResult<{ records: RecordDto[]; dereferencedRecords: RecordDto[] }> {
    const queryKey = getUseRecordsQueryKey({ objectSid, filters, fetchOptions, schemaTimestamp })

    const { selectedStack } = useAppContext()
    const objectsToWatch = useMemo(() => {
        return [...(internalOptions.lookupObjects || []), objectSid]
    }, [internalOptions.lookupObjects, objectSid])

    const invalidateQueriesWithTrigger = (invalidateKey: string | string[], trigger: string) => {
        const filters = {
            active: true,
            inactive: false,
            queryKey: invalidateKey,
        }

        // invalidate all queries and set the trigger if provided so we can later send that to the API
        // this is then used in metrics to track how many API requests are caused by realtime updates
        return notifyManager.batch(() => {
            queryCache.findAll(filters).forEach((query) => {
                query.setState({
                    _trigger: trigger,
                } as any)
                query.invalidate()
            })

            // If tab is not visible/active, we mark as stale but do not refetch
            // Stale queries when be refetched when window comes back into focus
            if (document.visibilityState === 'visible') {
                return queryClient.refetchQueries(filters)
            }
        })
    }

    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: objectsToWatch,
        handler: (eventName: string) => {
            invalidateQueriesWithTrigger(['records', objectSid], 'realtime_updates_' + eventName)
        },
        disabled: !!disableRealtimeUpdates,
    })

    // user record is required to properly transform user filters
    const { isFetched: isUserFetched } = useUserRecord()
    const currentUserRecordId = getCurrentUserRecordId()
    const queryOptions = {
        ...RECORD_LIST_QUERY_CONFIG,
        ...options,
        enabled: (!currentUserRecordId || isUserFetched) && options.enabled !== false,
        refetchOnWindowFocus: true,
    }

    return _useQuery(
        queryKey,
        (queryContext) => {
            const query = queryCache.find(queryContext.queryKey)

            return recordApi
                .getObjectRecords(objectSid, filters, {
                    ...fetchOptions,
                    trigger: (query?.state as any)._trigger,
                })
                .then((response) => {
                    // We also set the data for individual record queries. This means that we can still dereference
                    let records = response.records

                    const dereferencedRecords = records
                        .filter((record) => record._dereferenced)
                        .map((r) => ({ ...r, _dereferencedFrom: objectSid }))
                    // filter out dereferenced records
                    records = records.filter(
                        (record) => record._object_id === objectSid && !record._dereferenced
                    )

                    // If we're using manual sorting, then we
                    // populate the _local_display_order field with the actual index
                    // of the record in the list
                    if (fetchOptions?.manualOrderKey) {
                        records = records.map((record, idx) => ({
                            ...record,
                            _local_display_order: idx + 1,
                        }))
                    }

                    return {
                        records,
                        dereferencedRecords,
                    }
                })
                .finally(() => {
                    // consume the trigger so subsequent requests don't use the same trigger param
                    // we only want trigger to be sent once on the request immediately after invalidation
                    if ((query?.state as any)._trigger) {
                        query?.setState({ _trigger: null } as any)
                    }
                })
        },
        queryOptions as any
    )
}

export function useRecordCount({
    objectSid,
    filters,
    fetchOptions,
    // until we are live resetting cache on notification from BE, we want to refetch on mount
    // otherwise the cache will go stale and never refresh
    options = { refetchOnMount: 'always' },
}: Pick<
    UseRecordsProps,
    'objectSid' | 'filters' | 'fetchOptions' | 'options'
>): UseQueryResult<number> {
    const serializableFilters = filters?.map((filter) => ({ ...filter, _id: undefined }))
    const queryKey = ['recordCounts', objectSid, serializableFilters, fetchOptions]

    const { selectedStack } = useAppContext()

    const invalidateQueriesWithTrigger = (invalidateKey: string | string[], trigger: string) => {
        const filters = {
            active: true,
            inactive: false,
            queryKey: invalidateKey,
        }

        // invalidate all queries and set the trigger if provided so we can later send that to the API
        // this is then used in metrics to track how many API requests are caused by realtime updates
        return notifyManager.batch(() => {
            queryCache.findAll(filters).forEach((query) => {
                query.setState({
                    _trigger: trigger,
                } as any)
                query.invalidate()
            })

            // If tab is not visible/active, we mark as stale but do not refetch
            // Stale queries when be refetched when window comes back into focus
            if (document.visibilityState === 'visible') {
                return queryClient.refetchQueries(filters)
            }
        })
    }

    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [objectSid],
        handler: (eventName: string) => {
            invalidateQueriesWithTrigger(
                ['recordCounts', objectSid],
                'realtime_updates_' + eventName
            )
        },
        disabled: true,
    })

    // user record is required to properly transform user filters
    const { isFetched: isUserFetched } = useUserRecord()
    const currentUserRecordId = getCurrentUserRecordId()
    const queryOptions = {
        ...RECORD_LIST_QUERY_CONFIG,
        ...options,
        enabled: (!currentUserRecordId || isUserFetched) && options.enabled !== false,
        refetchOnWindowFocus: true,
    }

    return _useQuery(
        queryKey,
        (queryContext) => {
            const query = queryCache.find(queryContext.queryKey)

            return recordApi
                .getRecordCount(objectSid, filters, {
                    ...fetchOptions,
                    trigger: (query?.state as any)._trigger,
                })
                .finally(() => {
                    // consume the trigger so subsequent requests don't use the same trigger param
                    // we only want trigger to be sent once on the request immediately after invalidation
                    if ((query?.state as any)._trigger) {
                        query?.setState({ _trigger: null } as any)
                    }
                })
        },
        queryOptions as any
    )
}

/**
 * Fetches the specified record Ids from the server. Breaks the request up into separate
 * queries as needed to fit within SERVER_PAGE_SIZE
 */
export function useRecordsByIds({
    objectSid,
    recordIds,
    filters,
    fetchOptions,
    options,
}: {
    objectSid: string
    recordIds: string[]
    filters?: Filter[]
    fetchOptions?: RecordSearchQueryOptions
    options?: UseQueryOptions
}) {
    const filtersForQueries: Filter[][] = []
    let values = recordIds
    while (values.length > 0) {
        let idsToFetch = values.slice(0, SERVER_PAGE_SIZE)
        const queryFilters = [
            ...(filters || []),
            {
                field: {
                    api_name: '_sid',
                },
                options: {
                    value: idsToFetch,
                    option: 'oneOf',
                    operator: 'AND',
                },
            } as Filter,
        ]
        filtersForQueries.push(queryFilters)
        // chunk by page size just in case there are lots of values selected
        values = values.slice(SERVER_PAGE_SIZE)
    }

    const query_options = { ...RECORD_LIST_QUERY_CONFIG, ...options }
    const serializableFilters = filters?.map((filter) => ({ ...filter, _id: undefined }))
    return _useQuery(
        ['records', objectSid, recordIds, serializableFilters, fetchOptions],
        () => {
            const promises = filtersForQueries.map((filters) =>
                recordApi.getObjectRecords(objectSid, filters, fetchOptions).then((response) => {
                    let records = response.records
                    // filter out dereferenced records
                    records = records.filter(
                        (record) => record._object_id === objectSid && !record._dereferenced
                    )
                    return records
                })
            )
            // wait for all queries to return, then combine the results and return
            return Promise.all(promises).then((values) => {
                return { records: values.reduce((combined, value) => [...combined, ...value], []) }
            })
        },
        query_options as any
    )
}

/**
 * Fetches a record from the server
 */
export function useGetRecord({
    recordId,
    includeFields,
    options = {},
    useQueryOptions,
}: {
    recordId: string
    includeFields: string[] | undefined
    options?: RecordSearchQueryOptions
    useQueryOptions?: UseQueryOptions
}) {
    const requiredFieldSuffix = includeFieldsToRelatedFieldSuffix(includeFields)
    const queryKey = ['record', recordId, requiredFieldSuffix]

    return _useQuery(
        queryKey,
        () => {
            if (!recordId) return Promise.resolve(undefined)
            return getRecordQueryFunction(recordId, includeFields, options)
        },
        { ...RECORD_QUERY_CONFIG, ...useQueryOptions } as any
    )
}

/**
 * Get a list of partial linked records for a field
 * @param {any} field
 * @param {string} parentRecordId
 * @param {string | array} parentRecordId
 */
export function useGetFieldRecords({
    field,
    parentRecordId,
    value,
    dereferencedRecords = [],
}: {
    field?: FieldDto
    value?: string | string[]
    parentRecordId?: string
    dereferencedRecords?: RecordDto[]
}) {
    // We do an api fetch for all records in the field, this means that we won't be doing lots of
    // requests to get each individual record
    let invalid = false
    if (!value || !field) invalid = true
    const recordFieldFilter = useMemo((): Filter[] => {
        if (field?.type == 'multi_lookup') {
            if (parentRecordId) {
                return [
                    {
                        field: { api_name: '_sid' }, // A fake field object for the SID "field".
                        options: {
                            value: `${parentRecordId}___${field.api_name}`,
                            option: 'appearsIn',
                            operator: 'AND',
                        },
                    },
                ]
            }

            return [
                {
                    field: {
                        api_name: '_sid',
                    },
                    options: {
                        value,
                        option: 'oneOf',
                        operator: 'AND',
                    },
                },
            ]
        } else {
            return [
                {
                    field: { api_name: '_sid' },
                    options: {
                        value: value,
                        option: 'equals',
                        operator: 'AND',
                    },
                },
            ]
        }
    }, [value, parentRecordId, field?.api_name, field?.type])

    const fetchOptions = { includeFields: ['_primary'] }

    const recordValues = Array.isArray(value) ? value : [value]
    const recordValuesSet = new Set(recordValues)
    const previousRecordValuesSet = usePrevious(recordValuesSet)

    let cachedRecords: RecordDto[] = []
    recordValues.forEach((recId) => {
        // If we have dereferenced records, then check there first
        let cachedRecord = dereferencedRecords.find((r) => r._sid === recId)
        // Check the record query cache otherwise
        if (!cachedRecord) {
            getCachedRecord(recId ?? '')
        }
        if (cachedRecord) {
            cachedRecords.push(cachedRecord)
        }
    })

    const haveCachedRecords = cachedRecords.length === recordValues.length

    const options = useMemo(() => {
        return { enabled: !invalid && !haveCachedRecords }
    }, [invalid, haveCachedRecords])

    const useRecordsParams = {
        objectSid: field?.link_target_object_id ?? '',
        filters: recordFieldFilter,
        fetchOptions,
        options,
    }

    const {
        isLoading,
        data: { records = [] } = {},
        isError,
        isSuccess,
    } = useRecords(useRecordsParams)

    // Check if we already have the cached version first
    if (haveCachedRecords) {
        return { isLoading: false, records: cachedRecords }
    }

    // If the new list of record SIDs is different to the previous one and not all the records
    // are present in the result we got from useRecords, invalidate it so that they get refetched.
    const recordsInRecordValues = records.filter((record) => recordValues.includes(record._sid))
    if (
        recordsInRecordValues.length !== recordValues.length &&
        !areSetsEqual(recordValuesSet, previousRecordValuesSet ?? new Set())
    ) {
        queryClient.invalidateQueries(getUseRecordsQueryKey(useRecordsParams))
    }

    return { isLoading: isLoading || (!isSuccess && !isError && options?.enabled), records }
}

const areSetsEqual = <T>(a: Set<T>, b: Set<T>): boolean =>
    a.size === b.size && [...a].every((value) => b.has(value))
