// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import useWindowSize from 'v2/views/utils/useWindowSize'

import { useAppContext } from 'app/AppContext'
import { useLDFlags } from 'data/hooks/useLDFlags'
import { withDataConnections } from 'data/wrappers/WithDataConnections'

import { SidebarItem } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

/**
 * list of sidebar items
 * @type {{page: WorkspaceSettingsPage, name: string, icon: string, Component?: React.ReactElement, disabled?: (stack: any, workspaceAccount: any) => boolean }[]}
 */
export const appSettingsMenuItems = [
    {
        name: 'Navigation',
        page: 'navigation',
        icon: 'list',
    },
    {
        name: 'Permissions',
        page: 'permissions',
        icon: 'lock',
        class: ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_MENU_ITEM,
    },
    {
        name: 'General',
        page: 'general',
        icon: 'cog',
    },
    {
        name: 'Roles',
        page: 'roles',
        icon: 'userLock',
    },
    {
        name: 'Appearance',
        page: 'appearance',
        icon: 'brush',
        // only show appearance option if feature flag is enabled (we want to hide this section by default for now but
        // this feature flag can be used to show it to allow us to access setting custom css feature)
        disabled: (_stack, _workspaceAccount, ldflags) => {
            return ldflags?.showAppearanceSettings === false
        },
    },
]

const BORDER_COLOR = V4DesignSystem.colors.gray[100]

const fadeInAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const slideInLeftAnimation = keyframes`
    0% {
        transform: translateX(-250px)
    }
    100% {
        transform: translateX(0px)
    }
`

const Container = styled(Flex)`
    position: relative;

    & > div {
        position: relative;
    }

    .fadeIn {
        animation: ${fadeInAnimation} 0.2s linear;
    }

    ${
        '' /* .slideInLeft {
        animation: ${slideInLeftAnimation} 0.2s ease-out;
    } */
    }
`

type SecondaryMenuItems = {
    name: string
    section: string
    icon: string
    shouldShow?: (
        connectionType: string | undefined,
        object: ObjectDto,
        stack?: StackDto | null,
        account?: Account | null,
        ldflags?: LDFlagSet
    ) => boolean
}
/**
 * list of sidebar items
 */
const dataSourcesSecondaryMenuItems: SecondaryMenuItems[] = [
    {
        name: 'Data',
        section: 'data',
        icon: 'table',
        shouldShow: (connectionType, object, stack, account, ldflags) => ldflags?.dataGridEnabled,
    },
    {
        name: 'Fields',
        section: 'fields',
        icon: 'table',
    },
    {
        name: 'Settings',
        section: 'settings',
        icon: 'cog',
        shouldShow: (connectionType) => connectionType !== 'stacker_users',
    },
    {
        name: 'Permissions',
        section: 'permissions',
        icon: 'lock',
    },
    {
        name: 'Layouts',
        section: 'layouts',
        icon: 'columns',
    },
    {
        name: 'Actions',
        section: 'actions',
        icon: 'bolt',
    },
]

export const getAvailableSecondarySources = (
    connectionType: string | undefined,
    object: ObjectDto,
    stack?: StackDto | null,
    workspaceAccount?: Account | null,
    ldflags?: LDFlagSet
): SecondaryMenuItems[] => {
    return dataSourcesSecondaryMenuItems.filter((item) =>
        item.shouldShow
            ? item.shouldShow(connectionType, object, stack, workspaceAccount, ldflags)
            : true
    )
}

function DataSourcesSecondaryMenu({ dataSourcesMenuState, dataSourcesMenuStateFns }) {
    return (
        // @ts-expect-error
        <Flex
            column
            className="slideInLeft"
            style={{
                width: 160,
                borderRight: `1px solid ${BORDER_COLOR}`,
                overflowY: 'auto',
                padding: '20px 10px',
                flexShrink: 0,
                zIndex: 1,
            }}
        >
            {dataSourcesMenuState.secondaryMenuSectionState.available.map((menuItem) => (
                <>
                    <SidebarItem
                        key={menuItem.section}
                        icon={menuItem.icon}
                        active={
                            dataSourcesMenuState.secondaryMenuSectionState.selected ===
                            menuItem.section
                        }
                        onChange={() => {
                            dataSourcesMenuStateFns.setSelectedSecondaryMenuSection(
                                dataSourcesMenuState.selectedObject,
                                menuItem.section
                            )
                        }}
                        classes={
                            menuItem.section === 'permissions'
                                ? ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.PERMISSIONS_ITEM
                                : ''
                        }
                    >
                        {menuItem.name}
                    </SidebarItem>
                    <div style={{ height: 1 }} />
                </>
            ))}
        </Flex>
    )
}

const cleanDataSourcesMenuState = (
    state: { object?: any; dataConnection?: any; section?: any } = {},
    stack?: StackDto | null,
    workspaceAccount?: Account | null,
    ldflags?: LDFlagSet
) => ({
    selectedDataConnection: state.dataConnection,
    selectedObject: state.object,
    secondaryMenuSectionState: {
        selected: state.section,
        available: getAvailableSecondarySources(
            state.dataConnection?.type,
            state.object,
            stack,
            workspaceAccount,
            ldflags
        ),
    },
})

/**
 *
 * @param {{onChangePage: (page: AppSettingsPage) => Promise<any>, [keyof: string]: any}} param0
 * @returns
 */
function _Sidebar({ onChangePage, pageName, dataConnections, pageOptions = undefined }) {
    const { workspaceAccount, selectedStack } = useAppContext()
    const { flags: ldflags } = useLDFlags()
    const { width: windowWidth } = useWindowSize()

    const [dataSourcesMenuState, _setDataSourcesMenuState] = useState(
        cleanDataSourcesMenuState(pageOptions, selectedStack, workspaceAccount, ldflags)
    )

    // when page options change, we need to update our menu state
    // so the correct nav items are selected
    useEffect(() => {
        _setDataSourcesMenuState(
            cleanDataSourcesMenuState(pageOptions, selectedStack, workspaceAccount, ldflags)
        )
    }, [pageOptions, workspaceAccount, selectedStack, ldflags])

    const dataSourcesMenuStateFns = {
        resetState() {
            _setDataSourcesMenuState(
                cleanDataSourcesMenuState(undefined, selectedStack, workspaceAccount, ldflags)
            )
        },
        setSelectedSecondaryMenuSection(object, selectedSecondaryMenuSection) {
            const dataConnection = dataConnections.find((dc) => dc._sid === object.data_connection)
            onChangePage({
                name: 'object',
                meta: {
                    dataConnection,
                    object,
                    section: selectedSecondaryMenuSection,
                },
            }).then(() => {
                _setDataSourcesMenuState((state) => ({ ...state, selectedSecondaryMenuSection }))
            })
        },
    }

    return (
        // @ts-expect-error
        <Container>
            {/* @ts-expect-error */}
            <Flex
                column
                className="first-col"
                style={{
                    width: (windowWidth ?? 0) < 1366 ? 180 : 220,
                    borderRight: `1px solid ${BORDER_COLOR}`,
                    flexShrink: 0,
                    zIndex: 2,
                    background: 'white',
                    borderBottomLeftRadius: '0.375rem',
                    borderTopLeftRadius: '5px',
                }}
            >
                <div
                    style={{
                        padding: 16,
                        paddingTop: 12,
                        borderTop: 0,
                    }}
                >
                    {appSettingsMenuItems
                        .filter((menuItem) => {
                            if (typeof menuItem.disabled === 'function') {
                                return !menuItem.disabled(selectedStack, workspaceAccount, ldflags)
                            }
                            return true
                        })
                        .map((menuItem, index) => (
                            <React.Fragment key={index}>
                                <SidebarItem
                                    key={menuItem.page}
                                    icon={menuItem.icon}
                                    active={
                                        !dataSourcesMenuState.selectedObject &&
                                        pageName === menuItem.page
                                    }
                                    onChange={() => {
                                        // reset state in data sources menu when selecting a new top level page
                                        dataSourcesMenuStateFns.resetState()

                                        onChangePage({
                                            name: menuItem.page,
                                        })
                                    }}
                                    classes={menuItem.class || ''}
                                >
                                    {menuItem.name}
                                </SidebarItem>
                                <div style={{ height: 1 }} />
                            </React.Fragment>
                        ))}
                </div>
            </Flex>

            {!!dataSourcesMenuState.selectedObject && !ldflags?.dataGridSidePane && (
                <DataSourcesSecondaryMenu
                    dataSourcesMenuState={dataSourcesMenuState}
                    dataSourcesMenuStateFns={dataSourcesMenuStateFns}
                />
            )}
        </Container>
    )
}

export const Sidebar = withDataConnections(_Sidebar)
