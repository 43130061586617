import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import * as Parts from './Select.parts'
import { useSelectContext } from './SelectContext'
import { SelectOptionItem } from './types'

import { SelectAvatarStyle, SelectErrorIconStyle, SelectIconStyle } from './Select.css'

type IconProps = IconNameVariantType
type AvatarProps = React.ComponentPropsWithoutRef<typeof Avatar>

type SelectTriggerRef = HTMLButtonElement

type SelectTriggerProps = React.ComponentPropsWithoutRef<typeof Parts.Trigger> & {
    placeholder?: string
    isError?: boolean
    readOnly?: boolean
    startIcon?: IconProps
    isClearable?: boolean
    startAvatar?: AvatarProps
}

export const SelectTrigger = forwardRef<SelectTriggerRef, SelectTriggerProps>(
    (
        {
            placeholder,
            disabled,
            isError,
            readOnly,
            startIcon,
            size = 'm',
            isClearable,
            startAvatar,
            ...props
        },
        ref
    ) => {
        const { value, options, clearValue, multiSelect, setOptionState } = useSelectContext()

        const hasValue = value.length > 0 && value[0] !== ''

        const triggerRef = useRef<HTMLButtonElement>(null)

        const handleRemoveOption = useCallback(
            (value: string) => {
                setOptionState(value, false)
            },
            [setOptionState]
        )

        return (
            <Parts.Trigger
                ref={composeRefs(triggerRef, ref)}
                aria-invalid={isError || undefined}
                aria-readonly={readOnly || undefined}
                aria-disabled={disabled || undefined}
                disabled={readOnly || disabled}
                size={size}
                onPointerDown={(e) => {
                    const trigger = triggerRef.current
                    if (!trigger) return

                    const target = e.target as HTMLElement
                    const closestInteractive = target.closest('button, a, input, [role="button"]')

                    // Allow interacting with content in the right slot.
                    if (closestInteractive && closestInteractive !== trigger) {
                        e.stopPropagation()
                        e.preventDefault()
                        return
                    }
                }}
                {...props}
            >
                {startIcon && (
                    <Icon
                        aria-hidden={true}
                        size="m"
                        className={SelectIconStyle}
                        {...startIcon}
                        color={disabled ? 'iconDisabled' : 'icon'}
                    />
                )}
                {startAvatar && (
                    <Avatar
                        size="xs"
                        shape="circle"
                        {...(startAvatar as any)}
                        className={SelectAvatarStyle}
                    />
                )}
                {placeholder && !hasValue && (
                    <Parts.Placeholder size={size} isDisabled={disabled}>
                        {placeholder}
                    </Parts.Placeholder>
                )}
                {hasValue && (
                    <Parts.ValueWrapper>
                        {multiSelect ? (
                            <MultiValue
                                value={value}
                                options={options}
                                disabled={disabled}
                                size={size}
                                onRemoveOption={handleRemoveOption}
                            />
                        ) : (
                            <SingleValue
                                value={value}
                                options={options}
                                disabled={disabled}
                                size={size}
                                onRemoveOption={handleRemoveOption}
                            />
                        )}
                    </Parts.ValueWrapper>
                )}
                <Parts.RightSlot>
                    {isError && (
                        <Icon
                            aria-hidden={true}
                            size="s"
                            name="AlertCircle"
                            color="iconError"
                            className={SelectErrorIconStyle}
                        />
                    )}
                    {isClearable && hasValue && (
                        <Parts.ClearButton
                            type="button"
                            aria-label="Clear"
                            disabled={disabled || readOnly}
                            onClick={clearValue}
                        >
                            <Icon aria-hidden={true} size="s" name="X" />
                        </Parts.ClearButton>
                    )}
                    <Parts.IndicatorIcon
                        aria-hidden={true}
                        size="m"
                        name="ChevronDown"
                        color={disabled || readOnly ? 'iconDisabled' : 'icon'}
                    />
                </Parts.RightSlot>
            </Parts.Trigger>
        )
    }
)

type ValueProps = {
    size: 'm' | 'l'
    value: string[]
    options: SelectOptionItem[]
    onRemoveOption: (value: string) => void
    disabled?: boolean
}

const SingleValue: React.FC<ValueProps> = ({ value, options, disabled, size }) => {
    const selectedOption = useMemo(() => {
        const selectedValue = value[0]
        if (!selectedValue) return undefined

        return options.find((o) => o.value === selectedValue)
    }, [options, value])

    if (!selectedOption) return null

    return (
        <Parts.SingleValue isDisabled={disabled}>
            <Body size={size} weight="medium">
                {selectedOption?.label}
            </Body>
        </Parts.SingleValue>
    )
}

const MultiValue: React.FC<ValueProps> = ({ value, options, disabled, onRemoveOption }) => {
    const selectedOptions = useMemo(() => {
        const selectedValues = new Set(value)

        return options.filter((o) => selectedValues.has(o.value))
    }, [options, value])

    if (selectedOptions.length < 1) return null

    return (
        <Box overflow="hidden">
            <Parts.MultiValue>
                {selectedOptions.map((option) => (
                    <Parts.MultiValueItem
                        key={option.value}
                        showRemoveButton
                        onRemove={() => {
                            onRemoveOption(option.value)
                        }}
                        shade="light"
                        size="xs"
                        color={option.color}
                        disabled={disabled}
                        tabIndex={-1}
                        onKeyDown={stopPropagation}
                    >
                        {option.label}
                    </Parts.MultiValueItem>
                ))}
            </Parts.MultiValue>
        </Box>
    )
}
