import { formatDate } from 'utils/dateUtils'

export function formatDueDate(value: string): string | undefined {
    const { dateValue } = formatDate(value, 'UTC', true)

    return dateValue?.local?.().format?.('ll')
}

export function getDueDateValue(value: string): string | undefined {
    const { dateValue } = formatDate(value, 'UTC', true)

    return dateValue?.local?.().format?.('YYYY-MM-DD')
}
