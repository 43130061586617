import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import {
    PreviewRecordListItem,
    usePreviewRecordContext,
} from 'v2/views/List/PreviewRecord/PreviewRecordContext'

import { getUrl } from 'app/UrlService'
import { ActionContextMenuHandle } from 'features/views/ListView/Actions/ActionContextMenu'
import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewFooterAttribute } from 'features/views/ListView/BoardView/types'
import { extractSrcFromCoverImageValue } from 'features/views/ListView/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useBoardViewTitleState } from './useBoardViewTitleState'

type UseBoardViewCardStateOptions = {
    record?: RecordDto
    siblingRecords?: RecordDto[]
    removeRecordNotMatchingFilters?: (recordSid: string) => void
    isNotMatchingFilters?: boolean
}

export function useBoardViewCardState(options: UseBoardViewCardStateOptions) {
    const { record, siblingRecords, removeRecordNotMatchingFilters, isNotMatchingFilters } = options

    const {
        object,
        stack,
        attributeStyle,
        labelStyle,
        statusField,
        pendingRecords,
        view,
        requestIncludedFields,
        allFields,
        coverImageField,
        eyebrowField,
        subtitleField,
        onRecordClick,
    } = useBoardViewContext()
    const { previewRecord } = usePreviewRecordContext()

    const recordDetailUrl = useMemo(() => {
        return getUrl(`${object?.url}/view/${record?._sid}`, stack)
    }, [object?.url, record?._sid, stack])
    const to = record ? recordDetailUrl : ''

    const openInNewTab = useCallback(() => {
        window.open(recordDetailUrl, '_blank')
    }, [recordDetailUrl])

    const history = useHistory()
    const openAsFullPage = useCallback(() => {
        history.push(recordDetailUrl)
    }, [history, recordDetailUrl])

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            // Only open side peek on regular left click.
            if (e.ctrlKey || e.metaKey || e.button === 1 || !record?._sid) return

            e.preventDefault()

            // Check for any record click overrides.
            switch (onRecordClick) {
                case 'none':
                    return
                case 'new_tab':
                    openInNewTab()
                    return
                case 'detail':
                    openAsFullPage()
                    return
            }

            const records = siblingRecords
            const recordListItems: PreviewRecordListItem[] | undefined = records?.map((r) => ({
                recordId: r._sid,
            }))

            previewRecord({
                recordId: record._sid,
                objectId: record._object_id,
                partOfRecordList: recordListItems
                    ? {
                          direction: 'vertical',
                          items: recordListItems,
                      }
                    : undefined,
            })

            if (isNotMatchingFilters) {
                setTimeout(() => {
                    removeRecordNotMatchingFilters?.(record._sid)
                }, 150)
            }
        },
        [
            isNotMatchingFilters,
            onRecordClick,
            openAsFullPage,
            openInNewTab,
            previewRecord,
            record?._object_id,
            record?._sid,
            removeRecordNotMatchingFilters,
            siblingRecords,
        ]
    )

    const { titleAttribute, contentAttributes, recordTitle } = useBoardViewTitleState({ record })

    const footerLeftAttribute = useMemo(() => {
        const leftFieldSid = view.options.boardCardFooter?.leftFieldSid
        const leftFieldDisplayOptions = view.options.boardCardFooter?.leftFieldDisplayOptions
        return getFooterAttribute(allFields, leftFieldSid, leftFieldDisplayOptions)
    }, [
        allFields,
        view.options.boardCardFooter?.leftFieldDisplayOptions,
        view.options.boardCardFooter?.leftFieldSid,
    ])

    const footerRightAttribute = useMemo(() => {
        const rightFieldSid = view.options.boardCardFooter?.rightFieldSid
        const rightFieldDisplayOptions = view.options.boardCardFooter?.rightFieldDisplayOptions
        return getFooterAttribute(allFields, rightFieldSid, rightFieldDisplayOptions)
    }, [
        allFields,
        view.options.boardCardFooter?.rightFieldDisplayOptions,
        view.options.boardCardFooter?.rightFieldSid,
    ])

    const actions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: true,
    })
    const actionsMemo = useDeepEqualsMemoValue(actions.map((a) => a.action))
    const actionsRef = useRef(actionsMemo)
    actionsRef.current = actionsMemo

    const cardRef = useRef<HTMLDivElement>(null)

    const actionContextMenuRef = useRef<ActionContextMenuHandle>(null)

    const onContextMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const card = cardRef.current
        if (!card) return

        // Don't open the context menu if there are no actions enabled.
        const actions = actionsRef.current
        if (!actions.length) return

        const target = e.target as HTMLElement
        const closestInteractive = target.closest('a') as HTMLElement | null
        if (closestInteractive && closestInteractive !== card) {
            // Don't open the context menu if the click was on a link.
            return
        }

        e.preventDefault()
        e.stopPropagation()

        actionContextMenuRef?.current?.openAt(e.clientX, e.clientY)
    }, [])

    const additionalEditFields = useMemo(() => {
        const fields = new Map<string, FieldDto>()

        if (statusField) {
            fields.set(statusField._sid, statusField)
        }

        if (footerLeftAttribute && typeof footerLeftAttribute !== 'string') {
            const field = footerLeftAttribute.field
            fields.set(field._sid, field)
        }

        if (footerRightAttribute && typeof footerRightAttribute !== 'string') {
            const field = footerRightAttribute.field
            fields.set(field._sid, field)
        }

        if (eyebrowField) {
            fields.set(eyebrowField._sid, eyebrowField)
        }

        if (subtitleField) {
            fields.set(subtitleField._sid, subtitleField)
        }

        return Array.from(fields.values())
    }, [eyebrowField, footerLeftAttribute, footerRightAttribute, statusField, subtitleField])

    const isPending = record && pendingRecords.some((r) => r._sid === record._sid)

    const coverImageSrc = extractSrcFromCoverImageValue(record, coverImageField)

    // Remove temporary records when clicking anywhere on the board.
    useLayoutEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            if (isNotMatchingFilters && record?._sid) {
                const card = cardRef.current
                if (!card) return

                const target = e.target as HTMLElement
                const isCurrentCard = card.contains(target)
                // Allow clicks on the card itself.
                if (isCurrentCard) return

                removeRecordNotMatchingFilters?.(record._sid)
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)
        return () => document.removeEventListener('mousedown', handleOutsideClick)
    }, [isNotMatchingFilters, record?._sid, removeRecordNotMatchingFilters])

    return useMemo(
        () => ({
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            attributeStyle,
            labelStyle,
            cardRef,
            onContextMenu,
            actionContextMenuRef,
            additionalEditFields,
            isPending,
            actionButtons: actionsMemo,
            includeFields: requestIncludedFields,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
            recordTitle,
        }),
        [
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            attributeStyle,
            labelStyle,
            onContextMenu,
            additionalEditFields,
            isPending,
            actionsMemo,
            requestIncludedFields,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
            recordTitle,
        ]
    )
}

function getFooterAttribute(
    allFields: FieldDto[],
    fieldSid?: string,
    fieldDisplayOptions?: BoardCardFooterDisplayOptions
): BoardViewFooterAttribute | undefined {
    if (!fieldSid) return undefined

    if (fieldSid === '_record_stats') {
        return '_record_stats'
    }

    const field = allFields.find((f) => f._sid === fieldSid)
    if (!field) return undefined

    return {
        id: field._sid,
        title: field.label,
        field,
        rawOptions: fieldDisplayOptions ?? {},
        type: 'footer',
    }
}
