import { useCallback, useMemo, useState } from 'react'

import * as client from 'filestack-js'

import { useAppContext } from 'app/AppContext'
import { refetchRoles } from 'data/hooks/roles'
import { refetchStacks } from 'data/hooks/stacks/stackOperations'

import { useToast } from 'ui/components/Toast'

import { importUrl } from './bundlesApi'
async function uploadBlob(file: File) {
    const result = await client.init('Arfn2PRozQ26eRkdQy74Ez').upload(file)

    return result
}
export function useBundleDragAndDrop({
    stackOptions,
}: {
    stackOptions?: Partial<StackDto['options']>
}) {
    const [isFileDraggingOver, setIsFileDraggingOver] = useState(false)
    const { workspaceAccount } = useAppContext()
    const isEnabled = localStorage.getItem('support_login')
    const toast = useToast()

    const onDragOver = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (!isEnabled) return
            e.preventDefault()

            const file = e.dataTransfer.items[0]
            if (file && file.type === 'application/json') {
                setIsFileDraggingOver(true)
            }
        },
        [isEnabled]
    )
    const onDragLeave = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (!isEnabled) return
            e.preventDefault()
            setIsFileDraggingOver(false)
        },
        [isEnabled]
    )

    const doImport = useCallback(
        async (file: File) => {
            toast({ title: 'Bundle import started', type: 'default' })
            try {
                const result = await uploadBlob(file)

                const stack = await importUrl({
                    url: result.url,
                    account_id: workspaceAccount?._sid,
                    stackOptions,
                })
                toast({ title: 'Bundle import complete!', type: 'success' })
                console.log(stack)
            } catch (ex) {
                console.error(ex)
                toast({ title: 'Bundle import failed!', type: 'error' })
            }

            await Promise.all([refetchStacks(), refetchRoles()])
        },
        [stackOptions, toast, workspaceAccount?._sid]
    )
    const onDrop = useCallback(
        (e: React.DragEvent<HTMLDivElement>) => {
            if (!isEnabled) return
            e.preventDefault()
            setIsFileDraggingOver(false)
            const file = e.dataTransfer.files[0]
            if (file && file.type === 'application/json') {
                doImport(file)
            }
        },
        [doImport, isEnabled]
    )

    return useMemo(
        () => ({
            isFileDraggingOver,
            onDragOver,
            onDragLeave,
            onDrop,
        }),
        [isFileDraggingOver, onDragOver, onDragLeave, onDrop]
    )
}
