import { useMemo } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'

export function useLayoutEditorWidgetControlsState() {
    const { selectedWidget, schema, commands, onChangeSelectedWidgetAttrs } =
        useLayoutEditorContext()

    const widgetSchema = useMemo(() => {
        if (!selectedWidget?.type) return undefined
        return schema.widgets[selectedWidget.type]
    }, [schema.widgets, selectedWidget?.type])

    const Component = widgetSchema?.adminControlsComponent

    const label = widgetSchema?.label ?? 'Widget'

    return useMemo(
        () => ({
            Component,
            selectedWidget,
            onBackClick: commands.deselectWidget,
            onChangeWidgetAttrs: onChangeSelectedWidgetAttrs,
            label,
        }),
        [Component, selectedWidget, commands.deselectWidget, onChangeSelectedWidgetAttrs, label]
    )
}
