import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'

import { CellEditingStartedEvent, ColumnMovedEvent, SelectionChangedEvent } from 'ag-grid-community'
import { LicenseManager, ProcessCellForExportParams } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import settings from 'app/settings'
import PastingBanner from 'features/datagrid/components/PastingBanner'
import { useContextMenuItems } from 'features/datagrid/hooks/useContextMenuItems'
import { useDeleteSelectedRecords } from 'features/datagrid/hooks/useDeleteSelectedRecords'
import { useDisplayedFields } from 'features/datagrid/hooks/useDisplayedFields'
import { useGridColumns } from 'features/datagrid/hooks/useGridColumns'
import { useGridData } from 'features/datagrid/hooks/useGridData'
import { useGridKeyboardEvents } from 'features/datagrid/hooks/useGridKeyboardEvents'
import { useGridOverflowEffects } from 'features/datagrid/hooks/useGridOverflowEffects'
import { useHandleCellEditRequest } from 'features/datagrid/hooks/useHandleCellEditRequest'
import { useHandleFillOperation } from 'features/datagrid/hooks/useHandleFillOperation'
import { useHandlePaste } from 'features/datagrid/hooks/useHandlePaste'
import { useHandleSendToClipboard } from 'features/datagrid/hooks/useHandleSendToClipboard'
import { useIsAddNewFieldPanelVisible } from 'features/datagrid/hooks/useIsAddNewFieldPanelVisible'
import { useIsAddNewRowPanelVisible } from 'features/datagrid/hooks/useIsAddNewRowPanelVisible'
import { useProcessDataFromClipboard } from 'features/datagrid/hooks/useProcessDataFromClipboard'
import useRecordEditManager from 'features/datagrid/hooks/useRecordEditManager'
import { useRestoreSelectedRecords } from 'features/datagrid/hooks/useRestoreSelectedRecords'
import { AddNewFieldPanel } from 'features/datagrid/panels/AddNewFieldPanel'
import { AddNewFieldPanelStyle } from 'features/datagrid/panels/AddNewFieldPanel.css'
import { AddNewRecordPanel } from 'features/datagrid/panels/AddNewRecordPanel'
import { AddNewRecordPanelStyle } from 'features/datagrid/panels/AddNewRecordPanel.css'
import { PaginationPanel } from 'features/datagrid/panels/PaginationPanel'
import {
    DataGridEditorHandle,
    DataGridEditorProps,
    GridInternalContext,
    RowData,
} from 'features/datagrid/types'
import {
    getColumnIdsFromMovedEvent,
    getRowId,
    handleNavigateToNextCell,
    handleRangeSelectionChanged,
    handleRowSelected,
    isRowSelectable,
    processDataBeforeCopy,
    startEditingNewRecord,
} from 'features/datagrid/utils'

import { Box } from 'ui/components/Box'

import { DataGridContextProvider, useDataGridContext } from './DataGridContext'
import { ErrorMessage } from './ErrorMessage'

import 'ag-grid-enterprise/styles/ag-grid.css'
import {
    DataGridAddNewFieldPanelStyle,
    DataGridAddNewRecordPanelStyle,
    DataGridPaginationPanelStyle,
    DataGridStyle,
    DataGridTheme,
} from './DataGrid.css'

LicenseManager.setLicenseKey(settings.AG_GRID_KEY)

type DataGridProps = React.ComponentPropsWithoutRef<typeof Box> & DataGridEditorProps & {}

export const DataGrid: React.FC<DataGridProps> = React.forwardRef<
    DataGridEditorHandle,
    DataGridProps
>((props, ref) => {
    return (
        <DataGridContextProvider
            cellDataProvider={props.cellDataProvider}
            canAddRecords={!!props.addRecord}
            canDeleteRecords={!!props.deleteRecords}
            canEditRecords={!!props.updateRecord}
            canRestoreRecords={!!props.restoreRecords}
            isTrashMode={!!props.trashMode}
            pageIndex={props.pageIndex ?? 0}
            setPageIndex={props.setPageIndex ?? (() => {})}
            pageSize={props.pageSize ?? 0}
        >
            <DataGridInner {...props} ref={ref} />
        </DataGridContextProvider>
    )
})

const DataGridInner: React.FC<DataGridProps> = React.forwardRef<
    DataGridEditorHandle,
    DataGridProps
>(
    (
        {
            object,
            records,
            updateRecord,
            addRecord,
            deleteRecords,
            restoreRecords,
            setIsDirty,
            onRecordsSelected,
            refetchRecords,
            onColumnsOrderChanged,
            dataSourceSupportsPasting,
            dataSourceLabel,
            importRawRecords,
            orderBy,
            setOrderBy,
            trashMode,
            totalRecordCount,
            ...props
        },
        ref
    ) => {
        const gridRef = useRef<AgGridReact>(null)

        const { dispatch } = useDataGridContext()

        const {
            records: mergedRecords,
            editRecord,
            addRecord: doAddRecord,
            pendingDeletes,
            pendingEdits,
            failedRecords,
            retryFailures,
            pendingNewRecords,
        } = useRecordEditManager({
            records,
            updateRecord,
            addRecord,
            deleteRecords,
        })

        // Evaluate the isDirty state out here, rather than in the useEffect
        // because it appears that sometimes while the contents of these objects
        // may change, they are being mutated, not replaced.
        const isDirty =
            !isEmpty(pendingEdits) || !isEmpty(pendingDeletes) || !isEmpty(failedRecords)

        useEffect(() => {
            setIsDirty?.(isDirty)
        }, [isDirty, setIsDirty])

        const onFieldCreated = useCallback(
            async (field: FieldDto) => {
                const grid = gridRef.current
                if (!grid) return

                grid.api.ensureColumnVisible(field.api_name, 'start')
                refetchRecords?.()
            },
            [refetchRecords]
        )

        const addNewEmptyRecord = useCallback(() => {
            const grid = gridRef.current
            if (!grid) return

            dispatch({ type: 'showEmptyRecordRow' })
            grid.api.clearRangeSelection()

            // Enter edit mode on the first editable column.
            setTimeout(() => {
                startEditingNewRecord(grid.api)
            }, 50)
        }, [dispatch])

        const displayedFields = useDisplayedFields(object)
        const colDefs = useGridColumns({
            object,
            fields: displayedFields,
            orderBy,
            setOrderBy,
            refetchRecords,
            onFieldCreated,
            addNewEmptyRecord,
            trashMode,
        })

        const onCellEditRequest = useHandleCellEditRequest({
            addRecord: doAddRecord,
            editRecord,
            importRawRecords,
        })

        const onSelectionChanged = useCallback(
            (params: SelectionChangedEvent) => {
                const selectedRecords = params.api.getSelectedRows()
                onRecordsSelected?.(selectedRecords)
            },
            [onRecordsSelected]
        )

        const deleteSelectedRecords = useDeleteSelectedRecords(deleteRecords)
        const restoreSelectedRecords = useRestoreSelectedRecords(restoreRecords)

        useImperativeHandle<DataGridEditorHandle, DataGridEditorHandle>(ref, () => ({
            onFieldCreated,
            deleteSelectedRecords,
            restoreSelectedRecords,
        }))

        const onGridReady = useCallback(() => {
            dispatch({
                type: 'setGridReady',
                gridApi: gridRef.current!.api!,
            })
        }, [dispatch])

        const onGridPreDestroyed = useCallback(() => {
            dispatch({
                type: 'destroyGrid',
            })
        }, [dispatch])

        const onColumnMoved = useCallback(
            (params: ColumnMovedEvent) => {
                const columnApiNames = getColumnIdsFromMovedEvent(params)
                if (columnApiNames.length < 1) return

                onColumnsOrderChanged?.(columnApiNames)
            },
            [onColumnsOrderChanged]
        )

        const processCellForClipboard = useCallback((params: ProcessCellForExportParams) => {
            return processDataBeforeCopy(params)
        }, [])

        const { processDataFromClipboard, hidePastingIndicator, pastingIndicator } =
            useProcessDataFromClipboard({
                object,
                fields: displayedFields,
                records: mergedRecords,
                importRawRecords,
                dataSourceSupportsPasting,
                dataSourceLabel,
                pendingNewRecords,
                pendingDeletes,
                pendingEdits,
                failedRecords,
            })

        const onCellEditingStarted = useCallback(
            (params: CellEditingStartedEvent) => {
                dispatch({
                    type: 'openEditor',
                    editorOpenedWithEvent: params.event ?? undefined,
                })
            },
            [dispatch]
        )

        const onCellEditingStopped = useCallback(() => {
            dispatch({
                type: 'closeEditor',
            })
        }, [dispatch])

        const { getContextMenuItems } = useContextMenuItems()

        const gridInternalContext: GridInternalContext = useMemo(() => ({ object }), [object])

        const data = useGridData(mergedRecords, trashMode)

        useGridKeyboardEvents({ deleteRecords, addNewEmptyRecord })
        useHandlePaste({ fields: displayedFields, importRawRecords })

        const wrapperRef = useRef<HTMLDivElement>(null)
        useGridOverflowEffects(wrapperRef.current)

        const isAddNewRowPanelVisible = useIsAddNewRowPanelVisible(wrapperRef.current)
        const isAddNewFieldPanelVisible = useIsAddNewFieldPanelVisible(wrapperRef.current)

        const handleFillOperation = useHandleFillOperation({
            fields: displayedFields,
            object,
        })

        useEffect(() => {
            const grid = gridRef.current
            if (!grid) return

            return () => {
                // Make sure we stop editing when the component is unmounted, so we prevent crashes.
                grid.api?.stopEditing(true)
            }
        }, [])

        const handleSendToClipboard = useHandleSendToClipboard({ fields: displayedFields })

        return (
            <Box
                height="full"
                width="full"
                minWidth="100px"
                minHeight="100px"
                position="relative"
                background="surfaceStrongest"
                {...props}
                className={classNames(DataGridTheme, props.className)}
                ref={wrapperRef}
            >
                <AgGridReact<RowData>
                    ref={gridRef}
                    onGridReady={onGridReady}
                    onGridPreDestroyed={onGridPreDestroyed}
                    rowData={data}
                    columnDefs={colDefs}
                    rowSelection="multiple"
                    context={gridInternalContext}
                    getRowId={getRowId}
                    readOnlyEdit={true}
                    onCellEditRequest={onCellEditRequest}
                    rowMultiSelectWithClick={false}
                    suppressRowClickSelection={true}
                    rowHeight={32}
                    headerHeight={32}
                    enableRangeSelection={true}
                    enableFillHandle={true}
                    suppressMenuHide={true}
                    suppressRowHoverHighlight={true}
                    stopEditingWhenCellsLoseFocus={true}
                    animateRows={false}
                    getContextMenuItems={getContextMenuItems}
                    suppressNoRowsOverlay={true}
                    onSelectionChanged={onSelectionChanged}
                    isRowSelectable={isRowSelectable}
                    onColumnMoved={onColumnMoved}
                    processCellForClipboard={processCellForClipboard}
                    processDataFromClipboard={processDataFromClipboard}
                    onCellEditingStarted={onCellEditingStarted}
                    onCellEditingStopped={onCellEditingStopped}
                    onRowSelected={handleRowSelected}
                    onRangeSelectionChanged={handleRangeSelectionChanged}
                    enterNavigatesVertically={false}
                    enterNavigatesVerticallyAfterEdit={true}
                    fillOperation={handleFillOperation}
                    suppressClearOnFillReduction={true}
                    suppressMultiRangeSelection={true}
                    navigateToNextCell={handleNavigateToNextCell}
                    sendToClipboard={handleSendToClipboard}
                    suppressClipboardPaste={true}
                    rowBuffer={30}
                    className={DataGridStyle}
                />
                <AddNewFieldPanel
                    onFieldCreated={onFieldCreated}
                    context={gridInternalContext}
                    className={classNames(
                        DataGridAddNewFieldPanelStyle,
                        AddNewFieldPanelStyle({ isVisible: isAddNewFieldPanelVisible })
                    )}
                />
                <AddNewRecordPanel
                    totalRecordCount={totalRecordCount}
                    addNewEmptyRecord={addNewEmptyRecord}
                    className={classNames(
                        DataGridAddNewRecordPanelStyle,
                        AddNewRecordPanelStyle({
                            isVisible: isAddNewRowPanelVisible,
                        })
                    )}
                />
                <PaginationPanel
                    totalRecordCount={totalRecordCount}
                    isAddNewVisible={isAddNewRowPanelVisible}
                    className={DataGridPaginationPanelStyle}
                    addNewEmptyRecord={addNewEmptyRecord}
                />

                <PastingBanner
                    show={pastingIndicator.show}
                    text={pastingIndicator.text}
                    icon={pastingIndicator.icon}
                    variant={pastingIndicator.variant}
                    useTimer={pastingIndicator.useTimer}
                    onHide={hidePastingIndicator}
                    zIndex={10}
                />
                <ErrorMessage
                    failedRecords={failedRecords}
                    retryFailures={retryFailures}
                    zIndex={10}
                />
            </Box>
        )
    }
)
