import { ICstVisitor, IRecognitionException } from 'chevrotain'
import moment from 'moment'

import { ParsingError } from './formulaFunctionErrors'
import { FormulaLexer, parser } from './formulaParser'
import {
    StackerDateLiteral,
    StackerDatetimeFunction,
    StackerDatetimeLiteral,
    StackerDocumentFunction,
    StackerFieldFunction,
    StackerFormulaSyntaxTree,
    StackerLogFunction,
    StackerNAryFunction,
    StackerOperatorFunction,
    StackerPowerFunction,
    StackerRoundFunction,
    StackerSearchFindFunction,
    StackerStringLiteral,
    StackerStringValueToken,
    StackerUnaryStringFunction,
    StackerUnaryValueFunction,
} from './formulaParserTypes'
import { StackerFormulaVisitor } from './formulaParserVisitor'

export const parseStackerFormula = ({
    object,
    text,
}: {
    object?: ObjectDto
    text: string
}):
    | { errors: (string | IRecognitionException)[]; isValid: false; message?: string }
    | { isValid: true; output: StackerFormulaSyntaxTree } => {
    try {
        // Avoid passing a formula to the tokenizer if there are unclosed strings
        const unclosedStringError = getUnclosedStringError(text)
        if (unclosedStringError) {
            return {
                errors: [unclosedStringError],
                message: unclosedStringError,
                isValid: false,
            }
        }

        const lexingResult = FormulaLexer.tokenize(text)
        if (lexingResult.errors.length) {
            console.log('Lexing errors detected!\n' + lexingResult.errors[0].message)

            return {
                errors: ['Cannot parse'],
                isValid: false,
            }
        }

        parser.input = lexingResult.tokens

        // Our visitor has no state, so a single instance is sufficient.
        const toAstVisitorInstance: ICstVisitor<any, StackerFormulaSyntaxTree> =
            new StackerFormulaVisitor(object)

        // Automatic CST created when parsing
        const output: chevrotain.CstNode = (parser as any).formula()
        if (parser.errors.length > 0) {
            console.log('Parsing errors detected!\n' + parser.errors[0].message)
            return {
                errors: parser.errors,
                isValid: false,
            }
        }

        // Visit
        const ast = toAstVisitorInstance.visit(output)
        return {
            isValid: true,
            output: ast,
        }
    } catch (e) {
        if (e instanceof ParsingError) {
            return {
                errors: ['Cannot parse'],
                isValid: false,
                message: e.message,
            }
        }

        console.error(e)
        return {
            errors: ['Cannot parse'],
            isValid: false,
        }
    }
}

export const getUnclosedStringError = (text: string): string | undefined => {
    let currentStringOpener: { pos: number; char: string } | undefined = undefined
    let inFieldName = false
    for (let i = 0; i < text.length; i++) {
        const char = text.charAt(i)
        const isCurrentCharEscaped = getIsCharacterEscaped(text, i)

        if (['"', "'"].includes(char) && !isCurrentCharEscaped && !inFieldName) {
            if (currentStringOpener) {
                if (char === currentStringOpener.char) {
                    currentStringOpener = undefined
                }
            } else {
                currentStringOpener = {
                    pos: i,
                    char,
                }
            }
        }

        if (!currentStringOpener && char === '{') {
            inFieldName = true
        } else if (inFieldName && char === '}') {
            inFieldName = false
        }
    }

    if (currentStringOpener) {
        const substring = text.substring(currentStringOpener.pos, currentStringOpener.pos + 10)
        return `This quote is never closed: …${substring}…`
    } else {
        return undefined
    }
}

const getIsCharacterEscaped = (text: string, pos: number): boolean => {
    // The logic here is that " isn't escaped, \" is escaped, \\" isn't escaped,
    // \\\" is escaped...
    let backslashesBeforeCurrentChar = 0
    let backslashCheckIndex = pos - 1
    let someNonBackslashFound = false
    while (backslashCheckIndex >= 0 && !someNonBackslashFound) {
        if (text.charAt(backslashCheckIndex) === '\\') {
            backslashesBeforeCurrentChar += 1
            backslashCheckIndex--
        } else {
            someNonBackslashFound = true
        }
    }
    return backslashesBeforeCurrentChar % 2 === 1
}

export const stringifyStackerAST = <T extends StackerFormulaSyntaxTree>(
    ast: T | undefined,
    object?: ObjectDto
): string => {
    if (!ast) {
        return ''
    }
    // It's a bit weird that we have to do this, but TS doesn't realize that
    // stackerAstStringifyMap[ast.function] actually accepts only a parameter
    // of the same type as our ast here, so we need to do the cast.
    // TODO: make this look a bit nicer.
    const stringifyFunction = stackerAstStringifyMap[ast.function] as
        | ((ast: T, object: ObjectDto | undefined) => string | undefined)
        | undefined
    const output = stringifyFunction?.(ast, object) ?? 'COULD NOT STRINGIFY AST'
    return ast.bracketed ? `(${output})` : output
}

// This type ensures that:
// 1. If we add a new function and forge to add it to stackerAstStringifyMap, we get a
//    compilation error.
// 2. The stringify function for function ABS accepts an AST for the ABS function,
//    and so on for every function that we have.
type AstStringifyMap = {
    [F in StackerFormulaSyntaxTree as F['function']]: (
        ast: F,
        object: ObjectDto | undefined
    ) => string | undefined
}

const stackerAstStringifyMap: AstStringifyMap = {
    ABS: formatUnaryFunction,
    ADD: getJoinWithOperatorFunction('+'),
    AMPERSAND: getJoinWithOperatorFunction('&'),
    AND: getJoinWithOperatorFunction('AND'),
    AND_FUN: stringifyNAryFunctionWithFunSuffix,
    ARRAY: stringifyNAryFunction,
    AVERAGE: stringifyNAryFunction,
    BOOL: (ast) => (ast.value ? 'True' : 'False'),
    CEILING: formatRoundFunction,
    CONCAT: stringifyNAryFunction,
    CREATED_AT: formatFunctionWithoutArguments,
    CREATED_BY: formatFunctionWithoutArguments,
    DATE_LITERAL: formatDateLiteral,
    DATEADD: (ast, object) =>
        `${ast.function}(${stringifyStackerAST(ast.date, object)}, ${stringifyStackerAST(
            ast.number,
            object
        )}, ${stringifyStackerAST(ast.units, object)})`,
    DATEDIF: (ast, object) => formatFunction(ast, object, ['date_1', 'date_2', 'units']),
    DATESTR: formatDatetime,
    DATETIME_LITERAL: formatDatetimeLiteral,
    DAY: formatDatetime,
    DIVIDE: getJoinWithOperatorFunction('/'),
    EQUALS: getJoinWithOperatorFunction('='),
    FIELD: stringifyFieldFunction,
    FIND: formatSearchOrFindFunction,
    FLOOR: formatRoundFunction,
    FORMAT_NUMBER: formatUnaryFunction,
    GT: getJoinWithOperatorFunction('>'),
    GTE: getJoinWithOperatorFunction('>='),
    HOUR: formatDatetime,
    IF: (ast, object) =>
        `${ast.function}(${stringifyStackerAST(ast.condition, object)}, ${stringifyStackerAST(
            ast.then,
            object
        )}, ${stringifyStackerAST(ast.else, object)})`,
    INT: formatUnaryFunction,
    IS_AFTER: (ast, object) => formatFunction(ast, object, ['date_1', 'date_2']),
    IS_BEFORE: (ast, object) => formatFunction(ast, object, ['date_1', 'date_2']),
    IS_BLANK: formatUnaryFunction,
    IS_SAME: (ast, object) => formatFunction(ast, object, ['date_1', 'date_2', 'units']),
    LAST_UPDATED_AT: formatFunctionWithoutArguments,
    LAST_UPDATED_BY: formatFunctionWithoutArguments,
    LEFT: (ast, object) => formatFunction(ast, object, ['string', 'count']),
    LEN: formatUnaryFunction,
    LOG: formatLogFunction,
    LOWER: formatUnaryFunction,
    LT: getJoinWithOperatorFunction('<'),
    LTE: getJoinWithOperatorFunction('<='),
    MAX: stringifyNAryFunction,
    MIN: stringifyNAryFunction,
    MINUTE: formatDatetime,
    MOD: (ast, object) => formatFunction(ast, object, ['dividend', 'divisor']),
    MONTH: formatDatetime,
    MULTIPLY: getJoinWithOperatorFunction('*'),
    NOT: (ast, object) => 'NOT ' + ast.arguments?.map((expr) => stringifyStackerAST(expr, object)),
    NOT_EQUALS: getJoinWithOperatorFunction('!='),
    NOT_FUN: stringifyNAryFunctionWithFunSuffix,
    NOW: formatFunctionWithoutArguments,
    NUMBER: (ast) => `${ast.value}`,
    OR: getJoinWithOperatorFunction('OR'),
    OR_FUN: stringifyNAryFunctionWithFunSuffix,
    POWER: formatPowerFunction,
    REGEX_EXTRACT: (ast, object) => formatFunction(ast, object, ['string', 'regex']),
    REGEX_MATCH: (ast, object) => formatFunction(ast, object, ['string', 'regex']),
    REGEX_REPLACE: (ast, object) => formatFunction(ast, object, ['string', 'regex', 'replacement']),
    RIGHT: (ast, object) => formatFunction(ast, object, ['string', 'count']),
    ROUND: formatRoundFunction,
    ROUNDDOWN: formatRoundFunction,
    ROUNDUP: formatRoundFunction,
    RECORD_ID: formatFunctionWithoutArguments,
    SEARCH: formatSearchOrFindFunction,
    STRING: formatStringLiteral,
    STRING_VALUE: formatStringLiteral,
    SUBTRACT: getJoinWithOperatorFunction('-'),
    SUM: stringifyNAryFunction,
    TODAY: (ast, object) => formatFunction(ast, object, []),
    TRIM: formatUnaryFunction,
    UPPER: formatUnaryFunction,
    VALUE: formatUnaryFunction,
    YEAR: formatDatetime,
    DOCUMENT: stringifyDocumentFunction,
}

function getJoinWithOperatorFunction(
    operator: string
): (ast: StackerOperatorFunction, object: ObjectDto | undefined) => string | undefined {
    return (ast, object) => stringifyArguments(ast, object, ` ${operator} `)
}

function stringifyNAryFunction(
    ast: StackerNAryFunction,
    object: ObjectDto | undefined
): string | undefined {
    return `${ast.function}(${stringifyArguments(ast, object)})`
}

function stringifyNAryFunctionWithFunSuffix(
    ast: StackerNAryFunction,
    object: ObjectDto | undefined
) {
    return `${ast.function.replace(/_FUN$/, '')}(${stringifyArguments(ast, object)})`
}

function stringifyArguments(
    ast: StackerNAryFunction | StackerOperatorFunction,
    object: ObjectDto | undefined,
    separator = ', '
): string {
    return ast.arguments?.map((expr) => stringifyStackerAST(expr, object)).join(separator) ?? ''
}

function stringifyFieldFunction(ast: StackerFieldFunction, object: ObjectDto | undefined) {
    if (!object) {
        return `{${ast.api_name}}`
    }

    const field = object.fields.find((x) => x.api_name === ast.api_name)
    if (field) {
        const fieldsByName = object.fields.filter(
            (x) => x.label.toLowerCase() === field.label.toLowerCase()
        )
        if (fieldsByName.length > 1) {
            return `{${field.label} [${field.api_name}]}`
        } else {
            return `{${field.label}}`
        }
    } else {
        return `{DELETED [${ast.api_name}]}`
    }
}

function stringifyDocumentFunction(ast: StackerDocumentFunction) {
    return `${ast.function}()`
}

// For any AST object, pick the keys whose values are also ASTs
// e.g., for StackerComparisonFunction (function, date_1 and date_2),
// it accepts "date_1" and "date_2".
// The | undefined bit is so that can accept optional fields
type AstFieldKey<T extends StackerFormulaSyntaxTree> = {
    [K in keyof T]: T[K] extends StackerFormulaSyntaxTree | undefined ? K : never
}[keyof T]

function formatFunctionWithoutArguments<T extends StackerFormulaSyntaxTree>(ast: T) {
    return `${ast.function}()`
}

function formatFunction<T extends StackerFormulaSyntaxTree>(
    ast: T,
    object: ObjectDto | undefined,
    fields: AstFieldKey<T>[]
) {
    const fieldsStr = fields.map((f) => stringifyStackerAST(ast[f] as any, object)).join(', ')
    return `${ast.function}(${fieldsStr})`
}

function formatWithSeparator<T extends StackerFormulaSyntaxTree>(
    ast: T,
    object: ObjectDto | undefined,
    fields: AstFieldKey<T>[],
    separator: string
) {
    return fields.map((f) => stringifyStackerAST(ast[f] as any, object)).join(separator)
}

function formatUnaryFunction(
    ast: StackerUnaryValueFunction | StackerUnaryStringFunction,
    object: ObjectDto | undefined
) {
    return formatFunction(ast, object, ['argument'])
}

function formatDatetime(ast: StackerDatetimeFunction, object: ObjectDto | undefined) {
    return formatFunction(ast, object, ['datetime'])
}

function formatRoundFunction(ast: StackerRoundFunction, object: ObjectDto | undefined) {
    return formatFunction(ast, object, ast.precision ? ['number', 'precision'] : ['number'])
}

function formatLogFunction(ast: StackerLogFunction, object: ObjectDto | undefined) {
    return formatFunction(ast, object, ast.base ? ['number', 'base'] : ['number'])
}

function formatSearchOrFindFunction(ast: StackerSearchFindFunction, object: ObjectDto | undefined) {
    return formatFunction(
        ast,
        object,
        ast.start_position ? ['substring', 'string', 'start_position'] : ['substring', 'string']
    )
}

function formatStringLiteral(ast: StackerStringLiteral | StackerStringValueToken) {
    // We try to be smart about escaping quotes in the string.
    if (ast.value.includes('"')) {
        return `'${ast.value.replace(/'/g, "\\'")}'`
    }
    return `"${ast.value.replace(/"/g, '\\"')}"`
}

function formatDatetimeLiteral(ast: StackerDatetimeLiteral) {
    const datetime = moment(ast.value)
    if (datetime.seconds() === 0 && datetime.milliseconds() === 0) {
        return `"${datetime.format('YYYY-MM-DD HH:mm')}"`
    }
    return `"${datetime.format('YYYY-MM-DD HH:mm:ss')}"`
}

function formatDateLiteral(ast: StackerDateLiteral) {
    return `"${ast.value}"`
}

function formatPowerFunction(ast: StackerPowerFunction, object: ObjectDto | undefined) {
    if (ast.syntax === '^') {
        return formatWithSeparator(ast, object, ['base', 'exponent'], ' ^ ')
    }

    return formatFunction(ast, object, ['base', 'exponent'])
}
