import { useCallback, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'

import useDebounce from 'v2/ui/utils/useDebounce'

const DEBOUNCE_RATE = 300

type UseListHeaderDefaultControlsStateOptions = {
    header: ListViewHeaderDefault
    updateHeader: (header: Partial<ListViewHeaderDefault>) => void
}

export function useListHeaderDefaultControlsState(
    options: UseListHeaderDefaultControlsStateOptions
) {
    const { header, updateHeader } = options

    const { title: titlePlaceholder } = useListViewContext()

    const debouncedUpdateHeader = useDebounce(updateHeader, DEBOUNCE_RATE, [updateHeader])

    const [title, setTitle] = useState(header.title)

    const handleChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setTitle(value)

            debouncedUpdateHeader({
                title: value.trim(),
            })
        },
        [debouncedUpdateHeader]
    )

    const [subtitle, setSubtitle] = useState(header.subtitle)

    const handleChangeSubtitle = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value

            setSubtitle(value)

            debouncedUpdateHeader({
                subtitle: value.trim(),
            })
        },
        [debouncedUpdateHeader]
    )

    return useMemo(
        () => ({
            title,
            handleChangeTitle,
            titlePlaceholder,
            subtitle,
            handleChangeSubtitle,
        }),
        [handleChangeSubtitle, handleChangeTitle, subtitle, title, titlePlaceholder]
    )
}
