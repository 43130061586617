import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { useWorkflowChainContext } from './WorkflowChainContext'
import { useWorkflowEditorContext } from './WorkflowEditorContext'

type FlowLineProps = {
    line: WorkflowLine
    showArrows?: boolean
    compact?: boolean
}
export function FlowLine({ line, showArrows = true, compact = false }: FlowLineProps) {
    const { chainPath } = useWorkflowChainContext()

    const { selectedLine, setSelectedLine } = useWorkflowEditorContext()

    const isSelected = line.id === selectedLine?.id

    let lineHeight: React.ComponentPropsWithoutRef<typeof Box>['height'] = 'auto'
    if (showArrows) lineHeight = '6xl'
    if (showArrows && compact) lineHeight = '5xl'

    return (
        <Box height={lineHeight} position="relative" flex center justifyContent="center" noShrink>
            <Icon
                name="PlusSquare"
                color={
                    {
                        default: isSelected ? 'admin500' : 'textWeakest',
                        hover: 'admin500',
                    } as never
                }
                background="surfaceStrong"
                style={{ zIndex: 1 }}
                py="3xs"
                role="button"
                onClick={(e) => {
                    setSelectedLine(line, chainPath)

                    e.stopPropagation()
                }}
                size={showArrows ? 'm' : '2xl'}
            />
            {showArrows && (
                <>
                    <Box
                        position="absolute"
                        width="2xs"
                        height="full"
                        background="border"
                        style={{ top: 0, bottom: 0, margin: 'auto' }}
                    />

                    <Icon
                        name="ChevronDown"
                        color="border"
                        position="absolute"
                        style={{ bottom: '-2px', margin: 'auto' }}
                        size="s"
                    />
                </>
            )}
        </Box>
    )
}
