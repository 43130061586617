import React, { memo, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useObject } from 'data/hooks/objects'
import { useGetFieldRecords } from 'data/hooks/records'
import WithObject from 'data/wrappers/WithObject'
import { filtersToValue } from 'features/records/components/RecordFilters'

import { Editable } from 'v2/ui'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { resolveContextRecordFilters } from './utils/resolveContextRecordFilters'
import DisplayText from './DisplayText'
import { RecordDropdownWithCreate } from './RecordDropdown'
import { RecordLinkDisplay } from './RecordLinkDisplay'
import TagList from './TagList'

const MultiRecordLinkAttribute = memo(
    ({
        mode,
        layout,
        size,
        onChange,
        renderOptions = {},
        controlStyle = {},
        isInlineCreate,
        field,
        recordId,
        dereferencedRecords: suppliedDereferencedRecords,
        inDataGrid,
        bypassPreviewAs,
        onRecordsSelected,
        placeholder,
        additionalRecords,
        hasFocus,
        contextRecord,
        valueContainerStyle,
        style,
        ...props
    }) => {
        const { object: targetObject } = useObject(field.link_target_object_id)

        const dereferencedRecords =
            contextRecord?._dereferenced_records || suppliedDereferencedRecords
        // We do an api fetch for all records in the field, this means that we won't be doing lots of
        // requests to get each individual record
        const { records = [], isLoading } = useGetFieldRecords({
            field,
            parentRecordId: recordId,
            value: props.children,
            dereferencedRecords,
        })

        const { disableSearch, showCreateButton, wrapText } = renderOptions
        const fieldFilters = field.connection_options?.relationship_target_lookup_filters
        // If any lookup filters has been specified on the link field
        // we apply them for the dropdown
        const rawFilters = useMemo(
            () => filtersToValue(fieldFilters || [], targetObject),
            [fieldFilters, targetObject]
        )

        const filters = useMemo(
            () => resolveContextRecordFilters(rawFilters, contextRecord),
            [rawFilters, contextRecord]
        )

        const dropdownRef = useRef()
        if (inDataGrid) {
            // Focus on open for the Glide data grid.
            dropdownRef.current?.select?.inputRef?.focus()
        }
        useEffect(() => {
            if (inDataGrid) {
                // Focus on open for the AG data grid.
                dropdownRef.current?.select?.inputRef?.focus()
            }
        }, [inDataGrid])

        const form = (
            <WithObject objectId={field.object_id}>
                {() => {
                    let value = props.children

                    return (
                        <RecordDropdownWithCreate
                            forwardedRef={dropdownRef}
                            className={classNames(
                                inDataGrid ? 'click-outside-ignore ag-custom-component-popup' : '',
                                props.className
                            )}
                            showCreateButton={!!showCreateButton}
                            isInlineCreate={isInlineCreate}
                            objectId={field.link_target_object_id}
                            value={props.children || value}
                            onChange={onChange}
                            autoFocus={mode === modes.editable || hasFocus}
                            filters={filters}
                            isMulti
                            maxWidth="100%"
                            isSearchable={!disableSearch}
                            onRecordsSelected={onRecordsSelected}
                            bypassPreviewAs={bypassPreviewAs}
                            placeholder={placeholder}
                            additionalRecords={additionalRecords}
                            controlStyle={{
                                background: 'white',
                                ...controlStyle,
                            }}
                            valueContainerProps={valueContainerStyle}
                            defaultMenuIsOpen={hasFocus}
                            style={style}
                        />
                    )
                }}
            </WithObject>
        )

        const getRecordBySid = (record, sid) => {
            return record.find((r) => r._sid === sid)
        }

        const inline = layout === layouts.inline
        // This deep equals memoizer makes sure we don't needlessly rerender
        // when the children Object changes, but the values are exactly the same
        // This is safe because the children are going to be an array of
        // attachment objects with simple properties such as Urls, name, etc.
        const memoizedChildren = useDeepEqualsMemoValue(props.children)
        let display = useMemo(() => {
            let display
            if (Array.isArray(memoizedChildren) && memoizedChildren && memoizedChildren.length) {
                const validRecords = memoizedChildren
                    .map((record_sid) => getRecordBySid(records, record_sid))
                    .filter((record) => record?._sid)

                let lookups = validRecords.map((record) => {
                    return (
                        <RecordLinkDisplay
                            record={record}
                            margin="none"
                            recordId={record._sid}
                            key={record._sid}
                            noLink={props.noLinks}
                            size={size}
                            flexShrink={0}
                            layout={props.layout}
                        />
                    )
                })

                if (props.keepSmall) {
                    if (lookups.length > 5) {
                        lookups = lookups.slice(0, 6)
                    }
                }

                display = (
                    <TagList
                        wrapText={wrapText}
                        items={lookups}
                        singleLine={inline}
                        maxVisible={5}
                        className={props.className}
                    />
                )
            } else {
                display = <DisplayText>-</DisplayText>
            }
            return display
        }, [
            memoizedChildren,
            props.keepSmall,
            props.noLinks,
            props.layout,
            wrapText,
            inline,
            records,
            size,
            props.className,
        ])

        if (isLoading && mode !== modes.editing) return null

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end }) =>
                        React.cloneElement(form, {
                            onBlur: () => {
                                end()
                            },
                        })
                    }
                    display={() => display}
                    onChange={onChange}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

MultiRecordLinkAttribute.propTypes = {
    /** handles saving the changed value */
    onChange: PropTypes.func,
}
export default MultiRecordLinkAttribute
