import { useCallback, useEffect, useRef, useState } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

export function useIsGridElementInViewport(
    getElement: () => HTMLElement | null,
    getContainer: () => HTMLElement | null
) {
    const { isGridReady, gridApi } = useDataGridContext()

    const [isElemInViewport, setIsElemInViewport] = useState(true)

    const getElementRef = useRef(getElement)
    getElementRef.current = getElement

    const getContainerRef = useRef(getContainer)
    getContainerRef.current = getContainer

    const handleElement = useCallback(() => {
        const root = getContainerRef.current()
        const addNewButton = getElementRef.current()

        if (!root || !addNewButton) {
            setIsElemInViewport(false)
            return
        }

        const isElementVisible = isVisible(addNewButton, root)
        setIsElemInViewport(isElementVisible)
    }, [])

    const mutationObserverRef = useRef<MutationObserver | null>(null)

    useEffect(() => {
        // Also observe the container for changes, in case the element is removed and re-added.
        mutationObserverRef.current = new MutationObserver(handleElement)
        const container = getContainerRef.current()
        if (container) {
            mutationObserverRef.current.observe(container, { childList: true, subtree: true })
        }

        if (!isGridReady) return

        handleElement()

        gridApi?.addEventListener('bodyScroll', handleElement)
        gridApi?.addEventListener('gridSizeChanged', handleElement)

        return () => {
            mutationObserverRef.current?.disconnect()

            if (gridApi?.isDestroyed()) return

            gridApi?.removeEventListener('bodyScroll', handleElement)
            gridApi?.removeEventListener('gridSizeChanged', handleElement)
        }
    }, [gridApi, isGridReady, handleElement])

    return isElemInViewport
}

function isVisible(element: HTMLElement, container: HTMLElement) {
    const elemRect = element.getBoundingClientRect()
    const containerRect = container.getBoundingClientRect()

    // Check for vertical overlap.
    const verticalOverlap =
        Math.min(elemRect.bottom, containerRect.bottom) - Math.max(elemRect.top, containerRect.top)

    // Check for horizontal overlap.
    const horizontalOverlap =
        Math.min(elemRect.right, containerRect.right) - Math.max(elemRect.left, containerRect.left)

    // The element is partially visible if there's an overlap in both dimensions.
    const isPartiallyVisible = verticalOverlap > 0 && horizontalOverlap > 0

    return isPartiallyVisible
}
