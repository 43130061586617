// @ts-strict-ignore
import React, { useState } from 'react'

import {
    UnsavedChangesModal,
    useUnsavedChangesModalCallback,
} from 'features/workspace/UnsavedChangesModal'

import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import Form from 'ui/deprecated/forms/Form'
import SubmitButton from 'ui/deprecated/forms/SubmitButton'
import { SyncRefWithForm } from 'ui/deprecated/forms/utils'

import { PermissionsRuleConfigContent } from './PermissionsRuleConfigContent'

/**
 *
 * @param {{ onSubmit: () => unknown, onClose: () => void, roles: any[], objects: any[], object: any, userObject: any }} props
 * @returns
 */
export const PermissionsRuleForm = ({
    title,
    onClose,
    onSubmit,
    roles,
    object,
    userObject,
    objects,
    defaultValues,
    formRef,
    enableUnsavedChangesModal,
    isNew,
}) => {
    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)
    const [canSave, setCanSave] = useState(true)

    const handleClose = () => {
        saveChanges(onClose)
    }

    const onFormSubmit = () => {
        const data = formRef.current.getValues()
        const formState = formRef.current.formState

        if (!formState.isValid) {
            return
        }

        // Bizarrely, the isDirty param of formState comes back false even when there are
        // dirty fields, so check the dirtyFields object instead.
        const isDirty = !Object.values(formState.dirtyFields).every((item) => item !== true)
        if (isDirty || isNew) {
            onSubmit(data)
        } else {
            onClose()
        }
    }

    const onValidityChange = (valid) => {
        setCanSave(valid)
    }

    return (
        <SimpleModalCompat
            title={title}
            isOpen
            onClose={handleClose}
            size="800px"
            height="800px"
            style={{ maxHeight: 'calc(100dvh - 48px)' }}
            actions={[
                {
                    label: 'Save',
                    variant: 'adminPrimary',
                    className: ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_SAVE_BUTTON,
                    disabled: !canSave,
                    onClick: onFormSubmit,
                },
            ]}
        >
            <Form options={{ defaultValues }} ref={formRef} onSubmit={onFormSubmit}>
                {/* SyncRefWithForm is needed because it tracks the dirty state
                    and records it on the form context. Without it, UnsavedChangesModal component
                    can't detect any changes. */}
                <SyncRefWithForm formRef={formRef} />
                {displayChangesModal && enableUnsavedChangesModal && (
                    //@ts-ignore
                    <UnsavedChangesModal {...displayChangesModal} />
                )}

                <PermissionsRuleConfigContent
                    objects={objects}
                    userObject={userObject}
                    object={object}
                    roles={roles}
                />

                {/* This submit button is hidden. The actual submit button is outside the <Form>,
                    in the modal footer as defined in actions above. This extra button is needed
                    because of what appears to be a bug in react-hook-form where formState.isValid
                    remains false even when there are is nothing in formState.errors, and for some
                    reason adding a submit button to the form fixes the issue. */}
                <SubmitButton v4 style={{ display: 'none' }} onValidityChange={onValidityChange}>
                    Save
                </SubmitButton>
            </Form>
        </SimpleModalCompat>
    )
}
