import { useMemo, useState } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'
import { useTableViewRowContext } from 'features/views/ListView/TableView/TableViewRowContext'

export function useTableViewRecordActionsState() {
    const [isOpen, setIsOpen] = useState(false)

    const { record } = useTableViewRowContext()
    const { isRecordActionsColumnPinned: isPinned } = useTableViewContext()

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
            record,
            isPinned,
        }),
        [isOpen, record, isPinned]
    )
}
