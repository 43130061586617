import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentValue } from 'features/views/attributes/types'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_ATTACHMENTS = 2
const MAX_ITEM_LENGTH = 20
const MAX_LINES = 1

type AttachmentsCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const AttachmentsCell: TableViewCellComponent<AttachmentValue[], AttachmentsCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field, rawOptions } = column

    const isEmpty = !isLoading && (!value || value.length < 1)

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <AttachmentsAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxAttachments={MAX_ATTACHMENTS}
                maxItemLength={MAX_ITEM_LENGTH}
                maxLines={MAX_LINES}
                displayAsImage={rawOptions.displayAsImage}
                imageAltText={rawOptions.imageAltText}
                isLoading={isLoading}
            />
        </BaseCell>
    )
}
