import React from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useRecords } from 'data/hooks/records'
import { Icon8, Tooltip } from 'legacy/v1/ui'

import { CogIcon } from './CogIcon'

export const DetailLayoutSettings = ({
    object,
    onCloseSettingsModal,
    userActions,
    view,
}: {
    object: ObjectDto
    onCloseSettingsModal: () => void
    userActions: any
    view: ViewDto
}): React.ReactElement | null => {
    const { data: recordData } = useRecords({ objectSid: object?._sid })
    const records = recordData?.records
    const history = useHistory()

    if (view?.type === 'detail') {
        // For detail view, we will redirect to the first record
        // if there are no records, we will suggest to add one
        if (records && records.length > 0) {
            // There is a record, we redirect to that URL
            const url = getUrl(`${object.url}/view/${records[0]._sid}`)
            return (
                <CogIcon
                    onClick={() => {
                        userActions.startEditing()
                        history.push(url)

                        onCloseSettingsModal()
                    }}
                />
            )
        } else {
            // There are no records, we will show a tooltip asking the user to add a record
            return (
                <Tooltip
                    id="save-view-name"
                    tooltip="This table is empty, you must first add a record to update the detail layout"
                    align="right"
                >
                    <Icon8
                        icon="settings"
                        size="88"
                        displaySize="22"
                        iconStyle="ios"
                        style={cogIconPositionStyle}
                    />
                </Tooltip>
            )
        }
    }
    return null
}

const cogIconPositionStyle = { marginLeft: 12, marginTop: -4 }
