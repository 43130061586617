import { useContext, useMemo } from 'react'
import { useMutation } from 'react-query'

import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import { AppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/GlobalStaticState'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import {
    buildQueryKey,
    queryClient,
    useCanRunStackScopedQueries,
    useCanRunWorkspaceScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from './_helpers'
import { useCreateDocument, useDocument } from './documents'
import { invalidateNavigation } from './navigation'
import { invalidatePages } from './pages'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'useViews'
const ENDPOINT = 'views/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
function getQueryKey() {
    return buildQueryKey(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
/**
 *
 * @param {import('react-query').UseQueryOptions } options
 * @return {import('react-query').UseQueryResult<ViewDto[]>}
 */
export function useViews(options = {}) {
    const enabled = useCanRunStackScopedQueries()
    // When query key changes due to previewing another role or user,
    // instead of reverting to an empty/loading state, keep the previous
    // data and just updated it when the results come in.
    const query_config = {
        ...STACK_QUERY_CONFIG,
        keepPreviousData: true,
        ...options,
        enabled: enabled && options.enabled,
    }
    return useQuery(useQueryKey(options.stackId), ENDPOINT, query_config)
}

// Fetch all views across the workspace
export function useViewsAll(options = {}) {
    const enabled = useCanRunWorkspaceScopedQueries()
    return useQuery(
        [...useQueryKey(), 'ALL'],
        ENDPOINT,
        { ...options, enabled: enabled && options.enabled !== false },
        {
            bypassMatchingStackCheck: true,
        }
    )
}

export function useStackViews(options = {}) {
    const { data: views = [] } = useViews(options)
    const { selectedStack } = useContext(AppContext)
    const { allStacks } = options

    return useMemo(
        () => views.filter((item) => allStacks || item.stack_id === selectedStack?._sid),
        [views, selectedStack, allStacks]
    )
}

export function useCreateView() {
    return useCreateItem(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateViews()
                invalidatePages()
                invalidateNavigation()
            },
        },
        {
            bypassPreviewAs: true,
        }
    )
}
export function useUpdateView(allowOptimisticUpdates = true) {
    return useUpdateItem(
        useQueryKey(),
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateViews()
                invalidatePages()
                invalidateNavigation()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        },
        allowOptimisticUpdates
    )
}
export function useDeleteView() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidateViews()
            invalidatePages()
            invalidateNavigation()
        },
    })
}
export function invalidateViews() {
    // Want to include the stack id here otherwise we'll be invalidating for all the apps
    // which have been loaded
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}
export function refetchViews() {
    // invalidate all instances of these queries
    invalidateViews()
    // but only refetch the one that is for the current user
    return queryClient.refetchQueries(getQueryKey())
}

/**
 *
 * @param {string | undefined} id
 * @return {ViewDto}
 */
export function useViewFromId(id) {
    const stackId = getCurrentStackId()
    const { data: views } = useViews()

    return useMemo(() => {
        return views?.find((view) => view._sid === id && stackId == view.stack_id)
    }, [views, id, stackId])
}

function cloneView(newName, view) {
    const newView = {
        options: {},
        ...cloneDeep(omit(view, ['_sid', 'api_name'])),
    }

    newView.name = newName
    newView.options.title = newName
    newView.options.menu_label = newName

    newView.options.cloned_from = view._sid

    if (view.options) {
        newView.options.optimizedLayout = view.options.optimizedLayout
        newView.options.document_id = view.options.document_id
    }

    return newView
}

function cloneDocument(newName, document) {
    const newDocument = {
        ...cloneDeep(omit(document, ['auto_id'])),
    }

    newDocument.title = newName
    return newDocument
}

export function useDuplicateView(options) {
    const { mutateAsync: createView } = useCreateView()

    // Also create a cloned document if we pass in a document ID
    const { mutateAsync: createDocument } = useCreateDocument()
    // Enabled will be false if no document ID is present, so no fetch will occur
    const documentResponse = useDocument(options.document_id, null, {
        enabled: !!options.document_id,
    })

    return useMutation(async ({ name, view }) => {
        const newView = cloneView(name, view)
        if (documentResponse) {
            const { data: document } = documentResponse
            const newDocSettings = cloneDocument(name, document)
            const { document: newDoc } = await createDocument(newDocSettings)
            newView.options.document_id = newDoc.auto_id
        }
        return createView(newView)
    }, options)
}
