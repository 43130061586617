// @ts-strict-ignore
import React from 'react'
import { components } from 'react-select'

// @ts-ignore
import { Button, Dropdown, Flex } from 'v2/ui'

export type UserProfilesDropdownOptions = {
    label: string
    value: string
}

type UserProfilesDropdownProps = {
    id?: string
    placeholder?: string
    value: string | undefined
    options: UserProfilesDropdownOptions[]
    style?: any
    withFooter?: boolean
    onChange?: (field: any) => void
    isClearable?: boolean
    variant?: string
    usePortal?: boolean
}

const UserProfilesDropdown: React.FC<UserProfilesDropdownProps> = ({
    id,
    placeholder = 'Select attribute',
    value,
    options,
    style,
    withFooter = false,
    onChange,
    variant,
    usePortal,
}) => {
    return (
        <Dropdown
            id={id}
            options={options}
            value={value}
            optionComponent={FilterOption}
            singleValueComponent={FilterSelectValue}
            footer={withFooter ? FiltersListFooter : ''}
            placeholder={placeholder}
            style={style}
            onChange={onChange}
            variant={variant}
            usePortal={usePortal}
        />
    )
}

const FilterOption = (props) => {
    return (
        <components.Option {...props}>
            (
            <Flex align="center">
                <span>{props.data?.label}</span>
            </Flex>
            )
        </components.Option>
    )
}

const FilterSelectValue = (props) => {
    return (
        <div>
            (
            <Flex align="center">
                <span>{props.data?.label}</span>
            </Flex>
            )
        </div>
    )
}

const FiltersListFooter = (props) => {
    const { onFooterClick } = props

    return (
        <div>
            <Button buttonSize="sm" variant="adminClear" onClick={onFooterClick}>
                Connect a user table
            </Button>
        </div>
    )
}

export default UserProfilesDropdown
