import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

import { Combobox } from './Primitives'

import { GroupTitleStyle, ItemStyle, MenuStyle } from './ComboboxStyles.css'
export const Root = Combobox.Root
export const Input = makeComponent(Combobox.Input, EmptyStyles)
export const Menu = makeComponent(Combobox.Menu, MenuStyle)
export const Item = makeComponent(Combobox.Item, ItemStyle)
export const GroupTitle = makeComponent('div', GroupTitleStyle)
