import React, { useCallback, useEffect } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Pagination } from 'ui/components/Pagination'
import { theme } from 'ui/styling/Theme.css'

type PaginationPanelProps = React.ComponentPropsWithoutRef<typeof Box> & {
    addNewEmptyRecord: () => void
    totalRecordCount?: number
    isAddNewVisible?: boolean
}

export const PaginationPanel: React.FC<PaginationPanelProps> = ({
    totalRecordCount = 0,
    addNewEmptyRecord,
    isAddNewVisible,
    ...props
}) => {
    const { gridApi, isGridReady, pageSize, pageIndex, setPageIndex } = useDataGridContext()

    const onChangePageIndex = useCallback(
        (newPageIndex: number) => {
            setPageIndex?.(newPageIndex)
        },
        [setPageIndex]
    )

    useEffect(() => {
        if (!gridApi || !isGridReady) return

        // Scroll to the top of the grid, and deselect all rows when changing pages.
        gridApi.deselectAll()
        gridApi.clearRangeSelection()
        gridApi.ensureIndexVisible(0, 'top')
    }, [gridApi, isGridReady, pageIndex])

    if (!gridApi || !isGridReady) return null

    // Don't show pagination if it's not needed.
    if (totalRecordCount <= pageSize) return null

    return (
        <Box
            background="surfaceStrong"
            width="full"
            flex
            center
            px="s"
            borderTopWidth={1}
            {...props}
        >
            <Box flex center grow noShrink>
                <Button
                    variant="ghost"
                    size="s"
                    type="button"
                    startIcon={{ name: 'Plus' }}
                    color={{ default: 'textWeak', hover: 'text' }}
                    onClick={addNewEmptyRecord}
                    style={{ opacity: isAddNewVisible ? 1 : 0 }}
                    pointerEvents={isAddNewVisible ? 'auto' : 'none'}
                >
                    To add a new record, click here or press Shift+Enter
                </Button>
            </Box>
            <Pagination
                pageSize={pageSize}
                totalItemsCount={totalRecordCount}
                pageIndex={pageIndex}
                onChangePageIndex={onChangePageIndex}
                noShrink
                style={{
                    paddingTop: theme.space.m,
                    paddingBottom: theme.space.m,
                }}
            />
        </Box>
    )
}
