import { useEffect, useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import { ContextSchema } from 'features/expressions/types'
import { getSchemaItemFromPath } from 'features/expressions/utilities'
import { StackerFieldFunction } from 'features/formulas/parser/formulaParserTypes'
import { useWorkflowEditorContext } from 'features/workflows/WorkflowEditorContext'

import { WorkflowActionEditorComponent } from './types'

export const UpdateRecordActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { contextSchema } = useWorkflowEditorContext()
    const { selectedStack } = useAppContext()

    const targetRecordInput = action.inputs?.find(
        (i) => i.id === 'target_record_id'
    ) as WorkflowActionConfigInput
    const targetRecordExpression = targetRecordInput?.value

    const schemaItem = targetRecordExpression
        ? (getSchemaItemFromPath(
              (targetRecordExpression?.value as StackerFieldFunction)?.api_name ?? '',
              contextSchema as ContextSchema
          )?.item as WorkflowSchemaActionStateItem)
        : undefined

    const targetObjectId = schemaItem?.extra_options?.link_target_object_id

    const settingsRef = useRef(action.settings)
    settingsRef.current = action.settings

    useEffect(() => {
        onChange({
            settings: {
                ...settingsRef.current,
                object_id: targetObjectId,
            },
        })
    }, [onChange, selectedStack?._sid, targetObjectId])

    return null
}
