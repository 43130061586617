import { useMemo } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

import { IconName } from 'ui/components/Icon/Icon'

const actionConfigs = {
    maximize: {
        icon: 'Maximize',
        _sid: 'maximize',
        name: 'Open as full page',
    },
    open_in_new_tab: {
        icon: 'ArrowUpRightFromSquare',
        _sid: 'open_in_new_tab',
        name: 'Open in new tab',
    },
    copy_link: {
        icon: 'Link',
        _sid: 'copy_link',
        name: 'Copy link',
    },
    add_task: {
        icon: 'ClipboardCheck',
        _sid: 'add_task',
        name: 'Add task',
    },
    add_favorite: {
        icon: 'Star',
        _sid: 'add_favorite',
        name: 'Add to favorites',
    },
    delete: {
        icon: 'Trash',
        _sid: 'delete',
        name: 'Delete',
    },
    edit: {
        icon: 'Pencil',
        _sid: 'edit',
        name: 'Edit',
    },
}

export type SystemActionType = keyof typeof actionConfigs

export type SystemAction = ActionDto & {
    icon?: IconName
}

export function useSystemActions(): SystemAction[] {
    const { object } = useListViewContext()

    const objectSid = object._sid
    const stackSid = object.stack_id

    const canEdit = getAreObjectRecordsEditable(object)

    const canDelete = canDeleteRecords(object)

    const { flags } = useLDFlags()
    const canAddTasks = !!flags.tasks
    const canFavoriteRecord = !!flags.favorites

    return useMemo(() => {
        const items = [
            makeSystemAction(objectSid, stackSid, 'maximize'),
            makeSystemAction(objectSid, stackSid, 'open_in_new_tab'),
            makeSystemAction(objectSid, stackSid, 'copy_link'),
        ]

        if (canEdit) {
            items.push(makeSystemAction(objectSid, stackSid, 'edit'))
        }

        if (canAddTasks) {
            items.push(makeSystemAction(objectSid, stackSid, 'add_task'))
        }

        if (canFavoriteRecord) {
            items.push(makeSystemAction(objectSid, stackSid, 'add_favorite'))
        }

        if (canDelete) {
            items.push(makeSystemAction(objectSid, stackSid, 'delete'))
        }

        return items
    }, [canAddTasks, canDelete, canEdit, canFavoriteRecord, objectSid, stackSid])
}

function makeSystemAction(
    objectSid: string,
    stackSid: string,
    action: SystemActionType
): SystemAction {
    const actionConfig = actionConfigs[action]

    return {
        object_id: objectSid,
        stack_id: stackSid,
        options: {
            steps: [],
        },
        ...actionConfig,
    } as SystemAction
}

export function isSystemAction(id: string): boolean {
    return actionConfigs.hasOwnProperty(id as SystemActionType)
}
