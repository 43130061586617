import React from 'react'

import { useUnreadNotificationCount } from 'data/hooks/activities/notifications'
import { AppBarButton } from 'features/AppBar/AppBarButton'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { useNotificationPaneContext } from './NotificationPane'

import { BadgeStyle } from './Notifications.css'

type Props = {
    navTheme: any
    userMenuOnly: boolean
}
export function NotificationButton({ userMenuOnly, navTheme }: Props) {
    const { data: count = 0 } = useUnreadNotificationCount()
    const { setIsOpen } = useNotificationPaneContext()

    return (
        // @ts-ignore
        <AppBarButton
            aria-label="Notifications"
            navTheme={navTheme}
            isDisabled={userMenuOnly}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            alignSelf="center"
        >
            <Icon name="Bell" />
            {count > 0 && <Box className={BadgeStyle}>{count}</Box>}
        </AppBarButton>
    )
}
