import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { useCreateRoleModalState } from './hooks/useCreateRoleModalState'

export const CreateRoleModal = ({
    isOpen,
    setIsOpen,
    onRoleCreated,
}: {
    isOpen: boolean
    setIsOpen: (open: boolean) => void
    onRoleCreated: (role: RoleDto) => void
}) => {
    const { isRoleNameUnique, error, onModalClose, onSubmit, formContext } =
        useCreateRoleModalState({
            setIsOpen,
            onRoleCreated,
        })
    const {
        register,
        watch,
        formState: { isSubmitting, errors, isDirty },
    } = formContext

    const labelValue = watch('label')

    return (
        <Modal open={isOpen} onOpenChange={onModalClose}>
            <ModalContent zIndex={50000}>
                <ModalHeader title="Create role" showCloseButton={true} />
                <Box pb="m" px="3xl">
                    <Input
                        label="Role name"
                        size="m"
                        type="text"
                        placeholder="Enter role name"
                        isError={!!errors?.label}
                        helperText={errors?.label?.message}
                        required
                        {...register('label', {
                            required: 'Name is required',
                            validate: (name) =>
                                isRoleNameUnique(name) ||
                                'A role already exists with this name. Please choose another name.',
                        })}
                    />
                </Box>
                <ModalFooter flex flexDirection="column">
                    <Button
                        size="l"
                        variant="primary"
                        style={{ width: '100%' }}
                        disabled={!isDirty || !labelValue}
                        onClick={onSubmit}
                    >
                        {isSubmitting ? 'Creating role' : 'Create'}
                    </Button>
                    {error && (
                        <Body color="textError" size="s" mt="m" weight="medium">
                            {error}
                        </Body>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
