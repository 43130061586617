import { Bundle } from 'features/AiPlayground/bundles/bundleBuilder'
import { AppRecordsSchema, AppRecordsSchemaType } from 'features/AiPlayground/bundles/zodSchema'
import { getChatCompletion } from 'features/AiPlayground/chatUtils/chatApi'
import { zodToOpenAiResponseFormat } from 'features/AiPlayground/chatUtils/zodUtils'
import { swapImages } from 'features/AiPlayground/swapImages'

function getTerseSchema(bundle: Bundle, firstFieldsOnly: boolean = false): string {
    const schema = bundle.apps[0]
    const objects = schema.objects
    const lines: string[] = []
    for (const object of objects) {
        const objectName = object.name
        lines.push(`${objectName} (id: ${object.id}, ${object.description})`)
        const objectFields = object.fields

        if (firstFieldsOnly) {
            lines.push(`  ${objectFields[0].name} (${objectFields[0].type})`)
        } else {
            for (const field of objectFields) {
                const fieldName = field.name
                const fieldType = field.type
                lines.push(`  ${fieldName} (${fieldType})`)
            }
        }
    }
    return lines.join('\n')
}

function getRecords(bundle: Bundle) {
    return {
        objects: bundle.apps[0].objects.map((obj) => ({
            id: obj.id,
            records: obj.records.map((r) => ({
                id: r.recordId,
                fields: r.fields.map((x) => x.value),
            })),
        })),
    }
}

export async function generateBasicRecords(bundle: Bundle, requestId: string): Promise<Bundle> {
    console.log('# Generating record display names', { requestId, bundle })
    const result = await getChatCompletion(
        [
            {
                role: 'system',
                content: `You are a chat bot in an app builder.
Generate 3 sample records for each of the objects described in the following schema.
`,
            },
            { role: 'user', content: getTerseSchema(bundle, true) },
        ],
        undefined,
        zodToOpenAiResponseFormat(AppRecordsSchema, 'AppRecorddisplayNamesSchema')
    )

    const records = JSON.parse(result.content || '')

    console.log('# Generated basic records', records)

    for (const obj of records.objects) {
        const bundleObj = bundle.apps[0].objects.find((o) => o.id === obj.id)
        if (!bundleObj) {
            throw new Error(`Object ${obj.id} not found in bundle`)
        }

        bundleObj.records = obj.records.map((r) => ({
            recordId: r.id,
            fields: r.fields.map((value, idx) => ({ fieldId: bundleObj.fields[idx].id, value })),
        }))
    }

    await swapImages(bundle.apps[0])

    return bundle
}

export async function generateRecords(
    bundle: Bundle,
    objectId: string,
    requestId: string
): Promise<Bundle> {
    console.log('# Generating fields and records', { requestId, bundle })
    const existingRecords = getRecords(bundle)
    const result = await getChatCompletion(
        [
            {
                role: 'system',
                content: `YoThe schema for the current app is as follow:

                ${getTerseSchema(bundle)}

                The following records exist in the app:

                ${JSON.stringify(existingRecords.objects)}

Your job is to populate sample values for ALL the fields on the records specified by the user. 
Multi-relationship fields should be filled with csv list of target record ids.

For images, you will use our built in image handler: it supports three different options: company logos, male headshot, female headshot, other:

logos/1
male/1
female/1
other/1 

Increment the number at the end to ensure all records have different images. 
`,
            },
            {
                role: 'user',
                content:
                    existingRecords.objects
                        .find((o) => o.id === objectId)
                        ?.records.map((r) => r.id)
                        .join(', ') ?? '',
            },
        ],
        undefined,
        zodToOpenAiResponseFormat(AppRecordsSchema, 'AppRecordsSchema')
    )

    const records = JSON.parse(result.content || '') as AppRecordsSchemaType

    console.log('# Generated records', records)

    for (const obj of records.objects) {
        const bundleObj = bundle.apps[0].objects.find((o) => o.id === obj.id)
        if (!bundleObj) {
            throw new Error(`Object ${obj.id} not found in bundle`)
        }

        bundleObj.records = obj.records.map((r) => ({
            recordId: r.id,
            fields: r.fields.map((value, idx) => ({ fieldId: bundleObj.fields[idx].id, value })),
        }))
    }

    await swapImages(bundle.apps[0])

    return bundle
}
