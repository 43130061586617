import { useCallback, useMemo, useRef } from 'react'

import { ListViewUrlParams } from 'features/views/ListView/hooks/useListViewUrlParams'
import { useSyncListViewUrlParams } from 'features/views/ListView/hooks/useSyncListViewUrlParams'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { useSearchContext } from 'features/views/ListView/Search/SearchContext'

export function useCardViewSearch() {
    const { urlParams } = useListViewContext()

    const urlParamsRef = useRef(urlParams)
    urlParamsRef.current = urlParams

    const { query, setQuery } = useSearchContext()

    const onParamLoad = useCallback(
        (urlParams: ListViewUrlParams) => {
            const { searchQuery } = urlParams
            if (!searchQuery) return

            setQuery(searchQuery)
        },
        [setQuery]
    )
    const onParamSync = useCallback(
        (urlParams: ListViewUrlParams) => {
            urlParams.setParams({
                searchQuery: query,
            })
        },
        [query]
    )
    useSyncListViewUrlParams(onParamLoad, onParamSync)

    return useMemo(
        () => ({
            query,
            setQuery,
        }),
        [query, setQuery]
    )
}
