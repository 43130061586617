// @ts-strict-ignore
import { tokenMatcher } from 'chevrotain'
import { compact, flatten } from 'lodash'
import moment from 'moment'

import {
    ParsingError,
    UnexpectedArgumentsError,
    UnexpectedFunctionError,
} from './formulaFunctionErrors'
import { parser, TrueLiteral } from './formulaParser'
import { StackerNonOperatorFormulaSyntaxTree } from './formulaParserTypes'

const BaseVisitor = parser.getBaseCstVisitorConstructor()

const SUPPORTED_DATETIME_FORMATS = [
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm',
    'MM/DD/YYYY HH:mm:ss',
    'MM/DD/YYYY HH:mm',
]

const SUPPORTED_DATE_FORMATS = ['YYYY-MM-DD', 'MM/DD/YYYY']

type ParserParams = {
    requiresDatetimeLiteral?: boolean
}

type FunctionExpressionMap = Readonly<{
    [F in StackerNonOperatorFormulaSyntaxTree as F['function']]: (functionName: string, ctx) => any
}>

export class StackerFormulaVisitor extends BaseVisitor {
    constructor(private _object: ObjectDto | undefined) {
        super()
        this.validateVisitor()
    }

    formula(ctx, params: ParserParams) {
        return this.visit(ctx.orExpression, params)
    }

    genericOperatorExpression(functionName, ctx, syntax?, params?: ParserParams) {
        // Note: the left hand side should only have one element
        const lhs = ctx.lhs.map((x) => this.visit(x, params))

        if (ctx.rhs) {
            const rhs = ctx.rhs.map((x) => this.visit(x))
            return {
                function: functionName,
                arguments: flatten([...lhs, ...rhs]),
                syntax,
            }
        }

        return lhs[0]
    }

    orExpression(ctx, params?: ParserParams) {
        if (ctx.Or) {
            return this.genericOperatorExpression('OR', ctx, 'OR')
        }
        return this.visit(ctx.lhs[0], params)
    }

    andExpression(ctx, params?: ParserParams) {
        if (ctx.And) {
            return this.genericOperatorExpression('AND', ctx, 'AND')
        }
        return this.visit(ctx.lhs[0], params)
    }

    negationExpression(ctx, params: ParserParams) {
        if (ctx.Not) {
            const rhs = ctx.rhs.map((x) => this.visit(x))
            return {
                function: 'NOT',
                arguments: flatten([...rhs]),
                syntax: 'NOT',
            }
        }
        return this.visit(ctx.rhs[0], params)
    }

    comparisonExpression(ctx, params: ParserParams) {
        if (ctx.Equal) {
            return this.genericOperatorExpression('EQUALS', ctx, '=')
        }
        if (ctx.NotEqual) {
            return this.genericOperatorExpression('NOT_EQUALS', ctx, '!=')
        }
        if (ctx.LessThan) {
            return this.genericOperatorExpression('LT', ctx, '<')
        }
        if (ctx.GreaterThan) {
            return this.genericOperatorExpression('GT', ctx, '>')
        }
        if (ctx.LessThanEqual) {
            return this.genericOperatorExpression('LTE', ctx, '<=')
        }
        if (ctx.GreaterThanEqual) {
            return this.genericOperatorExpression('GTE', ctx, '>=')
        }
        return this.visit(ctx.lhs[0], params)
    }

    ampersandExpression(ctx, params: ParserParams) {
        return this.genericOperatorExpression('AMPERSAND', ctx, '&', params)
    }

    additionExpression(ctx, params: ParserParams) {
        return this.genericOperatorExpression('ADD', ctx, '+', params)
    }

    subtractionExpression(ctx, params: ParserParams) {
        return this.genericOperatorExpression('SUBTRACT', ctx, '-', params)
    }

    multiplicationExpression(ctx, params: ParserParams) {
        return this.genericOperatorExpression('MULTIPLY', ctx, '*', params)
    }

    divisionExpression(ctx, params: ParserParams) {
        return this.genericOperatorExpression('DIVIDE', ctx, '/', params)
    }

    powerExpression(ctx, params: ParserParams) {
        const lhs = ctx.lhs.map((x) => this.visit(x, params))

        if (ctx.rhs) {
            const rhs = ctx.rhs.map((x) => this.visit(x))
            return {
                function: 'POWER',
                base: lhs[0],
                exponent: rhs[0],
                syntax: '^',
            }
        }

        return lhs[0]
    }

    fieldItem(ctx) {
        const fieldLabel = ctx.FieldName[0].image.slice(1, -1) // Remove the {}
        const apiName = /\[(\w+)\]/.exec(fieldLabel)?.[1] // capture api_name

        if (this._object === undefined) {
            return {
                api_name: fieldLabel,
                function: 'FIELD',
            }
        }

        const fieldByLabel = this._object.fields.filter(
            (f) => f.label.toLowerCase() === fieldLabel.toLowerCase()
        )
        const fieldByApiName = this._object.fields.filter((f) => f.api_name === apiName)
        if (fieldByLabel.length === 0 && fieldByApiName.length === 0) {
            throw new ParsingError(`Cannot find field ${ctx.FieldName[0].image}`)
        } else if (fieldByLabel.length === 1) {
            return {
                api_name: fieldByLabel[0].api_name,
                function: 'FIELD',
            }
        } else if (fieldByApiName.length === 1) {
            return {
                api_name: fieldByApiName[0].api_name,
                function: 'FIELD',
            }
        } else {
            throw new ParsingError(`Cannot find field ${ctx.FieldName[0].image}`)
        }
    }

    numberExpression(ctx) {
        const multiplier = ctx.Minus ? -1 : 1
        return {
            value: multiplier * parseFloat(ctx.NumberLiteral[0].image),
            function: 'NUMBER',
        }
    }

    atomicExpression(ctx, params?: ParserParams) {
        if (ctx.StringLiteral) {
            const rawString = ctx.StringLiteral[0].image
            const isSingleQuote = rawString[0] === "'"
            const rawValue = rawString.substring(1, rawString.length - 1)
            const stringValue = isSingleQuote
                ? rawValue.replace(/\\'/g, "'")
                : rawValue.replace(/\\"/g, '"')

            // The parent function can require that their string literal children
            // must be parsed as dates or datetimes (depending on the format)
            if (params?.requiresDatetimeLiteral) {
                const parsedDatetime = moment(stringValue, SUPPORTED_DATETIME_FORMATS, true)
                const parsedDate = moment(stringValue, SUPPORTED_DATE_FORMATS, true)

                if (parsedDatetime.isValid()) {
                    return {
                        value: parsedDatetime.toISOString(),
                        function: 'DATETIME_LITERAL',
                    }
                } else if (parsedDate.isValid()) {
                    return {
                        value: parsedDate.format('YYYY-MM-DD'),
                        function: 'DATE_LITERAL',
                    }
                } else {
                    throw new ParsingError(`"${stringValue}" is not a valid date or date and time`)
                }
            }

            return {
                value: stringValue,
                function: 'STRING',
            }
        } else if (ctx.OpenStringLiteral) {
            const stringStart = ctx.OpenStringLiteral[0].image.substring(0, 10)
            throw new Error(`This quote is never closed: ...${stringStart}...`)
        } else if (ctx.BooleanLiteral) {
            const value = tokenMatcher(ctx.BooleanLiteral[0], TrueLiteral)
            return {
                value: value,
                function: 'BOOL',
            }
        } else if (ctx.fieldItem) {
            return this.visit(ctx.fieldItem)
        } else if (ctx.functionExpression) {
            return this.visit(ctx.functionExpression)
        } else if (ctx.parentheses) {
            return this.visit(ctx.parentheses)
        } else if (ctx.numberExpression) {
            return this.visit(ctx.numberExpression)
        } else {
            throw new ParsingError('Unexpected expression type in atomicExpression')
        }
    }

    functionExpressionMap: Omit<FunctionExpressionMap, 'DOCUMENT' | 'ARRAY'> = {
        ABS: this.unaryFunctionExpression,
        AND_FUN: this.nAryOperatorFunctionExpression,
        AVERAGE: this.nAryFunctionExpression,
        CEILING: this.roundFunctionExpression,
        CONCAT: this.nAryFunctionExpression,
        CREATED_AT: this.zeroaryFunctionExpression,
        CREATED_BY: this.zeroaryFunctionExpression,
        DATEADD: this.dateaddFunctionExpression,
        DATEDIF: this.datedifFunctionExpression,
        DATESTR: this.datestrFunctionExpression,
        DAY: this.datePartFunctionExpression,
        FIND: this.searchOrFindFunctionExpression,
        FORMAT_NUMBER: this.unaryFunctionExpression,
        FLOOR: this.roundFunctionExpression,
        HOUR: this.datePartFunctionExpression,
        IF: this.ifFunctionExpression,
        INT: this.unaryFunctionExpression,
        IS_AFTER: this.isBeforeOrIsAfterFunctionExpression,
        IS_BEFORE: this.isBeforeOrIsAfterFunctionExpression,
        IS_BLANK: this.unaryFunctionExpression,
        IS_SAME: this.isSameFunctionExpression,
        LAST_UPDATED_AT: this.zeroaryFunctionExpression,
        LAST_UPDATED_BY: this.zeroaryFunctionExpression,
        LEFT: this.leftOrRightFunctionExpression,
        LEN: this.unaryFunctionExpression,
        LOG: this.logFunctionExpression,
        LOWER: this.unaryFunctionExpression,
        MAX: this.nAryFunctionExpression,
        MIN: this.nAryFunctionExpression,
        MINUTE: this.datePartFunctionExpression,
        MOD: this.modFunctionExpression,
        MONTH: this.datePartFunctionExpression,
        NOT_FUN: this.nAryOperatorFunctionExpression, // it's a bit fun though
        NOW: this.zeroaryFunctionExpression,
        OR_FUN: this.nAryOperatorFunctionExpression,
        POWER: this.powerFunctionExpression,
        RECORD_ID: this.zeroaryFunctionExpression,
        REGEX_EXTRACT: this.regexMatchOrExtractFunctionExpression,
        REGEX_MATCH: this.regexMatchOrExtractFunctionExpression,
        REGEX_REPLACE: this.regexReplaceFunctionExpression,
        RIGHT: this.leftOrRightFunctionExpression,
        ROUND: this.roundFunctionExpression,
        ROUNDDOWN: this.roundFunctionExpression,
        ROUNDUP: this.roundFunctionExpression,
        SEARCH: this.searchOrFindFunctionExpression,
        SUM: this.nAryFunctionExpression,
        TODAY: this.zeroaryFunctionExpression,
        TRIM: this.unaryFunctionExpression,
        UPPER: this.unaryFunctionExpression,
        VALUE: this.unaryFunctionExpression,
        YEAR: this.datePartFunctionExpression,
        // Please keep this sorted alphabetically :-)
    }

    powerFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 2) {
            return {
                function: functionName,
                base: this.visit(ctx.formula[0]),
                exponent: this.visit(ctx.formula[1]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 2,
            })
        }
    }

    modFunctionExpression(functionName: string, ctx) {
        if (ctx.formula.length === 2) {
            return {
                function: functionName,
                dividend: this.visit(ctx.formula[0]),
                divisor: this.visit(ctx.formula[1]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 2,
            })
        }
    }

    logFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 2 || ctx.formula?.length === 1) {
            return {
                function: functionName,
                number: this.visit(ctx.formula[0]),
                base: this.visit(ctx.formula[1]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: [1, 2],
            })
        }
    }

    dateaddFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 3) {
            return {
                function: functionName,
                date: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
                number: this.visit(ctx.formula[1]),
                units: this.visit(ctx.formula[2]),
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 3,
                argumentHint: 'date, number, and units',
            })
        }
    }

    datedifFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 3) {
            return {
                function: functionName,
                date_1: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
                date_2: this.visit(ctx.formula[1], { requiresDatetimeLiteral: true }),
                units: this.visit(ctx.formula[2]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 3,
                argumentHint: 'two date arguments and a unit argument',
            })
        }
    }

    datePartFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length !== 1) {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 1,
            })
        }

        return {
            function: functionName,
            datetime: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
            syntax: functionName,
        }
    }

    datestrFunctionExpression(functionName: string, ctx) {
        if (ctx.formula && ctx.formula?.length !== 1) {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 1,
                argumentHint: 'date/datetime',
            })
        }

        return {
            function: functionName,
            datetime: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
        }
    }

    ifFunctionExpression(functionName: string, ctx) {
        return {
            function: functionName,
            condition: this.visit(ctx.formula[0]),
            then: this.visit(ctx.formula[1]),
            else: this.visit(ctx.formula[2]),
        }
    }

    isBeforeOrIsAfterFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length !== 2) {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 2,
            })
        }

        return {
            function: functionName,
            date_1: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
            date_2: this.visit(ctx.formula[1], { requiresDatetimeLiteral: true }),
            syntax: functionName,
        }
    }

    isSameFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length !== 3) {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 3,
            })
        }
        return {
            function: functionName,
            date_1: this.visit(ctx.formula[0], { requiresDatetimeLiteral: true }),
            date_2: this.visit(ctx.formula[1], { requiresDatetimeLiteral: true }),
            units: this.visit(ctx.formula[2]),
            syntax: functionName,
        }
    }

    leftOrRightFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 2) {
            return {
                function: functionName,
                string: this.visit(ctx.formula[0]),
                count: this.visit(ctx.formula[1]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 2,
                argumentHint: 'string and howMany',
            })
        }
    }

    searchOrFindFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 2 || ctx.formula?.length === 3) {
            return {
                function: functionName,
                substring: this.visit(ctx.formula[0]),
                string: this.visit(ctx.formula[1]),
                start_position: ctx.formula?.length === 3 ? this.visit(ctx.formula[2]) : undefined,
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: [2, 3],
                argumentHint: 'string, substring, and optionally start_position',
            })
        }
    }

    nAryFunctionExpression(functionName: string, ctx) {
        if (ctx.formula) {
            const argumentItems = ctx.formula?.map((i) => this.visit(i))
            return {
                function: functionName,
                arguments: compact([...argumentItems]),
                syntax: functionName,
            }
        }
    }

    nAryOperatorFunctionExpression(functionName: string, ctx) {
        if (ctx.formula) {
            const argumentItems = ctx.formula?.map((i) => this.visit(i))
            return {
                function: `${functionName}_FUN`,
                arguments: compact([...argumentItems]),
                syntax: functionName,
            }
        }
    }

    regexMatchOrExtractFunctionExpression(functionName: string, ctx) {
        if (ctx.formula) {
            return {
                function: functionName,
                string: this.visit(ctx.formula[0]),
                regex: this.visit(ctx.formula[1]),
            }
        }
    }

    regexReplaceFunctionExpression(functionName: string, ctx) {
        if (ctx.formula) {
            return {
                function: functionName,
                string: this.visit(ctx.formula[0]),
                regex: this.visit(ctx.formula[1]),
                replacement: this.visit(ctx.formula[2]),
            }
        }
    }

    roundFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length == 1) {
            return {
                function: functionName,
                number: this.visit(ctx.formula[0]),
            }
        } else if (ctx.formula?.length == 2) {
            return {
                function: functionName,
                number: this.visit(ctx.formula[0]),
                precision: this.visit(ctx.formula[1]),
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: [1, 2],
            })
        }
    }

    unaryFunctionExpression(functionName: string, ctx) {
        if (ctx.formula?.length === 1) {
            return {
                function: functionName,
                argument: this.visit(ctx.formula[0]),
                syntax: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
                expectedArgumentCount: 1,
            })
        }
    }

    zeroaryFunctionExpression(functionName: string, ctx) {
        if (!(ctx.formula?.length > 0)) {
            return {
                function: functionName,
            }
        } else {
            throw new UnexpectedArgumentsError({
                functionName,
            })
        }
    }

    functionExpression(ctx) {
        // Convert to upper case and remove the trailing `(`
        const functionName = ctx.FunctionName?.[0]?.image?.toUpperCase()?.slice(0, -1)
        // These functions have a _FUN suffix to distinguish them from operators.
        const functionKey = ['AND', 'OR', 'NOT'].includes(functionName)
            ? `${functionName}_FUN`
            : functionName
        const functionToApply = this.functionExpressionMap[functionKey]

        if (!functionToApply) {
            throw new UnexpectedFunctionError(functionName)
        } else {
            return functionToApply.apply(this, [functionName, ctx])
        }
    }

    parentheses(ctx) {
        const formula = this.visit(ctx.formula[0])
        return {
            ...formula,
            bracketed: true,
        }
    }
}
