// @ts-strict-ignore
import React from 'react'
import { render } from 'react-dom'

import './utils/dev-tools'

import Root from './app/Root'
import store from './app/store'
import { consumeLoadAction } from './features/loadActions'

import 'v2/ui/theme/styles.scss'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

function doRender(RootElem) {
    render(<RootElem store={store} />, document.getElementById('app'))
}

consumeLoadAction()
doRender(Root)
