import React, { useContext } from 'react'

import AppContext from 'app/AppContext'

import { LoadingSplash } from 'v2/ui'

import { FailedPage } from './FailedPage'

const MetadataLoading = ({ children, onlyShowOnInitialLoad = true }) => {
    const { isLoading, initialLoadComplete, loadingAccountFailed } = useContext(AppContext)

    if (!isLoading || (initialLoadComplete && onlyShowOnInitialLoad)) {
        // If we weren't able to load the account, show the error page.
        // IMPORTANT: we must not render the children in this case, because they
        // they will try to access the stacks, etc, and will trigger a refetch
        // which will put us back into a loading state, and we'll be stuck in
        // an infinite render/fetch loop.
        // NB: we only want to show this if we were never able to successfully load data
        // in the first place.
        if (loadingAccountFailed && !initialLoadComplete) {
            return <FailedPage />
        }

        return children
    }
    return <LoadingSplash />
}

export default MetadataLoading
