import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'

export function useTableViewPaginationState() {
    const { currentPageIndex, setCurrentPageIndex, pageSize, recordCount, isEmbedded } =
        useTableViewContext()

    const hasMoreThanOnePage = recordCount > pageSize
    const showPagination = isEmbedded ? hasMoreThanOnePage : true

    return useMemo(
        () => ({
            pageIndex: currentPageIndex,
            onChangePageIndex: setCurrentPageIndex,
            pageSize,
            totalItemsCount: recordCount,
            showPagination,
        }),
        [currentPageIndex, setCurrentPageIndex, pageSize, recordCount, showPagination]
    )
}
