import React, { useEffect, useRef, useState } from 'react'
import { Prompt, useHistory, useLocation } from 'react-router-dom'

import shortid from 'shortid'

import {
    registerEnterEditModeCheckpoint,
    registerExitEditModeCheckpoint,
    unregisterEnterEditModeCheckpoint,
    unregisterExitEditModeCheckpoint,
} from 'data/api/userActions'
import { withUser } from 'data/wrappers/WithUser'

import Heading from 'v2/ui/components/Heading'
import Icon from 'v2/ui/components/Icon'
import Modal from 'v2/ui/components/Modal'
import Text from 'v2/ui/components/Text'

const TransitionStatus = {
    // Admin or user attempted to navigate to a different page
    // through react-router
    INTERNAL_NAV_REQUEST: 'internal_nav_request',
    // Admin requested to enter edit mode
    ENTER_EDIT_MODE: 'enter-edit-mode',
    // Admin requested to close edit mode
    LEAVE_EDIT_MODE: 'leave-edit-mode',
    // No transition has been requested
    IDLE: 'idle',
}

const getDescription = ({ transition, endUserThemed }) => {
    return {
        [TransitionStatus.INTERNAL_NAV_REQUEST]: endUserThemed
            ? 'You’re about to leave this page with unsaved record changes. Would you like to save the changes?'
            : 'You’re about to leave this page with unsaved layout changes. Would you like to save the changes?',
        [TransitionStatus.LEAVE_EDIT_MODE]:
            'You’re about to leave edit mode with unsaved layout changes. Would you like to save the changes?',
        [TransitionStatus.ENTER_EDIT_MODE]:
            'You’re about to enter edit mode which will reset your record form. Would you like to save the changes?',
    }[transition]
}

const getStayOnPageLabel = ({ transition }) => {
    return {
        [TransitionStatus.INTERNAL_NAV_REQUEST]: 'Stay on this page',
        [TransitionStatus.LEAVE_EDIT_MODE]: 'Continue editing',
        [TransitionStatus.ENTER_EDIT_MODE]: 'Continue editing',
    }[transition]
}

export const UnsavedChangesModal = withUser(
    ({
        title = 'Unsaved Changes',
        description: customDescription,
        onSave,
        isDirty,
        userActions,
        revertChanges,
        endUserThemed = false,
        editingStartRequested,
        editingStopRequested,
        triggerOnHashChange,
    }) => {
        const [state, setState] = useState({
            transition: TransitionStatus.IDLE,
            modalVisible: false,
            confirmedNavigation: false,
            saved: false,
        })

        const EditModeCheckpointPromise = useRef()
        const [componentId] = useState(shortid.generate())

        const nextLocation = useRef()
        const history = useHistory()
        const currentLocation = useLocation()

        const shouldBlockNavigation = isDirty && !state.confirmedNavigation
        const description =
            customDescription ?? getDescription({ transition: state.transition, endUserThemed })

        const stayOnPageLabel = getStayOnPageLabel({ transition: state.transition })
        const blockExitEditMode = !endUserThemed
        const blockEnterEditModel = !!endUserThemed

        useEffect(() => {
            // This method gets called when the userApi is trying to enter or exit edit mode,
            // depending on which we've registered for
            const handleEditModeChange = () => {
                // If we are dirty, then we block the enter/exit by returning a promise that
                // will be resolved when our modal is closed.
                if (isDirty) {
                    return new Promise((resolve, reject) => {
                        EditModeCheckpointPromise.current = { resolve, reject }
                    })
                    // otherwise, don't block
                } else {
                    return Promise.resolve(true)
                }
            }

            // Depending on whether we're supposed to block entering or exiting edit mode with
            // this modal, register a handler to give us a chance to block.
            if (blockExitEditMode) {
                registerExitEditModeCheckpoint(handleEditModeChange)
            } else if (blockEnterEditModel) {
                registerEnterEditModeCheckpoint(handleEditModeChange)
            }

            return () => {
                // Make sure to unregister on unmount
                if (blockExitEditMode) {
                    unregisterExitEditModeCheckpoint(handleEditModeChange)
                } else if (blockEnterEditModel) {
                    unregisterEnterEditModeCheckpoint(handleEditModeChange)
                }
            }
        }, [isDirty, blockExitEditMode, blockEnterEditModel])

        // handles refreshing/going to external pages
        useEffect(() => {
            window.addEventListener('beforeunload', handleUnload)

            return () => window.removeEventListener('beforeunload', handleUnload)
        })

        //If we exit out of design view and have unsaved changes
        useEffect(() => {
            if (editingStopRequested && isDirty && !endUserThemed) {
                setState((prevState) => ({
                    ...prevState,
                    transition: TransitionStatus.LEAVE_EDIT_MODE,
                }))
                handleToggleDesignView()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [editingStopRequested])

        //If we enter design view and have unsaved changes
        useEffect(() => {
            if (editingStartRequested && isDirty && endUserThemed) {
                setState((prevState) => ({
                    ...prevState,
                    transition: TransitionStatus.ENTER_EDIT_MODE,
                }))
                handleToggleDesignView()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [editingStartRequested])

        // Confirmed navigation without save
        useEffect(() => {
            if (nextLocation.current) {
                history.push(nextLocation.current)
                return
            }

            // revert changes and stop editing
            if (state.confirmedNavigation && !state.saved) {
                if (revertChanges) {
                    revertChanges()
                }
            }

            if (state.confirmedNavigation && !isDirty) {
                setState((prevState) => ({
                    ...prevState,
                    confirmedNavigation: false,
                    saved: false,
                }))
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state.confirmedNavigation, isDirty])

        const showModal = (location) => {
            setState((prevState) => ({ ...prevState, modalVisible: true }))
            nextLocation.current = location
        }

        const closeModal = () => {
            // clear out the next location value since the user
            // opted to stay on this page. Otherwise we end up
            // navigating to it later when isDirty changes
            nextLocation.current = null
            setState((prevState) => ({
                ...prevState,
                modalVisible: false,
            }))
            // Cancel the edit mode transition
            EditModeCheckpointPromise.current?.resolve(false)
        }

        const navigate = (saved = false) => {
            setState((prevState) => ({
                ...prevState,
                confirmedNavigation: true,
                modalVisible: false,
                saved,
            }))
            // Unblock the edit mode transition
            EditModeCheckpointPromise.current?.resolve(true)
        }

        // We use react-router-dom's Prompt component to intercept
        // route changes when form is dirty. Note that Prompt does not
        // handle the unload event
        const handlePromptMessage = (nextLocation) => {
            // Prevent the modal from showing if the next location has this key in the state.
            if (nextLocation.state?.bypassUnsavedChangesModal) {
                return true
            }

            if (!shouldBlockNavigation) {
                return
            }
            const currentPath = currentLocation?.pathname
            const currentHash = currentLocation?.hash
            const nextPath = nextLocation?.pathname
            const nextHash = nextLocation?.hash
            let isSamePath = currentPath === nextPath
            if (triggerOnHashChange) {
                isSamePath = isSamePath && currentHash === nextHash
            }
            // Do not intercept if we somehow navigate to the same exact page
            if (isSamePath) return true
            setState((prevState) => ({
                ...prevState,
                transition: TransitionStatus.INTERNAL_NAV_REQUEST,
            }))
            showModal(nextLocation)
            return false
        }

        // It appears that it's not possible to display a custom dialog
        // for the beforeunload event, so the standard browser dialog will be used
        // https://stackoverflow.com/a/38880926/3287921
        const handleUnload = (event) => {
            if (!shouldBlockNavigation) {
                return
            }
            event.preventDefault()
            // Chrome requires returnValue to be set
            event.returnValue = ''
        }

        const handleToggleDesignView = () => {
            if (!shouldBlockNavigation) {
                return
            }
            showModal()
        }

        return (
            <>
                <Prompt when={isDirty} message={handlePromptMessage} />
                <LeavingModal
                    title={title}
                    description={description}
                    stayOnPageLabel={stayOnPageLabel}
                    isOpen={state.modalVisible}
                    nextLocation={nextLocation?.current}
                    endUserThemed={endUserThemed}
                    onSave={onSave}
                    onClose={closeModal}
                    onNavigate={navigate}
                    userActions={userActions}
                    editingStartRequested={editingStartRequested}
                    editingStopRequested={editingStopRequested}
                    componentId={componentId}
                />
            </>
        )
    }
)

const LeavingModal = ({
    isOpen,
    onSave,
    onClose,
    onNavigate,
    title,
    description,
    stayOnPageLabel,
    endUserThemed = false,
    userActions,
}) => {
    const [isSaving, setIsSaving] = useState(false)

    const processEditActions = () => {}

    const saveAndContinue = async () => {
        // Record saves return a promise
        if (endUserThemed) {
            setIsSaving(true)

            try {
                await onSave()
                processEditActions()
                onNavigate(true)
            } catch {
                stayOnPage()
            } finally {
                setIsSaving(false)
            }
        } else {
            // want to be sure and wait on any promise returned
            await onSave()
            processEditActions()
            onNavigate(true)
        }
    }

    const stayOnPage = () => {
        // discard the editing request for now
        userActions.discardEditingRequest()
        onClose()
    }

    const discard = () => {
        processEditActions()
        onNavigate()
    }

    return (
        <Modal
            showCloseButton={false}
            isOpen={isOpen}
            onClose={stayOnPage}
            size={'400px'}
            height={'434px'}
            body={
                <>
                    <Icon
                        size="56px"
                        icon="warning"
                        mb={4}
                        color="grey.200"
                        display="inline-block"
                    />
                    <Heading variant="modal" value={title} />
                    <Text variant="modalBody">{description}</Text>
                </>
            }
            actions={[
                {
                    label: 'Save and continue',
                    onClick: saveAndContinue,
                    variant: endUserThemed ? 'endUserPrimary' : 'adminPrimary',
                    buttonSize: 'md',
                    isLoading: isSaving,
                },
                {
                    label: 'Discard and continue',
                    onClick: discard,
                    variant: endUserThemed ? 'endUserSecondary' : 'adminSecondary',
                    buttonSize: 'md',
                    disabled: isSaving,
                },
                {
                    label: stayOnPageLabel,
                    onClick: stayOnPage,
                    variant: endUserThemed ? 'endUserClear' : 'adminClear',
                    buttonSize: 'sm',
                    disabled: isSaving,
                },
            ]}
        />
    )
}
