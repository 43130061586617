import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Spinner } from '@chakra-ui/react'

import AppUserContext from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'

import {
    Avatar,
    Box,
    Button,
    CloseButton,
    Divider,
    DropdownButton,
    Flex,
    Icon,
    LoadingScreen,
    Modal,
    Text,
} from 'v2/ui'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { Exit } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'

import { BANNER_HEIGHT } from './appBannerConstants'
import PreviewAsList from './PreviewAsList'

const colors = stackerTheme().colors

export const PreviewingBar = ({
    leftOffset = 0,
    buttonProps = {},
    showingAppBar,
    setIsBannerShowing,
}) => {
    const { role } = useContext(AppUserContext)
    const { stopPreviewing, isPreviewingAsUserOrRole, previewingAsUser } =
        usePreviewServiceContext()
    const { data: users = [], isLoading: usersLoading } = useAppUsersForAdmin(
        !!isPreviewingAsUserOrRole
    )
    const [isLoading, setIsLoading] = useState(false)
    const isSupportLoginPermitted = useIsSupportLoginPermitted()
    const history = useHistory()
    const {
        state: { key },
        setContextInfo: setSlidingPaneContextInfo,
    } = useSlidingPane()
    const { open } = useEditMode()

    const handleStop = useCallback(async () => {
        await stopPreviewing()
        history.push(getUrl(Urls.Home))
        setSlidingPaneContextInfo({ showSideNav: true })

        // If the sliding pane is open to the edit layout pane, then we need to start editing mode again
        if (key === 'edit-layout') {
            open()
        }
    }, [stopPreviewing, history, setSlidingPaneContextInfo, key, open])

    const keyDownHandler = useCallback(
        (event) => {
            if (event.key === 'Escape') {
                handleStop()
            }
        },
        [handleStop]
    )

    useEffect(() => {
        if (isPreviewingAsUserOrRole) {
            document.addEventListener('keydown', keyDownHandler)
        }

        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [isPreviewingAsUserOrRole, keyDownHandler])

    useEffect(() => {
        // If previewing as a user or role, show banner.
        if (isPreviewingAsUserOrRole) {
            setIsBannerShowing(true)
        } else {
            setIsBannerShowing(false)
        }
    }, [isPreviewingAsUserOrRole, setIsBannerShowing])

    if (!isPreviewingAsUserOrRole) return null

    const displayUser = previewingAsUser
        ? users.find((x) => x._sid === previewingAsUser._sid)
        : null

    const buttonContent = displayUser ? (
        <>
            <Avatar src={displayUser.avatar} name={displayUser.name} size="xs" mr={2} />
            <Text fontWeight="bold" color="gray.300">
                {displayUser.name || displayUser?.email}
            </Text>
        </>
    ) : (
        <>
            <Icon icon="userLock" size="sm" display="inline" mr={2} color="gray.300" />
            <Text fontSize="sm" display="inline" color="gray.300">
                {role?.label}
            </Text>
        </>
    )
    const overidesStyles = {
        bg: colors.userInterface.accent[1600],
        boxShadow: '0px 4px 6px 0 rgba(0, 0, 0, 0.2)',
    }

    return (
        <Flex
            justifyContent="space-between"
            position="fixed"
            px={3}
            top={0}
            right={0}
            left={showingAppBar ? leftOffset + 'px' : 0}
            height={BANNER_HEIGHT + 'px'}
            wrap="nowrap"
            zIndex={300}
            color={colors.userInterface.accent[200]}
            bg="black"
            {...overidesStyles}
        >
            <Box w="100%">
                <Flex w="100%">
                    <Icon icon="play" size="xs" display="inline" mr={2} />
                    <Text
                        color={colors.userInterface.accent[200]}
                        fontSize="sm"
                        display="inline"
                        mr={2}
                    >
                        Previewing as
                    </Text>
                    {isLoading || usersLoading ? (
                        <Spinner size="sm" verticalAlign="middle" />
                    ) : (
                        <DropdownButton
                            className={isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK}
                            buttonContent={buttonContent}
                            variant="clear"
                            buttonSize="smNoPadding"
                            px={0}
                            display="inline-flex"
                            bodyPadding={0}
                        >
                            <PreviewAsList
                                setIsLoading={setIsLoading}
                                maxHeight="400px"
                                width="300px"
                            />
                        </DropdownButton>
                    )}

                    <Button
                        mx="40px"
                        buttonSize="extraSmall"
                        icon={<Exit style={{ marginRight: '0.25rem' }} />}
                        iconAlign="left"
                        variant="Primary"
                        flexShrink={0}
                        px={3}
                        py={2}
                        onClick={handleStop}
                    >
                        Exit preview
                    </Button>
                </Flex>
            </Box>
            <Button
                buttonSize="sm"
                icon="x"
                variant="iconButton"
                flexShrink={0}
                px={3}
                py={2}
                color="gray.400"
                onClick={handleStop}
                {...buttonProps}
            />
        </Flex>
    )
}

const openFunction = {}

export function PreviewAsModal() {
    const [isLoading, setIsLoading] = useState()
    const [isOpen, setIsOpen] = useState()
    useEffect(() => {
        openFunction.setIsOpen = setIsOpen
    }, [])

    if (!isOpen) return null
    return (
        <Modal
            isOpen
            showCloseButton={false}
            onClose={() => setIsOpen(false)}
            noDividers
            noPadding
            size={'300px'}
            height={'400px'}
        >
            <Flex column wrap="nowrap" align="stretch" maxHeight="100%" height="100%">
                <Flex wrap="nowrap" marginLeft="16px">
                    <Text flexGrow={1} fontSize="smmd" fontWeight="bold" color="gray.500">
                        <span className={ONBOARDING_CLASSES.PREVIEW_AS_LIST}>Preview as...</span>
                    </Text>
                    <CloseButton onClick={() => setIsOpen(false)} />
                </Flex>
                <Divider />
                <LoadingScreen
                    isLoading={isLoading}
                    flexShrink={1}
                    minHeight={0}
                    flexGrow={1}
                    height="100%"
                >
                    <PreviewAsList
                        setIsLoading={setIsLoading}
                        maxHeight="100%"
                        onSelected={() => setIsOpen(false)}
                    />
                </LoadingScreen>
            </Flex>
        </Modal>
    )
}
