import React, { useCallback } from 'react'

import { useAppContext } from 'app/AppContext'
import { useObject, useObjects } from 'data/hooks/objects'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import ObjectPicker from 'features/studio/ui/ObjectPicker'
import { DEFAULT_TRIGGER_ICON, filterRecordDataFields } from 'features/workflows/common'
import { fieldToWorkflowExtraOptions } from 'features/workflows/fieldToWorkflowExtraOptions'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'

import { TriggerCondition } from './TriggerCondition'
import { WorkflowTriggerEditorComponent, WorkflowTriggerNodeRendererComponent } from './types'
import { getTriggerUserOutput } from './utils'

export const RecordChangeTriggerConfig: WorkflowTriggerEditorComponent = ({
    trigger,
    onChange,
}) => {
    const { selectedStack } = useAppContext()

    return (
        <>
            <ConfigurationLabel>Table</ConfigurationLabel>
            <ObjectPicker
                value={trigger.settings?.object_id}
                onChange={(item: { value: string }) => {
                    onChange({
                        settings: {
                            ...trigger.settings,
                            object_id: item.value,
                            stack_id: selectedStack?._sid,
                        },
                    })
                }}
                returnObject
                isClearable={false}
            />
        </>
    )
}

export const RecordChangeTriggerNode: WorkflowTriggerNodeRendererComponent = ({
    trigger,
    triggerType,
    showCondition,
    showType,
}) => {
    const { object } = useObject(trigger.settings?.object_id as string | undefined)

    return (
        <Box>
            <Box flex center>
                <ItemIcon
                    icon={triggerType?.icon || DEFAULT_TRIGGER_ICON}
                    iconStyle={triggerType?.icon_style}
                    mr="m"
                />
                <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
                    {showType && (
                        <Box fontSize="bodyXs" color="textWeakest">
                            Trigger
                        </Box>
                    )}
                    <Box>
                        {trigger.name}
                        {object && (
                            <>
                                {' '}
                                in
                                <Container
                                    as="span"
                                    ml="s"
                                    p="xs"
                                    borderRadius="s"
                                    variant="accentMuted"
                                >
                                    {object?.name}
                                </Container>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            {showCondition && <TriggerCondition trigger={trigger} />}
        </Box>
    )
}

export function useRecordChangeTriggerFinalSchema() {
    const { data: objects } = useObjects()
    const { data: schema } = useWorkflowSchema()
    const usersObject = useStackerUsersObject()

    return useCallback(
        (config: WorkflowTriggerConfig): WorkflowSchemaTriggerType | undefined => {
            const type = schema?.triggers.find((t) => t.id === config.trigger_type)
            const object = objects?.find((o) => o._sid === config.settings?.object_id)

            if (!type || !object) {
                return undefined
            }

            const isCreate = config.trigger_type === 'record_created'

            return {
                ...type,
                outputs: [
                    {
                        id: 'record_id',
                        name: isCreate
                            ? `New record in ${object.name}`
                            : `Updated record in ${object.name}`,
                        type: 'lookup',
                        extra_options: { link_target_object_id: object._sid },
                    },
                    getTriggerUserOutput(usersObject),
                    ...type.outputs.map((o) => {
                        if (['record', 'original_record', 'record_data'].includes(o.id)) {
                            const group = o as WorkflowSchemaStateItemGroup
                            return {
                                ...group,
                                items: group.items.concat(
                                    filterRecordDataFields(object?.fields).map((f) => ({
                                        id: f.api_name,
                                        name: f.label,
                                        type: f.type,
                                        extra_options: fieldToWorkflowExtraOptions(f),
                                    })) as WorkflowSchemaStateItem[]
                                ),
                            }
                        }
                        return o
                    }),
                ],
            }
        },
        [objects, schema?.triggers, usersObject]
    )
}
