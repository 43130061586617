import React, { useCallback, useImperativeHandle } from 'react'

import { Editor } from '@tiptap/core'
import { Range } from '@tiptap/react'
import { SuggestionProps } from '@tiptap/suggestion'

import { updateRecordQuery } from 'data/hooks/records/updateRecordQuery'
import useLDFlags from 'data/hooks/useLDFlags'
import { useGlobalSearchProvider } from 'features/Search/GlobalSearchProvider'
import { useRecentItemsSearchProvider } from 'features/Search/RecentItemsSearchProvider'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

import { SuggestionComponentHandle } from './extensionHelpers'

export type RecordSearchResult = {
    _sid: string
    url: string
    _stack_id?: string
    _object_id?: string
    _primary?: string
}

type ListProps = SuggestionProps<any> & {}

export const RecordsList = React.forwardRef<SuggestionComponentHandle, ListProps>(
    ({ query, editor, range }, ref) => {
        const { flags } = useLDFlags()

        const searchProvider = useGlobalSearchProvider({
            blockLinkClick: true,
            allowedItemTypes: ['record'],
        })
        const recentItemsProvider = useRecentItemsSearchProvider({ blockLinkClick: true })

        const providers = [
            ...(flags.search ? [searchProvider] : []),
            ...(flags.recentItems ? [recentItemsProvider] : []),
        ]

        const onItemSelected = useCallback(
            (item) => {
                insertRecordLink(editor, range, item)
            },
            [editor, range]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: providers,
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))

        const { isLoading, collections, showMore, items } = itemsState

        return (
            <ComboboxContext.Provider value={comboboxState}>
                <Container
                    {...comboboxState.getMenuProps(undefined, { suppressRefError: true })}
                    maxWidth="100vw"
                    maxHeight="66vh"
                >
                    {!query && (
                        <Box flex center px="l" py="l" color="textWeakest">
                            <Icon name="Search" mr="m" /> Start typing to search for records...
                        </Box>
                    )}
                    {query && !isLoading && !items.length && (
                        <Box py="l" px="xl">
                            No results found
                        </Box>
                    )}
                    {!query && items.length > 0 && <Divider mb="xs" />}
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />

                    {isLoading && (
                        <Box py="l" px="xl" flex center color="textWeakest">
                            <LoadingIndicator />
                        </Box>
                    )}
                </Container>
            </ComboboxContext.Provider>
        )
    }
)

export function insertRecordLink(editor: Editor, range: Range, item: RecordSearchResult) {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')

    if (overrideSpace) {
        range.to += 1
    }
    // drop this record info into the record cache incase it isn't already
    // there. This ensures that the record link plugin will be able to render
    // it straight away without hitting the server
    updateRecordQuery(
        [
            {
                _sid: item._sid,
                _object_id: item._object_id!,
                _primary: item._primary,
                _partial: true,
            },
        ],
        false,
        undefined
    )

    queueMicrotask(() => {
        editor
            .chain()
            .focus()
            .insertContentAt(range, [
                {
                    type: 'recordLink',
                    attrs: {
                        id: item._sid,
                        stack_id: item._stack_id,
                        url: item.url,
                    },
                },
                { type: 'text', text: ' ' },
            ])
            .run()

        window.getSelection()?.collapseToEnd()
    })
}
