import React from 'react'
import { useHistory } from 'react-router-dom'

import { Urls } from 'app/UrlService'
import { AiAppBuilder } from 'features/AiPlayground/AiAppBuilder'
import { DefaultHeaderMeta } from 'features/core/DefaultHeaderMeta'

import { Box } from 'ui/components/Box'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

export function OnboardingStartPage() {
    const history = useHistory()
    const handleFinish = () => {
        history.replace(Urls.Root)
    }

    return (
        <ThemeProvider theme="admin">
            {/*@ts-expect-error*/}
            <DefaultHeaderMeta title="Get started with Stacker" />
            <Box
                fullWidth
                height="100dvh"
                flex
                column
                center
                justifyContent="center"
                px={{ desktopLarge: '10xl' }}
                py={{ desktopLarge: '5xl' }}
            >
                <Box
                    p={{ mobile: 'xl', tablet: '4xl' }}
                    borderStyle="base"
                    borderWidth={{ mobile: 0, desktopLarge: 'base' }}
                    boxShadow={{ desktopLarge: 'xl' }}
                    minWidth={{ mobile: 'full' }}
                    width={{ mobile: 'full' }}
                    rounded="4xl"
                    flex
                    column
                    stretch
                    grow
                >
                    <AiAppBuilder onClose={handleFinish} />
                </Box>
            </Box>
        </ThemeProvider>
    )
}
