import React, { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'
import { RequestMethodDropdown } from 'features/workflows/RequestMethodDropdown'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'

import { WorkflowActionEditorComponent } from './types'

export const APIRequestActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const requestMethod = getRequestMethod(action)
    const responseSchema = action.settings?.response_schema as WorkflowOutputSchema | undefined

    const handleChangeSettings = (patch: Record<string, unknown>) => {
        onChange({
            settings: {
                ...action.settings,
                ...patch,
            },
        })
    }

    const handleChangeRequestMethod = (value: string) => {
        const patch: Record<string, unknown> = {
            settings: { ...action.settings, request_method: value },
        }

        const supportsBody = supportsRequestMethodBody(value)
        if (!supportsBody) {
            const newInputs: WorkflowActionConfigInput[] = action.inputs.map((input) => {
                if (input.id === 'body') {
                    return {
                        ...input,
                        value: {
                            input_content: null,
                            value: undefined,
                        },
                    } as WorkflowActionConfigInput
                }

                return input
            })
            patch.inputs = newInputs
        }

        onChange(patch)
    }

    return (
        <>
            <Box flex center gap="m">
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Request method</ConfigurationLabel>
                    <RequestMethodDropdown
                        value={requestMethod}
                        onChange={handleChangeRequestMethod}
                    />
                </Box>
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Response format</ConfigurationLabel>
                    JSON
                </Box>
            </Box>
            <ConfigurationLabel>Response attributes</ConfigurationLabel>
            <OutputSchemaEditor
                value={responseSchema}
                onChange={(value) => handleChangeSettings({ response_schema: value })}
                mb="l"
            />
        </>
    )
}

export function useAPIRequestActionFinalSchema() {
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            if (!type) return undefined

            const responseSchema = config.settings?.response_schema as
                | WorkflowOutputSchema
                | undefined

            const stateItems = responseSchema ? convertOutputSchemaToOutputs(responseSchema) : []

            const inputs = type.inputs.slice()
            const requestMethod = getRequestMethod(config)
            if (supportsRequestMethodBody(requestMethod)) {
                inputs.push({
                    id: 'body',
                    name: 'Body',
                    type: 'string',
                    is_required: false,
                })
            }

            return {
                ...type,
                state: stateItems,
                inputs,
            }
        },
        [schema?.nodes]
    )
}

function supportsRequestMethodBody(method: string): boolean {
    switch (method) {
        case 'POST':
        case 'PUT':
        case 'PATCH':
            return true
        default:
            return false
    }
}

function getRequestMethod(config: WorkflowActionConfig): string {
    return (config.settings?.request_method as string | undefined) ?? 'GET'
}
