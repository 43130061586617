import React from 'react'

import { isEqual } from 'lodash'

import { Users } from 'features/views/attributes/Users'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { useAppUsersGroupListGroupMembersState } from './hooks/useAppUsersGroupListGroupMembersState'
import { useAppUsersGroupListRowState } from './hooks/useAppUsersGroupListRowState'
import { useAppUsersGroupListState } from './hooks/useAppUsersGroupListState'
import { AppUsersListEmptyValue } from './AppUsersListEmptyValue'
import { AppUsersRoleDropdown } from './AppUsersRoleDropdown'
import { AppUsersSearchBar } from './AppUsersSearchBar'
import { AppUsersUserList } from './AppUsersUserList'
import { AppGroup, AppRole } from './types'

import {
    AppUsersListCellStyles,
    AppUsersListHeaderStyles,
    AppUsersListRowStyles,
} from './AppUsers.css'

const MAX_GROUP_NAME_LENGTH = 30
const MAX_USER_LENGTH = 30
const PAGE_SIZE = 12

type AppUsersGroupListProps = {
    groups: AppGroup[]
    roles: AppRole[]
    isLoading?: boolean
}

export const AppUsersGroupList: React.FC<AppUsersGroupListProps> = ({
    groups,
    roles,
    isLoading,
}) => {
    const { rows } = useAppUsersGroupListState({ groups, isLoading })

    return (
        <Box
            display="grid"
            height="full"
            width="full"
            style={{
                gridTemplateColumns: '1fr 1fr 1fr auto',
            }}
        >
            <Heading />
            {rows.map((row, index) => (
                <Row key={index} row={row} isLoading={isLoading} roles={roles} />
            ))}
        </Box>
    )
}

type HeadingProps = {}

const Heading: React.FC<HeadingProps> = () => {
    return (
        <Box className={AppUsersListRowStyles.styleFunction()}>
            <Box className={AppUsersListHeaderStyles.styleFunction()}>
                <Body size="m" weight="medium">
                    Group name
                </Body>
            </Box>
            <Box className={AppUsersListHeaderStyles.styleFunction()}>
                <Body size="m" weight="medium">
                    Members
                </Body>
            </Box>
            <Box
                className={AppUsersListHeaderStyles.styleFunction()}
                justifyContent="flex-end"
                style={{
                    paddingRight: theme.space['3xl'],
                }}
            >
                <Body size="m" weight="medium">
                    Role
                </Body>
            </Box>
            <Box className={AppUsersListHeaderStyles.styleFunction()} />
        </Box>
    )
}

type RowProps = {
    row: AppGroup
    roles: AppRole[]
    isLoading?: boolean
}

const Row: React.FC<RowProps> = React.memo(function Row({
    row,
    roles,
    isLoading: providedIsLoading,
}) {
    const {
        isOpen,
        onOpenChange,
        collapsibleIcon,
        collapsibleLabel,
        searchQuery,
        users,
        onChangeSearchInput,
        supportedRoles,
        totalUserCount,
        isError,
        hasNoUsers,
        hasNoUsersMatchingSearch,
        isLoading,
        pageIndex,
        setPageIndex,
        goToTableGroupSettings,
    } = useAppUsersGroupListRowState({
        group: row,
        roles,
        isLoading: providedIsLoading,
        pageSize: PAGE_SIZE,
    })

    return (
        <Box
            as={Collapsible}
            className={AppUsersListRowStyles.styleFunction({ isLoading })}
            open={isOpen}
            onOpenChange={onOpenChange}
        >
            <Box
                flex
                center
                gap="m"
                className={AppUsersListCellStyles.styleFunction({
                    borderless: isOpen,
                    hoverable: !isOpen,
                })}
            >
                <Skeleton isLoading={isLoading}>
                    <Avatar
                        size="s"
                        type="icon"
                        icon={{
                            name: 'UsersRound',
                        }}
                        shape="square"
                    />
                </Skeleton>
                {row.name ? (
                    <Skeleton isLoading={isLoading} isInline>
                        <Body size="m" weight="bold" maxLength={MAX_GROUP_NAME_LENGTH}>
                            {row.name}
                        </Body>
                    </Skeleton>
                ) : (
                    <AppUsersListEmptyValue isLoading={isLoading} />
                )}
                {!!row.objectName && (
                    <Tag
                        size="2xs"
                        type="solid"
                        shape="rectangle"
                        color="Neutral"
                        shade="light"
                        startIcon={{ name: 'Link2' }}
                        pointerEvents="none"
                    >
                        {row.objectName}
                    </Tag>
                )}
                {!row.isWorkspaceGroup && (
                    <Button
                        variant="ghost"
                        size="2xs"
                        aria-label="Go to table group settings"
                        startIcon={{ name: 'Settings' }}
                        onClick={goToTableGroupSettings}
                        disabled={isLoading}
                    />
                )}
            </Box>
            <Box
                className={AppUsersListCellStyles.styleFunction({
                    borderless: isOpen,
                    hoverable: !isOpen,
                })}
            >
                <GroupMembers group={row} isLoading={isLoading} />
            </Box>
            <Box
                className={AppUsersListCellStyles.styleFunction({
                    borderless: isOpen,
                    hoverable: !isOpen,
                })}
                justifyContent="flex-end"
                style={{
                    paddingRight: row.isWorkspaceGroup ? theme.space.l : theme.space.xl,
                }}
            >
                <Skeleton isLoading={isLoading}>
                    <AppUsersRoleDropdown group={row} roles={supportedRoles} />
                </Skeleton>
            </Box>
            <Box
                className={AppUsersListCellStyles.styleFunction({
                    borderless: isOpen,
                    hoverable: !isOpen,
                })}
            >
                <CollapsibleTrigger asChild>
                    <Button
                        variant="secondary"
                        startIcon={{ name: collapsibleIcon }}
                        aria-label={collapsibleLabel}
                        size="xs"
                        disabled={isLoading}
                    />
                </CollapsibleTrigger>
            </Box>
            <Box
                style={{ gridColumn: '1 / -1' }}
                className={AppUsersListCellStyles.styleFunction({
                    isExpandable: true,
                    borderless: !isOpen,
                    hoverable: false,
                })}
            >
                <CollapsibleContent pt="m" pb="xl" width="full">
                    <Box
                        p="xl"
                        pb={0}
                        rounded="2xl"
                        background="gray50"
                        width="full"
                        flex
                        gap="m"
                        column
                    >
                        <AppUsersSearchBar
                            value={searchQuery}
                            onChange={onChangeSearchInput}
                            autoFocus={isOpen}
                        />
                        {hasNoUsersMatchingSearch && (
                            <Box py="m">
                                <Body color="textWeakest">No users found matching your search</Body>
                            </Box>
                        )}
                        {hasNoUsers && (
                            <Box py="m">
                                <Body color="textWeakest">There are no users to display</Body>
                            </Box>
                        )}
                        {isError && (
                            <Box py="m">
                                <Body color="textError">There was a problem fetching users</Body>
                            </Box>
                        )}
                        {!hasNoUsersMatchingSearch && !hasNoUsers && !isError && !!users && (
                            <AppUsersUserList
                                isLoading={isLoading}
                                showLastRowBorder={false}
                                pageSize={PAGE_SIZE}
                                users={users}
                                totalUserCount={totalUserCount}
                                pageIndex={pageIndex}
                                onChangePageIndex={setPageIndex}
                            />
                        )}
                    </Box>
                </CollapsibleContent>
            </Box>
        </Box>
    )
},
isEqual)

type GroupMembersProps = {
    group: AppGroup
    isLoading?: boolean
}

export const GroupMembers: React.FC<GroupMembersProps> = ({ group, isLoading }) => {
    const { users, isFetchingSlow, hasNoUsers, hasNoUsersMatchingSearch, isError } =
        useAppUsersGroupListGroupMembersState({ group })

    const effectiveIsLoading = isLoading || isFetchingSlow

    if (isError) {
        return <Icon name="AlertCircle" color="iconError" size="m" />
    }

    if (hasNoUsers || hasNoUsersMatchingSearch) {
        return <AppUsersListEmptyValue isLoading={isLoading} />
    }

    return (
        <Skeleton isLoading={effectiveIsLoading}>
            <Box
                style={{
                    marginTop: '-2px',
                    marginBottom: '-2px',
                }}
            >
                <Users
                    value={users}
                    avatarSize="s"
                    isLoading={effectiveIsLoading}
                    size="m"
                    maxLength={MAX_USER_LENGTH}
                    showTooltips={false}
                    showSingleUserName={false}
                />
            </Box>
        </Skeleton>
    )
}
