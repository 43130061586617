import { useMemo } from 'react'

import {
    DEFAULT_MAX_ITEMS,
    HARD_LIMIT_MAX_ITEMS,
    LINE_CHART_MAX_ITEMS,
} from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock/constants'
import { useMetricConfiguration } from 'v2/blocks/blockTypes/view/aggregationBlocks/hooks/useMetricConfiguration'

import { useFields } from 'data/hooks/fields'
import { DATE_TYPES } from 'features/admin/fields/common'

const ALLOWED_GROUPING_CHART_TYPES = ['Bar', 'Line']

export function useChartConfiguration(
    chart: ChartBlockAttributes,
    view: ViewDto,
    record: RecordDto | {}
): MetricsQueryRequest {
    const { data: fields } = useFields()
    const groupField = fields?.find((f) => f._sid === chart.groupByFieldId)
    const metricConfiguration = useMetricConfiguration(chart, view, record)

    // we need to filter empty primary groups for lines charts
    const lineChartFilters: ChartFilter[] = useMemo(
        () =>
            chart.type === 'Line'
                ? [
                      {
                          operation: 'isnotempty',
                          option: 'isNotEmpty',
                          target: groupField?.api_name,
                          value: '',
                      },
                  ]
                : [],
        [chart.type, groupField?.api_name]
    )

    const sort: ChartSort = useMemo(() => {
        if (chart.sort) {
            return chart.sort
        } else if (groupField?.type && DATE_TYPES.includes(groupField?.type)) {
            return { type: 'label', order: 'desc' }
        } else {
            return { type: 'label', order: 'asc' }
        }
    }, [chart.sort, groupField?.type])

    return useMemo(() => {
        const lineChartMaxItems =
            metricConfiguration.filters.length > 0 ? HARD_LIMIT_MAX_ITEMS : LINE_CHART_MAX_ITEMS
        return {
            ...metricConfiguration,
            chart_type: chart.type || 'Pie',
            filters: [...metricConfiguration.filters, ...lineChartFilters],
            group: chart.groupByFieldId
                ? {
                      type: 'value' as ChartGroupType,
                      group_field_sid:
                          chart.type && ALLOWED_GROUPING_CHART_TYPES.includes(chart.type)
                              ? chart.additionalGroupFieldId
                              : undefined,
                      field_sid: chart.groupByFieldId,
                      fieldType: groupField?.type,
                      bucketBy:
                          groupField?.type && DATE_TYPES.includes(groupField?.type)
                              ? chart.bucketBy
                              : undefined,
                  }
                : { type: 'all' as ChartGroupType },
            sort: sort,
            limit:
                chart.type === 'Line'
                    ? lineChartMaxItems
                    : chart.display.limitNumberItems ?? DEFAULT_MAX_ITEMS,
        }
    }, [
        chart.additionalGroupFieldId,
        chart.groupByFieldId,
        chart.type,
        metricConfiguration,
        chart.bucketBy,
        groupField?.type,
        chart.display.limitNumberItems,
        lineChartFilters,
        sort,
    ])
}
