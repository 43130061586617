import { useCallback, useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25

type UseBoardViewAddNewCardFieldOptions = {
    field: FieldDto
    onChange: (value: unknown) => void
}

export function useBoardViewAddNewCardFieldState(options: UseBoardViewAddNewCardFieldOptions) {
    const { field, onChange } = options

    const { view, statusField, embeddedByField } = useBoardViewContext()

    const column = view.options.columns.find((column) => column.fieldId === field?._sid)
    const label = truncateText(column?.label || field.label, MAX_LABEL_LENGTH)

    const onClear = useCallback(() => {
        onChange(null)
    }, [onChange])

    const isEditable = statusField?._sid !== field._sid && embeddedByField?._sid !== field._sid

    return useMemo(
        () => ({
            label,
            onClear,
            isEditable,
        }),
        [isEditable, label, onClear]
    )
}
