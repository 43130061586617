import React from 'react'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

import { useConfirmDeleteWorkflowModal } from './useConfirmDeleteWorkflowModal'
import { useDuplicateWorkflowModal } from './useDuplicateWorkflowModal'
import { useWorkflowManagerContext } from './WorkflowManagerContext'

type WorkflowActionDropdownProps = {
    workflow: WorkflowDto
    onDelete: () => void
    onDuplicate: (workflow: WorkflowDto) => void
}

export const WorkflowActionDropdown: React.FC<WorkflowActionDropdownProps> = ({
    workflow,
    onDelete,
    onDuplicate,
}) => {
    const { navigateToPane } = useWorkflowManagerContext()
    const showConfirmDeleteModal = useConfirmDeleteWorkflowModal({ onDelete })
    const showDuplicateModal = useDuplicateWorkflowModal(onDuplicate)

    return (
        <Dropdown>
            <DropdownButton variant="ghost" startIcon={{ name: 'MoreVertical' }} size="s" />
            <DropdownContent align="end">
                <DropdownItem
                    startIcon={{ name: 'Clock' }}
                    onClick={() => navigateToPane('editorRunHistory')}
                    label="Run history"
                />
                <DropdownItem
                    startIcon={{ name: 'Copy' }}
                    onClick={() => showDuplicateModal(workflow)}
                    label="Duplicate workflow"
                />
                <DropdownItem
                    startIcon={{ name: 'Trash' }}
                    onClick={() => showConfirmDeleteModal(workflow._sid)}
                    label="Delete workflow"
                />
            </DropdownContent>
        </Dropdown>
    )
}
