import { useCallback, useMemo, useRef, useState } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { getAvatarFromUserRecord } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { useLookupRecords } from 'features/views/ListView/hooks/useLookupRecords'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { useBoardViewAddNewCardFieldState } from './useBoardViewAddNewCardFieldState'

const MAX_LABEL_LENGTH = 25
const MAX_ITEMS = 2
const DEBOUNCE_RATE = 200

type UseBoardViewAddNewCardFieldRecordLinksOptions = {
    field: FieldDto
    value: string | string[]
    onChange: (value?: string | string[]) => void
}

type OptionValue = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

export function useBoardViewAddNewCardFieldRecordLinksState(
    options: UseBoardViewAddNewCardFieldRecordLinksOptions
) {
    const { field, value, onChange } = options

    const {
        label: fieldLabel,
        onClear,
        isEditable,
    } = useBoardViewAddNewCardFieldState({ field, onChange })

    const currentValue = getCurrentValue(value)
    const internalValue = useDeepEqualsMemoValue(currentValue)
    const effectiveValue = useMemo(() => new Set(internalValue), [internalValue])
    const effectiveValueRef = useRef(effectiveValue)
    effectiveValueRef.current = effectiveValue

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(setDebouncedSearchQuery, DEBOUNCE_RATE)

    const handleChangeSearchQuery = useCallback(
        (query: string) => {
            setSearchQuery(query)
            applyDebouncedSearchQuery(query)
        },
        [applyDebouncedSearchQuery]
    )

    const { isFetchingSlow, isError, records } = useLookupRecords({
        field,
        searchQuery: debouncedSearchQuery,
        alwaysShowValueRecords: true,
        valueRecordSids: internalValue,
    })

    const usersObject = useStackerUsersObject()

    const editOptions = isFetchingSlow ? PLACEHOLDER_OPTIONS : makeOptions(records, usersObject)

    const isSingle = field.type === 'lookup'

    const onEditValue = useCallback(
        (value: string, isChecked: boolean) => {
            if (field.type === 'lookup') {
                // Just replace the value.
                onChange(value)
            } else {
                const existingValue = effectiveValueRef.current
                const newValue = new Set(existingValue)

                // Add or remove the value from the list of values.
                if (isChecked) {
                    newValue.add(value)
                } else {
                    newValue.delete(value)
                }

                onChange(Array.from(newValue))
            }
        },
        [field.type, onChange]
    )

    const label = formatLabel(effectiveValue, editOptions, fieldLabel)

    const fieldIcon = useDeepEqualsMemoValue(getFieldIcon(field))

    return useMemo(
        () => ({
            label,
            onClear,
            isSingle,
            editOptions,
            onEditValue,
            isError,
            isFetchingSlow,
            value: effectiveValue,
            isEditable,
            searchQuery,
            setSearchQuery: handleChangeSearchQuery,
            fieldIcon,
        }),
        [
            label,
            onClear,
            isSingle,
            editOptions,
            onEditValue,
            isError,
            isFetchingSlow,
            effectiveValue,
            isEditable,
            searchQuery,
            handleChangeSearchQuery,
            fieldIcon,
        ]
    )
}

function getCurrentValue(value?: string | string[]) {
    if (Array.isArray(value)) {
        return value
    }

    return value ? [value] : []
}

function formatLabel(value: Set<string>, editOptions: OptionValue[], defaultLabel: string) {
    if (value.size < 1) {
        return defaultLabel
    }

    const activeOptions = editOptions.filter((option) => value.has(option.value))

    const overflowingItemCount = activeOptions.length - MAX_ITEMS
    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    const formattedItems = truncatedOptions.map((option) => option.label).join(', ')

    if (overflowingItemCount > 0) {
        return `${formattedItems} +${overflowingItemCount}`
    }

    return formattedItems
}

function makeOptions(records?: RecordDto[], usersObject?: ObjectDto): OptionValue[] {
    if (!records) {
        return []
    }

    const options = records.map((record) => {
        const avatar = getAvatarFromUserRecord(record, usersObject)

        const recordPrimary = record?._primary || ''
        const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()

        return {
            label: truncateText(label, MAX_LABEL_LENGTH),
            value: record._sid,
            avatar,
        }
    })

    // Sort by label alphabetically.
    options.sort((a, b) => a.label.localeCompare(b.label))

    return options
}

const PLACEHOLDER_OPTIONS: OptionValue[] = [
    { label: 'Loading all options...', value: 'loading1' },
    { label: 'Loading all options...', value: 'loading2' },
    { label: 'Loading all options...', value: 'loading3' },
    { label: 'Loading all options...', value: 'loading4' },
    { label: 'Loading all options...', value: 'loading5' },
    { label: 'Loading all options...', value: 'loading6' },
]
