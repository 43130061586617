import { useAppContext } from 'app/AppContext'
import { useLocalStorageState } from 'utils/useLocalStorageState'

export const MAX_COLUMNS = 20
export const MAX_CARDS = 20

type KanbanStatus = {
    value?: string
    label?: string
    isEmpty?: boolean
    color?: string
}

export const getDisplayedStatuses = (field: FieldDto, statusColumns: (string | null)[]) => {
    if (!field && (!statusColumns || statusColumns.includes(null))) return null

    const statuses = field?.options?.options && [...field.options.options]

    // If the user asked us to include a column for records with no status value,
    // do so now and insert at the top of the list
    if (statuses && (!statusColumns || statusColumns.includes(null))) {
        statuses.unshift({
            label: 'None',
            value: '',
        })
    }

    // This are the statuses the user has optied to display in this view.
    // Note we limit the number of visible columns regardless of what the user
    // has selected so they don't get in a visually broken state.
    const displayedStatuses =
        statuses &&
        statuses
            .filter((x: KanbanStatus) => {
                if (!statusColumns || statusColumns.length === 0) return true
                if (statusColumns.includes(x.value!)) return true
                if (statusColumns.includes(null) && x.value!.length === 0) return true

                return false
            })
            .slice(0, MAX_COLUMNS)

    return displayedStatuses
}

/*
    This routine returns a filter object that fetches only
    the cards that should be displayed on the board
*/
export const getKanbanFilters = (
    object: ObjectDto,
    statusFieldId: string,
    statusColumns: string[]
) => {
    if (!object || !statusFieldId) return

    const field = object.fields.find((x) => x._sid === statusFieldId)
    const displayedStatuses = getDisplayedStatuses(field, statusColumns!)

    if (displayedStatuses) {
        let filterValues = displayedStatuses.map((x: KanbanStatus) => x.value)
        let filterOperator = 'oneOf'

        // If we are including the None column (cards with no status value)
        if (displayedStatuses.find((x: KanbanStatus) => x.value === null || x.value === '')) {
            // the filter operation changes to noneOf the columns
            // not on the board, so that cards with no fied value are included
            filterValues =
                field?.options?.options
                    .filter((x: KanbanStatus) => !displayedStatuses.includes(x))
                    .map((x: KanbanStatus) => x.value) ?? []
            filterOperator = 'noneOf'
        }

        // Do not filter if the value is empty
        // but do send through the field name for includefields
        if (!filterValues.length) {
            return [
                {
                    field: { api_name: field.api_name },
                    options: {},
                },
            ]
        }

        return [
            {
                field: { api_name: field.api_name },
                options: {
                    value: filterValues,
                    option: filterOperator,
                },
            },
        ]
    }

    return
}

// If the view options allow the end user to customize the kanban settings,
// then we need to store the user's selections in local storage
export function useKanbanSettings({
    viewId,
    viewOptions,
}: {
    viewId: string
    viewOptions?: ListViewOptions
}) {
    const { selectedStack } = useAppContext()
    const [selectedStatusFieldId, setSelectedStatusFieldId] = useLocalStorageState(
        `${selectedStack?._sid}_${viewId}_kanban_status_field`
    )

    const [selectedStatusColumns, setSelectedStatusColumns] = useLocalStorageState(
        `${selectedStack?._sid}_${viewId}_${selectedStatusFieldId}_kanban_columns`
    )

    // Only use the stored values if the user is allowed to customize it,
    // and default to what's stored in the view options.
    const effectiveStatusFieldId = viewOptions?.userCanUpdateStatusField
        ? selectedStatusFieldId || viewOptions?.statusField
        : viewOptions?.statusField

    // Only default to the columns stored in view options if it is for
    // the same field as is also stored in view options.
    const defaultColumns =
        effectiveStatusFieldId === viewOptions?.statusField ? viewOptions?.statusColumns : undefined

    // Only use the stored values if the user is allowed to customize it
    const effectiveStatusColumns = viewOptions?.userCanUpdateStatusColumns
        ? selectedStatusColumns || defaultColumns
        : defaultColumns
    return {
        selectedStatusFieldId: effectiveStatusFieldId,
        selectedStatusColumns: effectiveStatusColumns,
        setSelectedStatusFieldId,
        setSelectedStatusColumns,
    }
}

export function prefillRelatedRecordData(
    record: Partial<RecordDto>,
    field?: FieldDto,
    value?: string
) {
    if (!field || !value) return

    if (field.type === 'multi_lookup') {
        record[field.api_name] = [value]
    } else {
        record[field.api_name] = value
    }
}
