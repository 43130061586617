import React, { forwardRef } from 'react'

import type { DropdownMenuSubTriggerProps } from '@radix-ui/react-dropdown-menu'

import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { StandardComponentProps } from 'ui/helpers/styles'

import { LeftSlot, MenuIcon, RightSlot, SubTrigger } from './Dropdown.parts'
import { DropdownEndIndicatorSubmenu } from './DropdownEndIndicatorSubmenu'

type IconProps = IconNameVariantType

type DropdownSubTriggerRef = HTMLDivElement

type DropdownSubTriggerProps = Omit<DropdownMenuSubTriggerProps, 'asChild'> &
    StandardComponentProps & {
        startIcon?: IconProps
        label?: string
    }

export const DropdownSubTrigger = forwardRef<DropdownSubTriggerRef, DropdownSubTriggerProps>(
    ({ startIcon, label, ...props }, ref) => {
        return (
            <SubTrigger {...props} ref={ref}>
                {startIcon && (
                    <LeftSlot>
                        <MenuIcon size="m" {...startIcon} />
                    </LeftSlot>
                )}
                {label}
                <RightSlot>
                    <DropdownEndIndicatorSubmenu />
                </RightSlot>
            </SubTrigger>
        )
    }
)
