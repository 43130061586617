import React from 'react'

import { WorkflowNodeEditorComponent } from 'features/workflows/types'
import { useWorkflowEditorContext } from 'features/workflows/WorkflowEditorContext'
import { WorkflowExpressionInput } from 'features/workflows/WorkflowExpressionInput'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'

import { filterIterableContextItems } from './utils'

type LoopEditorProps = React.ComponentPropsWithoutRef<typeof Box> & {
    node: WorkflowLoopConfig
}

export const LoopEditor: WorkflowNodeEditorComponent<LoopEditorProps> = ({
    onChange,
    node,
    props,
}) => {
    const { contextSchema } = useWorkflowEditorContext()

    const inputValue = node.iterable_value?.expr

    const handleChangeItems = (value: WorkflowExpression | undefined) => {
        onChange({
            iterable_value: {
                type: 'array_expr',
                expr: value,
            },
        } as Partial<WorkflowLoopConfig>)
    }

    const filteredSchema = filterIterableContextItems(contextSchema)

    return (
        <Box flex column stretch p="xl" {...props}>
            <ConfigurationLabel mt="xs">List</ConfigurationLabel>
            <WorkflowExpressionInput
                contextSchema={filteredSchema}
                value={inputValue}
                onChange={handleChangeItems}
                placeholder="provide a list of items"
                allowFormulaFunctions={false}
            />
        </Box>
    )
}
