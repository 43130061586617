import * as Sentry from '@sentry/react'
import saveAs from 'file-saver'

import { fetchAndReturn, fetchAndReturnRaw, StackerAPI } from 'data/utils/utils'
import { filtersToQueryDict } from 'features/utils/filtersToQueryDict'

import {
    RecordListQueryResult,
    RecordSearchQueryDict,
    RecordSearchQueryOptions,
} from './recordApiTypes'

function buildQueryDictFromFilters(
    filters?: Filter[],
    options?: RecordSearchQueryOptions
): RecordSearchQueryDict {
    const search = (filters ?? []).find((filter) => filter?.field?.api_name === '_search')?.options
        ?.value as string | undefined
    const queryDict: RecordSearchQueryDict = filtersToQueryDict(
        (filters ?? []).filter((filter) => filter?.field?.api_name !== '_search')
    )

    if (!options) {
        return queryDict
    }

    if (search) {
        queryDict.search = search
    }
    if (options.dereference) {
        queryDict.dereference = true
    }
    if (options.dereferenceMultiFields) {
        queryDict.dereference_list = options.dereferenceMultiFields
    }

    if (options.disablePartials) {
        queryDict.disable_partials = options.disablePartials
    }
    if (options.includeFields) {
        queryDict.include_fields = options.includeFields
    }
    if (options.searchFields) {
        queryDict.search_fields = options.searchFields
    }
    if (options && !!options.excludeRecordsIdFromCsv) {
        queryDict.exclude_records_id_from_csv = options.excludeRecordsIdFromCsv
    }
    if (options.csvFieldsOrder) {
        queryDict.csv_fields_order = options.csvFieldsOrder
    }
    if (options.forcePartials) {
        queryDict.force_partials = true
    }
    if (options.orderBy && options.orderBy.id) {
        queryDict.order_by = `${options.orderBy.desc ? '-' : ''}${options.orderBy.id}`
    }
    if (options.manualOrderKey) {
        queryDict.manual = options.manualOrderKey
    }
    if (options.pageSize) {
        queryDict.count = options.pageSize
    }
    if (options.pageSize !== undefined && options.pageIndex) {
        queryDict.start = options.pageSize * options.pageIndex
    }
    if (options.trigger) {
        queryDict.trigger = options.trigger
    }
    if (options.showingDeleted) {
        queryDict.deleted_records = options.showingDeleted
        queryDict.filters = queryDict.filters ?? []
        queryDict.filters.push({ target: '_date_deleted', operation: 'withinlast', value: 7 })
    }

    return queryDict
}

// API
class RecordApi extends StackerAPI {
    path = 'records/'

    onFetchFailed = (error: any, extra: Record<string, any>) => {
        // Connection error. Wrapping so a failure here
        // doesn't mask the underlying error
        try {
            Sentry.withScope((scope) => {
                scope.setTags({ ...extra, error })
                if (error instanceof Error) {
                    Sentry.captureMessage('Record fetch failed: connection error')
                    // Response object from server error
                } else if (error.status) {
                    Sentry.captureMessage(`Record fetch failed: Server error (${error.status})`)
                } else {
                    Sentry.captureException(`Record fetch failed: Unknown error type`)
                }
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    getObjectRecords(
        objectSid: string,
        filters?: Filter[],
        options?: RecordSearchQueryOptions
    ): Promise<RecordListQueryResult> {
        const path = `objects/${objectSid}/records/search/`
        const queryDict = buildQueryDictFromFilters(filters, options)
        return fetchAndReturn(
            path,
            {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(queryDict),
            },
            null,
            this.onFetchFailed
        ) as Promise<RecordListQueryResult>
    }

    getObjectRecordsRaw(
        objectSid: string,
        filters: Filter[] | undefined,
        options: RecordSearchQueryOptions,
        headers = {}
    ): Promise<Response> {
        const path = `objects/${objectSid}/records/search/`
        const queryDict = buildQueryDictFromFilters(filters, options)
        return fetchAndReturnRaw(
            path,
            {
                headers: { ...headers, 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(queryDict),
            },
            null
        )
    }

    getRecordCount(
        objectSid: string,
        filters?: Filter[],
        options?: RecordSearchQueryOptions
    ): Promise<number> {
        if (!objectSid) {
            return Promise.reject('No object specified')
        }

        const path = `objects/${objectSid}/records/count/`
        const queryDict = buildQueryDictFromFilters(filters, options)

        return fetchAndReturn(
            path,
            {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(queryDict),
            },
            null,
            this.onFetchFailed
        ) as Promise<number>
    }

    async downloadCsvRecords({
        object,
        filename,
        filters,
        includeFields,
        disablePartials,
        orderBy,
        csvFieldsOrder,
        excludeRecordsIdFromCsv = false,
    }: {
        object: ObjectDto
        filename: string
        filters?: Filter[]
    } & Pick<
        RecordSearchQueryOptions,
        | 'includeFields'
        | 'disablePartials'
        | 'orderBy'
        | 'csvFieldsOrder'
        | 'excludeRecordsIdFromCsv'
    >) {
        const response = await recordApi.getObjectRecordsRaw(
            object._sid,
            filters,
            {
                includeFields,
                disablePartials,
                orderBy,
                csvFieldsOrder,
                excludeRecordsIdFromCsv,
            },
            { Accept: 'text/csv' }
        )

        const blob = await response.blob()
        // TODO: get good types for file-saver
        // @ts-expect-error
        saveAs(blob, filename)
    }

    getRecord(
        recordSid: string,
        includeFields: string[] | undefined,
        options: Pick<RecordSearchQueryOptions, 'dereference' | 'stackId'>
    ) {
        if (!recordSid) {
            return Promise.reject()
        }

        return fetchAndReturn(
            `records/${recordSid}/fetch/`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    include_fields: includeFields,
                    dereference: options.dereference,
                }),
            },
            null,
            this.onFetchFailed,
            options
        )
    }
}

export const recordApi = new RecordApi()
