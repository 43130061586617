import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'

import { extractStandardProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import * as Parts from './Range.parts'

type RangeRef = HTMLDivElement

type RangeProps<M extends boolean> = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.Control>,
    'asChild' | 'value' | 'defaultValue' | 'onValueChange' | 'onValueCommit'
> & {
    showCount?: boolean
    disabled?: boolean
    required?: boolean
    isError?: boolean
    helperText?: string
    infoText?: string
    label?: string
    defaultValue?: M extends true ? number[] : number
    value?: M extends true ? number[] : number
    onValueChange?: M extends true ? (value: number[]) => void : (value: number) => void
    onValueCommit?: M extends true ? (value: number[]) => void : (value: number) => void
    isMulti?: M
}

const RangeInner = <M extends boolean>(
    {
        showCount,
        label,
        id,
        className,
        infoText,
        helperText,
        isError,
        onValueChange,
        defaultValue: providedDefaultValue,
        value: providedValue,
        onValueCommit,
        isMulti,
        ...props
    }: RangeProps<M>,
    ref: React.ForwardedRef<RangeRef>
) => {
    // this is so the id property can be optional, but still use the
    // label htmlFor property to link the label to the input.
    const effectiveId = useMemo(() => id || generateUniqueId(), [id])

    // Extract the standard component style props and pass those along to the container.
    // Everything else goes to the Root part.
    const [standardProps, rootProps] =
        extractStandardProps<
            Omit<RangeProps<M>, 'value' | 'defaultValue' | 'onValueChange' | 'onValueCommit'>
        >(props)

    const internalValue: number[] | undefined = useMemo(
        () => (isMulti ? (providedValue as number[]) : [providedValue as number]),
        [isMulti, providedValue]
    )
    const defaultValue: number[] | undefined = useMemo(
        () => (isMulti ? (providedDefaultValue as number[]) : [providedDefaultValue as number]),
        [isMulti, providedDefaultValue]
    )

    const isControlled = typeof internalValue !== 'undefined'
    const [value, setValue] = useState<number[]>(
        internalValue || defaultValue || getInitialValue(isMulti)
    )
    useEffect(() => {
        setValue(internalValue || defaultValue || getInitialValue(isMulti))
    }, [defaultValue, internalValue, isMulti])

    const shouldShowCount = showCount && value.length < 3
    const hasLhsCount = shouldShowCount && value.length > 1
    const lhsCount = value[0]
    const rhsCount = value[value.length - 1]

    const handleValueChange = useCallback(
        (newValue: number[]) => {
            if (!isControlled) {
                setValue(newValue)
            }

            if (isMulti) {
                const onChange = onValueChange as RangeProps<true>['onValueChange']
                onChange?.(newValue)
            } else {
                const onChange = onValueChange as RangeProps<false>['onValueChange']
                onChange?.(newValue[0])
            }
        },
        [isControlled, isMulti, onValueChange]
    )

    const handleValueCommit = useCallback(
        (value: number[]) => {
            if (isMulti) {
                const onCommit = onValueCommit as RangeProps<true>['onValueCommit']
                onCommit?.(value)
            } else {
                const onCommit = onValueCommit as RangeProps<false>['onValueCommit']
                onCommit?.(value[0])
            }
        },
        [isMulti, onValueCommit]
    )

    return (
        <Parts.Container
            className={className}
            htmlFor={effectiveId}
            label={label}
            isError={isError}
            infoText={infoText}
            helperText={helperText}
            required={rootProps.required}
            disabled={rootProps.disabled}
            leftSlotContent={
                hasLhsCount && (
                    <Parts.Count aria-disabled={rootProps.disabled || undefined}>
                        {lhsCount}
                    </Parts.Count>
                )
            }
            rightSlotContent={
                shouldShowCount && (
                    <Parts.Count aria-disabled={rootProps.disabled || undefined}>
                        {rhsCount}
                    </Parts.Count>
                )
            }
            {...standardProps}
            ref={ref}
        >
            <Parts.Control
                id={effectiveId}
                aria-disabled={rootProps.disabled || undefined}
                minStepsBetweenThumbs={1}
                onValueChange={handleValueChange}
                onValueCommit={handleValueCommit}
                value={value}
                {...rootProps}
            >
                <Parts.Track>
                    <Parts.Range aria-disabled={rootProps.disabled || undefined} />
                </Parts.Track>
                {value.map((_, i) => (
                    <Parts.Thumb key={i} aria-disabled={rootProps.disabled || undefined} />
                ))}
            </Parts.Control>
        </Parts.Container>
    )
}

export const Range = forwardRef(RangeInner) as <M extends boolean = false>(
    props: RangeProps<M> & { ref?: React.ForwardedRef<RangeRef> }
) => ReturnType<typeof RangeInner>

function getInitialValue(isMulti) {
    return isMulti ? [0, 0] : [0]
}
