import React, { Dispatch, useCallback, useContext, useMemo, useReducer, useRef } from 'react'

import { GridApi } from 'ag-grid-enterprise'

import { DataGridCellProvider, RowData } from 'features/datagrid/types'
import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'

type OpenEditorAction = {
    type: 'openEditor'
    editorOpenedWithEvent?: Event
}

type CloseEditorAction = {
    type: 'closeEditor'
}

type SetGridReadyAction = {
    type: 'setGridReady'
    gridApi: GridApi<RowData>
}

type DestroyGridAction = {
    type: 'destroyGrid'
}

type ShowEmptyRecordRowAction = {
    type: 'showEmptyRecordRow'
}

type HideEmptyRecordRowAction = {
    type: 'hideEmptyRecordRow'
}

type DataGridStateForContextAction =
    | OpenEditorAction
    | CloseEditorAction
    | SetGridReadyAction
    | DestroyGridAction
    | ShowEmptyRecordRowAction
    | HideEmptyRecordRowAction

type DataGridContextState = {
    isEditorOpen: boolean
    editorOpenedWithEvent: Event | null
    isGridReady: boolean
    gridApi: GridApi<RowData> | null
    showEmptyRecordRow: boolean
}

const defaultState: DataGridContextState = {
    isEditorOpen: false,
    editorOpenedWithEvent: null,
    isGridReady: false,
    gridApi: null,
    showEmptyRecordRow: false,
}

function dataGridStateForContextReducer(
    state: DataGridContextState,
    action: DataGridStateForContextAction
): DataGridContextState {
    switch (action.type) {
        case 'openEditor':
            return {
                ...state,
                editorOpenedWithEvent: action.editorOpenedWithEvent ?? null,
                isEditorOpen: true,
            }
        case 'closeEditor':
            return {
                ...state,
                editorOpenedWithEvent: null,
                isEditorOpen: false,
            }
        case 'setGridReady':
            return {
                ...state,
                isGridReady: true,
                gridApi: action.gridApi,
            }
        case 'destroyGrid':
            return {
                ...state,
                isGridReady: false,
                gridApi: null,
            }
        case 'showEmptyRecordRow':
            return {
                ...state,
                showEmptyRecordRow: true,
            }
        case 'hideEmptyRecordRow':
            return {
                ...state,
                showEmptyRecordRow: false,
            }
        default:
            return state
    }
}

export type DataGridContext = DataGridContextState & {
    cellDataProvider: DataGridCellProvider
    canAddRecords: boolean
    canDeleteRecords: boolean
    canEditRecords: boolean
    canRestoreRecords: boolean
    isTrashMode: boolean
    dispatch: Dispatch<DataGridStateForContextAction>
    getDocumentFieldHTMLContent: (content: TipTapValue) => string
    getDocumentFieldContentFromHTML: (content: string) => TipTapValue
    pageIndex: number
    setPageIndex: (pageIndex: number) => void
    pageSize: number
}

export const defaultContext: DataGridContext = {
    ...defaultState,
    canAddRecords: false,
    canDeleteRecords: false,
    canEditRecords: false,
    canRestoreRecords: false,
    cellDataProvider: {} as DataGridCellProvider,
    dispatch: () => {},
    getDocumentFieldHTMLContent: () => '',
    getDocumentFieldContentFromHTML: () => ({
        content: null,
        format: 'tiptap',
        plainTextContent: '',
    }),
    isTrashMode: false,
    pageIndex: 0,
    setPageIndex: () => {},
    pageSize: 0,
}

export const DataGridContext = React.createContext(defaultContext)

export function useDataGridContext() {
    return useContext(DataGridContext)
}

type DataGridContextProviderProps = React.PropsWithChildren<{
    cellDataProvider: DataGridCellProvider
    canAddRecords: boolean
    canDeleteRecords: boolean
    canEditRecords: boolean
    canRestoreRecords: boolean
    isTrashMode: boolean
    pageIndex: number
    setPageIndex: (pageIndex: number) => void
    pageSize: number
}>

export const DataGridContextProvider: React.FC<DataGridContextProviderProps> = ({
    cellDataProvider,
    canAddRecords,
    canDeleteRecords,
    canEditRecords,
    canRestoreRecords,
    isTrashMode,
    pageIndex,
    setPageIndex,
    pageSize,
    children,
}) => {
    const [state, dispatch] = useReducer(dataGridStateForContextReducer, defaultState)

    const tiptapRef = useRef<TipTapEditorHandle | null>(null)

    const getDocumentFieldHTMLContent = useCallback((content: TipTapValue) => {
        tiptapRef.current?.editor?.commands.setContent(content.content, false)

        return tiptapRef.current?.editor?.getHTML() ?? ''
    }, [])

    const getDocumentFieldContentFromHTML = useCallback((content: string) => {
        tiptapRef.current?.editor?.commands.setContent(content, false)

        const tiptapValue: TipTapValue = {
            content: tiptapRef.current?.editor?.getJSON() ?? null,
            format: 'tiptap',
            plainTextContent: tiptapRef.current?.editor?.getText() ?? '',
        }

        return tiptapValue
    }, [])

    const contextValue = useMemo(
        () => ({
            ...state,
            cellDataProvider,
            canAddRecords,
            canDeleteRecords,
            canEditRecords,
            canRestoreRecords,
            isTrashMode,
            dispatch,
            getDocumentFieldHTMLContent,
            getDocumentFieldContentFromHTML,
            pageIndex,
            setPageIndex,
            pageSize,
        }),
        [
            state,
            cellDataProvider,
            canAddRecords,
            canDeleteRecords,
            canEditRecords,
            canRestoreRecords,
            isTrashMode,
            getDocumentFieldHTMLContent,
            getDocumentFieldContentFromHTML,
            pageIndex,
            setPageIndex,
            pageSize,
        ]
    )

    return (
        <DataGridContext.Provider value={contextValue}>
            {children}
            {/* This component is used to render document field content off-screen, before appending it to the clipboard,
            so we would keep the rich text formatting. */}
            <TipTapDocumentEditor
                ref={tiptapRef}
                readOnly={true}
                allowComments={false}
                style={{
                    display: 'none',
                }}
                renderContent={false}
            />
        </DataGridContext.Provider>
    )
}
