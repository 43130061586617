import React from 'react'

import AttachmentDisplay from 'v2/ui/components/Attribute/Attachment'
import Gallery from 'v2/ui/components/Gallery'

import { Box, BoxProps } from 'ui/components/Box/Box'

import { Attachment } from './types'
import { UploadingAttachment, UploadingAttachmentType } from './UploadingAttachment'

type AttachmentGalleryProps = BoxProps & {
    attachments?: Attachment[]
    onDeleteAttachment?: (url: string) => void
    uploadingAttachments?: UploadingAttachmentType[]
    onUploaded?: (id: string, attachment: Attachment) => void
    size?: 'sm' | 'md' | 'lg'
    isLightboxOpen?: boolean
    onLightboxClose?: () => void
}
export function AttachmentGallery({
    attachments = [],
    onDeleteAttachment,
    uploadingAttachments = [],
    onUploaded,
    size = 'lg',
    isLightboxOpen,
    onLightboxClose,
    ...props
}: AttachmentGalleryProps) {
    return (
        <Box flex stretch {...props}>
            <Gallery isOpen={isLightboxOpen} onClose={onLightboxClose}>
                {attachments?.map((a, idx) => (
                    /* @ts-expect-error */
                    <AttachmentDisplay
                        canDelete={!!onDeleteAttachment}
                        key={a.url}
                        attachment={a}
                        index={idx}
                        size={size}
                        onDelete={onDeleteAttachment}
                    />
                ))}
                {onUploaded &&
                    uploadingAttachments?.map((a, idx) => (
                        <UploadingAttachment
                            key={a.id}
                            galleryIndex={idx + attachments.length}
                            size={size}
                            {...a}
                            onUploaded={onUploaded}
                            onDelete={onDeleteAttachment}
                        />
                    ))}
            </Gallery>
        </Box>
    )
}

// type AttachmentProps = BoxProps & {attachment: Attachment}
// export function Attachment({attachment, ...props}: AttachmentProps) {
//     return <>Content</>
// }
