import { useEffect, useRef } from 'react'

import { useAppContext } from 'app/AppContext'

import { WorkflowActionEditorComponent } from './types'

export const CommentOnRecordActionConfig: WorkflowActionEditorComponent = ({
    action,
    onChange,
}) => {
    const { selectedStack } = useAppContext()

    const targetStackId = selectedStack?._sid

    const settingsRef = useRef(action.settings)
    settingsRef.current = action.settings

    useEffect(() => {
        onChange({
            settings: {
                ...settingsRef.current,
                stack_id: targetStackId,
            },
        })
    }, [onChange, targetStackId])

    return null
}
