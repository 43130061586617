import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { signOut } from 'supertokens-auth-react/recipe/session'
import { EmailVerificationClaim } from 'supertokens-web-js/recipe/emailverification'

import { GlobalStaticState } from 'app/GlobalStaticState'
import { CLEAR_STORE } from 'data/utils/constants'
import { useSessionContext } from 'features/auth/supertokens'
import analytics from 'utils/analytics'

import { LoadingSplash } from 'v2/ui'

import { AuthContext } from './AuthContext'
import { AuthContextStorage } from './AuthContextStorage'
export function AuthContextProvider({ children }: { children: React.ReactNode }) {
    const context = useSessionContext()
    const [user, setUserState] = React.useState<undefined | AuthedUserDto>(
        AuthContextStorage.getUser()
    )
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const dispatch = useDispatch()

    AuthContextStorage.setUser(user)
    GlobalStaticState.setUser(user)
    const hasSession = 'doesSessionExist' in context && context.doesSessionExist
    const userId = hasSession ? context.userId : undefined
    const tenantId = 'accessTokenPayload' in context ? context.accessTokenPayload.tId : undefined

    const setUser = useCallback(
        (user: AuthedUserDto | undefined) => {
            if (!user?._sid) {
                user = undefined
            }

            const isChanging = GlobalStaticState.getUser()?._sid !== user?._sid
            AuthContextStorage.setUser(user)
            GlobalStaticState.setUser(user)
            // Will remove when we no longer have anything in redux
            if (isChanging) {
                dispatch({ type: CLEAR_STORE })
            }

            setUserState(user)
        },
        [dispatch]
    )

    const accessToken = hasSession ? context.accessTokenPayload : undefined
    const apiToken = user?.api_token
    useEffect(() => {
        if (accessToken) {
            setIsEmailVerified(EmailVerificationClaim.getValueFromPayload(accessToken))
        } else if (apiToken) {
            setIsEmailVerified(true)
        } else {
            setIsEmailVerified(false)
        }
    }, [accessToken, apiToken])

    React.useEffect(() => {
        if (userId) {
            setUser({ _sid: userId } as AuthedUserDto)
        }
    }, [setUser, userId])

    const logOut = useCallback(async () => {
        await signOut()
        analytics.reset()
        localStorage.removeItem('support_login')
        setUser(undefined)
    }, [setUser])

    // User is only considered authed if it has an api_token (for legacy sessions) or if we have
    // a userId from our supertokens session
    const authedUser = user?.api_token || userId ? user : undefined
    if (context.loading) {
        return <LoadingSplash />
    }
    return (
        <AuthContext.Provider
            value={{ user: authedUser, setUser, logOut, tenantId, isEmailVerified }}
        >
            {children}
        </AuthContext.Provider>
    )
}
