import { useMemo } from 'react'
import { QueryKey, useMutation, useQuery } from 'react-query'

import { orderBy } from 'lodash'
import shortid from 'shortid'

import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { useNavigation } from 'data/hooks/navigation'
import { useCachedRecords } from 'data/hooks/records/useCachedRecords'
import { useViews } from 'data/hooks/views'
import { fetchAndReturn } from 'data/utils/utils'

import { FavoritesData, FavoriteType } from './types'
const LIST_NAME = 'favorites'

export function useFavorites() {
    const queryKey = useQueryKeyBuilder([LIST_NAME], { includeAuthKeys: true })

    const queryResult = useQuery<FavoritesData>(queryKey, async () => {
        const results = (await fetchAndReturn(`${LIST_NAME}/`, undefined, undefined, undefined, {
            bypassPreviewAs: true,
        })) as FavoritesData

        const existingData = {
            ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
        }
        // any pending new items get preserved.
        const newFavorites =
            existingData?.favorites?.filter((favorite) => !!favorite.pendingCreateId) ?? []
        results.favorites = [...newFavorites, ...(results.favorites ?? [])]
        return results
    })

    const recordIds =
        queryResult.data?.favorites?.filter((f) => f.record_id).map((f) => f.record_id as string) ??
        []

    const recordsFromCache = useCachedRecords(recordIds)

    const { data: views } = useViews()
    const { data: navigation } = useNavigation()

    // merge any cached records in, so that changes to records are reflected live in
    // our favorites display
    const mergedRecords = queryResult.data?.records?.map(
        (record) => recordsFromCache[record._sid as string] || record
    )
    const mergedViews = queryResult.data?.views?.map(
        (view) => views?.find((item) => item?._sid === view._sid) || view
    )
    const mergedNavigation = queryResult.data?.navigation_items?.map(
        (nav) => navigation?.find((item: NavigationDto) => item?._sid === nav._sid) || nav
    )

    const { mutateAsync: createFavorite } = useCreatefavorite(queryKey)
    const { mutateAsync: deleteFavorite } = useDeletefavorite(queryKey)
    const { mutateAsync: updateDisplayOrder } = useUpdateDisplayOrder(queryKey)

    // const { mutateAsync: updatefavorite } = useUpdatefavorite(queryKey)
    return {
        ...queryResult,
        data: {
            ...queryResult.data,
            records: mergedRecords,
            views: mergedViews,
            navigation_items: mergedNavigation,
        },
        favorites: useMemo(
            () =>
                orderBy(
                    (queryResult.data?.favorites ?? []).filter((f) => !f.pendingDelete),
                    'display_order'
                ),
            [queryResult.data?.favorites]
        ),
        createFavorite,
        deleteFavorite,
        updateDisplayOrder,
    }
}

export type CreateFavoriteData = {
    favorite: Partial<FavoriteDto>
    navigationItem: NavigationDto
}
export function useCreatefavorite(queryKey: QueryKey) {
    return useMutation(
        async ({ favorite }: CreateFavoriteData) => {
            const results = (await fetchAndReturn(
                `${LIST_NAME}/`,
                {
                    method: 'POST',
                    body: JSON.stringify(favorite),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )) as FavoriteDto

            return results
        },
        {
            onMutate: ({ favorite, navigationItem }: CreateFavoriteData) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }

                if (!favorite.pendingCreateId) {
                    favorite.pendingCreateId = shortid.generate()
                    favorite.display_order =
                        Math.max(...(queryData.favorites?.map((f) => f.display_order) ?? [0])) + 1
                    queryData.favorites = [favorite as FavoriteDto, ...(queryData?.favorites ?? [])]
                } else {
                    queryData.favorites = queryData?.favorites?.map((f) =>
                        f.pendingCreateId === favorite.pendingCreateId
                            ? { ...f, saveFailed: false }
                            : f
                    )
                }

                if (navigationItem) {
                    queryData.navigation_items = [
                        ...(queryData.navigation_items || []),
                        navigationItem,
                    ]
                }

                queryClient.setQueryData(queryKey, queryData)
            },
            onSettled: (data, error, { favorite }) => {
                if (data) {
                    const queryData = {
                        ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                    }

                    queryData.favorites = [
                        ...(queryData?.favorites?.filter(
                            (f) =>
                                f.pendingCreateId !== favorite.pendingCreateId &&
                                f.auto_id !== data.auto_id
                        ) ?? []),
                        data,
                    ]

                    queryClient.setQueryData(queryKey, queryData)
                    queryClient.invalidateQueries([LIST_NAME])
                }
            },
            onError: (err, { favorite }) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }

                queryData.favorites = queryData?.favorites?.map((v) =>
                    v.pendingCreateId === favorite.pendingCreateId ? { ...v, saveFailed: true } : v
                )
                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

export function useDeletefavorite(queryKey: QueryKey) {
    return useMutation(
        (id: number) => {
            return fetchAndReturn(
                `${LIST_NAME}/${id}`,
                {
                    method: 'DELETE',
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )
        },
        {
            onMutate: (id: number) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }
                queryData.favorites = queryData?.favorites?.map((favorite) =>
                    favorite.auto_id === id ? { ...favorite, pendingDelete: true } : favorite
                )

                queryClient.setQueryData(queryKey, queryData)

                return
            },
            onError: (err, id) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }
                queryData.favorites = queryData?.favorites?.map((favorite) =>
                    favorite.auto_id === id ? { ...favorite, pendingDelete: false } : favorite
                )
                queryClient.setQueryData(queryKey, queryData)
            },
            onSuccess: (data, id) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }
                queryData.favorites = queryData?.favorites?.filter(
                    (favorite) => favorite.auto_id !== id
                )

                queryClient.setQueryData(queryKey, queryData)
            },
        }
    )
}

type UseFavoriteOptions = {
    targetType: FavoriteType
    stackId?: string
    objectId?: string
    recordId?: string
    documentId?: number
    navigationId?: string

    onCreateFavorite?: (data: CreateFavoriteData) => void
}

export function useFavorite(options: UseFavoriteOptions) {
    const { targetType, stackId, objectId, recordId, documentId, navigationId, onCreateFavorite } =
        options

    const { createFavorite, deleteFavorite, favorites } = useFavorites()

    const favorite = favorites.find(
        (f) =>
            f.type === targetType &&
            f.stack_id === stackId &&
            (!navigationId || f.navigation_id === navigationId) &&
            (!objectId || f.object_id === objectId) &&
            (!recordId || f.record_id === recordId) &&
            (!documentId || f.document_id === documentId)
    )

    const toggleFavorite = () => {
        if (favorite) {
            deleteFavorite(favorite.auto_id)
        } else {
            const data = {
                favorite: {
                    type: targetType,
                    stack_id: stackId,
                    navigation_id: navigationId,
                    object_id: objectId,
                    record_id: recordId,
                    document_id: documentId,
                },
            } as CreateFavoriteData

            onCreateFavorite?.(data)
            createFavorite(data)
        }
    }

    return { favorite, toggleFavorite }
}

export type UpdateDisplayOrderArgs = {
    id: number
    displayOrder: number
}
function useUpdateDisplayOrder(queryKey: QueryKey) {
    return useMutation(
        ({ id, displayOrder }: UpdateDisplayOrderArgs) => {
            return fetchAndReturn(
                `favorites/${id}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify({ display_order: displayOrder }),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )
        },
        {
            onMutate: ({ id, displayOrder }: UpdateDisplayOrderArgs) => {
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }
                const originalFavorite = queryData?.favorites?.find(
                    (favorite) => favorite.auto_id === id
                )

                if (!originalFavorite) return

                const oldDisplayOrder = originalFavorite.display_order

                const direction = oldDisplayOrder > displayOrder ? 1 : -1
                const lowerBound = Math.min(displayOrder, oldDisplayOrder)
                const upperBound = Math.max(displayOrder, oldDisplayOrder)

                const oldDisplayOrders: { [keyof: number]: number } | undefined =
                    queryData.favorites?.reduce(
                        (result, favorite) => ({
                            ...result,
                            [favorite.auto_id]: favorite.display_order,
                        }),
                        {}
                    )

                console.log('#', { lowerBound, upperBound, direction })

                queryData.favorites = queryData?.favorites?.map((favorite) => ({
                    ...favorite,
                    display_order:
                        favorite.auto_id === id
                            ? displayOrder
                            : favorite.display_order >= lowerBound &&
                              favorite.display_order <= upperBound
                            ? favorite.display_order + direction
                            : favorite.display_order,
                }))

                queryClient.setQueryData(queryKey, queryData)
                return oldDisplayOrders
            },
            onError: (error, _data, oldDisplayOrders) => {
                if (!oldDisplayOrders) return
                console.log('# error', error)
                const queryData = {
                    ...(queryClient.getQueryData<FavoritesData>(queryKey) ?? {}),
                }
                queryData.favorites = queryData?.favorites?.map((favorite) => ({
                    ...favorite,
                    display_order: oldDisplayOrders[favorite.auto_id] || favorite.display_order,
                }))
                queryClient.setQueryData(queryKey, queryData)
            },
            onSuccess: () => {
                queryClient.invalidateQueries([LIST_NAME])
            },
        }
    )
}
