import { useLayoutEffect, useState } from 'react'

import { DataGridOverflowingLeftStyle } from 'features/datagrid/components/DataGrid.css'
import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

import { useOverflowing } from 'v2/ui/utils/useIsOverflowing'

/**
 * Add a classnames to the grid wrapper when the grid scrolls right.
 * @param wrapperElement
 */
export function useGridOverflowEffects(wrapperElement: HTMLDivElement | null) {
    const { isGridReady } = useDataGridContext()

    const [viewportEl, setViewportEl] = useState<HTMLElement | null>(null)
    useLayoutEffect(() => {
        if (!isGridReady) return

        const element = wrapperElement?.querySelector('.ag-center-cols-viewport')
        setViewportEl(element as HTMLElement | null)
    }, [wrapperElement, isGridReady])

    const { left: overflowingLeft } = useOverflowing(viewportEl)
    useLayoutEffect(() => {
        if (overflowingLeft) {
            wrapperElement?.classList.add(DataGridOverflowingLeftStyle)
        } else {
            wrapperElement?.classList.remove(DataGridOverflowingLeftStyle)
        }
    }, [wrapperElement, overflowingLeft])
}
