import React, { useCallback, useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import * as client from 'filestack-js'

import AttachmentDisplay from 'v2/ui/components/Attribute/Attachment'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { stopAllPointerPropagation } from 'ui/helpers/utilities'

import { Attachment } from './types'

export type UploadingAttachmentType = {
    id: string
    blob: Blob
}
export function UploadingAttachment({
    id,
    blob,
    onUploaded,
    galleryIndex,
    onDelete,
    size,
}: UploadingAttachmentType & {
    onUploaded: (id: string, result: Attachment) => void
    galleryIndex: number
    onDelete?: (id: string) => void

    size: 'sm' | 'md' | 'lg'
}) {
    const [uploading, setUploading] = useState(false)
    const [failed, setFailed] = useState(false)
    const [imgSrc, setImgSrc] = useState('')

    const upload = useCallback(() => {
        setFailed(false)
        client
            .init('Arfn2PRozQ26eRkdQy74Ez')
            .upload(blob)
            .then((res) =>
                onUploaded(id, {
                    url: res.url,
                    filename: res._file.name,
                    size: res._file.size,
                    mimetype: res._file.type,
                })
            )
            .catch(() => {
                setFailed(true)
            })
    }, [blob, id, onUploaded])
    useEffect(() => {
        if (!uploading) {
            setUploading(true)
            setImgSrc(URL.createObjectURL(blob))
            upload()
        }
    }, [blob, id, onUploaded, upload, uploading])

    return (
        <Box position="relative">
            {/* @ts-expect-error */}
            <AttachmentDisplay
                size={size}
                index={galleryIndex}
                attachment={{ url: imgSrc }}
                canDelete={!!onDelete}
                onDelete={() => onDelete?.(id)}
            />
            {uploading && !failed && (
                <Spinner position="absolute" left={0} top={0} bottom={0} right={0} margin="auto" />
            )}
            {failed && (
                <Container
                    position="absolute"
                    right="m"
                    style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        margin: 'auto',
                    }}
                    width="fit-content"
                    height="fit-content"
                    variant="error"
                    flex
                    column
                    center
                    border
                    px="m"
                    py="xs"
                >
                    Failed
                    <Box
                        role="button"
                        style={{ textDecoration: 'underline' }}
                        {...stopAllPointerPropagation()}
                        onClick={(e: React.MouseEvent) => {
                            upload()
                            e.stopPropagation()
                        }}
                    >
                        Try again
                    </Box>
                </Container>
            )}
        </Box>
    )
}
