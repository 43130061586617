import React, { useMemo, useRef, useState } from 'react'

import { TasksEditModePopover } from 'features/tasks/TasksEditModePopover'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'

import { useActivityFeedContext } from './ActivityFeedContext'

type TaskLinkProps = {
    taskId: number
}
export function TaskLink({ taskId }: TaskLinkProps) {
    const { tasks } = useActivityFeedContext()

    const buttonRef = useRef<HTMLButtonElement>(null)
    const [isEditMode, setIsEditMode] = useState(false)

    const task = useMemo(() => tasks.find((t) => t.auto_id === taskId), [tasks, taskId])

    const handleLinkClick = (e: React.MouseEvent) => {
        if (task) setIsEditMode(!isEditMode)

        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <>
            <Button
                ref={buttonRef}
                variant="ghost"
                onClick={handleLinkClick}
                type="button"
                fontWeight="bodyBold"
                color={task ? 'admin500' : 'text'}
                disabled={!task}
            >
                <Icon name="CalendarCheck" mr="xs" />

                {task ? <Box as="span">{task.title}</Box> : <Box as="i">deleted task</Box>}
            </Button>
            {task && (
                <TasksEditModePopover
                    task={task}
                    targetElement={buttonRef.current as HTMLElement}
                    open={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    placement="bottom-start"
                    matchParentWidth={false}
                    overlayOnParent={false}
                    width="600px"
                />
            )}
        </>
    )
}
