import React, { forwardRef } from 'react'

import classNames from 'classnames'
import { icons, LucideProps } from 'lucide-react'

import { StandardComponentProps, transformStandardProps } from 'ui/helpers/styles'
import {
    ResponsiveValuesFromProps,
    useResponsiveValue,
} from 'ui/styling/helpers/useResponsiveValue'

import { IconStyles, IconVariants } from './Icon.css'

export type IconName = keyof typeof icons

export type IconNameVariantType = { name: IconName }

type IconRef = SVGSVGElement

type IconPropsBase = Omit<LucideProps, 'ref' | 'size' | 'color'> &
    StandardComponentProps &
    IconNameVariantType &
    IconVariants & {}

type IconProps = Omit<IconPropsBase, 'size'> & ResponsiveValuesFromProps<IconPropsBase, 'size'>

export const Icon = forwardRef<IconRef, IconProps>(({ name, size = 's', ...props }, ref) => {
    const LucideIcon = icons[name]

    const { className, ...transformedProps } = transformStandardProps(props)

    const effectiveSize = useResponsiveValue(size)

    return (
        <LucideIcon
            absoluteStrokeWidth={true}
            {...transformedProps}
            className={classNames(IconStyles.styleFunction({ size: effectiveSize }), className)}
            ref={ref}
        />
    )
})
