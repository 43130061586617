import { useMemo } from 'react'

import { useAppUserContext } from 'app/AppUserContext'
import { TaskSource } from 'data/hooks/tasks/types'
import { useWorkspaceUsers } from 'data/hooks/users/main'

export function useSourceLabel(source: string, source_type: TaskSource) {
    const { data: users } = useWorkspaceUsers()

    const { user: currentUser } = useAppUserContext()
    const currentUserId = currentUser?._sid

    return useMemo(() => {
        if (source_type === TaskSource.User) {
            if (currentUserId && source === currentUserId) return 'me'

            const user = users?.find((u: UserDto) => u._sid === source)
            if (user) return user.name || user.email
        }

        return ''
    }, [currentUserId, source, source_type, users])
}
