import React from 'react'

import { Box } from 'ui/components/Box'

import { useTableViewGridState } from './hooks/useTableViewGridState'

type TableViewGridProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TableViewGrid: React.FC<TableViewGridProps> = React.memo(function TableViewGrid({
    style,
    ...props
}) {
    const { gridTemplateColumns } = useTableViewGridState()

    return (
        <Box
            display="grid"
            height="full"
            width="full"
            style={{
                gridTemplateColumns,
                ...style,
            }}
            px={{ mobile: 'xl', tablet: 0 }}
            {...props}
        />
    )
})
