import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'

export function useCardViewPaginationState() {
    const { currentPageIndex, setCurrentPageIndex, pageSize, recordCount, isEmbedded } =
        useCardViewContext()

    const hasMoreThanOnePage = recordCount > pageSize
    const showPagination = isEmbedded ? hasMoreThanOnePage : true

    return useMemo(
        () => ({
            pageIndex: currentPageIndex,
            onChangePageIndex: setCurrentPageIndex,
            pageSize,
            totalItemsCount: recordCount,
            showPagination,
        }),
        [currentPageIndex, setCurrentPageIndex, pageSize, recordCount, showPagination]
    )
}
