import React from 'react'

import useRecordEditManager, {
    RecordEditManagerArgs,
    RecordEditManagerResult,
} from 'features/datagrid/hooks/useRecordEditManager'

export const RecordEditManagerContext = React.createContext<RecordEditManagerResult | null>(null)

export function useRecordEditManagerContext() {
    const context = React.useContext(RecordEditManagerContext)
    if (!context) {
        throw new Error(
            'useRecordEditManagerContext must be used within a RecordEditManagerContextProvider'
        )
    }
    return context
}

export function RecordEditManagerContextProvider({
    children,
    ...props
}: RecordEditManagerArgs & { children: React.ReactNode }) {
    const recordEditManager = useRecordEditManager(props)

    return (
        <RecordEditManagerContext.Provider value={recordEditManager}>
            {children}
        </RecordEditManagerContext.Provider>
    )
}
