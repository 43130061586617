import { useMemo } from 'react'

import { useTheme } from 'ui/styling/themes/ThemeProvider'

import { Breakpoint, breakpoints } from './breakpoints'

export type ResponsiveValueObject<T> = Partial<Record<Breakpoint, T>>
export type ResponsiveValue<T> = ResponsiveValueObject<T> | T

export type ResponsiveValuesFromProps<T, K extends keyof T> = {
    [TK in K]: ResponsiveValue<T[TK]>
}

export type PropsWithResponsiveValues<T, K extends keyof T> = Omit<T, K> & {
    [TK in K]: ResponsiveValue<T[TK]>
}

export type UnderlyingTypesFromResponsiveValues<T> = {
    [TK in keyof T]: T[TK] extends ResponsiveValue<infer U> ? U : T[TK]
}

export function useResponsiveValue<T>(value: ResponsiveValue<T>): T {
    const { breakpoint } = useTheme()

    return useMemo(() => {
        if (isResponsiveValueObject(value)) {
            return computeValueFromResponsiveValueObject(value, breakpoint)
        }

        return value as T
    }, [breakpoint, value])
}

export function useTransformResponsiveProps<T>(props: T) {
    const { breakpoint } = useTheme()

    return transformResponsiveProps(props, breakpoint)
}

// Breakpoints ordered by the start value.
const orderedBreakpoints = Object.entries(breakpoints)
    .sort(([, a], [, b]) => a.start - b.start)
    .map(([key]) => key) as Breakpoint[]

function computeValueFromResponsiveValueObject<T>(
    value: ResponsiveValueObject<T>,
    breakpoint: Breakpoint
): T {
    const hasCurrentBreakpoint = value.hasOwnProperty(breakpoint)
    if (hasCurrentBreakpoint) {
        return value[breakpoint] as T
    }

    const previousBreakpoints = orderedBreakpoints.slice(0, orderedBreakpoints.indexOf(breakpoint))

    for (const bp of previousBreakpoints.reverse()) {
        if (value.hasOwnProperty(bp)) {
            return value[bp] as T
        }
    }

    // No-op.
    throw new Error(`No value found for breakpoint ${breakpoint}`)
}

export function isResponsiveValueObject<T>(value: unknown): value is ResponsiveValueObject<T> {
    return (
        !!value &&
        typeof value === 'object' &&
        orderedBreakpoints.some((key) => value.hasOwnProperty(key))
    )
}

export function transformResponsiveProps<T>(
    props: T,
    currentBreakpoint: Breakpoint
): UnderlyingTypesFromResponsiveValues<T> {
    if (!props || typeof props !== 'object') return props as UnderlyingTypesFromResponsiveValues<T>

    return Object.entries(props).reduce((result, [key, value]) => {
        if (isResponsiveValueObject(value)) {
            result[key] = computeValueFromResponsiveValueObject(value, currentBreakpoint)
        } else {
            result[key] = value
        }

        return result
    }, {} as UnderlyingTypesFromResponsiveValues<T>)
}
