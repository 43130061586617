import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'

export function useCardViewContentState() {
    const { records } = useCardViewContext()

    return useMemo(
        () => ({
            records,
        }),
        [records]
    )
}
