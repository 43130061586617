import React, { useCallback } from 'react'

import { DropdownItem } from 'ui/components/Dropdown'

import { useActionMenuItemFavoriteState } from './hooks/useActionMenuItemFavoriteState'

type ActionMenuItemFavoriteRef = HTMLDivElement

type ActionMenuItemFavoriteProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {
    record: RecordDto
}

export const ActionMenuItemFavorite = React.forwardRef<
    ActionMenuItemFavoriteRef,
    ActionMenuItemFavoriteProps
>(({ record, onClick, ...props }, ref) => {
    const { label, isFavorite, toggleFavorite } = useActionMenuItemFavoriteState({ record })

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()

            toggleFavorite()
            onClick?.(e)
        },
        [onClick, toggleFavorite]
    )

    return (
        <DropdownItem
            ref={ref}
            onClick={handleClick}
            startIcon={{
                name: 'Star',
                style: {
                    fill: isFavorite ? 'currentColor' : 'transparent',
                },
            }}
            label={label}
            {...props}
        />
    )
})
