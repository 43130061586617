// @ts-strict-ignore
import React, { useContext } from 'react'

export type AppContextProps = {
    workspaceAccount: Account | null
    workspaceZone: AccountZone | null
    selectedStack: StackDto | null
    lastVisitedStack: StackDto | null
    isLoading: boolean
    initialLoadComplete?: boolean
    setSelectedStack: (stack: StackDto) => void
    loadingAccountFailed: boolean
    isSubscribed: boolean
}

export const defaultContext: AppContextProps = {
    workspaceAccount: null,
    selectedStack: null,
    workspaceZone: null,
    lastVisitedStack: null,
    isLoading: false,
    initialLoadComplete: false,
    setSelectedStack: () => {},
    loadingAccountFailed: false,
    isSubscribed: false,
}

export const AppContext = React.createContext(defaultContext)

export const useAppContext = () => {
    return useContext(AppContext)
}

export default AppContext
