import React, { useMemo } from 'react'

import { SuggestionProps } from '@tiptap/suggestion'

import { useMentionUsersForStack } from 'features/Activity/useMentionUsersForStack'
import { matchesSearchTerms } from 'features/Search/helpers'
import { Highlighter } from 'features/Search/Highlighter'
import { MentionsList } from 'features/tiptap/Extensions/MentionsExtension'
import { RecordsList } from 'features/tiptap/Extensions/RecordSearchExtension'

import { Box } from 'ui/components/Box'
import { IconName } from 'ui/components/Icon/Icon'

import { CommandListIcon } from './CommandListIcon'
import { CommandsProvider } from './CommandsExtension'

type InternalLinkCommand = {
    id: string
    label: string
    icon: IconName
    component: React.ComponentType<SuggestionProps<any>>
}

type SearchUsersProps = SuggestionProps<any> & InternalLinkCommandProviderProps & {}

const SearchUsers = React.forwardRef<React.ElementRef<typeof MentionsList>, SearchUsersProps>(
    ({ stackId, ...props }, ref) => {
        const mentionUsers = useMentionUsersForStack(stackId)

        return <MentionsList ref={ref} {...props} items={mentionUsers} />
    }
)

const InternalLinkCommands: InternalLinkCommand[] = [
    {
        id: 'mention_user',
        label: 'Mention user...',
        icon: 'User',
        component: SearchUsers,
    },
    {
        id: 'link_record',
        label: 'Link to record...',
        icon: 'FileText',
        component: RecordsList,
    },
]

type InternalLinkCommandProviderProps = {
    stackId?: string
    allowMentions?: boolean
    props?: unknown
}

// Adds the "mention a user" and "link a record" commands. Might add more in the future.
export const useInternalLinkCommandProvider = ({
    allowMentions,
    props,
}: InternalLinkCommandProviderProps): CommandsProvider<InternalLinkCommand> => {
    return useMemo(() => {
        const availableCommands = InternalLinkCommands.filter(
            (u) => allowMentions || u.id !== 'mention_user'
        )

        return {
            id: 'internal_links',
            title: 'Link and tag things',
            initialItems: availableCommands,
            getItems({ queryTerms }) {
                return Promise.resolve({
                    items: availableCommands.filter((u) => matchesSearchTerms(u.label, queryTerms)),
                })
            },
            renderItem({ item, queryTerms }) {
                return <RenderCommand item={item} queryTerms={queryTerms} />
            },
            onSelect: ({
                editor,
                range,
                item,
                setDisplayedComponent,
                setDisplayedComponentProps,
            }) => {
                editor.chain().focus().insertContentAt(range, '/').run()
                setDisplayedComponent(item.component)
                setDisplayedComponentProps(props)
            },
        } as CommandsProvider<InternalLinkCommand>
    }, [allowMentions, props])
}

function RenderCommand({ item, queryTerms }: { item: InternalLinkCommand; queryTerms?: string[] }) {
    return (
        <Box flex center maxWidth="full" width="full" color="text" tabIndex={-1}>
            <CommandListIcon name={item.icon} mr="m" />
            <Box flex column grow shrink>
                <Box mr="l" trim grow>
                    <Highlighter terms={queryTerms || []}>{item.label}</Highlighter>
                </Box>
                {/* <Box color="textWeak" fontSize="bodyS">
                    {item.description}
                </Box> */}
            </Box>
        </Box>
    )
}
