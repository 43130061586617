import React, { forwardRef, useMemo } from 'react'

import { Avatar } from 'ui/components/Avatar/Avatar'
import { Box } from 'ui/components/Box'
import { Tooltip } from 'ui/components/Tooltip'
import { makeComponent } from 'ui/helpers/recipes'
import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { theme } from 'ui/styling/Theme.css'

import {
    AvatarGroupItemStyle,
    AvatarGroupStyles,
    AvatarOverflowLabelStyles,
} from './AvatarGroup.css'

const MAX_AVATARS = 5

const Container = makeComponent(Box, AvatarGroupStyles)
const OverflowLabelContainer = makeComponent(Box, AvatarOverflowLabelStyles)

type AvatarGroupProps = Omit<React.ComponentPropsWithRef<typeof Container>, 'type'> & {
    colorScheme?: ColorScheme
    overflow?: boolean
    showTooltips?: boolean
    tooltipProps?: Partial<React.ComponentPropsWithoutRef<typeof Tooltip>>
}

export const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
    (
        {
            overflow,
            children,
            size = 'm',
            colorScheme = 'Theme',
            showTooltips,
            tooltipProps,
            ...props
        },
        ref
    ) => {
        const allAvatars = useMemo(() => {
            return React.Children.toArray(children).filter(
                (child) => React.isValidElement(child) && child.type === Avatar
            ) as React.ReactElement[]
        }, [children])

        const childrenAvatars = useMemo(() => {
            if (overflow) {
                return allAvatars.slice(0, MAX_AVATARS - 1)
            }

            return allAvatars.slice(0, MAX_AVATARS)
        }, [allAvatars, overflow])

        const overflowingAvatars = useMemo(() => {
            if (overflow) {
                return allAvatars.slice(MAX_AVATARS - 1)
            }

            return []
        }, [allAvatars, overflow])

        const overflowCount = overflowingAvatars.length

        const overflowLabel = overflow && overflowCount > 1 && (
            <OverflowLabel
                size={size}
                colorScheme={colorScheme}
                className={AvatarGroupItemStyle.styleFunction({ size })}
                count={overflowCount}
            />
        )

        const overflowTooltipContent = useMemo(() => {
            return overflowingAvatars.map((child) => getFullName(child.props)).join('\r\n')
        }, [overflowingAvatars])

        return (
            <Container flex center flexShrink={false} {...props} ref={ref}>
                {childrenAvatars.map((child, idx) => {
                    const content = React.cloneElement<
                        React.ComponentPropsWithoutRef<typeof Avatar>
                    >(child as React.ReactElement, {
                        size,
                        colorScheme,
                        shape: 'circle',
                        online: false,
                        className: AvatarGroupItemStyle.styleFunction({ size }),
                    })

                    const fullName = getFullName(child.props)
                    if (showTooltips && fullName) {
                        return (
                            <Tooltip
                                key={idx}
                                content={fullName}
                                side="bottom"
                                asChild
                                {...tooltipProps}
                            >
                                {content}
                            </Tooltip>
                        )
                    }

                    return content
                })}
                {showTooltips ? (
                    <Tooltip
                        content={<Box whiteSpace="pre">{overflowTooltipContent}</Box>}
                        side="bottom"
                        asChild
                        {...tooltipProps}
                    >
                        {overflowLabel}
                    </Tooltip>
                ) : (
                    overflowLabel
                )}
            </Container>
        )
    }
)

type OverflowLabelProps = React.ComponentPropsWithoutRef<typeof OverflowLabelContainer> & {
    count: number
    size: 's' | 'm'
    colorScheme?: ColorScheme
}

const OverflowLabel = React.forwardRef<HTMLDivElement, OverflowLabelProps>(
    ({ count, size, colorScheme, style, ...props }, ref) => {
        const colorStyles = {
            backgroundColor: theme.avatar.color[`surface${colorScheme}`],
            color: theme.avatar.color[`text${colorScheme}`],
        }

        const displayedCount = Math.max(Math.min(count, 99), 1)

        return (
            <OverflowLabelContainer
                ref={ref}
                size={size}
                flex
                center
                justifyContent="center"
                flexShrink={0}
                style={{
                    ...style,
                    ...colorStyles,
                }}
                {...props}
            >
                +{displayedCount}
            </OverflowLabelContainer>
        )
    }
)

function getFullName(props?: React.ComponentPropsWithoutRef<typeof Avatar>) {
    if (!props) return ''

    return [props.firstName, props.lastName].join(' ').trim()
}
