import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { throttle } from 'lodash'
import {
    PreviewRecordListItem,
    usePreviewRecordContext,
} from 'v2/views/List/PreviewRecord/PreviewRecordContext'

import { getUrl } from 'app/UrlService'
import { ActionContextMenuHandle } from 'features/views/ListView/Actions/ActionContextMenu'
import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import { TableCellStyles } from 'features/views/ListView/TableView/TableView.css'
import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type ColumnValue = TableViewColumn & {
    value: any
}

export function useTableViewRowState(record?: RecordDto) {
    const {
        object,
        stack,
        records,
        columns,
        pendingRecords,
        onRecordClick,
        view,
        requestIncludedFields,
    } = useTableViewContext()
    const { previewRecord, recordId: selectedRecordId } = usePreviewRecordContext()

    const recordsRef = useRef(records)
    recordsRef.current = records

    const recordDetailUrl = useMemo(() => {
        return getUrl(`${object?.url}/view/${record?._sid}`, stack)
    }, [object?.url, record?._sid, stack])

    const to = record ? recordDetailUrl : ''

    const isPressed = useRef(false)
    const isSelectingRef = useRef(false)
    const selectionStartPoint = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

    const onMouseDown = useCallback((e: React.MouseEvent) => {
        isPressed.current = true
        selectionStartPoint.current = { x: e.clientX, y: e.clientY }
    }, [])

    const onMouseMove = useCallback((e) => {
        if (isSelectingRef.current || !isPressed.current) return

        const { x, y } = selectionStartPoint.current

        // If the user moves the mouse more than 5 pixels, consider it a selection.
        const distance = Math.sqrt(Math.pow(e.clientX - x, 2) + Math.pow(e.clientY - y, 2))
        if (distance > 5) {
            isSelectingRef.current = true
        }
    }, [])

    const openInNewTab = useCallback(() => {
        window.open(recordDetailUrl, '_blank')
    }, [recordDetailUrl])

    const history = useHistory()
    const openAsFullPage = useCallback(() => {
        history.push(recordDetailUrl)
    }, [history, recordDetailUrl])

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            // Only open side peek on regular left click.
            if (e.ctrlKey || e.metaKey || e.button === 1 || !record?._sid) return

            e.preventDefault()

            // Check for any record click overrides.
            switch (onRecordClick) {
                case 'none':
                    return
                case 'new_tab':
                    openInNewTab()
                    return
                case 'detail':
                    openAsFullPage()
                    return
            }

            selectionStartPoint.current = { x: 0, y: 0 }
            isPressed.current = false

            // If the user is trying to select text, don't open the side peek.
            if (isSelectingRef.current) {
                isSelectingRef.current = false
                return
            }

            const records = recordsRef.current
            const recordListItems: PreviewRecordListItem[] | undefined = records?.map((r) => ({
                recordId: r._sid,
            }))

            previewRecord({
                recordId: record?._sid,
                objectId: record?._object_id,
                partOfRecordList: recordListItems
                    ? {
                          direction: 'vertical',
                          items: recordListItems,
                      }
                    : undefined,
            })
        },
        [
            previewRecord,
            record?._sid,
            record?._object_id,
            onRecordClick,
            openAsFullPage,
            openInNewTab,
        ]
    )

    const isSelected = selectedRecordId === record?._sid

    const columnsWithValues: ColumnValue[] = useMemo(() => {
        return columns.map((column) => ({
            ...column,
            value: record?.[column.field.api_name] ?? undefined,
        }))
    }, [columns, record])

    const rowRef = useRef<HTMLDivElement>(null)

    const [rowSizes, setRowSizes] = useState<{ top: number; height: number }>({
        top: 0,
        height: 0,
    })

    const getSizes = useCallback(() => {
        const row = rowRef.current
        if (!row) return

        const firstCellClassName = TableCellStyles.styleFunction().split(' ')[0]
        const firstCell = row.querySelector(`.${firstCellClassName}`) as HTMLElement | null
        if (!firstCell) return

        const top = firstCell.offsetTop
        const height = firstCell.scrollHeight

        setRowSizes({ top, height })
    }, [])

    useLayoutEffect(() => {
        getSizes()

        const observer = new ResizeObserver(getSizes)
        const row = rowRef.current
        if (row) {
            observer.observe(row)
        }

        const throttledGetSize = throttle(getSizes, 100)
        window.addEventListener('resize', throttledGetSize)

        return () => {
            observer.disconnect()
            window.removeEventListener('resize', throttledGetSize)
        }
    }, [getSizes])

    const recordLen = records?.length ?? 0
    useLayoutEffect(() => {
        // Recalculate row sizes when the record list changes.
        getSizes()
    }, [recordLen, getSizes])

    const hasCalculations = columns.some((c) => !!c.calculation)
    useLayoutEffect(() => {
        // Recalculate row sizes when calculations are enabled or disabled.
        getSizes()
    }, [hasCalculations, getSizes])

    const actions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: true,
    })
    const actionsMemo = useDeepEqualsMemoValue(actions.map((a) => a.action))
    const actionsRef = useRef(actionsMemo)
    actionsRef.current = actionsMemo

    const actionContextMenuRef = useRef<ActionContextMenuHandle>(null)

    const onContextMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const row = rowRef.current
        if (!row) return

        // Don't open the context menu if there are no actions enabled.
        const actions = actionsRef.current
        if (!actions.length) return

        const target = e.target as HTMLElement
        const closestInteractive = target.closest('a') as HTMLElement | null
        if (closestInteractive && closestInteractive !== row) {
            // Don't open the context menu if the click was on a link.
            return
        }

        e.preventDefault()
        e.stopPropagation()

        actionContextMenuRef?.current?.openAt(e.clientX, e.clientY)
    }, [])

    const isPending = record && pendingRecords.some((r) => r._sid === record._sid)

    return useMemo(
        () => ({
            to,
            onClick,
            isSelected,
            columns: columnsWithValues,
            rowRef,
            top: rowSizes.top,
            height: rowSizes.height,
            onContextMenu,
            actionContextMenuRef,
            onMouseMove,
            onMouseDown,
            isPending,
            actionButtons: actionsMemo,
            includeFields: requestIncludedFields,
        }),
        [
            to,
            onClick,
            isSelected,
            columnsWithValues,
            rowSizes.top,
            rowSizes.height,
            onContextMenu,
            onMouseMove,
            onMouseDown,
            isPending,
            actionsMemo,
            requestIncludedFields,
        ]
    )
}
