import { useMemo } from 'react'

import { useFiltersContext } from 'features/views/ListView/Filters/FiltersContext'

export function useTableViewFilters() {
    const { clearFilters, inlineFilters } = useFiltersContext()

    const hasFilters = inlineFilters.length > 0

    return useMemo(
        () => ({
            hasFilters,
            clearFilters,
        }),
        [clearFilters, hasFilters]
    )
}
