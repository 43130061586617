import React, { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames'

import { HeadSearchInputStyle, HeadSearchWrapperStyle } from 'ui/components/Menu/Menu.css'

import { useDropdownContentContext } from './DropdownContentContext'
import { DropdownHeadBase } from './DropdownHeadBase'
import { useKeyDownFromHead } from './useKeyDownFromHead'

type DropdownHeadSearchRef = HTMLDivElement

type InheritedInputProps = Pick<
    React.ComponentPropsWithoutRef<'input'>,
    'placeholder' | 'autoFocus' | 'value' | 'onChange'
>

type DropdownHeadSearchProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> &
    InheritedInputProps & {}

export const DropdownHeadSearch = React.forwardRef<DropdownHeadSearchRef, DropdownHeadSearchProps>(
    ({ placeholder = 'Filter options…', autoFocus = true, onFocus, className, ...props }, ref) => {
        const { searchQuery, setSearchQuery, setIsSearchManaged } = useDropdownContentContext()

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value)
            },
            [setSearchQuery]
        )

        const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)

        const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

        const handleOnFocus = useCallback(
            (e: React.FocusEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()

                // Only run this when the head is focused from another menu item.
                if (e.target !== e.currentTarget) return

                const input = inputRef
                if (!input) return

                input.focus()
                input.setSelectionRange(searchQuery.length, searchQuery.length)

                onFocus?.(e)
            },
            [inputRef, onFocus, searchQuery.length]
        )

        const handleInputKeyDown = useKeyDownFromHead(headRef)

        useEffect(() => {
            const input = inputRef ?? undefined
            setIsSearchManaged(true, input)

            return () => setIsSearchManaged(false, input)
        }, [inputRef, setIsSearchManaged])

        const handleOnSelect = useCallback(() => {
            inputRef?.focus()
        }, [inputRef])

        return (
            <DropdownHeadBase
                onFocus={handleOnFocus}
                onSelect={handleOnSelect}
                className={classNames(className, HeadSearchWrapperStyle)}
                {...props}
                ref={(element: HTMLDivElement) => {
                    setHeadRef(element)

                    return ref
                }}
            >
                <input
                    ref={setInputRef}
                    type="text"
                    className={HeadSearchInputStyle}
                    value={searchQuery}
                    onChange={handleChange}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    onKeyDown={handleInputKeyDown}
                />
            </DropdownHeadBase>
        )
    }
)
