import { useCallback, useEffect, useMemo, useRef } from 'react'

import { getUserLocale } from 'get-user-locale'
import moment from 'moment'

import { AdvancedFilter } from 'features/views/ListView/ListHeader/Filters/Advanced/types'
import { formatDate } from 'utils/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { DatePickerRef } from 'ui/components/DatePicker/DatePicker'

type UseAdvancedFilterValueDateStateOptions = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
}

export function useAdvancedFilterValueDateState(options: UseAdvancedFilterValueDateStateOptions) {
    const { filter, onChange } = options

    const field = filter.field as FieldDto
    const fieldRef = useRef(field)
    fieldRef.current = field

    const filterValue = getValueFromFilter(filter)
    const filterValueMemo = useDeepEqualsMemoValue(filterValue)

    const showTime = field.type === 'datetime'

    const onInputChange = useCallback(
        (newDates: Date | [Date, Date]) => {
            const field = fieldRef.current

            const values = getFilterValueFromDates(newDates, field)
            onChange(values)
        },
        [onChange]
    )

    const dateValue = useMemo(() => {
        const convertedValues = filterValueMemo.reduce((acc, value) => {
            const { dateValue: parsedValue } = formatDate(value, field.options?.timezone, showTime)
            if (parsedValue) {
                acc.push(parsedValue.toDate())
            }

            return acc
        }, [] as Date[])

        if (convertedValues.length === 1) {
            return convertedValues[0]
        } else if (convertedValues.length < 1) {
            return undefined
        }

        return convertedValues as [Date, Date]
    }, [field.options?.timezone, filterValueMemo, showTime])
    const dateValueRef = useRef(dateValue)
    dateValueRef.current = dateValue

    const locale = getUserLocale()
    const uses24HrFormat = moment.localeData(locale).longDateFormat('LT').includes('H')

    const timeFormat = uses24HrFormat ? 'H:mm' : 'h:mm a'
    const dateFormat = showTime ? `MMM d, yyyy ${timeFormat}` : 'MMM d, yyyy'

    const calendarRef = useRef<DatePickerRef>(null)

    // Focus input when filter is created.
    useEffect(() => {
        if (filter.isDraft) {
            calendarRef.current?.setOpen(true)
        }
    }, [filter.isDraft])

    // Focus input when operation is changed, and the filter is empty.
    useEffectOnlyOnUpdate(() => {
        const isEmpty = !dateValueRef.current
        if (!isEmpty) return

        calendarRef.current?.setOpen(true)
    }, [filter.options.option])

    return useMemo(
        () => ({
            onInputChange,
            value: dateValue,
            showTime,
            dateFormat,
            calendarRef,
        }),
        [onInputChange, dateValue, showTime, dateFormat]
    )
}

function getValueFromFilter(filter: AdvancedFilter): string[] {
    const filterValue = filter.options?.value

    if (Array.isArray(filterValue)) {
        return filterValue
    }

    if (filterValue) {
        return [filterValue]
    }

    return []
}

function getFilterValueFromSingleDate(date: Date, field: FieldDto) {
    const showTime = field.type === 'datetime'

    const { dateValue } = formatDate(date, field.options?.timezone, showTime)
    const formattedDate = showTime ? dateValue?.toISOString() : dateValue?.format('YYYY-MM-DD')

    return formattedDate
}

function getFilterValueFromDates(dates: Date | [Date, Date], field: FieldDto) {
    if (Array.isArray(dates)) {
        return dates.reduce((acc, date) => {
            const formattedDate = getFilterValueFromSingleDate(date, field)
            if (formattedDate) {
                acc.push(formattedDate)
            }

            return acc
        }, [] as string[])
    }

    return getFilterValueFromSingleDate(dates, field)
}
