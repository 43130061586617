import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { withNavigation } from 'data/wrappers/WithNavigation'
import { withObjects } from 'data/wrappers/WithObjects'
import { withPages } from 'data/wrappers/WithPages'
import { withViews } from 'data/wrappers/WithViews'
import { useNavTree } from 'features/internal/nav/AppNavTree'
import { PathContext } from 'features/utils/PathContext'

import { Box, Flex } from 'v2/ui'

import { HoverContainerStyle } from 'ui/styles/hoverUtils.css'

import AppNavItem from './AppNavItem'

const AppNavTree = ({ pages, objects, stack, views, navigation, navTheme, onClickNavItem }) => {
    const pathContext = useContext(PathContext)
    const history = useHistory()
    const routeUrl = pathContext.pageUrl

    const navTree = useNavTree({ navigation, views, pages, objects }).filter(
        (item) => item.display !== false
    )

    return (
        <Flex column align="stretch" id="navTree">
            {navTree.map(
                (
                    {
                        item,
                        view,
                        isOnChildRoute,
                        isActive,
                        isFolder,
                        topLevelItem,
                        topLevelUrl,
                        children,
                    },
                    index
                ) => {
                    return (
                        <Flex
                            key={item._sid}
                            style={{ width: '100%' }}
                            align="stretch"
                            direction="column"
                            flexWrap="nowrap"
                            className={HoverContainerStyle}
                        >
                            <AppNavItem
                                isTopLevel
                                isFolder={isFolder}
                                url={topLevelUrl}
                                item={topLevelItem}
                                stack={stack}
                                label={topLevelItem.label}
                                view={view}
                                isActive={isActive || isOnChildRoute}
                                navTheme={navTheme}
                                icon={isFolder ? (!isOnChildRoute ? 'caretDown' : 'caretUp') : null}
                                index={index}
                                onClick={onClickNavItem}
                            />

                            {isOnChildRoute && children.length > 0 && (
                                <Box
                                    maxWidth="100%"
                                    mb={2}
                                    style={{
                                        borderLeft:
                                            navTheme.theme === 'dark'
                                                ? `2px solid rgba(255,255,255, 0.14)`
                                                : `2px solid rgba(0,0,0, 0.14)`,
                                        marginLeft: 15,
                                    }}
                                >
                                    {children.map((child) => (
                                        <AppNavItem
                                            key={child._sid}
                                            isFolder={false}
                                            view={views.find((v) => v.url === child.url)}
                                            item={child}
                                            label={child.label}
                                            stack={stack}
                                            isActive={
                                                child.url === routeUrl ||
                                                history?.location?.state?.prev?.pathname?.endsWith(
                                                    child.url
                                                )
                                            }
                                            navTheme={navTheme}
                                            onClick={onClickNavItem}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Flex>
                    )
                }
            )}
        </Flex>
    )
}

export default React.memo(withObjects(withNavigation(withViews(withPages(AppNavTree)))))
