import React from 'react'
import { components } from 'react-select'

import { withDataConnections } from 'data/wrappers/WithDataConnections'
import WithObjects from 'data/wrappers/WithObjects'

import { Dropdown, Flex, Icon } from 'v2/ui'

const ObjectPicker = ({
    id,
    showFeatureConfigData,
    onChange,
    placeholder,
    value,
    style,
    returnObject,
    objectSidWhitelist,
    filter,
    dataConnections = [],
    ...props
}) => {
    const renderValue = ({ object }) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                    size="14px"
                    display="inline-block"
                    verticalAlign="middle"
                    mr={2}
                    color="neutral.600"
                    icon="caretRight"
                />
                <div>
                    <b>{object.name}</b>
                </div>
            </div>
        )
    }

    const formatGroupLabel = ({ dataConnection }) => {
        return (
            <Flex borderBottom="1px solid #eee" ml="-6px" pl="6px" pb="3px">
                <span
                    style={{
                        verticalAlign: 'baseline',
                        fontWeight: 'bold',
                        color: 'black',
                        textTransform: 'none',
                        fontSize: '0.875rem',
                    }}
                >
                    {dataConnection?.label}
                </span>
            </Flex>
        )
    }
    return (
        <WithObjects showFeatureConfigData={showFeatureConfigData}>
            {({ objects }) => {
                const groupedOptions = dataConnections.map((dc) => ({
                    label: dc.label,
                    dataConnection: dc,
                    options: objectToOptions(objects || [], objectSidWhitelist, filter, dc._sid),
                }))

                // If we have multiple data connections then we display the options
                // grouped by DC, and we also render them differently
                const isGrouped = dataConnections?.length > 1

                return (
                    <Dropdown
                        id={id}
                        onChange={onChange}
                        placeholder={placeholder || 'Select data source'}
                        clearable={false}
                        options={
                            isGrouped
                                ? groupedOptions
                                : objectToOptions(objects || [], objectSidWhitelist, filter)
                        }
                        value={value}
                        usePortal={true}
                        style={style}
                        returnObject={returnObject}
                        adminTheme
                        renderValue={isGrouped ? renderValue : null}
                        disableComponentOverrides={isGrouped}
                        optionComponent={isGrouped ? ListItem : null}
                        formatGroupLabel={formatGroupLabel}
                        {...props}
                    />
                )
            }}
        </WithObjects>
    )
}

function ListItem(props) {
    const { object } = props.data
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { onMouseMove, onMouseOver, ...rest } = props
    return (
        <components.Option {...rest}>
            <div>
                {props.isSelected && (
                    <Icon
                        icon="checkmark"
                        size="sm"
                        color="neutral.600"
                        display="inline"
                        mr="6px"
                    />
                )}
                <span style={{ marginLeft: !props.isSelected && '20px' }}>{object?.name}</span>
            </div>
        </components.Option>
    )
}

const objectToOptions = (objects, sidWhitelist, filter, dataConnectionId) => {
    //only show objects that are enabled
    return objects
        .filter(
            (obj) =>
                (!filter || filter(obj)) &&
                (!sidWhitelist || sidWhitelist.includes(obj._sid)) &&
                (!dataConnectionId || obj.data_connection === dataConnectionId)
        )
        .reduce((filtered, obj) => {
            if (!obj.connection_options.data_mapping_disabled) {
                filtered.push({
                    label: obj.name,
                    value: obj._sid,
                    object: obj,
                })
            }
            return filtered
        }, [])
}

ObjectPicker.propTypes = {}

export default withDataConnections(ObjectPicker)
