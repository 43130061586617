import React from 'react'
import { Link } from 'react-router-dom'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { isEqual } from 'lodash'

import { ActionContextMenu } from 'features/views/ListView/Actions/ActionContextMenu'

import { Box } from 'ui/components/Box'
import { Tag } from 'ui/components/Tag'

import { BoardViewRecordActions } from './Actions/BoardViewRecordActions'
import { Attribute } from './attributes/Attribute'
import { useBoardViewCardState } from './hooks/useBoardViewCardState'
import { useBoardViewDnDCardState } from './hooks/useBoardViewDnDCardState'
import * as Parts from './BoardView.parts'
import { BoardViewCommentCount } from './BoardViewCommentCount'
import { BoardViewContextValue } from './BoardViewContext'
import { BoardViewCoverImage } from './BoardViewCoverImage'
import { BoardViewEyebrow } from './BoardViewEyebrow'
import { BoardViewProfileImage } from './BoardViewProfileImage'
import { BoardViewSubtitle } from './BoardViewSubtitle'
import { BoardViewAttribute, BoardViewFooterAttribute } from './types'

import {
    BoardViewCardContentStyle,
    BoardViewFooterColumnStyle,
    BoardViewFooterContentStyle,
    BoardViewFooterStyle,
} from './BoardView.css'

type BoardViewCardRef = HTMLDivElement

type BoardViewCardProps = React.ComponentPropsWithoutRef<typeof Parts.Card> & {
    record?: RecordDto
    siblingRecords?: RecordDto[]
    removeRecordNotMatchingFilters?: (recordSid: string) => void
}

const BoardViewCardInner = React.forwardRef<BoardViewCardRef, BoardViewCardProps>(
    function BoardViewCard(
        {
            record,
            isDraggingOverlay = false,
            siblingRecords,
            isDragging = false,
            isLoading = false,
            isNotMatchingFilters = false,
            removeRecordNotMatchingFilters,
            ...props
        },
        ref
    ) {
        const {
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            attributeStyle,
            labelStyle,
            actionContextMenuRef,
            cardRef,
            onContextMenu,
            additionalEditFields,
            isPending,
            actionButtons,
            includeFields,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
        } = useBoardViewCardState({
            record,
            siblingRecords,
            removeRecordNotMatchingFilters,
            isNotMatchingFilters,
        })

        const composedRef = useComposedRefs(ref, cardRef)

        return (
            <Parts.Card
                ref={composedRef}
                as={isDraggingOverlay ? undefined : Link}
                to={to}
                onClick={onClick}
                isDraggingOverlay={isDraggingOverlay}
                isDragging={isDragging}
                data-recordid={record?._sid}
                isLoading={isLoading}
                isNotMatchingFilters={isNotMatchingFilters}
                onContextMenu={onContextMenu}
                isPending={isPending}
                tabIndex={isLoading || isPending ? -1 : undefined}
                {...props}
            >
                {record && (
                    <>
                        <BoardViewCardHeader
                            record={record}
                            isDraggingOverlay={isDraggingOverlay}
                            isNotMatchingFilters={isNotMatchingFilters}
                            titleAttribute={titleAttribute}
                            isLoading={isLoading}
                            coverImageSrc={coverImageSrc}
                        />
                        <BoardViewCardContent
                            record={record}
                            attributes={contentAttributes}
                            isLoading={isLoading}
                            attributeStyle={attributeStyle}
                            labelStyle={labelStyle}
                        />
                        <BoardViewCardFooter
                            record={record}
                            isDraggingOverlay={isDraggingOverlay}
                            isLoading={isLoading}
                            leftAttribute={footerLeftAttribute}
                            rightAttribute={footerRightAttribute}
                        />
                        {!isDraggingOverlay && !isDragging && !isLoading && (
                            <>
                                <BoardViewRecordActions
                                    record={record}
                                    additionalEditFields={additionalEditFields}
                                    actionButtons={actionButtons}
                                    includeFields={includeFields}
                                />
                                <ActionContextMenu
                                    ref={actionContextMenuRef}
                                    record={record}
                                    additionalEditFields={additionalEditFields}
                                    actionButtons={actionButtons}
                                    includeFields={includeFields}
                                />
                            </>
                        )}
                    </>
                )}
            </Parts.Card>
        )
    }
)

export const BoardViewCard = React.memo(BoardViewCardInner, isEqual)

type BoardViewCardHeaderProps = {
    record: RecordDto
    titleAttribute?: BoardViewAttribute
    isDraggingOverlay?: boolean
    isNotMatchingFilters?: boolean
    isLoading?: boolean
    coverImageSrc?: string
}

const BoardViewCardHeader: React.FC<BoardViewCardHeaderProps> = React.memo(
    function BoardViewCardHeader({
        titleAttribute,
        isLoading,
        isNotMatchingFilters,
        record,
        coverImageSrc,
    }) {
        const hasCoverImage = !!coverImageSrc

        return (
            <Box position="relative">
                {hasCoverImage && <BoardViewCoverImage isLoading={isLoading} src={coverImageSrc} />}
                <BoardViewProfileImage
                    record={record}
                    isLoading={isLoading}
                    hasCoverImage={hasCoverImage}
                />
                <BoardViewEyebrow
                    record={record}
                    isLoading={isLoading}
                    hasCoverImage={hasCoverImage}
                />
                <Box
                    display="grid"
                    style={{
                        gridTemplateColumns: '1fr auto',
                    }}
                    p="s"
                    pr={isNotMatchingFilters ? undefined : '5xl'}
                    alignSelf="stretch"
                    gap="s"
                >
                    <Box>
                        {titleAttribute && (
                            <Attribute
                                attribute={titleAttribute}
                                isLoading={isLoading}
                                displayStyle="list"
                                value={record[titleAttribute.field.api_name] ?? undefined}
                            />
                        )}
                        <BoardViewSubtitle record={record} isLoading={isLoading} />
                    </Box>
                    {isNotMatchingFilters && (
                        <Box noShrink height="full">
                            <Tag
                                type="solid"
                                size="xs"
                                startIcon={{ name: 'FilterX' }}
                                pointerEvents="none"
                            >
                                Not a match
                            </Tag>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }
)

type BoardViewCardContentProps = {
    record: RecordDto
    attributes: BoardViewAttribute[]
    isLoading?: boolean
    labelStyle?: BoardViewContextValue['labelStyle']
    attributeStyle?: BoardViewContextValue['attributeStyle']
}

const BoardViewCardContent: React.FC<BoardViewCardContentProps> = React.memo(
    function BoardViewCardContent({ record, attributes, isLoading, attributeStyle, labelStyle }) {
        const hasLabels = labelStyle === 'text'

        const isTagsDisplay = attributeStyle === 'tags'
        const displayInline = isTagsDisplay && !hasLabels

        if (attributes.length < 1) return null

        return (
            <Box
                flex
                flexDirection={displayInline ? 'row' : 'column'}
                center={displayInline}
                flexWrap={displayInline ? 'wrap' : undefined}
                gap={hasLabels ? 'm' : 'xs'}
                p="s"
                pt={0}
                alignSelf="stretch"
                className={BoardViewCardContentStyle}
            >
                {attributes.map((attribute) => {
                    return (
                        <Attribute
                            key={attribute.id}
                            attribute={attribute}
                            isLoading={isLoading}
                            displayStyle={attributeStyle}
                            value={record[attribute.field.api_name] ?? undefined}
                            showIcon={
                                labelStyle === 'icon' &&
                                !!attribute.icon &&
                                attribute.type === 'content'
                            }
                        />
                    )
                })}
            </Box>
        )
    },
    isEqual
)

type FooterAttributeProps = {
    record: RecordDto
    attribute: BoardViewFooterAttribute
    isLoading?: boolean
}

const FooterAttribute: React.FC<FooterAttributeProps> = React.memo(function FooterAttribute({
    record,
    isLoading,
    attribute,
}) {
    if (attribute === '_record_stats') {
        return <BoardViewCommentCount record={record} isLoading={isLoading} />
    }

    return (
        <Attribute
            attribute={attribute}
            isLoading={isLoading}
            value={record[attribute.field.api_name] ?? undefined}
        />
    )
})

type BoardViewCardFooterProps = {
    record: RecordDto
    isDraggingOverlay?: boolean
    isLoading?: boolean
    leftAttribute?: BoardViewFooterAttribute
    rightAttribute?: BoardViewFooterAttribute
}

const BoardViewCardFooter: React.FC<BoardViewCardFooterProps> = React.memo(
    function BoardViewCardFooter({ isLoading, record, leftAttribute, rightAttribute }) {
        if (!leftAttribute && !rightAttribute) return null

        return (
            <Box p="s" pb="2xs" alignSelf="stretch" className={BoardViewFooterStyle}>
                <Box
                    flex
                    center
                    gap="s"
                    alignSelf="stretch"
                    justifyContent="space-between"
                    className={BoardViewFooterContentStyle}
                    pt="m"
                >
                    <Box minWidth={0} className={BoardViewFooterColumnStyle}>
                        {leftAttribute && (
                            <FooterAttribute
                                record={record}
                                isLoading={isLoading}
                                attribute={leftAttribute}
                            />
                        )}
                    </Box>
                    <Box minWidth={0} className={BoardViewFooterColumnStyle}>
                        {rightAttribute && (
                            <FooterAttribute
                                record={record}
                                isLoading={isLoading}
                                attribute={rightAttribute}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
)

type BoardViewDnDCardProps = BoardViewCardProps & {}

export const BoardViewDnDCard: React.FC<BoardViewDnDCardProps> = React.memo(
    function BoardViewDnDCard(props) {
        const { attributes, listeners, setNodeRef, isDragging } = useBoardViewDnDCardState({
            recordSid: props.record!._sid,
            isNotMatchingFilters: props.isNotMatchingFilters,
        })

        return (
            <BoardViewCard
                ref={setNodeRef}
                isDragging={isDragging}
                {...attributes}
                {...listeners}
                {...props}
            />
        )
    },
    isEqual
)
