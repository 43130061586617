import React, { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { useUpdateAccount } from 'data/hooks/accounts'
import { WorkspaceRoleLabel } from 'features/admin/settings/common/UserAccessEditor'
import { getWorkspaceRoles } from 'features/auth/utils/roleUtils'

import { Box, Dropdown, Flex, Text } from 'v2/ui'

import Form from 'ui/deprecated/forms/Form'
import { FormField } from 'ui/deprecated/forms/FormField'

import { Divider, FormFrame } from './WorkspaceSettingsModalUi'

const PermissionDefinitions = [
    {
        title: 'Create apps',
        description: 'Create and share apps with other workspace collaborators.',
        right: 'create_apps',
    },
    { title: 'Invite users', description: 'Invite users to the workspace.', right: 'invite_users' },
    {
        title: 'Manage users',
        description: 'Remove users from the workspace and change user access levels.',
        right: 'manage_users',
    },
    // hide billing section for now
    // {
    //     title: 'Billing',
    //     description: 'Manage subscription and billing details for the workspace.',
    //     right: 'manage_billing',
    // },
    {
        title: 'See Stacker Support',
        description: 'Contact Stacker support and view help documentation.',
        right: 'contact_support',
    },
    {
        title: 'View workspace settings',
        description:
            'View workspace settings, including the list of collaborators and their access levels.',
        right: 'view_settings',
    },
    {
        title: 'Edit workspace settings',
        description: 'Edit workspace name, appearance, and other settings.',
        right: 'manage_settings',
    },
]

const RoleOptions = [
    { label: 'Everyone', value: '*' },
    { label: 'Owners only', value: 'owners', icon: 'key', iconColor: '#C9251B' },
]

const WorkspaceAdvancedSettings = ({ formRef, children }) => {
    const { workspaceAccount } = useAppContext()
    const { mutateAsync: updateAccount } = useUpdateAccount({
        allowOptimisticUpdates: false,
    })

    const roles = useMemo(() => {
        return getWorkspaceRoles(workspaceAccount?.roles)
    }, [workspaceAccount?.roles])

    const values = PermissionDefinitions.reduce((values, item) => {
        values[item.right] = roles.every(
            (role) =>
                role.options?.rights.includes(item.right) || role.options?.rights.includes('*')
        )
            ? '*'
            : 'owners'
        return values
    }, {})

    const handleSave = (data) => {
        const roleData = []
        for (const role of roles.filter((role) => role.api_name !== 'owner')) {
            const rights = Object.keys(data)
                .map((right) =>
                    data[right] === '*' || data[right] === role.api_name ? right : null
                )
                .filter((right) => right !== null)
            roleData.push({ ...role, options: { ...role.options, rights } })
        }

        return updateAccount({ id: workspaceAccount._sid, patch: { roles: roleData } })
    }
    return (
        <Form
            onSubmit={handleSave}
            options={{
                defaultValues: values,
            }}
            style={{ flex: 1, width: '100%', height: '100%' }}
            resetOnSuccess
        >
            <FormFrame title="Advanced Workspace Settings" formRef={formRef} bottomBorder>
                <WorkspacePermissionsSettings />
                {children}
            </FormFrame>
        </Form>
    )
}

function WorkspacePermissionsSettings() {
    return PermissionDefinitions.map((item, idx) => (
        <>
            {idx > 0 && <Divider />} <WorkspacepermissionItem {...item} />
        </>
    ))
}

function WorkspacepermissionItem({ title, description, right }) {
    return (
        <Flex fontSize="sm" wrap="nowrap">
            <Box flexGrow={1}>
                <Text fontWeight="bold">{title}</Text>
                <Text mt={1}>{description}</Text>
            </Box>
            <Box width="200px" minWidth="200px" ml={3}>
                <FormField
                    width="100%"
                    as={Dropdown}
                    name={right}
                    variant="settings"
                    options={RoleOptions}
                    controlled
                    isSearchable={false}
                    renderValue={(option) => <WorkspaceRoleLabel role={option} />}
                />
            </Box>
        </Flex>
    )
}
export default WorkspaceAdvancedSettings
