import React, { useCallback, useLayoutEffect } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

import { Box } from 'ui/components/Box'

import { DataGridCellRendererComponent } from './types'

type RowIndexCellRendererProps = {}

/**
 * This is the cell that gets displayed in the first column of the grid.
 */
export const RowIndexCellRenderer: DataGridCellRendererComponent<RowIndexCellRendererProps> = ({
    node,
    api,
}) => {
    const refreshCell = useCallback(() => {
        api.refreshCells({ rowNodes: [node], force: true })
    }, [api, node])

    useLayoutEffect(() => {
        // Refresh the cell when the grid is sorted or filtered, so the row indices are updated.
        api.addEventListener('sortChanged', refreshCell)
        api.addEventListener('filterChanged', refreshCell)

        return () => {
            if (api.isDestroyed()) return

            api.removeEventListener('sortChanged', refreshCell)
            api.removeEventListener('filterChanged', refreshCell)
        }
    }, [api, refreshCell])

    const { pageIndex, pageSize } = useDataGridContext()

    const index = node.rowIndex ?? 0
    const globalIndex = index + 1 + pageIndex * pageSize

    return (
        <Box height="full" width="full">
            {globalIndex}
        </Box>
    )
}
