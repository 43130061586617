import React from 'react'

import { LayoutEditorBlock } from 'v2/blocks/types'

import { useAppContext } from 'app/AppContext'
import { TaskRelatedTo } from 'data/hooks/tasks/types'
import {
    conditionalVisibility,
    longText,
    text,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { RelatedTaskList } from 'features/tasks/RelatedTaskList'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'

import { Box as V2Box, Collapse, Container, Heading, MarkdownText, Text } from 'v2/ui'

import { Box } from 'ui/components/Box'

type TaskListBlockProps = {}

const TaskListBlock: LayoutEditorBlock<TaskListBlockProps, TaskListBlock> = ({
    attrs,
    context,
}) => {
    const { title, description } = attrs
    let titleVariant = description ? 'widgetContainerTitle' : 'widgetContainerTitleNoMargin'
    const { isEditing } = context.editor
    const tasksEnabled = useTasksEnabled()

    const { selectedStack } = useAppContext()

    // Conditional Visibility
    const passesFilters = useAttributeRecordFilter(attrs, context.record)

    const hidden = !isEditing && passesFilters
    return (
        <Collapse isOpen={!!tasksEnabled && !hidden} duration={0.4}>
            <Container py={['container.padding', null, null, 'container.paddingLg']} mb={4}>
                <V2Box px={['container.padding', null, null, 'container.paddingLg']}>
                    <Box flex center mb={!description ? 'l' : undefined}>
                        {title && (
                            <Heading as="h2" value={title} flexGrow={1} variant={titleVariant} />
                        )}
                    </Box>

                    {description && (
                        <Text mb={4} variant="widgetContainerDescription">
                            <MarkdownText
                                showRichTextEditor={typeof description === 'object'}
                                convertToMarkdown={false}
                            >
                                {description}
                            </MarkdownText>
                        </Text>
                    )}
                </V2Box>
                <RelatedTaskList
                    related_to={(context.record as RecordDto | undefined)?._sid}
                    related_to_stack={selectedStack?._sid}
                    related_to_type={TaskRelatedTo.Record}
                    _object_id={(context.record as RecordDto | undefined)?._object_id}
                />
            </Container>
        </Collapse>
    )
}

TaskListBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    text({ field: 'title', label: 'Title', simple: true, fullWidth: true }),
    longText({
        field: 'description',
        label: 'Description',
        multiline: true,
        maxHeight: '200px',
        fullWidth: true,
        simple: true,
    }),
]

export default TaskListBlock
