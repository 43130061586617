import React, { useContext, useMemo } from 'react'

import { PathContext } from 'features/utils/PathContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'
import { RecordManager } from 'features/views/RecordManager/hooks/useRecordManagerState'
import {
    RecordManagerContextProvider,
    useRecordManagerContext,
} from 'features/views/RecordManager/RecordManagerContext'

export type DetailViewContextValue = {
    view?: ViewDto
    recordManager: RecordManager
    title: string
}

export const DetailViewContext = React.createContext<DetailViewContextValue | null>(null)

export function useDetailViewContext(): DetailViewContextValue {
    const context = useContext(DetailViewContext)

    if (!context) {
        throw new Error('useDetailViewContext must be used within a DetailViewContextProvider')
    }

    return context
}

export type DetailViewContextProviderProps = {}

export const DetailViewContextProvider: React.FC<DetailViewContextProviderProps> = ({
    children,
}) => {
    const { isEditing } = useLayoutEditorContext()
    const { recordId: recordSid } = useContext(PathContext)

    const includeFields = isEditing ? undefined : undefined

    return (
        <RecordManagerContextProvider recordSid={recordSid} includeFields={includeFields}>
            <DetailViewContextProviderInner>{children}</DetailViewContextProviderInner>
        </RecordManagerContextProvider>
    )
}

const DetailViewContextProviderInner: React.FC = React.memo(
    function DetailViewContextProviderInner({ children }) {
        const { view } = useLayoutEditorContext()

        const recordManager = useRecordManagerContext()

        const title = (recordManager.record?._primary as string | undefined) || ''

        const value = useMemo(
            () => ({
                view,
                recordManager,
                title,
            }),
            [recordManager, title, view]
        )

        return <DetailViewContext.Provider value={value}>{children}</DetailViewContext.Provider>
    }
)
