import { useCallback } from 'react'

import { useDropdownContentContext } from './DropdownContentContext'

export function useContentClose(onClose?: (e: Event) => void) {
    const { setSearchQuery } = useDropdownContentContext()

    return useCallback(
        (e: Event) => {
            // Clear search query when clicking outside the dropdown.
            setSearchQuery('')
            onClose?.(e)
        },
        [onClose, setSearchQuery]
    )
}
