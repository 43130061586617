import { getCurrentStack, getWorkspaceAccount, GlobalStaticState } from 'app/GlobalStaticState'

import settings from './settings'
import { isOnStudioDomain } from './urlUtils'

export const Urls = {
    Root: '/',
    AdminHome: '/admin',
    Home: '/home',
    Login: '/login',
    SignUp: '/signup',
    Auth: '/auth',
    AuthThirdPartyCallback: '/auth/callback',
    LoginLinkCallback: '/auth/verify',
    EmailVerificationCallback: '/auth/verify-email',
    Logout: '/logout',
    AccountSettings: '/__account',
    StackSettings: '/__settings',
    Benchmark: '/__benchmark',
    AdminSetup: '/admin/setup',
    AdminSupportLogin: '/admin/support-login',
    Admin: '/admin',
    // register route is meant to be hidden for now, so url is set to something that's not as obvious but still accessible
    RegisterHidden: '/register-hidden',
    AcceptInvitation: '/accept',
    SetPassword: '/set-password',
    Docs: '/docs',
    ResetPassword: '/reset-password',
    TermsOfService: 'https://www.stackerhq.com/legal/terms',
    PrivacyPolicy: 'https://www.stackerhq.com/legal/privacy',
    Tasks: '/s/tasks',
    OnboardingStart: '/signup/get-started',
    Workflows: '/workflows',
} as const

export const StudioRoutes = [
    Urls.Login,
    Urls.SignUp,
    Urls.Auth,
    Urls.Logout,
    Urls.RegisterHidden,
    Urls.AcceptInvitation,
    Urls.SetPassword,
    Urls.ResetPassword,
]

export const ZONE_PREFIX = 'p'

const STUDIO_ROUTE_PATTERNS = StudioRoutes.map((route) => new RegExp(`^${route}(/.*)?`))

// Checks if the current path is on an auth-related route.
export function isOnStudioRoute() {
    return STUDIO_ROUTE_PATTERNS.find((pattern) => pattern.test(window.location.pathname))
}

/**
 * Returns the [portal] domain for the given zone to redirect to.
 *
 * This function is only suitable for when the user is already on a portal domain.
 *
 * If the given zone is an internal zone, the url returned will be main domain for the workspace as
 * internal zones do not have a portal domain.
 */
export const getPortalDomain = (
    zone: AccountZone,
    currentAccount: Account,
    url: string
): string => {
    if (zone.type === 'Internal') {
        return getAbsoluteWorkspaceRootUrl(currentAccount)
    }
    const hostParts = window.location.host.split('.')
    return `${window.location.protocol}//${zone.slug}.${hostParts.slice(1).join('.')}${url}`
}

export function getAbsoluteUrl(url: string) {
    return `${window.location.protocol}//${window.location.host}${url}`
}

export const getAbsoluteWorkspaceRootUrl = (account: Account) => {
    if (account.sso_required) {
        return `https://${account.custom_base_url || account.base_url}`
    }
    return `https://${settings.STUDIO_DOMAIN}/${account.slug}`
}
export const getWorkspaceUrl = (
    url: string,
    workspaceAccount?: Account | null,
    workspaceZone?: AccountZone | null
) => {
    const rootPath = getWorkspaceRootPath(workspaceAccount, workspaceZone)
    return `${rootPath}${url}`
}

export const getUrl = (url: string, stack?: StackDto | null, workspaceAccount?: Account | null) => {
    return `${getRootPath(stack, workspaceAccount)}${url}`
}

export const trimRootPathFromUrl = (url: string) => {
    const root = getRootPath()
    return root ? url.slice(root.length) : url
}

export const getWorkspaceRootPath = (
    workspaceAccount?: Account | null,
    workspaceZone?: AccountZone | null
) => {
    // We will have a root path if we are in a workspace account, and we have a
    // selected app.

    const account = workspaceAccount || getWorkspaceAccount()
    const zone = workspaceZone || GlobalStaticState.getWorkspaceZone()

    // Only use the account slug if we are on the studio domain.
    // Otherwise, we're on a custom domain which is already workspace-scoped
    let rootPath = account && isOnStudioDomain() ? account.slug : ''

    // don't include the zone in the path if it's the default zone
    // or we're on a portal domain
    if (zone && !zone.is_default && !GlobalStaticState.getIsOnPortalDomain()) {
        if (rootPath) {
            rootPath += '/'
        }
        rootPath += `${ZONE_PREFIX}/${zone.slug}`
    }
    return rootPath ? `/${rootPath}` : ''
}

export const getRootPath = (
    stack?: StackDto | null,
    workspaceAccount?: Account | null,
    workspaceZone?: AccountZone | null
) => {
    let rootPath = getWorkspaceRootPath(workspaceAccount, workspaceZone)
    const selectedStack = stack || getCurrentStack()

    rootPath += selectedStack ? `/${selectedStack?.url_slug}` : ''

    return rootPath
}

export function getWorkspaceSlugFromLocation() {
    if (isOnStudioDomain() && !isOnStudioRoute()) {
        const pathParts = window.location.pathname.split('/')
        return pathParts[1]
    }

    return undefined
}
