import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { isRightGranted } from 'features/auth/utils/roleUtils'

import { useAuthContext } from './AuthContext/AuthContext'
import { useAppContext } from './AppContext'

export const Rights = {
    CreateApps: 'create_apps',
    InviteUsers: 'invite_users',
    ManageUsers: 'manage_users',
    ManageBilling: 'manage_billing',
    ContactSupport: 'contact_support',
    ViewSettings: 'view_settings',
    ManageSettings: 'manage_settings',
    CustomScripts: 'custom_scripts',
    DeleteWorkspace: 'delete_workspace',
}

export const defaultContext = {
    hasRight: () => null,
    user: undefined,
}
export const AccountUserContext = React.createContext(defaultContext)

/**
 * @return {{ user: any, role: any, hasRight: function(string):boolean, isLoading:boolean }}
 */
export const useAccountUserContext = () => {
    return useContext(AccountUserContext)
}
/*
    This provider keeps track of the currently authenticated user and
    provides functions for checking roles and rights available to that user
    in the current account.
*/

export const AccountUserContextProvider = ({ children }) => {
    const { workspaceAccount } = useAppContext()
    const [role, setRole] = useState()
    const { user } = useAuthContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const roles = workspaceAccount?.roles || []

    // Update the user's role when the user, roles, or selectedStack change.
    useEffect(() => {
        if (!user || !roles?.length) return setRole(null)
        const role = roles.find((role) => role.api_name === user.account_role_api_name)
        setRole(role)
    }, [user, roles])

    // Returns whether or not the current user has the specified right
    // in the context of the current stack
    const hasRight = useCallback(
        (right) => {
            if (!user || !role) return false

            const rights = role.options?.rights || []

            return isRightGranted(rights, right)
        },
        [role, user]
    )

    const userHasRight = useCallback(
        (currentUser, right) => {
            if (!currentUser) return false
            const currentRole = roles.find(
                (role) => role.api_name === currentUser.account_role_api_name
            )
            if (!currentRole) return false
            const rights = currentRole.options?.rights || []

            return isRightGranted(rights, right)
        },
        [roles]
    )

    const contextState = useMemo(() => {
        return {
            user,
            role: role,
            hasRight: hasRight,
            isLoading: !user || !role,
            userHasRight,
        }
    }, [user, role, hasRight, userHasRight])
    return (
        <AccountUserContext.Provider value={contextState}>{children}</AccountUserContext.Provider>
    )
}
