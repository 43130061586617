import React, { useEffect, useMemo, useState } from 'react'

import { MenuItemOptionProps, MenuItemProps } from '@chakra-ui/menu/src/menu'
import {
    forwardRef,
    MenuOptionGroup,
    Spinner,
    useMenuItem,
    useMenuOption,
    useMenuOptionGroup,
} from '@chakra-ui/react'

import { Box, Text } from 'v2/ui'
import useDebounce from 'v2/ui/utils/useDebounce'

import { useLookupFilter } from './components/LookupFilterContext'
import { InlineFilterMultiValueInputSearch } from './InlineFilterMultiValueInputSearch'
import { InlineFilterOption, InlineFilterOptionProps } from './InlineFilterOption'
import { filterMultiValueOptions } from './multiValueInputUtils'
import { InlineFilterMultiValueOption } from './types'

const MAX_VISIBLE_OPTIONS = 6

type EmptyStateProps = {
    query: string
}

type InlineFilterMultiValueInputProps = Omit<
    React.ComponentPropsWithoutRef<typeof Box>,
    'onChange' | 'value'
> & {
    options: InlineFilterMultiValueOption[]
    value?: string[] | string
    onChange?: (value: string[] | string) => void
    searchEmptyState?: React.ComponentType<EmptyStateProps>
    singleValue?: boolean
    customOptionComponent?: (props: MenuOptionProps) => React.ReactElement | null
    optionProps?: Partial<MenuOptionProps>
}

export const InlineFilterMultiValueInput: React.FC<InlineFilterMultiValueInputProps> = ({
    value,
    options,
    onChange,
    searchEmptyState: SearchEmptyState,
    singleValue,
    customOptionComponent,
    optionProps,
    ...props
}) => {
    const isScrollable = options.length > MAX_VISIBLE_OPTIONS

    const handleChange = (value: string | string[]) => {
        const valueAsArray = Array.isArray(value) ? value : [value]
        onChange?.(singleValue ? value : valueAsArray)
    }

    const { setSearchString: setSearchStringContext, isFetching } = useLookupFilter()
    const [searchString, setSearchString] = useState('')

    const debouncedSetSearchString = useDebounce(setSearchStringContext, 300, [
        setSearchStringContext,
    ])

    // Update the context's search string when the input's search string changes.
    // This allows queries to run to fetch filtered options, particularly when fetching records, so that we don't try to render too many options
    useEffect(() => {
        debouncedSetSearchString(searchString)
    }, [debouncedSetSearchString, searchString])

    const [filteredOptions, setFilteredOptions] = useState<InlineFilterMultiValueOption[]>([])
    const debouncedSetFilteredOptions = useDebounce(setFilteredOptions, 200, undefined, {
        leading: true,
    })

    // If the primary field of a linked record is empty, the label will be null.
    // so what want to show a placeholder label in that case
    const optionsWithNoNullLabels = useMemo(
        () =>
            options.map((option) => ({
                ...option,
                label: option.label ?? '[Primary field is empty]',
            })),
        [options]
    )
    useEffect(() => {
        const filteredOptions = filterMultiValueOptions(optionsWithNoNullLabels, searchString)

        debouncedSetFilteredOptions(filteredOptions)
    }, [optionsWithNoNullLabels, searchString, debouncedSetFilteredOptions])

    const showEmptyState = searchString && filteredOptions.length < 1

    const usableValue = useMemo(() => {
        const valueIsArray = Array.isArray(value)
        if (singleValue) {
            return valueIsArray ? value?.[0] ?? '' : value
        }

        return value ?? []
    }, [singleValue, value])

    return (
        <Box {...props}>
            <SearchMenuItem
                mb={1.5}
                onChange={(e) => setSearchString((e.target as HTMLInputElement).value)}
                value={searchString}
            />
            <OptionGroup
                type={singleValue ? 'radio' : 'checkbox'}
                value={usableValue}
                onChange={handleChange}
                isScrollable={isScrollable}
            >
                {filteredOptions.map(({ value, label, color }) => (
                    <MenuOption
                        key={value}
                        label={label!}
                        color={color}
                        value={value}
                        customOptionComponent={customOptionComponent}
                        {...optionProps}
                    />
                ))}

                {isFetching && <Spinner size="sm" margin="4px auto" />}

                {showEmptyState && SearchEmptyState && !isFetching && (
                    <SearchEmptyState query={searchString} />
                )}

                {showEmptyState && !SearchEmptyState && !isFetching && (
                    <Text color="userInterface.neutral.850" px={3} py={2}>
                        No options match your search query.
                    </Text>
                )}

                {!showEmptyState && !isFetching && options.length < 1 && (
                    <Text color="userInterface.neutral.850" px={3} py={2}>
                        No options to display.
                    </Text>
                )}
            </OptionGroup>
        </Box>
    )
}

type MenuOptionProps = InlineFilterOptionProps &
    MenuItemOptionProps & {
        isScrollable?: boolean
        customOptionComponent?: InlineFilterMultiValueInputProps['customOptionComponent']
    }

const MenuOption = forwardRef<MenuOptionProps, 'button'>((props, ref): React.ReactElement => {
    const { label, color, customOptionComponent, ...rest } = props
    const {
        // Disable mouse events so that the options don't
        // steal the focus from the other inputs.
        /* eslint-disable @typescript-eslint/no-unused-vars */
        /* eslint-disable unused-imports/no-unused-vars */
        onMouseEnter,
        onMouseLeave,
        onMouseMove,
        onMouseOver,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        /* eslint-enable unused-imports/no-unused-vars */
        ...optionProps
    } = useMenuOption(rest, ref)

    const OptionComponent = customOptionComponent ? customOptionComponent : InlineFilterOption

    return (
        <OptionComponent
            {...optionProps}
            isChecked={props.isChecked}
            label={label}
            color={color}
            cursor="pointer"
        />
    )
})

// Chakra only allows components that have this property
// set to `MenuItemOption` to be used as a child of <MenuOptionGroup />.
MenuOption.id = 'MenuItemOption'

type OptionGroupProps = React.ComponentPropsWithoutRef<typeof MenuOptionGroup> & {
    isScrollable?: boolean
}

const OptionGroup: React.FC<OptionGroupProps> = ({ isScrollable, ...props }) => {
    const groupProps = useMenuOptionGroup(props)

    return (
        <Box
            role="group"
            display="flex"
            flexDirection="column"
            gap={1}
            maxHeight={isScrollable ? '13.5rem' : undefined}
            overflowY={isScrollable ? 'auto' : undefined}
            {...groupProps}
        />
    )
}

type SearchMenuItemProps = MenuItemProps &
    React.ComponentPropsWithoutRef<typeof InlineFilterMultiValueInputSearch> & {}

const SearchMenuItem = forwardRef<SearchMenuItemProps, 'input'>(
    (props, ref): React.ReactElement => {
        const {
            // These props break the input's functionality.
            /* eslint-disable @typescript-eslint/no-unused-vars */
            /* eslint-disable unused-imports/no-unused-vars */
            onKeyUp,
            onKeyDown,
            role,
            type,
            onClick,
            onMouseMove,
            onMouseLeave,
            /* eslint-enable @typescript-eslint/no-unused-vars */
            /* eslint-enable unused-imports/no-unused-vars */
            onMouseEnter,
            ...rest
        } = useMenuItem(props, ref) as any

        return <InlineFilterMultiValueInputSearch onFocus={onMouseEnter} {...rest} />
    }
)
