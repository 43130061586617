import moment from 'moment'

import { formatDate } from 'utils/dateUtils'

export function formatExecutionDate(value: string | number): string | undefined {
    const { dateValue } = formatDate(value, 'UTC', true)

    return dateValue?.local?.().format?.('lll:ss')
}

export function getExecutionDuration(execution: WorkflowExecutionDto): string | undefined {
    if (!execution.is_finished) return undefined

    const startDate = moment(execution.started_at)
    const endDate = moment(execution.finished_at)

    return formatExecutionDuration(endDate.diff(startDate))
}

export function formatExecutionDuration(duration: number): string {
    const precision = 3
    const durationValue = duration / 1000

    return `${durationValue.toFixed(precision)}s`
}

const INDENT_SPACES_PER_LEVEL = 4

export function prettyPrintExecutionErrorTrace(errorTrace: string): string {
    return errorTrace
        .trim()
        .split('\n')
        .map((l) => {
            // Increase indentation.
            const currSpacesPerLevel = 2
            const currIndentationLevel = (l.match(/^\s*/)?.[0]?.length ?? 0) / currSpacesPerLevel

            const indentation = ' '.repeat(INDENT_SPACES_PER_LEVEL * currIndentationLevel)

            return `${indentation}${l.trim()}`
        })
        .join('\n')
}
