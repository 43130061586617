import React, { ReactNode, useContext, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'

import { getDefaultPageSize } from 'v2/ui/components/List/utils'

export type PaginationContextValue = {
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
}

export const PaginationContext = React.createContext<PaginationContextValue | null>(null)

export function usePaginationContext(): PaginationContextValue | null {
    return useContext(PaginationContext)
}

export type PaginationContextProviderProps = { children: ReactNode; pageSize?: number }

export const PaginationContextProvider: React.FC<PaginationContextProviderProps> = ({
    children,
    pageSize,
}) => {
    const { view } = useListViewContext()

    const effectivePageSize =
        pageSize || view.options.pageSize || getDefaultPageSize(view.options.display)

    const [currentPageIndex, setCurrentPageIndex] = useState(0)

    const value = useMemo(
        () => ({
            currentPageIndex,
            setCurrentPageIndex,
            pageSize: effectivePageSize,
        }),
        [currentPageIndex, effectivePageSize]
    )

    return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>
}
