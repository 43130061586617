import React, { useMemo, useState } from 'react'

import * as Sentry from '@sentry/react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/GlobalStaticState'
import { invalidateAppUsersList } from 'data/hooks/users/invalidateAppUsersList'
import { useAddWorkspaceUser, useWorkspaceUsers } from 'data/hooks/users/main'
import { getWorkspaceRoles } from 'features/auth/utils/roleUtils'
import analytics from 'utils/analytics'

import { Button, Collapse, Dropdown, Flex, Icon, Input, Text } from 'v2/ui'

const InviteUsers = ({ onUserAdded, showInvitationSent = true, ...props }) => {
    const { workspaceAccount } = useAppContext()
    const { hasRight, role: currentUserRole, user } = useAccountUserContext()

    // Because this is only used in the workspace modal, we need to filter out the portal user role
    const roles = useMemo(
        () => getWorkspaceRoles(workspaceAccount?.roles),
        [workspaceAccount?.roles]
    )

    // If the user doesn't have ManageUsers right, then only allow them to invite users
    // to the same role they have.
    const roleOptions = useMemo(
        () =>
            roles
                .filter(
                    (x) => hasRight(Rights.ManageUsers) || x.api_name === currentUserRole.api_name
                )
                .map((x) => ({ label: x.label, value: x.api_name })),
        [roles, currentUserRole.api_name, hasRight]
    )

    const [processing, setProcessing] = useState()
    const [email, setEmail] = useState()
    const [error, setError] = useState()
    const [role, setRole] = useState('member')
    const [inviteSent, setInviteSent] = useState()
    const { data: users } = useWorkspaceUsers()
    const { mutateAsync: addUser } = useAddWorkspaceUser()
    const disabled = !hasRight(Rights.InviteUsers)

    const sendInvite = () => {
        if (!email || processing) {
            return
        }

        if (users?.find((x) => x.email.toLowerCase() === email.trim().toLowerCase())) {
            setError('A user with that email already exists')
            return
        }
        setError(null)
        setProcessing(true)

        addUser({ email: email.trim(), options: { role } })
            .then((response) => {
                // @TRACKING | workspace user invited
                analytics.track('workspace user invited', {
                    app_id: getCurrentStackId(),
                    workspace_id: workspaceAccount?._sid,
                    user_id: user._sid,

                    referred_user_email: email.trim(),
                    referred_user_role: role,

                    event_category: 'workspace',
                    event_description: 'A new user was invited to the workspace',
                })
                // note this is an email invite

                setInviteSent(email)
                setEmail('')
                if (onUserAdded) onUserAdded(response)
                setTimeout(() => {
                    setInviteSent(null)
                }, 5000)
                invalidateAppUsersList()
            })
            .catch((ex) => {
                Sentry.captureException(ex)
                setError('An error occurred')
                setInviteSent(null)
            })
            .finally(() => {
                setProcessing(false)
            })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            sendInvite()
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
        setError(null)
    }
    return (
        <Flex
            column
            align="stretch"
            wrap="nowrap"
            {...props}
            borderBottom="1px solid #EBECEE"
            bg="grey.100"
            px="20px"
            py={3}
        >
            <Flex width="100%" wrap="nowrap" align="stretch">
                <Input
                    flexGrow={1}
                    placeholder="Enter email address"
                    variant="settings"
                    size="sm"
                    onKeyPress={handleKeyDown}
                    value={email}
                    width
                    mr={2}
                    onChange={handleEmailChange}
                    minWidth={'50px'}
                    disabled={disabled}
                />
                <Dropdown
                    variant="settings"
                    value={role}
                    options={roleOptions}
                    onChange={setRole}
                    disabled={disabled}
                    data-testid="invite-user-role"
                />
                <Button
                    isLoading={processing}
                    onClick={sendInvite}
                    ml={2}
                    disabled={!email}
                    buttonSize="sm"
                    variant="adminPrimaryV4"
                >
                    Invite
                </Button>
            </Flex>
            <Collapse isOpen={!!error}>
                <Text variant="error" mt={2}>
                    {error}
                </Text>
            </Collapse>
            <Collapse isOpen={!!inviteSent && showInvitationSent} maxWidth="100%">
                <Text
                    variant="success"
                    my={2}
                    maxWidth="100%"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                >
                    <Icon icon="checkmarkCircle" display="inline" mr={2} />
                    Invite has been sent to {inviteSent}
                </Text>
            </Collapse>
        </Flex>
    )
}
export default InviteUsers
