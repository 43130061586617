import React from 'react'

import * as Parts from 'features/views/ListView/TableView/TableView.parts'
import { TableViewCellContent } from 'features/views/ListView/TableView/TableViewCellContent'
import { TableViewCommentCount } from 'features/views/ListView/TableView/TableViewCommentCount'
import { TableViewThumbnail } from 'features/views/ListView/TableView/TableViewThumbnail'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

type BaseCellProps = React.ComponentPropsWithoutRef<typeof Parts.Cell> & {
    column: TableViewColumn
    isEmpty?: boolean
}

export const BaseCell: React.FC<BaseCellProps> = React.memo(function BaseCell({
    children,
    column,
    isEmpty,
    style,
    ...props
}) {
    const { isPinned, isPrimary, minWidth, maxWidth } = column

    return (
        <Parts.Cell
            fontFamily="body"
            fontSize={{ mobile: 'bodyS', tablet: 'bodyM' }}
            fontWeight={isPrimary ? 'bodyBold' : 'bodyRegular'}
            lineHeight={{ mobile: 'bodyS', tablet: 'bodyM' }}
            color="text"
            isPinned={isPinned}
            style={{
                minWidth: `${minWidth}px`,
                maxWidth: maxWidth ? `${maxWidth}px` : undefined,
                ...style,
            }}
            {...props}
        >
            <TableViewCellContent column={column} isEmpty={isEmpty}>
                {isPrimary && <TableViewThumbnail mr="xl" />}
                {children}
                {isPrimary && <TableViewCommentCount ml="m" />}
            </TableViewCellContent>
        </Parts.Cell>
    )
})
