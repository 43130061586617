import React, { useCallback, useMemo, useState } from 'react'

export enum BreadcrumbsMode {
    Full,
    BackButtonOnly,
    Hidden,
}

export type HeaderContextType = {
    colorMode: null | 'dark' | 'light'
    setColorMode: (mode: null | 'dark' | 'light') => void
    headerImageVisible: boolean
    setHeaderImageVisible: (val: boolean) => void
    showBreadcrumbs: boolean
    setShowBreadcrumbs: (val: boolean) => void
    breadcrumbsMode: BreadcrumbsMode
    setBreadcrumbsMode: (mode: BreadcrumbsMode) => void
    reset: () => void
}

export const HeaderContext = React.createContext<HeaderContextType | null>(null)

export const HeaderContextProvider: React.FC = ({ children }) => {
    const [colorMode, setColorMode] = useState<null | 'dark' | 'light'>(null)
    const [headerImageVisible, setHeaderImageVisible] = useState(false)
    const [showBreadcrumbs, setShowBreadcrumbs] = useState(false)
    const [breadcrumbsMode, setBreadcrumbsMode] = useState(BreadcrumbsMode.Full)

    const reset = useCallback(() => {
        setColorMode(null)
        setHeaderImageVisible(false)
        setBreadcrumbsMode(BreadcrumbsMode.Full)
    }, [])

    const state = useMemo(
        () => ({
            colorMode,
            setColorMode,
            headerImageVisible,
            setHeaderImageVisible,
            reset,
            showBreadcrumbs,
            setShowBreadcrumbs,
            breadcrumbsMode,
            setBreadcrumbsMode,
        }),
        [breadcrumbsMode, colorMode, headerImageVisible, reset, showBreadcrumbs]
    )

    return <HeaderContext.Provider value={state}>{children}</HeaderContext.Provider>
}
