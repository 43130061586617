import React, { useContext } from 'react'
// @ts-strict-ignore - because typescript strict complains about this import being untyped

export const Rights = {
    Admin: {
        All: 'admin',
        Any: 'admin.*',
        DataConnections: {
            All: 'admin.dataconnections',
            Any: 'admin.dataconnections.*',
            View: 'admin.dataconnections.view',
        },
    },
    AllDataAccess: 'all_data_access',
}

export type AppUserContextValue = {
    user?: AuthedUserDto
    role?: RoleDto | null
    isLoggedIn: boolean
    isPreviewingAsUserOrRole: boolean
    isLoading: boolean
    isLoadingIncludingStack: boolean
    isAdmin: boolean
    authStateKey?: number
    hasRight: (right: string) => boolean
}
export const defaultContext: AppUserContextValue = {
    user: undefined,
    role: undefined,
    isLoggedIn: false,
    isPreviewingAsUserOrRole: false,
    isLoading: false,
    isLoadingIncludingStack: false,
    isAdmin: false,
    authStateKey: undefined,
    hasRight: () => false,
}

export const AppUserContext = React.createContext(defaultContext)

export const useAppUserContext = () => {
    return useContext(AppUserContext)
}
export default AppUserContext
