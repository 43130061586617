import React from 'react'

import AuthPageFrame from './stacker-auth/AuthPageFrame'
import SignInFlow from './stacker-auth/SignInFlow'

export function LoginPage() {
    return (
        <AuthPageFrame title="Log in">
            <SignInFlow />
        </AuthPageFrame>
    )
}

export default LoginPage
