import React from 'react'

import { DatePicker } from 'ui/components/DatePicker'
import { DropdownSub, DropdownSubContent, DropdownSubTrigger } from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditDateState } from './hooks/useActionMenuEditDateState'
import { ActionMenuEditComponent } from './types'

import { ActionMenuEditDateStyle } from './ActionMenuEditDate.css'

type ActionMenuEditDateProps = {}

export const ActionMenuEditDate: ActionMenuEditComponent<string, ActionMenuEditDateProps> =
    React.memo(function ActionMenuEditDate(props) {
        const { value, fieldLabel, onEditValue, showTime, fieldIcon } =
            useActionMenuEditDateState(props)

        return (
            <DropdownSub>
                <DropdownSubTrigger
                    startIcon={fieldIcon?.type === 'lucide' ? { name: fieldIcon.name } : undefined}
                    label={fieldLabel}
                />
                <DropdownSubContent
                    className={ActionMenuEditDateStyle}
                    style={{ minWidth: 0, maxHeight: 'auto', padding: 0 }}
                    onClick={stopPropagation}
                >
                    <DatePicker showTime={showTime} value={value} onChange={onEditValue} />
                </DropdownSubContent>
            </DropdownSub>
        )
    })
