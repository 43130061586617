import { useCallback, useMemo, useState } from 'react'

import { Variants } from 'v2/ui/components/Banner'

import { RecordEditManagerResult } from './useRecordEditManager'
import useShowConfirmPasting from './useShowConfirmPasting'

export type UseImportOnPasteProps = {
    importRawRecords?: (
        updates: { _sid: string | null; data: Record<string, string> }[]
    ) => Promise<void>
    dataSourceSupportsPasting?: boolean
    dataSourceLabel?: string
    pendingNewRecords: RecordEditManagerResult['pendingNewRecords']
    pendingDeletes: RecordEditManagerResult['pendingDeletes']
    pendingEdits: RecordEditManagerResult['pendingEdits']
    failedRecords: RecordEditManagerResult['failedRecords']
    records: RecordDto[]
    fields: FieldDto[]
}

export function useImportOnPaste({
    importRawRecords,
    dataSourceSupportsPasting,
    dataSourceLabel,
    pendingNewRecords,
    pendingDeletes,
    pendingEdits,
    failedRecords,
    records,
    fields,
}: UseImportOnPasteProps) {
    const [pastingIndicator, setPastingIndicator] = useState<{
        show: boolean
        text: string
        icon: string
        variant: Variants
        useTimer: boolean
    }>({
        show: false,
        text: '',
        icon: 'info',
        variant: 'ImportantInformation',
        useTimer: false,
    })

    const showConfirmPasting = useShowConfirmPasting()

    const showSuccessBanner = (numberRecordsUpdated: number, numberRecordsCreated: number) => {
        const updatedRowsLabel = formatRowCount(numberRecordsUpdated)
        const createdRowsLabel = formatRowCount(numberRecordsCreated)

        setPastingIndicator({
            show: true,
            text: `${updatedRowsLabel} updated${
                numberRecordsCreated > 0 ? `, ${createdRowsLabel} created` : ''
            }.`,
            icon: 'info',
            variant: 'ImportantInformation',
            useTimer: true,
        })
    }

    const hidePastingIndicator = useCallback(() => {
        setPastingIndicator({
            show: false,
            text: '',
            icon: 'info',
            variant: 'Information',
            useTimer: false,
        })
    }, [])

    const processImportRawRecords = useCallback(
        async (
            colIndex: number,
            rowIndex: number,
            values: string[][],
            numberRecordsUpdated: number,
            numberRecordsCreated: number
        ): Promise<void> => {
            if (
                pendingNewRecords.length > 0 ||
                pendingDeletes.length > 0 ||
                Object.keys(pendingEdits).length > 0 ||
                Object.keys(failedRecords).length > 0
            ) {
                setPastingIndicator({
                    show: true,
                    text: 'Cannot paste while records are being updated…',
                    icon: 'info',
                    variant: 'Warning',
                    useTimer: true,
                })

                return
            }

            setPastingIndicator({
                show: true,
                text: 'Pasting...',
                icon: 'info',
                variant: 'ImportantInformation',
                useTimer: false,
            })

            const updates = values.map((rowValues, currentRow) => {
                const record = records[currentRow + rowIndex]
                const fieldsToUpdate: { [keyof: string]: string } = {}

                rowValues.forEach((toPaste, currentCol) => {
                    const field = fields[currentCol + colIndex]
                    if (!!field) {
                        fieldsToUpdate[field.api_name] = toPaste
                    }
                })

                return { _sid: record?._sid || null, data: fieldsToUpdate }
            })

            try {
                await importRawRecords?.(updates)
                showSuccessBanner(numberRecordsUpdated, numberRecordsCreated)
            } catch (error) {
                setPastingIndicator({
                    show: true,
                    text: 'Failed to paste',
                    icon: 'warning',
                    variant: 'Error',
                    useTimer: true,
                })
            }
        },
        [
            fields,
            failedRecords,
            importRawRecords,
            records,
            pendingDeletes.length,
            pendingEdits,
            pendingNewRecords.length,
        ]
    )

    const handlePaste = useCallback(
        ({
            colIndex,
            rowIndex,
            values,
            selectionX,
            selectionY,
            selectionWidth,
            selectionHeight,
        }: {
            colIndex: number
            rowIndex: number
            values: string[][]
            selectionX: number
            selectionY: number
            selectionWidth: number
            selectionHeight: number
        }) => {
            if (!importRawRecords) {
                return
            }

            if (!dataSourceSupportsPasting) {
                setPastingIndicator({
                    show: true,
                    text: `Bulk pasting is not supported for ${dataSourceLabel}...`,
                    icon: 'warning',
                    variant: 'Error',
                    useTimer: true,
                })
                return
            }

            // This logic removes any empty lines that live at the end of the pasted data
            // It prevents the creation of (many) empty records when pasting

            // Iterate through the pasted rows from the end to the start and stop at the first non-empty row or when there's no row any more
            let rowIsEmpty = true
            let i = values.length
            while (i > 0 && rowIsEmpty) {
                i -= 1
                const rowValues = values[i]
                rowIsEmpty = !rowValues.some((toPaste) => toPaste.length > 0)
            }

            // If the last tested row is empty, we reached the end and there is only empty rows, nothing to do
            if (rowIsEmpty) {
                return
            }

            // Remove all the empty rows
            const valuesToPaste = values.slice(0, i + 1)
            if (valuesToPaste.length === 0) {
                return
            }

            // If we have more rows selected than rows to paste,
            // we're just going to repeat the last pasted row until we have
            // enough rows to cover the selection. This allows the user
            // to paste a single row into a selection of multiple rows, etc.
            if (selectionHeight > valuesToPaste.length) {
                const rowsToAdd = selectionHeight - valuesToPaste.length
                for (let i = 0; i < rowsToAdd; i++) {
                    valuesToPaste.push([...valuesToPaste[values.length - 1]])
                }
            }

            const recordCount = records.length

            const isPastingOverflowingSelection =
                rowIndex + valuesToPaste.length > selectionY + selectionHeight ||
                colIndex + values[0].length > selectionX + selectionWidth

            const willAddRecords = recordCount < rowIndex + valuesToPaste.length

            const numberRecordsToAdd = willAddRecords
                ? rowIndex + valuesToPaste.length - recordCount
                : 0

            const numberRecordsUpdated = willAddRecords
                ? valuesToPaste.length - numberRecordsToAdd
                : valuesToPaste.length
            const numberRecordsCreated = willAddRecords ? numberRecordsToAdd : -1

            if (isPastingOverflowingSelection) {
                showConfirmPasting(
                    numberRecordsUpdated,
                    numberRecordsCreated,
                    () =>
                        processImportRawRecords(
                            colIndex,
                            rowIndex,
                            valuesToPaste,
                            numberRecordsUpdated,
                            numberRecordsCreated
                        ),
                    () => undefined
                )

                return
            }

            processImportRawRecords(
                colIndex,
                rowIndex,
                valuesToPaste,
                numberRecordsUpdated,
                numberRecordsCreated
            )
        },
        [
            dataSourceLabel,
            dataSourceSupportsPasting,
            importRawRecords,
            processImportRawRecords,
            records.length,
            showConfirmPasting,
        ]
    )

    return useMemo(
        () => ({
            handlePaste,
            hidePastingIndicator,
            pastingIndicator,
        }),
        [handlePaste, hidePastingIndicator, pastingIndicator]
    )
}

function formatRowCount(count: number): string {
    if (count === 1) {
        return '1 row'
    }

    return `${count} rows`
}
