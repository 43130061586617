import React, { forwardRef, useRef } from 'react'

import { useColumnWidth } from 'features/datagrid/hooks/useColumnWidth'
import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'
import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import DocumentAttribute, { TipTapValue } from 'v2/ui/components/Attribute/DocumentAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCell, DataGridCellEditorComponent } from './types'

import { DocumentCellStyle } from './DocumentCell.css'

type DocumentCellEditorProps = {}

const DocumentCellEditor: DataGridCellEditorComponent<DocumentCellEditorProps> = forwardRef(
    ({ value, field, column, onKeyDown }, ref) => {
        const [newValue, setNewValue] = useEditorValue<TipTapValue | null>(ref, value)

        const tiptapRef = useRef<TipTapEditorHandle | null>(null)

        const handleTypeInCell = (key: string) => {
            tiptapRef?.current?.editor
                ?.chain()
                .focus('end')
                .insertContent({
                    type: 'text',
                    text: key,
                })
                .run()
        }

        useTypeInCellToEdit({
            onChange: handleTypeInCell,
        })

        const columnWidth = useColumnWidth(column)

        return (
            <Box height="full" width="400px" style={{ minWidth: `${columnWidth}px` }}>
                <DocumentAttribute
                    ref={tiptapRef}
                    field={field}
                    onChange={setNewValue}
                    mode={modes.editing}
                    className={DocumentCellStyle}
                    renderOptions={{
                        autoFocus: true,
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Tab') {
                            if (e.defaultPrevented) return
                            e.preventDefault()

                            onKeyDown(e.nativeEvent)
                        }
                    }}
                >
                    {newValue!}
                </DocumentAttribute>
            </Box>
        )
    }
)

export const DocumentCell: DataGridCell = {
    provideEditor: DocumentCellEditor,
    popupEditor: true,
}
