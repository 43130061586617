import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@chakra-ui/react'
import History from 'history'

import { useAppContext } from 'app/AppContext'
import { useViews } from 'data/hooks/views'
import { ViewBlock } from 'features/pages/blocks/blockTypes/view/ViewBlock'

import { Modal } from 'v2/ui'
import usePrevious from 'v2/ui/hooks/usePrevious'
import useRefCallback from 'v2/ui/utils/useRefCallback'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { LinkButton } from 'ui/components/LinkButton'

import {
    NavigationState,
    PreviewRecordContextBreadcrumbs,
    PreviewRecordContextRecordList,
    PreviewRecordScrollPosition,
    usePreviewRecordContext,
} from './PreviewRecordContext'

import { PreviewRecordModalWrapperStyle } from './PreviewRecordModal.css'

type PreviewRecordModalProps = {}

export const PreviewRecordModal: React.FC<PreviewRecordModalProps> = () => {
    const {
        recordId,
        objectId,
        isModalOpen,
        closePreview,
        recordList,
        fullscreenPath,
        breadcrumbs,
        goBack,
    } = usePreviewRecordContext()

    const [bodyRef, setBodyRef] = useRefCallback<HTMLDivElement>()

    const setBodyElRef = useCallback(
        (el: HTMLDivElement) => {
            const modalBody = el?.closest('.chakra-modal__body')
            if (modalBody) setBodyRef(modalBody as HTMLDivElement)
        },
        [setBodyRef]
    )

    const setModalScrollPosition = useCallback(
        (pos: PreviewRecordScrollPosition) => {
            bodyRef.current?.scrollTo({
                top: pos[1],
                left: pos[0],
            })
        },
        [bodyRef]
    )

    const location = useLocation()
    const prevRecordId = usePrevious(recordId)
    useLayoutEffect(() => {
        if (recordId === prevRecordId) return

        const locationState = location.state as NavigationState
        const scrollPosition = locationState?.scrollPosition
        if (!scrollPosition || !isModalOpen) return

        window.requestAnimationFrame(() => {
            setModalScrollPosition(scrollPosition)
        })
    }, [isModalOpen, location.state, prevRecordId, recordId, setModalScrollPosition])

    const [isEditing, setIsEditing] = useState(false)
    useLayoutEffect(() => {
        setIsEditing(false)
    }, [recordId])

    const handleClose = () => {
        if (isEditing) return

        closePreview()
    }

    const theme = useTheme()

    const handleCloseRef = useRef(handleClose)
    handleCloseRef.current = handleClose

    useLayoutEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (!isModalOpen) return

            const handleClose = handleCloseRef.current
            if (e.key === 'Escape' && !e.defaultPrevented) {
                handleClose()
            }
        }

        window.addEventListener('keydown', listener)
        return () => window.removeEventListener('keydown', listener)
    }, [isModalOpen])

    return (
        <Modal
            isOpen={isModalOpen}
            motionPreset="slideInRight"
            scrollBehavior="inside"
            size="full"
            height="100dvh"
            noDividers={false}
            onClose={handleClose}
            textAlign="left"
            closeOnOverlayClick={false}
            useInert={false}
            id="preview-record-modal"
            body={
                <PreviewRecordModalContent
                    ref={setBodyElRef}
                    recordId={recordId}
                    objectId={objectId}
                    closePreview={handleClose}
                    pt="m"
                />
            }
            contentProps={{
                backgroundColor: 'backgroundColor',
                position: 'absolute',
                margin: 0,
                top: 0,
                right: 0,
                bottom: 0,
                maxHeight: 'none',
                maxWidth: '1000px',
                outline: 'none',
                roundedRight: 'none',
                boxSize: ['100%', null, null, '60%'],
                borderLeft: '1px solid',
                borderColor: 'userInterface.neutral.500',
                pointerEvents: 'auto',
                boxShadow: `${theme.shadows.lg}!important`,
            }}
            containerProps={{
                pointerEvents: 'none',
            }}
            customOverlay={<></>}
            noPadding={true}
            headerVariant="custom"
            title={
                <PreviewRecordModalHeader
                    fullscreenPath={fullscreenPath}
                    recordList={recordList}
                    breadcrumbs={breadcrumbs}
                    goBack={goBack}
                    setModalScrollPosition={setModalScrollPosition}
                    isEditing={isEditing}
                />
            }
            trapFocus={false}
            zIndex={999}
            closeButtonProps={{
                disabled: isEditing,
            }}
            dividerProps={{
                mb: 0,
            }}
        />
    )
}

type PreviewRecordModalNavigationProps = React.ComponentPropsWithoutRef<typeof Box> & {
    totalCount: number
    currentIndex: number
    direction: 'horizontal' | 'vertical'
    goNext: () => void
    goPrevious: () => void
    setModalScrollPosition: (pos: PreviewRecordScrollPosition) => void
    isEditing?: boolean
}

const PreviewRecordModalNavigation: React.FC<PreviewRecordModalNavigationProps> = ({
    totalCount,
    currentIndex,
    direction,
    goNext,
    goPrevious,
    setModalScrollPosition,
    isEditing,
    ...props
}) => {
    const canGoNext = currentIndex < totalCount - 1
    const canGoPrevious = currentIndex > 0

    const isHorizontal = direction === 'horizontal'

    const handleRecordListGoNext = () => {
        setModalScrollPosition([0, 0])
        goNext()
    }

    const handleRecordListGoPrevious = () => {
        setModalScrollPosition([0, 0])
        goPrevious()
    }

    return (
        <Box flex center gap="m" {...props}>
            <Box flex center gap="xs">
                <Button
                    variant="secondary"
                    startIcon={{ name: isHorizontal ? 'ChevronLeft' : 'ChevronUp' }}
                    size="xs"
                    onClick={handleRecordListGoPrevious}
                    type="button"
                    disabled={isEditing || !canGoPrevious}
                />
                <Button
                    variant="secondary"
                    startIcon={{ name: isHorizontal ? 'ChevronRight' : 'ChevronDown' }}
                    size="xs"
                    onClick={handleRecordListGoNext}
                    type="button"
                    disabled={isEditing || !canGoNext}
                />
            </Box>
            <Box flex center gap="xs">
                <Box fontSize="bodyS" fontWeight="bodyRegular" color="text">
                    {currentIndex + 1}
                </Box>
                <Box fontSize="bodyS" fontWeight="bodyRegular" color="textWeak">
                    / {totalCount}
                </Box>
            </Box>
        </Box>
    )
}

type PreviewRecordModalHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    goBack: () => void
    setModalScrollPosition: (pos: PreviewRecordScrollPosition) => void
    recordList?: PreviewRecordContextRecordList
    breadcrumbs?: PreviewRecordContextBreadcrumbs
    isEditing?: boolean
    fullscreenPath?: History.LocationDescriptor
}

const PreviewRecordModalHeader: React.FC<PreviewRecordModalHeaderProps> = ({
    setModalScrollPosition,
    recordList,
    breadcrumbs,
    goBack,
    isEditing,
    fullscreenPath,
    ...props
}) => {
    return (
        <Box
            flex
            center
            grow
            justifyContent="space-between"
            pr="xs"
            style={{ maxWidth: 'calc(100% - 32px)' }}
            {...props}
        >
            {recordList ? (
                <PreviewRecordModalNavigation
                    totalCount={recordList.totalCount}
                    currentIndex={recordList.currentIndex}
                    direction={recordList.direction}
                    goNext={recordList.goNext}
                    goPrevious={recordList.goPrevious}
                    setModalScrollPosition={setModalScrollPosition}
                    ml="m"
                    isEditing={isEditing}
                />
            ) : (
                <PreviewRecordModalBreadcrumbs
                    breadcrumbs={breadcrumbs}
                    goBack={goBack}
                    isEditing={isEditing}
                />
            )}
            <LinkButton
                variant="dotted"
                startIcon={{ name: 'Maximize2' }}
                size="s"
                to={fullscreenPath}
                style={{ marginLeft: 'auto' }}
                noShrink
                disabled={isEditing}
                replace
            >
                Expand
            </LinkButton>
        </Box>
    )
}

type PreviewRecordModalBreadcrumbsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    goBack: () => void
    breadcrumbs?: PreviewRecordContextBreadcrumbs
    isEditing?: boolean
}

const PreviewRecordModalBreadcrumbs: React.FC<PreviewRecordModalBreadcrumbsProps> = ({
    breadcrumbs,
    goBack,
    isEditing,
    ...props
}) => {
    const prevItem = breadcrumbs?.[breadcrumbs.length - 1]
    if (!prevItem) return null

    const handleGoBack = (e: React.MouseEvent) => {
        goBack()
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <Box {...props}>
            <LinkButton
                variant="ghost"
                startIcon={{ name: 'ArrowLeft' }}
                size="s"
                color="text"
                to={`#${prevItem.hash}`}
                width="full"
                onClick={handleGoBack}
                aria-disabled={isEditing}
            >
                <Box as="span" width="full" trim>
                    Back to {prevItem.title}
                </Box>
            </LinkButton>
        </Box>
    )
}

type PreviewRecordModalContentProps = React.ComponentPropsWithoutRef<typeof Box> & {
    recordId: string | null
    objectId: string | null
    closePreview: () => void
}

const PreviewRecordModalContent = React.forwardRef<HTMLDivElement, PreviewRecordModalContentProps>(
    ({ recordId, objectId, closePreview, ...props }, ref) => {
        const { data: views } = useViews()
        const { selectedStack } = useAppContext()

        const detailView = useMemo(() => {
            return views?.find((v) => v.object_id === objectId && v.type === 'detail')
        }, [views, objectId])

        const attrs = useMemo(
            () => ({
                viewId: detailView?._sid,
            }),
            [detailView?._sid]
        )

        const context = useMemo(
            () => ({
                url: {
                    id: recordId,
                },
            }),
            [recordId]
        )

        if (!recordId || !detailView) return null

        return (
            <Box ref={ref} className={PreviewRecordModalWrapperStyle} {...props}>
                <ViewBlock
                    attrs={attrs}
                    context={context}
                    isEditing={false}
                    fromListView={true}
                    isRecordList={true}
                    storeTabState={true}
                    stack={selectedStack}
                    key={recordId}
                    onRecordLoadError={closePreview}
                />
            </Box>
        )
    }
)
