import React, { forwardRef } from 'react'

import type { PopperContentProps, TooltipProps as PrimitiveProps } from '@radix-ui/react-tooltip'

import { extractStandardProps, StandardComponentProps } from 'ui/helpers/styles'
import { useTheme } from 'ui/styling/themes/ThemeProvider'

import * as Parts from './Tooltip.parts'

type TooltipRef = HTMLButtonElement

export type TooltipProps = PrimitiveProps &
    StandardComponentProps & {
        content: React.ReactNode
        side?: PopperContentProps['side']
        align?: PopperContentProps['align']
        zIndex?: number
        asChild?: boolean
        className?: string
    }

export const Tooltip = forwardRef<TooltipRef, TooltipProps>(
    ({ content, children, side = 'top', zIndex, asChild, align, ...props }, ref) => {
        const [standardProps, { className, ...rootProps }] = extractStandardProps(props)
        const { themeClassName } = useTheme()

        return (
            <Parts.Provider>
                <Parts.Root {...rootProps}>
                    <Parts.Trigger ref={ref} asChild className={className} {...standardProps}>
                        {asChild ? (
                            children
                        ) : (
                            <span role="button" tabIndex={0}>
                                {children}
                            </span>
                        )}
                    </Parts.Trigger>
                    <Parts.Portal>
                        <Parts.Content
                            style={{ zIndex }}
                            side={side}
                            sideOffset={8}
                            align={align}
                            className={themeClassName}
                        >
                            {content}
                        </Parts.Content>
                    </Parts.Portal>
                </Parts.Root>
            </Parts.Provider>
        )
    }
)
