import React, { useCallback } from 'react'

import useConfirmModal from 'app/ConfirmModal'

import { Icon, Text } from 'v2/ui'
import { DeleteData } from 'v2/ui/svgs'
import { Modal } from 'v2/ui/utils/useModalToggle'

import { useWorkflowChainContext } from './WorkflowChainContext'
import { useWorkflowEditorContext } from './WorkflowEditorContext'

export const useConfirmDeleteNodeModal = () => {
    const { show } = useConfirmModal()
    const { deleteNode } = useWorkflowEditorContext()
    const { chainPath } = useWorkflowChainContext()

    return useCallback(
        (id: string) => {
            show({
                title: 'Confirm Delete',
                confirmVariant: 'PrimaryError',
                icon: <Icon svg={<DeleteData />} size="56px" ml="15px" mb={4} />,
                message: (
                    <Text>
                        Are you sure you want to delete this node? This action cannot be undone.
                    </Text>
                ),
                onConfirm: async (modal: Modal<unknown>) => {
                    deleteNode(id, chainPath)

                    modal.setIsOpen(false)
                },
                onCancel: (modal: Modal<unknown>) => {
                    modal.setIsOpen(false)
                },
            })
        },
        [show, deleteNode, chainPath]
    )
}
