import { useCallback, useMemo } from 'react'

import { filterSelectedFields, filterSupportedFields } from 'features/views/ListView/Filters/fields'
import { getInlineFilterType, getRecommendedFieldSids } from 'features/views/ListView/Filters/utils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

type UseInlineFiltersControlsStateOptions = {
    config: ListViewOptions
    setConfig: (config: Partial<ListViewOptions>) => void
}

export function useInlineFiltersControlsState(options: UseInlineFiltersControlsStateOptions) {
    const { config, setConfig } = options

    const inlineFiltersType = getInlineFilterType(config) ?? ''
    const handleInlineFiltersTypeChange = useCallback(
        (type: string) => {
            const newType = type === '' ? undefined : (type as ListViewInlineFilters)

            setConfig({
                enableEndUserFilters: !!newType,
                inlineFilters: newType,
            })
        },
        [setConfig]
    )

    const filterFieldType = config.enableSpecificEndUserFilters ? 'specific' : 'all'
    const handleFieldTypeChange = useCallback(
        (type: string) => {
            setConfig({
                enableSpecificEndUserFilters: type === 'specific',
            })
        },
        [setConfig]
    )

    const { object, allFields } = useListViewContext()
    const supportedFields = filterSupportedFields(allFields, inlineFiltersType || undefined)
    const selectedFieldSids = filterSelectedFields(
        config.specificEndUserFilters ?? [],
        supportedFields
    )

    const handleUpdateSelectedFieldSids = useCallback(
        (selectedFieldSids: { fieldId: string; label: string }[]) => {
            setConfig({
                specificEndUserFilters: selectedFieldSids.map((item) => item.fieldId),
            })
        },
        [setConfig]
    )

    const supportedRecommendedFields = useMemo(() => {
        if (filterFieldType === 'specific') {
            const specificFields = new Set(config.specificEndUserFilters ?? [])

            return supportedFields.filter((field) => specificFields.has(field._sid))
        }

        return supportedFields
    }, [config.specificEndUserFilters, filterFieldType, supportedFields])

    const recommendedFieldsSids = useMemo(() => getRecommendedFieldSids(config), [config])
    const onRecommendedFieldsChange = useCallback(
        (fieldSids: string[]) => {
            setConfig({
                inlineFiltersRecommendedFields: fieldSids,
            })
        },
        [setConfig]
    )

    return useMemo(
        () => ({
            object,
            inlineFiltersType,
            handleInlineFiltersTypeChange,
            filterFieldType,
            handleFieldTypeChange,
            supportedFields,
            selectedFieldSids,
            handleUpdateSelectedFieldSids,
            supportedRecommendedFields,
            recommendedFieldsSids,
            onRecommendedFieldsChange,
        }),
        [
            object,
            inlineFiltersType,
            handleInlineFiltersTypeChange,
            filterFieldType,
            handleFieldTypeChange,
            supportedFields,
            selectedFieldSids,
            handleUpdateSelectedFieldSids,
            supportedRecommendedFields,
            recommendedFieldsSids,
            onRecommendedFieldsChange,
        ]
    )
}
