// @ts-strict-ignore
import { useEffect, useMemo } from 'react'
import { useQuery as _useQuery } from 'react-query'

import { useAppContext } from 'app/AppContext'
import { objectApi } from 'data/api/objectApi'
import { fetchAndReturn } from 'data/utils/utils'
import { isBlankPageObject } from 'features/blank-pages/utils'

import { groupFieldsByObjectId } from './fields/fieldOperations'
import { getObject } from './objects/getObject'
import { OBJECT_LIST_NAME, SHARED_OBJECT_LIST_NAME } from './objects/objectConstants'
import { createObject, invalidateObjects, updateObject } from './objects/objectOperations'
import { getRecord } from './records/getRecord'
import {
    createRecord,
    deleteRecord,
    invalidateRecords,
    updateRecord,
    updateRecordCache,
} from './records/recordOperations'
import { queryClient, QueryOptions, useQueryKeyBuilder } from './_helpers'
import { bulkUpdateFields, createField, deleteField, updateField, useFields } from './fields'

type UseObjects = {
    options?: QueryOptions<ObjectDto[]>
    allowBlankPageObjects?: boolean
}

// Include the current auth information (user id, previewed user, previewed role, etc) in the ky
// so that when that info changes we refetch, as the permissions annotations on the objects
// needs updated
function useQueryKey() {
    return useQueryKeyBuilder(OBJECT_LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}

export function useObjects({ options = {}, allowBlankPageObjects }: UseObjects = {}) {
    const queryKey = useQueryKey()
    const { selectedStack } = useAppContext()

    const { data: fields } = useFields()

    const queryResult = _useQuery<ObjectDto[]>(
        queryKey,
        async () => {
            const objects = (await objectApi.get(undefined, options)) as ObjectDto[]
            const fieldsByObj = fields ? groupFieldsByObjectId(fields) : {}

            return objects.map((object) => ({
                ...object,
                fields: fieldsByObj ? fieldsByObj[object?._sid] || [] : [],
            }))
        },
        {
            enabled:
                (!options.disabled || (options.disabledFn && !options.disabledFn())) &&
                !!selectedStack?._sid,
        }
    )

    const data = useMemo(() => {
        const fieldsByObj = fields ? groupFieldsByObjectId(fields) : {}
        return queryResult.data?.reduce<ObjectDto[]>((agg, curr) => {
            if (allowBlankPageObjects || (!allowBlankPageObjects && !isBlankPageObject(curr))) {
                const currObjectFields = fieldsByObj[curr?._sid] || []

                agg.push({
                    ...curr,
                    fields: currObjectFields,
                })
            }

            return agg
        }, []) as ObjectDto[]
    }, [allowBlankPageObjects, fields, queryResult.data])

    return {
        ...queryResult,
        data,
    }
}

export function useSharedWorkspaceObjects() {
    const queryKey = useQueryKeyBuilder(SHARED_OBJECT_LIST_NAME, { includeAuthKeys: true })
    const { workspaceAccount } = useAppContext()

    useEffect(() => {
        // once account's shared_resources_stack option becomes available, we want to invalidate the query so the latest
        // list of shared objs is fetched
        if (workspaceAccount?.options?.shared_resources_stack) {
            queryClient.invalidateQueries([SHARED_OBJECT_LIST_NAME])
        }
    }, [workspaceAccount?.options?.shared_resources_stack])

    return _useQuery<ObjectDto[]>(
        queryKey,
        async () => {
            const results = (await fetchAndReturn(
                `objects/?stack_id=${workspaceAccount?.options?.shared_resources_stack}`
            )) as ObjectDto[]

            return results
        },
        {
            enabled: !!workspaceAccount?.options?.shared_resources_stack,
        }
    )
}

const getPrimaryField = (fields: any[] | undefined) => {
    if (!fields) return { api_name: '_sid' }
    const primaries = fields.filter((field) => field.is_primary)
    if (!primaries.length) return { api_name: '_sid' }
    return primaries[0]
}

type RecordActionType = {
    update: (
        id: string,
        data: object,
        includeFields: string[] | undefined,
        options?: any
    ) => Promise<any>
    create: (data: any, includeFields: string[] | undefined, options?: any) => Promise<any>
    remove: (id: string) => Promise<any>
    fetch: (id: string, options?: any) => Promise<any>
    onChange: (
        id: string,
        data: object,
        includeFields: string[] | undefined,
        options?: any
    ) => Promise<any>
    updateRecordCache: (data: RecordDto | RecordDto[], includeFields: string[] | undefined) => void
    clear: (objectId: string) => void
}

export const useRecordActions = () => {
    const recordActions: RecordActionType = useMemo(() => {
        return {
            update: updateRecord,
            create: createRecord,
            remove: deleteRecord,
            fetch: getRecord,
            onChange: updateRecord,
            updateRecordCache,
            clear: invalidateRecords,
        }
    }, [])

    return recordActions
}

export function useObject(objectId: string | undefined) {
    const { data: objects = [] } = useObjects({ allowBlankPageObjects: true })

    const object = useMemo(() => {
        if (!objectId) return undefined

        return objects.find((obj) => obj._sid === objectId)
    }, [objectId, objects])

    return useMemo(
        () => {
            return {
                object,
                primaryField: getPrimaryField(object?.fields),
                fetch: getObject,
                clear: invalidateObjects,
                onChange: updateObject,
                createField: (data, options = {}) =>
                    createField(
                        {
                            ...data,
                            object_id: object?._sid,
                        },
                        options
                    ),
                createObject,
                changeObject: (data, options = {}) => updateObject(objectId, data, options),
                deleteField,
                changeField: updateField,
                bulkChangeFields: bulkUpdateFields,
                schemaTimestamp: object?.schemaTimestamp,
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [object, objectId]
    )
}

export function getConnectedObjectsFromIDs(objectIDs: string[], allObjects: ObjectDto[]) {
    const objects: ObjectDto[] = []

    objectIDs.forEach((objectSid) => {
        const object = allObjects.find((o) => o._sid === objectSid)

        // do not show object with data_mapping_disabled set true
        if (!object?.connection_options || object.connection_options.data_mapping_disabled) {
            return
        }

        objects.push(object)
    })

    return objects
}
