import { useCallback, useMemo, useRef } from 'react'

import { updateRecord as originalUpdateRecord } from 'data/hooks/records/recordOperations'
import { useRecordEditManagerContext } from 'features/records/RecordEditManagerContext'
import { isFieldEditable } from 'features/views/ListView/Actions/utils'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

type UseActionMenuSectionEditStateOptions = {
    record: RecordDto
    editableFields: FieldDto[]
}

export function useActionMenuSectionEditState(options: UseActionMenuSectionEditStateOptions) {
    const { record, editableFields } = options
    const recordRef = useRef(record)
    recordRef.current = record

    const { object } = useListViewContext()
    const editManager = useRecordEditManagerContext()

    const objectSid = object._sid
    const recordSid = record._sid

    const updateRecord = useCallback(
        async (patch: Partial<RecordDto>) => {
            if (editManager) {
                const record = recordRef.current
                await editManager.editRecord(record, patch)
            } else {
                await originalUpdateRecord(recordSid, patch, undefined, { deferStoreUpdate: true })
            }

            // Record the recent action.
            const fieldApiName = Object.keys(patch)[0]
            const value = patch[fieldApiName]
            persistRecentAction(fieldApiName, objectSid, value)
        },
        [editManager, objectSid, recordSid]
    )

    const { recentFields, otherFields } = useMemo(() => {
        const recentActions = restoreRecentActions(objectSid)

        return extractFields(recentActions, editableFields)
    }, [objectSid, editableFields])

    const editOptionLabel = !!recentFields.length ? 'Edit other' : 'Edit'

    return useMemo(
        () => ({ recentFields, otherFields, updateRecord, editOptionLabel }),
        [otherFields, recentFields, updateRecord, editOptionLabel]
    )
}

const MAX_STORED_ACTIONS_PER_FIELD = 3

type RecentAction = {
    value: unknown
    timestamp: number
}

type RecentActions = Record<string, RecentAction[]>

function getRecentActionsKey(objectSid: string) {
    return `${objectSid}_recent_actions`
}

function persistRecentAction(fieldApiName: string, objectSid: string, value: unknown) {
    const key = getRecentActionsKey(objectSid)

    const recentActions = restoreRecentActions(objectSid)

    const existingActions = recentActions[fieldApiName] ?? []
    const action = {
        value,
        timestamp: Date.now(),
    }
    existingActions.unshift(action)

    // Keep only the last `MAX_STORED_ACTIONS_PER_FIELD` actions.
    recentActions[fieldApiName] = existingActions.slice(0, MAX_STORED_ACTIONS_PER_FIELD)

    localStorage.setItem(key, JSON.stringify(recentActions))

    return recentActions
}

function restoreRecentActions(objectSid: string): RecentActions {
    const key = getRecentActionsKey(objectSid)
    const recentActions = localStorage.getItem(key)
    if (recentActions) {
        return JSON.parse(recentActions)
    }

    return {}
}

const MAX_RECENT_FIELDS = 3

type ActionMenuField = {
    field: FieldDto
    values?: unknown[]
    lastUpdateTimestamp?: number
}

function extractFields(recentActions: RecentActions, fields: FieldDto[]) {
    const allFields = fields.reduce((acc, f) => {
        if (!isFieldEditable(f)) return acc

        const actions = recentActions[f.api_name] ?? []
        const values = actions.map((a) => a.value)
        const lastUpdateTimestamp = actions[0]?.timestamp

        acc.push({ field: f, values, lastUpdateTimestamp })

        return acc
    }, [] as ActionMenuField[])

    // Sort the fields by the most recent action.
    allFields.sort((a, b) => {
        const aTimestamp = a.lastUpdateTimestamp ?? 0
        const bTimestamp = b.lastUpdateTimestamp ?? 0

        return bTimestamp - aTimestamp
    })

    const recentFields: ActionMenuField[] = []
    const otherFields: ActionMenuField[] = []

    let recentFieldCount = 0
    for (const field of allFields) {
        if (field.lastUpdateTimestamp && recentFieldCount < MAX_RECENT_FIELDS) {
            recentFields.push(field)

            recentFieldCount++
            continue
        }

        otherFields.push(field)
    }

    return {
        recentFields,
        otherFields,
    }
}
