import React, { useMemo } from 'react'

import { useWorkspaceUsers } from 'data/hooks/users/main'

import { Avatar } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'

import { Highlighter } from './Highlighter'

export type UserSearchItem = UserDto & {
    label: string
    avatar?: string
}

type useUserSearchProviderProps = {
    showOnEmptySearch?: boolean
    showGroupTitle?: boolean
    users?: UserDto[]
}
function toUserSearchItem(user: UserDto): UserSearchItem {
    return {
        ...user,
        label: user.name || user.email,
        avatar: user.avatar,
    }
}

function getUserList(users: UserDto[]) {
    return users?.map(toUserSearchItem).sort((a, b) => a.label.localeCompare(b.label)) ?? []
}

export function searchUsers(users: UserDto[], searchText?: string, showOnEmptySearch?: boolean) {
    return getUserList(users).filter(
        (u) =>
            (!!searchText || showOnEmptySearch) &&
            u.label?.toLowerCase()?.includes(searchText || '')
    )
}
export const useUserSearchProvider = ({
    showOnEmptySearch,
    showGroupTitle,
    users: suppliedUsers,
}: useUserSearchProviderProps): ItemProvider<UserSearchItem> => {
    const { data: users } = useWorkspaceUsers()
    const usersList = suppliedUsers || users
    return useMemo(
        () =>
            ({
                id: 'users',
                title: showGroupTitle ? 'Workspace Users' : undefined,
                initialItems: showOnEmptySearch ? getUserList(usersList) : [],
                getItems({ query }) {
                    const searchText = query?.toLowerCase()
                    return Promise.resolve({
                        items: searchUsers(usersList, searchText, showOnEmptySearch),
                    })
                },
                renderItem({ item, queryTerms }) {
                    return <UserSearchResult item={item} queryTerms={queryTerms} />
                },
            } as ItemProvider<UserSearchItem>),
        [showGroupTitle, showOnEmptySearch, usersList]
    )
}

export function UserSearchResult({
    item,
    size = 'xs',
    queryTerms,
    ...props
}: BoxProps & { item: UserSearchItem; size?: 'xs' | 'sm'; queryTerms: string[] }) {
    return (
        <Box flex center py={size === 'xs' ? undefined : 'xs'} {...props}>
            <Avatar
                src={item.avatar}
                name={item.label}
                size={size === 'xs' ? '2xs' : 'xs'}
                mr={size === 'xs' ? 1.5 : 2}
                flexShrink={0}
            />
            <Box as="span" trim>
                <Highlighter terms={queryTerms}>{item.label}</Highlighter>
            </Box>
        </Box>
    )
}
