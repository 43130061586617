import { useActionButtons as useViewActionButtons } from 'features/actions/ActionsContextProvider'
import { getRecordActionDisplay } from 'features/views/ListView/Actions/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { isSystemAction, useSystemActions } from './useSystemActions'

type UseActionButtonsOptions = {
    record: RecordDto
    object: ObjectDto
    view: ViewDto
    showSystemActions?: boolean
    filterByActionDisplay?: boolean
}

export function useRecordActionButtons(options: UseActionButtonsOptions) {
    const {
        record,
        object,
        view,
        showSystemActions = false,
        filterByActionDisplay = true,
    } = options

    const activeButtons = view.options.actionButtons?.slice() ?? []

    const systemActions = useSystemActions()
    // If the view still has the default system actions, let's show all of them.
    if (!view.options.hasCustomSystemActions) {
        const existingActionButtonIds = new Set(activeButtons.map(({ id }) => id))

        for (const action of systemActions) {
            if (!existingActionButtonIds.has(action._sid)) {
                activeButtons.push({ id: action._sid, conditions: [] })
            }
        }
    }

    let actions = useViewActionButtons(
        activeButtons,
        record,
        object,
        showSystemActions ? systemActions : []
    )

    const actionDisplay = getRecordActionDisplay(view)
    actions = filterByActionDisplay
        ? actions.filter((a) => {
              if (!actionDisplay) {
                  // If there is no action display, we only show system actions.
                  return !!isSystemAction(a.action._sid)
              }

              return true
          })
        : actions

    return useDeepEqualsMemoValue(actions)
}
