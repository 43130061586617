/** @jsxRuntime classic */
/** @jsx jsx */
/* Code Quality: Not audited */
import React from 'react'

import { jsx } from '@emotion/react'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import matchSorter from 'match-sorter'

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

export const filterBoolean = (rows, id, filterValue) => {
    return rows.filter((row) => {
        const rowValue = row.values[id]
        const value = filterValue === '1' || filterValue === true
        return rowValue == value
    })
}

filterBoolean.autoRemove = (val) => !val

export const filterDropdown = (rows, id, value) => {
    if (!value) return rows

    const { filterType, filterValue } = value

    if (Array.isArray(filterValue)) {
        filterValue.sort()
    }

    return rows.filter((row) => {
        let rowValue = row.values[id]
        if (Array.isArray(rowValue)) {
            rowValue = rowValue.sort()
        }

        switch (filterType) {
            case 'contains':
                return Array.isArray(rowValue)
                    ? intersection(rowValue, filterValue).length > 0
                    : filterValue.includes(rowValue)
            case 'containsNone':
                return Array.isArray(rowValue)
                    ? intersection(rowValue, filterValue).length < 1
                    : !filterValue.includes(rowValue)
            case 'is':
                return isEqual(filterValue, rowValue)
            case 'isNot':
                return !isEqual(filterValue, rowValue)
        }
    })
}

filterDropdown.autoRemove = (val) => !val

// Define a default UI for filtering
export function DefaultColumnFilter({ filterValue, setFilter }) {
    return (
        <input
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder="Search..."
        />
    )
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val
