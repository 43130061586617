import React from 'react'
import { useParams } from 'react-router-dom'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { useAuthProviders } from 'data/hooks/authProviders'
import { useGetAccountAndZone } from 'data/hooks/zones'
import OldLoginPage from 'features/auth/LoginPage'
import { LoginPage as NewLoginPage } from 'features/auth/new-stacker-auth/LoginPage'

import { LoadingSplash } from 'v2/ui'

import { PortalLoginPage } from './PortalLoginPage'
import { WorkspaceLoginPage } from './WorkspaceLoginPage'

export const LoginPageRenderer = () => {
    const { slug } = useParams()

    const isStudioDomain = !!settings.SUPPORTED_STUDIO_DOMAINS.includes(settings.HOST)
    if (!slug && isStudioDomain) {
        return <StudioLogin />
    }
    return <WorkspaceLogin slug={slug} />
}

const StudioLogin = () => {
    const useNewAuth = !Boolean(localStorage.getItem('useOldAuth'))
    const LoginPage = useNewAuth ? NewLoginPage : OldLoginPage

    return <LoginPage />
}

const WorkspaceLogin = ({ slug }: { slug?: string | undefined }) => {
    const { zone, account, isLoading } = useGetAccountAndZone(slug)
    const { data: providers, isLoading: providersIsLoading } = useAuthProviders(account?._sid)

    if (isLoading || providersIsLoading) {
        return <LoadingSplash />
    }

    // if the domain is invalid, redirect to the studio login page
    if (!(account && zone)) {
        const studioLogin = `https://${settings.STUDIO_DOMAIN}${Urls.Login}`
        window.location.assign(studioLogin)
        return null
    }

    // portals can only be accessed at the sub-domain
    const allowedZoneTypes = slug ? ['Internal'] : ['Internal', 'Portal']
    if (!allowedZoneTypes.includes(zone.type)) {
        window.location.assign(getUrl(Urls.Login))
        return null
    }

    if (zone.type === 'Portal') {
        return <PortalLoginPage zone={zone} account={account} />
    }

    return <WorkspaceLoginPage zone={zone} account={account} providers={providers} />
}
