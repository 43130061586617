import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'

export function useCardViewListHeaderState() {
    const { records, requestFilters, requestIncludedFields } = useCardViewContext()

    const totalCount = records ? Object.values(records).flat().length : 0

    return useMemo(
        () => ({
            recordCount: totalCount,
            requestFilters,
            requestIncludedFields,
        }),
        [requestFilters, requestIncludedFields, totalCount]
    )
}
