import React from 'react'

import { Editor } from '@tiptap/core'

import * as Parts from 'ui/components/Textarea/Textarea.parts'

import { useTextareaToolbarContext } from './TextareaToolbarContext'
import { TextareaToolbarItem } from './TextareaToolbarItem'

type TextareaToolbarExtraProps = React.ComponentPropsWithoutRef<typeof Parts.ToolbarGroup> & {
    editor: Editor
    disabled?: boolean
}

export const TextareaToolbarExtra: React.FC<TextareaToolbarExtraProps> = ({
    editor,
    disabled,
    ...props
}) => {
    const { setCurrentPanel } = useTextareaToolbarContext()

    return (
        <Parts.ToolbarGroup aria-label="Type of node" {...props}>
            <TextareaToolbarItem
                icon={{ name: 'Link' }}
                label="Link"
                isActive={editor.isActive('link')}
                onClick={() => setCurrentPanel('link')}
                disabled={disabled || !editor.can().setLink({ href: '' })}
            />
            <TextareaToolbarItem
                icon={{ name: 'Code' }}
                label="Code Block"
                isActive={editor.isActive('codeBlock')}
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                disabled={disabled || !editor.can().toggleCodeBlock()}
            />
            <TextareaToolbarItem
                icon={{ name: 'Quote' }}
                label="Blockquote"
                isActive={editor.isActive('blockquote')}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                disabled={disabled || !editor.can().toggleBlockquote()}
            />
            <TextareaToolbarItem
                icon={{ name: 'Image' }}
                label="Image"
                isActive={editor.isActive('image')}
                onClick={() => setCurrentPanel('image')}
                disabled={disabled || !editor.can().setImage({ src: '' })}
            />
        </Parts.ToolbarGroup>
    )
}
