import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'

type UseCardViewSubtitleStateOptions = {
    record: RecordDto
}

export function useCardViewSubtitleState(options: UseCardViewSubtitleStateOptions) {
    const { record } = options

    const { subtitleField, view } = useCardViewContext()

    const fieldDisplayOptions = useMemo(
        () => (subtitleField ? view.options.cardCardSubtitle?.fieldDisplayOptions : {}),
        [subtitleField, view.options.cardCardSubtitle?.fieldDisplayOptions]
    )

    const attribute: CardViewAttribute | undefined = useMemo(() => {
        if (!subtitleField) return undefined

        return {
            id: subtitleField._sid,
            title: subtitleField.label,
            field: subtitleField,
            rawOptions: fieldDisplayOptions ?? {},
            type: 'subtitle',
        }
    }, [subtitleField, fieldDisplayOptions])

    const value = subtitleField ? record[subtitleField?.api_name] ?? undefined : undefined

    return useMemo(
        () => ({
            attribute,
            value,
        }),
        [attribute, value]
    )
}
