import React from 'react'
import { Link } from 'react-router-dom'

import { isEqual } from 'lodash'

import { ActionContextMenu } from 'features/views/ListView/Actions/ActionContextMenu'

import { TableViewRecordActions } from './Actions/TableViewRecordActions'
import { Cell } from './cells/Cell'
import { useTableViewRowState } from './hooks/useTableViewRowState'
import * as Parts from './TableView.parts'
import { TableViewRowContextProvider } from './TableViewRowContext'

type TableViewRowProps = React.ComponentPropsWithoutRef<typeof Parts.Row> & {
    record?: RecordDto
}

export const TableViewRow: React.FC<TableViewRowProps> = React.memo(function TableViewRow({
    record,
    isLoading,
    ...props
}) {
    const {
        to,
        onClick,
        isSelected,
        columns,
        rowRef,
        top,
        height,
        actionContextMenuRef,
        onContextMenu,
        onMouseMove,
        onMouseDown,
        isPending,
        actionButtons,
        includeFields,
    } = useTableViewRowState(record)

    return (
        <TableViewRowContextProvider record={record}>
            <Parts.Row
                ref={rowRef}
                as={Link}
                to={to}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onMouseDown={onMouseDown}
                isSelected={isSelected}
                isLoading={isLoading}
                data-recordid={record?._sid}
                onContextMenu={onContextMenu}
                isPending={isPending}
                tabIndex={isLoading || isPending ? -1 : undefined}
                {...props}
            >
                {columns.map((column) => (
                    <Cell
                        key={column.id}
                        column={column}
                        value={column.value}
                        isLoading={isLoading}
                    />
                ))}
                <TableViewRecordActions
                    actionButtons={actionButtons}
                    includeFields={includeFields}
                    rowTop={top}
                    rowHeight={height}
                />
                {record && (
                    <ActionContextMenu
                        ref={actionContextMenuRef}
                        record={record}
                        actionButtons={actionButtons}
                        includeFields={includeFields}
                    />
                )}
            </Parts.Row>
        </TableViewRowContextProvider>
    )
},
isEqual)
