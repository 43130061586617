import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import queryString from 'qs'

import { Urls } from 'app/UrlService'
import { insertCustomCss, removeCustomCss } from 'utils/customCss'

import { useAppContext } from './AppContext'

const IGNORE_PATHS = [Urls.StackSettings]

export const _CustomCss = () => {
    const { selectedStack: stack, workspaceAccount } = useAppContext()
    const customCss = stack?.options?.custom_css
    const CUSTOM_CSS_ATTR = 'data-custom-css'
    const inWorkspace = Boolean(workspaceAccount)

    const location = useLocation()

    // we always want to do this on page change as we don't want the user to
    // be able to hide important admin elements
    useEffect(() => {
        const query = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        })
        // ignore if the ignore_css parameter is set
        const ignoreCss =
            (query && query.ignore_css == 1) ||
            IGNORE_PATHS.find((x) => location.pathname.includes(x))
        const shouldInsertCustomCss = !ignoreCss

        removeCustomCss(CUSTOM_CSS_ATTR)

        if (shouldInsertCustomCss) {
            insertCustomCss(customCss, CUSTOM_CSS_ATTR)
        }
    }, [location, customCss, inWorkspace])

    return null
}
export const CustomCss = memo(_CustomCss)
