import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { ListViewOrderBy } from 'v2/views/utils/orderBy/types'

import { useAppContext } from 'app/AppContext'
import { queryClient, useQueryKeyBuilder } from 'data/hooks/_helpers'
import { fetchAndReturn } from 'data/utils/utils'

import { getAggregatesUrlAndBody } from './getAggregatesUrlAndBody'
import { mapRawResultToAggregates } from './mapRawResultToAggregates'
import type { Aggregation, AggregationGroup } from './types'

type UseAggregatesProps = {
    objectId: string
    aggregations: Aggregation[]
    primaryGroupFieldApiName?: string
    secondaryGroupFieldApiName?: string
    filters: ChartFilter[]
    sort?: ChartSort
    bucketBy?: Bucket
    limit?: number
    previousTimePeriod?: boolean
    includedFields?: string[]
    searchFields?: string[]
    orderBy?: ListViewOrderBy
    options: UseQueryOptions<AggregationGroup[]>
}

const LIST_NAME = 'aggregates'

export const useAggregates = ({
    objectId,
    aggregations,
    primaryGroupFieldApiName,
    secondaryGroupFieldApiName,
    filters = [],
    sort,
    bucketBy,
    limit,
    previousTimePeriod,
    includedFields,
    searchFields,
    orderBy,
    options = {},
}: UseAggregatesProps): UseQueryResult<AggregationGroup[]> => {
    if (!aggregations || aggregations.length == 0) {
        options.enabled = false
    }
    const [url, requestBody] = getAggregatesUrlAndBody({
        objectId,
        aggregations,
        primaryGroupFieldApiName,
        secondaryGroupFieldApiName,
        filters,
        sort,
        bucketBy,
        limit,
        previousTimePeriod,
        orderBy,
        includedFields,
        searchFields,
    })
    const { selectedStack } = useAppContext()
    const queryKey = useQueryKeyBuilder(
        [
            LIST_NAME,
            selectedStack?._sid,
            objectId,
            aggregations,
            primaryGroupFieldApiName,
            secondaryGroupFieldApiName,
            filters,
            sort,
            bucketBy,
            limit,
        ],
        { includeAuthKeys: true }
    )

    return useQuery(
        queryKey,
        async () => {
            const data = await fetchAndReturn(url, {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(requestBody),
            })
            return mapRawResultToAggregates(data)
        },
        options
    )
}

export const useInvalidateObjectAggregates = () => {
    const { selectedStack } = useAppContext()
    return (objectId: string) =>
        queryClient.invalidateQueries([LIST_NAME, selectedStack?._sid, objectId])
}
