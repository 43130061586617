import { useMemo } from 'react'
import { useMutation, UseQueryOptions } from 'react-query'

import { useAppContext } from 'app/AppContext'
import {
    buildQueryKey,
    queryClient,
    useCanRunWorkspaceScopedQueries,
    useQuery,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { useObjects } from 'data/hooks/objects'
import { useCachedRecords } from 'data/hooks/records/useCachedRecords'
import { useStacks } from 'data/hooks/stacks'
import { fetchAndReturn } from 'data/utils/utils'

import {
    AddRecentRecordPayload,
    RecentRecord,
    RecentRecordPayloadRecord,
    RecentRecordsPayload,
} from './types'

type UseRecentRecordsResult = {
    recentRecords: RecentRecord[]
    objectUrls: Record<string, string>
}

const LIST_NAME = 'useRecentRecords'
const ENDPOINT = 'recent-records/'

function useQueryKey() {
    return useQueryKeyBuilder([LIST_NAME], { includeAuthKeys: true }) as string[]
}

function getQueryKey() {
    return buildQueryKey([LIST_NAME], { includeAuthKeys: true }) as string[]
}

export function useRecentRecords(options: UseQueryOptions<RecentRecordsPayload> = {}) {
    const enabled = useCanRunWorkspaceScopedQueries()
    const queryKey = useQueryKey()
    const { data, ...query } = useQuery<RecentRecordsPayload>(
        queryKey,
        ENDPOINT,
        { ...options, enabled: enabled && options.enabled !== false },
        {
            bypassPreviewAs: true,
        }
    )

    const mappedStacks = useMappedStacks()
    const mappedObjects = useMappedObjects(data?.objects)
    const mappedRecords = useMappedRecords(data?.records)

    const objectUrls = useObjectUrls(data?.object_urls)

    const recentRecords = useMemo(() => {
        if (!data) return []

        return getRecentRecords({
            recentRecords: data?.recent_records ?? [],
            mappedObjects,
            mappedRecords,
            mappedStacks,
        })
    }, [data, mappedObjects, mappedRecords, mappedStacks])

    const result: UseRecentRecordsResult = useMemo(
        () => ({
            recentRecords,
            objectUrls,
        }),
        [recentRecords, objectUrls]
    )

    return {
        ...query,
        data: result,
    }
}

function getRecentRecords({
    recentRecords,
    mappedObjects,
    mappedRecords,
    mappedStacks,
}: {
    recentRecords: RecentRecordPayloadRecord[]
    mappedObjects: Map<string, Partial<ObjectDto>>
    mappedRecords: Map<string, Partial<RecordDto>>
    mappedStacks: Map<string, StackDto>
}): RecentRecord[] {
    return recentRecords.reduce<RecentRecord[]>((agg, curr) => {
        const record = mappedRecords.get(curr.id)
        if (!record) return agg

        const object = record._object_id ? mappedObjects.get(record._object_id) : undefined
        if (!object) return agg

        const stack = mappedStacks.get(curr.stack_id)
        if (!stack) return agg

        agg.push({
            record,
            object,
            stack,
        })

        return agg
    }, [])
}

function useMappedStacks(): Map<string, StackDto> {
    const { data: stacks } = useStacks()

    return useMemo(() => {
        if (!stacks) return new Map()

        return stacks.reduce((agg, curr) => agg.set(curr._sid, curr), new Map<string, StackDto>())
    }, [stacks])
}

function useMappedObjects(partials: Partial<ObjectDto>[] = []): Map<string, Partial<ObjectDto>> {
    const { data: objects } = useObjects()

    return useMemo(() => {
        return mergeEntities(objects ?? [], partials)
    }, [objects, partials])
}

function useMappedRecords(partials: Partial<RecordDto>[] = []): Map<string, Partial<RecordDto>> {
    const records = useCachedRecords(partials.map((p) => p._sid!))

    return useMemo(() => {
        return mergeEntities(Object.values(records), partials)
    }, [partials, records])
}

type Entity = { _sid: string }

export function mergeEntities<T extends Entity>(
    entities: T[],
    partials: Partial<T>[]
): Map<string, Partial<T>> {
    const mapped = entities.reduce(
        (agg, curr) => agg.set(curr._sid, curr),
        new Map<string, Partial<T>>()
    )

    for (const partial of partials) {
        const existing = mapped.get(partial._sid!)
        if (existing) continue

        mapped.set(partial._sid!, { ...partial })
    }

    return mapped
}

function useObjectUrls(existing: Record<string, string> = {}): Record<string, string> {
    const { data: objects } = useObjects()

    return useMemo(() => {
        const newUrls = { ...existing }

        if (objects) {
            for (const obj of objects) {
                newUrls[obj._sid] = obj.url
            }
        }

        return newUrls
    }, [objects, existing])
}

const MAX_RECORD_COUNT = 10

export function useAddRecentRecord() {
    const { selectedStack } = useAppContext()

    return useMutation(
        async (recordId: string) => {
            const stackId = selectedStack?._sid
            if (!stackId) return Promise.reject(new Error('No stack selected'))

            const payload: AddRecentRecordPayload = {
                stack_id: stackId,
                record_id: recordId,
            }

            await fetchAndReturn(
                ENDPOINT,
                {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: { 'Content-type': 'application/json' },
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )
        },
        {
            onMutate: async (recordId: string) => {
                const queryKey = getQueryKey()

                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<RecentRecordsPayload>(queryKey)

                const newRecord: RecentRecordPayloadRecord = {
                    id: recordId,
                    stack_id: selectedStack!._sid,
                    date: new Date().toISOString(),
                }
                const newRecentRecords = addRecordToList(
                    newRecord,
                    previousPayload?.recent_records ?? []
                )
                const newPayload = {
                    ...previousPayload,
                    recent_records: newRecentRecords,
                }

                queryClient.setQueryData(queryKey, newPayload)

                return { previousPayload }
            },
            onError: (err, recordId, context) => {
                const queryKey = getQueryKey()

                queryClient.setQueryData(queryKey, context?.previousPayload)
            },
            onSettled: () => {
                const queryKey = getQueryKey()
                queryClient.invalidateQueries({ queryKey })
            },
        }
    )
}

export function addRecordToList(
    newRecord: RecentRecordPayloadRecord,
    existingRecords: RecentRecordPayloadRecord[]
): RecentRecordPayloadRecord[] {
    const filteredRecords = removeRecordFromList(newRecord.id, existingRecords)
    const newRecords = [newRecord, ...filteredRecords]

    if (newRecords.length > MAX_RECORD_COUNT) {
        return newRecords.slice(0, MAX_RECORD_COUNT)
    }

    return newRecords
}

export function useDeleteRecentRecord() {
    return useMutation(
        async (recordId: string) => {
            await fetchAndReturn(
                `${ENDPOINT}${recordId}/`,
                {
                    method: 'DELETE',
                },
                undefined,
                undefined,
                {
                    bypassPreviewAs: true,
                }
            )
        },
        {
            onMutate: async (recordId: string) => {
                const queryKey = getQueryKey()

                await queryClient.cancelQueries({ queryKey })

                const previousPayload = queryClient.getQueryData<RecentRecordsPayload>(queryKey)

                const newRecentRecords = removeRecordFromList(
                    recordId,
                    previousPayload?.recent_records ?? []
                )
                const newPayload = {
                    ...previousPayload,
                    recent_records: newRecentRecords,
                }

                queryClient.setQueryData(queryKey, newPayload)

                return { previousPayload }
            },
            onError: (err, recordId, context) => {
                const queryKey = getQueryKey()

                queryClient.setQueryData(queryKey, context?.previousPayload)
            },
            onSettled: () => {
                const queryKey = getQueryKey()
                queryClient.invalidateQueries({ queryKey })
            },
        }
    )
}

function removeRecordFromList(
    recordId: string,
    existingRecords: RecentRecordPayloadRecord[]
): RecentRecordPayloadRecord[] {
    const filteredRecords = existingRecords.filter((record) => record.id !== recordId)

    return filteredRecords
}
