import { TableViewColumn } from './types'

function getColumnMinWidth(column: TableViewColumn): number {
    switch (column.field.type) {
        case 'percentage':
            if (column.rawOptions.percentageDisplay === 'percentBar') {
                return 180
            }
            return 50
        default:
            return 50
    }
}

function getColumnMaxWidth(column: TableViewColumn): number | undefined {
    switch (column.field.type) {
        case 'document':
        case 'long_text':
        case 'string':
            return 600
        default:
            return undefined
    }
}

export function setColumnWidths(columns: TableViewColumn[]): TableViewColumn[] {
    const newColumns = columns.map((column) => {
        column.minWidth = getColumnMinWidth(column)
        column.maxWidth = getColumnMaxWidth(column)
        return column
    })

    // Make sure at least one of the columns can span the full width.
    if (
        !!newColumns.length &&
        !newColumns.some((column) => typeof column.maxWidth === 'undefined')
    ) {
        newColumns[newColumns.length - 1].maxWidth = undefined
    }

    return newColumns
}
