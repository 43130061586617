import React, { useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { createDocument } from 'data/hooks/documents'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'

type NewDocumentPlaceholderProps = BoxProps & {
    parent_record_id?: string
    parent_document_id?: number
}
export function NewDocumentPlaceholder({
    parent_record_id,
    parent_document_id,
    style: providedStyle,
    ...props
}: NewDocumentPlaceholderProps) {
    const [isEditing, setIsEditing] = useState(false)
    const [name, setName] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const history = useHistory()

    const doAdd = async () => {
        if (name) {
            setIsSaving(true)
            const newDoc = await createDocument({
                title: name,
                parent_record_id,
                parent_document_id,
            })
            history.push(getUrl(`/docs/${newDoc.document.auto_id}`))
            setIsSaving(false)
        }
        setName('')
        setIsEditing(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            doAdd()
            setIsEditing(true)
        } else if (e.key === 'Escape') {
            setName('')
            setIsEditing(false)
        }
    }

    const inputRef = useRef<HTMLInputElement>(null)

    const isEditingRef = useRef(isEditing)
    isEditingRef.current = isEditing
    const isSavingRef = useRef(isSaving)
    isSavingRef.current = isSaving
    const doAddRef = useRef(doAdd)
    doAddRef.current = doAdd

    useLayoutEffect(() => {
        // Implementing custom blur logic, so we won't trigger the blur event when switching tabs.
        const saveOnBlur = (e: MouseEvent) => {
            if (e.target === inputRef.current || !isEditingRef.current || isSavingRef.current)
                return

            queueMicrotask(() => {
                doAddRef.current()
            })
        }

        window.addEventListener('mousedown', saveOnBlur)

        return () => {
            window.removeEventListener('mousedown', saveOnBlur)
        }
    }, [])

    if (isEditing || isSaving) {
        return (
            <Box flex center {...props} style={providedStyle} maxWidth="300px" py="xs">
                <Icon name="Notebook" color="textWeak" mr="m" ml="2xs" />
                <Input
                    ref={inputRef}
                    grow
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    mr="xs"
                    style={{ paddingRight: '20px' }}
                    disabled={isSaving}
                    placeholder="enter title"
                />

                {isSaving && <LoadingIndicator style={{ marginLeft: '-40px' }} />}
                {!isSaving && (
                    <Button
                        startIcon={{ name: 'Check' }}
                        variant="ghost"
                        size="s"
                        style={{ marginLeft: '-36px' }}
                    />
                )}
            </Box>
        )
    }
    return (
        <Button
            variant="ghost"
            size="m"
            width="full"
            startIcon={{ name: 'Plus' }}
            onClick={() => setIsEditing(true)}
            style={{ justifyContent: 'flex-start', borderRadius: 0, ...providedStyle }}
            {...(props as React.ComponentPropsWithoutRef<typeof Button>)}
        >
            Add note
        </Button>
    )
}
