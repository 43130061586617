import { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { useAppUsersContext } from 'features/users/AppUsers/AppUsersContext'
import { AppUser } from 'features/users/AppUsers/types'
import { extractPartsFromUserName } from 'features/views/attributes/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type UseAppUsersOptions = {
    grantSid?: string
    grantType?: AccessGrant['type']
    pageSize?: number
    enabled?: boolean
    searchQuery?: string
    paginate?: boolean
}

export function useAppUsers(options: UseAppUsersOptions) {
    const [pageIndex, setPageIndex] = useState(0)

    const {
        grantSid,
        grantType,
        pageSize = 24,
        enabled = true,
        searchQuery: searchQueryOverride,
        paginate = true,
    } = options

    const { searchQuery } = useAppUsersContext()
    const normalizedSearchQuery = (searchQueryOverride || searchQuery)?.toLowerCase().trim()

    // Using a separate state to store the effective search query,
    // so we can reset the page index when the search query changes.
    const [effectiveSearchQuery, setEffectiveSearchQuery] = useState(normalizedSearchQuery)
    useEffect(() => {
        setEffectiveSearchQuery(normalizedSearchQuery)
    }, [normalizedSearchQuery])

    // Reset page index when search query changes.
    useLayoutEffect(() => {
        if (normalizedSearchQuery) {
            setPageIndex(0)
        }
    }, [normalizedSearchQuery])

    const fetchOptions = useDeepEqualsMemoValue({
        includeUsersWithNoAccess: true,
        paginate,
        pageSize,
        pageIndex: pageIndex + 1,
        grantType,
        grantSid,
        searchQuery: effectiveSearchQuery,
    })

    const {
        data: users,
        totalUserCount,
        isFetching,
        isError,
    } = useAppUsersForAdmin(enabled, {}, fetchOptions)

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const mappedUsers = useMemo(() => {
        if (isFetchingSlow) return PLACEHOLDER_USERS

        return users?.map((user) => {
            const { firstName, lastName } = extractPartsFromUserName(user.name)
            const avatarUrl = user.avatar

            const role = user.role
            const isAdmin = role === 'internal_admin'

            const isInvitePending = false

            const appUser: AppUser = {
                id: user._sid,
                firstName,
                lastName,
                imageUrl: avatarUrl,
                type: avatarUrl ? 'image' : 'initial',
                isAdmin,
                email: user.email,
                role,
                isInvitePending,
            }

            return appUser
        })
    }, [isFetchingSlow, users])

    const hasSearch = !!effectiveSearchQuery

    const isLoading = !users

    const hasNoUsersMatchingSearch = !isLoading && users.length < 1 && hasSearch && !isError

    const hasNoUsers = !isLoading && users.length < 1 && !hasSearch && !isError

    const totalPages = Math.ceil(totalUserCount ?? 0 / pageSize)

    useLayoutEffect(() => {
        // Reset page index if it exceeds the total number of pages.
        if (pageIndex >= totalPages) {
            setPageIndex(0)
        }
    }, [pageIndex, totalPages])

    return useDeepEqualsMemoValue({
        totalUserCount,
        users: mappedUsers,
        isError,
        isFetchingSlow,
        hasSearch,
        isLoading,
        hasNoUsersMatchingSearch,
        hasNoUsers,
        pageIndex,
        setPageIndex,
    })
}

const PLACEHOLDER_USERS: AppUser[] = [
    {
        id: '1',
        firstName: 'John',
        lastName: 'Doe',
        type: 'initial',
        isAdmin: true,
        email: 'john.doe@stackerhq.com',
        role: 'internal_admin',
        isInvitePending: false,
    },
    {
        id: '2',
        firstName: 'John',
        lastName: 'Doe',
        type: 'initial',
        isAdmin: true,
        email: 'john.doe@stackerhq.com',
        role: 'internal_admin',
        isInvitePending: false,
    },
    {
        id: '3',
        firstName: 'Steve',
        lastName: 'Doe',
        type: 'initial',
        isAdmin: false,
        email: 'steve.doe@stackerhq.com',
        role: 'user',
        isInvitePending: false,
    },
    {
        id: '4',
        firstName: 'Jane',
        lastName: 'Doe',
        type: 'initial',
        isAdmin: false,
        email: 'jane.doe@stackerhq.com',
        role: 'user',
        isInvitePending: false,
    },
    {
        id: '5',
        firstName: 'Grace',
        lastName: 'Doe',
        type: 'initial',
        isAdmin: false,
        email: 'grace.doe@stackerhq.com',
        role: 'user',
        isInvitePending: false,
    },
]
