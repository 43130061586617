import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { verifyEmail } from 'supertokens-web-js/recipe/emailverification'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'
import { refetchAccounts } from 'data/hooks/accounts'
import { AuthFrame } from 'features/auth/new-stacker-auth/AuthFrame'

import { LoadingSplash } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { EmailVerificationPage } from './EmailVerificationPage'

export function EmailVerificationCallbackPage() {
    const { user } = useAuthContext()
    const submittedCode = useRef(false)

    const [verificationFailed, setVerificationFailed] = useState(false)
    const [verificationSucceeded, setVerificationSucceeded] = useState(false)
    const history = useHistory()

    const handleCallback = useCallback(
        async function handleCallback() {
            try {
                submittedCode.current = true
                let response = await verifyEmail()
                if (response.status === 'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR') {
                    setVerificationFailed(true)
                } else {
                    // email was verified successfully.
                    if (user) {
                        await refetchAccounts()
                        // we have a session so we can redirect to the home page.
                        history.push('/home')
                    } else {
                        // We don't have a session, so we'll need to render some UI below to
                        // tell the user to login next.
                        setVerificationSucceeded(true)
                    }
                }
            } catch (ex: any) {
                console.log('Email verification failed:', ex)
                setVerificationFailed(true)
            }
        },
        [history, user]
    )

    useEffect(() => {
        if (!submittedCode.current && user) {
            handleCallback()
        }
    }, [handleCallback, history, user])

    // We have a session, but the verification failed: show the page to send a new link.
    if (user && verificationFailed) {
        return <EmailVerificationPage verificationFailed />
    }

    // We don't have a session, and the verification failed: tell the user to login.
    if (!user && verificationFailed) {
        return (
            <AuthFrame title="Email Verification Failed">
                <Box flex column gap="m">
                    <Box fontSize="bodyM" mb="m" maxWidth="full" textAlign="center">
                        The link you clicked has expired or already been used. Please login again to
                        request a new link.
                    </Box>
                    <Button variant="primary" onClick={() => history.push(getUrl(Urls.Login))}>
                        Log in
                    </Button>
                </Box>
            </AuthFrame>
        )
    }

    // If the user is not logged in, instead of automatically consuming the verification code,
    // show a button that they have to click to verify their email.
    // This is important because some email clients will follow the link in the email and it will consume the code.
    // However, in that scenario the user won't have a session and the email client won't click the button.
    if (!user && !verificationSucceeded) {
        return (
            <AuthFrame title="Email Verification">
                <Box flex column gap="m">
                    <Box fontSize="bodyM" mb="m">
                        You are almost finished. Click the button below to continue.
                    </Box>
                    <Button variant="primary" onClick={handleCallback}>
                        Continue
                    </Button>
                </Box>
            </AuthFrame>
        )
    }

    // Verification succeeded, but the user is not logged in: show the login page.
    if (!user && verificationSucceeded) {
        return (
            <AuthFrame title="Email Verified">
                <Box flex column gap="m">
                    <Box fontSize="bodyM" mb="m">
                        Your email as been verified.
                    </Box>
                    <Button variant="primary" onClick={() => history.push(getUrl(Urls.Login))}>
                        Log in
                    </Button>
                </Box>
            </AuthFrame>
        )
    }

    return <LoadingSplash />
}
