import { useContext, useMemo } from 'react'

import { AppContext } from 'app/AppContext'
import { getCurrentStackId } from 'app/GlobalStaticState'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'
import { REACT_QUERY } from 'data/utils/constants'

import {
    buildQueryKey,
    queryClient,
    useCanRunStackScopedQueries,
    useCanRunWorkspaceScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from './_helpers'

/** @type {REACT_QUERY_DEPS_NAME} */
const LIST_NAME = 'usePage'
const ENDPOINT = 'pages/'

function useQueryKey() {
    return useQueryKeyBuilder(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
function getQueryKey() {
    return buildQueryKey(LIST_NAME, { includeAuthKeys: true, includeStackId: true })
}
/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function usePages(options = {}) {
    const enabled = useCanRunStackScopedQueries()
    // When query key changes due to previewing another role or user,
    // instead of reverting to an empty/loading state, keep the previous
    // data and just updated it when the results come in.
    const query_config = {
        ...STACK_QUERY_CONFIG,
        retryOnMount: false,
        keepPreviousData: true,
        ...options,

        enabled: enabled && options.enabled,
    }

    const queryResult = useQuery(useQueryKey(), ENDPOINT, query_config)

    return queryResult
}

// Fetch all pages across the workspace
export function usePagesAll(options = {}) {
    const enabled = useCanRunWorkspaceScopedQueries()
    const queryResult = useQuery(
        [...useQueryKey(), 'ALL'],
        ENDPOINT,
        { ...options, enabled: enabled && options.enabled !== false },
        {
            bypassMatchingStackCheck: true,
        }
    )

    return queryResult
}

export function useStackPages(options = {}) {
    const { data: pages, status } = usePages(options)
    const { selectedStack } = useContext(AppContext)

    const { showFeatureConfigData = false, allStacks } = options

    const stackPages = useMemo(() => {
        if (status !== 'success') {
            return pages
        }

        if (showFeatureConfigData) {
            return pages.filter((page) => allStacks || page.stack_id === selectedStack?._sid)
        }

        return pages.filter((page) => allStacks || page.stack_id === selectedStack?._sid)
    }, [pages, selectedStack, allStacks, showFeatureConfigData, status])

    return stackPages
}

export function useCreatePage() {
    return useCreateItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            queryClient.invalidateQueries(REACT_QUERY.roles.listName)
            invalidatePages()
        },
    })
}
export function useUpdatePage() {
    return useUpdateItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            queryClient.invalidateQueries(REACT_QUERY.roles.listName)
            invalidatePages()
        },
    })
}
export function useDeletePage() {
    return useDeleteItem(useQueryKey(), ENDPOINT, {
        onSuccess: () => {
            invalidatePages()
        },
    })
}

export function invalidatePages() {
    // Want to include the stack id here otherwise we'll be invalidating for all the apps
    // which have been loaded
    return queryClient.invalidateQueries([LIST_NAME, getCurrentStackId()])
}

export function refetchPages() {
    // invalidate all instances of these queries
    invalidatePages()
    // but only refetch the one that is for the current user
    return queryClient.refetchQueries(getQueryKey())
}
