import React, { useCallback, useContext, useLayoutEffect, useMemo } from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'
import { getColumnConfig } from 'v2/views/List/getColumnConfig'
import { ViewState } from 'v2/views/List/viewStateType'
import useViewBreadcrumbTitle from 'v2/views/useViewBreadcrumbTitle'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { useObject } from 'data/hooks/objects'
import { useStacks } from 'data/hooks/stacks'
import { useViews } from 'data/hooks/views'
import { canCreateRecords } from 'data/utils/getObjectRecordRestrictions'
import { BreadcrumbsMode, HeaderContext } from 'features/utils/HeaderContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { ListViewUrlParams, useListViewUrlParams } from './hooks/useListViewUrlParams'
import { useDefaultedListHeader } from './ListHeader/hooks/useDefaultedListHeader'

export type ViewConfig = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    setViewData: (patch: Partial<ViewDto>) => void
    saveConfig: () => Promise<void>
    isConfigDirty: boolean
    viewState: ViewState
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
    rolesDirty: boolean
    revertChanges: () => void
}

export type ListViewContextValue = {
    view: ViewDto
    context?: ViewLayoutContext
    columnConfigs: ListViewColumnConfig[]
    updateView: (patch: Partial<ViewDto>) => void
    updateViewOptions: (patch: Partial<ListViewOptions>) => void
    isDeleteEnabled: boolean
    commitChanges: () => void
    discardChanges: () => void
    hasPendingChanges: boolean
    object: ObjectDto
    allFields: FieldDto[]
    visibleFields: FieldDto[]
    // api name of any fields which should be searched
    searchFields: string[]
    stack: StackDto
    pageRoles: PageRole[]
    updatePageRoles: (roles: PageRole[]) => void
    isEditMode: boolean
    useUrlParams: boolean
    isEmbedded: boolean
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    title: string
    allowSearch: boolean
    allowCreate: boolean
    allowDownload: boolean
    urlParams?: ListViewUrlParams
    additionalFilters?: Filter[]
    header: ListViewHeader
}

export const ListViewContext = React.createContext<ListViewContextValue | null>(null)

export function useListViewContext(): ListViewContextValue {
    const context = useContext(ListViewContext)

    if (!context) {
        throw new Error('useListViewContext must be used within a ListViewContextProvider')
    }

    return context
}

export type ListViewContextProviderProps = {
    viewConfig: ViewConfig
    context?: ViewLayoutContext
    isEditMode?: boolean
    isEmbedded?: boolean
    embeddedByFieldSid?: string
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    title?: string
    allowSearch?: boolean
    allowCreate?: boolean
    allowDownload?: boolean
    additionalFilters?: Filter[]
}

export const ListViewContextProvider: React.FC<ListViewContextProviderProps> = ({
    children,
    context,
    isEditMode = false,
    isEmbedded = false,
    embeddedByFieldSid,
    embeddedByFieldValue,
    embeddedByRecord,
    title: titleOverride,
    allowSearch = true,
    allowCreate = true,
    allowDownload,
    additionalFilters,
    viewConfig,
}) => {
    const {
        setConfig,
        setViewData,
        saveConfig,
        isConfigDirty,
        viewState,
        setPageRoles,
        rolesDirty,
        pageRoles,
        revertChanges,
    } = viewConfig

    const view = viewState?.view

    const { object } = useObject(view.object_id)
    const memoizedObject = useDeepEqualsMemoValue(object)

    const { showAllFields, columns: viewStateColumns } = view.options

    const columnConfigs: ListViewColumnConfig[] = useMemo(() => {
        if (!memoizedObject) return []
        return getColumnConfig(memoizedObject, viewStateColumns, showAllFields)
    }, [memoizedObject, showAllFields, viewStateColumns])

    const updateViewOptions = useCallback(
        (patch: Partial<ListViewOptions>) => {
            setConfig({
                ...view.options,
                ...patch,
            })
        },
        [setConfig, view.options]
    )

    const { data: stacks = [] } = useStacks()
    const stack = stacks.find((stack) => stack._sid === memoizedObject?.stack_id)
    const memoizedStack = useDeepEqualsMemoValue(stack)

    const { data: views } = useViews()
    const isDeleteEnabled = isViewDeleteEnabled(view, views)

    const hasPendingChanges = isConfigDirty || rolesDirty

    // Make sure to add the view to the breadcrumbs, but without actually displaying the breadcrumbs in the header.
    const breadcrumbTitle = useViewBreadcrumbTitle(viewState.view)
    useHistoryBreadcrumb(
        { title: breadcrumbTitle, type: 'list', objectId: memoizedObject?._sid },
        isEmbedded
    )
    const { reset: resetBreadcrumbsMode, setBreadcrumbsMode } = useContext(HeaderContext)!
    useLayoutEffect(() => {
        if (isEmbedded) return

        setBreadcrumbsMode(BreadcrumbsMode.Hidden)

        return () => resetBreadcrumbsMode()
    }, [isEmbedded, resetBreadcrumbsMode, setBreadcrumbsMode])

    const allFields = makeAllFields(memoizedObject)
    const memoizedAllFields = useDeepEqualsMemoValue(allFields)

    const embeddedByField = embeddedByFieldSid
        ? allFields.find((field) => field._sid === embeddedByFieldSid)
        : undefined
    const embeddedByRecordMemo = useDeepEqualsMemoValue(embeddedByRecord)

    const useUrlParams = !isEmbedded
    let urlParams: ListViewUrlParams | undefined = useListViewUrlParams({
        disabled: !useUrlParams,
        fields: allFields,
    })
    if (!useUrlParams) {
        urlParams = undefined
    }

    const title = titleOverride || viewState.view?.options.title || viewState.view?.name || ''

    const fields = makeVisibleFields(memoizedAllFields, viewState.view)
    const memoizedFields = useDeepEqualsMemoValue(fields)

    const additionalFiltersMemo = useDeepEqualsMemoValue(additionalFilters)

    const canCreate = !!object && canCreateRecords(object) && allowCreate

    const canDownload = allowDownload ?? view.options.allowDownload ?? false

    const searchFields = columnConfigs
        // unless isSearchable is explicitly false, treat the field as searchable (so no config value defaults to true)
        .filter((column) => column.isSearchable !== false)
        .map((column) => column.fieldApiName)

    const header = useDefaultedListHeader(view.options)

    const value = useMemo(
        () => ({
            view: viewState.view,
            context,
            columnConfigs,
            updateView: setViewData,
            updateViewOptions,
            isDeleteEnabled,
            commitChanges: saveConfig,
            discardChanges: revertChanges,
            hasPendingChanges,
            object: memoizedObject!,
            stack: memoizedStack!,
            visibleFields: memoizedFields,
            searchFields,
            allFields: memoizedAllFields,
            pageRoles,
            updatePageRoles: setPageRoles,
            isEditMode,
            useUrlParams,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecord: embeddedByRecordMemo,
            title,
            allowSearch,
            allowCreate: canCreate,
            allowDownload: canDownload,
            urlParams,
            additionalFilters: additionalFiltersMemo,
            header,
        }),
        [
            viewState.view,
            context,
            columnConfigs,
            setViewData,
            updateViewOptions,
            isDeleteEnabled,
            saveConfig,
            revertChanges,
            hasPendingChanges,
            memoizedObject,
            memoizedStack,
            memoizedFields,
            searchFields,
            memoizedAllFields,
            pageRoles,
            setPageRoles,
            isEditMode,
            useUrlParams,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecordMemo,
            title,
            allowSearch,
            canCreate,
            canDownload,
            urlParams,
            additionalFiltersMemo,
            header,
        ]
    )

    if (!memoizedObject || !view || !memoizedStack) return null

    return <ListViewContext.Provider value={value}>{children}</ListViewContext.Provider>
}

function isViewDeleteEnabled(view?: ViewDto, views?: ViewDto[]): boolean {
    if (!views?.length || !view) return false

    // Is there another list view for the same object?
    return views.some(
        (v) => v.object_id === view.object_id && v._sid !== view._sid && v.type == 'list'
    )
}

function makeAllFields(object?: ObjectDto): FieldDto[] {
    if (!object) return []

    const fieldsOrder = object.options?.fields_order?.reduce(
        (acc, apiName, idx) => acc.set(apiName, idx),
        new Map<string, number>()
    )

    return object.fields
        .filter((f) => {
            // Filter out disabled fields.
            return !f.connection_options?.is_disabled
        })
        .sort((a, b) => {
            // Primary fields should always be first.
            if (a.is_primary) return -1
            if (b.is_primary) return 1

            // If the object has a custom fields order, sort by that.
            if (fieldsOrder) {
                const aOrder = fieldsOrder.get(a.api_name) || Number.MAX_VALUE
                const bOrder = fieldsOrder.get(b.api_name) || Number.MAX_VALUE

                return aOrder - bOrder
            }

            // Otherwise, sort by created date.
            const aCreatedDate = a.created_date || ''
            const bCreatedDate = b.created_date || ''

            return aCreatedDate.localeCompare(bCreatedDate)
        })
}

function makeVisibleFields(allFields: FieldDto[], view?: ViewDto): FieldDto[] {
    if (!view) return []

    let fields = allFields.slice()
    if (!view.options.showAllFields) {
        const columnsOrder = new Map<string, number>()
        const includedFields = new Set<string>()
        for (let i = 0; i < view.options.columns.length; i++) {
            const column = view.options.columns[i]
            columnsOrder.set(column.fieldId, i)
            includedFields.add(column.fieldId)
        }

        fields = fields.filter((field) => includedFields.has(field._sid))
        // Sort in the order defined in the admin pane.
        fields = fields.sort((a, b) => {
            const aOrder = columnsOrder.get(a._sid) ?? -1
            const bOrder = columnsOrder.get(b._sid) ?? -1

            return aOrder - bOrder
        })
    }

    return fields
}
