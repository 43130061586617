import React from 'react'

import { useCreateEmptyBlankPageModal } from 'features/workspace/AddCustomPageButton/CreateEmptyBlankPageModal'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

export const AddCustomPageButton: React.FC = () => {
    const { show: handleAddPage } = useCreateEmptyBlankPageModal()

    return (
        <Box
            style={{
                padding: 3,
            }}
        >
            <Button onClick={handleAddPage}>Add custom page</Button>
        </Box>
    )
}
