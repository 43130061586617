import React from 'react'

import { Button, Icon, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export const RoleToggles = ({
    value,
    onChange,
    roles,
}: {
    value: string[]
    onChange: (value: string[]) => void
    roles: RoleDto[]
}): React.ReactElement => {
    const selectedStyles = {
        color: V4DesignSystem.colors.stacker,
        borderColor: V4DesignSystem.colors.stacker,
        background: V4DesignSystem.colors.blue[200],
    }
    const buttonStyles = {
        borderRadius: '9999px',
        paddingLeft: '0.75rem',
        paddingRight: '0.75rem',
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
    }
    return (
        <div>
            <Text
                size="xs"
                fontWeight="600"
                color={V4DesignSystem.colors.gray[400]}
                style={{ marginBottom: 8 }}
            >
                Selected{' '}
                <span style={{ color: V4DesignSystem.colors.stacker }}>{value.length}</span> /{' '}
                {roles.length}
            </Text>
            {roles.map((role) => {
                if (value.includes(role._sid)) {
                    return (
                        <Button
                            variant="adminSecondary"
                            buttonSize="sm"
                            style={{ ...selectedStyles, ...buttonStyles }}
                            onClick={() => onChange(value.filter((x) => x !== role._sid))}
                        >
                            <span style={{ width: '21px', display: 'inline-block' }}>
                                <Icon icon="check" />
                            </span>
                            {role.label}
                            <span style={{ width: '1px', display: 'inline-block' }}></span>
                        </Button>
                    )
                }
                return (
                    <Button
                        key={role._sid}
                        variant="adminSecondary"
                        buttonSize="sm"
                        style={{ ...buttonStyles }}
                        onClick={() => onChange([...value, role._sid])}
                    >
                        <span style={{ width: '11px', display: 'inline-block' }}></span>
                        {role.label}
                        <span style={{ width: '11px', display: 'inline-block' }}></span>
                    </Button>
                )
            })}
            <div style={{ marginTop: '12px' }}>
                {roles.length === value.length ? (
                    <Button variant="adminSecondary" buttonSize="sm" onClick={() => onChange([])}>
                        Clear all
                    </Button>
                ) : (
                    <Button
                        variant="adminSecondary"
                        buttonSize="sm"
                        onClick={() => onChange(roles.map((x) => x._sid))}
                    >
                        Select all
                    </Button>
                )}
            </div>
        </div>
    )
}
