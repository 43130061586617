import React from 'react'

export type WorkflowChainContextValue = {
    chainPath: string[]
}

export const WorkflowChainContext = React.createContext<WorkflowChainContextValue>({
    chainPath: [],
})

export function useWorkflowChainContext() {
    return React.useContext(WorkflowChainContext)
}

export type WorkflowChainProviderProps = {
    children: React.ReactNode
    parentId?: string
}

export function WorkflowChainContextProvider({ children, parentId }: WorkflowChainProviderProps) {
    // This helps us keep track of all the parent chains, so we know where to
    // insert and remove nodes in the editor.
    const { chainPath } = useWorkflowChainContext()
    const newChainPath = parentId ? [parentId, ...chainPath] : chainPath

    return (
        <WorkflowChainContext.Provider
            value={{
                chainPath: newChainPath,
            }}
        >
            {children}
        </WorkflowChainContext.Provider>
    )
}
