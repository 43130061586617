import { useCallback, useMemo } from 'react'

import { CellRange } from 'ag-grid-community'
import { ProcessDataFromClipboardParams } from 'ag-grid-enterprise'

import { useImportOnPaste, UseImportOnPasteProps } from 'features/datagrid/hooks/useImportOnPaste'
import { ColumnDefinition } from 'features/datagrid/types'

import { useValueParser } from './useValueParser'

type UseProcessDataFromClipboardProps = UseImportOnPasteProps & {
    object: ObjectDto
}

/**
 * This hook is used to process the data from the clipboard.
 * The user can replace the data in the grid by pasting, or even create new records.
 */
export function useProcessDataFromClipboard({
    object,
    fields,
    records,
    importRawRecords,
    dataSourceSupportsPasting,
    dataSourceLabel,
    pendingNewRecords,
    pendingDeletes,
    pendingEdits,
    failedRecords,
}: UseProcessDataFromClipboardProps) {
    const { handlePaste, hidePastingIndicator, pastingIndicator } = useImportOnPaste({
        importRawRecords,
        dataSourceSupportsPasting,
        dataSourceLabel,
        pendingNewRecords,
        pendingDeletes,
        pendingEdits,
        failedRecords,
        records,
        fields,
    })

    const fieldsByApiName = useMemo(() => {
        return fields.reduce<Map<string, FieldDto>>(
            (agg, field) => agg.set(field.api_name, field),
            new Map<string, FieldDto>()
        )
    }, [fields])

    const parseValue = useValueParser({})

    const processDataFromClipboard = useCallback(
        (params: ProcessDataFromClipboardParams) => {
            let values = params.data

            const cellRanges = params.api.getCellRanges() ?? []
            if (cellRanges.length < 1) return null
            const cellRange = cellRanges[0]

            let colDefs: ColumnDefinition[] = params.api.getColumnDefs() ?? []
            colDefs = colDefs.filter((col) => col.isFieldColumn)

            const startColIndex = getStartColumnIndex(cellRange, colDefs)

            // Only include the columns that will be filled by the data from the clipboard.
            colDefs = colDefs.slice(startColIndex, startColIndex + values[0].length)

            const parsedData = parseDataFromValues(
                values,
                colDefs,
                fieldsByApiName,
                object,
                parseValue
            )

            let startRowIndex = cellRange.startRow?.rowIndex ?? 0
            let endRowIndex = cellRange.endRow?.rowIndex ?? 0
            // Make sure we always start filling values from the top to the bottom.
            if (startRowIndex > endRowIndex) {
                const tmp = startRowIndex
                startRowIndex = endRowIndex
                endRowIndex = tmp
            }

            const rowCount = endRowIndex - startRowIndex + 1

            const selectionX = startColIndex
            const selectionY = startRowIndex
            const selectionWidth = colDefs.length
            const selectionHeight = rowCount

            handlePaste({
                colIndex: startColIndex,
                rowIndex: startRowIndex,
                selectionX,
                selectionY,
                selectionWidth,
                selectionHeight,
                values: parsedData,
            })

            return null
        },
        [fieldsByApiName, handlePaste, object, parseValue]
    )

    return useMemo(
        () => ({
            processDataFromClipboard,
            hidePastingIndicator,
            pastingIndicator,
        }),
        [processDataFromClipboard, hidePastingIndicator, pastingIndicator]
    )
}

function parseDataFromValues(
    values: string[][],
    columnDefs: ColumnDefinition[],
    fieldsByApiName: Map<string, FieldDto>,
    object: ObjectDto,
    parseValue: (value: string, field: FieldDto, object: ObjectDto) => string
): string[][] {
    const parsedData: string[][] = []
    for (const row of values) {
        const rowData: string[] = []
        for (let i = 0; i < columnDefs.length; i++) {
            const colId = columnDefs[i].field!
            const field = fieldsByApiName.get(colId)

            let cellValue = row[i]
            if (field) {
                cellValue = parseValue(cellValue, field, object)
            }

            rowData.push(cellValue ?? '')
        }

        parsedData.push(rowData)
    }

    return parsedData
}

function getStartColumnIndex(cellRange: CellRange, colDefs: ColumnDefinition[]): number {
    const colDefIndexes = colDefs.reduce(
        (agg, col, index) => agg.set(col.field!, index),
        new Map<string, number>()
    )

    // The columns in the selection, sorted by their order in the UI.
    const sortedRangeColumns = [...cellRange.columns].sort((a, b) => {
        const aIndex = colDefIndexes.get(a.getColId()) ?? 0
        const bIndex = colDefIndexes.get(b.getColId()) ?? 0

        return aIndex - bIndex
    })

    const startColumnId = sortedRangeColumns[0].getColId()
    const colIndex = colDefIndexes.get(startColumnId) ?? 0

    return colIndex
}
