import { useMemo } from 'react'

import { DropdownValue } from 'features/views/attributes/types'
import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'
import { CardViewAttribute } from 'features/views/ListView/CardView/types'

import { ResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

type UseDropdownAttributeStateOptions = {
    attribute: CardViewAttribute
    value?: DropdownValue
    isLoading?: boolean
}

const titleSizeMapping = {
    small: { mobile: 'm', tablet: 's' },
    medium: 'm',
    large: 'l',
}

export function useDropdownAttributeState(options: UseDropdownAttributeStateOptions) {
    const { value, isLoading, attribute } = options
    const { type } = attribute

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const isSingle = !Array.isArray(value) || value.length === 1

    const { titleSize } = useCardViewContext()
    const size = useMemo(() => {
        switch (type) {
            case 'title':
                return titleSizeMapping[titleSize || 'medium'] as ResponsiveValue<'s' | 'm'>
            case 'eyebrow':
                return 's'
            default:
                return 'm'
        }
    }, [type, titleSize])

    return useMemo(
        () => ({
            isEmpty,
            isSingle,
            size,
        }),
        [isEmpty, isSingle, size]
    )
}
