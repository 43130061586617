import { useMemo } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/ListHeaderContext'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

export function useListHeaderAdvancedFilterControlState() {
    const { inlineFilters } = useListHeaderContext()

    const filterCount = inlineFilters.length

    const isActive = filterCount > 0
    const defaultLabel = useResponsiveValue({
        mobile: '',
        tablet: 'Filter',
    })
    const label = isActive ? filterCount.toString() : defaultLabel

    return useMemo(
        () => ({
            hasFilters: isActive,
            label,
        }),
        [isActive, label]
    )
}
