import React, { useEffect, useRef, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { NotificationsResponse, useNotifications } from 'data/hooks/activities/notifications'
import { ActivitiesData } from 'data/hooks/activities/types'
import { ActivityType, NotificationType, RelatedToType } from 'data/hooks/activityTypes'
import useLDFlags from 'data/hooks/useLDFlags'
import { getUserDisplayName } from 'features/tasks/assigneeUtils'
import { formatDueDate } from 'features/tasks/dueDateUtils'
import publicAsset from 'utils/publicAsset'
import { useLocalStorageState } from 'utils/useLocalStorageState'

import Modal from 'v2/ui/components/Modal'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

type DesktopNotificationServiceProps = {}
/*
 * When a new notification comes in while the app is loaded,
 * we want to show a desktop notification.
 */
export function DesktopNotificationService({}: DesktopNotificationServiceProps) {
    const { workspaceAccount } = useAppContext()
    const { user } = useAppUserContext()
    const { data } = useNotifications()
    const initialUnreadNotifications = useRef<number[] | undefined>()
    const { notifications, ...notificationData } = data || ({} as NotificationsResponse)
    const [notificationsDeclined, setNotificationsDeclined] = useLocalStorageState<boolean>(
        'desktop-notifications-declined',
        { defaultValue: false }
    )
    const [showPermissionModal, setShowPermissionModal] = useState(false)

    const supportsNotifications = useSupportsNotifications()

    useEffect(() => {
        if (!supportsNotifications) return

        if (!initialUnreadNotifications.current) {
            initialUnreadNotifications.current = notifications
                ?.filter((n) => !n.is_read)
                .map((n) => n.auto_id)
            return
        }

        if (Notification.permission === 'denied') {
            return
        }

        const unread = notifications?.filter(
            (n) => !n.is_read && !initialUnreadNotifications.current?.includes(n.auto_id)
        )
        for (const notification of unread ?? []) {
            const icon =
                workspaceAccount?.options?.theme?.logo_icon ||
                publicAsset('/static/media/stacker-logo-s-only.png')
            const content = getNotificationContent(notification, notificationData)
            if (!content) {
                continue
            }
            const { title, body } = content

            new Notification(title, {
                icon,
                body,
            })
        }
        initialUnreadNotifications.current = [
            ...initialUnreadNotifications.current,
            ...(unread?.map((n) => n.auto_id) ?? []),
        ]
    }, [
        notificationData,
        notifications,
        supportsNotifications,
        workspaceAccount?.options?.theme?.logo_icon,
    ])

    useEffect(() => {
        if (!supportsNotifications) return

        if (
            Notification.permission === 'default' &&
            !notificationsDeclined &&
            user &&
            workspaceAccount
        ) {
            setShowPermissionModal(true)
        }
    }, [notificationsDeclined, supportsNotifications, user, workspaceAccount])

    const closeModal = () => {
        setNotificationsDeclined(true)
        setShowPermissionModal(false)
    }

    return (
        <Modal isOpen={showPermissionModal} title="Notifications" onClose={closeModal}>
            <Box flex column stretch fontSize="bodyM">
                <Box as="p">
                    You can receive important notifications even when your application is open in
                    the background.
                </Box>
                <Box as="p">Would you like to turn on desktop notifications?</Box>

                <Button
                    variant="primary"
                    size="l"
                    onClick={async () => {
                        if (!supportsNotifications) return

                        await Notification.requestPermission()
                        setShowPermissionModal(false)
                    }}
                >
                    Yes
                </Button>
                <Button variant="secondary" size="l" mt="m" onClick={closeModal}>
                    No, maybe later
                </Button>
            </Box>
        </Modal>
    )
}

type DesktopNotificationContent = {
    title: string
    body: string
}
function getNotificationContent(
    notification: NotificationDto,
    data: ActivitiesData
): DesktopNotificationContent | undefined {
    const activity = data.activities.find((a) => a.auto_id === notification.activity_id)

    if (activity?.related_to_type === RelatedToType.Record) {
        return getRecordActivityContent(notification, activity, data)
    }

    if (activity?.related_to_type === RelatedToType.Task) {
        return getTaskActivityContent(notification, activity, data)
    }

    if (activity?.related_to_type === RelatedToType.Document) {
        return getDocumentActivityContent(notification, activity, data)
    }

    if (notification?.task_id) {
        return getTaskNotificationContent(notification, data)
    }

    return
}

function getTaskNotificationContent(
    notification: NotificationDto,
    data: ActivitiesData
): DesktopNotificationContent | undefined {
    const task = data.tasks.find((a) => a.auto_id === notification.task_id)
    let body = task ? task.title : ''
    let title = ''
    if (notification.type === NotificationType.TaskAssigned) {
        const userId = notification.content?.user_id
        const user = userId ? data.users.find((u) => u._sid === userId) : undefined

        title = `${user?.name ?? 'Someone'} added you to a task`
    }

    return { title, body }
}
function getRecordActivityContent(
    notification: NotificationDto,
    activity: ActivityDto,
    data: ActivitiesData
): DesktopNotificationContent | undefined {
    const activityUser = activity
        ? data.users.find((u) => u._sid === activity.created_by)
        : undefined
    const record =
        activity && activity.related_to_type === RelatedToType.Record
            ? data.records.find((r) => r._sid === activity.related_to)
            : undefined

    if (!activityUser || !record) return

    let actionText = ''

    switch (activity.type) {
        case ActivityType.RecordCreated:
            actionText = 'created'
            break
        case ActivityType.RecordUpdated:
            actionText = 'updated'
            break
        case ActivityType.RecordDeleted:
            actionText = 'deleted'
            break
        default:
            actionText = 'on'
    }
    let body = activity.type === ActivityType.Comment ? activity.content.plainTextMessage : ''
    let title = ''

    switch (notification.type) {
        case NotificationType.Mention:
        case NotificationType.FollowedFeed:
            title = `${activityUser.name} ${actionText} ${record._primary}`
            break
        case NotificationType.Reply:
            title = `${activityUser.name} replied on ${record._primary}`
            break
    }

    return { title, body }
}

function getTaskActivityContent(
    notification: NotificationDto,
    activity: ActivityDto,
    data: ActivitiesData
): DesktopNotificationContent | undefined {
    const activityUser = activity
        ? data.users.find((u) => u._sid === activity.created_by)
        : undefined
    const task = data.tasks.find((r) => r.auto_id.toString() === activity.related_to)

    if (!activityUser || !task) return

    let actionText = 'on'
    let body = ''

    switch (activity.type) {
        case ActivityType.TaskCompleted:
            actionText = 'completed'
            break
        case ActivityType.TaskDueDateChanged: {
            actionText = 'updated'

            if (activity.content?.due_at) {
                const formattedDate = formatDueDate(activity.content.due_at)
                body = `Due date: ${formattedDate}`
            } else {
                body = 'Due date removed'
            }
            break
        }
        case ActivityType.TaskAssigneesChanged: {
            actionText = 'updated'

            const noAssignees =
                Array.isArray(activity.content?.assignees) && !activity.content?.assignees.length

            if (noAssignees) {
                body = 'Assignee removed'
            } else {
                const assignee = activity.content?.assignees?.[0]
                const user = data.users.find((u) => u._sid === assignee)
                if (user) {
                    const formattedUser = getUserDisplayName(user)
                    body = `Assignee: ${formattedUser}`
                } else {
                    body = 'Assignee changed'
                }
            }
            break
        }
        case ActivityType.Comment:
            actionText = 'on'
            body = activity.content.plainTextMessage
            break
        default:
            actionText = 'on'
    }

    let title = ''

    switch (notification.type) {
        case NotificationType.Mention:
        case NotificationType.FollowedFeed:
            title = `${activityUser.name} ${actionText} ${task.title}`
            break
        case NotificationType.Reply:
            title = `${activityUser.name} replied on ${task.title}`
            break
    }

    return { title, body }
}

function getDocumentActivityContent(
    notification: NotificationDto,
    activity: ActivityDto,
    data: ActivitiesData
): DesktopNotificationContent | undefined {
    const activityUser = activity
        ? data.users.find((u) => u._sid === activity.created_by)
        : undefined
    const document = data.documents.find((r) => r.auto_id.toString() === activity.related_to)

    if (!activityUser || !document) return

    let actionText = 'on'
    let body = ''

    switch (activity.type) {
        case ActivityType.Comment:
            actionText = 'on'
            body = activity.content.plainTextMessage
            break
        default:
            actionText = 'on'
    }

    let title = ''

    switch (notification.type) {
        case NotificationType.Mention:
        case NotificationType.FollowedFeed:
            title = `${activityUser.name} ${actionText} ${document.title}`
            break
        case NotificationType.Reply:
            title = `${activityUser.name} replied on ${document.title}`
            break
    }

    return { title, body }
}

function useSupportsNotifications() {
    const { isOnPortalDomain } = useWorkspaceContext()
    const { flags } = useLDFlags()
    const isMobile = useIsMobile()

    return !isMobile && 'Notification' in window && flags.notifications && !isOnPortalDomain
}
