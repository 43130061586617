// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { injectGlobal } from 'emotion'
import lightGallery from 'lightgallery'
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgVideo from 'lightgallery/plugins/video'
import lgZoom from 'lightgallery/plugins/zoom'

import { LIGHTGALLERY_LICENSE_KEY } from 'app/settings'

import { Box } from 'ui/components/Box'
import { LinkButton } from 'ui/components/LinkButton'

// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-video.css'

injectGlobal`
    .lg-container{
        z-index: 2000;
        position: relative;
    }

    .lg-fileviewer iframe{
        vertical-align: top;
    }

    .lg-file-error{
        position: absolute;
        top: 0;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column;
    }

    .lg-error-message{
        color: #999;
        margin-bottom: 1em;
        display: block;
        font-size: 1rem;
    }

    .lg-thumb-item{
        background: white;
    }

    .lg-container{font-family: inherit}

    .lg-pdf-viewer{
        display: inline-block;
        width: 100%;
        height: 100%;
    }
`

type GalleryProps = React.ComponentPropsWithoutRef<typeof Box> & {
    children: any
    selector?: string
    isOpen?: boolean
    onClose?: () => void
    useThumbnailsFromChildren?: boolean
}

const getPdfEmbed = (file: string) => {
    const element = document.createElement('object')
    element.classList.add('lg-pdf-viewer')
    element.setAttribute('type', 'application/pdf')
    element.setAttribute('data', file)
    return element
}

const Gallery = (
    {
        children,
        selector,
        isOpen: isOpenProp,
        onClose: onCloseProp,
        useThumbnailsFromChildren = true,
        ...props
    }: GalleryProps,
    ref
) => {
    const lightGalleryRef = useRef<any>()
    const galleryRef = useRef<any>()
    const item = useRef<any>()
    const errorElement = useRef<any>()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const onClose = useCallback(() => {
        setIsOpen(false)
        onCloseProp?.()
    }, [onCloseProp])

    const onInit = (event) => {
        const instance = event?.detail?.instance
        if (instance) {
            lightGalleryRef.current = instance
            if (ref) ref.current = instance
            // Add the event listeners and open the gallery on the correct item
            addEventListeners(galleryRef.current)
            instance.openGallery(item.current)
        }
    }

    const onAfterSlide = (event) => {
        const { index } = event.detail
        // The download button only works with images by default. This enables it on for all file types
        if (!lightGalleryRef?.current) return
        const downloadButton = lightGalleryRef.current?.$toolbar?.find('.lg-download')?.firstElement
        const slideItem = lightGalleryRef.current.galleryItems[index]
        if (downloadButton && slideItem?.downloadUrl) {
            downloadButton.setAttribute('download', '')
            downloadButton.style.pointerEvents = 'initial'
            downloadButton.style.opacity = '1'
            downloadButton.href = slideItem.downloadUrl + '?dl=true'
        }

        // Hide the zoom button if not an image type
        hideZoom(slideItem)
    }

    const hideZoom = (slideItem) => {
        const zoomButton = lightGalleryRef.current?.$toolbar?.find('.lg-zoom-in')?.firstElement
        if (zoomButton) {
            if (slideItem?.video || slideItem?.iframe) {
                zoomButton.style.display = 'none'
            } else {
                zoomButton.style.display = ''
            }
        }
    }

    const setError = (src) => {
        const error = errorElement.current.cloneNode(true)
        const downloadButton = error.getElementsByTagName('a')[0]
        downloadButton.href = src
        error.style.display = 'flex'
        return error
    }

    const onAfterAppendSlide = (event: any) => {
        const { index } = event.detail
        const slideItem = lightGalleryRef.current.galleryItems[index]
        const itemElement = lightGalleryRef.current?.getSlideItem(index)?.firstElement
        // use a pdf embed
        if (slideItem.pdf) {
            const original = itemElement.getElementsByClassName('lg-object')[0]
            if (original) original.remove()
            slideItem['iframe'] = true
            hideZoom(slideItem)
            const pdfEmbed = getPdfEmbed(slideItem.src)
            pdfEmbed.appendChild(setError(slideItem.src))
            itemElement.firstChild.appendChild(pdfEmbed)
            itemElement.classList.add('lg-complete')
            itemElement.classList.add('lg-loaded')
        }
    }

    const onSlideItemLoad = (event) => {
        const { index } = event.detail
        const itemElement = lightGalleryRef.current?.getSlideItem(index)?.firstElement
        const slideItem = lightGalleryRef.current.galleryItems[index]
        // The slide item has failed to load, so show a custom error message
        if (itemElement.getElementsByClassName('lg-error-msg').length) {
            // We need to set it to an iframe, otherwise it'll try to load the zoom plugin and crash
            slideItem['iframe'] = true
            hideZoom(slideItem)
            itemElement.replaceChildren(setError(slideItem.src))
        }
    }

    const onClick = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            setIsOpen(true)
            item.current = parseInt(e.target.getAttribute('data-index') || 0)
        },
        [setIsOpen]
    )

    const onKeyPress = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                onClick(e)
            }
        },
        [onClick]
    )

    const addEventListeners = (lg) => {
        lg.addEventListener('lgSlideItemLoad', onSlideItemLoad)
        lg.addEventListener('lgAfterAppendSlide', onAfterAppendSlide)
        lg.addEventListener('lgAfterSlide', onAfterSlide)
        lg.addEventListener('lgAfterClose', onClose)
    }

    const removeEventListeners = (lg) => {
        lg.removeEventListener('lgInit', onInit)
        lg.removeEventListener('lgSlideItemLoad', onSlideItemLoad)
        lg.removeEventListener('lgAfterAppendSlide', onAfterAppendSlide)
        lg.removeEventListener('lgAfterSlide', onAfterSlide)
        lg.removeEventListener('lgAfterClose', onClose)
    }

    useEffect(() => {
        const gallery = galleryRef.current
        if (isOpen && !lightGalleryRef.current) {
            galleryRef.current.addEventListener('lgInit', onInit)
            lightGallery(galleryRef.current, {
                extraProps: ['fileviewer', 'pdf'],
                speed: 500,
                plugins: [lgThumbnail, lgZoom, lgVideo],
                selector,
                mobileSettings: { controls: false, showCloseIcon: true, download: true },
                licenseKey: LIGHTGALLERY_LICENSE_KEY,
                addClass: 'ag-custom-component-popup',
                exThumbImage: useThumbnailsFromChildren ? undefined : 'data-thumbnail',
            })
        }
        return () => {
            // Destroy the gallery and remove event listeners on close
            if (gallery) removeEventListeners(gallery)
            if (lightGalleryRef.current) {
                lightGalleryRef.current.destroy()
                lightGalleryRef.current = undefined
            }
        }
        // We only want to instantiate the lightgallery when isOpen changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        if (typeof isOpenProp === 'undefined') return

        if (isOpenProp) {
            item.current = 0
        }

        setIsOpen(isOpenProp)
    }, [isOpenProp])

    if (!children) return null

    // Only load the lightbox if we have clicked on an item
    return (
        <>
            <Box
                style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}
                className="test"
                onClick={onClick}
                onKeyPress={onKeyPress}
                ref={galleryRef}
                {...props}
            >
                {children}
            </Box>
            <div className="lg-file-error" style={{ display: 'none' }} ref={errorElement}>
                <span className="lg-error-message">This file cannot be opened</span>
                <LinkButton
                    href="download"
                    variant="primary"
                    size="m"
                    startIcon={{ name: 'Download' }}
                >
                    Download file
                </LinkButton>
            </div>
        </>
    )
}

export default React.memo(React.forwardRef(Gallery))
