import React, { FC } from 'react'

import { DisplayPanel as AggregationDisplayPanel } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/panels/DisplayPanel'

import IconPickerForm from 'features/workspace/forms/IconPicker'

import { Box, Icon, Text } from 'v2/ui'
import { CustomRadioGroup } from 'v2/ui/components/CustomRadio'
import { Target, Zeros } from 'v2/ui/svgs'

import Form from 'ui/deprecated/forms/Form'

type DisplayPanelProps = {
    metric: MetricsBlockAttributes
    onChange: (
        metric: Pick<MetricsBlockAttributes, 'objectId'> & Partial<MetricsBlockAttributes>
    ) => void
}

const DISPLAY_AS_OPTIONS = [
    {
        value: 'default',
        label: 'Default',
        icon: <Icon svg={<Zeros />} />,
    },
    {
        value: 'icon',
        label: 'With icon',
        icon: <Icon svg={<Target />} />,
    },
]

const DEFAULT_ICON = 'faBook'

export const DisplayPanel: FC<DisplayPanelProps> = ({ metric, onChange }) => {
    const onChangeDisplayAs = (value: string) => {
        onChange({
            ...metric,
            display: { ...metric.display, icon: value === 'icon' ? DEFAULT_ICON : undefined },
        })
    }

    const onIconChange = (e: React.FormEvent<HTMLFormElement>, newIcon: string) => {
        e.stopPropagation()
        onChange({
            ...metric,
            display: { ...metric.display, icon: newIcon },
        })
    }
    return (
        <>
            <Box>
                <Text
                    variant="paletteSectionLabel"
                    as="label"
                    htmlFor="chart-style"
                    display="block"
                >
                    Display as
                </Text>
                <CustomRadioGroup
                    selectedOption={metric.display.icon ? 'icon' : 'default'}
                    options={DISPLAY_AS_OPTIONS}
                    onChange={onChangeDisplayAs}
                />
            </Box>
            {metric.display.icon && (
                <Box mb={2}>
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="chart-style"
                        display="block"
                    >
                        Icon
                    </Text>
                    <Form
                        options={{
                            mode: 'onChange',
                            defaultValues: {
                                icon: DEFAULT_ICON,
                            },
                        }}
                        onSubmit={() => {}}
                    >
                        <IconPickerForm
                            name="icon"
                            margin="0"
                            bodyPadding="0"
                            item={{ options: { icon: metric.display.icon } }}
                            customOnChange={onIconChange}
                        />
                    </Form>
                </Box>
            )}
            <AggregationDisplayPanel aggregationWidget={metric} onChange={onChange} />
        </>
    )
}
