import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'

export function useTableViewContentState() {
    const { records } = useTableViewContext()

    return useMemo(
        () => ({
            records,
        }),
        [records]
    )
}
