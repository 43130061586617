import React, { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { useNavigation } from 'data/hooks/navigation'
import AdminFrame from 'features/core/AdminFrame'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box, Flex } from 'v2/ui'
import { StackerWelcome } from 'v2/ui/svgs'

import { Button } from 'ui/components/Button'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { palette } from 'ui/styling/baseVariables/colors/colorPalette'

export function NewAppSplashScreen() {
    const { showManageData } = useSlidingPane()
    const { selectedStack } = useAppContext()
    const { data: navigation } = useNavigation()
    const isEmptyStackerDataStack = useMemo(() => {
        if (!navigation || !selectedStack) {
            return false
        }

        return !navigation.find(
            ({ api_name, stack_id }) =>
                !api_name.startsWith('users') && stack_id === selectedStack._sid
        )
    }, [navigation, selectedStack])

    let SplashImage = StackerWelcome

    // App has pages, but they are all disabled
    if (!isEmptyStackerDataStack)
        return (
            <AdminFrame ignoreLoadingState>
                <Flex
                    id="splashscreen_wrapper"
                    column
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    height="100%"
                >
                    <div style={{ marginBottom: 40 }}>
                        <SplashImage />
                    </div>
                    <Box fontSize={24} fontWeight={600} marginBottom="6px">
                        All of your pages have been hidden.
                    </Box>
                </Flex>
            </AdminFrame>
        )

    return (
        <Flex alignItems="center" justifyContent="center" column>
            <AdminFrame ignoreLoadingState>
                {
                    <Flex
                        id="splashscreen_wrapper"
                        column
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        height="100%"
                    >
                        <div style={{ marginBottom: 40 }}>
                            <SplashImage />
                        </div>
                        {
                            <>
                                <Box fontSize={18} fontWeight={600} marginBottom="6px">
                                    Your{' '}
                                    {selectedStack ? (
                                        <>
                                            <span
                                                style={{
                                                    color: V4DesignSystem.colors.stacker,
                                                }}
                                            >
                                                {selectedStack.name}
                                            </span>{' '}
                                            app
                                        </>
                                    ) : (
                                        'app'
                                    )}{' '}
                                    has been created!
                                </Box>
                                <Flex
                                    mt={2}
                                    style={{
                                        color: V4DesignSystem.colors.text,
                                        maxWidth: 465,
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        lineHeight: 1.4,
                                        flexDirection: 'column',
                                    }}
                                >
                                    Start building your app by creating a new table.
                                    {isEmptyStackerDataStack && (
                                        <Flex marginTop="20px" alignItems="center" direction="row">
                                            <Button
                                                size="l"
                                                variant="primary"
                                                onClick={() =>
                                                    showManageData({
                                                        shouldShowNewTableModal: true,
                                                    })
                                                }
                                                style={{
                                                    backgroundColor: palette.admin600,
                                                }}
                                            >
                                                Create a new table
                                            </Button>
                                        </Flex>
                                    )}
                                </Flex>
                            </>
                        }
                    </Flex>
                }
            </AdminFrame>
        </Flex>
    )
}
