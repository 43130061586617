import React, { useEffect, useState } from 'react'

import { Button } from 'ui/components/Button'
import { Modal, ModalContent, ModalTrigger } from 'ui/components/Modal'

import { AiAppBuilder } from './AiAppBuilder'

export function AiPlaygroundModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [contentsKey, setContentsKey] = useState<number>(0)

    useEffect(() => {
        if (!isOpen) {
            setContentsKey((k) => k + 1)
        }
    }, [isOpen])

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>
                <Button>Create with AI</Button>
            </ModalTrigger>

            <ModalContent flex column style={{ width: '95vw', height: '95vh' }}>
                <AiAppBuilder key={contentsKey} onClose={() => setIsOpen(false)} />
            </ModalContent>
        </Modal>
    )
}
