import React from 'react'

import { useAppContext } from 'app/AppContext'
import settings from 'app/settings'
import { useUpdateAccount } from 'data/hooks/accounts'
import { Frame } from 'features/core/Frame'

import AttributeList from 'v2/ui/components/Attribute/AttributeList'

import { premiumFeatures } from './config'

export const AccountSettings = ({}) => {
    const { workspaceAccount } = useAppContext()
    const { mutate: updateAccount } = useUpdateAccount()

    if (!workspaceAccount) {
        return null
    }

    if (!localStorage.getItem('support_login') && settings.IS_PROD)
        return (
            <Frame background="#2a333c">
                =<h1 style={{ color: '#e9e9ea' }}>You are not authorized to view this page.</h1>
            </Frame>
        )

    return (
        <Frame background="#2a333c">
            <h1 style={{ color: '#e9e9ea' }}>Account Premium Features</h1>
            <div style={{ marginTop: 10, marginBottom: 30 }}>
                <div>
                    <AttributeList
                        editable
                        data={workspaceAccount.optional_features}
                        onChange={(vals) =>
                            updateAccount({
                                id: workspaceAccount._sid,
                                patch: {
                                    optional_features: {
                                        ...workspaceAccount.optional_features,
                                        ...vals,
                                    },
                                },
                            })
                        }
                        fields={premiumFeatures}
                    />
                </div>
            </div>
            <h1 style={{ color: '#e9e9ea' }}>Auth Settings</h1>
            <div style={{ marginTop: 10, marginBottom: 30, background: '#e9e9ea' }}>
                <div>
                    <AttributeList
                        editable
                        data={workspaceAccount}
                        onChange={(vals) =>
                            updateAccount({ id: workspaceAccount._sid, patch: { ...vals } })
                        }
                        fields={[
                            {
                                api_name: 'sso_required',
                                label: 'Enable SSO',
                                type: 'checkbox',
                            },
                        ]}
                    />
                </div>
            </div>
        </Frame>
    )
}
