import React from 'react'

import styled from '@emotion/styled'

import { PoweredBy } from 'features/core/PoweredBy'
import Card from 'legacy/v1/ui/components/Card'

export const CenterPanelWrap = styled('div')`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
`

export const CenterPanel = styled(Card)`
    width: 400px;
`

export const CenterPanelWrapper = ({ children, hidePoweredBy, className }) => (
    <CenterPanelWrap className={className} style={{ minHeight: '100%', height: 'auto' }}>
        {children}
        {!hidePoweredBy && <PoweredBy />}
    </CenterPanelWrap>
)
