import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import get from 'lodash/get'

import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { insertCustomScripts, removeCustomScripts } from 'utils/customScripts'

import { useAuthContext } from './AuthContext/AuthContext'
import { useAppContext } from './AppContext'

const _CustomScripts: React.FC = () => {
    const { selectedStack } = useAppContext()
    const { user } = useAuthContext()

    const { isPreviewingAsUserOrRole } = usePreviewServiceContext()
    //return children
    const customScripts = get(selectedStack?.options, 'custom_scripts')
    const CUSTOM_SCRIPT_ATTR = 'data-custom-user-script'
    const customPageScripts = get(selectedStack?.options, 'custom_page_scripts')
    const CUSTOM_PAGE_SCRIPT_ATTR = 'data-custom-page-user-script'
    const LOGGED_OUT_PATHS = ['/login', '/register', '/forgot-password', '/set-password']

    const location = useLocation()

    const extra_info = { user, location }

    const currentUserSid = user?._sid
    const userLoaded = !isPreviewingAsUserOrRole
    //we are only interested in the first part of the path
    const topPath = `/${location?.pathname?.split('/')?.[1]}`
    const readyToLoad = userLoaded || LOGGED_OUT_PATHS.includes(topPath)

    useEffect(() => {
        if (readyToLoad && !localStorage.getItem('support_login')) {
            removeCustomScripts(CUSTOM_SCRIPT_ATTR)
            insertCustomScripts(customScripts, CUSTOM_SCRIPT_ATTR, extra_info)
        }
        // we want to remove and add the app script again if the app changes (even if the script is the same in both apps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStack?._sid, customScripts, userLoaded, currentUserSid])

    // remove and add the page scripts again on location change
    // this is so that scripts such as GA can track all
    // page views
    useEffect(() => {
        if (!localStorage.getItem('support_login') && readyToLoad) {
            removeCustomScripts(CUSTOM_PAGE_SCRIPT_ATTR)
            insertCustomScripts(customPageScripts, CUSTOM_PAGE_SCRIPT_ATTR, extra_info)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, userLoaded, customPageScripts, currentUserSid])

    // Remove any scripts on unmount
    useEffect(() => {
        return () => {
            removeCustomScripts(CUSTOM_PAGE_SCRIPT_ATTR)
            removeCustomScripts(CUSTOM_SCRIPT_ATTR)
        }
    }, [])

    return null
}
export const CustomScripts = memo(_CustomScripts)
