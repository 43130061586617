import React, { forwardRef, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import Select, { components, createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FixedSizeList as List } from 'react-window'

import { useTheme } from '@chakra-ui/react'
import Color from 'color'
import { isArray } from 'lodash'
import PropTypes from 'prop-types'

import { getDropdownFieldOptionColor, getDropdownFieldOptionTextColor } from 'utils/fieldUtils'
import { wrapStringComponentWithSpan } from 'utils/utils'

import { withTheme } from 'v2/ui/theme/components/withTheme'

import ConditionalWrapper from './ConditionalWrapper'
import Flex from './Flex'

function MenuList(props) {
    const { options, children, maxHeight, getValue, selectProps = {} } = props
    if (!children || !Array.isArray(children)) return children

    const hideSelectedOptions = props.hideSelectedOptions !== false && props.isMulti
    const height = props.selectProps?.optionHeight || 35
    let totalHeight = children.length * height

    if (props.selectProps?.allowHorizontalScroll) {
        totalHeight += 20
    }

    const selectedValues = getValue()
    const menuHeight = Math.min(maxHeight, totalHeight)
    let initialIndex = 0
    // calc the start of the first selected item
    // if we're hiding options then get the first index
    if (!hideSelectedOptions) {
        initialIndex = selectedValues[0] ? options.indexOf(selectedValues[0]) : 0
    }
    let initialOffset = initialIndex > 0 ? initialIndex * height : 0
    // if it's within the height of the menu, then don't need it
    // otherwise, we'll set that as our initial scroll offset so we
    // scroll to the the first selected item
    if (initialOffset < menuHeight) {
        initialOffset = 0
    }

    const FooterComponent = props.selectProps?.footer

    return (
        <React.Fragment>
            <List
                width={''}
                itemSize={height}
                height={menuHeight}
                itemCount={children.length}
                initialScrollOffset={initialOffset}
                style={{ overflowX: props.selectProps?.allowHorizontalScroll ? 'scroll' : 'auto' }}
                className={selectProps.className}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
            {props.selectProps?.footer && <FooterComponent {...props.selectProps} />}
        </React.Fragment>
    )
}

function SelectContainer(props) {
    // overriding so we can pass along data-testid prop
    return (
        <components.SelectContainer
            {...props}
            innerProps={{
                ...props.innerProps,
                'data-testid': props.selectProps['data-testid'],
            }}
        />
    )
}

function Menu(props) {
    return (
        <components.Menu
            {...props}
            innerProps={{
                ...props.innerProps,
                'data-testid': props.selectProps['data-testid']
                    ? props.selectProps['data-testid'] + '-menu'
                    : '',
            }}
        />
    )
}

function Option(props) {
    // dropping these two handlers for perf reasons.
    // We handle the highlighting via CSS anway.
    const {
        selectProps: { allowDropdownColors },
        data,
        // eslint-disable-next-line unused-imports/no-unused-vars
        onMouseMove,
        // eslint-disable-next-line unused-imports/no-unused-vars
        onMouseOver,
        ...rest
    } = props

    const optionColor = getDropdownFieldOptionColor(data)
    const textColor = getDropdownFieldOptionTextColor(data)

    return (
        <components.Option
            {...rest}
            innerProps={{
                ...props.innerProps,
                title: props.children,
                'data-testid': props.selectProps['data-testid']
                    ? props.selectProps['data-testid'] + '-option'
                    : '',
            }}
        >
            <ConditionalWrapper
                condition={allowDropdownColors && optionColor}
                wrapper={(children) => (
                    <span
                        style={{
                            backgroundColor: optionColor,
                            color: textColor,
                            padding: '0.25rem',
                            borderRadius: 5,
                        }}
                    >
                        {children}
                    </span>
                )}
            >
                {props.children}
            </ConditionalWrapper>
        </components.Option>
    )
}

// Iterates of the supplied options and returns a list of any any
// which are in the supplied selected values list
function getSelectedOptions(values, options) {
    return options.reduce((selected, option) => {
        if (values.find((x) => option.value === x)) {
            selected.push(option)
            // If this option has sub options, check them to see if any are selected
        } else if (isArray(option.options)) {
            selected = selected.concat(getSelectedOptions(values, option.options))
        }
        return selected
    }, [])
}

// Get selected options for Kanban.
// This function accounts for the kanban case where 'none' option has a value of null
function getSelectedOptionsAllowNull(values, options) {
    return values.reduce((selected, value) => {
        const selectedOption = options.find((option) => option.value === value)
        selectedOption && selected.push(selectedOption)
        return selected
    }, [])
}

const _Dropdown = (props, ref) => {
    const style = props.style ? props.style : {}
    const variant = props.variant
    const { margin, width, flex, ...otherStyles } = style

    const { display } = props
    const { renderValuesOutside, usePortal = true } = props
    const { disableComponentOverrides } = props
    const valueContainer = useRef()
    const [isDropdownFocused, setIsDropdownFocused] = useState(false)

    const MultiValueContainer = (props) => {
        if (!valueContainer.current) return null
        return createPortal(<components.MultiValueContainer {...props} />, valueContainer.current)
    }

    const SingleValue = (innerProps) => {
        const testId = `${
            props['data-testid'] ? props['data-testid'] + '.' : ''
        }dropdown-single-value`
        const singleValueProps = {
            'data-testid': testId,
        }
        if (props.renderValue) {
            return wrapStringComponentWithSpan(props.renderValue(innerProps.data), singleValueProps)
        }
        // Prevent Dropdown from failing when translation is used.
        return wrapStringComponentWithSpan(innerProps.children, singleValueProps)
    }

    let variantProps = {}

    switch (variant) {
        case 'settings':
            variantProps = {
                padding: '2px',
                isClearable: false,
                optionHeight: 23,
                controlStyle: {
                    background: props.disabled ? '#efefef' : 'white',
                    borderRadius: '5px',
                    height: '100%',
                },
            }
            break
    }

    // Todo: this is really horrible and uses a mixture of themes. We need to clean this up and make it more consistent
    const theme = useTheme()
    const colors = theme.colors
    const finalProps = { ...variantProps, ...props, theme: { ...props.theme, ...theme } }

    const fontSize = finalProps.fontSize ? finalProps.fontSize : finalProps.theme.fontSizes.sm

    const customStyles = {
        container: (provided) => ({
            ...provided,
            display: display,
            margin: margin || '',
            flex: flex || 'none',
            maxWidth: finalProps.maxWidth,
            ...otherStyles,
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: finalProps.theme?.fonts?.body || finalProps.theme.fontFamily,
            fontSize,
            width: '100%',
            height: finalProps.optionHeight || '35px',
            backgroundColor:
                state.isFocused && !finalProps.ignoreOptionFocused
                    ? finalProps.theme.elementHighlightColor
                    : finalProps.theme.inputBackgroundColor,
            color: finalProps.color ? finalProps.color : finalProps.theme.fontColor,
            padding: finalProps.padding ? `${finalProps.padding} 8px` : '8px',
            '&:hover': {
                backgroundColor: finalProps.theme.elementHighlightColor,
            },
            whiteSpace: !finalProps.wrap ? 'nowrap' : null,
            overflow: !finalProps.allowHorizontalScroll ? 'hidden' : null,
            textOverflow: 'ellipsis',
            ...finalProps.optionProps,
        }),
        placeholder: () => ({
            color: finalProps.color ? finalProps.color : colors.userInterface.neutral[850],
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontFamily: finalProps.theme?.fonts?.body || finalProps.theme.fontFamily,
            fontSize,
            color: finalProps.color ? finalProps.color : finalProps.theme.fontColor,
            textAlign: 'left',
        }),
        control: (provided, state) => ({
            ...provided,
            background: finalProps.theme.input,
            fontFamily: finalProps.theme?.fonts?.body || finalProps.theme.fontFamily,
            fontSize,
            borderRadius: finalProps.theme.elementBorderRadius,
            border: state.isFocused
                ? `1px solid ${
                      finalProps.adminTheme
                          ? colors.admin.input.borderActive
                          : colors.input.borderHover
                  }`
                : `1px solid ${
                      finalProps.adminTheme ? colors.admin.input.border : colors.input.border
                  }`,
            outline: 'none',
            color: finalProps.color ? finalProps.color : finalProps.theme.fontColor,
            boxShadow: 'none',
            minWidth: finalProps.width ? finalProps.width : width || '150px',
            minHeight: 'auto',
            opacity: finalProps.disabled ? 0.7 : 1,
            cursor: 'pointer',
            '&:hover': {
                borderColor: colors.input.borderHover,
            },
            ...(typeof finalProps.controlStyle === 'function'
                ? finalProps.controlStyle(state)
                : finalProps.controlStyle),
        }),
        multiValueLabel: (provided, { selectProps, data }) => {
            if (!selectProps.allowDropdownColors || !data.color) {
                return {
                    ...provided,
                    backgroundColor: colors.grey[100],
                    ':hover': {
                        backgroundColor: colors.grey[200],
                    },
                }
            }

            const color = Color(getDropdownFieldOptionColor(data))
            const textColor = getDropdownFieldOptionTextColor(data)

            return {
                ...provided,
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
                backgroundColor: color.hex(),
                color: textColor,
                ':hover': {
                    backgroundColor: color.darken(0.1).hex(),
                },
            }
        },
        multiValueRemove: (provided, { selectProps, data }) => {
            if (!selectProps.allowDropdownColors || !data.color) {
                return {
                    ...provided,
                    cursor: 'pointer',
                    backgroundColor: colors.grey[100],
                    ':hover': {
                        backgroundColor: colors.grey[200],
                    },
                }
            }

            const color = Color(getDropdownFieldOptionColor(data))
            const textColor = getDropdownFieldOptionTextColor(data)

            return {
                ...provided,
                cursor: 'pointer',
                borderTopRightRadius: 3,
                borderBottomRightRadius: 3,
                backgroundColor: color.hex(),
                color: textColor,
                ':hover': {
                    backgroundColor: color.darken(0.1).hex(),
                },
            }
        },
        singleValue: (provided) => {
            if (!finalProps.useTagStyle) return provided

            return {
                ...provided,
                backgroundColor: colors.grey[100],
                padding: '2px',
                paddingLeft: '6px',
                paddingRight: '6px',
                borderRadius: '4px',
                ':hover': {
                    backgroundColor: colors.grey[200],
                },
            }
        },
        menu: (provided) => ({
            ...provided,
            outline: 'none',
            boxShadow: '1px 1px 5px rgba(0,0,0,.25)',
            background: '#fff',
            border: finalProps.border
                ? finalProps.border
                : `${finalProps.theme.dividerThickness}px solid ${finalProps.theme.outlineColor}`,
            margin: 0,
            zIndex: 2,
            ...finalProps.menuProps,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            opacity: 0.5,
            padding: finalProps.padding ? `${finalProps.padding} 2px` : '8px 2px',
            marginBottom: '2px',
            ...finalProps.dropdownIndicatorProps,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: finalProps.padding ? `${finalProps.padding} 2px` : '8px 2px',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: colors.grey[100],
            },
            ...finalProps.clearIndicatorProps,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (provided) => {
            return {
                ...provided,
                ...finalProps.valueContainerProps,
            }
        },
        ...finalProps.additionalStyles,
    }

    let selected = finalProps.defaultValue || null
    if (finalProps.value && finalProps.options) {
        const values = isArray(finalProps.value) ? finalProps.value : [finalProps.value]
        selected = finalProps.allowNullValues
            ? getSelectedOptionsAllowNull(values, finalProps.options)
            : getSelectedOptions(values, finalProps.options)
        if (!finalProps.isMulti) {
            if (selected && selected.length) {
                selected = selected?.[0]
            } else if (finalProps.returnObject) {
                selected = finalProps.value
            }
        }
    }

    // If we selected an option then unpack its value and pass to onChange
    // Otherwise we cleared the select box, so pass empty to onChange
    // In the multi-select case we receive an array of options and pass out an array of unpacked values.
    let onChange
    if (finalProps.isMulti) {
        onChange = (optionList) => {
            if (optionList) {
                const optionValues = optionList.map((option) =>
                    finalProps.returnObject ? option : option.value
                )
                return finalProps.onChange && finalProps.onChange(optionValues)
            } else {
                return finalProps.onChange && finalProps.onChange([])
            }
        }
    } else {
        onChange = (option) =>
            option
                ? finalProps.onChange &&
                  finalProps.onChange(finalProps.returnObject ? option : option.value)
                : finalProps.onChange && finalProps.onChange(null)
    }

    // The default filter in react-select filters on both the label and the value,
    // which can lead to unexpected results. Only use the label for filtering.

    const filter = createFilter({
        ignoreAccents: false,
        stringify: (option) => (option.label && option.label.toString()) || '',
    })

    const SelectComponent = props.creatable ? CreatableSelect : Select

    let internalComponents = {
        SelectContainer,
        Menu,
        Option: props.optionComponent || Option,
        SingleValue: props.singleValueComponent || SingleValue,
        Control: props.controlComponent || components.Control,
        ...props.components,
    }

    if (!disableComponentOverrides) {
        internalComponents = renderValuesOutside
            ? {
                  SelectContainer: props.containerComponent || SelectContainer,
                  Menu,
                  MultiValueContainer,
                  MenuList,
                  Control: props.controlComponent || components.Control,
                  Option: props.optionComponent || Option,
                  SingleValue: props.singleValueComponent || SingleValue,
              }
            : {
                  SelectContainer: props.containerComponent || SelectContainer,
                  Menu,
                  MenuList,
                  Option: props.optionComponent || Option,
                  SingleValue: props.singleValueComponent || SingleValue,
                  Control: props.controlComponent || components.Control,
              }
    }

    const ensureNoUndefinedOption = (option) =>
        !option || option.label ? option : { ...selected, label: '' }

    return (
        <>
            <SelectComponent
                ref={ref}
                {...finalProps}
                value={
                    Array.isArray(selected)
                        ? selected.map(ensureNoUndefinedOption)
                        : ensureNoUndefinedOption(selected)
                }
                onChange={onChange}
                menuPortalTarget={usePortal ? document.body : null}
                options={finalProps.options.map(ensureNoUndefinedOption)}
                ignoreCase
                filterOption={filter}
                multi={finalProps.isMulti}
                styles={customStyles}
                components={{ ...internalComponents, ...props.components }}
                isClearable={finalProps.isClearable === false ? false : true}
                isDisabled={!!finalProps.disabled}
                onFocus={() => setIsDropdownFocused(true)}
                onBlur={() => setIsDropdownFocused(false)}
                placeholder={
                    props.shouldHidePlaceholderOnFocus && isDropdownFocused ? '' : props.placeholder
                }
                menuPlacement="auto"
            />
            <Flex ref={valueContainer} display={display} />
        </>
    )
}

_Dropdown.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
    shouldHidePlaceholderOnFocus: PropTypes.bool,
}

_Dropdown.defaultProps = {
    value: undefined,
    onChange: undefined,
    isMulti: false,
    shouldHidePlaceholderOnFocus: false,
}

export const Dropdown = React.memo(withTheme(forwardRef(_Dropdown)))
