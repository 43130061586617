import { useEffect } from 'react'

import { useDataGridContext } from 'features/datagrid/components/DataGridContext'
import {
    getFocusedRow,
    isNewRecordPlaceholder,
    isTargetEditable,
    startEditingNewRecord,
} from 'features/datagrid/utils'

import { useDeleteSelectedRecords } from './useDeleteSelectedRecords'

type UseKeyboardEventsProps = {
    addNewEmptyRecord: () => void
    deleteRecords?: (recordIds: string[]) => Promise<void>
}

/**
 * Here we handle various keyboard shortcuts for the grid.
 * If you want to disable one of the grid's default behaviors, you can do so in
 * `suppressKeyboardEvent` in `useGridColumns.ts`.
 * @param deleteRecords
 */
export function useGridKeyboardEvents({
    deleteRecords,
    addNewEmptyRecord,
}: UseKeyboardEventsProps) {
    const deleteSelectedRecords = useDeleteSelectedRecords(deleteRecords)

    const { isGridReady, dispatch, gridApi } = useDataGridContext()

    useEffect(() => {
        if (!gridApi || !isGridReady) return

        const listener = (e: KeyboardEvent) => {
            const target = e.target as HTMLElement | null
            const isWithinEditableEl = isTargetEditable(target)
            const focusedRow = getFocusedRow(gridApi)
            const isNewRecordPlaceholderRow = isNewRecordPlaceholder(focusedRow?.data)
            const editingCells = gridApi.getEditingCells()
            const isEditing = editingCells.length > 0

            if (e.key === 'Enter') {
                // Shift + Enter to show new record row.
                if (e.shiftKey) {
                    e.preventDefault()
                    addNewEmptyRecord()
                    return
                }

                if (isNewRecordPlaceholderRow) {
                    // Focus on the new record row again, to be able to add multiple records quickly.
                    setTimeout(() => {
                        startEditingNewRecord(gridApi, false)
                    }, 50)
                }

                // Meta/Ctrl + Enter to save and stop editing.
                if ((e.metaKey || e.ctrlKey) && isEditing) {
                    gridApi.stopEditing()
                    return
                }
            }

            // Escape within the new record row to hide it.
            if (e.key === 'Escape' && isNewRecordPlaceholderRow) {
                dispatch({ type: 'hideEmptyRecordRow' })
                e.preventDefault()
                e.stopPropagation()
                return
            }

            // Delete/Backspace to delete selected records.
            if ((e.key === 'Delete' || e.key === 'Backspace') && !isWithinEditableEl) {
                deleteSelectedRecords()
                return
            }

            // If pressing Tab when creating a new record, stop editing to prevent the editor from flashing in.
            if (e.key === 'Tab' && isNewRecordPlaceholderRow && isEditing) {
                gridApi.stopEditing()
            }
        }

        document.addEventListener('keydown', listener)
        return () => document.removeEventListener('keydown', listener)
    }, [deleteSelectedRecords, isGridReady, dispatch, gridApi, addNewEmptyRecord])
}
