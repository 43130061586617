import React, { useContext, useMemo } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { ListViewSort, ListViewSortType } from 'features/views/ListView/Sort/types'

import { useListHeaderFilters } from './Filters/hooks/useListHeaderFilters'
import { useListHeaderSearch } from './Search/hooks/useListHeaderSearch'
import { useListHeaderSort } from './Sort/hooks/useListHeaderSort'

export type ListHeaderContextValue = {
    viewTitle: string
    value: ListViewHeader
    object: ObjectDto
    stack: StackDto
    recordCount: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    availableSortFields: FieldDto[]
    sortType: ListViewSortType
    view: ViewDto
    searchQuery: string
    setSearchQuery: (searchQuery: string) => void
    inlineFilters: Filter[]
    inlineFilterType?: ListViewInlineFilters
    addInlineFilter: (filter: Filter) => void
    removeInlineFilter: (id: number) => void
    updateInlineFilter: (filter: Filter) => void
    setFilters: (filters: Filter[]) => void
    clearInlineFilters: () => void
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    allowSearch: boolean
    allowCreate: boolean
    allowDownload: boolean
    isEditMode: boolean
    requestIncludedFields: string[]
    requestFilters: Filter[]
}

export const ListHeaderContext = React.createContext<ListHeaderContextValue | null>(null)

export function useListHeaderContext(): ListHeaderContextValue {
    const context = useContext(ListHeaderContext)

    if (!context) {
        throw new Error('useListHeaderContext must be used within a ListHeaderContextProvider')
    }

    return context
}

export type ListHeaderContextProviderProps = {
    recordCount?: number
    requestIncludedFields?: string[]
    requestFilters?: Filter[]
}

export const ListHeaderContextProvider: React.FC<ListHeaderContextProviderProps> = ({
    children,
    recordCount = 0,
    requestIncludedFields = [],
    requestFilters = [],
}) => {
    const {
        view,
        object,
        isEmbedded,
        embeddedByField,
        embeddedByFieldValue,
        allowCreate,
        allowDownload,
        isEditMode,
        stack,
        title: viewTitle,
        header,
    } = useListViewContext()

    const {
        availableFields: availableSortFields,
        setSortBy,
        sortBy,
        sortType,
    } = useListHeaderSort()

    const { query: searchQuery, setQuery: setSearchQuery, allowSearch } = useListHeaderSearch()

    const {
        inlineFilters,
        addInlineFilter,
        removeInlineFilter,
        updateInlineFilter,
        setFilters,
        clearInlineFilters,
        availableInlineFilterFields,
        inlineFilterType,
    } = useListHeaderFilters()

    const value = useMemo(
        () => ({
            viewTitle,
            value: header,
            object,
            stack,
            recordCount,
            sortBy,
            setSortBy,
            availableSortFields,
            sortType,
            view,
            searchQuery,
            setSearchQuery,
            allowSearch,
            inlineFilters,
            inlineFilterType,
            addInlineFilter,
            removeInlineFilter,
            updateInlineFilter,
            setFilters,
            clearInlineFilters,
            availableInlineFilterFields,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            allowCreate,
            allowDownload,
            isEditMode,
            requestIncludedFields,
            requestFilters,
        }),
        [
            viewTitle,
            header,
            object,
            stack,
            recordCount,
            sortBy,
            setSortBy,
            availableSortFields,
            sortType,
            view,
            searchQuery,
            setSearchQuery,
            allowSearch,
            inlineFilters,
            inlineFilterType,
            addInlineFilter,
            removeInlineFilter,
            updateInlineFilter,
            setFilters,
            clearInlineFilters,
            availableInlineFilterFields,
            isEmbedded,
            embeddedByField,
            embeddedByFieldValue,
            allowCreate,
            allowDownload,
            isEditMode,
            requestIncludedFields,
            requestFilters,
        ]
    )

    return <ListHeaderContext.Provider value={value}>{children}</ListHeaderContext.Provider>
}
