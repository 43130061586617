import React from 'react'

import Box from './Box'
import Collapse from './Collapse'
import Flex from './Flex'
import Icon from './Icon'
import Text from './Text'
const AlertBar = ({ isOpen, children, buttons, ...props }) => {
    return (
        <Collapse isOpen={!!isOpen}>
            <Flex
                wrap="nowrap"
                bg="alertBackground"
                color="alertForeground"
                p={4}
                rounded="md"
                {...props}
            >
                <Icon icon="alert" fontSize="md" opacity={0.7} mr={3} />
                <Text flexGrow={1} variant="alert">
                    {children}
                </Text>

                {buttons && <Box>{buttons}</Box>}
            </Flex>
        </Collapse>
    )
}
export default AlertBar
