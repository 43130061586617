import React from 'react'
import { Link } from 'react-router-dom'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import get from 'lodash/get'

import { getUrl, Urls } from 'app/UrlService'
import { withUser } from 'data/wrappers/WithUser'
import Button from 'legacy/v1/ui/components/Button'
import Icon8 from 'legacy/v1/ui/components/Icon8'
import Text from 'legacy/v1/ui/components/Text'
import publicAsset from 'utils/publicAsset'

export const MenuToggleButton = styled('span')`
    display: inline-flex;
    flex-direction: row;
    padding: 4px 8px;
    border-radius: 3px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`

export const Cover = styled('div')`
    display: inline-flex;
    position: fixed;
    height: 100dvh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 1000;
`

export const LoadingRecords = styled('img')`
    display: block;
    height: 32px;
    width: 32px;
    margin: 0% auto;
`

export const LoadingError = () => (
    <Text style={{ textAlign: 'center' }}>
        <Icon8
            icon="cancel-2"
            displaySize="32"
            color="333333"
            style={{ transform: 'rotate(90deg)', position: 'relative', top: '-1px' }}
        />
        These records could not be loaded.
    </Text>
)

export const _PermissionError = ({ errorCode, object, isPreviewingAsUserOrRole }) => {
    // These errors will only happen for the enduser, or when the admin is previewing as a user or role
    const message = `There is a problem with your permissions, please check the configuration. Error
    code: ${errorCode}`

    // we will show the permission button config only for admins
    let permissionButton = ''
    if (isPreviewingAsUserOrRole && object) {
        const permissionsUrl = `${getUrl(Urls.AdminSetup)}${get(object, 'url')}/permissions`
        const permissionsLabel = `${get(object, 'name')} Permissions`

        permissionButton = (
            <Button style={{ textAlign: 'center', width: '300px', margin: 'auto' }}>
                <Link to={permissionsUrl}>{permissionsLabel}</Link>
            </Button>
        )
    }

    return (
        <>
            <Text style={{ textAlign: 'center' }}>
                <Icon8
                    icon="cancel-2"
                    displaySize="32"
                    color="333333"
                    style={{ transform: 'rotate(90deg)', position: 'relative', top: '-1px' }}
                />
                {message}
            </Text>
            {permissionButton}
        </>
    )
}

export const PermissionError = withUser(_PermissionError)

export const LoadingState = ({ loadingError, loadingTimedOut, errorCode, object }) => {
    if (loadingError) {
        if (errorCode >= 100) {
            return <PermissionError errorCode={errorCode} object={object} />
        } else {
            return <LoadingError />
        }
    } else if (loadingTimedOut) {
        return <TimedOutState />
    } else {
        return <LoadingRecords src={publicAsset('/static/media/Spinner.svg')} alt="" />
    }
}

export const ListCallout = styled('div')`
    width: 100%;
    display: flex;
    padding: 5px 30px;
    align-items: center;
    justify-content: center;
    background: #eceff3;
    flex-direction: row;
`

const _TimedOutState = ({ hasData, isPreviewingAsUserOrRole }) => {
    return (
        <ListCallout>
            <Icon8
                iconStyle="ios-glyphs"
                size="100"
                icon="turtle"
                color="9da3ab"
                displaySize="35"
                style={{ paddingRight: '10px' }}
            />
            <Text
                css={css`
                    color: #9da3ab;
                    font-weight: 500;
                `}
            >
                {hasData
                    ? "It's taking too long to load more records. The data here might be incomplete"
                    : 'This data is taking longer than expected to load.'}
                {!isPreviewingAsUserOrRole && (
                    <>
                        <br />
                        Please contact Stacker Support (support@stackerhq.com) for help.
                    </>
                )}
            </Text>
        </ListCallout>
    )
}

export const TimedOutState = withUser(_TimedOutState)
