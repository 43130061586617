import React, { useMemo } from 'react'

import { useStackRoles } from 'data/hooks/roles'

import { CheckboxDropdown, DropdownButton, Icon } from 'v2/ui'

import ToggleDropdown from './ToggleDropdown'

export const RolesSelect = ({
    value,
    onChange: _onChange,
    isInSettingsModal = false,
    ...props
}) => {
    const { data: roles } = useStackRoles()
    const rolesWithAllRoles = useMemo(() => {
        return [{ _sid: '*', label: 'All Roles' }, ...roles]
    }, [roles])

    const valueWithAllRoles = useMemo(() => {
        if (value.includes('*')) {
            // all roles should be selected
            return rolesWithAllRoles.map(({ _sid }) => _sid)
        }

        return value
    }, [value, rolesWithAllRoles])

    function onChange(selectedRoles) {
        // uncheck All Roles if unchecking another role
        if (
            value.includes('*') &&
            selectedRoles.includes('*') &&
            selectedRoles.length < valueWithAllRoles.length
        ) {
            return _onChange(selectedRoles.filter((roleId) => roleId !== '*'))
        }
        return _onChange(selectedRoles)
    }

    return (
        <div className="display-flex">
            <div style={{ flex: 1 }}>
                {isInSettingsModal ? (
                    <ToggleDropdown
                        options={rolesWithAllRoles.map((role) => ({
                            value: role._sid,
                            label: role.label,
                            icon: role.options?.all_pages && 'lock',
                            tooltip: role.options?.all_pages && 'This role can see all pages',
                            isDisabled: role.options?.all_pages,
                            forceSelected: role.options?.all_pages,
                        }))}
                        isMulti
                        isOptionSelected={(option) => option?.forceSelected}
                        placeholder="Select roles"
                        value={valueWithAllRoles}
                        onChange={(updatedRoles) => onChange(updatedRoles)}
                        autoFocus
                        menuIsOpen
                        isClearable={false}
                        isSearchable={false}
                        hideControl
                    />
                ) : (
                    <CheckboxDropdown
                        options={rolesWithAllRoles.map((role) => ({
                            value: role._sid,
                            label: role.label,
                            icon: role.options?.all_pages && 'lock',
                            tooltip: role.options?.all_pages && 'This role can see all pages',
                            isDisabled: role.options?.all_pages,
                            forceSelected: role.options?.all_pages,
                        }))}
                        isMulti
                        isOptionSelected={(option) => option?.forceSelected}
                        placeholder="Select roles"
                        value={valueWithAllRoles}
                        onChange={(updatedRoles) => onChange(updatedRoles)}
                        autoFocus
                        menuIsOpen
                        isClearable={false}
                        isSearchable={false}
                        hideControl
                        {...props}
                    />
                )}
            </div>
        </div>
    )
}

export function RolesSelectToggle(props) {
    const buttonContent = props.icon && (
        <Icon
            icon={props.icon}
            style={{ color: '#cccccc', fontSize: '16px', marginRight: '-4px' }}
        />
    )

    return (
        <DropdownButton
            buttonContent={buttonContent}
            hideOnScroll={false}
            variant="clear"
            buttonSize="sm"
            px={0}
            display="inline-flex"
            bodyPadding={0}
            style={{
                marginLeft: '-16px',
                marginRight: '-8px',
            }}
            place={null} // To prevent override of preferPlace.
            popoverProps={{ preferPlace: 'below' }}
            dropdownBoxStyle={{ width: '200px', position: 'relative', right: '90px' }}
        >
            <RolesSelect {...props} />
        </DropdownButton>
    )
}
