// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { queryClient, removeStackSpecificQueries } from 'data/hooks/_helpers'
import { useStackRoles } from 'data/hooks/roles'
import { useStacks } from 'data/hooks/stacks'
import {
    invalidateAppUserQuery,
    resetUserRecordQuery,
    useFetchAppUser,
} from 'data/hooks/users/main'
import { isPortalUser, isRightGranted } from 'features/auth/utils/roleUtils'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { isSupportLoginPermitted } from 'utils/supportLogin'
import useStableState from 'utils/useStableState'

import usePrevious from 'v2/ui/hooks/usePrevious'

import { useAuthContext } from './AuthContext/AuthContext'
import AppContext from './AppContext'
import { AppUserContext, AppUserContextValue, Rights } from './AppUserContext'
import { GlobalStaticState } from './GlobalStaticState'
/*
    This provider keeps track of the currently authenticated user and
    provides functions for checking roles and rights available to that user
    in the current stack.
*/
export const AppUserContextProvider: React.FC = ({ children }) => {
    const { selectedStack } = useContext(AppContext)
    const { previewingAsUser, previewingAsRoleApiName, isPreviewingAsUserOrRole } =
        usePreviewServiceContext()
    const isFirstRender = useRef(true)
    const { user: authedUser } = useAuthContext()

    const [authStateKey, setAuthStateKey] = useState(window.performance.now())

    // Load the current studio user object in context of the current selected stack.
    // The back-end will decorate it with some stack-specific context information
    // such as the user's corresponding data record id.
    const { isLoading: userIsLoading, data: appUser } = useFetchAppUser()

    const { data: roles, isLoading: rolesLoading } = useStackRoles()

    const { data: stacks } = useStacks()

    const effectiveAppUser = previewingAsUser || appUser || authedUser

    const userId = effectiveAppUser?._sid
    const previousUserId = usePrevious(userId)

    const role = useMemo(() => {
        const roleApiName = previewingAsRoleApiName || effectiveAppUser?.role
        const role = roles?.find((role) => role.api_name === roleApiName)
        GlobalStaticState.setAppUserRole(role)
        return role
    }, [effectiveAppUser?.role, previewingAsRoleApiName, roles])

    // Remove stack specific queries when the user id or stack id changes
    useEffect(() => {
        if (!isFirstRender.current) removeStackSpecificQueries()
    }, [selectedStack?._sid, userId, previewingAsRoleApiName])

    // When the stack changes, make sure we get the latest app user
    // record loaded from the server
    useEffect(() => {
        if (!isFirstRender.current) invalidateAppUserQuery()
    }, [selectedStack?._sid, userId])

    useEffect(() => {
        if (!isFirstRender.current) return resetUserRecordQuery()
    }, [selectedStack?._sid, userId, authedUser])

    useEffect(() => {
        // If the user logs out, remove all queries
        if (!userId && previousUserId) {
            queryClient.removeQueries()
        }
    }, [previousUserId, userId])

    // Returns whether or not the current user has the specified right
    // in the context of the current stack
    const hasRight = useCallback(
        (right) => {
            if (!selectedStack || !effectiveAppUser || !role || isPortalUser(effectiveAppUser))
                return false

            const rights = role.options?.rights || []

            return isRightGranted(rights, right)
        },
        [effectiveAppUser, role, selectedStack]
    )

    const isAdmin = !isPreviewingAsUserOrRole && hasRight(Rights.Admin.Any)
    const [, setSupportLoginPermitted] = useStableState('permitSupportLogin')

    useEffect(() => {
        if (authedUser) setSupportLoginPermitted(isSupportLoginPermitted(authedUser, stacks))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authedUser, stacks])

    // Sets (or clears) the currently selected "preview as" role.
    useEffect(() => {
        if (isFirstRender.current) return

        resetUserRecordQuery()
        invalidateAppUserQuery()

        // A unique key every time the auth state changes due to previewing.
        // This lets other components re-compose if appropriate.
        setAuthStateKey(window.performance.now())
    }, [userId])

    const contextState: AppUserContextValue = useMemo(() => {
        return {
            user: effectiveAppUser,
            role: role,
            isLoggedIn: Boolean(authedUser),
            isPreviewingAsUserOrRole,
            hasRight: hasRight,
            isLoading: userIsLoading || rolesLoading,
            isLoadingIncludingStack: userIsLoading || rolesLoading || !selectedStack,
            isAdmin,
            authStateKey,
        }
    }, [
        effectiveAppUser,
        role,
        authedUser,
        isPreviewingAsUserOrRole,
        hasRight,
        userIsLoading,
        rolesLoading,
        selectedStack,
        isAdmin,
        authStateKey,
    ])

    // So that we don't trigger invalidations the first time that the component is mounted
    useEffect(() => {
        isFirstRender.current = false
    }, [])

    return <AppUserContext.Provider value={contextState}>{children}</AppUserContext.Provider>
}
