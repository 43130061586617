import React, { useCallback } from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { DeleteTaskPayload } from 'data/hooks/tasks/types'

import { Icon, Text } from 'v2/ui'
import { useToast } from 'v2/ui/components/Toast'
import { DeleteData } from 'v2/ui/svgs'
import { Modal } from 'v2/ui/utils/useModalToggle'

import { useTaskListContext } from './TaskListContext'

export const useConfirmDeleteTaskModal = () => {
    const { show } = useConfirmModal()
    const { deleteTask } = useTaskListContext()

    const toast = useToast()

    return useCallback(
        (params: DeleteTaskPayload) => {
            show({
                title: 'Confirm Delete',
                confirmVariant: 'PrimaryError',
                icon: <Icon svg={<DeleteData />} size="56px" ml="15px" mb={4} />,
                message: (
                    <Text>
                        Are you sure you want to delete this task? This action cannot be undone.
                    </Text>
                ),
                onConfirm: async (modal: Modal<unknown>) => {
                    try {
                        await deleteTask(params)

                        toast({ title: 'Task deleted successfully.', status: 'success' })
                    } catch {
                        toast({
                            title: 'There was a problem deleting the task. Please try again later.',
                            status: 'error',
                        })
                    }

                    modal.setIsOpen(false)
                },
                onCancel: (modal: Modal<unknown>) => {
                    modal.setIsOpen(false)
                },
            })
        },
        [show, deleteTask, toast]
    )
}
