import React from 'react'

export type DropdownContextValue = {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

export const DropdownContext = React.createContext<DropdownContextValue | null>(null)

export function useDropdownContext() {
    return React.useContext(DropdownContext)
}

export type DropdownContextProviderProps = {
    children: React.ReactNode
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

export const DropdownContextProvider: React.FC<DropdownContextProviderProps> = ({
    children,
    isOpen,
    setIsOpen,
}) => {
    return (
        <DropdownContext.Provider
            value={{
                isOpen,
                setIsOpen,
            }}
        >
            {children}
        </DropdownContext.Provider>
    )
}
