import React, { forwardRef } from 'react'

import { stopPropagation } from 'ui/helpers/utilities'
import { useTheme } from 'ui/styling/themes/ThemeProvider'

import * as Parts from './Modal.parts'

type ModalContentRef = HTMLDivElement

type ModalContentProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Content>, 'asChild'> & {
    zIndex?: number
}

export const ModalContent = forwardRef<ModalContentRef, ModalContentProps>(
    ({ zIndex, ...props }, ref) => {
        const { themeClassName } = useTheme()

        return (
            <Parts.Portal>
                <Parts.Overlay
                    className={themeClassName}
                    onClick={stopPropagation}
                    style={{ zIndex }}
                >
                    <Parts.Content ref={ref} {...props} />
                </Parts.Overlay>
            </Parts.Portal>
        )
    }
)
