import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

type EmptyThumbnailProps = {
    effectiveSize: 's' | 'm' | 'manual'
    placeholder?: React.ReactNode
}

const sizeMapping = {
    s: 's',
    m: 'm',
}

const MAX_LENGTH_TITLE = 60
const MAX_LINES_TITLE = 2

export const EmptyThumbnailContent = ({ effectiveSize, placeholder }: EmptyThumbnailProps) => {
    const noImageIconSize = sizeMapping[effectiveSize]

    if (placeholder) {
        if (typeof placeholder === 'string') {
            return (
                <Body
                    weight="medium"
                    color="gray400"
                    maxLength={MAX_LENGTH_TITLE}
                    maxLines={MAX_LINES_TITLE}
                    textAlign="center"
                    px="4xl"
                >
                    {placeholder}
                </Body>
            )
        } else {
            return (
                <Box
                    color="gray400"
                    textAlign="center"
                    px="4xl"
                    fontWeight="bodyMedium"
                    fontSize="bodyM"
                    trim
                >
                    {placeholder}
                </Box>
            )
        }
    }
    return <Icon name="ImageOff" color="icon" size={noImageIconSize} />
}
