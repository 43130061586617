import { useCallback } from 'react'

import { useDropdownContentContext } from './DropdownContentContext'
import { isTargetEditable } from './utils'

export function useContentKeyDown() {
    const { isSearchManaged, searchInputElement, setSearchQuery } = useDropdownContentContext()

    return useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            const isElementEditable = isTargetEditable(e.target as HTMLElement)
            if (isElementEditable) return

            const isCharacterKey = e.key.length === 1
            const isBackspaceKey = e.key === 'Backspace'
            const isModifierKey = e.ctrlKey || e.altKey || e.metaKey

            if (isSearchManaged && (isCharacterKey || isBackspaceKey)) {
                const input = searchInputElement
                if (!input) return

                e.preventDefault()
                e.stopPropagation()

                input.focus()
                if (isCharacterKey && !isModifierKey) {
                    const key = e.key
                    setSearchQuery((prev) => prev + key)
                } else if (isBackspaceKey) {
                    setSearchQuery((prev) => prev.slice(0, -1))
                }
            }
        },
        [isSearchManaged, searchInputElement, setSearchQuery]
    )
}
