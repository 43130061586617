import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'

export function useUrlAttributeState() {
    const { attributeStyle } = useBoardViewContext()
    const isTagListAttributeStyle = attributeStyle === 'tags'

    return useMemo(
        () => ({
            isTagListAttributeStyle,
        }),
        [isTagListAttributeStyle]
    )
}
