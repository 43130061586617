export class ParsingError extends Error {}

export class UnexpectedFunctionError extends ParsingError {
    constructor(functionName: string) {
        super(`Unexpected formula function: ${functionName}`)
    }
}

export class UnexpectedArgumentsError extends ParsingError {
    constructor({
        functionName,
        expectedArgumentCount,
        argumentHint,
    }: {
        functionName: string
        expectedArgumentCount?: number | [number, number]
        argumentHint?: string
    }) {
        const getCustomMessage = () => {
            const expectedArgumentCountMessage = Array.isArray(expectedArgumentCount)
                ? `Between ${expectedArgumentCount[0]} and ${expectedArgumentCount[1]} arguments`
                : expectedArgumentCount === 1
                ? `Only one argument`
                : `Exactly ${expectedArgumentCount} arguments`

            return `${expectedArgumentCountMessage} expected in formula function ${functionName}${
                argumentHint ? `: ${argumentHint}` : '.'
            }`
        }

        super(
            expectedArgumentCount
                ? getCustomMessage()
                : `No arguments accepted in formula function: ${functionName}`
        )
    }
}
