import React, { useContext, useMemo } from 'react'

export type TableViewRowContextValue = {
    record?: RecordDto
}

export const TableViewRowContext = React.createContext<TableViewRowContextValue | null>(null)

export function useTableViewRowContext(): TableViewRowContextValue {
    const context = useContext(TableViewRowContext)

    if (!context) {
        throw new Error('useTableViewRowContext must be used within a TableViewRowContextProvider')
    }

    return context
}

export type TableViewRowContextProviderProps = {
    record?: RecordDto
}

export const TableViewRowContextProvider: React.FC<TableViewRowContextProviderProps> = ({
    children,
    record,
}) => {
    const value = useMemo(
        () => ({
            record,
        }),
        [record]
    )

    return <TableViewRowContext.Provider value={value}>{children}</TableViewRowContext.Provider>
}
