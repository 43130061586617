import { fetchWithAuth } from './fetchWithAuth'

/**
 *
 * @param {*} requestedSlug Requested URL slug by user
 * @param {*} zone Current account zone (null if called from the create form)
 * @returns
 */

export const validateSlug = async (
    requestedSlug: string,
    zone?: AccountZone | null
): Promise<{ isValid: true } | { isValid: false; error?: string }> => {
    const requestedSlugLower = requestedSlug.toLowerCase()

    // no changes
    if (zone?.slug?.toLowerCase() === requestedSlugLower) {
        return { isValid: true }
    }

    const url = `zone/check-slug?slug=${encodeURIComponent(requestedSlugLower)}`

    try {
        const resp = await fetchWithAuth(url)
        const { slug: uniqueSlug } = await resp.json()

        return requestedSlugLower === uniqueSlug
            ? { isValid: true }
            : { isValid: false, error: `Slug unavailable. Try "${uniqueSlug}".` }
    } catch {
        // Handle errors by returning invalid status
        return { isValid: false, error: 'An error occurred while validating the slug.' }
    }
}
