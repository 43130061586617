import React, { useContext } from 'react'

import { get } from 'lodash'

import useLDFlags from 'data/hooks/useLDFlags'
import { SideMenu } from 'features/core/SideMenu'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { useLayoutContextPortal } from 'features/utils/useLayoutContextPortal'
import { LayoutEditorControls } from 'features/views/LayoutEditor/controls/LayoutEditorControls'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'

import { Button } from 'v2/ui'

type EditLayoutSidePaneProps = {}
export const EditLayoutSidePane: React.VFC<EditLayoutSidePaneProps> = () => {
    const portalContext = useContext(LayoutEditorContext)

    const buttonLabel = get(portalContext, 'button.label')
    const buttonOnClick = get(portalContext, 'button.onClick')
    const isDirty = get(portalContext, 'button.isDirty')
    const setPortal = useLayoutContextPortal('sideMenu')

    const { flags } = useLDFlags()
    const { view } = useLayoutEditorContext()
    if (flags.turboDetailView && view?.type === 'detail') {
        return (
            <SlidingPaneFrame title="Edit layout" description="Edit what your app looks like.">
                <LayoutEditorControls />
            </SlidingPaneFrame>
        )
    }

    if (!portalContext) {
        return null
    }

    return (
        <SlidingPaneFrame title="Edit Layout" description="Edit what your app looks like.">
            <SideMenu
                width="100%"
                showButton={!!portalContext.button}
                id="sideMenu"
                ref={setPortal}
                inSidePane
                showOnRight
                style={{ position: 'relative', paddingBottom: 0, flexShrink: 1, minHeight: 0 }}
            />
            {portalContext?.button && (
                <Button
                    style={{
                        position: 'inherit',
                        width: 'calc(100% - 20px)',
                        height: '40px',
                        alignSelf: 'center',
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                    variant="sideMenu"
                    onClick={buttonOnClick}
                    disabled={!isDirty}
                    buttonSize="medium"
                    data-testid="menu-save-button"
                >
                    {buttonLabel}
                </Button>
            )}
        </SlidingPaneFrame>
    )
}
