import { cloneDeep } from 'lodash'

import type { TreeInstance } from 'features/pages/tree/tree'

export const LOCAL_STORAGE_BLOCK_KEY = 'clipboard'

export function copyBlockToLocalstorage(tree: TreeInstance, blockId: string) {
    // @ts-ignore
    const blockToCopy = tree.indexedBlocks?.[blockId]
    if (!blockToCopy) {
        return
    }

    const copy = JSON.stringify(blockToCopy)
    localStorage.setItem(LOCAL_STORAGE_BLOCK_KEY, copy)
}

export function getBlockFromLocalStorage() {
    const serialisedBlock = localStorage.getItem(LOCAL_STORAGE_BLOCK_KEY)
    if (!serialisedBlock) {
        return null
    }

    try {
        return JSON.parse(serialisedBlock) as Block
    } catch (error) {
        return null
    }
}

export function prepareBlockToAdd(payload: {
    id: string
    blockElement?: Block
    blockTemplate?: object
    defaultConfig?: Pick<Block, 'config'>
}) {
    let newBlock: Block | undefined = payload.blockElement
    const template = payload.blockTemplate || {}

    if (newBlock) {
        // If we pass in a blockelement, then we want to create a new block
        newBlock = {
            // @ts-ignore
            type: payload.id.replace('template_', ''),
            config: {
                // @ts-ignore
                attributes: {},
                style: {},
                broadcast: [],
            },
            childBlocks: [],
            ...template,
        }

        // @ts-ignore
        newBlock.config = {
            // @ts-ignore
            ...newBlock.config,
            ...cloneDeep(payload.defaultConfig),
        }
    }

    return newBlock
}

export function isFocusedElementFormElement() {
    const element = document.activeElement
    if (!element) {
        return false
    }

    if ((element as HTMLElement).isContentEditable) {
        return true
    }

    const listOfFormElements = ['INPUT', 'TEXTAREA']
    if (listOfFormElements.indexOf(element.nodeName) >= 0) {
        return true
    }

    return false
}
