import React, { useContext } from 'react'

import { useAppContext } from 'app/AppContext'
import { CreateFavoriteData, useFavorite } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useNavigation } from 'data/hooks/navigation'
import { PathContext } from 'features/utils/PathContext'

import { Icon } from 'ui/components/Icon'

type FavoriteButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Icon>, 'name'> & {
    targetType: FavoriteType
    stack_id?: string
    navigation_id?: string
    object_id?: string
    record_id?: string
    document_id?: number
    onCreateFavorite?: (data: CreateFavoriteData) => void
}
export function FavoriteButton({
    targetType,
    stack_id,
    navigation_id,
    object_id,
    record_id,
    document_id,
    onCreateFavorite,
    color: providedColor,
    style: providedStyle,
    ...props
}: FavoriteButtonProps) {
    const { favorite, toggleFavorite } = useFavorite({
        targetType,
        stackId: stack_id,
        navigationId: navigation_id,
        objectId: object_id,
        recordId: record_id,
        documentId: document_id,
        onCreateFavorite,
    })

    const color =
        (providedColor as React.ComponentPropsWithoutRef<typeof Icon>['color']) ?? 'textWeakest'

    return (
        <Icon
            name="Star"
            role="button"
            noShrink
            onClick={toggleFavorite}
            {...props}
            style={{
                fill: favorite ? 'currentColor' : 'transparent',
                ...providedStyle,
            }}
            color={color}
        />
    )
}

export function NavigationItemFavoriteButton(
    props: Partial<React.ComponentPropsWithoutRef<typeof FavoriteButton>>
) {
    const { selectedStack } = useAppContext()
    const pathContext = useContext(PathContext)
    const { data: navigation } = useNavigation()

    const currentItem = (navigation as NavigationDto[] | undefined)?.find(
        (n) => n.parent_id && n.url === pathContext.pageUrl
    )

    if (!currentItem) {
        return null
    }
    return (
        <FavoriteButton
            targetType={FavoriteType.NavigationItem}
            stack_id={selectedStack?._sid}
            navigation_id={currentItem._sid}
            onCreateFavorite={(data) => (data.navigationItem = currentItem)}
            {...props}
        />
    )
}
