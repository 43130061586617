import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

type UseBoardViewEyebrowStateOptions = {
    record: RecordDto
}

export function useBoardViewEyebrowState(options: UseBoardViewEyebrowStateOptions) {
    const { record } = options

    const { eyebrowField } = useBoardViewContext()

    const attribute: BoardViewAttribute | undefined = useMemo(() => {
        if (!eyebrowField) return undefined

        return {
            id: eyebrowField._sid,
            title: eyebrowField.label,
            field: eyebrowField,
            rawOptions: {},
            type: 'eyebrow',
        }
    }, [eyebrowField])

    const value = eyebrowField ? record[eyebrowField?.api_name] ?? undefined : undefined

    return useMemo(
        () => ({
            attribute,
            value,
        }),
        [attribute, value]
    )
}
