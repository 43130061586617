/* Code Quality: Not audited */

import React, { useCallback } from 'react'

import { Box } from '@chakra-ui/react'

import { useObject } from 'data/hooks/objects'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { getDocumentFields } from 'utils/fieldUtils'

import { Dropdown, Flex, Text } from 'v2/ui'

const DocumentFieldConfig = ({ ...props }) => {
    const { object } = useObject(props.objectId)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)
    const onChange = useCallback(
        (id) => props.onChange({ ...props.value, id }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onChange, props.value]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = props.value || {}

    let { fields } = object
    fields = getDocumentFields(sortByFieldsOrder(fields))

    return (
        <Flex>
            <Box flex={1}>
                <Box my={4}>
                    <label>
                        <Text variant="paletteSectionLabel">
                            {props.label || 'Document source'}
                        </Text>
                        <Dropdown
                            adminTheme
                            options={fields.map((field) => ({
                                value: field.api_name,
                                label: field.label,
                            }))}
                            value={value.id}
                            onChange={onChange}
                        />
                    </label>
                </Box>
            </Box>
        </Flex>
    )
}

export default DocumentFieldConfig
