import React from 'react'

import { Box } from 'ui/components/Box'

import { useCardViewGridState } from './hooks/useCardViewGridState'
import { useCardViewPaginationContext } from './Pagination/CardViewPaginationContext'
import * as Parts from './CardView.parts'

import { CardViewStyle } from './CardView.css'

type CardViewGridProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const CardViewGrid: React.FC<CardViewGridProps> = React.memo(function CardViewGrid({
    ...props
}) {
    const { cardSize } = useCardViewGridState()
    const { setContainerRef } = useCardViewPaginationContext()

    return (
        <div className={CardViewStyle}>
            <Parts.CardGrid
                ref={setContainerRef}
                cardSize={cardSize}
                display="grid"
                height="full"
                width="full"
                gap="xl"
                px={{ mobile: 'xl', tablet: 0 }}
                {...props}
            />
        </div>
    )
})
