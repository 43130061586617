import React, { FC, useCallback, useMemo } from 'react'

import { useTheme } from '@chakra-ui/react'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

import { useAppContext } from 'app/AppContext'
import { renderNumberValueAsText } from 'features/charts/NumberValue'
import { CategoryLabel } from 'features/charts/recharts/common/CategoryLabel'
import useTickFormatter from 'features/charts/recharts/hooks/useTickFormatter'
import { getColoredCategories } from 'features/charts/recharts/utils/getColoredCategories'
import { getTooltipLabel } from 'features/charts/recharts/utils/getTooltipLabel'

import { prepareData } from './utils/prepareData'

type RechartsLineProps = {
    metrics: MetricsQueryResponse
    config: ChartParamsConfig
    display: ChartParamsDisplay
    width?: number
    height?: number
    chartObject?: ObjectDto
    options: {
        maxItems?: number
        showStacked?: boolean
        showLabels: boolean
        showValues: boolean
    }
}

export const RechartsLine: FC<RechartsLineProps> = ({
    metrics,
    display,
    config,
    width,
    height,
    chartObject,
    options: { showLabels, showValues },
}) => {
    const { selectedStack } = useAppContext()
    const brandColor = selectedStack?.options?.theme?.brandColor
    const theme = useTheme()

    const data = useMemo(
        () => prepareData(metrics.data, config?.group?.bucketBy, config.limit),
        [metrics.data, config?.group?.bucketBy, config.limit]
    )

    const ticks = useMemo(() => data.map(({ name }) => name), [data])

    const groupByField = useMemo(
        () => chartObject?.fields?.find(({ _sid }) => _sid === config.group.group_field_sid),
        [chartObject?.fields, config.group.group_field_sid]
    )

    const coloredCategories = useMemo(
        () => getColoredCategories(metrics.categories, groupByField, theme),
        [metrics.categories, groupByField, theme]
    )

    const {
        renderKey,
        xTickWidth,
        xTickFormatter,
        xTicksRotated,
        onRotateXTicks,
        yTickWidth,
        yTickFormatter,
    } = useTickFormatter({
        metrics,
        display,
        config,
        chartWidth: width,
        numDataPoints: data.length,
        maxItems: config.limit,
    })

    const formatter = useCallback(
        (value: string, name: string, payload: any): [string, string] => {
            const label = getTooltipLabel(name, payload, config?.group?.bucketBy)
            const formattedValue = Number.isNaN(value)
                ? value
                : renderNumberValueAsText({
                      value: Number(value),
                      display,
                      config,
                      metrics,
                  })
            return [formattedValue, label]
        },
        [config, display, metrics]
    )

    const domain = useCallback(([dataMin, dataMax]: [number, number]): [number, number] => {
        const min = Math.min(dataMin, 0)
        return [min, dataMax]
    }, [])

    return (
        <LineChart key={renderKey} data={data} width={width} height={height}>
            <CartesianGrid horizontal={true} vertical={false} strokeDasharray="2 2" />
            <XAxis
                dataKey="name"
                type="category"
                width={xTickWidth}
                axisLine={false}
                tickLine={false}
                tickFormatter={xTickFormatter}
                ticks={ticks}
                tick={({ index, ...props }) => (
                    <CategoryLabel
                        {...props}
                        barWidth={xTickWidth}
                        chartHeight={height}
                        rotateLabel={xTicksRotated}
                        isLastLabel={index === ticks.length - 1}
                        onLabelRotated={onRotateXTicks}
                    />
                )}
                interval="preserveStartEnd"
                hide={!showLabels}
            />
            <YAxis
                type="number"
                width={yTickWidth}
                axisLine={false}
                tickLine={false}
                tick={{ fill: theme.colors.userInterface.neutral[700] }}
                tickFormatter={yTickFormatter}
                allowDecimals={config.aggr.type !== 'count'}
                tickCount={10}
                domain={domain}
                interval="preserveStartEnd"
                hide={!showValues}
            />
            {!config.group.group_field_sid && (
                <Line
                    isAnimationActive={false}
                    animationDuration={500}
                    type="monotone"
                    dataKey="value"
                    stroke={brandColor}
                    strokeWidth={2}
                    dot={false}
                />
            )}
            {config.group.group_field_sid &&
                coloredCategories.map((category) => (
                    <Line
                        isAnimationActive={false}
                        animationDuration={500}
                        name={category.category}
                        key={category.category}
                        type="monotone"
                        strokeWidth={2}
                        dataKey={(point: any) =>
                            category.category in point ? point[category.category] : 0
                        }
                        stroke={category.color}
                        dot={false}
                    />
                ))}
            <Tooltip isAnimationActive={false} formatter={formatter} />
        </LineChart>
    )
}
