import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import Linkify from 'react-linkify'

import { JSONContent } from '@tiptap/core/dist/packages/core/src/types'
import { SerializedEditorState } from 'lexical'

import { useAppContext } from 'app/AppContext'
import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'
import { useTipTapDocumentContent } from 'features/tiptap/useTipTapDocumentContent'

import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import DisplayText from './DisplayText'

export type TipTapValue = {
    content: JSONContent | null
    format: 'tiptap'
    plainTextContent: string
}

type DocumentAttributeProps = {
    field: FieldDto
    onChange?: (value: TipTapValue | null) => void
    singleLine?: boolean
    renderOptions?: {
        retainPadding?: boolean
        documentControls?: React.ReactNode
        autoFocus?: boolean
    }
    mode?: typeof modes[keyof typeof modes]
    layout?: typeof layouts[keyof typeof layouts]
    children: TipTapValue | SerializedEditorState
    recordId?: string
    className?: string
    onKeyDown?: (e: React.KeyboardEvent) => void
}

const DocumentAttribute = forwardRef<TipTapEditorHandle, DocumentAttributeProps>(
    (
        {
            mode,
            singleLine,
            onChange,
            children,
            recordId,
            field,
            renderOptions = {},
            className,
            onKeyDown,
        },
        ref
    ) => {
        const { autoFocus = false } = renderOptions
        const format = children ? (children as TipTapValue).format : 'tiptap'

        const [editValue, setEditValue] = useState(
            format === 'tiptap'
                ? (children as TipTapValue)?.content
                : (children as SerializedEditorState)
        )

        useEffect(() => {
            setEditValue(
                format === 'tiptap'
                    ? (children as TipTapValue)?.content
                    : (children as SerializedEditorState)
            )
        }, [children, format])

        const isEditing = mode == modes.editing
        const handleChange = useCallback(
            (content: JSONContent, plainTextContent: string) => {
                const attrValue: TipTapValue | null =
                    // If the field is empty, set it back to null
                    plainTextContent == ''
                        ? null
                        : {
                              format: 'tiptap',
                              content,
                              plainTextContent,
                          }

                onChange?.(attrValue)
            },
            [onChange]
        )

        const tipTapContent = useTipTapDocumentContent({
            content: editValue,
            format,
        })

        const { selectedStack } = useAppContext()

        if (singleLine) {
            if (!children) return null

            return (
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            target="_blank"
                            href={decoratedHref}
                            key={key}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {decoratedText}
                        </a>
                    )}
                >
                    <DisplayText singleLine={true} whiteSpace="inherit">
                        {(children as TipTapValue).plainTextContent}
                    </DisplayText>
                </Linkify>
            )
        }

        return (
            <TipTapDocumentEditor
                ref={ref}
                content={tipTapContent}
                onChange={handleChange}
                readOnly={!isEditing}
                stackId={selectedStack?.id}
                placeholder="Press '/' for commands or just start typing..."
                width="full"
                border={isEditing}
                px={isEditing ? 'l' : undefined}
                recordId={recordId}
                fieldId={field?._sid}
                allowComments={false}
                documentControls={renderOptions?.documentControls}
                className={className}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                linkOptions={{
                    openOnClick: false,
                }}
            />
        )
    }
)

export default memo(DocumentAttribute)
