import { useMemo } from 'react'

import { useAppContext } from 'app/AppContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { Urls } from 'app/UrlService'
import {
    buildQueryKey,
    queryClient,
    useBatchUpdate,
    useCanRunStackScopedAdminQueries,
    useCanRunWorkspaceScopedQueries,
    useCreateItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { invalidateAccounts } from 'data/hooks/accounts'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'
import { getCurrentUserRecordId } from 'data/wrappers/withUserUtils'

const ENDPOINT = 'users/'
const GLOBAL_USER_ENDPOINT = 'global-users/'
const USER_PROFILES_RECORDS_ENDPOINT = 'user-profiles/'
const WORKSPACE_USERS_KEY = 'workspace_users&workspace=1'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useAppUsers(options = {}, internalOptions = {}) {
    const { selectedStack } = useAppContext()
    const workspaceQueriesEnabled = useCanRunWorkspaceScopedQueries()
    const queryKey = useQueryKeyBuilder(
        ['useAppUsers', internalOptions?.stackId || selectedStack?._sid],
        {
            includeAuthKeys: true,
        }
    )
    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })

    const urlParams = new URLSearchParams()
    if (internalOptions.paginate) {
        urlParams.set('paginate', 'true')
        if (typeof internalOptions.pageSize !== 'undefined') {
            urlParams.set('limit', internalOptions.pageSize.toString())
        }
        if (typeof internalOptions.pageIndex !== 'undefined') {
            urlParams.set('offset', internalOptions.pageIndex.toString())
        }
    }

    if (internalOptions.searchQuery) {
        urlParams.set('search', internalOptions.searchQuery)
    }

    if (internalOptions.grantType) {
        urlParams.set('grant_type', internalOptions.grantType)
    }
    if (internalOptions.grantSid) {
        urlParams.set('grant_sid', internalOptions.grantSid)
    }

    const url = !!urlParams.size ? `list-app-users/?${urlParams.toString()}` : 'list-app-users/'

    const results = useQuery(
        queryKey,
        url,
        {
            ...options,
            enabled: (!!internalOptions?.stackId || !!selectedStack) && workspaceQueriesEnabled,
        },
        {
            ...internalOptions,
        }
    )

    return useMemo(() => {
        const refetch = async (options) => {
            const result = await results.refetch(options)

            return { ...result, data: result.data?.users, totalUserCount: result.data?.count }
        }

        return {
            ...results,
            data: results.data?.users,
            totalUserCount: results.data?.count,
            refetch,
        }
    }, [results])
}

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useWorkspaceUsers(options = {}) {
    const enabled = useCanRunWorkspaceScopedQueries()
    return useQuery(
        WORKSPACE_USERS_KEY,
        'list-account-users/',
        { ...options, enabled },
        {
            bypassMatchingStackCheck: true,
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        }
    )
}

export function useAddWorkspaceUser() {
    return useCreateItem(
        WORKSPACE_USERS_KEY,
        'account/add-user/',
        {
            onSuccess: () => {
                invalidateAccounts()
            },
        },
        {
            disableOptimisticUpdates: true,
        }
    )
}

export function useGlobalUser() {
    const { user } = useAuthContext()
    const queryKey = useQueryKeyBuilder('useGlobalUser', {
        includeAuthKeys: true,
    })

    // need the user Id in the deps, as sometimes when we first run this hook we haven't
    // got a user yet
    const result = useQuery(queryKey, `${GLOBAL_USER_ENDPOINT}${user?._sid}/`, {
        enabled: !!user,
    })

    if (!user) {
        return { data: null, isLoading: false }
    }

    return result
}
/**
 * Fetches the latest version of the logged in Stacker User's info
 * @param {import('react-query').UseQueryOptions } options
 */
export function useFetchAppUser(options = {}) {
    const enabled = useCanRunStackScopedAdminQueries()
    const { user } = useAuthContext()
    const userId = user?._sid
    const queryKey = useQueryKeyBuilder('useFetchAppUser', {
        includeAuthKeys: true,
        includeStackId: true,
    })

    const extraOptions = {
        onSuccess: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                if (options?.onSuccess) {
                    options.onSuccess(user)
                }
            }
        },
    }

    const internalOptions = {
        disabledValue: null,
        bypassMatchingStackCheck: true,
    }

    // need the user Id in the deps, as sometimes when we first run this hook we haven't
    // got a user yet
    return useQuery(
        queryKey,
        `${ENDPOINT}${userId}/`,
        {
            ...options,
            ...extraOptions,
            retry: (_, error) => {
                if (error?.detail?.includes('Not found')) {
                    // Force logging out the current user
                    window.location.href = Urls.Logout
                }
                return false
            },
            enabled,
        },
        internalOptions
    )
}

/**
 * Invalidates the useFetchAppUser and useUserRecord queries
 */
export const refetchWorkspaceUserList = () => {
    return queryClient.refetchQueries(WORKSPACE_USERS_KEY)
}
export const invalidateWorkspaceUsersList = () => {
    queryClient.invalidateQueries(WORKSPACE_USERS_KEY)
}

export const refetchAppUsersForAdmin = () => {
    return queryClient
        .refetchQueries(
            buildQueryKey('useAppUsersForAdmin', {
                includeStackId: true,
            })
        )
        .then(() => {
            queryClient.invalidateQueries(
                buildQueryKey('useAppUsers', {
                    includeAuthKeys: true,
                    includeStackId: true,
                })
            )
        })
}

export const invalidateAppUserQuery = () => {
    queryClient.invalidateQueries(
        buildQueryKey('useFetchAppUser', {
            includeAuthKeys: true,
            includeStackId: true,
        })
    )
}
export const resetUserRecordQuery = () => {
    queryClient.invalidateQueries(
        buildQueryKey('useUserRecord', {
            includeAuthKeys: true,
            includeStackId: true,
        })
    )
}
/**
 * Fetches the current user's record (their record from the data context, not their stacker user record)
 * @param {import('react-query').UseQueryOptions} [options]
 */
export function useUserRecord(options) {
    const userId = getCurrentUserRecordId()
    const { selectedStack } = useAppContext()
    const { isFetching } = useFetchAppUser()

    const internalOptions = {
        onSuccess: (result) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (result) {
                if (options?.onSuccess) {
                    options.onSuccess(result)
                }
            }
        },
        // If we don't pass anything, assume that the query is enabled
        enabled: !!(
            (options?.enabled === undefined ? true : options?.enabled) &&
            userId &&
            !isFetching
        ),
    }

    const queryKey = useQueryKeyBuilder('useUserRecord', {
        includeAuthKeys: true,
        includeStackId: true,
    })

    // listen for updates to the user object and invalidate the app
    // user lists when it changes
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [selectedStack?.options?.data_mapping?.user_object],
        handler: () => queryClient.invalidateQueries(queryKey),
    })

    // NOTE: can't guarantee that we have perms to read this record,
    // so ignore 40x errors
    return useQuery(
        queryKey,
        `${USER_PROFILES_RECORDS_ENDPOINT}${userId}/`,
        { ...options, ...internalOptions, keepPreviousData: true },
        { ignore40x: true }
    )
}

export function useUpdateUser(options) {
    const { setUser } = useAuthContext()
    return useUpdateItem('users', ENDPOINT, {
        onSuccess: (user) => {
            // Send the info through via redux dispatch to update
            // the redux store as well as local storage
            if (user) {
                setUser(user)
            }
            if (options?.onSuccess) {
                options.onSuccess(user)
            }
        },
    })
}

export function useUpdateAccountUser() {
    return useBatchUpdate(
        WORKSPACE_USERS_KEY,
        'account/update-users',
        (x, y) => x._sid === y._sid,
        {
            onSuccess: () => {
                invalidateAccounts()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        }
    )
}
