import React, { VFC } from 'react'
import { useWatch } from 'react-hook-form'

import { useAppContext } from 'app/AppContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { useObject } from 'data/hooks/objects'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { FormField } from 'ui/deprecated/forms/FormField'

import { getCanChooseTableForLink } from './logic/availableFieldOperationUtils'
import { ConfigurationComponentProps } from './common'
import FiltersConfiguration from './FiltersConfiguration'
import { ConfigurationField } from './ui'

const RecordLinkConfiguration: VFC<ConfigurationComponentProps> = ({ object, field }) => {
    const targetObjectId = useWatch({ name: 'link_target_object_id' })
    const { selectedStack, workspaceAccount: account } = useAppContext()

    const { object: targetObject } = useObject(targetObjectId)
    const { data: dataConnection } = useDataConnection(object?.data_connection)

    const isDisabled =
        selectedStack && account
            ? !getCanChooseTableForLink({
                  dataConnection: dataConnection ?? undefined,
                  field,
              })
            : true

    return (
        <>
            <FormField
                name="link_target_object_id"
                as={ObjectPicker}
                errorMessages={{ required: 'Select a table to link to' }}
                variant="settings"
                placeholder="Select a table to link to"
                disabled={isDisabled}
                controlled
                required
                usePortal={false}
            />
            <ConfigurationField
                show={!!targetObjectId}
                as={FiltersConfiguration}
                name="connection_options.relationship_target_lookup_filters"
                object={targetObject}
                contextRecordObject={object}
                field={field}
                controlled
                required={false}
                checkboxLabel="Limit the records which can be selected"
            />
        </>
    )
}

export default RecordLinkConfiguration
