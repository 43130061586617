import React, { useCallback, useState } from 'react'

import { RecordContextMenu } from 'v2/views/List/RecordContextMenu'

import useLDFlags from 'data/hooks/useLDFlags'
import { useMemo } from 'utils/debugUtils'

export function ListViewRecordContextMenuWrapper({
    records,
    object,
    viewOptions,
    children,
    pendingRecordIds,
}: {
    records: RecordDto[]
    object: ObjectDto
    viewOptions: ListViewOptions
    children: (params: { onChildPointerDown: (e: React.MouseEvent) => void }) => React.ReactNode
    pendingRecordIds?: string[]
}) {
    const fieldIds = useMemo(() => {
        const selectedColumns = viewOptions.columns.map((x) => x.fieldId)
        const inlineFilters = viewOptions.enableSpecificEndUserFilters
            ? viewOptions.specificEndUserFilters ?? []
            : []
        return [...selectedColumns, ...inlineFilters]
    }, [
        viewOptions.columns,
        viewOptions.enableSpecificEndUserFilters,
        viewOptions.specificEndUserFilters,
    ])

    const [contextMenuRecords, setContextMenuRecords] = useState<RecordDto[]>([])

    const [isTriggerDisabled, setIsTriggerDisabled] = useState(false)

    const handlePreviewContextMenu = useCallback(
        (e) => {
            const recordId = e.target?.closest('[data-recordid]')?.dataset?.recordid
            const filteredRecords = recordId ? records.filter((x) => x._sid === recordId) : []
            setContextMenuRecords(filteredRecords)
        },
        [records]
    )

    const onChildPointerDown = (e: React.MouseEvent) => {
        const element = e.target as HTMLElement

        if (element.tagName === 'A' && Boolean(element.getAttribute('href'))) {
            setIsTriggerDisabled(true)
        } else {
            setIsTriggerDisabled(false)
        }
    }

    const isLinkable = useMemo(() => {
        const pendingRecordIdsSet = new Set(pendingRecordIds)

        return contextMenuRecords.every((record) => !pendingRecordIdsSet.has(record._sid))
    }, [contextMenuRecords, pendingRecordIds])

    const { flags } = useLDFlags()

    return (
        <RecordContextMenu
            disabled={contextMenuRecords.length < 1}
            isTriggerDisabled={!flags.contextMenu || isTriggerDisabled}
            records={contextMenuRecords}
            object={object}
            fieldIds={fieldIds}
            isLinkable={isLinkable}
            onTriggerContextMenu={handlePreviewContextMenu}
        >
            {children({ onChildPointerDown })}
        </RecordContextMenu>
    )
}
