import React, { memo, useCallback, useContext, useEffect } from 'react'
import useScrollbarSize from 'react-scrollbar-size'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useAppContext } from 'app/AppContext'
import AppUsercontext from 'app/AppUserContext'
import { catchComponentErrors } from 'app/ErrorBoundaries'
import MetadataLoading from 'app/MetadataLoading'
import { withUser } from 'data/wrappers/WithUser'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useIsEditingBlankPages } from 'features/blank-pages/useIsEditingBlankPages'
import { DefaultHeaderMeta } from 'features/core/DefaultHeaderMeta'
import HeroContainer from 'features/core/HeroContainer'
import { HEADER_HEIGHT, MobileNavigation } from 'features/core/nav/MobileNavigation'
import { useNavigationPosition } from 'features/core/nav/useNavigationPosition'
import { PoweredBy } from 'features/core/PoweredBy'
import SidebarsContainer from 'features/NewAppBar/SidebarsContainer'
import { HeaderContextProvider } from 'features/utils/HeaderContext'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { NavContext } from 'features/utils/NavContext'
import { SIDE_PANE_DEFAULT_WIDTH } from 'features/workspace/AdminSideTray/constants'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import SidebarWorkspace from 'features/workspace/Sidebar'

import { Box, LoadingSplash } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDimension from 'v2/ui/utils/useDimension'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { BANNER_HEIGHT } from './appBannerConstants'
import AppBannerService from './AppBannerService'
import { EDIT_MODE_GUTTER, WORKSPACE_BAR_WIDTH } from './frameConstants'
import { getLeftOffset } from './getLeftOffset'
import { useIsBannerShowing } from './useIsBannerShowing'
import { useWorkspaceBarEnabled } from './useWorkspaceBarEnabled'

export const FrameContext = React.createContext()

export const _Frame = ({
    children,
    context,
    isEditing,
    width,
    page,
    view,
    background,
    ignoreLoadingState,
    paddingOverride,
    hidePoweredBy,
    hideSideNav,
    overrideShowBreadcrumbs,
}) => {
    const { workspaceAccount } = useAppContext()
    const { setContextInfo: setSlidingPaneContextInfo } = useSlidingPane()
    const isMobile = useIsMobile()
    const workspaceBarEnabled = useWorkspaceBarEnabled()
    const navPosition = useNavigationPosition()
    const sideNavV4 = navPosition === 'left'

    const showSideNav = sideNavV4 && !isMobile && !hideSideNav
    const [bannerLeftOffset, setBannerLeftOffset] = React.useState(0)

    const [isBannerShowing] = useIsBannerShowing()

    useEffect(() => {
        setSlidingPaneContextInfo({
            showSideNav,
            isSecondaryHeaderVisible: false,
        })
    }, [showSideNav, setSlidingPaneContextInfo])

    const frameContext = React.useMemo(() => ({}), [])
    const isInbox = view?.options?.display === 'inbox' && !isMobile
    const { sidebarState, mobileDrawerState } = useContext(NavContext)
    const { isOpen: isDrawerOpen, onClose: closeDrawer } = mobileDrawerState

    const { selectedStack } = useAppContext()
    const showBreadcrumbs =
        overrideShowBreadcrumbs ?? selectedStack?.options?.showBreadcrumbs ?? false
    const { isLoading, authStateKey } = useContext(AppUsercontext)

    const [backdropWidth, setBackdropWidth] = React.useState()

    const defaultWidth = '100%'
    const contentWidth = width || defaultWidth

    const padding = paddingOverride || isInbox ? '0' : '0px 10px 50px'

    const isEditingBlankPages = useIsEditingBlankPages()
    const portalContext = useContext(LayoutEditorContext)

    //close active portal if closing editor
    if (!isEditing && !isEditingBlankPages && portalContext.activeEditor) {
        portalContext.closeEditor()
    }

    // Close the active editor on unmount
    useEffect(() => {
        return () => portalContext.closeEditor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sidebarState === 'open') {
            setBannerLeftOffset(WORKSPACE_BAR_WIDTH)
        } else {
            setBannerLeftOffset(0)
        }
    }, [sidebarState])

    return (
        <FrameContext.Provider value={frameContext}>
            <MetadataLoading onlyShowOnInitialLoad={false}>
                <Backdrop
                    showSidebar={isEditing}
                    showAppBar={!isMobile && workspaceBarEnabled && sidebarState === 'open'}
                    showSideNav={showSideNav && sidebarState !== 'fullyCollapsed'}
                    background={background}
                    isMobile={isMobile}
                    setBackdropWidth={setBackdropWidth}
                    paddingTop={isMobile ? `${HEADER_HEIGHT}px` : 0}
                >
                    <DefaultHeaderMeta page={page} view={view}></DefaultHeaderMeta>

                    <SideNavBar
                        context={context}
                        showAppBar={!isMobile && workspaceBarEnabled}
                        showSideNav={showSideNav}
                    />

                    {isMobile && <MobileNavigation />}

                    {/* All app banners */}
                    {workspaceAccount && (
                        <AppBannerService leftOffset={bannerLeftOffset} showingAppBar={!isMobile} />
                    )}

                    {/* if we're in a workspace, and we're previewing as a user or role
                                    then we need to insert some space at the top of the page since
                                    we have a fixed banner above. */}
                    {workspaceAccount && isBannerShowing && (
                        <div style={{ height: BANNER_HEIGHT }} />
                    )}
                    {isMobile && (
                        <SideNavDrawer
                            isOpen={isDrawerOpen}
                            showAppBar={!isMobile}
                            onClose={closeDrawer}
                            showSideNav={sideNavV4 && !isLoading}
                        />
                    )}
                    {isLoading && !ignoreLoadingState ? (
                        <LoadingSplash />
                    ) : (
                        <>
                            <HeaderContextProvider>
                                <HeroContainer
                                    hide={isInbox}
                                    backdropWidth={backdropWidth}
                                    showBreadcrumbs={showBreadcrumbs}
                                />

                                {/* we want to completely recreate the components below this level if our auth state changes
                                            (ie., impersonation/preview status changes): so we key this div with the authStateKey provided
                                            from AppUserContext */}
                                <div
                                    className={STYLE_CLASSES.CONTENT}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flex: '1 0 auto',
                                        padding: padding,
                                        overflow: 'hidden',
                                    }}
                                    key={authStateKey}
                                >
                                    <div
                                        style={{
                                            padding: padding,
                                            width: contentWidth,
                                            maxWidth: '100%',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    >
                                        {catchComponentErrors(children)}
                                    </div>
                                </div>
                            </HeaderContextProvider>
                        </>
                    )}
                    {!isInbox && !hidePoweredBy && <PoweredBy />}
                </Backdrop>
            </MetadataLoading>
        </FrameContext.Provider>
    )
}

_Frame.propTypes = {
    children: PropTypes.node,
    context: PropTypes.object,
}
_Frame.defaultProps = {
    children: null,
    context: {},
}

export const Frame = withUser(_Frame)

const BackdropStyled = styled('div')`
    background-color: ${(props) =>
        props.background ? props.background : props.theme.backgroundColor};
    width: ${(props) =>
        props.leftMargin || props.rightMargin
            ? `calc(100% - ${props.leftMargin || '0px'} - ${props.rightMargin || '0px'})`
            : '100%'};
    height: min-content;
    min-height: 100dvh;
    margin-left: ${(props) => props.leftMargin};
    margin-right: ${(props) => props.rightMargin};
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding-right: ${(props) => (props.rightPadded ? `${EDIT_MODE_GUTTER}px` : 0)};
    transition: margin-left 400ms;
    padding-top: ${(props) => props.paddingTop};
`
const Backdrop = ({
    showSidebar,
    showAppBar,
    showSideNav,
    background,
    children,
    setBackdropWidth,
    shouldAppBarOverlay,
    paddingTop,
}) => {
    const {
        state: { animationComplete },
    } = useSlidingPane()

    // Use the non-admin call here as the admin call has a delay before it returns true
    // (waiting on roles to load, etc) and all of the conditions below just need to know if
    // we're in an SB app, period.
    const { width } = useScrollbarSize()
    const { isOpen } = useEditMode()
    const backdropRef = React.useRef()
    const { width: backdropWidth } = useDimension(backdropRef)

    useEffect(() => {
        setBackdropWidth(backdropWidth)
    }, [backdropWidth, setBackdropWidth])

    const showRightOffsetForEditLayout = showSidebar && animationComplete
    const showRightOffset = showRightOffsetForEditLayout
    const rightOffset = SIDE_PANE_DEFAULT_WIDTH

    return (
        <BackdropStyled
            className="BackdropStyled"
            rightPadded={isOpen && showSideNav}
            leftMargin={
                shouldAppBarOverlay ? 0 : `${getLeftOffset(showAppBar, showSideNav, false)}px`
            }
            rightMargin={
                showRightOffset
                    ? // subtract scrollbar width and an extra 10px to account for the
                      // rounded corners of the side pane
                      // and another 30px to fully overlap the page
                      `${rightOffset - width - 10 - 30}px`
                    : '0px'
            }
            background={background}
            ref={backdropRef}
            paddingTop={paddingTop}
        >
            {children}
        </BackdropStyled>
    )
}

const SideNavBar = memo(function SideNavBar({ showAppBar, showSideNav }) {
    const width = getLeftOffset(showAppBar, showSideNav)
    return (
        <SideNavBarContainer
            className={width > 0 && (showAppBar || showSideNav) ? 'expanded' : 'closed'}
            left={0}
            align="stretch"
            position="fixed"
            contentWidth={width}
            height="100%"
            zIndex={99}
            pointerEvents="none"
        >
            <SidebarsContainer showSideNav={showSideNav} />
        </SideNavBarContainer>
    )
})

const SideNavDrawer = React.memo(({ isOpen, onClose, showSideNav, showAppBar }) => {
    const width = getLeftOffset(showAppBar, showSideNav)
    const onClickNavItem = useCallback(
        ({ isFolder }) => {
            if (!isFolder) onClose()
        },
        [onClose]
    )

    return (
        <Drawer key="drawer" placement="left" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent maxWidth={width}>
                <SidebarWorkspace showSideNav={showSideNav} onClickNavItem={onClickNavItem} />
            </DrawerContent>
        </Drawer>
    )
})

const SideNavBarContainer = styled(Box)`
    &.closed {
        width: 0;
        overflow: hidden;
    }

    &.expanded {
        width: ${(p) => p.contentWidth}px;
        z-index: 200;
    }

    transition: width 400ms;
`
