import React from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { unpublishSharedTable } from 'data/hooks/objects/objectOperations'
import { Divider, ExpandSection, Section } from 'legacy/v1/ui'

import { Button, Icon as V2Icon, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { DeleteTable } from 'v2/ui/svgs'

import { Icon } from 'ui/components/Icon'

type UnShareTablesSettingsProps = {
    object: ObjectDto
    openErrorToast: (operation?: 'update' | 'delete' | 'unshare') => void
}

export const UnShareTablesSettings: React.FC<UnShareTablesSettingsProps> = ({
    object,
    openErrorToast,
}) => {
    return (
        <>
            <Divider />
            <ExpandSection
                heading="Unshare table"
                text="Unshare this table."
                helpUrl=""
                helpText=""
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DELETE_OBJECT,
                }}
            >
                <Section noPadding noMargin>
                    <UnShareTableButton
                        object={object}
                        onUnshareObject={async (sid: string) => {
                            unpublishSharedTable(
                                sid,
                                () => undefined,
                                () => openErrorToast('unshare')
                            )
                        }}
                    />
                </Section>
            </ExpandSection>
        </>
    )
}

type UnShareTableButtonProps = {
    object: ObjectDto
    onUnshareObject: (objectSid: string) => Promise<unknown>
}

export const UnShareTableButton: React.FC<UnShareTableButtonProps> = ({
    object,
    onUnshareObject,
}) => {
    const unShareModalData = {
        icon: <V2Icon svg={<DeleteTable />} size="56px" ml="15px" mb={4} />,
        confirmVariant: 'PrimaryError',
        message: (
            <>
                <Text fontWeight="bold">Unshare {object?.name}?</Text>

                <Text mt={4}>Are you sure you want to unshare this table?</Text>

                <Text mt={4}>
                    This table should be removed from any apps importing it before it can be
                    unshared.
                </Text>
            </>
        ),
    }
    const { show: showConfirm, setData } = useConfirmModal({
        ...unShareModalData,
        onConfirm: (modal: any) => {
            setData({ ...unShareModalData, isSaving: true })
            onUnshareObject(object?._sid).then(() => {
                setData({ ...unShareModalData, isSaving: false })
                modal.toggle()
            })
        },
    })

    if (!object?.is_shared_original) {
        return null
    }

    return (
        <div>
            <Button
                icon={<Icon name="Unlock" size="m" mr="xs" />}
                onClick={showConfirm}
                variant="Primary"
                buttonSize="sm"
            >
                Unshare table
            </Button>
        </div>
    )
}
