import isEqual from 'lodash/isEqual'
import memoize from 'lodash/memoize'
import sortBy from 'lodash/sortBy'
import moment from 'moment/moment'

import { GlobalStaticState } from 'app/GlobalStaticState'
import isEditing from 'data/utils/isEditing'
import {
    getCurrentUserProfileRecordId,
    getCurrentUserRecordField,
    getCurrentUserRecordId,
} from 'data/wrappers/withUserUtils'

function _processFilter(data, filters, lookupRecord) {
    if (!filters) return data
    if (!data) {
        return data
    }
    let workingData = data

    filters.forEach((filter) => {
        if (!filter.field) return

        // role based filter doesn't need to run through actual record data
        if (filter.field.type === 'user_role') {
            if (isEditing()) {
                return
            }
            const userRoleId = GlobalStaticState.getAppUserRole()?._sid
            const roleAccess = isTrue(filter, userRoleId)
            workingData = roleAccess ? workingData : []
        } else {
            workingData = workingData.filter((row) => {
                return isTrue(filter, !!row && row[filter?.field?.api_name], lookupRecord)
            })
        }
    })

    return workingData
}

// This is a more efficient version, but I don't understand the memoize thing
const map = new WeakMap()
export const processFilterMemoized = memoize(_processFilter, (a, b) => {
    if (typeof a === 'undefined' || typeof b === 'undefined') return Math.random() + 1
    if (!map.has(a)) map.set(a, Math.random())
    if (!map.has(b)) map.set(b, Math.random())

    return `${map.get(a)}_${map.get(b)}`
})

export function isTrue(filter, providedData, lookupRecord) {
    // If the value is undefined, and this option should have prompted
    // for a value, then ignore
    const filterOptions = filter.options
    const objectId = filter?.field?.link_target_object_id
    const fieldType = filter?.field?.type
    if (filterOptions.value == null && FILTER_OPTIONS_LIST.includes(filterOptions.option))
        return true
    let userId
    let fieldValue
    let userProfileRecordId

    let data = providedData
    if (fieldType === 'document') {
        data = data?.plainTextContent
    }

    const isNull = data === null || data === undefined

    switch (filterOptions.option) {
        case 'is':
            if (isNull && fieldType === 'checkbox' && filterOptions.value === 'false') return true
            if (Array.isArray(data)) {
                return !isNull && doesArrayContain(data, filterOptions.value)
            }
            return !isNull && String(data) === filterOptions.value
        case 'isnt':
            if (Array.isArray(data)) {
                return isNull || !doesArrayContain(data, filterOptions.value)
            }
            return isNull || String(data) !== filterOptions.value
        case 'listIs':
            if (!Array.isArray(data)) {
                return !isNull && doesArrayMatch([data], filterOptions.value)
            }
            return !isNull && doesArrayMatch(data, filterOptions.value)
        case 'listIsnt':
            if (!Array.isArray(data)) {
                return isNull || !doesArrayMatch([data], filterOptions.value)
            }
            return isNull || !doesArrayMatch(data, filterOptions.value)
        case 'oneOf':
            if (Array.isArray(data)) {
                return !isNull && doesArrayContain(data, filterOptions.value)
            }
            return !isNull && filterOptions.value && filterOptions.value.includes(data)
        case 'noneOf':
            if (Array.isArray(data)) {
                return isNull || !doesArrayContain(data, filterOptions.value)
            }
            return isNull || !filterOptions.value || !filterOptions.value.includes(data)
        case 'contains':
            return (
                !isNull &&
                (String(data).toLowerCase().includes(String(filterOptions.value).toLowerCase()) ||
                    filterOptions.value === 'undefined')
            )
        case "doesn't contain":
            return (
                isNull ||
                !String(data).toLowerCase().includes(String(filterOptions.value).toLowerCase())
            )
        case 'containsAny':
            if (!Array.isArray(data)) {
                return !isNull && doesArrayContain([data], filterOptions.value)
            }
            return !isNull && doesArrayContain(data, filterOptions.value)
        case 'containsNone':
            if (!Array.isArray(data)) {
                return isNull || !doesArrayContain([data], filterOptions.value)
            }
            return isNull || !doesArrayContain(data, filterOptions.value)
        case 'startsWith':
            return (
                !isNull &&
                String(data.toLowerCase()).startsWith(String(filterOptions.value).toLowerCase())
            )
        case 'endsWith':
            return (
                !isNull &&
                String(data.toLowerCase()).endsWith(String(filterOptions.value).toLowerCase())
            )
        case 'equals':
            return !isNull && parseNumber(data) === Number(filterOptions.value)
        case 'notEquals':
            return isNull || parseNumber(data) !== Number(filterOptions.value)
        case 'lessThan':
            return !isNull && parseNumber(data) < filterOptions.value
        case 'greaterThan':
            return !isNull && parseNumber(data) > filterOptions.value
        case 'equalsOrLessThan':
            return !isNull && parseNumber(data) <= filterOptions.value
        case 'equalsOrGreaterThan':
            return !isNull && parseNumber(data) >= filterOptions.value
        case 'isCurrentUser':
            userId = getCurrentUserRecordId()
            userProfileRecordId = getCurrentUserProfileRecordId(objectId)
            return (
                !isNull &&
                (!userId || String(data) === userId || String(data) === userProfileRecordId)
            )
        case 'isNotCurrentUser':
            userId = getCurrentUserRecordId()
            userProfileRecordId = getCurrentUserProfileRecordId(objectId)
            return (
                isNull ||
                ((!userId || String(data) !== userId) && String(data) !== userProfileRecordId)
            )
        case 'containsCurrentUser':
            userId = getCurrentUserRecordId()
            userProfileRecordId = getCurrentUserProfileRecordId(objectId)
            return (
                !isNull &&
                (!userId ||
                    doesArrayContain(data, userId) ||
                    doesArrayContain(data, userProfileRecordId))
            )
        case 'doesNotContainCurrentUser':
            userId = getCurrentUserRecordId()
            userProfileRecordId = getCurrentUserProfileRecordId(objectId)
            return (
                isNull ||
                ((!userId || !doesArrayContain(data, userId)) &&
                    !doesArrayContain(data, userProfileRecordId))
            )
        case 'isCurrentUserField':
            // don't apply these filters if we have no current user record.
            // It means we are in a v3 app running as an admin and thus have
            // no user context in the data.
            if (!getCurrentUserRecordId()) return true
            fieldValue = getCurrentUserRecordField(filterOptions.value)

            // the field value can be an single value or a list, but the data is expected to always be a single value
            return !isNull && (String(data) === fieldValue || !!doesArrayContain(fieldValue, data))
        case 'isNotCurrentUserField':
            // don't apply these filters if we have no current user record.
            // It means we are in a v3 app running as an admin and thus have
            // no user context in the data.
            if (!getCurrentUserRecordId()) return true
            fieldValue = getCurrentUserRecordField(filterOptions.value)
            // the field value can be an single value or a list, but the data is expected to always be a single value
            return (
                isNull ||
                !fieldValue ||
                (!Array.isArray(fieldValue) && String(data) !== fieldValue) ||
                (Array.isArray(fieldValue) && !doesArrayContain(fieldValue, data))
            )
        case 'containsCurrentUserField':
            // don't apply these filters if we have no current user record.
            // It means we are in a v3 app running as an admin and thus have
            // no user context in the data.
            if (!getCurrentUserRecordId()) return true
            fieldValue = getCurrentUserRecordField(filterOptions.value)
            return !isNull && (!fieldValue || doesArrayContain(data, fieldValue))
        case 'doesNotContainCurrentUserField':
            // don't apply these filters if we have no current user record.
            // It means we are in a v3 app running as an admin and thus have
            // no user context in the data.
            if (!getCurrentUserRecordId()) return true
            fieldValue = getCurrentUserRecordField(filterOptions.value)
            return isNull || !fieldValue || !doesArrayContain(data, fieldValue)
        case 'isEmpty':
            return isEmpty(data)
        case 'isNotEmpty':
            return !isEmpty(data)
        //don't filter within last/next on FE
        case 'withinNext':
        case 'withinLast':
            return true
        case 'beforeDate':
            return (
                isRelativeFilter(filterOptions.value) ||
                (!isNull && moment(data).isBefore(filterOptions.value))
            )
        case 'afterDate':
            return (
                isRelativeFilter(filterOptions.value) ||
                (!isNull && moment(data).isAfter(filterOptions.value))
            )
        case 'sameDay':
            return (
                isRelativeFilter(filterOptions.value) ||
                (!isNull && moment(data).isSame(filterOptions.value))
            )
        case 'appearsIn':
            // Checks if a record appears in another record field
            // Used for related lists
            if (isNull) return false
            const filterValue = filterOptions.value.split('___')
            if (!filterValue || filterValue.length < 2) return false
            const [, field] = filterValue
            if (!lookupRecord || !lookupRecord?.[field]) return false
            return doesArrayContain(lookupRecord?.[field], data)
        default:
            return true
    }
}

function parseNumber(string) {
    try {
        return Number(string)
    } catch (e) {
        return string
    }
}

const isRelativeFilter = (value) => {
    return value.indexOf('_') >= 0
}

function doesArrayContain(data, value) {
    data = data || []
    //field type has changed so return false until the filter is updated
    if (!Array.isArray(data)) {
        return false
    }
    value = value || []
    value = Array.isArray(value) ? value : [value]
    return data.find((x) => value.includes(x))
}

function isEmpty(data) {
    const empty = [undefined, null, '']
    return empty.includes(data) || (Array.isArray(data) && data.length === 0)
}

function doesArrayMatch(data, value) {
    data = data || []
    value = value || []
    //field type has changed so return false until the filter is updated
    if (!Array.isArray(data)) {
        return false
    }
    value = Array.isArray(value) ? value : [value]
    return isEqual(sortBy(data), sortBy(value))
}

/*
 * This function should not be used when current user/role filters may be included
 * in the filters list. In that case, there will be no automatic re-evaluation of the result
 * should the current user or role change. In that case you should use the useProcessFilter hook.
 */
export const processStaticFilter = (data, filters, lookupRecord) => {
    return processFilterMemoized(data, filters, lookupRecord)
}

// Keep this in sync with nextFilterOptions in RecordFilters.jsx. This should be done automatically
// once these files are migrated to TS, but for now, they must be kept in sync manually.
export const FILTER_OPTIONS_LIST = [
    'is',
    'isnt',
    'listIs',
    'listIsnt',
    'contains',
    "doesn't contain",
    'startsWith',
    'endsWith',
    'equals',
    'notEquals',
    'greaterThan',
    'lessThan',
    'equalsOrGreaterThan',
    'equalsOrLessThan',
    'beforeDate',
    'afterDate',
    'isInBetween',
    'sameDay',
    'withinNext',
    'withinLast',
    'oneOf',
    'noneOf',
    'containsAny',
    'containsNone',
    'isCurrentUserField',
    'isNotCurrentUserField',
    'containsCurrentUserField',
    'doesNotContainCurrentUserField',
    'isContextRecordField',
    'isNotContextRecordField',
    'containsContextRecordField',
    'doesNotContainContextRecordField',
]

export const getFilterFields = (filters) => {
    return (filters || [])
        .filter((x) => x.field.type !== 'user_role' && x.field.api_name !== '_sid')
        .map((filter) => filter.field.api_name)
}
