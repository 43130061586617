import React, { forwardRef, useCallback, useRef } from 'react'

import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import NumberAttribute from 'v2/ui/components/Attribute/NumberAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCell, DataGridCellEditorComponent } from './types'

import { PercentageCellWrapperStyle } from './PercentageCell.css'

type PercentageCellEditorProps = {}

const PercentageCellEditor: DataGridCellEditorComponent<PercentageCellEditorProps> = forwardRef(
    ({ value }, ref) => {
        const [newValue, setNewValue] = useEditorValue<number>(ref, value)

        const onChange = useCallback(
            (value: number) => {
                const percent = value / 100
                setNewValue(percent)
            },
            [setNewValue]
        )

        const currentValue = parseFloat((newValue * 100).toFixed(5)) || undefined

        const inputElementRef = useRef<HTMLInputElement | null>(null)

        const handleTypeInCell = useCallback(
            (key: string) => {
                const newValue = parseFloat(key)
                onChange(newValue)
            },
            [onChange]
        )

        useTypeInCellToEdit({
            getInputElement: () => inputElementRef.current,
            onChange: handleTypeInCell,
        })

        return (
            <Box
                ref={(el: HTMLDivElement | null) => {
                    inputElementRef.current = el?.querySelector('input') ?? null
                }}
                height="full"
                width="full"
                tabIndex={0}
                className={PercentageCellWrapperStyle}
            >
                <NumberAttribute mode={modes.editing} onChange={onChange}>
                    {currentValue}
                </NumberAttribute>
            </Box>
        )
    }
)

export const PercentageCell: DataGridCell = {
    provideEditor: PercentageCellEditor,
}
