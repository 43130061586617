import React, { useState } from 'react'

import { orderBy } from 'lodash'

import { useAppUserContext } from 'app/AppUserContext'
import { ActivityType } from 'data/hooks/activityTypes'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'

import { ActivityContent } from './ActivityContent'
import { useActivityFeedContext } from './ActivityFeedContext'
import { ActivityTitle } from './ActivityTitle'
import { TypeIcon } from './ActivityTypeIcon'
import { Comment } from './Comment'
import { CreateComment } from './CreateComment'
import { FeedUserDisplay } from './FeedUserDisplay'
import { makeDraftId } from './utils'

import { CommentContainerStyle } from './ActivityStyles.css'
const DEFAULT_VISIBLE_REPLIES = 2
type ActivityProps = BoxProps & {
    activity: ActivityDto
    controls?: React.ReactNode
    compact?: boolean
    startCollapsed?: boolean
    unreadActivities?: number[]
    collapsedHeight?: string
    showActivityControls?: boolean
    showLocationPreview?: boolean
}
export function Activity({
    activity,
    controls,
    compact,
    startCollapsed,
    collapsedHeight,
    unreadActivities,
    showActivityControls = true,
    showLocationPreview = false,
    ...props
}: ActivityProps) {
    const { user } = useAppUserContext()
    const { activities, updateActivity } = useActivityFeedContext()
    const comments = orderBy(
        activities.filter((a) => a.parent_activity_id && a.parent_activity_id === activity.auto_id),
        ['created_at'],
        ['asc']
    )
    const isSavePending =
        (!!activity.pendingCreateId || activity.originalContent) && !activity.saveFailed
    const showAvatar = !compact && activity.type == ActivityType.Comment

    const indexOfFirstUnreadReply = comments.findIndex((c) => unreadActivities?.includes(c.auto_id))

    const [firstVisibleReply, setFirstVisibleReply] = useState(
        Math.min(
            indexOfFirstUnreadReply > -1 ? indexOfFirstUnreadReply : comments.length,
            comments.length - DEFAULT_VISIBLE_REPLIES
        )
    )

    const draftId = makeDraftId({
        ...activity,
        parentActivityId: activity.auto_id,
    })

    return (
        <Box flex column width="full" grow stretch {...props}>
            {activity.is_deleted ? (
                <Box flex center px="l" py="m" color="textWeaker">
                    <Icon name="Trash" mr="l" m="m" opacity={0.6} />
                    <Box grow> This post has been deleted.</Box>
                    {activity.created_by === user?._sid && (
                        <Button
                            variant="ghost"
                            startIcon={{ name: 'Undo' }}
                            size="s"
                            opacity={0.8}
                            onClick={() =>
                                updateActivity({
                                    id: activity.auto_id,
                                    patch: { is_deleted: false },
                                    allowOptimisticUpdate: false,
                                })
                            }
                        >
                            Restore
                        </Button>
                    )}
                </Box>
            ) : (
                <Box flex alignItems="flex-start" px="m" style={{ minHeight: '2.5rem' }}>
                    {showAvatar && (
                        <FeedUserDisplay
                            userId={activity.created_by}
                            showName={false}
                            avatarSize="sm"
                            mr="m"
                        />
                    )}
                    {!showAvatar && !compact && <TypeIcon type={activity.type} size="4xl" mr="m" />}
                    <Box flex column width="full" grow stretch shrink>
                        <ActivityTitle
                            activity={activity}
                            controls={controls}
                            compact={compact}
                            showActivityControls={showActivityControls}
                        />
                        <ActivityContent
                            activity={activity}
                            compact={compact}
                            startCollapsed={startCollapsed}
                            collapsedHeight={collapsedHeight}
                            showLocationPreview={showLocationPreview}
                        />
                    </Box>
                </Box>
            )}

            <Container variant="neutralMuted" className={CommentContainerStyle} mx="2xs" mb="2xs">
                {comments.length > 0 && (
                    <>
                        <Box mt="l" mx="l">
                            {firstVisibleReply > 0 && (
                                <Button
                                    width="full"
                                    variant="ghost"
                                    onClick={() => setFirstVisibleReply((x) => x - 3)}
                                    mb="l"
                                    size="xs"
                                >
                                    Show more replies...
                                </Button>
                            )}
                            {comments.map(
                                (comment, idx) =>
                                    idx >= firstVisibleReply && (
                                        <Comment
                                            key={comment.auto_id}
                                            activity={comment}
                                            isUnread={unreadActivities?.includes(comment.auto_id)}
                                            compact={compact}
                                            showControls={showActivityControls}
                                        />
                                    )
                            )}
                        </Box>
                        <Divider />
                    </>
                )}

                {!isSavePending && (
                    <Box m="xs" ml="l">
                        <CreateComment
                            replyingTo={activity}
                            placeholder="Reply..."
                            inputContainerProps={{ px: 'xs', pl: 'm', borderRadius: 's' }}
                            autoSaveDraft={true}
                            draftId={draftId}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    )
}
