import { pxToRem } from 'ui/helpers/converters'
import { selectPaletteThemeColorsByTone } from 'ui/styling/helpers/selectPaletteThemeColorsByTone'

import { makeAppVariables } from './app'

/**
 * Token shortcuts:
 * SLC = Solid Light Color
 * SDC = Solid Dark Color
 * SDN = Solid Dark Neutral
 * SLN = Solid Light Neutral
 * ON = Outline Neutral
 * OC = Outline Color
 */
export function makeTagVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surfaceDisabled: s.color.surfaceDisabled,
            surfaceSdn: s.color.gray900,
            surfaceSdnHover: s.color.gray950,
            surfaceSln: s.color.gray100,
            surfaceSlnHover: s.color.gray200,
            surfaceOn: s.color.white00,
            surfaceOnHover: s.color.gray100,
            ...selectPaletteThemeColorsByTone('100', 'surfaceSlc'),
            ...selectPaletteThemeColorsByTone('200', 'surfaceSlc', 'Hover'),
            ...selectPaletteThemeColorsByTone('600', 'surfaceSdc'),
            ...selectPaletteThemeColorsByTone('700', 'surfaceSdc', 'Hover'),
            surfaceOc: s.color.white00,
            ...selectPaletteThemeColorsByTone('50', 'surfaceOc', 'Hover'),

            textDisabled: s.color.gray400,
            textSdn: s.color.textInverse,
            textSln: s.color.text,
            textSdc: s.color.textInverse,
            textOn: s.color.gray900,
            ...selectPaletteThemeColorsByTone('800', 'textSlc'),
            ...selectPaletteThemeColorsByTone('800', 'textOc'),

            iconDisabled: s.color.gray400,
            iconSdn: s.color.iconInverse,
            iconSln: s.color.icon,
            iconSdc: s.color.iconInverse,
            iconOn: s.color.icon,
            ...selectPaletteThemeColorsByTone('700', 'iconSlc'),
            ...selectPaletteThemeColorsByTone('600', 'iconOc'),

            border: 'transparent',
            borderDisabled: 'transparent',
            borderOn: s.color.gray300,
            ...selectPaletteThemeColorsByTone('300', 'borderOc'),
        },
        gap: {
            '2xs': s.space.xs,
            xs: s.space.xs,
            s: s.space.s,
            m: s.space.m,
            betweenParts: s.space.xs,
        },
        size: {
            '2xs': pxToRem(20),
            xs: pxToRem(24),
            s: pxToRem(28),
            m: pxToRem(36),
            '2xsIcon': 'xs',
            xsIcon: 's',
            sIcon: 's',
            mIcon: 's',
        },
        radius: {
            rectangle: s.radius.s,
            pill: s.radius.pill,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        padding: {
            '2xs': s.space.s,
            xs: s.space.m,
            s: s.space.m,
            m: s.space.l,
        },
        borderWidth: s.borderWidths[1],
        borderStyle: s.borderStyles.base,
        textSize: {
            '2xs': 's',
            xs: 'm',
            s: 'm',
            m: 'm',
        },
        textWeight: {
            '2xs': 'medium',
            xs: 'medium',
            s: 'medium',
            m: 'medium',
        },
    }
}
