import React from 'react'

import { Spinner } from '@chakra-ui/react'

import Text from 'v2/ui/components/Text'
import useHover from 'v2/ui/utils/useHover'

import { Box } from 'ui/components/Box'

import { ActivityContent } from './ActivityContent'
import { ActivityControls } from './ActivityControls'
import { useActivityFeedContext } from './ActivityFeedContext'
import { ActivityTimestamp } from './ActivityTimestamp'
import { FeedUserDisplay } from './FeedUserDisplay'

export type CommentProps = {
    activity: ActivityDto
    isUnread?: boolean
    compact?: boolean
    showControls?: boolean
}
export function Comment({ activity, isUnread, compact, showControls = true }: CommentProps) {
    const { retryFailedActivity } = useActivityFeedContext()
    const [isHovered, hoverHandlers] = useHover()

    const isSavePending =
        (!!activity.pendingCreateId || activity.originalContent) && !activity.saveFailed

    const retryPost = () => {
        retryFailedActivity(activity)
    }
    return (
        <Box flex alignItems="baseline" width="full" fontSize="bodyM" {...hoverHandlers}>
            <FeedUserDisplay userId={activity.created_by} showName={false} mr="m" />

            <Box flex column stretch grow style={{ minWidth: 0 }}>
                <Box flex alignItems="center">
                    <FeedUserDisplay userId={activity.created_by} mr="xs" showAvatar={false} />
                    {isSavePending && (
                        <Spinner size="sm" ml={2} color="userInterface.neutral.800" />
                    )}
                    {activity.saveFailed && (
                        <Box ml="m">
                            <Text variant="error" display="inline" mr="m">
                                Failed to post.
                            </Text>
                            <a href="#" onClick={retryPost}>
                                Try again
                            </a>
                        </Box>
                    )}
                    {!isSavePending && !activity.saveFailed && (
                        <ActivityTimestamp activity={activity} />
                    )}
                    {isUnread && (
                        <Box pl="xs" pr="xs" style={{ paddingBottom: '2px' }}>
                            <Box
                                background="admin500"
                                borderRadius="pill"
                                opacity={1}
                                width="m"
                                height="m"
                            />
                        </Box>
                    )}
                    <Box grow />
                    {!isSavePending && !activity.saveFailed && showControls && (
                        <ActivityControls activity={activity} hide show={isHovered} />
                    )}
                </Box>
                <ActivityContent activity={activity} compact={compact} />
            </Box>
        </Box>
    )
}
