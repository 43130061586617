import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'

export type ModalType = 'users' | 'tableGrant'

export type UserModalContextValue = {
    mode: ModalType
    editData: any
    setMode: (mode: ModalType, grant?: AccessGrant | null) => void
}

export const UserModalContext = createContext<UserModalContextValue>({
    mode: 'users',
    editData: null,
    setMode: () => {},
})

export const UserModalContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mode, setMode] = useState<ModalType>('users')
    const [editData, setEditData] = useState<any>(null)

    const handleSetMode = useCallback((mode: ModalType, grant: AccessGrant | null = null) => {
        setMode(mode)
        setEditData(grant)
    }, [])

    const contextValue = useMemo(
        () => ({
            mode,
            editData,
            setMode: handleSetMode,
        }),
        [mode, editData, handleSetMode]
    )

    return <UserModalContext.Provider value={contextValue}>{children}</UserModalContext.Provider>
}

export const useUserModal = () => {
    const context = useContext(UserModalContext)
    if (!context) {
        throw new Error('useUserModal must be used within a UserModalContextProvider')
    }
    return context
}
