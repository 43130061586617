import React, { memo } from 'react'

import * as Sentry from '@sentry/react'

import { isExternal } from 'utils/utils'

import Box from './Box'
import Button from './Button'
import Image from './Image'
import Link from './Link'
// matches
//    //{url}
//    prefix:{url} (http:, https:, ftp:, mailto:, tel:, etc)
//    /{url} for internal partial urls
const urlPrefexRegEx = /^(\/\/|[a-z0-9A-Z]+:|\/)/

function encodeQueryParams(url) {
    const urlParts = url.split('?')
    if (urlParts.length === 1) {
        // There are no query params in the URL.
        return url
    }
    const queryStringParts = urlParts[1].split('#', 2)
    const queryString = queryStringParts[0]
    const fragment = queryStringParts.length > 1 ? '#' + queryStringParts[1] : ''

    const queryParams = queryString.split('&')
    const encodedQueryParams = queryParams.map((param) => {
        const paramParts = param.split('=')
        let key = paramParts[0]
        let value = paramParts.length > 1 ? paramParts[1] : ''

        try {
            key = decodeURIComponent(key)
        } catch (e) {
            console.error('Could not decode key', key, e)
        }

        try {
            value = decodeURIComponent(value)
        } catch (e) {
            console.error('Could not decode value', value, e)
        }

        return encodeURIComponent(key) + '=' + encodeURIComponent(value)
    })
    const encodedQueryString = encodedQueryParams.join('&')
    return urlParts[0] + '?' + encodedQueryString + fragment
}

// See this doc for our url handling rules
//  www.notion.so/stacker/Link-URL-Handling-Rules-412869fbcbe54de49edc9c1ff894c18b
const RenderButtonOrLink = memo(
    ({
        url: originalUrl,
        button,
        children,
        openInNewTab,
        image,
        imageAltText,
        variant = 'fieldButton',
        ...props
    }) => {
        let url = originalUrl
        if (!url) return ''

        try {
            if (url.startsWith('javascript:')) {
                return ''
            }
            // If we don't have a recognized prefix, then prepend http://
            if (!urlPrefexRegEx.test(url)) {
                url = 'http://' + url
            }
        } catch (e) {
            Sentry.captureMessage(
                `Expected a string when displaying URL. Got ${typeof url} instead`
            )
            return ''
        }

        if (!isExternal(url)) {
            // An internal URL may be absolute or relative
            const host = window.location.hostname
            if (url.indexOf(host) >= 0) {
                // Make a relative URL from the absolute one, so it will work with
                // react router
                url = url.slice(url.indexOf(host) + host.length)
            }
        }

        url = encodeQueryParams(url)
        const wrapContent = (content) => {
            if (typeof content === 'string') {
                return (
                    <Box
                        as="span"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        maxWidth="100%"
                    >
                        {content}
                    </Box>
                )
            } else {
                return content
            }
        }

        if (image) {
            return <Image alignSelf="flex-start" src={url} alt={imageAltText} />
        }

        if (button) {
            return (
                <Button
                    variant={variant}
                    href={url}
                    openInNewTab={openInNewTab}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    maxWidth="100%"
                    {...props}
                >
                    {wrapContent(children || 'Open')}
                </Button>
            )
        }

        return (
            <Link
                variant={variant}
                href={url}
                openInNewTab={openInNewTab}
                onClick={(e) => {
                    e.stopPropagation()
                }}
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                }}
                simplifiedComponent
                {...props}
            >
                {children || originalUrl}
            </Link>
        )
    }
)

export default RenderButtonOrLink
