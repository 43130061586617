import React, { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { isDraftWorkflow } from 'features/workflows/common'
import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import QuickCopyButton from 'v2/ui/components/QuickCopyButton'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'

import { WorkflowTriggerEditorComponent } from './types'

export const WebhookTriggerConfig: WorkflowTriggerEditorComponent = ({
    workflow,
    trigger,
    onChange,
}) => {
    const handleWebhookUrlInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select()
    }

    const isDraft = isDraftWorkflow(workflow)

    const outputSchema = trigger.settings?.output_schema as WorkflowOutputSchema | undefined
    const handleOutputSchemaChange = (value: WorkflowOutputSchema) => {
        onChange({
            settings: {
                ...trigger.settings,
                output_schema: value,
            },
        })
    }

    return (
        <>
            <ConfigurationLabel>
                This workflow will run when a request is made to the following webhook endpoint
            </ConfigurationLabel>
            <Box flex center gap="m">
                <Input
                    value={workflow.webhook_url ?? ''}
                    readOnly
                    onFocus={handleWebhookUrlInputFocus}
                    fontSize="bodyS"
                    placeholder="The endpoint will be available after saving"
                    disabled={isDraft}
                />
                <QuickCopyButton
                    value={workflow.webhook_url ?? ''}
                    isHidden={false}
                    buttonProps={{
                        style: {
                            padding: 0,
                        },
                        disabled: isDraft,
                    }}
                />
            </Box>
            <Box flex center gap="m" mt="m">
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Request method</ConfigurationLabel>
                    POST
                </Box>
                <Box style={{ flexBasis: '50%' }}>
                    <ConfigurationLabel>Data format</ConfigurationLabel>
                    JSON
                </Box>
            </Box>
            <ConfigurationLabel>Data attributes</ConfigurationLabel>
            <OutputSchemaEditor value={outputSchema} onChange={handleOutputSchemaChange} mb="l" />
        </>
    )
}

export function useWebhookTriggerFinalSchema() {
    const { data: schema } = useWorkflowSchema()

    return useCallback(
        (config: WorkflowTriggerConfig): WorkflowSchemaTriggerType | undefined => {
            const type = schema?.triggers.find((t) => t.id === config.trigger_type)
            if (!type) return undefined

            const outputSchema = config.settings?.output_schema as WorkflowOutputSchema | undefined
            if (!outputSchema) return undefined

            const outputs = convertOutputSchemaToOutputs(outputSchema)

            return {
                ...type,
                outputs,
            }
        },
        [schema?.triggers]
    )
}
