import React, { useEffect, useState } from 'react'

import {
    Bundle,
    BundleApp,
    BundleField,
    BundleListView,
    BundleObject,
    BundleRecord,
} from 'features/AiPlayground/bundles/bundleBuilder'
import { Card } from 'features/views/ListView/CardView/CardView.parts'
import { makeGridTemplateColumns } from 'features/views/ListView/TableView/hooks/useTableViewGridState'
import { Cell, Header, Row } from 'features/views/ListView/TableView/TableView.parts'

import { Box, BoxProps } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { LinkButton } from 'ui/components/LinkButton'
import { Tag } from 'ui/components/Tag'
import { Headline } from 'ui/components/Text'

type Props = {
    bundle: Bundle
}
export function SimpleBundlePreview({ bundle }: Props) {
    const app = bundle?.apps[0]
    const [selectedObject, setSelectedObject] = useState<BundleObject | null>(app?.objects[0])
    const [selectedListView, setSelectedListView] = useState<BundleListView>()

    console.log(app)
    useEffect(() => {
        const object = app?.objects[0]

        setSelectedObject(object)
        if (object?.listViews) {
            setSelectedListView(object.listViews[0])
        }
    }, [app])

    if (!app) return null
    return (
        <Box
            flex
            background="white00"
            border
            height="full"
            width="full"
            rounded="m"
            overflow="hidden"
            boxShadow="m"
        >
            <Sidebar
                app={app}
                selectedObject={selectedObject}
                setSelectedObject={setSelectedObject}
                selectedListView={selectedListView}
                setSelectedListView={setSelectedListView}
            />
            <Box flex column grow shrink>
                {selectedObject && (
                    <ObjectPreview
                        key={selectedObject.id}
                        object={selectedObject}
                        listView={selectedListView}
                        app={app}
                    />
                )}
            </Box>
        </Box>
    )
}

type SidebarProps = {
    app: BundleApp
    selectedObject: BundleObject | null
    setSelectedObject: (object: BundleObject | null) => void
    selectedListView?: BundleListView
    setSelectedListView: (listView: BundleListView | undefined) => void
}

function Sidebar({
    app,
    selectedObject,
    setSelectedObject,
    selectedListView,
    setSelectedListView,
}: SidebarProps) {
    return (
        <Box
            flex
            column
            borderRightWidth="base"
            background="purple700"
            color="white00"
            noShrink
            minWidth="200px"
            maxWidth="300px"
        >
            <Box px="l" py="2xl">
                <Headline size="s">{app.name}</Headline>
            </Box>
            <Box flex column overflowY="auto" p="m" alignItems="stretch">
                {app.objects.map((object) => (
                    <>
                        {object.listViews && object.listViews.length > 1 ? (
                            <>
                                <Box px="m" fontSize="bodyS" style={{ opacity: 0.7 }} mb="s" mt="l">
                                    {object.name}
                                </Box>
                                {object.listViews?.map((x) => (
                                    <SidebarItem
                                        key={object.id}
                                        object={object}
                                        listView={x}
                                        selectedObject={selectedObject}
                                        setSelectedObject={setSelectedObject}
                                        selectedListView={selectedListView}
                                        setSelectedListView={setSelectedListView}
                                    />
                                ))}
                            </>
                        ) : (
                            <SidebarItem
                                key={object.id}
                                object={object}
                                selectedObject={selectedObject}
                                setSelectedObject={setSelectedObject}
                                setSelectedListView={setSelectedListView}
                            />
                        )}
                    </>
                ))}
            </Box>
        </Box>
    )
}

function SidebarItem({
    object,
    selectedObject,
    setSelectedObject,
    listView,
    selectedListView,
    setSelectedListView,
}: {
    object: BundleObject
    selectedObject: BundleObject | null
    setSelectedObject: (object: BundleObject | null) => void
    listView?: BundleListView
    selectedListView?: BundleListView
    setSelectedListView: (listView: BundleListView | undefined) => void
}) {
    const isSelected = selectedListView?.id === listView?.id && selectedObject?.id === object.id
    return (
        <Box
            p="m"
            role="button"
            onClick={() => {
                setSelectedObject(object)
                setSelectedListView(listView)
            }}
            rounded="m"
            color="indigo100"
            style={{
                backgroundColor: isSelected ? 'rgba(0,0,0,0.1)' : 'unset',
                boxShadow: isSelected ? '1px 1px 2px 1px rgba(0,0,0,0.05) inset' : 'none',
            }}
        >
            {listView?.title || object.name}
        </Box>
    )
}

function ObjectPreview({
    object,
    listView,
    app,
}: {
    object: BundleObject
    listView?: BundleListView
    app: BundleApp
}) {
    const layout = listView?.layout || object.layout
    return (
        <>
            <Box px="l" py="2xl">
                <Headline size="s" m="m">
                    {object.name}
                </Headline>
            </Box>
            <Divider />
            <Box flex column grow overflow="auto" maxHeight="full" maxWidth="full" p="l">
                {(!layout || layout === 'table') && (
                    <TableView object={object} listView={listView} app={app} />
                )}
                {layout === 'gallery' && <CardView object={object} listView={listView} app={app} />}
            </Box>
        </>
    )
}

function TableView({
    object,
    app,
}: {
    object: BundleObject
    listView?: BundleListView
    app: BundleApp
}) {
    const coverImageField = object.fields?.find((x) => x.type === 'image')

    const defaultFields = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
    const fields = object.fields || defaultFields
    const nonCoverImageFields = fields.filter((field) => field.id !== coverImageField?.id)
    // @ts-expect-error
    const gridTemplateColumns = makeGridTemplateColumns(nonCoverImageFields, true)

    return (
        <Box
            display="grid"
            width="full"
            style={{
                gridTemplateColumns,
            }}
        >
            <Row display="table-row" style={{ opacity: 0.7 }}>
                {nonCoverImageFields.map((field) => (
                    <Header key={field.id}>{field.name ? field.name : <Skeleton />}</Header>
                ))}
            </Row>
            {object.records ? (
                object.records.map((record) => (
                    <Row key={record.recordId}>
                        {nonCoverImageFields.map((field, idx) =>
                            field.id !== coverImageField?.id ? (
                                <Cell key={idx}>
                                    {idx === 0 && coverImageField && (
                                        <TableRowAvatar
                                            record={record}
                                            coverImageField={coverImageField}
                                        />
                                    )}
                                    <CellValue
                                        field={field}
                                        value={
                                            record.fields.find((f) => f.fieldId === field.id)?.value
                                        }
                                        app={app}
                                    />
                                </Cell>
                            ) : null
                        )}
                    </Row>
                ))
            ) : (
                <>
                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>

                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>
                    <Row>
                        {nonCoverImageFields.map((x) => (
                            <Cell key={x.id}>
                                <Skeleton />
                            </Cell>
                        ))}
                    </Row>
                </>
            )}
        </Box>
    )
}

function TableRowAvatar({
    record,
    coverImageField,
}: {
    record: BundleRecord
    coverImageField: BundleField
}) {
    const value = record.fields.find((f) => f.fieldId === coverImageField.id)?.value

    if (!value) {
        return (
            <Box
                background="gray200"
                flex
                center
                justifyContent="center"
                style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '50%',
                    marginRight: '0.75rem',
                }}
            >
                <Icon name="Image" size="xl" opacity={0.2} />
            </Box>
        )
    }

    return (
        <img
            src={value}
            alt="Image"
            style={{
                width: '2rem',
                height: '2rem',
                objectFit: 'cover',
                borderRadius: '50%',
                marginRight: '0.75rem',
            }}
        />
    )
}

function CardView({
    object,
    app,
}: {
    object: BundleObject
    listView?: BundleListView
    app: BundleApp
}) {
    // @ts-expect-error
    const gridTemplateColumns = makeGridTemplateColumns([0, 1, 2], true)
    const coverImageField = object.fields?.find((x) => x.type === 'image')

    return (
        <Box
            display="grid"
            gap="m"
            width="full"
            style={{
                gridTemplateColumns,
            }}
        >
            {object.records
                ? object.records.map((record) => (
                      <Card key={record.recordId} border>
                          {coverImageField && (
                              <Box
                                  as="img"
                                  width="full"
                                  height="100px"
                                  style={{ objectFit: 'cover' }}
                                  src={
                                      record.fields.find((f) => f.fieldId === coverImageField.id)
                                          ?.value
                                  }
                              />
                          )}
                          <Box flex column p="l" gap="s">
                              {record.fields.map((field, idx) =>
                                  field.fieldId === coverImageField?.id ? null : (
                                      <CellValue
                                          key={idx}
                                          field={object.fields.find((f) => f.id === field.fieldId)!}
                                          value={field.value}
                                          app={app}
                                      />
                                  )
                              )}
                          </Box>
                      </Card>
                  ))
                : [0, 1, 2].map((x) => (
                      <Card key={x} border p="l">
                          <Skeleton mb="l" />
                          <Skeleton mb="l" />
                          <Skeleton mb="l" />
                          <Skeleton />
                      </Card>
                  ))}
        </Box>
    )
}

function CellValue({ field, value, app }: { field: BundleField; value: any; app: BundleApp }) {
    if (!value) return <Skeleton />

    try {
        if (field.type === 'multi_relationship') {
            return (
                <Box>
                    {value?.split(',').map((x) => (
                        <LinkButton key={x} variant="secondary" size="s" noShrink mb="s" mr="s">
                            {getRecordName(x, field.target, app)}
                        </LinkButton>
                    ))}
                </Box>
            )
        } else if (field.type === 'relationship') {
            return (
                <Box>
                    <LinkButton variant="secondary" size="s" noShrink mb="s" mr="s">
                        {getRecordName(value, field.target, app)}
                    </LinkButton>
                </Box>
            )
        } else if (field.type === 'multi_select') {
            return (
                <Box>
                    {value?.split(',').map((x) => (
                        <Tag key={x} size="s" noShrink mb="s" mr="s">
                            {x}
                        </Tag>
                    ))}
                </Box>
            )
        } else if (field.type === 'dropdown') {
            return (
                <Box>
                    <Tag size="s" noShrink mb="s" mr="s">
                        {value}
                    </Tag>
                </Box>
            )
        } else if (field.type === 'image') {
            return (
                <Box>
                    <img src={value} alt={field.name} style={{ height: '32px' }} />
                </Box>
            )
        }
        return <Box>{value}</Box>
    } catch (ex) {
        console.error(ex)
        return <Box>!!{value}</Box>
    }
}

function getRecordName(recordId: string, objectId: string, app: BundleApp) {
    const object = app.objects?.find((o) => o.id === objectId)
    if (!object) return recordId
    const record = object.records?.find((r) => r.recordId === recordId)
    if (!record) return recordId

    return record.fields[0].value
}

function Skeleton(props: BoxProps) {
    return (
        <Box
            background="gray200"
            width="full"
            grow
            rounded="m"
            style={{ minHeight: '1rem' }}
            {...props}
        ></Box>
    )
}
