import { useCallback, useMemo, useState } from 'react'

import moment from 'moment'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ActionMenuEditProps } from 'features/views/ListView/Actions/types'
import { formatDate } from 'utils/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25

type UseActionMenuEditDateStateOptions = ActionMenuEditProps<string> & {}

export function useActionMenuEditDateState(options: UseActionMenuEditDateStateOptions) {
    const { field, updateRecord, value } = options

    const fieldLabel = truncateText(field.label, MAX_LABEL_LENGTH)
    const showTime = field.type === 'datetime'

    const onEditValue = useCallback(
        (value: Date | null) => {
            setCurrentValue(value ?? undefined)

            const format = 'YYYY-MM-DDTHH:mm:ss'
            const parsedDate = value ? moment(value) : null

            let formattedDate: string | null = null
            if (showTime) {
                formattedDate = parsedDate?.utc().format(format) ?? null
            } else {
                // Make sure we don't change the day by switching back to UTC.
                formattedDate = parsedDate?.local().format(format) ?? null
            }

            updateRecord({ [field.api_name]: formattedDate })
        },
        [field.api_name, showTime, updateRecord]
    )

    const { dateValue } = formatDate(value, field.options?.timezone, showTime)
    const valueAsDate = dateValue?.local().toDate()
    const [currentValue, setCurrentValue] = useState<Date | undefined>(valueAsDate)

    const fieldIcon = useDeepEqualsMemoValue(getFieldIcon(field))

    return useMemo(
        () => ({ fieldLabel, onEditValue, value: currentValue, showTime, fieldIcon }),
        [fieldLabel, onEditValue, currentValue, showTime, fieldIcon]
    )
}
