import React, { useContext } from 'react'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'

import { RecordManager, useRecordManagerState } from './hooks/useRecordManagerState'

export type RecordManagerContextValue = RecordManager

export const RecordManagerContext = React.createContext<RecordManagerContextValue | null>(null)

export function useRecordManagerContext(): RecordManagerContextValue {
    const context = useContext(RecordManagerContext)

    if (!context) {
        throw new Error(
            'useRecordManagerContext must be used within a RecordManagerContextProvider'
        )
    }

    return context
}

export type RecordManagerContextProviderProps = {
    recordSid?: string
    includeFields?: string[]
}

export const RecordManagerContextProvider: React.FC<RecordManagerContextProviderProps> = ({
    recordSid,
    includeFields,
    children,
}) => {
    const value = useRecordManagerState({
        recordSid,
        includeFields,
    })

    return (
        <RecordManagerContext.Provider value={value}>
            {children}
            <UnsavedChangesModal
                isDirty={value.isDirty}
                onSave={value.saveChanges}
                revertChanges={value.discardChanges}
            />
        </RecordManagerContext.Provider>
    )
}
