import { useCallback, useRef, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { SharingSettingsPatch, useUpdateStackSharingSettings } from 'data/hooks/stacks'
import { refetchAppUsersForAdmin } from 'data/hooks/users/main'

import { InviteUserModalHandle } from './useAppUsersInviteUserModalState'

export function useAppUsersAddUsersButtonState() {
    const [selectedRole, setSelectedRole] = useState('')
    const selectedRoleRef = useRef(selectedRole)
    selectedRoleRef.current = selectedRole

    const [selectedUser, setSelectedUser] = useState('')
    const selectedUserRef = useRef(selectedUser)
    selectedUserRef.current = selectedUser

    const isSubmitEnabled = !!selectedUser && !!selectedRole

    const [isOpen, setIsOpen] = useState(false)
    const onOpenChange = useCallback((open: boolean) => {
        setSelectedUser('')
        setSelectedRole('')

        setIsOpen(open)
    }, [])

    const { selectedStack } = useAppContext()
    const stackRef = useRef(selectedStack)
    stackRef.current = selectedStack

    const updateStackSharingSettings = useUpdateStackSharingSettings()

    const onSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault()

            if (!isSubmitEnabled) return Promise.resolve()

            const stack = stackRef.current
            if (!stack) return Promise.resolve()

            const userSid = selectedUserRef.current
            const roleSid = selectedRoleRef.current
            if (!userSid || !roleSid) return Promise.resolve()

            const patch: SharingSettingsPatch = {
                [userSid]: {
                    role: roleSid,
                },
            }

            await updateStackSharingSettings(stack, patch)
            await refetchAppUsersForAdmin()

            onOpenChange(false)
        },
        [isSubmitEnabled, onOpenChange, updateStackSharingSettings]
    )

    const onClosePopup = useCallback(() => {
        onOpenChange(false)
    }, [onOpenChange])

    const inviteUserModalRef = useRef<InviteUserModalHandle>(null)

    const openInviteUsersModal = useCallback((prefillEmail?: string) => {
        inviteUserModalRef.current?.open(prefillEmail)
    }, [])

    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false)

    const onRoleCreated = useCallback(
        (role: RoleDto) => setSelectedRole(role.api_name),
        [setSelectedRole]
    )
    const onAddRole = useCallback(() => setIsRoleModalOpen(true), [])

    return {
        isSubmitEnabled,
        selectedRole,
        setSelectedRole,
        onOpenChange,
        selectedUser,
        setSelectedUser,
        onSubmit,
        isOpen,
        onClosePopup,
        inviteUserModalRef,
        openInviteUsersModal,
        isRoleModalOpen,
        setIsRoleModalOpen,
        onRoleCreated,
        onAddRole,
    }
}
