import React from 'react'
import { Helmet } from 'react-helmet'

import { useAppContext } from 'app/AppContext'
import { getStackTheme } from 'app/Branding'
import publicAsset from 'utils/publicAsset'

const _DefaultHeaderMeta = ({ page, view, title: customTitle }) => {
    const { workspaceAccount } = useAppContext()
    const { selectedStack: stack } = useAppContext()
    const theme = getStackTheme(stack)
    const V4Icon = theme?.logo_icon || workspaceAccount?.options?.theme?.logo_icon

    const workspace_icon = V4Icon

    let icon = workspace_icon
    if (!icon) {
        icon = publicAsset('/static/media/stacker-logo-s-only.png')
    }

    let title = stack?.name
    if (customTitle) {
        title = customTitle
    } else if (view && (view.type === 'list' || view.type === 'blankpage')) {
        // if the list is in single record display then do not show the overwritten title as they
        // cannot edit this
        if (view.options?.display === 'single_record' || view.type === 'blankpage') {
            title = view.name
        } else {
            title = view.options?.title || view.name
        }
    } else if (page) {
        title = page.options?.title || page.name
    }
    return (
        <Helmet>
            <link key={icon} rel="shortcut icon" href={icon} type="image/png" />
            <link rel="apple-touch-icon" href={icon}></link>
            <meta charSet="utf-8" />
            <title>{title}</title>
        </Helmet>
    )
}

export const DefaultHeaderMeta = React.memo(_DefaultHeaderMeta)
