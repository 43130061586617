import { useMemo } from 'react'

import { AttachmentValue } from 'features/views/attributes/types'
import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

type UseAttachmentsAttributeStateOptions = {
    attribute: BoardViewAttribute
    value?: AttachmentValue[]
    isLoading?: boolean
    displayStyle?: ListViewOptions['boardFieldStyle']
}

export function useAttachmentsAttributeState(options: UseAttachmentsAttributeStateOptions) {
    const { value, isLoading, displayStyle, attribute } = options
    const { rawOptions, type } = attribute
    const isFooter = type === 'footer'

    const isEmpty = !isLoading && (!value || value.length < 1)

    const isSingle = !Array.isArray(value) || value.length === 1

    const displayAsImage = rawOptions.displayAsImage as boolean
    let displayAsTag = displayStyle === 'tags'
    if (isFooter && !displayAsImage) {
        displayAsTag = true
    }

    const { attributeStyle } = useBoardViewContext()
    const isTagListAttributeStyle = attributeStyle === 'tags'

    return useMemo(
        () => ({
            isEmpty,
            isSingle,
            displayAsTag,
            displayAsImage,
            isTagListAttributeStyle,
        }),
        [displayAsImage, displayAsTag, isEmpty, isSingle, isTagListAttributeStyle]
    )
}
