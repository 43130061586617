import React from 'react'

import { useAppContext } from 'app/AppContext'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { breakpoints } from 'ui/styling/helpers/breakpoints'

import SidebarsContainer from './SidebarsContainer'

import { WorkspaceDrawerStyle } from './WorkspaceDrawer.css'

type WorkspaceDrawerProps = {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}
export function WorkspaceDrawer({ isOpen, setIsOpen }: WorkspaceDrawerProps) {
    const isMobile = useIsMobile(breakpoints.mobile.end)
    const { selectedStack } = useAppContext()
    const showingAppNav = !isMobile && selectedStack
    return (
        <>
            <Container
                popover
                className={WorkspaceDrawerStyle({
                    isOpen,
                    display: showingAppNav ? 'workspaceBarAndAppNav' : 'workspaceBar',
                })}
                style={{ zIndex: 1001 }}
            >
                <SidebarsContainer />
            </Container>
            {isOpen && (
                <Box
                    position="fixed"
                    onClick={() => setIsOpen(false)}
                    style={{
                        zIndex: 1000,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                />
            )}
        </>
    )
}
