import React, { forwardRef, memo, useState } from 'react'
import Linkify from 'react-linkify'

import removeMd from 'remove-markdown'

import QuillRichText from 'features/pages/blocks/blockTypes/form/QuillRichText'
import isRichTextField from 'utils/isRichTextField'

import { Box, Editable, MarkdownText, Textarea } from 'v2/ui'
import { layouts, modes } from 'v2/ui/utils/attributeSettings'

import DisplayText from './DisplayText'

const LongTextAttribute = forwardRef(
    (
        {
            field,
            mode,
            layout,
            size,
            singleLine,
            onChange,
            enableMarkdown,
            enableCopyPaste,
            borderColor,
            showRichTextEditor: alwaysShowRichTextEditor,
            convertToMarkdown,
            height,
            autofocus = false,
            placeholder,
            tabIndex,
            ...props
        },
        ref
    ) => {
        const [editValue, setEditValue] = useState(props.children || '')

        const handleTextareaChange = (e) => {
            onChange(e.target.value)
            setEditValue(e.target.value)
        }

        const handleQuillChange = (value) => {
            onChange(value)
            setEditValue(value)
        }

        // NOTE
        //
        // this extra flag for enabling the rich text editor for a rich text field is legacy
        // the rationale was to gradually turn on the feature in an opt-in manner for customers
        // that wanted it when it was launched
        //
        // for stacker data, we're removing this toggle (rich text field will always render a rich text editor)
        //
        // for airtable, we'll keep the check - there will still be apps created before we shipped rich text editor apps
        // with fields that may happen to be rich text but which customers do not want auto rendered as rich text fields
        //
        // adjacent TODO here -- we probably *should* turn the toggle off for new apps?
        //
        const showRichTextEditor = alwaysShowRichTextEditor || isRichTextField(field)

        const form =
            showRichTextEditor && enableMarkdown ? (
                <QuillRichText
                    ref={ref}
                    size={size}
                    onChange={handleQuillChange}
                    value={editValue}
                    borderColor={borderColor}
                    convertToMarkdown={convertToMarkdown}
                    height={height}
                    autofocus={autofocus}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                />
            ) : (
                <Textarea
                    forwardRef={ref}
                    resize="none"
                    width="100%"
                    margin="none"
                    size={size}
                    onChange={handleTextareaChange}
                    value={editValue}
                    variant={props.variant}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                />
            )

        const inline = layout === layouts.inline
        // In preview mode, show '-' if trimmed children is empty.
        let value = props.children
        if (typeof value !== 'string') {
            value = showRichTextEditor ? props.children : '-'
        } else {
            value = props.children?.trim() ? props.children : '-'
        }

        if (enableMarkdown) {
            if (inline || singleLine) {
                value = (
                    <Box
                        overflowWrap="anywhere"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        tabIndex={tabIndex}
                    >
                        {removeMd(value)}
                    </Box>
                )
            } else {
                // In preview mode, if value is '-', don't show RTE. Else, pass showRichTextEditor as the value.
                value = (
                    <MarkdownText
                        showRichTextEditor={value !== '-'}
                        background="transparent"
                        convertToMarkdown={convertToMarkdown}
                        tabIndex={tabIndex}
                    >
                        {value}
                    </MarkdownText>
                )
            }
        }

        const display =
            !singleLine && (showRichTextEditor || enableMarkdown) ? (
                value
            ) : (
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a target="_blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    <DisplayText
                        tabIndex={tabIndex}
                        maxWidth={enableCopyPaste ? '75%' : '100%'}
                        singleLine={singleLine}
                        maxLines={inline ? 3 : null}
                        overflow={inline ? 'hidden' : 'unset'}
                        whiteSpace={inline ? 'inherit' : 'pre-line'}
                    >
                        {value}
                    </DisplayText>
                </Linkify>
            )

        if (mode === modes.editable) {
            return (
                <Editable
                    input={({ end, ref }) =>
                        React.cloneElement(form, {
                            innerRef: ref,
                            onBlur: end,
                        })
                    }
                    display={() => display}
                    onChange={onChange}
                />
            )
        }
        if (mode === modes.editing) {
            return form
        }

        return display
    }
)

export default memo(LongTextAttribute)
