import React from 'react'

import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

type PercentageCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const PercentageCell: TableViewCellComponent<number, PercentageCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const {
        rawOptions: { percentageDisplay },
        field,
    } = column

    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <PercentageAttributeDisplay
                grow={percentageDisplay === 'percentBar'}
                value={value}
                valueDisplay={percentageDisplay}
                field={field}
                whiteSpace="nowrap"
                isLoading={isLoading}
            />
        </BaseCell>
    )
}
