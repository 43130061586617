// @ts-strict-ignore
import React, { CSSProperties } from 'react'

import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalProps,
} from '@chakra-ui/modal'
import classNames from 'classnames'

import Box from 'v2/ui/components/Box'
import Button from 'v2/ui/components/Button'
import Divider from 'v2/ui/components/Divider'
import Flex from 'v2/ui/components/Flex'
import Text from 'v2/ui/components/Text'
// @ts-ignore
import useLockBodyScroll from 'v2/ui/hooks/useLockBodyScroll'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'
import { useTheme } from 'ui/styling/themes/ThemeProvider'

import CloseButton from './CloseButton'

export type ModalAction = {
    label?: string
    onClick?: () => void
    variant?: string
    buttonSize?: string
    Component?: any
    [others: string]: any
}
export type StackerModalProps = Omit<ModalProps, 'children' | 'onClose'> & {
    title?: string | React.ReactNode
    headerVariant?: 'regular' | 'smallCentered' | 'largeCentered' | 'custom'
    body?: any
    actions?: ModalAction[]
    showCloseButton?: boolean
    closeButtonStyle?: CSSProperties
    isOpen: boolean
    onClose?: () => void
    textAlign?: string
    noPadding?: boolean
    scrollBehavior?: 'outside' | 'inside'
    isCentered?: boolean
    bodyStyle?: any
    actionsHorizontal?: boolean
    height?: string
    errorMessage?: any
    isHidden?: boolean
    isSecondLayer?: boolean
    noDividers?: boolean
    variant?: string
    padding?: string
    customOverlay?: any
    children?: any
    zIndex?: number
    contentProps?: Partial<React.ComponentPropsWithoutRef<typeof ModalContent>>
    containerProps?: Partial<React.ComponentPropsWithoutRef<typeof Box>>
    closeButtonProps?: Partial<React.ComponentPropsWithoutRef<typeof CloseButton>>
    dividerProps?: Partial<React.ComponentPropsWithoutRef<typeof Divider>>
    contentOverflow?: CSSProperties['overflow']
}

const StackerModal: React.FC<StackerModalProps> = ({
    title,
    headerVariant = 'regular',
    body,
    actions = [],
    isOpen,
    onClose = () => null,
    size = '370px',
    showCloseButton = true,
    closeButtonStyle,
    textAlign = 'center',
    noPadding = false,
    scrollBehavior = 'outside',
    children,
    isCentered = true,
    bodyStyle = {},
    actionsHorizontal,
    height,
    errorMessage,
    isSecondLayer, // Indicates that it is overlaying another modal.
    noDividers,
    padding,
    customOverlay,
    zIndex,
    contentProps,
    containerProps,
    closeButtonProps,
    dividerProps,
    contentOverflow,
    ...props
}) => {
    useLockBodyScroll(isOpen)

    let header
    switch (headerVariant) {
        case 'regular':
            header = (
                <>
                    <Flex
                        wrap="nowrap"
                        justify="space-between"
                        mt={-3}
                        mx={noPadding ? 3 : null}
                        my={noPadding ? 2 : null}
                    >
                        <Text variant="modalTitle">{title}</Text>

                        {showCloseButton && (
                            <CloseButton
                                data-testid="modal-close-button"
                                // @ts-ignore
                                style={closeButtonStyle}
                                onClick={onClose}
                                mr="-8px"
                                {...closeButtonProps}
                            />
                        )}
                    </Flex>
                    {!noDividers && <Divider my={2} {...dividerProps} />}
                </>
            )
            break
        case 'largeCentered':
            header = (
                <Flex wrap="nowrap" justify="space-between" mt={-3} alignItems="start">
                    <Flex column align="center" width="100%" ml={showCloseButton ? '34px' : '0'}>
                        <Text variant="modalLargeTitle">{title}</Text>
                    </Flex>

                    {showCloseButton && (
                        <CloseButton
                            data-testid="modal-close-button"
                            // @ts-ignore
                            style={closeButtonStyle}
                            onClick={onClose}
                            mr="-8px"
                            {...closeButtonProps}
                        />
                    )}
                </Flex>
            )
            break
        case 'smallCentered':
            header = (
                <>
                    <Flex
                        style={{
                            padding: '1px 10px',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                textAlign: 'center',
                                ...V4DesignSystem.h3,
                            }}
                        >
                            {title}
                        </div>
                        {showCloseButton && (
                            <CloseButton
                                data-testid="modal-close-button"
                                // @ts-ignore
                                style={closeButtonStyle}
                                onClick={onClose}
                                mr="-8px"
                                {...closeButtonProps}
                            />
                        )}
                    </Flex>
                    {!noDividers && <Divider {...dividerProps} />}
                </>
            )
            break
        case 'custom':
            header = (
                <>
                    <Flex
                        wrap="nowrap"
                        justify="space-between"
                        mt={-3}
                        mx={noPadding ? 3 : null}
                        my={noPadding ? 2 : null}
                    >
                        {title}

                        {showCloseButton && (
                            <CloseButton
                                data-testid="modal-close-button"
                                // @ts-ignore
                                style={closeButtonStyle}
                                onClick={onClose}
                                mr="-8px"
                                {...closeButtonProps}
                            />
                        )}
                    </Flex>
                    {!noDividers && <Divider mb={2} {...dividerProps} />}
                </>
            )
    }

    const overlayZIndex = zIndex || (isSecondLayer ? 1400 : 1300)

    const { themeClassName } = useTheme()

    return (
        <Modal
            size={size}
            motionPreset="slideInBottom"
            scrollBehavior={scrollBehavior}
            isCentered={isCentered}
            isOpen={isOpen}
            onClose={onClose}
            blockScrollOnMount={false}
            closeOnOverlayClick={false}
            closeOnEsc={showCloseButton}
            {...props}
        >
            {customOverlay ? (
                customOverlay
            ) : (
                <ModalOverlay zIndex={overlayZIndex} className={ONBOARDING_CLASSES.MODAL_OVERLAY} />
            )}

            <ModalContent
                boxSize={Array.isArray(size) ? size : [size]}
                padding={noPadding ? 0 : padding || 6}
                rounded="lg"
                height={height ? [height, null, null, height] : 'auto'}
                maxHeight={'95%'}
                overflow={contentOverflow ? contentOverflow : 'hidden'}
                containerProps={{
                    zIndex: overlayZIndex,
                    ...containerProps,
                }}
                {...contentProps}
                className={classNames(themeClassName, contentProps?.className)}
            >
                {(title || showCloseButton) && header}

                {body && (
                    <ModalBody flexGrow={1} textAlign={textAlign} {...bodyStyle}>
                        {body}
                    </ModalBody>
                )}
                {children}
                {actions.length > 0 && (
                    <>
                        <ModalFooter
                            display={actionsHorizontal ? 'flex' : 'block'}
                            alignItems="center"
                            padding={0}
                            textAlign="center"
                            flexWrap="wrap"
                            mt={actionsHorizontal ? 2 : 6}
                        >
                            {actions.map(
                                (
                                    { label, onClick, buttonSize, Component = Button, ...props },
                                    index
                                ) => (
                                    <Component
                                        key={index}
                                        buttonSize={buttonSize}
                                        onClick={onClick}
                                        width={actionsHorizontal ? 'auto' : '100%'}
                                        ml={actionsHorizontal ? 2 : 0}
                                        mb={actionsHorizontal ? 0 : 3}
                                        {...props}
                                    >
                                        {label}
                                    </Component>
                                )
                            )}
                            {errorMessage && (
                                <Text w="100%" variant="error" mt={4} mx={4}>
                                    {errorMessage}
                                </Text>
                            )}
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default StackerModal
