import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'

import { isFieldSupportedForGrouping } from 'v2/blocks/blockTypes/view/aggregationBlocks/utils/isFieldSupportedForGrouping'
import { ViewLayoutContext } from 'v2/blocks/types'
import { useGetLinkedFields } from 'v2/blocks/useGetLinkedFields'

import { useObject } from 'data/hooks/objects'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { Box, Checkbox, Dropdown, Flex, Input, Radio, Text } from 'v2/ui'

type DataPanelProps = {
    aggregationWidget: AggregateBlockAttributes
    onChange: (
        aggregationWidget: Pick<AggregateBlockAttributes, 'objectId'> &
            Partial<AggregateBlockAttributes>
    ) => void
    currentObject: ObjectDto
    context: ViewLayoutContext
}

type SelectedLinkOption = {
    label: string
    value: string
    objectId: string
    fieldId: string
    isExternalField: boolean
}

const listTypes = [
    { value: 'all', label: 'include all records' },
    { value: 'related', label: 'include only related records' },
]

export const DataPanel: FC<DataPanelProps> = ({
    aggregationWidget: { objectId, title: initialTitle, hideTitle, showRelated, relatedField },
    onChange,
    currentObject,
}) => {
    const onChangeObject = (newObjectId: string | undefined) => {
        onChange({ objectId: newObjectId || '' })
    }

    const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({ objectId, title: event.target.value })
    }

    const onChangeHideTitle = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({ objectId, hideTitle: event.target.checked })
    }

    const onChangeListType = (listTypeValue: string) => {
        onChange({ objectId, showRelated: listTypeValue === 'related' })
    }

    const onSelectLinkedField = (newLinkedFieldOption: SelectedLinkOption) => {
        onChange({ objectId, relatedField: newLinkedFieldOption })
    }

    const { object: targetObject } = useObject(objectId)
    const fieldFilter = useCallback(isFieldSupportedForGrouping, [])
    const [localLinkedFields, foreignLinkedFields] = useGetLinkedFields(
        currentObject,
        targetObject,
        fieldFilter
    )

    const availableFieldOptions = useMemo(
        () => [...localLinkedFields, ...foreignLinkedFields],
        [localLinkedFields, foreignLinkedFields]
    )

    return (
        <>
            <Box>
                <Text
                    as="label"
                    htmlFor="select-data-source"
                    variant="paletteSectionLabel"
                    display="block"
                >
                    Data source
                </Text>
                <ObjectPicker
                    inputId="select-data-source"
                    value={objectId}
                    onChange={onChangeObject}
                    placeholder="Select a data source…"
                />
            </Box>

            {currentObject && (
                <Box mt={2}>
                    <Radio
                        options={listTypes}
                        value={showRelated ? 'related' : 'all'}
                        size="md"
                        onChange={onChangeListType}
                    />
                </Box>
            )}

            {showRelated && (
                <Box mt={2}>
                    <Dropdown
                        value={relatedField?.value ? relatedField : undefined}
                        options={availableFieldOptions}
                        menuProps={{
                            width: '300px',
                        }}
                        placeholder="select link field"
                        onChange={(value: SelectedLinkOption) => onSelectLinkedField(value)}
                        allowHorizontalScroll
                        returnObject
                        style={{
                            fontSize: '14px',
                        }}
                        isClearable={false}
                        adminTheme
                    />
                </Box>
            )}

            <Box>
                <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Text
                        variant="paletteSectionLabel"
                        as="label"
                        htmlFor="metric-title"
                        display="block"
                    >
                        Title
                    </Text>
                    <Flex columnGap={1}>
                        <Checkbox
                            variant="admin"
                            id="hide-title"
                            checked={hideTitle}
                            onChange={onChangeHideTitle}
                        />
                        <Text as="label" htmlFor="hide-title">
                            Hide title
                        </Text>
                    </Flex>
                </Flex>
                <Input
                    key="metric-title"
                    id="metric-title"
                    value={initialTitle}
                    onChange={onChangeTitle}
                    placeholder="Enter a title…"
                />
            </Box>
        </>
    )
}
