import { useCallback, useLayoutEffect, useMemo } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'

import { useLayoutEditorCanvasKeyboardShortcuts } from './useLayoutEditorCanvasKeyboardShortcuts'

type UseLayoutEditorCanvasStateOptions = {
    viewSid: string
}

export function useLayoutEditorCanvasState(options: UseLayoutEditorCanvasStateOptions) {
    const { viewSid } = options

    const {
        commands,
        isInitialized,
        view,
        isEditing,
        selectedWidget,
        isViewDirty,
        isSchemaOutdated,
    } = useLayoutEditorContext()

    useLayoutEffect(() => {
        commands.initEditor({ viewSid })

        return () => {
            commands.closeEditor()
        }
    }, [commands, viewSid])

    const viewType = view?.type

    // Deselect the widget when clicking on the canvas.
    const onClick = useCallback(() => {
        if (isEditing && !!selectedWidget) {
            commands.deselectWidget()
        }
    }, [commands, isEditing, selectedWidget])

    useLayoutEditorCanvasKeyboardShortcuts()

    return useMemo(
        () => ({
            isInitialized,
            viewType,
            onClick,
            saveViewChanges: commands.saveViewChanges,
            discardViewChanges: commands.discardViewChanges,
            isViewDirty,
            isSchemaOutdated,
        }),
        [
            isInitialized,
            viewType,
            onClick,
            commands.saveViewChanges,
            commands.discardViewChanges,
            isViewDirty,
            isSchemaOutdated,
        ]
    )
}
