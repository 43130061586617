import React from 'react'

import { Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Box, Text } from 'v2/ui'

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`

const Overlay = styled.div<{ isLoading?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};

    transition: opacity 0.2s ease-out, transform 0.15s ease-out;
    transform: ${({ isLoading }) => (isLoading ? 'translate3d(0,0,0)' : 'translate3d(0,30px,0)')};
    opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
    pointer-events: ${({ isLoading }) => (isLoading ? 'all' : 'none')};
    user-select: none;
`

const Blocks = styled.div<{ isLoading?: boolean }>`
    width: 100%;
    transition: opacity 0.2s ease-out, filter 0.2s ease-out;
    height: 100%;
    opacity: ${({ isLoading }) => (isLoading ? 0.6 : 1)};
    filter: ${({ isLoading }) => (isLoading ? 'brightness(20%)' : 'none')};
`

type BlockLoaderProps = React.PropsWithChildren<{
    block: Block
    isLoading?: boolean
}>

export const BlockLoader: React.FC<BlockLoaderProps> = ({ block, isLoading, children }) => {
    const shouldDisplayLoader = block.type === 'container' && block.id === 'page' && isLoading

    return (
        <Wrapper>
            <Overlay isLoading={shouldDisplayLoader} className="stk-block-loader__overlay">
                <Box display="flex" flexDir="column" alignItems="center" justifyContent="center">
                    <Spinner aria-hidden="true" color="neutral.0" />
                    <Text mt={3} color="neutral.0">
                        Saving&#8230;
                    </Text>
                </Box>
            </Overlay>
            <Blocks isLoading={shouldDisplayLoader}>{children}</Blocks>
        </Wrapper>
    )
}
