import { getLocale } from 'utils/dateUtils'

const MAX_FRACTION_DIGITS = 2

export function formatPercentageValue(initialValue?: number): string {
    let value = initialValue
    if (typeof value !== 'number' || Number.isNaN(value)) {
        value = 0
    }

    const locale = getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
    })
    const formattedValue = formatter.format(value)

    return `${formattedValue}%`
}
