import React, { forwardRef, ReactNode } from 'react'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import {
    AvatarStyle,
    ComplexItemStyles,
    ComplexItemSubtitleStyle,
} from 'ui/components/Menu/Menu.css'
import { Body } from 'ui/components/Text'

import { MenuIcon, MenuIconComplex } from './Dropdown.parts'
import { DropdownCheckboxBase } from './DropdownCheckboxBase'
import { DropdownItemBase } from './DropdownItemBase'

type IconProps = IconNameVariantType
type AvatarProps = React.ComponentPropsWithoutRef<typeof Avatar>

type DropdownItemComplexRef = HTMLDivElement

type BaseProps = React.ComponentPropsWithoutRef<typeof DropdownItemBase> &
    React.ComponentPropsWithoutRef<typeof DropdownCheckboxBase>

type DropdownItemComplexProps = Omit<BaseProps, 'children' | 'leftSlotContent'> & {
    startIcon?: IconProps
    startAvatar?: AvatarProps
    label?: ReactNode
    subtitle?: ReactNode
    multiSelect?: boolean
    variant?: 'default'
}

export const DropdownItemComplex = forwardRef<DropdownItemComplexRef, DropdownItemComplexProps>(
    ({ startIcon, rightSlotContent, label, subtitle, startAvatar, multiSelect, ...props }, ref) => {
        const BaseComponent = multiSelect ? DropdownCheckboxBase : DropdownItemBase

        return (
            <BaseComponent
                {...props}
                ref={ref}
                leftSlotContent={
                    startIcon || startAvatar
                        ? () => (
                              <>
                                  {startIcon && (
                                      <MenuIconComplex variant={props.variant}>
                                          <MenuIcon size="m" {...startIcon} />
                                      </MenuIconComplex>
                                  )}
                                  {startAvatar && (
                                      <Avatar
                                          size="m"
                                          shape="circle"
                                          {...(startAvatar as any)}
                                          className={AvatarStyle}
                                      />
                                  )}
                              </>
                          )
                        : undefined
                }
                rightSlotContent={rightSlotContent}
                className={ComplexItemStyles.styleFunction()}
            >
                <Box flex flexDirection="column">
                    <Body size="m" weight="bold">
                        {label}
                    </Body>
                    <Body size="s" weight="regular" className={ComplexItemSubtitleStyle}>
                        {subtitle}
                    </Body>
                </Box>
            </BaseComponent>
        )
    }
)
