import React, { useContext, useMemo, useState } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'

import { useSortAvailableFields } from './hooks/useSortAvailableFields'
import { ListViewSort, ListViewSortType } from './types'

export type SortContextValue = {
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    availableFields: FieldDto[]
    sortType: ListViewSortType
    manualSortKey?: string
}

export const SortContext = React.createContext<SortContextValue | null>(null)

export function useSortContext(): SortContextValue {
    const context = useContext(SortContext)

    if (!context) {
        throw new Error('useSortContext must be used within a SortContextProvider')
    }

    return context
}

export type SortContextProviderProps = {}

export const SortContextProvider: React.FC<SortContextProviderProps> = ({ children }) => {
    const { view } = useListViewContext()

    const sortBy = view.options.order as ListViewSort | undefined
    const [sortByOverride, setSortByOverride] = useState<ListViewSort | undefined>(undefined)

    let effectiveSortBy = sortByOverride ?? sortBy
    if (!effectiveSortBy?.id) effectiveSortBy = undefined

    const availableFields = useSortAvailableFields()

    const sortType = view.options.orderType
    const manualSortKey = sortType === 'manual' ? `${view.stack_id}_${view._sid}` : undefined

    const value = useMemo(
        () => ({
            sortBy: effectiveSortBy,
            setSortBy: setSortByOverride,
            availableFields,
            sortType,
            manualSortKey,
            defaultSortBy: sortBy,
        }),
        [effectiveSortBy, availableFields, sortType, manualSortKey, sortBy]
    )

    return <SortContext.Provider value={value}>{children}</SortContext.Provider>
}
