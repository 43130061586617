import React from 'react'

export type TabsContextValue = {
    value: string
    setValue: (value: string) => void
    type: 'card' | 'underlined'
}

export const TabsContext = React.createContext<TabsContextValue>({
    value: '',
    setValue: () => {},
    type: 'card',
})

export function useTabsContext() {
    return React.useContext(TabsContext)
}

export type TabsProviderProps = {
    children: React.ReactNode
    value: string
    setValue: (value: string) => void
    type: 'card' | 'underlined'
}

export const TabsContextProvider: React.FC<TabsProviderProps> = ({
    value,
    setValue,
    type,
    children,
}) => {
    return (
        <TabsContext.Provider
            value={{
                value,
                setValue,
                type,
            }}
        >
            {children}
        </TabsContext.Provider>
    )
}
