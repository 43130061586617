import React from 'react'

import { useWorkflowExecutions } from 'data/hooks/workflows/workflows'
import { useWorkflowManagerContext } from 'features/workflows/WorkflowManagerContext'

import { Tooltip } from 'v2/ui'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

type ExecutionHistoryHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    workflow: WorkflowDto
}

export const ExecutionHistoryHeader: React.FC<ExecutionHistoryHeaderProps> = ({
    workflow,
    ...props
}) => {
    const { refetch, dataUpdatedAt } = useWorkflowExecutions({ flow_id: workflow._sid })

    const lastRefreshedTime = useDisplayTimeFrom(dataUpdatedAt)

    const { breadcrumbs, navigateBack } = useWorkflowManagerContext()

    const prevPane = breadcrumbs[breadcrumbs.length - 2]?.pane
    const backButtonText = prevPane === 'editorCanvas' ? workflow.name : 'Workflows'

    return (
        <Box flex center height="full" justifyContent="space-between" {...props}>
            <Button
                type="button"
                variant="ghost"
                startIcon={{ name: 'ArrowLeft' }}
                onClick={navigateBack}
                size="s"
            >
                {backButtonText}
            </Button>
            <Box as="h4" fontSize="headlineXs">
                Run history
            </Box>
            <Tooltip label={`last refreshed ${lastRefreshedTime}`} placement="bottom">
                <Button
                    type="button"
                    variant="ghost"
                    startIcon={{ name: 'RotateCcw' }}
                    onClick={() => refetch()}
                    size="s"
                />
            </Tooltip>
        </Box>
    )
}
