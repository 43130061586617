import React, { useCallback, useContext, useMemo, useState } from 'react'

import useDebounce from 'v2/ui/utils/useDebounce'

import { AppGroup } from './types'

const SEARCH_DEBOUNCE_RATE = 300 // ms

export type AppUsersPane = 'default' | 'createTableGroup' | 'updateTableGroup'

export type AppUsersContextValue = {
    pane: AppUsersPane
    setPane: (pane: AppUsersPane) => void
    resetPane: () => void
    searchQuery: string
    setSearchQuery: (searchQuery: string) => void
    selectedTableGroup?: AppGroup
    setSelectedTableGroup: (tableGroup?: AppGroup) => void
}

export const AppUsersContext = React.createContext<AppUsersContextValue | null>(null)

export function useAppUsersContext(): AppUsersContextValue {
    const context = useContext(AppUsersContext)

    if (!context) {
        throw new Error('useAppUsersContext must be used within a AppUsersContextProvider')
    }

    return context
}

export type AppUsersContextProviderProps = {}

export const AppUsersContextProvider: React.FC<AppUsersContextProviderProps> = ({ children }) => {
    const [currentPane, setCurrentPane] = useState<AppUsersPane>('default')
    const [selectedTableGroup, setSelectedTableGroup] = useState<AppGroup | undefined>(undefined)

    const resetPane = useCallback(() => {
        setCurrentPane('default')
        setSelectedTableGroup(undefined)
    }, [])

    const [searchQuery, setSearchQuery] = useState('')

    const debouncedSetSearchQuery = useDebounce(setSearchQuery, SEARCH_DEBOUNCE_RATE)

    const value = useMemo(
        () => ({
            pane: currentPane,
            setPane: setCurrentPane,
            resetPane,
            searchQuery,
            setSearchQuery: debouncedSetSearchQuery,
            selectedTableGroup,
            setSelectedTableGroup,
        }),
        [currentPane, debouncedSetSearchQuery, resetPane, searchQuery, selectedTableGroup]
    )

    return <AppUsersContext.Provider value={value}>{children}</AppUsersContext.Provider>
}
