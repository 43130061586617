import React, { useMemo } from 'react'

import { DocumentList } from 'v2/views/Document/DocumentList'

import { useAppContext } from 'app/AppContext'
import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'
import { TaskRelatedTo } from 'data/hooks/tasks/types'
import { ActivityFeed } from 'features/Activity/ActivityFeed'
import { RelatedTaskList } from 'features/tasks/RelatedTaskList'

import { Container } from 'v2/ui'

import { DetailViewTab } from './types'

type SystemTabProps = {
    tab: DetailViewTab
    record: RecordDto
    onlyTab: boolean
    stack: StackDto
    onActivityCreationDeletion: () => void
}
export function SystemTabContent({
    tab,
    record,
    onlyTab,
    stack,
    onActivityCreationDeletion,
}: SystemTabProps) {
    return (
        <div
            style={{
                // if it's the only tab, there is a margin issue
                marginTop: onlyTab ? 45 : 0,
            }}
        >
            {tab.type === 'activity' && (
                <ActivityTabContent
                    record={record}
                    stack={stack}
                    onActivityCreationDeletion={onActivityCreationDeletion}
                />
            )}
            {tab.type === 'tasks' && <TasksTabContent record={record} />}
            {tab.type === 'documents' && <DocumentsTabContent record={record} />}
        </div>
    )
}

const ACTIVITY_FEED_PARAMS = { types: [ActivityType.Comment] }
function ActivityTabContent({
    record,
    stack,
    onActivityCreationDeletion,
}: {
    record: RecordDto
    stack: StackDto
    onActivityCreationDeletion: () => void
}) {
    const stackId = stack?._sid
    const activityFeedTarget = useMemo(
        () => ({
            relatedTo: record._sid,
            relatedToType: RelatedToType.Record,
            objectId: record._object_id,
            stackId,
        }),
        [record._sid, record._object_id, stackId]
    )

    return (
        <ActivityFeed
            key={record._sid}
            contextKey={record._object_id}
            target={activityFeedTarget}
            params={ACTIVITY_FEED_PARAMS}
            showLocationPreview={true}
            onActivityCreationDeletion={onActivityCreationDeletion}
        />
    )
}

function TasksTabContent({ record }: { record: RecordDto }) {
    const { selectedStack } = useAppContext()
    return (
        <Container py={['container.padding', null, null, 'container.paddingLg']} mt={5} mb={4}>
            <RelatedTaskList
                related_to={record?._sid}
                related_to_stack={selectedStack?._sid}
                related_to_type={TaskRelatedTo.Record}
                _object_id={record?._object_id}
            />
        </Container>
    )
}

function DocumentsTabContent({ record }: { record: RecordDto }) {
    return (
        <Container py={['container.padding', null, null, 'container.paddingLg']} mt={5} mb={4}>
            <DocumentList recordId={record?._sid} />
        </Container>
    )
}
