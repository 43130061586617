import { useEffect } from 'react'

import userflow from 'userflow.js'

import { useAppUserContext } from 'app/AppUserContext'
import settings from 'app/settings'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { isPortalUser } from 'features/auth/utils/roleUtils'

export const UserflowIdentifier = () => {
    const { user, isPreviewingAsUserOrRole } = useAppUserContext()
    const { workspaceAccount } = useWorkspaceContext()

    useEffect(() => {
        if (!settings.USERFLOW_KEY || !user || isPortalUser(user)) return

        if (isPreviewingAsUserOrRole) {
            // once a user enters preview mode we want to hide userflow
            userflow.reset()
            return
        }

        userflow.init(settings.USERFLOW_KEY)
        userflow.identify(user._sid, {
            name: user.name,
            email: user.email,
            app_role: user?.role,
            account_role: user?.account_role_api_name,
        })
    }, [user, workspaceAccount, isPreviewingAsUserOrRole])

    return null
}
