import React from 'react'

import { isEqual } from 'lodash'

import { Frame } from 'features/core/Frame'

import { LayoutEditorCanvas } from './LayoutEditorCanvas'
import { LayoutEditorFrame } from './LayoutEditorFrame'

type LayoutEditorProps = {
    page: PageDto
    view: ViewDto
}

export const LayoutEditor: React.FC<LayoutEditorProps> = React.memo(function LayoutEditor({
    page,
    view,
}) {
    return (
        <Frame page={page} view={view} paddingOverride overrideShowBreadcrumbs={false}>
            <LayoutEditorFrame>
                <LayoutEditorCanvas viewSid={view._sid} />
            </LayoutEditorFrame>
        </Frame>
    )
},
isEqual)
