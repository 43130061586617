import React, { forwardRef, useMemo } from 'react'

import { Box } from 'ui/components/Box'
import { IconNameVariantType } from 'ui/components/Icon/Icon'
import { makeComponent } from 'ui/helpers/recipes'
import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { useTheme } from 'ui/styling/themes/ThemeProvider'

import { Icon } from './../Icon'

import { AvatarStyles, OnlineIconStyles } from './Avatar.css'

type IconProps = IconNameVariantType

const Container = makeComponent(Box, AvatarStyles)
const OnlineIndicator = makeComponent('div', OnlineIconStyles)

type BaseProps = Omit<React.ComponentProps<typeof Container>, 'type'> & {
    colorScheme?: ColorScheme
    online?: boolean
}

type InitialTypeProps = BaseProps & {
    type: 'initial'
    firstName: string
    lastName: string
}

type IconTypeProps = BaseProps & {
    type: 'icon'
    icon: IconProps
}

type ImageTypeProps = BaseProps & {
    type: 'image'
    imageUrl: string
}

type AvatarProps = InitialTypeProps | IconTypeProps | ImageTypeProps

export const Avatar: React.ForwardRefExoticComponent<AvatarProps> = forwardRef<
    HTMLDivElement,
    AvatarProps
>(({ type, shape = 'circle', size = 'm', online = false, style, ...props }, ref) => {
    const { firstName, lastName } = props as InitialTypeProps
    const { icon } = props as IconTypeProps
    const { imageUrl } = props as ImageTypeProps

    const { themeRawVariables } = useTheme()

    const iconSize = useMemo(() => {
        const avatarVariables = themeRawVariables.avatar

        return avatarVariables.size[`${size}Icon`] as React.ComponentPropsWithoutRef<
            typeof Icon
        >['size']
    }, [size, themeRawVariables.avatar])

    const initials = useMemo(
        () => formatInitials(firstName, lastName, size === 'xs'),
        [firstName, lastName, size]
    )

    return (
        <Container
            style={{
                ...style,
                backgroundImage: type === 'image' ? `url(${imageUrl})` : undefined,
            }}
            shape={shape}
            size={size}
            type={type}
            {...props}
            ref={ref}
        >
            {type === 'icon' && <Icon {...icon} size={iconSize} />}
            {type === 'initial' && <div>{initials}</div>}

            {online && <OnlineIndicator size={size} shape={shape} />}
        </Container>
    )
})

function formatInitials(
    firstName: string = '',
    lastName: string = '',
    onlyShowOneInitial = false
): string {
    const initials = [firstName.charAt(0).toUpperCase(), lastName.charAt(0).toUpperCase()].filter(
        Boolean
    )

    if (onlyShowOneInitial && initials.length > 0) return initials[0]

    return initials.join('')
}
