import React from 'react'

import styled from '@emotion/styled'

import { EditableFieldTypeDefinition } from 'features/admin/fields/definitions/editableFieldTypeDefinitions'
import {
    BaseFieldType,
    fieldBaseTypeMap,
} from 'features/admin/fields/definitions/fieldTypeDefinitions'

// This lists the field base data types which can be converted
// natively (ie., without possible data loss) to other base types
const supportedNativeDataTypeConversions: { [key in BaseFieldType]?: BaseFieldType[] } = {
    string: ['string_array'],
    number: ['string', 'string_array'],
    boolean: ['string', 'string_array'],
    date: ['string', 'string_array'],
}

type PartialConversionSupport = {
    targetType: BaseFieldType
    provideMessage: (
        sourceType: EditableFieldTypeDefinition,
        targetType: EditableFieldTypeDefinition
    ) => React.ReactNode | string
}

const FieldTypeLabel = styled('span')`
    text-decoration: underline;
    opacity: 0.6;
    font-weight: bold;
`

const partiallySupportedMessage = (
    _sourceType: EditableFieldTypeDefinition,
    targetType: EditableFieldTypeDefinition
) => (
    <>
        Converting to <FieldTypeLabel>{targetType.label}</FieldTypeLabel> might{' '}
        <strong>result in some data loss</strong>.
    </>
)

const partiallySupportedSelectingFirstValueMessage = (
    sourceType: EditableFieldTypeDefinition,
    targetType: EditableFieldTypeDefinition
) => (
    <>
        Converting this <FieldTypeLabel>{sourceType.label}</FieldTypeLabel> field to a{' '}
        <FieldTypeLabel>{targetType.label}</FieldTypeLabel> field may cause loss of data. Where
        there are multiple values selected, <strong>only the first value will be preserved</strong>.
    </>
)

const partiallySupportedNativeDataTypeConversions: {
    [key in BaseFieldType]?: PartialConversionSupport[]
} = {
    string_array: [
        {
            targetType: 'string',
            provideMessage: partiallySupportedSelectingFirstValueMessage,
        },
    ],
    string: [
        {
            targetType: 'number',
            provideMessage: partiallySupportedMessage,
        },
        {
            targetType: 'boolean',
            provideMessage: partiallySupportedMessage,
        },
        {
            targetType: 'date',
            provideMessage: partiallySupportedMessage,
        },
    ],
}

const UnsupportedMessageComponent = (
    <>
        Converting to this field type will <strong>remove all existing data in this field</strong>.
    </>
)

type ConversionAnalysis = {
    fullySupported: boolean
    exactMatch?: boolean
    warningMessage?: React.ReactNode | string
}

export const canConvertBetweenFieldTypes = (
    sourceType: EditableFieldTypeDefinition,
    targetType: EditableFieldTypeDefinition
): ConversionAnalysis => {
    const SUPPORTED: ConversionAnalysis = { fullySupported: true, exactMatch: true }
    const CANNOT_CONVERT: ConversionAnalysis = {
        fullySupported: false,
        warningMessage: UnsupportedMessageComponent,
    }
    const sourceFieldType = sourceType.field_template.type
    const targetFieldType = targetType.field_template.type
    // Can convert if fields are of the same type, or the source field doesn't have a type
    // meaning it is a dynamic field, and thus there is no data to lose so conversion is always possible
    if (targetFieldType === sourceFieldType || !sourceFieldType) {
        return SUPPORTED
    }

    // If we're converting to a dynamic type, then this is will result in full data loss
    if (!targetFieldType) {
        return CANNOT_CONVERT
    }

    // Cannot convert to/from attachment fields
    if (
        (sourceFieldType === 'multi_file' && targetFieldType !== 'multi_file') ||
        (sourceFieldType !== 'multi_file' && targetFieldType === 'multi_file')
    ) {
        return CANNOT_CONVERT
    }

    // conversion between document and string/long_text is manually handled despite the base types being different
    const isConversionBetweenDocumentAndText =
        ['string', 'long_text', 'document'].includes(sourceFieldType) &&
        ['string', 'long_text', 'document'].includes(targetFieldType)

    const baseType = fieldBaseTypeMap[sourceFieldType]
    const targetBaseType = fieldBaseTypeMap[targetFieldType]

    // If the field types are of the same base type, or
    // our current base type can be converted into the target field base type,
    // then we can fully convert the data
    const result: ConversionAnalysis = {
        fullySupported:
            !!baseType &&
            !!targetBaseType &&
            (targetBaseType === baseType ||
                !!supportedNativeDataTypeConversions[baseType]?.includes(targetBaseType) ||
                isConversionBetweenDocumentAndText),
        exactMatch: !!baseType && !!targetBaseType && targetBaseType === baseType,
    }

    let matchingPartialTarget: PartialConversionSupport | undefined = undefined
    if (!result.fullySupported && baseType) {
        matchingPartialTarget = partiallySupportedNativeDataTypeConversions?.[baseType]?.find(
            (x) => x.targetType === targetBaseType
        )
    }

    result.warningMessage =
        matchingPartialTarget?.provideMessage(sourceType, targetType) || UnsupportedMessageComponent

    // in case of conversion to/from multi-link we have some additional behaviour due to interaction with junction tables
    // so we have to modify the warningMessage despite what the base types were
    if (sourceFieldType == 'multi_lookup' && targetFieldType != 'multi_lookup') {
        result.fullySupported = false
        if (targetFieldType == 'lookup') {
            result.warningMessage = partiallySupportedSelectingFirstValueMessage(
                sourceType,
                targetType
            )
        } else {
            // conversion from multi_lookup to anything but a single lookup will result in full data loss
            result.warningMessage = UnsupportedMessageComponent
        }
    } else if (
        targetFieldType == 'multi_lookup' &&
        !['multi_lookup', 'lookup'].includes(sourceFieldType)
    ) {
        // converting from anything but lookup to multi_lookup will result in full data loss
        result.fullySupported = false
        result.warningMessage = UnsupportedMessageComponent
    }

    // converting any string-based field to a lookup results in existing values getting filtered out via LVF
    // so we want to show a warning for full data loss
    if (baseType == 'string' && targetFieldType == 'lookup' && sourceFieldType != 'lookup') {
        result.exactMatch = false
        result.fullySupported = false
        result.warningMessage = UnsupportedMessageComponent
    }

    return result
}
