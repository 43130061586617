import React from 'react'

class WithBlocks extends React.Component {
    render() {
        const blocksData = {}
        const blocks = Object.values(blocksData)
        return this.props.children({
            blocks,
            blockEntities: {},
            onChange: () => null,
            createBlock: () => null,
        })
    }

    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.dontUpdate === true) return false
        return true
    }
}

WithBlocks.propTypes = {}

export default WithBlocks
