import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    DragEndEvent,
    DragOverEvent,
    DragStartEvent,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewColumn } from 'features/views/ListView/BoardView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useToast } from 'ui/components/Toast'

export function useBoardViewContentState() {
    const {
        columns,
        records,
        statusField,
        updateRecord,
        allowReorder,
        sortBy,
        manualSortKey,
        errorMessage,
    } = useBoardViewContext()

    const toast = useToast()
    useEffect(() => {
        if (!errorMessage) return

        // If there is a problem with creating or updating a record, show this error message.
        toast({
            title: errorMessage,
            type: 'error',
            showDismissButton: true,
            startIcon: { name: 'AlertCircle' },
            helperText: 'Please try again later. If the issue persists, contact support.',
        })
    }, [errorMessage, toast])

    const allowReorderRef = useRef(allowReorder)
    allowReorderRef.current = allowReorder

    const statusFieldApiName = statusField?.api_name

    const [activeRecordSid, setActiveRecordSid] = useState<string | undefined>()
    const activeRecord = useMemo(() => {
        return records?.find((record) => record._sid === activeRecordSid)
    }, [activeRecordSid, records])
    const activeRecordRef = useRef(activeRecord)
    activeRecordRef.current = activeRecord

    const [activeRecordPatch, setActiveRecordPatch] = useState<Partial<RecordDto>>({})

    const patchedRecords = useMemo(() => {
        const newRecords = records?.map((record) => {
            // Add the patch to the active record.
            if (record._sid === activeRecord?._sid) {
                return { ...record, ...activeRecordPatch }
            }
            return record
        })

        const allowReorder = allowReorderRef.current
        if (allowReorder) {
            // Sort records based on the display order.
            newRecords?.sort((a, b) => {
                if (typeof a._local_display_order !== 'number') {
                    return 1
                } else if (typeof b._local_display_order !== 'number') {
                    return -1
                }

                return a._local_display_order - b._local_display_order
            })
        }

        return newRecords
    }, [activeRecord?._sid, activeRecordPatch, records])
    const patchedRecordsRef = useRef(patchedRecords)
    patchedRecordsRef.current = patchedRecords

    const columnsByStatus = useMemo(() => {
        return columns.reduce((acc, column) => {
            return acc.set(column.value, column)
        }, new Map<string | null, BoardViewColumn>())
    }, [columns])

    const columnsById = useMemo(() => {
        return columns.reduce((acc, column) => {
            return acc.set(column.id, column)
        }, new Map<string, BoardViewColumn>())
    }, [columns])

    const recordsForStatuses = useMemo(() => {
        if (!patchedRecords || !statusFieldApiName) return undefined

        return patchedRecords.reduce((acc, record) => {
            const status = record[statusFieldApiName] as string | undefined

            const column = status ? columnsByStatus.get(status) : columnsByStatus.get(null)
            if (!column) return acc

            const existingColRecords = acc[column.id] ?? []

            return {
                ...acc,
                [column.id]: [...existingColRecords, record],
            }
        }, {} as Record<string, RecordDto[]>)
    }, [columnsByStatus, patchedRecords, statusFieldApiName])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                // This lets us open the record side peek when clicking on a record, but still keeps the dragging functionality working in the same way.
                distance: 5,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                // This lets us scroll columns vertically, but still keeps the dragging functionality working in the same way.
                delay: 300,
                tolerance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    const onDragStart = useCallback((event: DragStartEvent) => {
        const { active } = event

        setActiveRecordSid(active.id.toString())
    }, [])

    const onDragOver = useCallback(
        (event: DragOverEvent) => {
            if (!statusFieldApiName) return

            const targetColumnId = getTargetColumnId(event)
            if (!targetColumnId) return

            const targetColumn = columnsById.get(targetColumnId)
            if (!targetColumn) return

            requestAnimationFrame(() => {
                // Drag records across columns.
                setActiveRecordPatch({
                    [statusFieldApiName]: targetColumn.value,
                })
            })
        },
        [columnsById, statusFieldApiName]
    )

    const onDragEnd = useCallback(
        (event: DragEndEvent) => {
            if (!statusFieldApiName) return

            const { over } = event
            if (!over) return

            const activeRecord = activeRecordRef.current
            if (!activeRecord) return

            const targetColumnId = getTargetColumnId(event)
            if (!targetColumnId) return

            const targetColumn = columnsById.get(targetColumnId)
            if (!targetColumn) return

            const patch: Partial<RecordDto> = {
                [statusFieldApiName]: targetColumn.value,
            }

            const allowReorder = allowReorderRef.current
            if (allowReorder) {
                const records = patchedRecordsRef.current ?? []

                const columnRecords = records.filter((record) => {
                    if (record._sid === activeRecord._sid) return false

                    const status = record[statusFieldApiName] as string | undefined
                    return status === targetColumn.value
                })

                // The new index of the record.
                const targetIdx = over.data.current?.sortable?.index ?? 0

                const shouldMoveAbove = targetIdx < columnRecords.length

                // The record in relation to we are moving the active record above or below.
                const targetRecordSid = shouldMoveAbove
                    ? columnRecords[targetIdx]?._sid
                    : columnRecords[columnRecords.length - 1]?._sid
                const targetRecordListIdx = records.findIndex(
                    (record) => record._sid === targetRecordSid
                )
                const targetRecord = records[targetRecordListIdx]

                let tempDisplayOrder: number | null = null
                if (targetRecord) {
                    if (shouldMoveAbove) {
                        if (targetRecordListIdx > 0) {
                            // The record above the target record.
                            const adjacentItem = records[targetRecordListIdx - 1]
                            tempDisplayOrder =
                                targetRecord._local_display_order -
                                (targetRecord._local_display_order -
                                    adjacentItem._local_display_order) /
                                    2
                        } else {
                            tempDisplayOrder = targetRecord._local_display_order - 1
                        }
                    } else {
                        if (targetRecordListIdx < records.length - 1) {
                            // The record below the target record.
                            const adjacentItem = records[targetRecordListIdx + 1]

                            tempDisplayOrder =
                                targetRecord._local_display_order +
                                (adjacentItem._local_display_order -
                                    targetRecord._local_display_order) /
                                    2
                        } else {
                            tempDisplayOrder = targetRecord._local_display_order + 1
                        }
                    }
                }

                patch[shouldMoveAbove ? '_display_order_before' : '_display_order_after'] =
                    targetRecord?._sid
                patch._display_order_key = targetRecord ? manualSortKey : undefined

                patch._local_display_order = tempDisplayOrder

                const orderBy = sortBy ? `${sortBy.desc ? '-' : ''}${sortBy.id}` : undefined
                patch._display_order_by = orderBy
            }

            setActiveRecordPatch(patch)

            // Apply the patch to the record.
            updateRecord(activeRecord, patch)

            setActiveRecordPatch({})
            setActiveRecordSid(undefined)
        },
        [columnsById, manualSortKey, sortBy, statusFieldApiName, updateRecord]
    )

    const onDragCancel = useCallback(() => {
        setActiveRecordPatch({})
        setActiveRecordSid(undefined)
    }, [])

    return useDeepEqualsMemoValue({
        columns,
        sensors,
        onDragStart,
        onDragEnd,
        onDragOver,
        onDragCancel,
        activeRecord,
        records: recordsForStatuses,
    })
}

function getTargetColumnId(event: DragOverEvent | DragEndEvent) {
    const { over } = event
    if (!over || over.disabled) return

    let targetColumnId: string | undefined
    if (over.data.current?.type === 'column') {
        // We are dragging over an empty column.
        targetColumnId = over.data.current.id
    } else {
        // We are dragging over a record in a column.
        targetColumnId = over.data.current?.sortable?.containerId
    }

    return targetColumnId
}
