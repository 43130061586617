import React, { useCallback, useRef } from 'react'

import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { theme } from 'ui/styling/Theme.css'

import { ListHeaderDefaultControls } from './ListHeaderDefault/ListHeaderDefaultControls'
import { ListHeaderPortalControls } from './ListHeaderPortal/ListHeaderPortalControls'

type ListHeaderControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
}

export const ListHeaderControls: React.FC<ListHeaderControlsProps> = ({ setConfig }) => {
    const { header } = useListViewContext()
    const headerRef = useRef(header)
    headerRef.current = header

    const updateHeader = useCallback(
        (newHeader: Partial<ListViewHeader>) => {
            const header = headerRef.current

            setConfig({
                listHeader: {
                    ...header,
                    ...newHeader,
                } as ListViewHeader,
            })
        },
        [setConfig]
    )

    return (
        <ListViewControlItem label="Header" icon="LayoutPanelTop">
            <Box width="300px" flex flexDirection="column" gap="l">
                <HeaderTypeControls updateHeader={updateHeader} header={header} />
                {header.type === 'default' && (
                    <ListHeaderDefaultControls updateHeader={updateHeader} header={header} />
                )}
                {header.type === 'portal' && (
                    <ListHeaderPortalControls updateHeader={updateHeader} header={header} />
                )}
            </Box>
        </ListViewControlItem>
    )
}

type HeaderTypeControlsProps = {
    header: ListViewHeader
    updateHeader: (header: Partial<ListViewHeader>) => void
}

const HeaderTypeControls: React.FC<HeaderTypeControlsProps> = ({ updateHeader, header }) => {
    return (
        <Field label="Type">
            <RadioCardGroup
                value={header.type}
                onValueChange={(value: string) => {
                    updateHeader({
                        type: value as ListViewHeader['type'],
                    })
                }}
                style={{
                    display: 'grid',
                    gridTemplateColumns: '82px 82px',
                    gap: theme.space.l,
                }}
            >
                <RadioCard
                    value="default"
                    icon={() => <HeaderTypeDefaultIcon isActive={header.type === 'default'} />}
                    cardIconWrapperProps={{
                        style: { padding: 0, overflow: 'hidden' },
                    }}
                >
                    Compact
                </RadioCard>
                <RadioCard
                    value="portal"
                    icon={() => <HeaderTypePortalIcon isActive={header.type === 'portal'} />}
                    cardIconWrapperProps={{
                        style: { padding: 0, overflow: 'hidden' },
                    }}
                >
                    Spacious
                </RadioCard>
            </RadioCardGroup>
        </Field>
    )
}

type HeaderTypeIconProps = {
    isActive: boolean
}

const HeaderTypeDefaultIcon: React.FC<HeaderTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                fill: theme.color.surface,
            }}
        >
            <rect
                x="7.54285"
                y="8.48572"
                width="26.4"
                height="3.77143"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                opacity="0.6"
                x="66"
                y="8"
                width="9"
                height="4"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme300 : theme.color.gray100,
                }}
            />
            <rect
                x="8.0144"
                y="16.9856"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                stroke="#E8EAEC"
                strokeWidth="0.942857"
            />
            <rect
                x="8.0144"
                y="40.5574"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                stroke="#E8EAEC"
                strokeWidth="0.942857"
            />
        </svg>
    )
}

const HeaderTypePortalIcon: React.FC<HeaderTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                fill: theme.color.surface,
            }}
        >
            <rect x="0.0288086" width="82.0286" height="59.4" rx="4.71429" fill="white" />
            <rect
                x="0.0288086"
                y="0"
                width="82.0286"
                height="24.5143"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                x="8"
                y="22"
                width="67"
                height="7"
                style={{
                    fill: theme.color.gray100,
                }}
            />
            <rect opacity="0.8" x="8" y="8.48535" width="26.4" height="3.77143" fill="white" />
            <rect opacity="0.4" x="8" y="16.2568" width="47.1429" height="1.88571" fill="white" />
            <rect
                x="8.47192"
                y="32.6428"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                stroke="#E8EAEC"
                strokeWidth="0.942857"
            />
            <rect
                x="8.04321"
                y="54.2143"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                stroke="#E8EAEC"
                strokeWidth="0.942857"
            />
        </svg>
    )
}
