import React, {
    ComponentPropsWithoutRef,
    ComponentPropsWithRef,
    ElementType,
    forwardRef,
} from 'react'

import { ComplexStyleRule } from '@vanilla-extract/css'
import { recipe, RuntimeFn } from '@vanilla-extract/recipes'

import {
    composeClassNames,
    extractProps,
    StandardComponentProps,
    transformStandardProps,
} from './styles'

// These types are copied from vanilla-extract/recipes as they are not
// exported from the package.
export type RecipeStyleRule = ComplexStyleRule | string
export type VariantDefinitions<T extends string = string> = Record<T, RecipeStyleRule>
type BooleanMap<T> = T extends 'true' | 'false' ? boolean : T

type VariantGroups = Record<string, VariantDefinitions>
export type VariantSelection<Variants extends VariantGroups = VariantGroups> = {
    [VariantGroup in keyof Variants]?: BooleanMap<keyof Variants[VariantGroup]>
}

export type CompoundVariant<Variants extends VariantGroups = VariantGroups> = {
    variants: VariantSelection<Variants>
    style: RecipeStyleRule
}
type PatternOptions<Variants extends VariantGroups> = {
    base?: RecipeStyleRule
    variants?: Variants
    defaultVariants?: VariantSelection<Variants>
    compoundVariants?: Array<CompoundVariant<Variants>>
}

type PreparedRecipe<Variants extends VariantGroups, TStyleFn extends RuntimeFn<Variants>> = {
    styleFunction: TStyleFn
    variants: { [key in keyof Variants]: string[] }
}

// creates a recipe from the supplied options, and also returns a list of the recipe
// variant names, which is by make to correctly extract the variant values and
// pass through to the style function.
export function createRecipe<
    Variants extends VariantGroups,
    TStyleFn extends RuntimeFn<Variants>,
    TVariantDefiniton extends { [key in keyof Variants]: string[] }
>(options: PatternOptions<Variants>): PreparedRecipe<Variants, TStyleFn> {
    const styleFunction = recipe(options)
    const variants = Object.entries(options?.variants ?? {}).reduce(
        (result, [key, value]) => ({ ...result, [key as keyof Variants]: Object.keys(value) }),
        {}
    )

    return {
        styleFunction: styleFunction as TStyleFn,
        variants: variants as TVariantDefiniton,
    }
}

// Takes a supplied component and PreparedRecipe and
// creates a component type with the recipe variant props and standard component props added.
// When that component is rendered, it extracts the supplied variant props
// and passes those to the style function to create the styles for the recipe
// and passes those class names to the created element.
export const makeComponent = <
    T extends ElementType,
    Variants extends VariantGroups,
    TStyleFn extends RuntimeFn<Variants>,
    TProps extends Partial<ComponentPropsWithoutRef<T> & Parameters<TStyleFn>[0]> &
        StandardComponentProps
>(
    component: T,
    { styleFunction: recipeFunction, variants }: PreparedRecipe<Variants, TStyleFn>,
    specifiedProps?: TProps,
    passThroughProps?: (keyof TProps)[]
) => {
    const name = `${typeof component === 'string' ? component : ''}`

    type ComponentProps = ComponentPropsWithRef<T>
    // Add the variant props to the props type
    type VariantArgs = VariantSelection<Variants>
    type AddedProps = VariantArgs & StandardComponentProps
    // Need to drop any props from the component type that are being
    // added via variants or standard props, to avoid conflicts.
    type ResultProps = Omit<ComponentProps, keyof AddedProps> & AddedProps

    const result = forwardRef<React.ElementRef<T>, ResultProps>((props: ResultProps, ref) => {
        const combinedProps = { ...props, ...specifiedProps }
        // extract any variant props
        const [variantArgs, otherProps] = extractProps(
            combinedProps,
            {} as VariantArgs,
            new Set(Object.keys(variants)),
            new Set(passThroughProps as string[])
        )
        // transform any standard props to classes
        const { className, ...finalProps } = transformStandardProps(otherProps)

        const Component = component

        return (
            // TODO: fix types
            // @ts-expect-error
            <Component
                ref={ref}
                {...(finalProps as ComponentProps)}
                className={composeClassNames(className, recipeFunction(variantArgs))}
            />
        )
    })
    result.displayName = name
    return result
}
