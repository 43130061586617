import {
    ColorScheme,
    colorSchemeNames,
    ColorTone,
} from 'ui/styling/baseVariables/colors/colorPalette'
import { paletteTheme } from 'ui/styling/baseVariables/colors/PaletteTheme.css'

export function selectPaletteThemeColorsByTone<
    TPrefix extends string = '',
    TSuffix extends string = ''
>(tone: ColorTone, prefix: TPrefix = '' as TPrefix, suffix: TSuffix = '' as TSuffix) {
    return colorSchemeNames.reduce(
        (result, colorScheme) => ({
            ...result,
            [`${prefix}${colorScheme}${suffix}`]: paletteTheme[colorScheme.toLowerCase() + tone],
        }),
        {}
    ) as { [K in `${TPrefix}${ColorScheme}${TSuffix}`]: string }
}
