import React, { useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { publishSharedTable } from 'data/hooks/objects/objectOperations'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'
import { Divider, ExpandSection } from 'legacy/v1/ui'

import { Box, Button, Checkbox, Text, Toast } from 'v2/ui'

type ShareTablesSettingsProps = {
    object: ObjectDto
}
export const ShareTablesSettings: React.FC<ShareTablesSettingsProps> = ({ object }) => {
    const [hasShareError, setHasShareError] = useState(false)
    const isSharingDisabled = getIsStackerUserObject(object)

    return (
        <>
            {/* @ts-expect-error */}
            <Divider margin="none" />
            <ExpandSection
                heading="Sharing settings"
                text="Manage how this table is shared within the workspace."
                helpUrl=""
                helpText=""
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Text fontWeight="bold" color="neutral.900">
                            Share table with workspace
                        </Text>
                        <Text size="xs" color="neutral.800">
                            {isSharingDisabled
                                ? "The user table can't be shared."
                                : 'Share this table with the workspace so that it can be used in other apps.'}
                        </Text>
                    </Box>
                    <ShareTableButton
                        object={object}
                        isDisabled={isSharingDisabled}
                        onShareSuccess={() => setHasShareError(false)}
                        onShareError={() => setHasShareError(true)}
                    />
                    <Toast
                        status="error"
                        show={hasShareError}
                        title="Sharing object failed. Please try again."
                        // clear the state on the toast closing so if there's an error again, the toast is shown again
                        onCloseComplete={() => setHasShareError(false)}
                    />
                </Box>
            </ExpandSection>
        </>
    )
}

type ShareTableButtonProps = {
    object: ObjectDto
    isDisabled: boolean
    onShareSuccess: () => void
    onShareError: () => void
}

const ShareTableButton: React.FC<ShareTableButtonProps> = ({
    object,
    isDisabled,
    onShareSuccess,
    onShareError,
}) => {
    const { workspaceAccount } = useAppContext()
    const sharedResourcesStackSid = workspaceAccount?.options?.shared_resources_stack

    function shareTable() {
        return publishSharedTable(
            object._sid,
            sharedResourcesStackSid,
            onShareSuccess,
            onShareError
        )
    }

    if (object?.connection_options?.shared_object_id) {
        return (
            <Checkbox isDisabled isChecked={!!object?.connection_options?.shared_object_id}>
                This table is shared and available to be added to other apps.
            </Checkbox>
        )
    }
    return (
        <Button
            buttonSize="medium"
            variant="Primary"
            onClick={shareTable}
            alignSelf="start"
            isDisabled={isDisabled}
        >
            Share
        </Button>
    )
}
