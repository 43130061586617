import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'

import { RecordSearchQueryOptions } from 'data/api/recordApiTypes'
import { useRecordCount, useRecords } from 'data/hooks/records'
import {
    isUsersRecord,
    useUserRecordLinksAvatars,
} from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { useFiltersContext } from 'features/views/ListView/Filters/FiltersContext'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { usePaginationContext } from 'features/views/ListView/Pagination/PaginationContext'
import { useSearchContext } from 'features/views/ListView/Search/SearchContext'
import { useSortContext } from 'features/views/ListView/Sort/SortContext'
import { getDereferencedMultiLookupSids } from 'features/views/ListView/TableView/utils'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useIncludedFields } from './useIncludedFields'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 3000 // 3 seconds

type UseListViewRecordsOptions = {
    additionalFieldApiNames?: string[]
    additionalFilters?: Filter[]
    disableRealtimeUpdates?: boolean
    allowManualSort?: boolean
}

export function useListViewRecords(options: UseListViewRecordsOptions = {}) {
    const { additionalFieldApiNames, additionalFilters, disableRealtimeUpdates, allowManualSort } =
        options

    const { object, isEditMode, searchFields } = useListViewContext()

    const includedFields = useIncludedFields(additionalFieldApiNames)

    const includedFieldsApiNames = useMemo(
        () => includedFields.map((field) => field.api_name),
        [includedFields]
    )

    const dereferencedMultiLookupSids = useMemo(
        () => getDereferencedMultiLookupSids(includedFields),
        [includedFields]
    )

    const { filters } = useFiltersContext()
    const { query } = useSearchContext()
    const { sortBy, manualSortKey } = useSortContext()
    const paginationContext = usePaginationContext()

    const allFilters = useMemo(
        () => [
            ...(additionalFilters ?? []),
            ...filters,
            // Add search filter if query is present.
            getSearchFilter(query),
        ],
        [additionalFilters, filters, query]
    )

    const prevFilters = useRef<Filter[]>([])
    const [effectiveFilters, setEffectiveFilters] = useState(allFilters)
    useEffect(() => {
        // Only update the effective filters if the filters have changed.
        // Doing this in a separate frame to make rendering more efficient.
        if (!isEqual(prevFilters.current, allFilters)) {
            requestAnimationFrame(() => {
                setEffectiveFilters(allFilters)
                prevFilters.current = allFilters
            })
        }
    }, [allFilters])

    const fetchOptions: RecordSearchQueryOptions = {
        dereference: true,
        dereferenceMultiFields: dereferencedMultiLookupSids,
        includeFields: includedFieldsApiNames,
        searchFields,
        disablePartials: isEditMode,
        orderBy: sortBy,
        manualOrderKey: allowManualSort ? manualSortKey : undefined,
    }

    if (paginationContext) {
        fetchOptions.pageIndex = paginationContext.currentPageIndex
        fetchOptions.pageSize = paginationContext.pageSize
    }

    const fetchOptionsMemo = useDeepEqualsMemoValue(fetchOptions)

    const { data, isError, refetch, isFetching } = useRecords({
        objectSid: object._sid,
        filters: effectiveFilters,
        fetchOptions: fetchOptionsMemo,
        schemaTimestamp: object.schemaTimestamp,
        disableRealtimeUpdates: disableRealtimeUpdates,
    })

    const memoizedRecords = useDeepEqualsMemoValue(data?.records as RecordDto[] | undefined)
    const dereferencedRecords = useDereferencedRecords(data?.dereferencedRecords, data?.records)

    const {
        data: recordCount,
        isError: isCountError,
        refetch: refetchCount,
    } = useRecordCount({
        objectSid: object._sid,
        fetchOptions: { includeFields: fetchOptions.includeFields, searchFields },
        filters: effectiveFilters,
    })

    const hasError = isError || isCountError

    const retryFetchRecords = useCallback(async () => {
        await Promise.all([refetch(), refetchCount()])
    }, [refetch, refetchCount])

    const isLoading = !memoizedRecords
    const [isLoadingSlow, setIsLoadingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isLoading) {
            timer = window.setTimeout(() => {
                setIsLoadingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsLoadingSlow(false)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [isLoading])

    const [isFetchingSlow, setisFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setisFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setisFetchingSlow(false)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [isFetching])

    return useMemo(
        () => ({
            records: memoizedRecords,
            dereferencedRecords,
            recordCount,
            hasError,
            retryFetchRecords,
            isLoading,
            isFetchingSlow,
            isLoadingSlow,
            effectiveFilters,
            includedFieldsApiNames,
        }),
        [
            hasError,
            dereferencedRecords,
            memoizedRecords,
            recordCount,
            retryFetchRecords,
            isLoading,
            isFetchingSlow,
            isLoadingSlow,
            effectiveFilters,
            includedFieldsApiNames,
        ]
    )
}

function getSearchFilter(query: string): Filter {
    return {
        field: {
            api_name: '_search',
        },
        options: {
            value: query,
        },
    }
}

function useDereferencedRecords(records?: RecordDto[], extraRecords?: RecordDto[]): RecordDto[] {
    // Dereferenced users don't have avatars, so we need to fetch them separately.
    const usersObject = useStackerUsersObject()
    const userRecordSids = records?.reduce((acc, record) => {
        if (isUsersRecord(record, usersObject)) {
            acc.push(record._sid)
        }

        return acc
    }, [] as string[])

    const { records: userRecords } = useUserRecordLinksAvatars(userRecordSids)
    const userRecordsMap = userRecords?.reduce(
        (acc, record) => acc.set(record._sid, record),
        new Map<string, RecordDto>()
    )

    const allRecords = [...(records ?? []), ...(extraRecords ?? [])]
    const finalDereferencedRecords = allRecords.map((record) => {
        const userOverride = userRecordsMap?.get(record._sid)
        if (userOverride) {
            return userOverride
        }

        return record
    })

    return useDeepEqualsMemoValue(finalDereferencedRecords)
}
