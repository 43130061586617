import React, { useEffect, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import queryString from 'qs'
import { signInAndUp } from 'supertokens-auth-react/recipe/thirdparty'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { GlobalStaticState } from 'app/GlobalStaticState'
import { getUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useGetAccountAndZone } from 'data/hooks/zones'

import { LoadingSplash } from 'v2/ui'

export function ThirdPartyCallbackPage() {
    const { user } = useAuthContext()
    const { workspaceUser } = useWorkspaceContext()

    const { slug } = useParams()
    const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true })

    const { account, isLoading: isAccountLoading } = useGetAccountAndZone(slug)

    if (account) {
        GlobalStaticState.setSuperTokensTenant(account._sid)
    }
    const [loginFailed, setLoginFailed] = useState(false)

    const hasStarted = useRef(false)
    const history = useHistory()
    useEffect(() => {
        async function handleCallback() {
            try {
                hasStarted.current = true
                sessionStorage.setItem('processedCode', query.code?.toString() ?? '')
                const response = await signInAndUp()

                if (response.status !== 'OK') {
                    console.log('Login failed :', response.status)
                    setLoginFailed(true)
                }
            } catch (ex) {
                console.log('Login failed :', ex)
                setLoginFailed(true)
            }
        }
        // If we haven't already started processing this code.
        if (
            !hasStarted.current &&
            (!slug || account) &&
            sessionStorage.getItem('processedCode') !== query.code?.toString()
        ) {
            handleCallback()
        }
    }, [account, history, isAccountLoading, query.code, slug])

    // Authenticated if we have a user and either we're not in a workspace or we have a workspace user.
    const isAuthenticated = !!user && (!slug || !!workspaceUser)
    useEffect(() => {
        if (isAuthenticated && (!!account || !slug)) {
            const redirectUri = sessionStorage.getItem('redirectAfterCallback')
            sessionStorage.setItem('redirectAfterCallback', '')
            history.push(redirectUri || getUrl(Urls.Root, undefined, account))
        }
    }, [history, slug, isAuthenticated, account])

    if (loginFailed) {
        return <Redirect to={getUrl(Urls.Login, undefined, account)} />
    }

    return <LoadingSplash />
}
