import React, { CSSProperties, FC } from 'react'

import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { useAppErrors } from 'data/hooks/appErrors'
import { useObject } from 'data/hooks/objects'
import { useStackRoles } from 'data/hooks/roles'
import { fieldHasError, isSyntheticLink } from 'data/utils/error_utils'
import InlineFilterItem from 'features/records/components/InlineFilterItem'
import { labelMap, nextFilterOptions } from 'features/records/components/RecordFilters/constants'
import { InlineLabel } from 'features/records/components/RecordFilters/InlineLabel'
import { InvalidFilter } from 'features/records/components/RecordFilters/InvalidFilter'
import { useRecordFilters } from 'features/records/components/RecordFilters/provider/RecordFiltersProvider'
import { getFilterOptionsForType } from 'features/records/components/RecordFilters/utils/utils'
import { getFilterFieldFromId } from 'features/utils/filterToField'

type Props = {
    color: string
    place: string | undefined
    prefix: string | undefined
    usePortal: boolean
    wrapItems: boolean
    itemStyle: CSSProperties
    overhangOperators: string[] | undefined
    closeOnOuterAction: boolean
    hideCurrentUserOption: boolean | undefined
    showRelativeDateFilters: boolean
    contextRecordObject?: ObjectDto
}

export const FieldsFilters: FC<Props> = ({
    color,
    place,
    prefix,
    usePortal,
    wrapItems,
    itemStyle,
    overhangOperators,
    closeOnOuterAction,
    hideCurrentUserOption,
    showRelativeDateFilters,
    contextRecordObject,
}) => {
    const {
        state: { filters },
        object,
        onFieldChanged,
        onDeleteFilter,
    } = useRecordFilters()
    const { selectedStack } = useAppContext()
    const { object: userObject } = useObject(selectedStack?.options?.data_mapping?.user_object)
    const { data: roles } = useStackRoles()

    const { data } = useAppErrors()
    const errors = data?.synthetic_field_errors

    const isFilterLabelError = (
        options: { label: string; value: string }[],
        filterOptions: FilterOptions
    ) => {
        const filter = options.find((f) => f.value === filterOptions?.option)
        const label = get(filter, 'label')

        return !label
    }

    const dropdownFilterHasError = (filter: Filter, field: Partial<FieldDto>) => {
        if (field.type !== 'dropdown') return false

        const dropdownFieldOptions =
            field?.options?.options && field?.options?.options.map((option) => option.value)
        if (!dropdownFieldOptions) return false

        if (!filter?.options?.value || !field?.options?.options) {
            return false
        }
        if (typeof filter?.options?.value === 'string') {
            return !dropdownFieldOptions.includes(filter?.options?.value)
        }
        for (const option of filter?.options?.value) {
            return !dropdownFieldOptions.includes(option)
        }
    }

    const filterErrorToDisplay = (
        dropdownFieldFilterError: boolean | undefined,
        filterLabelError: boolean
    ) => {
        let filterErrorMessage: string | null = null
        switch (true) {
            case dropdownFieldFilterError:
                filterErrorMessage =
                    'Some dropdown values have been changed (shown in red). Please update this filter.'
                break
            case filterLabelError:
                filterErrorMessage = 'Please update this filter as its field type has been changed.'
                break
            default:
                filterErrorMessage = null
        }
        return filterErrorMessage
    }

    return (
        <>
            {filters.map((filter, index) => {
                const field = getFilterFieldFromId(object, filter.field?._sid as string)

                let isInvalid
                if (isSyntheticLink(field)) {
                    isInvalid = fieldHasError(field, errors)
                }

                if (!field || isInvalid)
                    return <InvalidFilter handleDelete={() => onDeleteFilter(filter._id!)} />

                const filterOptions = getFilterOptionsForType(
                    field,
                    Boolean(hideCurrentUserOption),
                    userObject as ObjectDto,
                    showRelativeDateFilters,
                    contextRecordObject
                ).map((value) => ({
                    label: labelMap[value] || value,
                    value,
                }))

                const dropdownFieldFilterError = dropdownFilterHasError(filter, field)
                const filterLabelError = isFilterLabelError(filterOptions, filter.options)

                const filterErrorMessage = filterErrorToDisplay(
                    dropdownFieldFilterError,
                    filterLabelError
                )
                // it's unsafe to use _id here as it is regenerates with random number sometims. And index is ok as we do not resort here
                return (
                    <div
                        key={index}
                        data-testid="record-filters-inline"
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: 'inline-flex',
                            alignItems: 'center',
                            maxWidth: '100%',
                            width: wrapItems ? 'auto' : '100%',
                            marginBottom: wrapItems ? '5px' : undefined,
                            position: 'relative',
                        }}
                    >
                        {prefix && index === 0 && <InlineLabel>{prefix}</InlineLabel>}
                        {index > 0 && (
                            <InlineLabel
                                position={overhangOperators && 'absolute'}
                                transform={overhangOperators && 'translateX(-100%)'}
                            >
                                and
                            </InlineLabel>
                        )}
                        <InlineFilterItem
                            contextRecordObject={contextRecordObject}
                            style={itemStyle}
                            roles={roles}
                            label={field.label}
                            field={field}
                            filterOptions={filter.options}
                            hideCurrentUserOption={hideCurrentUserOption}
                            userObjectId={selectedStack?.options?.data_mapping?.user_object}
                            options={filterOptions}
                            onChange={onFieldChanged}
                            id={filter._id}
                            valueOptionsByType={nextFilterOptions}
                            onRemove={() => onDeleteFilter(filter._id!)}
                            display="inline"
                            isNew={filter.isNew}
                            hasFilterError={filterLabelError || dropdownFieldFilterError}
                            filterErrorMessage={filterErrorMessage}
                            color={color}
                            closeOnOuterAction={closeOnOuterAction}
                            place={place}
                            usePortal={usePortal}
                            showRelativeDateFilters={showRelativeDateFilters}
                        />
                    </div>
                )
            })}
        </>
    )
}
