import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Editor, Extension, Range } from '@tiptap/react'
import Suggestion, { SuggestionProps } from '@tiptap/suggestion'
import classNames from 'classnames'

import {
    renderSuggestionComponent,
    SuggestionComponentHandle,
} from 'features/tiptap/Extensions/extensionHelpers'
import { hasTrAddedChar } from 'features/tiptap/utilities'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Box } from 'ui/components/Box'
import { ComboboxContext } from 'ui/components/Combobox'
import { ComboboxList } from 'ui/components/Combobox/ComboboxList'
import { useComboboxExtended } from 'ui/components/Combobox/useComboboxExtended'
import { ItemProvider } from 'ui/components/Combobox/useComboboxProviders'
import { LoadingIndicator } from 'ui/components/LoadingIndicator'
import { MenuBaseStyle } from 'ui/components/Menu/Menu.css'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        commands: {
            closeCommandSuggestion: () => ReturnType
        }
    }
}

type CommandProps = {
    item: any
}
export type CommandsProvider<T> = ItemProvider<T> & {
    onSelect: (props: {
        editor: Editor
        range: Range
        item: T
        setDisplayedComponent: (
            component: React.ComponentType<SuggestionProps<any>> | undefined
        ) => void
        setDisplayedComponentProps: (props: any) => void
    }) => void
}

type CommandsExtensionOptions = {
    allowedPrefixes?: string[] | null
}
export function createCommandsExtension(
    providersFn: () => CommandsProvider<any>[],
    options: CommandsExtensionOptions = {}
) {
    const suggestionPluginKey = new PluginKey('commandSuggestion')

    return Extension.create({
        name: 'commands',
        addCommands() {
            return {
                closeCommandSuggestion: () => {
                    return ({ tr }) => {
                        this.storage.canShow = false

                        tr.setMeta(suggestionPluginKey, {
                            active: false,
                        })
                        tr.setMeta('addToHistory', false)

                        return true
                    }
                },
            }
        },
        addProseMirrorPlugins() {
            return [
                new Plugin({
                    filterTransaction: (tr, state) => {
                        const { selection, selectionSet } = tr
                        if (!selectionSet) return true

                        const hasAddedSlashChar = hasTrAddedChar('/', tr)
                        if (hasAddedSlashChar) {
                            this.storage.canShow = true
                            this.storage.lastAddedCharPos = selection.$from.pos
                        }

                        const hasAddedSpaceChar = hasTrAddedChar(' ', tr)
                        if (hasAddedSpaceChar) {
                            const currentPos = state.selection.$from.pos
                            if (currentPos < 1) return true

                            const prevCharPos = currentPos - 1
                            const charBeforeCursor = state.doc.textBetween(prevCharPos, currentPos)
                            if (charBeforeCursor !== '/') return true

                            this.editor.commands.closeCommandSuggestion()
                        }

                        return true
                    },
                }),
                Suggestion<CommandProps>({
                    pluginKey: suggestionPluginKey,
                    editor: this.editor,
                    char: '/',
                    allowedPrefixes: options.allowedPrefixes,
                    allowSpaces: true,
                    items: () => [],
                    render: () => {
                        const onHide = () => {
                            this.editor.commands.closeCommandSuggestion()
                        }

                        // console.log('#props', editor.isFocused)
                        // if (!editor.isFocused) return null
                        return renderSuggestionComponent(
                            CommandExtensionView,
                            { providersFn },
                            onHide
                        )
                    },
                    allow: ({ editor, range }) => {
                        if (!this.storage.canShow) return false
                        if (this.storage.lastAddedCharPos !== range.from + 1) return false

                        return editor.isFocused
                    },
                }),
            ]
        },
        addStorage() {
            return {
                canShow: false,
                lastAddedCharPos: -1,
            }
        },
    })
}

type ListProps = SuggestionProps<any> & {
    providersFn: () => CommandsProvider<any>[]
    setDisplayedComponent?: (component: React.ComponentType | undefined) => void
    setDisplayedComponentProps?: (props: any) => void
}

const CommandExtensionView = React.forwardRef<SuggestionComponentHandle, ListProps>(
    (props, ref) => {
        const [displayedComponent, setDisplayedComponent] = useState<
            React.ComponentType | undefined
        >()
        const [displayedComponentProps, setDisplayedComponentProps] = useState<any>({})

        // Doing it like this because a component is a function and so if we just pass
        // the state setter setDisplayedComponent through to the children, and they try to call it
        // like setDisplayedComponent(SomeComponent), the state setter will actually execute the supplied
        // function rather than taking it as the new state value.
        const doSetDisplayedComponent = useCallback(
            (component) => setDisplayedComponent(() => component),
            [setDisplayedComponent]
        )
        //
        // If a command provider has specified custom content to display, we render that instead
        // of the root command list.
        const ContentComponent = displayedComponent
        return (
            <Box
                minWidth="400px"
                maxWidth="100vw"
                maxHeight="66vh"
                overflowY="auto"
                className={classNames(MenuBaseStyle, 'ag-custom-component-popup')}
            >
                {ContentComponent ? (
                    <ContentComponent
                        ref={ref}
                        {...props}
                        setDisplayedComponent={doSetDisplayedComponent}
                        setDisplayedComponentProps={setDisplayedComponentProps}
                        {...displayedComponentProps}
                    />
                ) : (
                    <RootCommandsList
                        ref={ref}
                        {...props}
                        setDisplayedComponent={doSetDisplayedComponent}
                        setDisplayedComponentProps={setDisplayedComponentProps}
                    />
                )}
            </Box>
        )
    }
)

const RootCommandsList = React.forwardRef<SuggestionComponentHandle, ListProps>(
    (
        { query, providersFn, setDisplayedComponent, setDisplayedComponentProps, editor, range },
        ref
    ) => {
        const onItemSelected = useCallback(
            (item, provider) => {
                provider.onSelect({
                    editor,
                    range,
                    item,
                    setDisplayedComponent,
                    setDisplayedComponentProps,
                })
            },
            [editor, range, setDisplayedComponent, setDisplayedComponentProps]
        )
        const { comboboxState, itemsState, queryTerms } = useComboboxExtended({
            isOpen: true,
            inputValue: query,
            onItemSelected,
            providers: providersFn(),
            itemToString: () => '',
            debounceDelay: 10,
        })

        const { getInputProps } = comboboxState

        useImperativeHandle(ref, () => ({
            onKeyDown: ({ event }) => {
                getInputProps(undefined, { suppressRefError: true }).onKeyDown(event)
                return event.defaultPrevented
            },
        }))

        const { isLoading, collections, showMore, items } = itemsState

        // Prevent deselecting the active option after the menu closes.
        const { onMouseLeave: _onMouseLeave, ...menuProps } = comboboxState.getMenuProps(
            undefined,
            {
                suppressRefError: true,
            }
        )

        return (
            <ComboboxContext.Provider value={comboboxState}>
                {query && !isLoading && !items.length && (
                    <Box py="l" px="xl">
                        No results found
                    </Box>
                )}
                <Box {...menuProps}>
                    <ComboboxList
                        collections={collections}
                        queryTerms={queryTerms}
                        showMore={showMore}
                        isLoading={isLoading}
                    />
                </Box>
                {isLoading && (
                    <Box py="l" px="xl" flex center color="textWeakest">
                        <LoadingIndicator />
                    </Box>
                )}
            </ComboboxContext.Provider>
        )
    }
)

export function useCommandsExtension({
    providers: suppliedProviders,
    options: suppliedOptions,
}: {
    providers: CommandsProvider<any>[]
    options?: CommandsExtensionOptions
}) {
    const options = useDeepEqualsMemoValue(suppliedOptions)
    // this ensures that even if the caller passes in a new array, we don't re-create the extension
    const providers = useRef(suppliedProviders)
    providers.current = suppliedProviders
    return useMemo(() => {
        return createCommandsExtension(() => providers.current, options)
    }, [options])
}
