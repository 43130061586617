import { useCallback, useState } from 'react'

import { useAppUsersContext } from 'features/users/AppUsers/AppUsersContext'

import useDebounce from 'v2/ui/utils/useDebounce'

const SEARCH_DEBOUNCE_RATE = 500 // ms

export function useAppUsersSearchBarState() {
    const { searchQuery, setSearchQuery } = useAppUsersContext()

    const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery)
    const debouncedSetSearchQuery = useDebounce(setSearchQuery, SEARCH_DEBOUNCE_RATE)

    const onInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value
            setLocalSearchQuery(value)
            debouncedSetSearchQuery(value)
        },
        [debouncedSetSearchQuery]
    )

    return { searchQuery: localSearchQuery, onInputChange }
}
