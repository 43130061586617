import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'
import { BoardViewAttribute } from 'features/views/ListView/BoardView/types'

import { Box } from 'ui/components/Box'

type UseBoardViewBaseAttributeStateOptions = {
    attribute: BoardViewAttribute
}

export function useBoardViewBaseAttributeState(options: UseBoardViewBaseAttributeStateOptions) {
    const { attribute } = options

    const { labelStyle, titleSize, attributeStyle: listAttributeStyle } = useBoardViewContext()

    const showLabel = labelStyle === 'text' && !!attribute.title && attribute.type === 'content'
    const showIcon =
        labelStyle === 'icon' &&
        listAttributeStyle === 'list' &&
        !!attribute.icon &&
        attribute.type === 'content'

    let titleTextSize: React.ComponentPropsWithoutRef<typeof Box>['fontSize']
    switch (titleSize) {
        case 'small':
            titleTextSize = 'headline3Xs'
            break
        case 'medium':
            titleTextSize = 'headline2Xs'
            break
        case 'large':
            titleTextSize = 'headlineXs'
            break
    }

    const isFooter = attribute.type === 'footer'
    const hasListTagsDisplay = listAttributeStyle === 'tags'
    const textSize = isFooter && hasListTagsDisplay ? 'bodyS' : 'bodyM'

    return useMemo(
        () => ({ showLabel, titleTextSize, textSize, showIcon }),
        [showLabel, titleTextSize, textSize, showIcon]
    )
}
