import { useCallback, useMemo } from 'react'

import { Column, FillOperationParams } from 'ag-grid-community'
import moment from 'moment'

import { NUMBER_TYPES } from 'data/utils/fieldDefinitions'
import { useDataGridContext } from 'features/datagrid/components/DataGridContext'

import { useValueFormatter } from './useValueFormatter'

type UseHandleFillOperationProps = {
    fields: FieldDto[]
    object: ObjectDto
}

export function useHandleFillOperation({ fields, object }: UseHandleFillOperationProps) {
    const { cellDataProvider } = useDataGridContext()
    const formatValue = useValueFormatter({ cellDataProvider })

    const fieldsByApiName = useMemo(() => {
        return fields.reduce<Map<string, FieldDto>>(
            (agg, field) => agg.set(field.api_name, field),
            new Map<string, FieldDto>()
        )
    }, [fields])

    return useCallback(
        (params: FillOperationParams) => {
            const { initialValues, currentIndex, direction, api } = params

            let column: Column | undefined
            let targetColumn: Column | undefined
            if (direction === 'up' || direction === 'down') {
                column = params.column
                targetColumn = params.column
            } else {
                column = api.getFocusedCell()?.column
                targetColumn = params.column
            }
            if (!column) return false

            const fieldApiName = column.getColId()
            const field = fieldsByApiName.get(fieldApiName)
            if (!field) return false

            if (NUMBER_TYPES.includes(field.type)) {
                // Let the grid handle the fill operation for number fields.
                return false
            }

            // Support filling with multiple values selected.
            const totalValueCount = initialValues.length
            let value = initialValues[totalValueCount - 1]
            if (params.event.altKey) {
                value = initialValues[currentIndex % totalValueCount]
            }

            // If the current field is a document field, and the target field is a document field, just pass the TipTap value through.
            if (field.type === 'document' && targetColumn) {
                const targetField = fieldsByApiName.get(targetColumn.getColId())
                if (targetField?.type === 'document') {
                    return value
                }
            }

            // Make sure we always use the right date format that the formatter expects.
            if (field.type === 'date') {
                value = moment(value).format('YYYY-MM-DD')
            } else if (field.type === 'datetime') {
                value = moment(value).format('YYYY-MM-DD HH:mm')
            }

            return formatValue(value, field, object)
        },
        [fieldsByApiName, formatValue, object]
    )
}
