import { memo, useCallback, useMemo } from 'react'

import { SuppressKeyboardEventParams, ValueFormatterParams } from 'ag-grid-enterprise'

import { isMultiLineText } from 'data/utils/fieldDefinitions'
import {
    AddNewRecordButtonCellStyle,
    AddNewRecordCellStyle,
} from 'features/datagrid/cells/AddNewRecordCell.css'
import { cells, getCellForField } from 'features/datagrid/cells/cells'
import { RowIndexCellRenderer } from 'features/datagrid/cells/RowIndexCell'
import {
    HideRowIndexCellStyle,
    RowIndexBaseCellStyle,
    RowIndexCellStyle,
    RowIndexHeaderCellStyle,
} from 'features/datagrid/cells/RowIndexCell.css'
import { DataGridCellStyle } from 'features/datagrid/components/DataGrid.css'
import { useDataGridContext } from 'features/datagrid/components/DataGridContext'
import { DELETED_FIELDS } from 'features/datagrid/constants'
import { AddNewHeader } from 'features/datagrid/headers/AddNewHeader'
import {
    AddNewColumnBaseStyle,
    AddNewHeaderStyle,
} from 'features/datagrid/headers/AddNewHeader.css'
import { DataGridFieldHeader } from 'features/datagrid/headers/DataGridFieldHeader'
import { ColumnDefinition, DataGridEditorProps, RowData } from 'features/datagrid/types'
import {
    getFieldDataType,
    getValueFromParams,
    isFieldEditable,
    isNewRecordButton,
    isNewRecordRow,
} from 'features/datagrid/utils'

import { useValueFormatter } from './useValueFormatter'

type UseGridColumnsProps = {
    object: ObjectDto
    fields: FieldDto[]
    orderBy: DataGridEditorProps['orderBy']
    setOrderBy: DataGridEditorProps['setOrderBy']
    refetchRecords?: () => void
    onFieldCreated: (field: FieldDto) => void
    addNewEmptyRecord: () => void
    trashMode: boolean
}

export function useGridColumns({
    object,
    fields,
    orderBy,
    setOrderBy,
    refetchRecords,
    onFieldCreated,
    addNewEmptyRecord,
    trashMode,
}: UseGridColumnsProps) {
    const { cellDataProvider } = useDataGridContext()

    const formatValue = useValueFormatter({ cellDataProvider })

    const onFieldEdited = useCallback(
        (_field: FieldDto) => {
            refetchRecords?.()
        },
        [refetchRecords]
    )

    return useMemo<ColumnDefinition[]>(() => {
        const hasPrimaryField = fields.some((f) => f.is_primary)

        let finalFields = trashMode ? [...DELETED_FIELDS, ...fields] : fields

        const columns: ColumnDefinition[] = finalFields.map((f: FieldDto, idx) => {
            const isFirstColumn = idx === 0
            const cellForField = getCellForField(f)
            const isColumnPinned = hasPrimaryField ? f.is_primary : isFirstColumn

            return {
                lockPinned: true,
                suppressMovable: isColumnPinned,
                suppressMenu: true,
                minWidth: 100,
                field: f.api_name,
                headerName: f.label,
                sortable: false, // Disable built-in sorting since we're using the backend.
                suppressNavigable: (params) => {
                    // Don't let me use the keyboard to navigate to the add new record button.
                    return trashMode || isNewRecordButton(params.data)
                },
                editable: (params) => {
                    // Don't let me edit the add new record button.
                    if (trashMode || isNewRecordButton(params.data)) return false

                    return isFieldEditable(f)
                },
                pinned: isColumnPinned ? 'left' : false,
                cellDataType: getFieldDataType(f),
                headerComponent: DataGridFieldHeader,
                headerComponentParams: {
                    field: f,
                    onFieldEdited,
                    orderBy,
                    setOrderBy,
                    allowFieldEdit: !trashMode,
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    // This is used for cells without custom renderers, as well as copy operations.
                    return formatValue(params.value, f, object)
                },
                valueGetter: (params) => {
                    // This is used for adding default values to each field.
                    return getValueFromParams(params, f)
                },
                cellEditorPopup: cellForField?.popupEditor,
                cellRendererSelector: (params) => {
                    const paramsToPass: Record<string, unknown> = { field: f }

                    let cell = cellForField
                    if (isNewRecordButton(params.data)) {
                        if (!isFirstColumn) return undefined

                        // We use a custom renderer for the add new record button.
                        cell = cells.add_new_record_button
                        paramsToPass.addNewRecord = addNewEmptyRecord
                    }

                    let renderer = cell?.provideRenderer
                    if (!renderer) return undefined

                    if (typeof renderer !== 'string') {
                        renderer = memo(renderer)
                    }

                    return {
                        component: renderer,
                        params: paramsToPass,
                    }
                },
                cellEditorSelector: (params) => {
                    const paramsToPass: Record<string, unknown> = { field: f }

                    let cell = cellForField
                    if (isNewRecordButton(params.data)) {
                        if (!isFirstColumn) return undefined

                        // We use a custom editor for the add new record button.
                        cell = cells.add_new_record_button
                    }

                    let editor = cell?.provideEditor
                    if (!editor) return undefined

                    if (typeof editor !== 'string') {
                        editor = memo(editor)
                    }

                    return {
                        component: editor,
                        params: paramsToPass,
                    }
                },
                cellEditorParams: {
                    // Prevent stepping on number fields.
                    preventStepping: true,
                },
                cellClass: (params) => {
                    const classes = [DataGridCellStyle({ isFirstColumn: isColumnPinned })]

                    if (isNewRecordButton(params.data)) {
                        if (isFirstColumn) {
                            classes.push(AddNewRecordButtonCellStyle)
                        }

                        classes.push(AddNewRecordCellStyle)
                    }

                    return classes
                },
                suppressKeyboardEvent(params: SuppressKeyboardEventParams<RowData>) {
                    // Prevent the grid from handling keyboard events in the following cases:

                    if (trashMode) return true

                    const { editing, event } = params

                    // Don't enter edit mode when we want to show the add new record placeholder row.
                    if (!editing && event.key === 'Enter' && event.shiftKey) {
                        return true
                    }

                    // Allow all keyboard events for the add new record button.
                    if (isNewRecordButton(params.data)) return false

                    // Prevent keyboard events in multi-line text fields from being handled by the grid.
                    if (isMultiLineText(f.type, f.options) && editing && event.key !== 'Escape') {
                        return true
                    }

                    // Prevent the grid from handling the enter key on editors that have a dropdown.
                    if (
                        editing &&
                        event.key === 'Enter' &&
                        ['dropdown', 'multi_select', 'lookup', 'multi_lookup'].includes(f.type)
                    ) {
                        return true
                    }

                    return false
                },
                // Something to differentiate between field columns and the add new column.
                isFieldColumn: true,
            }
        })

        const rowIndexColumn = getRowIndexColumn()

        const finalColumns = [rowIndexColumn, ...columns]
        if (!trashMode) {
            const addNewColumn = getAddNewColumn(onFieldCreated)
            finalColumns.push(addNewColumn)
        }

        return finalColumns
    }, [
        addNewEmptyRecord,
        fields,
        formatValue,
        object,
        onFieldCreated,
        onFieldEdited,
        orderBy,
        setOrderBy,
        trashMode,
    ])
}

function getRowIndexColumn(): ColumnDefinition {
    return {
        colId: 'checkbox',
        headerName: '',
        checkboxSelection: (params) => !isNewRecordRow(params.data),
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        showDisabledCheckboxes: false,
        pinned: 'left',
        lockPinned: true,
        width: 60,
        editable: false,
        resizable: false,
        lockPosition: 'left',
        suppressFillHandle: true,
        suppressMovable: true,
        suppressNavigable: true,
        flex: 1,
        suppressMenu: true,
        sortable: false,
        // valueGetter: (params) => (params.node?.rowIndex ?? 0) + 1,
        headerClass: RowIndexHeaderCellStyle,
        cellClass: (params) => {
            const classes = [RowIndexBaseCellStyle]

            if (isNewRecordButton(params.data)) {
                classes.push(HideRowIndexCellStyle)
            }

            if (!isNewRecordRow(params.data)) {
                classes.push(RowIndexCellStyle)
            }

            return classes
        },
        cellRenderer: RowIndexCellRenderer,
    }
}

function getAddNewColumn(onFieldCreated: (field: FieldDto) => void): ColumnDefinition {
    return {
        colId: 'addNew',
        headerName: '',
        lockPinned: true,
        lockPosition: 'right',
        width: 64,
        editable: false,
        resizable: false,
        suppressFillHandle: true,
        suppressMovable: true,
        suppressNavigable: true,
        suppressMenu: true,
        sortable: false,
        cellClass: AddNewColumnBaseStyle,
        headerClass: AddNewHeaderStyle,
        headerComponent: AddNewHeader,
        headerComponentParams: {
            onFieldCreated,
        },
    }
}
