import React from 'react'

import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import getObject from 'data/utils/getObject'
import { formatValue } from 'data/utils/utils'
import { isReadOnlyField } from 'features/admin/data-connector/utils'
import isRichTextField from 'utils/isRichTextField'

import QuickCopyButton from 'v2/ui/components/QuickCopyButton'
import {
    layouts as layoutSettings,
    modes as modeSettings,
    sizes as sizeSettings,
} from 'v2/ui/utils/attributeSettings'

import 'moment/min/locales'

import AttachmentsAttribute from './AttachmentsAttribute'
import AttributeContainer from './AttributeContainer'
import CheckboxAttribute from './CheckboxAttribute'
import DateAttribute from './DateAttribute'
import DocumentAttribute from './DocumentAttribute'
import DropdownAttribute from './DropdownAttribute'
import LongTextAttribute from './LongTextAttribute'
import MultiRecordLinkAttribute from './MultiRecordLinkAttribute'
import NumberAttribute from './NumberAttribute'
import PercentAttribute from './PercentAttribute'
import { setDefaultValue } from './setDefaultValue'
import SingleRecordLinkAttribute from './SingleRecordLinkAttribute'
import TextAttribute from './TextAttribute'
import { UserRefAttribute } from './UserRefAttribute'

import 'react-datepicker/dist/react-datepicker.css'

class Attribute extends React.PureComponent {
    // This component handles rendering attributes of records,
    // either as static display, inline-editable, pre-filled form
    // fields, or even blank form fields
    state = {
        isQuickButtonVisible: false,
        currentValue: this.props.children,
        key: 0,
        usingDefaultValue: false,
    }

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleMouseEnter = this.handleMouseEnter.bind(this)
        this.handleMouseLeave = this.handleMouseLeave.bind(this)
        this.isQuickButtonEnabled = this.isQuickButtonEnabled.bind(this)
    }

    updateValueFromProps = (prevProps = {}) => {
        if (!isEqual(this.props.children, prevProps?.children)) {
            this.setState((state) => ({ ...state, currentValue: this.props.children }))
        }
    }

    componentDidMount() {
        // Only allow default values for create views
        if (this.props.isCreate) {
            setDefaultValue({
                props: this.props,
                prevProps: undefined,
                setState: this.setState.bind(this),
                isMounting: true,
                state: this.state,
            })
        }
    }

    componentDidUpdate(prevProps) {
        // Only allow default values for create views
        if (this.props.isCreate) {
            setDefaultValue({
                props: this.props,
                prevProps: prevProps,
                setState: this.setState.bind(this),
                state: this.state,
            })
        } else {
            this.updateValueFromProps(prevProps)
        }
    }

    handleChange(value) {
        this.props.onChange?.(value, this.props.fieldKey || this.props.field?.api_name)
    }

    isQuickButtonEnabled() {
        return this.props.enableCopyPaste && this.props.mode !== Attribute.modes.editing
    }

    handleMouseEnter() {
        if (this.isQuickButtonEnabled()) this.setState({ isQuickButtonVisible: true })
    }

    handleMouseLeave() {
        if (this.isQuickButtonEnabled()) this.setState({ isQuickButtonVisible: false })
    }

    render() {
        let mode = this.props.mode
        if (!mode && this.props.editable) {
            mode = this.props.inline ? Attribute.modes.editable : Attribute.modes.editing
        }

        // Set id field to read only - edit mode only
        let object = getObject(get(this.props, 'field.object_id'))
        if (
            !this.props.isCreate &&
            object &&
            get(object, 'connection_options.id_field') === get(this.props, 'field.api_name')
        ) {
            mode = 'readonly'
        }

        if (this.props.renderOptions?.lockValue) {
            mode = 'readonly'
        }

        const isFieldReadOnly = this.props.field ? isReadOnlyField(this.props.field) : true
        if (isFieldReadOnly) {
            mode = 'readonly'
        }

        const value = this.state.currentValue
        let Component = TextAttribute
        let passingProps = {
            ...this.props,
            mode,
            onChange: this.handleChange,
            inline: true,
            enableCopyPaste: this.props.enableCopyPaste,
            layout: this.props.layout,
        }
        let maxWidth = this.props.baseMaxWidth || '100%'
        let maxWidthRatio = 1
        const isQuickButtonEnabled =
            passingProps.enableCopyPaste && passingProps.mode !== Attribute.modes.editing

        let containerProps = {
            flexBasis: '100%',
            width: '100%',
            maxHeight: '100%',
            align: 'stretch',
            direction: isQuickButtonEnabled ? 'row' : 'column',
            wrap: isQuickButtonEnabled ? 'nowrap' : 'wrap',
            position: 'relative',
        }

        if (!this.props.field || this.props.field.type === 'string') {
            if (isRichTextField(this.props.field)) {
                passingProps = { ...passingProps, enableMarkdown: true }
                containerProps = {
                    ...containerProps,
                    align: this.props.enableCopyPaste ? 'baseline' : 'stretch',
                }
                Component = LongTextAttribute
            } else {
                Component = TextAttribute
            }
        }
        if (this.props.field?.type === 'long_text') {
            if (get(this.props.field, 'options.enable_markdown')) {
                passingProps = { ...passingProps, enableMarkdown: true }
            }
            containerProps = {
                ...containerProps,
                align: this.props.enableCopyPaste ? 'baseline' : 'stretch',
            }
            Component = LongTextAttribute
            maxWidthRatio = 2
        }
        if (this.props.field?.type === 'document') {
            Component = DocumentAttribute
            passingProps = { ...passingProps }
            maxWidthRatio = 2
        }
        if (this.props.field?.type === 'number') {
            // TODO: Need to add validation here
            Component = NumberAttribute
            maxWidthRatio = 0.5
        }
        if (this.props.field?.type === 'dropdown') {
            Component = DropdownAttribute
        }
        if (this.props.field?.type === 'multi_select') {
            passingProps = { ...passingProps, isMulti: true }
            Component = DropdownAttribute
        }
        if (this.props.field?.type === 'url') {
            passingProps = {
                ...passingProps,
                renderOptions: { ...this.props.renderOptions, treatAsUrl: true },
            }
            Component = TextAttribute
        }
        if (this.props.field?.type === 'image') {
            passingProps = { ...passingProps, isSingle: true }
            Component = AttachmentsAttribute
        }
        if (this.props.field?.type === 'date' || this.props.field?.type === 'datetime') {
            passingProps = { ...passingProps, showTime: this.props.field.type === 'datetime' }
            Component = DateAttribute
        }
        if (this.props.field?.type === 'lookup') {
            Component = SingleRecordLinkAttribute
        }
        if (this.props.field?.type === 'multi_lookup') {
            Component = MultiRecordLinkAttribute
        }
        if (this.props.field?.type === 'checkbox') {
            passingProps = {
                ...passingProps,
                checkboxDisplay: this.props.renderOptions?.checkboxDisplay,
            }
            Component = CheckboxAttribute
            maxWidthRatio = 0.5
        }
        if (this.props.field?.type === 'multi_file') {
            Component = AttachmentsAttribute
        }
        if (this.props.field?.type === 'currency') {
            const leftAdorner = this.props.field.options?.currency_symbol
            const leftAdornerMinWidth = `${leftAdorner.length * 8}px`
            const inputPaddingLeft = `${(leftAdorner.length + 2) * 8}px`
            const additionalProps = {
                renderOptions: {
                    inputProps: {
                        innerStyle: { paddingLeft: inputPaddingLeft },
                        leftAdorner: leftAdorner,
                        leftAdornerProps: {
                            leftAdornerMinWidth: leftAdornerMinWidth,
                        },
                    },
                    formattedValue: formatValue(this.props.field, value),
                },
            }
            passingProps = { ...passingProps, ...additionalProps }
            Component = NumberAttribute
            maxWidthRatio = 0.5
        }
        if (this.props.field?.type === 'currency_varying') {
            const symbol = value ? value.currency_symbol : null

            const additionalProps = {
                renderOptions: {
                    inputProps: { leftAdorner: symbol },
                    formattedValue: formatValue(this.props.field, value),
                    unpackForEditing: (currencyBlob) => (currencyBlob ? currencyBlob.value : null),
                    repackForSaving: (currencyAmount) => ({
                        value: currencyAmount,
                        currency_symbol: symbol,
                    }),
                },
            }

            passingProps = { ...passingProps, ...additionalProps }
            Component = NumberAttribute
            maxWidthRatio = 0.5
        }
        if (this.props.field?.type === 'percentage') {
            // Values are saved as decimals (33% saved as .33)
            // and are displayed * 100 (.33 displayed as 33)
            // Note this divide/multiply by 100 scheme produces
            // some very long floating point number results.
            // For instance 5.4/100 yields 0.054000000000000006.
            // when turning that back into 5.4 we round to the nearest
            // 5 decimal places (reasonable limit for our use case)
            // which yields the expected 5.4
            const additionalProps = {
                renderOptions: {
                    ...this.props.renderOptions,
                    formattedValue: formatValue(this.props.field, value),
                    unpackForEditing: (value) =>
                        value || value === 0 ? parseFloat((value * 100).toFixed(5)) : '',
                    repackForSaving: (value) =>
                        value || value === 0 ? (value / 100).toString() : '',
                },
            }
            passingProps = {
                ...passingProps,
                ...additionalProps,
                percentageDisplay: this.props.renderOptions?.percentageDisplay,
            }
            Component = PercentAttribute
            maxWidthRatio = 0.5
        }
        if (this.props.field?.type === 'user_ref') {
            Component = UserRefAttribute
        }

        if (typeof maxWidth === 'number') {
            maxWidth = maxWidth * maxWidthRatio + 'px'
        }
        containerProps.maxWidth = maxWidth

        const content = (
            <Component key={`${this.props.field?.api_name}${this.state.key}`} {...passingProps}>
                {value}
            </Component>
        )

        if (this.props.noContainer) return content

        return (
            <AttributeContainer
                {...containerProps}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                justifyContent={
                    this.props.layout === Attribute.layouts.highlighted ? 'center' : 'flex-start'
                }
            >
                {content}
                {isQuickButtonEnabled && (
                    <QuickCopyButton
                        marginLeft="auto"
                        isHidden={!this.state.isQuickButtonVisible}
                        value={passingProps?.renderOptions?.formattedValue || value}
                    />
                )}
            </AttributeContainer>
        )
    }
}

Attribute.modes = modeSettings

Attribute.layouts = layoutSettings

Attribute.sizes = sizeSettings

Attribute.propTypes = {
    mode: PropTypes.oneOf(Object.keys(Attribute.modes)),
    layout: PropTypes.oneOf(Object.keys(Attribute.layouts)),
    size: PropTypes.oneOf(Object.values(Attribute.sizes)),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    baseMaxWidth: PropTypes.number,
    field: PropTypes.object,
    onChange: PropTypes.func,
    noLinks: PropTypes.bool,
    style: PropTypes.object,
    keepSmall: PropTypes.bool,
    options: PropTypes.object,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    singleLine: PropTypes.bool,
    noContainer: PropTypes.bool,
}

Attribute.defaultProps = {
    noLinks: false,
    size: Attribute.sizes.medium,
}

export default Attribute
