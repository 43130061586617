import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'

export const PanelFloatingButton = () => {
    const { selectedStack } = useAppContext()
    const FloatingButton = styled('img')`
        height: 35px;
        width: 35px;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 99;
        opacity: 0.5;
    `

    const isStudio = window.location.pathname.startsWith(getUrl(Urls.Studio))
    return !isStudio && selectedStack.options.icon ? (
        <Link to={getUrl(Urls.StudioFeatures)}>
            <FloatingButton alt="Home" src={selectedStack.options.icon} />
        </Link>
    ) : (
        ''
    )
}
