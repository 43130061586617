import React, { forwardRef } from 'react'

import {
    Tab as ChakraTab,
    TabProps as ChakraTabProps,
    Tabs as TabsBase,
    useTab,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { variant } from 'styled-system'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

const lineBase = {
    fontSize: 'sm',
    lineHeight: '1',
    color: 'grey.300',
    outline: 'none',
    border: 'none',
    bg: 'transparent',
    cursor: 'pointer',
    transition: 'all .2s',
    borderRadius: '4px 4px 0px 0px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '4px',
    borderBottomColor: 'transparent',
    marginBottom: '-2px',
    fontWeight: 'bold',
    fontFamily: 'Proxima Nova',
    p: 3,
    ':hover': {
        color: 'grey.400',
        borderBottomColor: 'grey.200',
    },
}
const variants = {
    variants: {
        line: {
            ...lineBase,
            '&[aria-selected="true"]': {
                color: V4DesignSystem.colors.text,
                borderBottomColor: 'brand.300',
                marginBottom: '-2px',
                ':hover': {
                    borderBottomColor: 'brand.300',
                },
            },
        },

        admin: {
            ...lineBase,
            '&[aria-selected="true"]': {
                color: V4DesignSystem.colors.text,
                borderBottomColor: V4DesignSystem.colors.stacker,
                marginBottom: '-2px',
                ':hover': {
                    borderBottomColor: V4DesignSystem.colors.stacker,
                },
            },
        },
    },
}

const StyledTab = styled(ChakraTab as any)`
    &[aria-selected='true'] {
        color: inherit;
    }
    ${variant(variants)}
`

type TabProps = ChakraTabProps & {
    asButton?: boolean
    variant?: 'line' | 'admin'
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>((props, ref) => {
    const { asButton = true, variant = 'line', ...rest } = props

    const tabProps = useTab({ ...props, ref })
    const isDisabled = !!tabProps['aria-disabled']
    return (
        <StyledTab
            outline="none"
            as={asButton ? 'button' : undefined}
            isDisabled={isDisabled}
            {...rest}
            {...tabProps}
            //we override variant that comes from tabProps to use our admin_selected and line_selected
            variant={variant}
        />
    )
})

export const Tabs = styled(TabsBase as any)`
    border-color: ${(props) => props.theme.colors.grey[200]};
`
export const TabsWithOverflow = styled(Tabs)`
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
`
