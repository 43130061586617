import { useMemo } from 'react'

import { useIsCommentCountVisible } from 'features/Activity/useIsCommentCountVisible'
import { useListViewContext } from 'features/views/ListView/ListViewContext'

import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

type UseListViewCommentCountStateOptions = {
    record?: RecordDto
}

export function useListViewCommentCountState(options: UseListViewCommentCountStateOptions) {
    const { record } = options

    const count = record?._comment_count ?? 0
    const label = formatLabel(count)

    let iconColor: React.ComponentProps<typeof Icon>['color']
    if (count > 0) {
        iconColor = 'theme600'
    }

    let textColor: React.ComponentProps<typeof Body>['color'] = 'textWeak'
    if (count > 0) {
        textColor = 'theme600'
    }

    const { object } = useListViewContext()
    const showCount = useIsCommentCountVisible({ object, record })

    return useMemo(
        () => ({
            label,
            iconColor,
            textColor,
            showCount,
            count,
        }),
        [iconColor, label, showCount, textColor, count]
    )
}

function formatLabel(count?: number) {
    if (count) {
        return `${count === 1 ? '1 comment' : `${count} comments`}`
    }

    return 'No comments'
}
