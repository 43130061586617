import React from 'react'

import { isEqual } from 'lodash'

import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useDateAttributeDisplayState } from './hooks/useDateAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type DateAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    isLoading?: boolean
}

export const DateAttributeDisplay: AttributeDisplayComponent<string, DateAttributeDisplayProps> =
    React.memo(function DateAttributeDisplay({ value, field, isLoading, ...props }) {
        const { content } = useDateAttributeDisplayState({ value, field, isLoading })

        return (
            <Skeleton isLoading={isLoading}>
                <Body size="m" weight="regular" {...props}>
                    {content}
                </Body>
            </Skeleton>
        )
    }, isEqual)
