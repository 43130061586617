import React, { forwardRef, useEffect, useState } from 'react'

import * as Parts from './Tabs.parts'
import { TabsContextProvider } from './TabsContext'

type TabsRef = HTMLDivElement

type TabsProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Root>, 'asChild'> & {
    type?: 'card' | 'underlined'
}

export const Tabs = forwardRef<TabsRef, TabsProps>(
    ({ children, defaultValue, type = 'card', ...props }, ref) => {
        const isControlled = typeof props.value !== 'undefined'
        const [value, setValue] = useState(props.value || defaultValue || '')
        useEffect(() => {
            setValue(props.value || defaultValue || '')
        }, [defaultValue, props.value])

        const handleValueChange = (newValue: string) => {
            if (!isControlled) {
                setValue(newValue)
            }
            props.onValueChange?.(newValue)
        }

        return (
            <Parts.Root ref={ref} {...props} onValueChange={handleValueChange} value={value}>
                <TabsContextProvider value={value} setValue={setValue} type={type}>
                    {children}
                </TabsContextProvider>
            </Parts.Root>
        )
    }
)
