import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { userActions } from 'data/api/userActions'

function _WithUser(props) {
    const { user } = useAuthContext()

    return props.children({
        userActions: props.userActions,
        user,
        isLoggedIn: Boolean(user),
        isEditing: props.isEditing,
        redirect: props.redirect,
        editingStartRequested: props.editingStartRequested,
        editingStopRequested: props.editingStopRequested,
    })
}

_WithUser.propTypes = {
    children: PropTypes.func.isRequired,
    userActions: PropTypes.object.isRequired, // From redux
    isEditing: PropTypes.bool.isRequired, // From redux
}

function mapStateToProps(state) {
    return {
        isEditing: state.user.isEditing,
        editingStartRequested: state.user.editingStartRequested,
        editingStopRequested: state.user.editingStopRequested,
        redirect: state.user.redirect,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

const WithUser = connect(mapStateToProps, mapDispatchToProps)(_WithUser)

export const withUser = (Child) => (props) =>
    <WithUser>{(userProps) => <Child {...userProps} {...props} />}</WithUser>
