import { makeAppVariables } from './app'

export function makeLinkVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            text: s.color.theme600,
            textHover: s.color.text,
        },
        fontSizes: {
            s: s.fontSizes.bodyS,
            m: s.fontSizes.bodyM,
            l: s.fontSizes.bodyL,
        },
        lineHeights: {
            s: s.lineHeights.bodyS,
            m: s.lineHeights.bodyM,
            l: s.lineHeights.bodyL,
        },
        fontWeights: {
            s: s.fontWeights.bodyMedium,
            m: s.fontWeights.bodyMedium,
            l: s.fontWeights.bodyMedium,
        },
    }
}
