import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import { WorkflowCreateButton } from './WorkflowCreateButton'
import { useWorkflowManagerContext } from './WorkflowManagerContext'

type WorkflowListHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    onCreateClick: () => void
}

export const WorkflowListHeader: React.FC<WorkflowListHeaderProps> = ({
    onCreateClick,
    ...props
}) => {
    const { navigateToPane } = useWorkflowManagerContext()

    return (
        <Box flex center height="full" justifyContent="flex-end" gap="xs" {...props}>
            <Button
                type="button"
                variant="ghost"
                startIcon={{ name: 'Cable' }}
                size="s"
                onClick={() => navigateToPane('integrations')}
            >
                Connections
            </Button>
            <WorkflowCreateButton onClick={onCreateClick} />
        </Box>
    )
}
