import React, { useCallback, useEffect, useRef, useState } from 'react'

import shortid from 'shortid'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { Progress } from 'ui/components/Progress'
import { Tag } from 'ui/components/Tag'
import { Body, Headline } from 'ui/components/Text'

import { generateBasicSchema } from './aiPrompts/generateBasicShema'
import { generateBasicRecords, generateRecords } from './aiPrompts/generateRecords'
import { generateTableNames } from './aiPrompts/generateTableNames'
import { Bundle } from './bundles/bundleBuilder'
export function RequirementsStep({
    requirements,
    setRequirements,
    setBundle,
    onMoveNext,
    moveBack,
    updateProgress,
    failed,
    setFailed,
    isGenerating,
    setIsGenerating,
}: {
    requirements: string[]
    clearbitContext: string
    setRequirements: (requirements: string[]) => void
    onMoveNext: () => Promise<void>
    moveBack: () => void
    setBundle: (bundle: Bundle) => void
    updateProgress: (update: { progress: number; status: string }) => void
    isGenerating: boolean
    failed: boolean
    setFailed: (failed: boolean) => void
    setIsGenerating: (isGenerating: boolean) => void
}) {
    const requirementsRef = useRef(requirements)
    requirementsRef.current = requirements
    const [newRequirement, setNewRequirement] = useState('')
    const [showingAddRequirement, setShowingAddRequirement] = useState(false)
    const currentPreviewRequestId = useRef<string>('')
    const bundle = useRef<Bundle | null>(null)

    const doGenerateRecords = useCallback(
        async function (bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            updateProgress({
                progress: 50,
                status: `preparing sample records...`,
            })
            // function updateProgressForObject(objectIndex: number) {
            //     if (objectIndex >= bundle.apps[0].objects.length) {
            //         return
            //     }
            //     // if a new request has started, then just bail
            //     if (requestId !== currentPreviewRequestId.current) {
            //         return
            //     }

            //     timeout = setTimeout(() => updateProgressForObject(objectIndex + 1), 2000)
            // }
            // let timeout = setTimeout(() => updateProgressForObject(0), 2000)

            let newBundle = await generateBasicRecords(bundle, currentPreviewRequestId.current)
            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current) {
                return
            }

            setBundle(newBundle)
            let objectIndex = 0
            for (const obj of newBundle.apps[0].objects) {
                updateProgress({
                    progress: 60 + (30 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: `generating sample records for ${bundle.apps[0].objects[objectIndex].name}...`,
                })
                newBundle = await generateRecords(
                    newBundle,
                    obj.id,
                    currentPreviewRequestId.current
                )

                setBundle(newBundle)
                objectIndex++
            }
            setIsGenerating(false)
        },
        [setBundle, setIsGenerating, updateProgress]
    )

    const generateSchema = useCallback(
        async function generateSchema(bundle: Bundle) {
            const requestId = currentPreviewRequestId.current

            updateProgress({
                progress: 10,
                status: `preparing table for ${bundle.apps[0].objects[0].name}...`,
            })
            function updateProgressForObject(objectIndex: number) {
                if (objectIndex >= bundle.apps[0].objects.length) {
                    return
                }

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current) {
                    return
                }

                updateProgress({
                    progress: 10 + (40 * (objectIndex + 1)) / bundle.apps[0].objects.length,
                    status: `preparing table for ${bundle.apps[0].objects[objectIndex].name}...`,
                })
                timeout = setTimeout(() => updateProgressForObject(objectIndex + 1), 2000)
            }
            let timeout = setTimeout(() => updateProgressForObject(0), 2000)

            const newBundle = await generateBasicSchema(bundle, currentPreviewRequestId.current)

            // if a new request has started, then just bail
            if (requestId !== currentPreviewRequestId.current) {
                return
            }

            clearTimeout(timeout)
            setBundle(newBundle)

            await doGenerateRecords(newBundle)
            // await doGenerateRecords(newBundle)
            // await doGenerateRecords(newBundle)

            setBundle(newBundle)
        },
        [doGenerateRecords, setBundle, updateProgress]
    )

    const updateSchema = useCallback(
        async function () {
            setIsGenerating(true)

            const requestId = shortid.generate()
            currentPreviewRequestId.current = requestId
            try {
                updateProgress({ progress: 0, status: 'analyzing requirements...' })
                const newBundle = await generateTableNames(requirementsRef.current)

                // if a new request has started, then just bail
                if (requestId !== currentPreviewRequestId.current) {
                    return
                }

                setBundle(newBundle)
                bundle.current = newBundle
                await generateSchema(newBundle)
            } catch (e) {
                if (requestId === currentPreviewRequestId.current) {
                    console.error(e)
                    setFailed(true)
                }
            }
        },
        [setIsGenerating, updateProgress, setBundle, generateSchema, setFailed]
    )

    useEffect(() => {
        updateSchema()
    }, [updateSchema])

    function removeRequirement(requirement: string) {
        setRequirements(requirements.filter((r) => r !== requirement))
        setTimeout(updateSchema, 100)
    }

    function saveNewItem() {
        setRequirements([...requirements, newRequirement])
        setNewRequirement('')
        setShowingAddRequirement(false)
        setTimeout(updateSchema, 100)
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            saveNewItem()
        } else if (e.key === 'Escape') {
            setShowingAddRequirement(false)
            setNewRequirement('')
            e.stopPropagation()
            e.preventDefault()
        }
    }

    return (
        <>
            <Box flex width="full">
                <Progress value={75} style={{ width: '100%' }}>
                    Stacker AI Builder
                </Progress>
            </Box>

            <Box flex column pt="5xl" style={{ flexGrow: 1 }}>
                <Headline marginBottom="s" size="l">
                    What are you trying to track?
                </Headline>
                <Body>
                    List each entity that is important to the process or workflow you want to manage
                    with your app.
                </Body>
                <Headline mt="3xl" mb="m" size="2xs">
                    Entities
                </Headline>
                <Box flex column overflow="auto" alignItems="flex-start" gap="s">
                    {requirements.map((requirement, index) => (
                        <Tag
                            key={index}
                            showRemoveButton
                            onRemove={() => removeRequirement(requirement)}
                            color="Blue"
                        >
                            {requirement}
                        </Tag>
                    ))}
                    {!showingAddRequirement && (
                        <Button
                            variant="dotted"
                            border
                            size="s"
                            onClick={() => setShowingAddRequirement(true)}
                            mt="m"
                        >
                            Add a new entity
                        </Button>
                    )}
                    {showingAddRequirement && (
                        <Input
                            placeholder="new requirement"
                            value={newRequirement}
                            onChange={(e) => setNewRequirement(e.target.value)}
                            onBlur={() => saveNewItem()}
                            onKeyDown={handleKeyDown}
                            autoFocus
                            mt="m"
                        />
                    )}
                </Box>
            </Box>
            <Box flex width="full" justifyContent="space-between">
                <Button variant="secondary" onClick={moveBack}>
                    Back
                </Button>
                {/* <Button variant="ghost" onClick={() => updateSchema()}>
                    Refresh
                </Button> */}
                <Button variant="primary" onClick={onMoveNext} disabled={isGenerating || failed}>
                    {isGenerating ? 'Generating...' : 'Finish'}
                </Button>
            </Box>
        </>
    )
}
