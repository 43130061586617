import React from 'react'

import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { DefaultHeaderMeta } from 'features/core/DefaultHeaderMeta'
import useWorkspaceTheme from 'features/workspace/useWorkspaceTheme'
import WorkspaceLogo from 'features/workspace/WorkspaceLogoDeprecated'
import publicAsset from 'utils/publicAsset'

import { Box, Flex, Text } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'

/*
Provides the frame for all our Stacker user auth pages, both at the studio domain
and workspace domain. (With the exception of the studio register page which has
has some custom display requirements.)
*/
export const AuthPageFrame = ({ title, children, hideLogo = false }) => {
    const { workspaceAccount } = useAppContext()
    const navTheme = useWorkspaceTheme()
    const headerMeta = workspaceAccount ? `Login | ${workspaceAccount.name}` : 'Login | Stacker'
    const logo = workspaceAccount ? (
        <WorkspaceLogo logoStyle={{ maxHeight: 150, maxWidth: 230 }} />
    ) : (
        <Logo src={publicAsset('/static/media/stacker-logo-colour.png')} alt="Stacker Logo" />
    )

    return (
        <>
            <DefaultHeaderMeta title={headerMeta} />
            <Flex width="100%" height="100%" column align="center" background={navTheme?.bgScdNav}>
                <LoginPanel>
                    <Box
                        style={{ padding: '13px 5px 10px', minHeight: 66 }}
                        textAlign="center"
                        color={navTheme?.textBright}
                        className={STYLE_CLASSES.LOGO}
                    >
                        {!hideLogo && logo}
                    </Box>

                    <Flex flexGrow={1} align="center" justify="center" id="test">
                        <Box width="100%" flexGrow={1}>
                            {title && (
                                <Text
                                    as="h1"
                                    fontSize="lg"
                                    mb={8}
                                    fontWeight="bold"
                                    color={navTheme?.textBright}
                                    data-testid="login-title-header"
                                >
                                    {title}
                                </Text>
                            )}
                            {children}
                        </Box>
                    </Flex>

                    <Box
                        style={{ padding: '13px 5px 10px', minHeight: 66 }}
                        textAlign="center"
                        color={navTheme?.textBright}
                    >
                        {workspaceAccount && !workspaceAccount.optional_features?.whitelabel && (
                            <Text fontSize="smmd" color="neutral.800" fontWeight="bold">
                                <span
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    Powered by
                                </span>
                                <img
                                    src={publicAsset('/static/media/stacker-logo-grey.png')}
                                    alt="Stacker Logo"
                                    style={{
                                        height: '24px',
                                        verticalAlign: 'middle',
                                        marginLeft: '8px',
                                        marginBottom: '1px',
                                        display: 'inline',
                                    }}
                                />
                            </Text>
                        )}
                    </Box>
                </LoginPanel>
            </Flex>
        </>
    )
}

const LoginPanel = styled('div')`
    width: 100%;
    max-width: 500px;
    height: 100%;
    text-align: center;
    padding: 40px;
    align-items: stretch;
    display: flex;
    flex-flow: column;
`

const Logo = styled('img')`
    width: 100%;
    max-width: 200px;
    display: inline;
`

export default AuthPageFrame
