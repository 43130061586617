import React from 'react'

import {
    DropdownEndIndicatorIcon,
    DropdownHeadSearch,
    DropdownItem,
    DropdownItemTag,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditDropdownState } from './hooks/useActionMenuEditDropdownState'
import { ActionMenuEditComponent } from './types'

type ActionMenuEditDropdownProps = {}

export const ActionMenuEditDropdown: ActionMenuEditComponent<
    string | string[],
    ActionMenuEditDropdownProps
> = React.memo(function ActionMenuEditDropdown(props) {
    const { value, fieldLabel, editOptions, onEditValue, onClear, isSingle, fieldIcon } =
        useActionMenuEditDropdownState(props)

    return (
        <DropdownSub>
            <DropdownSubTrigger
                startIcon={fieldIcon?.type === 'lucide' ? { name: fieldIcon.name } : undefined}
                label={fieldLabel}
            />
            <DropdownSubContent
                head={<DropdownHeadSearch autoFocus={false} />}
                onClick={stopPropagation}
            >
                {value.size > 0 && (
                    <DropdownItem
                        startIcon={{ name: 'XCircle' }}
                        label="Clear value"
                        onClick={onClear}
                    />
                )}
                {editOptions.map((option) => {
                    const isChecked = value.has(option.value)

                    return (
                        <DropdownItemTag
                            key={option.value.toString()}
                            label={option.label}
                            size="2xs"
                            color={option.color}
                            multiSelect
                            checked={isChecked}
                            onCheckedChange={(checked) => {
                                onEditValue(option.value, checked)
                            }}
                            closeOnSelect={isSingle}
                            hideCheckbox={option.isRecent && !isChecked}
                            rightSlotContent={(props) => {
                                if (option.isRecent && !isChecked) {
                                    return <DropdownEndIndicatorIcon name="Clock" {...props} />
                                }

                                return null
                            }}
                        />
                    )
                })}
            </DropdownSubContent>
        </DropdownSub>
    )
})
