import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'
import * as Y from 'yjs'

import { useUpdateView, useViews } from 'data/hooks/views'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { useYjsState } from 'features/utils/useYjsState'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useToast } from 'ui/components/Toast'

import { useLayoutEditorSchema } from './hooks/useLayoutEditorSchema'
import { LayoutEditorSchema, Widget } from './types'
import {
    duplicateYWidgetAtPath,
    getWidgetAtPath,
    getYWidgetAtPath,
    insertYWidgetAtPath,
} from './utils'

// Using an interface instead of a type alias so we could extend it from other files.
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface LayoutEditorCommands {
    initEditor: (options: { viewSid: string }) => void
    closeEditor: () => void
    saveViewChanges: () => Promise<void>
    discardViewChanges: () => void
    updateViewName: (newName: string) => void
    insertWidgetAtPath: (widgetType: string, path: string[]) => void
    selectWidgetAtPath: (widgetId: string, path: string[]) => void
    deselectWidget: () => void
    removeSelectedWidget: () => void
    duplicateSelectedWidget: () => void
    undo: () => void
    redo: () => void
    transaction: (
        tr: (data: Y.Map<any>) => void,
        options?: {
            skipHistory?: boolean
        }
    ) => void
}

export type LayoutEditorContextValue = {
    isInitialized: boolean
    isEditing: boolean
    view?: ViewDto
    commands: LayoutEditorCommands
    isViewDirty: boolean
    schema: LayoutEditorSchema
    isSchemaOutdated: boolean
    selectedWidget?: Widget
    onChangeSelectedWidgetAttrs: (tr: (widget: Y.Map<any>) => void) => void
}

export const LayoutEditorContext = React.createContext<LayoutEditorContextValue | null>(null)

export function useLayoutEditorContext(): LayoutEditorContextValue {
    const context = useContext(LayoutEditorContext)

    if (!context) {
        throw new Error('useLayoutEditorContext must be used within a LayoutEditorContextProvider')
    }

    return context
}

export type LayoutEditorContextProviderProps = {}

export const LayoutEditorContextProvider: React.FC<LayoutEditorContextProviderProps> = ({
    children,
}) => {
    const [viewSid, setViewSid] = useState<string | undefined>()
    const { data: views = [] } = useViews()
    const originalView = views.find((v) => v._sid === viewSid)
    const originalViewMemo = useDeepEqualsMemoValue(originalView)
    const originalViewRef = useRef(originalViewMemo)
    originalViewRef.current = originalViewMemo

    const {
        data: view,
        replaceValue,
        applyTransaction,
        undo,
        redo,
    } = useYjsState<ViewDto>(originalView ?? ({} as ViewDto))
    const updatedView = viewSid ? view : undefined
    const updatedViewRef = useRef(updatedView)
    updatedViewRef.current = updatedView

    // Sync the view with the original view when the original view changes.
    useEffect(() => {
        replaceValue(originalViewMemo ?? ({} as ViewDto))
    }, [originalViewMemo, replaceValue])

    const { isOpen: isEditing } = useEditMode()

    const initEditor: LayoutEditorCommands['initEditor'] = useCallback(({ viewSid }) => {
        setViewSid(viewSid)
    }, [])

    const closeEditor: LayoutEditorCommands['closeEditor'] = useCallback(() => {
        setViewSid(undefined)
    }, [])

    const { mutateAsync: updateView } = useUpdateView(false)
    const toast = useToast()
    const saveViewChanges: LayoutEditorCommands['saveViewChanges'] = useCallback(async () => {
        const updatedView = updatedViewRef.current
        if (!updatedView) return Promise.resolve()

        try {
            await updateView({
                id: updatedView._sid,
                patch: { ...updatedView },
            })
        } catch (err) {
            toast({
                type: 'error',
                startIcon: {
                    name: 'AlertCircle',
                },
                title: 'There was a problem saving the view',
                helperText: 'Please try again later. If the issue persists, contact support.',
            })
        }
    }, [toast, updateView])

    const discardViewChanges: LayoutEditorCommands['discardViewChanges'] = useCallback(() => {
        const originalView = originalViewRef.current
        replaceValue(originalView ?? ({} as ViewDto))
    }, [replaceValue])

    const isViewDirty = checkIsViewDirty(updatedView, originalView)

    const updateViewName = useCallback(
        (newName: string) => {
            applyTransaction((data) => {
                data.set('name', newName)
            })
        },
        [applyTransaction]
    )

    const { schema: editorSchema, isLoaded: isEditorSchemaLoaded } = useLayoutEditorSchema({
        view: updatedView,
    })
    const editorSchemaRef = useRef(editorSchema)
    editorSchemaRef.current = editorSchema

    const isInitialized = !!viewSid && Object.keys(view).length > 0 && isEditorSchemaLoaded

    const viewSchemaVersion = updatedView?.layout?.schemaVersion
    // If the client is using an outdated schema version, we should not allow them to edit the view.
    const isSchemaOutdated =
        isInitialized &&
        !!viewSchemaVersion &&
        viewSchemaVersion > editorSchema.version &&
        isEditing

    const updateSchemaVersion = useCallback(() => {
        if (!isEditorSchemaLoaded) return

        applyTransaction((data) => {
            let layout = data.get('layout')
            if (!layout) {
                layout = data.set('layout', new Y.Map())
            }

            layout.set('schemaVersion', editorSchema.version)
        })
    }, [applyTransaction, editorSchema.version, isEditorSchemaLoaded])

    useEffect(() => {
        // Update the schema version when the editor is initialized.
        if (isInitialized && isEditing && !isSchemaOutdated) {
            updateSchemaVersion()
        }
    }, [isEditing, isInitialized, isSchemaOutdated, updateSchemaVersion])

    const insertWidgetAtPath: LayoutEditorCommands['insertWidgetAtPath'] = useCallback(
        (widgetType, path) => {
            applyTransaction((data) => {
                insertYWidgetAtPath(path, editorSchemaRef.current, widgetType, data)
            })
        },
        [applyTransaction]
    )

    // We find nested widgets by using a path of parent IDs to traverse the layout tree.
    const [selectedWidgetId, setSelectedWidgetId] = useState<string | undefined>()
    const selectedWidgetPathRef = useRef<string[]>([])
    const selectedWidget = useMemo(() => {
        const path = selectedWidgetPathRef.current

        return getWidgetAtPath(path, selectedWidgetId, updatedView?.layout)
    }, [selectedWidgetId, updatedView?.layout])

    const selectWidgetAtPath = useCallback((widgetId: string, path: string[]) => {
        setSelectedWidgetId(widgetId)
        selectedWidgetPathRef.current = path
    }, [])

    const deselectWidget = useCallback(() => {
        setSelectedWidgetId(undefined)
        selectedWidgetPathRef.current = []
    }, [])

    useEffect(() => {
        deselectWidget()
    }, [deselectWidget, isEditing])

    const onChangeSelectedWidgetAttrs = useCallback(
        (tr: (attrs: Y.Map<any>) => void) => {
            if (!selectedWidgetId) return

            const path = selectedWidgetPathRef.current

            applyTransaction((data) => {
                const { widget } = getYWidgetAtPath(path, selectedWidgetId, data)
                if (!widget) return

                const existingAttrs = widget.get('attrs')
                if (!existingAttrs) {
                    widget.set('attrs', new Y.Map())
                }

                tr(widget.get('attrs'))
            })
        },
        [applyTransaction, selectedWidgetId]
    )

    const removeSelectedWidget = useCallback(() => {
        if (!selectedWidgetId) return

        const path = selectedWidgetPathRef.current

        applyTransaction((data) => {
            const { widget, idx } = getYWidgetAtPath(path, selectedWidgetId, data)
            if (!widget) return

            const widgetArea = widget.parent as Y.Array<any> | undefined
            widgetArea?.delete(idx, 1)
        })
    }, [applyTransaction, selectedWidgetId])

    const duplicateSelectedWidget = useCallback(() => {
        if (!selectedWidgetId) return

        const path = selectedWidgetPathRef.current

        applyTransaction((data) => {
            const newWidgetId = duplicateYWidgetAtPath(path, selectedWidgetId, data)
            if (!newWidgetId) return

            selectWidgetAtPath(newWidgetId, path)
        })
    }, [applyTransaction, selectWidgetAtPath, selectedWidgetId])

    // Common functionality is abstracted into commands.
    // Don't use state or memo here, as we don't want to re-render the context provider.
    const commands = useMemo<LayoutEditorCommands>(
        () => ({
            initEditor: initEditor,
            closeEditor,
            saveViewChanges,
            discardViewChanges,
            updateViewName,
            insertWidgetAtPath,
            selectWidgetAtPath,
            deselectWidget,
            removeSelectedWidget,
            duplicateSelectedWidget,
            undo,
            redo,
            transaction: applyTransaction,
        }),
        [
            applyTransaction,
            closeEditor,
            deselectWidget,
            discardViewChanges,
            initEditor,
            insertWidgetAtPath,
            redo,
            removeSelectedWidget,
            duplicateSelectedWidget,
            saveViewChanges,
            selectWidgetAtPath,
            undo,
            updateViewName,
        ]
    )

    // De-select the selected widget if it was removed.
    useEffect(() => {
        if (!selectedWidget && selectedWidgetId) {
            deselectWidget()
        }
    }, [deselectWidget, selectedWidget, selectedWidgetId])

    const value = useMemo(
        () => ({
            isInitialized,
            view: updatedView,
            commands,
            isEditing,
            isViewDirty,
            schema: editorSchema,
            isSchemaOutdated,
            selectedWidget,
            onChangeSelectedWidgetAttrs,
        }),
        [
            isInitialized,
            updatedView,
            commands,
            isEditing,
            isViewDirty,
            editorSchema,
            isSchemaOutdated,
            selectedWidget,
            onChangeSelectedWidgetAttrs,
        ]
    )

    return <LayoutEditorContext.Provider value={value}>{children}</LayoutEditorContext.Provider>
}

function checkIsViewDirty(view?: ViewDto, originalView?: ViewDto) {
    return !isEqual(view, originalView)
}
