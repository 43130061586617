import React, { forwardRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { DropdownItem } from './DropdownItem'

type DropdownItemLinkRef = HTMLDivElement

type DropdownItemLinkProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {
    to?: string
    href?: string
}

export const DropdownItemLink = forwardRef<DropdownItemLinkRef, DropdownItemLinkProps>(
    ({ to, href, onClick, onKeyDown, ...props }, ref) => {
        const history = useHistory()
        const openUrl = useCallback(
            (newTab: boolean) => {
                if (newTab) {
                    window.open(to || href, '_blank')
                } else if (to) {
                    setTimeout(() => history.push(to), 50)
                } else if (href) {
                    window.location.assign(href)
                }
            },
            [to, href, history]
        )

        const handleClick = useCallback(
            (event: React.MouseEvent<HTMLDivElement>) => {
                if (event.defaultPrevented) return
                openUrl(event?.metaKey || event?.ctrlKey)
                event.preventDefault()

                onClick?.(event)
            },
            [onClick, openUrl]
        )

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    openUrl(event?.metaKey || event?.ctrlKey)
                    event.preventDefault()
                }

                onKeyDown?.(event)
            },
            [onKeyDown, openUrl]
        )

        return <DropdownItem {...props} onClick={handleClick} onKeyDown={handleKeyDown} ref={ref} />
    }
)
