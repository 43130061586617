import React, { forwardRef, useRef } from 'react'
import ReactQuill from 'react-quill'

import { useColumnWidth } from 'features/datagrid/hooks/useColumnWidth'
import { useEditorValue } from 'features/datagrid/hooks/useEditorValue'
import { useTypeInCellToEdit } from 'features/datagrid/hooks/useTypeInCellToEdit'

import LongTextAttribute from 'v2/ui/components/Attribute/LongTextAttribute'
import { modes } from 'v2/ui/utils/attributeSettings'

import { Box } from 'ui/components/Box'

import { DataGridCell, DataGridCellEditorComponent } from './types'

import { LegacyRichTextCellEditorStyle } from './LegacyRichTextCell.css'

type LegacyRichTextCellEditorProps = {}

const LegacyRichTextCellEditor: DataGridCellEditorComponent<LegacyRichTextCellEditorProps> =
    forwardRef(({ value, field, column }, ref) => {
        const [newValue, setNewValue] = useEditorValue<LongTextAttributeValue>(ref, value)

        const isLongTextField = field.type === 'long_text'

        const editorRef = useRef<ReactQuill | HTMLTextAreaElement | null>(null)

        const handleTypeInCell = (key: string) => {
            const editorElement = editorRef?.current as ReactQuill | null
            if (!editorElement) return

            const length = editorElement.editor?.getLength() ?? 0
            editorElement.editor?.insertText(length, key)
            editorElement.editor?.setSelection(length + 1, 0)
        }

        useTypeInCellToEdit({
            onChange:
                editorRef?.current instanceof HTMLTextAreaElement ? undefined : handleTypeInCell,
            getInputElement: () => {
                if (!isLongTextField) return null

                return editorRef?.current as HTMLTextAreaElement | null
            },
        })

        const columnWidth = useColumnWidth(column!)

        return (
            <Box
                height="full"
                width="400px"
                style={{ minWidth: `${columnWidth}px` }}
                className={LegacyRichTextCellEditorStyle}
            >
                {/* @ts-expect-error */}
                <LongTextAttribute
                    ref={editorRef}
                    field={field}
                    onChange={setNewValue}
                    mode={modes.editing}
                    enableMarkdown={!isLongTextField}
                    showRichTextEditor={!isLongTextField}
                    autofocus
                    tabIndex={0}
                >
                    {newValue}
                </LongTextAttribute>
            </Box>
        )
    })

export const LegacyRichTextCell: DataGridCell = {
    provideEditor: LegacyRichTextCellEditor,
    popupEditor: true,
}
