import { useCallback, useEffect, useRef } from 'react'

import { useAppContext } from 'app/AppContext'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { useWorkflowEditorContext } from 'features/workflows/WorkflowEditorContext'

import { WorkflowActionEditorComponent } from './types'

export const CreateTaskActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { contextSchema } = useWorkflowEditorContext()
    const { selectedStack } = useAppContext()

    const relatedStackId = selectedStack?._sid

    const settingsRef = useRef(action.settings)
    settingsRef.current = action.settings

    useEffect(() => {
        onChange({
            settings: {
                ...settingsRef.current,
                stack_id: relatedStackId,
            },
        })
    }, [onChange, relatedStackId])

    const hasCurrentUser = hasTriggerCurrentUser(contextSchema)

    const inputsRef = useRef(action.inputs)
    inputsRef.current = action.inputs

    useEffect(() => {
        if (hasCurrentUser) return

        // If we change the trigger type and the new type
        // does not support having a current user, just reset
        // the input.
        const inputs = inputsRef.current?.filter((i) => i.id !== USE_CURRENT_USER_INPUT_ID)

        onChange({
            inputs,
        })
    }, [onChange, hasCurrentUser])

    return null
}

const USE_CURRENT_USER_INPUT_ID = 'use_current_user_as_source'
const INPUT_USER_STATE_ID = 'current_user'

export function useCreateTaskActionFinalSchema() {
    const { data: schema } = useWorkflowSchema()
    const { contextSchema } = useWorkflowEditorContext()

    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaActionType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType

            if (!type) return undefined

            const hasCurrentUser = hasTriggerCurrentUser(contextSchema)

            return {
                ...type,
                inputs: type.inputs.filter((i) => {
                    if (!hasCurrentUser && i.id === USE_CURRENT_USER_INPUT_ID) {
                        return false
                    }

                    return true
                }),
            }
        },
        [contextSchema, schema?.nodes]
    )
}

function hasTriggerCurrentUser(contextSchema?: WorkflowSchemaStateItem[]): boolean {
    const finalTriggerType = contextSchema?.find(
        (t) => t.id === 'trigger'
    ) as WorkflowSchemaStateItemGroup
    if (!finalTriggerType) return false

    return finalTriggerType.items.some((i) => i.id === INPUT_USER_STATE_ID)
}
