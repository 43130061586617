import React, { useCallback, useEffect, useState } from 'react'

import * as Parts from './Dropdown.parts'
import { DropdownContextProvider } from './DropdownContext'

type SubProps = Omit<React.ComponentPropsWithoutRef<typeof Parts.Root>, 'asChild'> & {}

export const DropdownSub: React.FC<SubProps> = ({ open, defaultOpen, onOpenChange, ...props }) => {
    const [isOpen, setIsOpen] = useState(open || defaultOpen || false)
    useEffect(() => {
        setIsOpen(open || defaultOpen || false)
    }, [open, defaultOpen])

    const handleOpenChange = useCallback(
        (open: boolean) => {
            setIsOpen(open)
            onOpenChange?.(open)
        },
        [onOpenChange]
    )

    return (
        <DropdownContextProvider isOpen={isOpen} setIsOpen={setIsOpen}>
            <Parts.Sub open={isOpen} onOpenChange={handleOpenChange} {...props} />
        </DropdownContextProvider>
    )
}
