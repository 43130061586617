import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Item } from './Combobox.parts'

/*
 * Takes in a list of providers and current query text.
 * Returns a list of items and collections of items grouped by provider.
 */

export type ItemProvider<TItem, TAuxiliarData = unknown> = {
    id: string
    title?: string
    maxVisibleItemsDefault?: number
    initialItems?: TItem[]
    getItems: (props: {
        query: string | undefined
        queryTerms: string[]
    }) => Promise<{ items: TItem[]; data?: TAuxiliarData }>
    renderItem?: (props: {
        item: TItem
        data?: TAuxiliarData
        query?: string
        queryTerms: string[]
    }) => React.ReactNode
    getItemProps?: (props: {
        item: TItem
        isFirst: boolean
        isLast: boolean
    }) => Partial<React.ComponentPropsWithoutRef<typeof Item>>
    renderGroup?: (props: {
        source: ItemProvider<TItem, TAuxiliarData>
        items: TItem[]
        data?: TAuxiliarData
    }) => React.ReactNode
}
type useComboboxProvidersProps = {
    query?: string
    queryTerms: string[]
    providers: ItemProvider<any>[]
    debounceDelay?: number
}

export type ItemCollection = {
    items: any[]
    visibleItems: any[]
    data?: any
    source: ItemProvider<any>
    query?: string
}

export type useComboboxProvidersReturn = {
    items: any[]
    collections: ItemCollection[]
    showMore: (sourceId: string) => void
    isLoading: boolean
    queryFailed: boolean
}

type PendingQuery = {
    query?: string
    provider: ItemProvider<any>
    isCancelled?: boolean
}
export function useComboboxProviders({
    query,
    queryTerms,
    providers: suppliedProviders,
    debounceDelay = 400,
}: useComboboxProvidersProps): useComboboxProvidersReturn {
    const [itemsState, setItemsState] = useState<{
        items: any[]
        collections: ItemCollection[]
    }>({
        items: [],
        collections: [],
    })

    // Also put this in a ref so we can access it in the debounced function
    // and it won't be stale.
    const itemsStateRef = useRef(itemsState)
    itemsStateRef.current = itemsState

    const [isLoading, setIsLoading] = useState(false)
    const [queryFailed, setQueryFailed] = useState(false)

    const pendingQueries = usePendingQueries()

    // this is to guard against when the caller passes in an array of
    // providers that are the same, but the array reference changes
    const providers = useDeepEqualsMemoValue(suppliedProviders)

    const updateCollections = useCallback(
        (updateFn: (collections: ItemCollection[]) => ItemCollection[]) => {
            setItemsState(({ collections }) => {
                const newCollections = updateFn(collections)

                return { items: flattenCollections(newCollections), collections: newCollections }
            })
        },
        [setItemsState]
    )
    const showMore = useCallback(
        (sourceId: string) => {
            updateCollections((collections) =>
                collections.map((c) =>
                    c.source.id === sourceId ? { ...c, visibleItems: c.items } : c
                )
            )
        },
        [updateCollections]
    )

    useEffect(() => {
        updateCollections((collections) =>
            providers.map((provider) => {
                const existing = collections.find((p) => p.source.id === provider.id)
                return {
                    items: provider.initialItems ?? [],
                    visibleItems: provider.initialItems ?? [],
                    source: provider,
                    ...existing,
                }
            })
        )
    }, [providers, updateCollections])

    const getItems = useDebounce(
        useCallback(
            async (
                query: string | undefined,
                queryTerms: string[],
                providers: ItemProvider<any>[]
            ) => {
                const promises: Promise<void>[] = []
                setIsLoading(true)
                setQueryFailed(false)

                for (let i = 0; i < providers.length; i++) {
                    const provider = providers[i]
                    const collection = itemsStateRef.current.collections.find(
                        (c) => c.source.id === provider.id
                    )

                    // if neither query nor the provider itself has changed since the last time we queried it, then skip it
                    // then don't do anything
                    if (
                        collection &&
                        collection.query === query &&
                        collection.source === provider
                    ) {
                        continue
                    }
                    // or if we have a pending query for this query string and provider, then skip it
                    const existingQueries = pendingQueries.get(provider.id)

                    if (
                        existingQueries.find(
                            (p) => p.query === query && p.provider === provider && !p.isCancelled
                        )
                    ) {
                        continue
                    }

                    pendingQueries.cancel(provider.id)
                    const pendingQuery = pendingQueries.add(provider, query)

                    promises.push(
                        provider
                            .getItems({ query, queryTerms })
                            .then(({ items, data }) => {
                                // If the query params have changed since this query started, then discard the results
                                if (pendingQuery.isCancelled) {
                                    throw new Error('Query cancelled')
                                }

                                const visibleItems =
                                    provider.maxVisibleItemsDefault !== undefined
                                        ? items.slice(0, provider.maxVisibleItemsDefault)
                                        : items

                                updateCollections((collections) => {
                                    return collections.map((c) =>
                                        c.source.id === provider.id
                                            ? {
                                                  ...c,
                                                  items,
                                                  visibleItems,
                                                  data,
                                                  query,
                                                  source: provider,
                                              }
                                            : c
                                    )
                                })
                            })
                            .finally(() => {
                                pendingQueries.remove(pendingQuery)
                            })
                    )
                }

                try {
                    await Promise.all(promises)

                    setIsLoading(false)
                } catch (ex) {
                    // If this wasn't just a cancelled query error, then set the queryFailed flag
                    if (ex?.message !== 'Query cancelled') {
                        console.error(ex)
                        setQueryFailed(true)
                        setIsLoading(false)
                    }
                }
            },
            [pendingQueries, updateCollections]
        ),
        debounceDelay
    )
    useEffect(() => {
        getItems(query, queryTerms, providers)
    }, [getItems, providers, query, queryTerms])

    const { items, collections } = itemsState
    return useMemo(
        () => ({ items, collections, isLoading, queryFailed, showMore }),
        [items, collections, isLoading, queryFailed, showMore]
    )
}

function flattenCollections(collections: ItemCollection[]) {
    return collections.reduce<any[]>(
        (acc, ItemCollection) => [...acc, ...(ItemCollection.visibleItems || [])],
        []
    )
}

function usePendingQueries() {
    // Keep a list of objects representing pending queries so we can
    // cancel/invalidate them if the query changes
    const pendingQueries = useRef<PendingQuery[]>([])
    const get = useCallback((providerId: string): PendingQuery[] => {
        return pendingQueries.current.filter((p) => p.provider.id === providerId)
    }, [])

    const add = useCallback((provider: ItemProvider<any>, query?: string) => {
        const pendingQuery: PendingQuery = { provider, query }
        pendingQueries.current.push(pendingQuery)
        return pendingQuery
    }, [])
    const remove = useCallback((pendingQuery: PendingQuery) => {
        pendingQueries.current = pendingQueries.current.filter((q) => q !== pendingQuery)
    }, [])

    const cancel = useCallback(
        (providerId: string) => {
            get(providerId).forEach((q) => {
                q.isCancelled = true
            })
        },
        [get]
    )

    return useMemo(() => ({ get, add, remove, cancel }), [get, add, remove, cancel])
}
