import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { cloneDeep, isEqual } from 'lodash'
import { v4 as uuid } from 'uuid'

import { assertIsDefined } from 'data/utils/ts_utils'
import { UnsavedChangesBanner } from 'features/admin/edit-mode/UnsavedChangesBanner'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Box } from 'ui/components/Box'

import { ExecutionHistoryHeader } from './ExecutionHistory/ExecutionHistoryHeader'
import { ExecutionHistoryPane } from './ExecutionHistory/ExecutionHistoryPane'
import { getNodeAtPath, isDraftWorkflow } from './common'
import { useFinalWorkflowSchema } from './schemaBuilder'
import { useSaveWorkflow } from './useSaveWorkflow'
import { WorkflowCanvasHeader } from './WorkflowCanvasHeader'
import { WorkflowCanvasPane } from './WorkflowCanvasPane'
import { WorkflowEditorContextProvider } from './WorkflowEditorContext'
import { useWorkflowManagerContext } from './WorkflowManagerContext'

type WorkflowEditorProps = {
    workflow: WorkflowDto
    onClose: () => void
    openNewFlowInEditor: (workflow: WorkflowDto) => void
}
export function WorkflowEditor({
    workflow: suppliedWorkflow,
    onClose,
    openNewFlowInEditor,
}: WorkflowEditorProps) {
    const [workflow, setWorkflow] = useState<WorkflowDto>(suppliedWorkflow)

    const [selectedItemId, setSelectedItemId] = useState<string | undefined>()

    // Store the hierarchy of parent nodes of the selected item, so we know where to insert new items.
    const [selectedItemChainPath, setSelectedItemChainPath] = useState<string[]>([])
    const selectedItemChainPathRef = useRef(selectedItemChainPath)
    selectedItemChainPathRef.current = selectedItemChainPath

    const selectedItemPath = useMemo(() => {
        if (!selectedItemId) return undefined

        return [selectedItemId, ...selectedItemChainPath]
    }, [selectedItemChainPath, selectedItemId])
    const workflowSchema = useFinalWorkflowSchema(workflow, selectedItemPath)

    const [selectedLine, setSelectedLine] = useState<WorkflowLine | undefined>(undefined)

    const handleSetSelectedItem = (item: WorkflowItem | undefined, chainPath: string[] = []) => {
        if (item) {
            setSelectedLine(undefined)
        }
        setSelectedItemId(item?.id)
        setSelectedItemChainPath(chainPath)
    }

    const handleSetSelectedLine = (line: WorkflowLine | undefined, chainPath: string[] = []) => {
        if (line) {
            setSelectedItemId(undefined)
        }
        setSelectedLine(line)
        setSelectedItemChainPath(chainPath)
    }

    const onItemChange = useCallback((id: string, patch: Omit<Partial<WorkflowItem>, 'id'>) => {
        if (id === 'trigger') {
            setWorkflow((workflow) => ({
                ...workflow,
                trigger: {
                    ...workflow.trigger,
                    ...(patch as WorkflowTriggerConfig),
                },
            }))
            return
        } else {
            const atPath = selectedItemChainPathRef.current

            setWorkflow((workflow) => {
                return updateChainAtPath(workflow, atPath, (items) => {
                    return items.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                ...(patch as WorkflowActionConfig),
                            }
                        }

                        return item
                    })
                })
            })
        }
    }, [])

    const discardChanges = () => {
        if (isDraftWorkflow(suppliedWorkflow)) {
            onClose()
        } else {
            setWorkflow(suppliedWorkflow)
        }
    }

    const [showEnablePrompt, setShowEnablePrompt] = useState(false)

    const hideEnablePrompt = () => {
        setShowEnablePrompt(false)
    }

    const { mutateAsync: saveWorkflow } = useSaveWorkflow({
        onSuccess: (savedWorkflow, unsavedWorkflow) => {
            if (isDraftWorkflow(unsavedWorkflow)) {
                openNewFlowInEditor(savedWorkflow)

                setShowEnablePrompt(true)
            }

            setWorkflow(savedWorkflow)
        },
    })

    const saveChanges = async () => {
        return saveWorkflow(workflow)
    }

    const isDraft = isDraftWorkflow(workflow)
    const hasTriggerType = Boolean(workflow.trigger.trigger_type)

    const isDirty = hasTriggerType ? !isEqual(suppliedWorkflow, workflow) || isDraft : false

    const selectedItem = useMemo(() => {
        if (!selectedItemId) return undefined
        if (selectedItemId === 'trigger') return workflow.trigger

        const currentPath = selectedItemChainPathRef.current
        const path = [selectedItemId, ...currentPath]

        return getNodeAtPath(path, workflow)
    }, [selectedItemId, workflow])

    const handleChange = useCallback(
        (patch: Partial<WorkflowItem>) => {
            assertIsDefined(selectedItemId)
            onItemChange(selectedItemId, patch)
        },
        [onItemChange, selectedItemId]
    )

    const addNode = (nodeType: WorkflowSchemaNodeType) => {
        const id = uuid()

        const newNodeConfig: WorkflowNode = {
            id,
            kind: nodeType.kind,
        } as WorkflowNode

        switch (nodeType.kind) {
            case 'action':
                ;(newNodeConfig as WorkflowActionConfig).action_type = nodeType.id
                ;(newNodeConfig as WorkflowActionConfig).name = nodeType.name
                ;(newNodeConfig as WorkflowActionConfig).inputs = []
                break

            case 'loop':
                ;(newNodeConfig as WorkflowLoopConfig).chain = []
                break
        }

        const atPath = selectedItemChainPathRef.current

        setWorkflow((workflow) => {
            return updateChainAtPath(workflow, atPath, (items) => {
                let newIdx = items.length
                if (selectedLine) {
                    if (selectedLine.fromItemId === 'trigger') {
                        newIdx = 0
                    } else {
                        newIdx = items.findIndex((item) => item.id === selectedLine.fromItemId) + 1
                        if (newIdx === 0) newIdx = items.length
                    }
                }

                items.splice(newIdx, 0, newNodeConfig)

                return items
            })
        })

        handleSetSelectedItem(newNodeConfig, atPath)
    }

    const deleteNode = (nodeId: string, chainPath?: string[]) => {
        const atPath = chainPath ?? selectedItemChainPathRef.current

        setWorkflow((workflow) => {
            return updateChainAtPath(workflow, atPath, (items) => {
                return items.filter((item) => item.id !== nodeId)
            })
        })

        handleSetSelectedItem(undefined, atPath)
        handleSetSelectedLine(undefined, atPath)
    }

    const handleChangeTitle = (title: string) => {
        setWorkflow({ ...workflow, name: title })
    }

    const handleChangeStatus = (status: boolean) => {
        setWorkflow({ ...workflow, is_enabled: status })

        hideEnablePrompt()
    }

    // Sync local dirty state with the sliding pane state, to prevent closing the pane when there are unsaved changes.
    const { setDirty, openUnsavedChangesModal } = useSlidingPane()
    useEffect(() => {
        setDirty(isDirty)
    }, [setDirty, isDirty])

    const tryCloseEditor = () => {
        if (isDirty) {
            openUnsavedChangesModal(onClose)
            return
        }

        onClose()
    }

    const onDelete = () => {
        setDirty(false)
        onClose()
    }

    const { currentPane } = useWorkflowManagerContext()

    const onDuplicate = (workflow: WorkflowDto) => {
        openNewFlowInEditor(workflow)
        setWorkflow(workflow)
    }

    return (
        <WorkflowEditorContextProvider
            workflow={workflow}
            contextSchema={workflowSchema}
            selectedItem={selectedItem}
            setSelectedItem={handleSetSelectedItem}
            addNode={addNode}
            deleteNode={deleteNode}
            selectedLine={selectedLine}
            setSelectedLine={handleSetSelectedLine}
            closeEditor={tryCloseEditor}
            selectedItemChainPath={selectedItemChainPath}
        >
            <Box width="full" height="full" maxHeight="full" flex column stretch>
                <Box
                    px="m"
                    py="m"
                    borderColor="border"
                    borderBottomWidth={1}
                    boxShadow="m"
                    style={{ zIndex: 1, minHeight: '50px' }}
                    background="surface"
                >
                    {currentPane === 'editorRunHistory' && (
                        <ExecutionHistoryHeader grow workflow={workflow} />
                    )}
                    {currentPane === 'editorCanvas' && (
                        <WorkflowCanvasHeader
                            grow
                            workflow={workflow}
                            tryCloseEditor={tryCloseEditor}
                            onDelete={onDelete}
                            onChangeTitle={handleChangeTitle}
                            onChangeStatus={handleChangeStatus}
                            onDuplicate={onDuplicate}
                            showEnablePrompt={showEnablePrompt}
                            onHideSavePrompt={hideEnablePrompt}
                        />
                    )}
                </Box>
                {/* <Divider /> */}
                {currentPane === 'editorRunHistory' && (
                    <ExecutionHistoryPane grow height="full" workflow={workflow} />
                )}
                {currentPane === 'editorCanvas' && (
                    <WorkflowCanvasPane
                        grow
                        height="full"
                        maxHeight="full"
                        shrink
                        workflow={workflow}
                        selectedItem={selectedItem}
                        selectedLine={selectedLine}
                        onChange={handleChange}
                    />
                )}
            </Box>
            <UnsavedChangesBanner
                isDirty={isDirty}
                saveChanges={saveChanges}
                discardChanges={discardChanges}
                rightOffset={412}
                position="absolute"
            />
        </WorkflowEditorContextProvider>
    )
}

function updateChainAtPath(
    workflow: WorkflowDto,
    path: string[],
    patch: (chain: WorkflowNode[]) => WorkflowNode[]
): WorkflowDto {
    const updatedWorkflow = cloneDeep(workflow)
    if (path.length < 1) {
        updatedWorkflow.chain = patch(updatedWorkflow.chain)
    }

    const node = getNodeAtPath(path, updatedWorkflow)
    if (node?.kind === 'loop') {
        node.chain = patch(node.chain)
    }

    return updatedWorkflow
}
