import { useCallback, useMemo, useRef } from 'react'

import { useDndContext } from '@dnd-kit/core'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/LayoutEditorContext'
import { useLayoutEditorWidgetAreaContext } from 'features/views/LayoutEditor/LayoutEditorWidgetAreaContext'
import { Widget } from 'features/views/LayoutEditor/types'

import { LayoutEditorWidgetContextMenuHandle } from './useLayoutEditorWidgetContextMenuState'

type UseLayoutEditorWidgetStateOptions = {
    widget: Widget
}

export function useLayoutEditorWidgetState(options: UseLayoutEditorWidgetStateOptions) {
    const { widget } = options

    const { schema, selectedWidget, isEditing, commands } = useLayoutEditorContext()

    const widgetSchema = useMemo(() => {
        return schema.widgets[widget.type]
    }, [schema.widgets, widget.type])

    const Component = widgetSchema?.component

    const isSelected = selectedWidget?.id === widget.id

    const { active } = useDndContext()

    const isDraggingAnotherWidget = !!active && active.id !== widget.id
    const isSelectable = isEditing && !isSelected && !isDraggingAnotherWidget

    const { path = [] } = useLayoutEditorWidgetAreaContext() ?? {}

    const onClick = useCallback(
        (e) => {
            e.stopPropagation()

            if (!isSelectable) return
            e.preventDefault()

            commands.selectWidgetAtPath(widget.id, path)
        },
        [commands, isSelectable, path, widget.id]
    )

    const widgetLabel = widgetSchema?.label

    const contextMenuRef = useRef<LayoutEditorWidgetContextMenuHandle>(null)

    const onContextMenu = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (isSelectable && !isSelected) {
                commands.selectWidgetAtPath(widget.id, path)
            } else if (!isSelected) {
                return
            }

            e.preventDefault()
            e.stopPropagation()

            contextMenuRef?.current?.openAt(e.clientX, e.clientY)
        },
        [commands, isSelectable, isSelected, path, widget.id]
    )

    const onRemoveWidget = useCallback(() => {
        if (!isSelected) return

        commands.removeSelectedWidget()
    }, [commands, isSelected])

    const onDuplicateWidget = useCallback(() => {
        if (!isSelected) return

        commands.duplicateSelectedWidget()
    }, [commands, isSelected])

    const onFocus = useCallback(
        (e: React.FocusEvent) => {
            e.stopPropagation()
            e.preventDefault()

            if (!isSelectable) return

            commands.selectWidgetAtPath(widget.id, path)
        },
        [commands, isSelectable, path, widget.id]
    )

    return useMemo(
        () => ({
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            contextMenuRef,
            onRemoveWidget,
            onDuplicateWidget,
            onFocus,
        }),
        [
            Component,
            isSelected,
            onClick,
            isSelectable,
            widgetLabel,
            isEditing,
            isDraggingAnotherWidget,
            onContextMenu,
            onRemoveWidget,
            onDuplicateWidget,
            onFocus,
        ]
    )
}
